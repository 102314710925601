import React from "react";
import { TextField, OutlinedTextFieldProps } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, ControllerProps, UseFormRegisterReturn } from "react-hook-form";
import OtpInput from "react-otp-input";

/**
 * Component used for edit text
 */
Logger.debug("FxOtpInput.tsx", "Fx Otp Input initializing");

type FxOtpInputType = OutlinedTextFieldProps & ControllerProps;

interface FxOtpInputProps extends Partial<FxOtpInputType>{
    name: string;
    register: UseFormRegisterReturn;
}

export const FxOtpInput: React.FC<FxOtpInputProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control ? props.control : undefined}
      rules={props.rules ? props.rules : { required: false }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <OtpInput
            value={
              props.value === ""
                ? props.value
                : props.value
                ? props.value
                : value
            }
            onChange={props.onChange ? props.onChange : onChange}
            numInputs={6}
            separator={<span> </span>}
            className={"fx-otp-edit fx-input-edit"}
          />
          <TextField
            {...props.register}
            variant={"outlined"}
            id={props.id}
            name={props.name}
            className={"fx-hidden"}
            type={"hidden"}
            value={
              props.value === ""
                ? props.value
                : props.value
                ? props.value
                : value
            }
            onChange={props.onChange ? props.onChange : onChange}
            inputRef={props.inputRef}
            error={error ? error as unknown as boolean : false}
          />
        </>
      )}
    />
  );
};
