/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { CreateWebhook } from '../Create/CreateWebhook';
/**
 * Component: Edit Webhook
 * Usage: edit webhook by passing the data to CreateWebhook
 */
export const EditWebhook: React.FC = () => {
        const params = useParams<any>();

        const id = params.id;

        // API for get webhook details
        const webhook_source = {
            url: "/webhookSubscription/id/" + id,
            method: "GET"
        }

        return <CreateWebhook id={'edit-webhook-form-' + id} source={webhook_source}></CreateWebhook>

    }
