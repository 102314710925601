import moment from "moment";
import * as React from "react";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("CustomTooltip.tsx", "Custom ToolTip initializing")
/**
 * Component used to add tooltip in chart 
 */

interface CustomTooltipProps {
    chartVariation: string;
    label?: any;
    payload?: any;
}

export const CustomTooltip: React.FC<CustomTooltipProps> =
  (props) => {
    const { payload } = props;
  
    return (
      <div className="fx-area-chart-tooltip">
        <p>
          {"$"}
          <em>{payload && payload.length > 0 && payload ? payload[0]?.value : " -- "}</em>
        </p>
        <p>
          {props?.chartVariation === '1D' ? moment(new Date(props?.label)).format('HH:MM:SS') : ''}
          {props?.chartVariation !== '1D' ? moment(new Date(props?.label)).format('MMM DD, yyyy') : ''}
        </p>
      </div>
    )
  };
