/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'; 
import { Grid, Typography, Divider } from '@mui/material';
import {  useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { RegisterComponent } from '../../../libs/saga/dataSaga';

Logger.debug("ContactValidation.tsx", "External Account Validation Status initializing");

/**
 * Component: ContactValidation
 * Usage: To display external contact validation status information
 */

export const ContactValidation: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        /**
         * Set external account validation status default value 
         */
     
        const params = useParams<any>();
        const id = params.id
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
         /**
         * Set external account validation status default value 
         */
         const external_account_data = useMemo(()=>{
            const ext_data:any={ ...context?.data?.body }
             if (context?.data?.body?.externalAccount) {
                 context?.data?.body?.externalAccount.forEach((item: any) => {
                     if (item?.id === Number(id)) {
                         if (item?.validateAccount) {
                             item['validateAccount'].forEach((val: any) => {
                                 if (val.ews) {
                                     ext_data.ewsStatus = val.ews.status;
                                 }
                             });
                         }
                         if (item?.prenote) {
                             ext_data.prenoteStatus = item?.prenote.status;
                         }
                     }
                 })
             }
            return ext_data 
        },[context?.data?.body])
        
         return (
            <Grid id="payees-external-account-validation-info-status-grid" item xs={12}>
                <FxCard id="payees-external-account-validation-info-cards" className="fx-theme-passport">
                    <FxCardHeader id={'dexternal-account-info-card#card-header'}>
                        <Grid id="payees-external-account-validation-info-second-grid" item container justifyContent="space-between" xs={12} >
                            <Grid id="payees-external-account-validation-info-third-grid"  >
                                <Typography id="payees-external-account-validation-info-title-label" variant="h4" >Account Validations</Typography>
                                <div id="payees-external-account-validation-info-div" className="fx-flex-grow" />
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="payees-external-account-validation-info-card-body" className="fx-info-card " >
                        <Grid id={'payees-external-account-validation-info-grid'} container justifyContent="space-between" >
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>&nbsp;</Grid>
                             <Grid xs={12} sm={5} item className="fx-label" >Instant Verification</Grid>
                             <Grid item xs={12} sm={7} className="fx-value">{external_account_data && <FxStatus id={"payees-external-account-validation-ews-status"} tooltip={true} value={external_account_data?.ewsStatus && external_account_data?.ewsStatus ? external_account_data?.ewsStatus : 'NOT_INITIATED'} fill={true} className="fx-status" />}</Grid>
                             <Grid xs={12} sm={5} item className="fx-label">Prenote</Grid>
                             <Grid item xs={12} sm={7} className="fx-value">{external_account_data && <FxStatus id={"payees-external-account-validation-status-prenote-status"} tooltip={true} value={external_account_data?.prenoteStatus && external_account_data?.prenoteStatus ? external_account_data?.prenoteStatus : 'NOT_INITIATED'} fill={true} className="fx-status" />}</Grid>
                         </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
