import React, { useEffect, useMemo } from 'react'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { RegisterComponent } from '../../libs/saga/dataSaga';
import FxCardBody from '../Container/FxCardBody';
import FxCardFooter from '../Container/FxCardFooter';
import { useDispatch } from 'react-redux';
import { DATA_STATE } from '../../libs/reducer/dataReducer';
import { useForm } from 'react-hook-form';
import { ReactComponent as LeftIcon } from '../../assets/svg/page-left.svg';
import { ReactComponent as RightIcon } from '../../assets/svg/page-right.svg';
import FxMaterialSelect from '../Input/FxSelect/FxMaterialSelect';
import { getIcon } from '../../libs/dictionary/icon';
import { idConvert } from '../../libs/utils/string';
import { FxSkeltonList } from '../Page/Cards/FxSkelton';
import { FxCustomComponent } from '../Input/FxCurrency/FxCustomComponent';
import { FxButton } from '../Action/FxButton';
import { checkPermission } from '../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Page/Index/Passport/FxAccessDenied';
import FxCardHeader from '../Container/FxCardHeader';
import { useMediaQuery } from 'react-responsive';


const resultsPerPageData = [
    { label: 25, value: "25" },
    { label: 50, value: "50" },
    { label: 75, value: "75" },
    { label: 100, value: "100" }
]

interface GridListProps {
    id?: string;
    source?: any;
    pagination?: any;
    pageSize?: any;
    pageNumber?: any;
    rowClick?: any;
    iconClick?: any;
    tableClass?: any;
    dataTransformer?: any;
    multiplePermission?: boolean;
    permissions?: any;
    data?: any;
    noResources?: boolean;
    title?: string;
    column?: any;
    noRowCursor?: any;
    isloading?: boolean;
    accessDeniedTitle?: string;
    resultPerPage?: any;
}
/**
 * Grid List Component
 * to display data in grid format
 */
export const FxGridList: React.FC<GridListProps> = React.memo((props) => {

    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { register, formState: { errors }, setValue, control } = useForm();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    const isMobileScreen = useMediaQuery({ query: '(max-width: 600px)' });

    let context: any;
    ({ context, props } = RegisterComponent(props));

    let totalCount: any;
    let hasMore: any; //for timeline api

    //check api call completed or not
    const contextState = context?.data_state;
    const dataLoadStatus = (contextState && contextState === DATA_STATE.STATUS_DONE) ? true : (props.data ? true : false);
    let resultsPerPage = context?.source?.data?.pageSize ? context.source.data.pageSize : 25;
    const returnedCount: any = context?.data?.body?.returnedCount || context?.data?.body?.length;
    let pageSize: any = props.pageSize;

     /**
     * method to handle data transformer
     * @param data :data
     * @returns 
     */
    const transformationFormatter = (data: any) => {
        try {
            return props.dataTransformer(data);
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * reset the component on unmount.so that when redirect back page it will call the api again
     */
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    /**
    * to set permission
    */
    const permission = useMemo(() => {
        if (props?.multiplePermission) {
          for (const item of props?.permissions || []) {
            if (checkPermission(item)) {
              return true;
            }
          }
          return false;
        } else {
          return checkPermission(props?.permissions);
        }
      }, [props?.multiplePermission, props?.permissions]);

    let rows: any;
    if (props.noResources) {
        rows = props.data ? props.data : props.dataTransformer ? transformationFormatter(context?.data?.body) : context?.data?.body;
    }
    else {
        rows = props.data ? props.data : props.dataTransformer ? transformationFormatter(context?.data?.body?.resources) : context?.data?.body?.resources;
    }

    if (context?.data?.body?.totalCount || context?.data?.body?.length) {
        totalCount = context?.data?.body?.totalCount || context?.data?.body?.length;
    }
    /**
     * method to update next page source
     * @param pageSourceData :source
     */
    const updateNewPageSource = (pageSourceData: any) => {
        const pageSource: any = { ...context.source };
        pageSource.data = pageSourceData;
        const newProps = { ...props };
        newProps.source = pageSource;
        dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
    }
     /**
     * method to handle next page click
     */
    const nextPage = (event: any) => {
        const newPage = parseInt(context?.source?.data?.pageNumber) + 1;
        const pageSourceData: any = { ...context.source.data };
        pageSourceData.pageNumber = newPage;
        updateNewPageSource(pageSourceData);
    }
     /**
     * method to handle prev page click 
     */
    const prevPage = (event: any) => {
        const newPage = parseInt(context?.source?.data?.pageNumber) - 1;
        const pageSourceData: any = { ...context.source.data };
        pageSourceData.pageNumber = newPage;
        updateNewPageSource(pageSourceData);
    }
    /**
     * method to handle pagination change
     * @param event :event
     */
    const handlePaginationChange = (event: any) => {
        resultsPerPage = event.target.value;
        pageSize = resultsPerPage
        if (pageSize > totalCount) {
            pageSize = totalCount
        }
        const pageSourceData: any = { ...context.source.data };
        pageSourceData.pageSize = resultsPerPage;
        pageSourceData.pageNumber = 1;
        updateNewPageSource(pageSourceData);
    }

     /**
     * checking the status of the left pagination button whether disabled or not
     */
    const checkPaginationLeftButton = () => {
        if (parseInt(context?.source?.data?.pageNumber) <= 1) {
            return true;
        }
        return false;
    }
     /**
     * checking the status of the right pagination button whether disabled or not
     * @param totalCount 
     * @param resultsPerPage 
     * @returns 
     */
    const checkPaginationRightButton = (totalCount: any, resultsPerPage: any) => {
        if ((parseInt(context?.source?.data?.pageNumber) > (totalCount / parseInt(resultsPerPage))) || ((parseInt(context?.source?.data?.pageNumber) >= (totalCount / parseInt(resultsPerPage))) && ((totalCount % parseInt(resultsPerPage)) === 0))) {
            return true;
        }
        return false;
    }
    /**
     * method to get row click data
     * @param event 
     * @param row 
     */
    const handleClick = (event: any, row: any) => {
        if (props?.rowClick) {
          props.rowClick(event, row);
        }
      };
    return (
        <Grid id={props?.id + "-Home-Grid"} item xs={12} className="fx-grid-list">
            {props?.title && <FxCardHeader id={props?.id + "-list-Card-header"}>
                <Typography id="" variant="h4" >{props?.title}</Typography>
            </FxCardHeader>}
            <FxCardBody id={props?.id + "-list-Card-Body"}>
                {rows && rows.length > 0 && dataLoadStatus ? rows.map((item: any, index: number) => (
                    <Grid container direction="row" spacing={2} >
                    {props && props.column && props.column.columns && props.column.columns.map((dataColumn: any) => {
                        if (dataColumn.type === 'fri://fieldtype/header') {
                            return item?.header ? dataColumn.enable && <Grid item id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName} xs={dataColumn.xs} className={dataColumn.className ? dataColumn.className : false} ><FxCustomComponent id={dataColumn.fieldName + '-' + index} className={dataColumn.className ? dataColumn.className : item.className}>{item[dataColumn.fieldName]}</FxCustomComponent></Grid> : null
                        }
                        if (dataColumn.type === 'fri://fieldtype/image') {
                            return dataColumn.enable && <Grid item id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName} xs={dataColumn.xs} className={dataColumn.className ? dataColumn.className : false} >{<Grid item>{getIcon(dataColumn.icon ? dataColumn.icon : item.icon)}</Grid>}</Grid>
                        }
                        if (dataColumn.type === 'fri://fieldtype/divider') {
                            return dataColumn.enable && item?.divider && <Grid item xs={12} sm={12} className='fx-row-divider'><Divider /></Grid>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/custom') {
                            return dataColumn.enable && <Grid item id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName} xs={dataColumn.xs} alignItems={dataColumn?.alignItems} style={dataColumn.dataStyle ? dataColumn.dataStyle : {}} onClick={(event) => handleClick(event, item)} >
                                <FxCustomComponent id={dataColumn.fieldName + '-' + index} className={dataColumn.className ? dataColumn.className : item.className}>{item[dataColumn.fieldName]}</FxCustomComponent></Grid>
                        }
                        else{
                            return []
                        }
                    })}
                </Grid>
                )) : permission===false ?
                    <Grid className={"fx-grid-container"}>
                        <FxAccessDenied id={props?.id + "-access-denied"} title={props?.accessDeniedTitle ? props?.accessDeniedTitle : 'You are not authorized to access this page'} />
                    </Grid> :
                    rows && dataLoadStatus && rows.length === 0 ? <Grid className="fx-no-data" > No data to show</Grid>
                        : <Grid container direction="row" spacing={2} className={"fx-grid-container"}>
                            <Grid item xs>
                                <FxSkeltonList width="100%" height="12.5rem" />
                            </Grid>
                        </Grid>
                }
            </FxCardBody>
           { rows && dataLoadStatus && rows.length !== 0 && props.pagination && <FxCardFooter id="recent-ledger-list-Home-Card-Footer" className="fx-footer fx-footer-middle fx-table-pagination">
                <Grid item container justifyContent="flex-start" alignItems="center">
                    <Grid item xs={!isSmallScreen ? 1 : 2}>
                        <FxMaterialSelect register={{ ...register('resultsPerPage') }} className={"fx-select fx-pagination-select"} value={resultsPerPage} control={control} onChange={handlePaginationChange} id={'resultsPerPage'} name={'resultsPerPage'} setValue={setValue} data={resultsPerPageData} />
                    </Grid>
                    {!isMobileScreen && (isSmallScreen ? <Grid item xs={8}>
                        <span className="pagination-text pagination-text-no-padding">Results per page</span>
                    </Grid> :
                        <span className="pagination-text">&nbsp; Results per page</span>
)}
                </Grid> 
                <Grid item container justifyContent="flex-end" alignItems="center">
                    <Grid item xs={isMobileScreen?6:!isSmallScreen?12:4} className="page-count-label">{((parseInt(context?.source?.data?.pageNumber) - 1) * resultsPerPage) + 1} to {parseInt(returnedCount) < parseInt(resultsPerPage) ? ((parseInt(context?.source?.data?.pageNumber) - 1) * resultsPerPage) + parseInt(returnedCount) : parseInt(context?.source?.data?.pageNumber) * parseInt(resultsPerPage)} of {hasMore === undefined ? totalCount : hasMore === 1 ? "Many" : rows.length}  </Grid>
                   { !isSmallScreen ?<Grid item xs={2}>
                        <FxButton
                            id="btn-loadprev" onClick={prevPage}
                            className={checkPaginationLeftButton() ? "fx-button fx-pagination-button fx-button-disabled" : "fx-button fx-pagination-button"}
                            disabled={checkPaginationLeftButton() ? true : false}
                        >
                            {props.isloading ? (
                                <CircularProgress
                                    size={20}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                <LeftIcon />
                            )}</FxButton>
                        &nbsp;
                        <FxButton
                            id="btn-loadmore" onClick={nextPage}
                            className={checkPaginationRightButton(totalCount, resultsPerPage) ? "fx-button  fx-pagination-button fx-button-disabled" : "fx-button  fx-pagination-button"}
                            disabled={checkPaginationRightButton(totalCount, resultsPerPage) ? true : false}
                        >
                            {props.isloading ? (
                                <CircularProgress
                                    size={20}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                <RightIcon />
                            )}</FxButton>
                    </Grid>:
                    <Grid item container xs={!isMobileScreen?3:6} justifyContent={'end'}>
                    <FxButton
                        id="btn-loadprev" onClick={prevPage}
                        className={checkPaginationLeftButton() ? "fx-button fx-pagination-button fx-button-disabled" : "fx-button fx-pagination-button"}
                        disabled={checkPaginationLeftButton() ? true : false}
                    >
                        {props.isloading ? (
                            <CircularProgress
                                size={20}
                                style={{
                                    color: 'white',
                                }}
                            />
                        ) : (
                            <LeftIcon />
                        )}</FxButton>
                    &nbsp;
                    <FxButton
                        id="btn-loadmore" onClick={nextPage}
                        className={checkPaginationRightButton(totalCount, resultsPerPage) ? "fx-button  fx-pagination-button fx-button-disabled" : "fx-button  fx-pagination-button"}
                        disabled={checkPaginationRightButton(totalCount, resultsPerPage) ? true : false}
                    >
                        {props.isloading ? (
                            <CircularProgress
                                size={20}
                                style={{
                                    color: 'white',
                                }}
                            />
                        ) : (
                            <RightIcon />
                        )}</FxButton>
                </Grid>}
                </Grid>
            </FxCardFooter>}
        </Grid>
    )
})
