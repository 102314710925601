import React, {useMemo} from 'react'; // we need this to make JSX compile
import { Grid, IconButton, Modal, Typography, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useMediaQuery } from "react-responsive";
import { FxTextEdit } from '../../Input';
import { useForm } from 'react-hook-form';
import { FxButton } from '../../Action/FxButton';
import { FxPercentageTextField } from './FxPercentageTextField';
import { getKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch } from "react-redux";
import {updateComponentParams} from "../../Utils/CommonBaseClass";

const httpClient = HttpClient.getClient();


Logger.debug("ViewAccountBalanceDetails.tsx", "View Balance Detail Modal initializing")
/**
 * This component handles the View Balance Detail Modal
 */
export const FxInvestNowModal: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })
        /**
         * Method handles the onclick event for closing modal
         */
        const handleClose = () => {
            if (props.onClose) {
                props.onClose();
            }
        };
        const { register, formState: { errors }, handleSubmit, getValues, control, watch } = useForm();
        const dispatch = useDispatch();

        // onsubmit function for delete form
        async function onSubmit(data: any) {
            let req: any = {
                "investmentProvider": "TREASURE",
                "amount": data?.annualGrossRevenue,
            }
            if(+data?.managedMoneyTreasuries || +data?.managedMoneyIncome || +data?.managedMoneyMarketPercentage) {
                req['allocation'] = {
                    "managedTreasuries": data?.managedMoneyTreasuries || '0',
                    "managedIncome": data?.managedMoneyIncome || '0',
                    "managedMoneyMarket": data?.managedMoneyMarketPercentage || '0'
                }
            }
            let status:any;
            status = await invest(req);
            status = processAPIResponse(status);

            if (status.status) {
                handleInvestClose();
                updateComponentParams(dispatch, 'investment-summary-card', { 'investSuccessMessage': true,'amount':data?.annualGrossRevenue})
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'investment-instruction-list' } }); 
            } else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };
        
        /**
         * Api call for invest entity
         */
        async function invest(req: any) {
            try {
                const data = await httpClient.post(getCustomerUrl('account/id/' + getKey('customerInitialAccount')?.id + '/portfolio/invest'), req).then(response => {
                    return response;
                }).catch(error => {
                    return { ...error };
                })
                return data;
            } catch (err) {
                return err;
            }
        }


        // function for closing the modal
        const handleInvestClose = () => {
            props.onClose();
        };


        /**
         * Function handles amount validation
         */
        const setAmountValidationOptions = {
            required: true,
            min: 0.01,
            max: 99999999.99
        };

        /**
         * function to get total percentage of all three field
         */
        const totalAmount = useMemo(() => {
            let totalValue = Number(getValues('managedMoneyMarketPercentage')) + Number(getValues('managedMoneyTreasuries')) + Number(getValues('managedMoneyIncome'));
            return (totalValue || '0.00');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [watch()]);
        
        return (
            <Grid container className={"fx-container"}>
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={props.open}
                    onClose={handleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className={isTabScreen ? "fx-modal fx-modal-medium" : 'fx-modal fx-modal-small2medium'}
                >
                     <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-content fx-invest-now-card" >
                        <div className="modal-body">
                            <FxCard className="fx-theme-passport">
                                <FxCardHeader>
                                    <Grid container direction="row" item xs={12} justifyContent="space-between">
                                        <Grid item container xs={11} justifyContent="flex-start">
                                            <Typography variant="h2" className='fx-header-title'>
                                                Invest Now
                                            </Typography>
                                        </Grid>
                                        <Grid className="fx-modal-close-icon" item xs={1}>
                                            <IconButton
                                                onClick={handleClose}
                                                size="large">
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" className='fx-header-title fx-header-secondary-text'>
                                                The requested amount will be split as per the defined allocation.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody className="fx-info-card" >
                                    <Grid container direction="column" spacing={2} className="fx-modal-form flex column" >
                                        <Grid item container xs={12} className='fx-investment-amount'>   
                                            <FxTextEdit
                                                register={{ ...register("annualGrossRevenue") }}
                                                className={errors.annualGrossRevenue ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                type="number" 
                                                variant="outlined" 
                                                valuePattern={/-|\+|e|E/}
                                                onWheel={(e:any)=>e.target.blur()}
                                                control={control}
                                                id="business-info-card-gross-revenue-textbox"
                                                label="Amount"
                                                name="annualGrossRevenue"
                                                prefix="$"
                                                rules={setAmountValidationOptions}
                                                showDecimal={true} 
                                                placeholder='0.00'
                                            />
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Typography variant="h3" className='fx-header-title'>
                                               Define Investment Allocation
                                            </Typography>
                                            <Typography variant="h5" className='fx-header-title fx-info-text' marginTop='0.5rem'>
                                                Defined investment allocation will rebalance your entire portfolio.
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={12} direction='row' className='fx-allowed-investment-section'>
                                            <Grid item xs alignSelf='center'>
                                                <Typography>
                                                    Managed Money market
                                                </Typography>
                                            </Grid>
                                            <FxPercentageTextField 
                                                name={'managedMoneyMarketPercentage'} 
                                                register={{ ...register("managedMoneyMarketPercentage") }}  
                                                control={control} 
                                                id="invest-now-money-market"
                                                onWheel={(e:any)=>e.target.blur()}
                                                placeholder='0.00'
                                            />
                                        </Grid>
                                        <Grid item container xs={12} direction='row' className='fx-allowed-investment-section'>
                                            <Grid item xs alignSelf='center'>
                                                <Typography>
                                                    Managed Treasuries
                                                </Typography>
                                            </Grid>
                                            <FxPercentageTextField 
                                                name={'managedMoneyTreasuries'} 
                                                register={{ ...register("managedMoneyTreasuries") }}  
                                                control={control} 
                                                id="invest-now-money-treasury"
                                                onWheel={(e:any)=>e.target.blur()}
                                                placeholder='0.00'
                                            />
                                        </Grid>
                                        <Grid item container xs={12} direction='row' className='fx-allowed-investment-section'>
                                            <Grid item xs alignSelf='center'>
                                                <Typography>
                                                    Managed Income
                                                </Typography>
                                            </Grid>
                                            <FxPercentageTextField 
                                                name={'managedMoneyIncome'} 
                                                register={{ ...register("managedMoneyIncome") }}  
                                                control={control}  
                                                id="invest-now-managed-income"
                                                onWheel={(e:any)=>e.target.blur()}
                                                placeholder='0.00'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item container xs={12} direction='row' className='fx-allowed-investment-section'>
                                            <Grid item xs alignSelf='center' >
                                                <Grid item xs>
                                                    <Typography variant="h3">
                                                        Total Investment
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h5">
                                                        100% amount is available to invest
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <FxPercentageTextField 
                                                isEditable={false} 
                                                name={'managedMoneyTotalInvestment'} 
                                                register={{ ...register("managedMoneyTotalInvestment") }} 
                                                control={control}  
                                                id="invest-now-total-invest"
                                                placeholder='0.00'
                                                value={totalAmount}
                                            />  
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Typography variant="h5" className='fx-header-title fx-info-text'>
                                                By clicking Invest now you authorize passport to send your invest instruction to Treasure.
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Typography variant="h5" className='fx-header-title fx-info-text'>
                                                Note: If you do not specify an allocation for your investment, Treasure will perform an automatic allocation.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid container columnSpacing={2} justifyContent='end'>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-cancel"
                                                    title='Invest Now'
                                                    id="invest-now-button"
                                                    onClick={handleInvestClose}
                                                >
                                                    CANCEL    
                                                </FxButton>   
                                            </Grid>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-theme"
                                                    title='Redeem'
                                                    id="redeem-button"
                                                    // onClick={handleRedeemAmountOpen}
                                                    type="submit"
                                                >
                                                    INVEST NOW
                                                </FxButton>    
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                </FxCardBody>
                            </FxCard>
                        </div>
                    </div>
                    </form>
                </Modal>
            </Grid>
        );
    })

