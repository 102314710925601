/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import first from 'lodash/first';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import { getKey } from '../../../../libs/utils/storageManager';
import { IDataGridProps, IFilterColumns, IFilterFields } from '../../../../types/common.interfaces';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardHeader from '../../../Container/FxCardHeader';
import { DataGrid } from '../../../Data/DataGrid';
import { Filter } from '../../../Data/Filter';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { ReactComponent as LeftDownArrow } from '../../../../assets/svg/arrow-left-downbig.svg';
import { ReactComponent as RightUpArrow } from '../../../../assets/svg/arrow-right-topbig.svg';
import { truncateChars, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../../Data/FxGridList';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import moment from 'moment';

/**
 * This component handles the recurring list
 */
export const RecurringDepositList: React.FC<any> = React.memo(() => {
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "icon",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem", "paddingLeft": "0 !important" },
                    "dataStyle": { "textAlign": "left !important" }
                },
                {
                    "label": "START DATE",
                    "enable": true,
                    "fieldName": "startDate",
                    "type": "fri://fieldtype/date",
                    "default": true
                },
                {
                    "label": "NAME",
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "default": true
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true
                },
                {
                    "label": "AMOUNT",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/custom",
                    "style": {"text-align": "end"},
                    "default": true
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem"}
                }
            ]
        }


        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "Status",
                    "fieldName": "status",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "Active", "value": "ACTIVE", "checked": false },
                        { "label": "Paused", "value": "PAUSED", "checked": false },
                        { "label": "Cancelled", "value": "CANCELLED", "checked": false },
                        { "label": "Completed", "value": "COMPLETED", "checked": false }

                    ]
                },
                {
                    "label": "Start Date",
                    "toLabel": "To",
                    "fieldName": "transactionDetail.startDate",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Last Updated On",
                    "toLabel": "To",
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Method",
                    "fieldName": "transaction.method",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "ACH", "value": "ACH", "checked": false },
                        { "label": "WIRE", "value": "WIRE", "checked": false },
                        { "label": "CHECK", "value": "CHECK", "checked": false },
                        { "label": "BOOK", "value": "BOOK", "checked": false },
                        { "label": "VIRTUAL CARD", "value": "VIRTUAL_CARD", "checked": false },
                        { "label": "CARD", "value": "CARD", "checked": false },
                    ]
                }

            ]
        }

        const accountId = getKey("selectedAccount")?.id;

        const dataGridProps: IDataGridProps = {
            id: "collect-card-collect-list",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
            resultPerPage: 10
        };

    /**
    * useEffect to load title while loading page
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Recurring Transaction Activity' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
    * useEffect to reset the filter while unmount the page
    */
    useEffect(() => () => {
        updateComponentParams(dispatch, 'collect-card-filter', { resetFilter: true });
    }, [dispatch]);

        const history = useHistory()
        /**
         * Method handles the redirection to details page
         * @param event : event parameter
         * @param data : row data
         */
        function redirectDetailsPage(event: any, data: any) {
            history.push(`/recurring/details/${data.id}`);
        }

        /**
         * Method handles the source creation with account id
         * @returns : source object
         */
        function sourceInfo() {
            if (accountId) {
                const src = {
                    url: "/transaction/recurring/list",
                    method: "POST",
                    data: {
                        "pageNumber": 1,
                        "pageSize": 25,
                        "criteria": {
                            "filters": [


                            ]
                        }
                    }
                }
                return src
            }else{
                return  {
                    url: "",
                    method: "POST",
                    data: {}
                }
            }
        }
        /**
   * Method to transform data
   * @param data : recurring data
   */
        function transformRecurringList(data: any) {
            try {
                return data?.map((item: any) => {
                    const action = first(item.action) as any;
                    const Icon = action?.transaction?.source?.account?.id ?
                    RightUpArrow : action?.transaction?.destination?.account?.id ?
                    LeftDownArrow : undefined;
                    const method = action?.transaction?.method === 'VIRTUAL_CARD' ? 'VIRTUAL CARD' : action?.transaction?.method;
                    return {
                        ...item,
                        amount: <FxCurrencyView value={action?.transaction?.amount} justifyContent="flex-end" prefix={"$"}/>,
                        startDate: item.transactionDetail?.startDate,
                        method: method,
                        description: <><span>{ item?.name} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'recurring-method'} value={method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus> </>,
                        icon: Icon ? <div className={"fx-passport-debit-credit-svg"} ><Icon /></div> : undefined,
                    }
                }) || [];
            }
            catch (e) {
                Logger.error("RecurringDepositList.tsx", "error", e);
                return false;
            }
        }
        
        const permissionArray: any = [
            {
                entity: "Recurring Transaction",
                name: "Wire Send",
                operation: "View"
            },
             {
                entity: "Recurring Transaction",
                name: "Check Send",
                operation: "View"
            },
             {
                entity: "Recurring Transaction",
                name: "Book Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "Ach Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "International Wire Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "Virtual Card Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "ACH Collect",
                operation: "VIEW"
            },
            {
                entity: "Recurring Transaction",
                name: "CARD Collect",
                operation: "VIEW"
            }
        ]

        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "name",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }
            ]
        }

        /**
         * @param data : Recurring Transaction List
         * @returns : transformed data
         */
        const recurringTransactionGridTransformerForMobileView =(data:any)=>{
            const newData: any = [];
            let curDate: string | null = null;

            data?.forEach((item: any, i: number) => {
            newData[i] = { ...data[i] };

            const method = item.action[0]?.transaction.method;
            const amount = item.action[0]?.transaction.amount;
            const startDate = item.transactionDetail.startDate;

            const formattedStartDate = moment(new Date(startDate)).format('MMM DD, YYYY');
            const isDifferentDate = curDate !== formattedStartDate;

            //In case the StartDate is different, then place a header
            if (isDifferentDate) {
                curDate = formattedStartDate;
                newData[i].header = (
                    <Grid item className='fx-grid-list fx-padding-top-header'>
                        <Typography variant='h6'>{formattedStartDate}</Typography>
                    </Grid>
                );
            }
            //If case date is same, put a divider
            if(!isDifferentDate){
                newData[i].divider = true;
            }

            newData[i].name = (
                <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={12} alignItems='center' justifyContent={'flex-start'}>
                        <Grid item>
                            <Grid><Typography variant='h4'>Name : {truncateChars(item?.name, 20)}</Typography></Grid>
                        </Grid>
                        <Grid item container xs justifyContent='end'>
                            <Grid item xs>
                                <Grid><FxCurrencyView value={amount} justifyContent="flex-end" prefix={"$"} /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} alignItems='center'> 
                        <Grid item>
                            &nbsp;<FxPaymentModeStatus tooltip={true} id={'recurring-transaction-status'} value={method} noColor={true} fill={true} className="fx-status"></FxPaymentModeStatus>
                        </Grid>
                        <Grid item>
                            <FxStatus id="recurring-transaction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
        return newData;
        }

        return (

            <Grid id="collect-grid" className="fx-data-list" item xs={12}>
                <FxCard id="collect-card" className="fx-theme-passport">
                    <FxCardHeader id="collect-card-header" className="fx-card-header">
                        <Grid id="collect-card-header-first-grid" item container justifyContent="flex-end" xs={12} >
                            <Grid id="collect-card-header-filter-grid" item className="fx-inline-flex" >
                                <Grid item>
                                  <Filter id="collect-card-filter" multiplePermission={true} field={filterFields} permissions={permissionArray} dataGridProps={dataGridProps} hiddenParameter={["destination.account.id"]}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody>
                        <Grid id="collect-card-body-grid" item xs={12}>
                            {
                            !isSmallScreen?<DataGrid id="collect-card-collect-list" multiplePermission={true} permissions={permissionArray} tableClass="fx-table-passport" rowClick={redirectDetailsPage} source={sourceInfo()} dataTransformer={transformRecurringList} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            :<FxGridList id="collect-card-collect-list" source={sourceInfo()} permissions={permissionArray} dataTransformer={recurringTransactionGridTransformerForMobileView} rowClick={redirectDetailsPage} column={gridColumn} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            }
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    })
