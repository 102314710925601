/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Chip, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Control, Controller, UseFormClearErrors, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FxDateEdit, FxTextEdit } from '../../../Input';
import { FxRecurring } from './FxRecurring';
import { InsertDriveFileTwoTone } from '@mui/icons-material';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/delete-icon.svg';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import { FxFileUpload } from '../../../Input/FxFile/FxFileUpload';
import FxLabel from '../../../Input/FxLabel/FxLabelView';
import { useDispatch } from 'react-redux';

type FormValueType = Record<string, any>;

type FxRepeatProps = {
    id: string;
    /**
     * Field name for using in react-hook-form
     * @default "startDate"
     */
    startDateFieldName?: string;
    /**
     * Field name for using in react-hook-form
     * @default "name"
     */
    nameFieldName?: string;
    /**
     * Field name for using in react-hook-form
     * @default "docType"
     */
    docTypeFieldName?: string;
    /**
     * Field name for using in react-hook-form
     * @default "files"
     */
    filesFieldName?: string;
    /**
     * Field name for using in react-hook-form
     * @default "fileId"
     */
    fileIdFieldName?: string;
    control: Control<FormValueType>;
    errors: FormValueType;
    excludeFiles?: boolean;
    watch: UseFormWatch<FormValueType>;
    register: UseFormRegister<FormValueType>;
    setValue: UseFormSetValue<FormValueType>;
    resetField: UseFormResetField<FormValueType>;
    clearErrors: UseFormClearErrors<FormValueType>;
    onRecurringFormChange(newForm: any): void;
}

const docTypeData = [
    { label: 'Contract', value: 'CONTRACT' }
];

const acceptedFiles = [
    ".pdf",
    ".docx",
    ".jpg",
    ".txt",
    ".png",
    ".jpeg",
    ".xls",
    ".wav",
    ".xlsx",
    ".tiff",
    ".doc",
    ".rtf",
    ".bmp",
    ".efx",
    ".csv",
    ".kswps",
    ".wps",
];

const FxRepeat = (props: FxRepeatProps) => {
    const {
        id,
        startDateFieldName = 'startDate',
        nameFieldName = 'name',
        docTypeFieldName = 'docType',
        fileIdFieldName = 'fileId',
        filesFieldName = 'files',
        control,
        errors,
        excludeFiles,
        watch,
        register,
        setValue,
        resetField,
        clearErrors,
        onRecurringFormChange,
    } = props;
    const dispatch = useDispatch();

    const acceptedFileText = useMemo(() => `(${acceptedFiles.join(', ')})`, []);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <FxDateEdit
                    register={{ ...register(startDateFieldName) }}
                    className={
                        errors.startDate
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                    }
                    control={control}
                    id={`${id}-startDate`}
                    name={startDateFieldName}
                    type="date"
                    variant="outlined"
                    label={"Start Date *"}
                    setValue={setValue}
                    disablePast={true}
                    resetField={resetField}
                    rules={{ required: true }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FxTextEdit
                    register={{ ...register(nameFieldName) }}
                    className={
                        errors[nameFieldName]
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                    }
                    control={control}
                    rules={{ required: true }}
                    id="create-send-account-ach-form-card-name-textbox"
                    label="Name *"
                    name={nameFieldName}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FxRecurring
                    id={`${id}-fx-recurring`}
                    updateTrigger={onRecurringFormChange}
                    startDate={watch(startDateFieldName)}
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
                {!excludeFiles && (
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={12}>
                            <Typography className="filter-popover-label">
                                LINKED DOCUMENTS
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            &nbsp;
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={1}
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={8}>
                                <FxMaterialSelect
                                    register={{ ...register(docTypeFieldName) }}
                                    id={`${id}-doc-type`}
                                    control={control}
                                    rules={{ required: false }}
                                    name={docTypeFieldName}
                                    data={docTypeData}
                                    value={"CONTRACT"}
                                    readOnly={true}
                                    label="Select a document type"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Controller
                                name={filesFieldName}
                                control={control}
                                render={({field: { onChange, value }}) => (
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <FxFileUpload
                                                register={{ ...register(fileIdFieldName) }}
                                                name={fileIdFieldName}
                                                passData={onChange}
                                                id={`${id}-file-id-file-upload`}
                                                acceptedFiles={acceptedFiles}
                                                formData={{ type: "CONTRACT" }}
                                                maxFileSize={10485760}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <FxLabel
                                                className="fx-label-small"
                                                id={`${id}-file-types-supported`}
                                            >
                                                {acceptedFileText}
                                            </FxLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div className={"error-message"}>
                                                {/* {errors.fileError && renderError(errors.fileError)} */}
                                            </div>
                                        </Grid>
                                        {value && (
                                            <Grid item container>
                                                {value.map((file: any) => {
                                                    return (
                                                        <Chip
                                                            icon={<InsertDriveFileTwoTone />}
                                                            label={file.name}
                                                            onDelete={() => {
                                                                const newval = value.filter((item: any) => item.id !== file.id);
                                                                dispatch({
                                                                    type: "DATA_UPDATE_COMPONENT_PARAM",
                                                                    payload: {
                                                                        id: `${id}-file-id-file-upload`,
                                                                        files: newval,
                                                                    },
                                                                });
                                                                onChange(newval);
                                                            }}
                                                            color="primary"
                                                            variant="outlined"
                                                            deleteIcon={<DeleteIcon />}
                                                        />
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default FxRepeat;
