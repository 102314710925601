import React, { useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import { Logo } from '../../Utils/Logo';
import { getKey } from '../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxTreasureStartedLayout } from './FxTreasureStartedLayout';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { useForm } from 'react-hook-form';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { processAPIResponse, pushGTM } from '../../../libs/utils/utils';
import { FxApplicationBanner } from './FxApplicationBanner';
import { useDispatch } from 'react-redux';
import { LogoAccount } from '../../Utils/Logo';
import { FxTextEdit } from '../../Input';
Logger.debug("FxInvestmentAccountAgreement .tsx", "sign up info")

interface IInvestmentAccountAgreement {
    type?: string;
    onChange: Function
}

export const FxInvestmentAccountAgreement: React.FC<IInvestmentAccountAgreement> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const { setValue, control, register, formState: { errors, isSubmitting }, handleSubmit } = useForm();
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [agreementInfo, setAgreementInfo] = useState<IFileInfo[]>();
    const httpClient = HttpClient.getClient();
    const dispatch = useDispatch();

    const isCashBuilderPlus = props.type === 'CASH_BUILDER_PLUS';
    const userName = getKey('userName');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        setBtnDisabled(!event.target.checked)
    }

    const createCustomerInvestmentAccount = async (data:any) => {

      const linkedDocuments = agreementInfo?.map((file: IFileInfo) => {
        return {
          purpose: 'AUTHORIZATION',
          document: {
            type: file?.fileType,
            name:file?.fileType === 'RATE_CARD' ? 'RATE_CARD.pdf' : 'Terms&Condition.pdf',
            base64encodedContent: file?.base64
          }
        }
      })

        let requestBody : any = {
            isCustomerOwned: true, //will always be true for INVESTMENT
            purpose: "INVESTMENT", //can be optional 
            type: props?.type, //new node - CHECKING and DEPOSIT, default CHECKING
            // subType: props?.type, //new node
            linkedDocument: linkedDocuments
        }

        if(data?.nickname){
            requestBody = {
                ...requestBody,
                nickName : data?.nickname
            }
        }

        try {
            const data: any = await httpClient.post('/customer/id/' + getKey('customerId') + '/account', requestBody).then((response) => {
                return response
            })
                .catch((error) => {
                    return { ...error };
                })
            return data;
        }
        catch(e){ 
            
        }
    }

    async function handleAccept(data:any) {
        let status = await createCustomerInvestmentAccount(data);
        status = processAPIResponse(status)
        if (status.status) {
            if(isCashBuilderPlus){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'cash-builder-account-created', data: { isCashBuilderAccountCreated: true } } });
            }else{
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'investment-account-created', data: { isInvestmentAccountCreated: true } } });
            } 
            // Handling gtm data for cash builder and cash builder plus account while successfully enable
            const event = isCashBuilderPlus ? 'bannerCashBuilderPlusSuccessTrigger' : 'bannerCashBuilderSuccessTrigger';
            const gtmData: any = {
                event ,
                userName
            }
            pushGTM(gtmData)
            props.onChange(3);
        } else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    const FeeDetailsTerms = [
        {
            label: (
                <span>By submitting this form, you accept the terms and conditions to enable Investment services. You also accept
                    receiving electronic communications regarding your account, and you certify that the information you
                    provided is complete and accurate.</span>),
            value: 'yes'
        }
    ];

    const treasureLayoutHeader = <>
        <h1>Turn your&nbsp;</h1>
        <h1>business's idle cash &nbsp;</h1>
        <h1>into revenue with&nbsp;</h1>
        <div className='fx-drawer-left-logo'><Logo showFull /></div>
        <h1>{isCashBuilderPlus ? 'Cash Builder+ Account' : 'Cash Builder Account'}&nbsp;</h1>
        <h1>Now! &nbsp;</h1>
    </>;

    return (
        <Grid container id="treasure-account" className="fx-investment-account-agreement">
            <Grid item className={'fx-drawer-left-content fx-drawer-left-content-grey-background'}>
                <Grid item xs={12} className={'fx-treasure-left-layout-header'}>
                    <Grid item xs={2} className={'fx-logo'}>
                        <LogoAccount />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='h2' className={'fx-welcome'}>Welcome!</Typography>
                        <Typography variant='h3' className={'fx-username'}>{getKey('CustomerName')}</Typography>
                    </Grid>
                </Grid>
                <FxTreasureStartedLayout type={props?.type} layoutHeader={treasureLayoutHeader} />
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                <Grid item xs={12} className={'fx-investment-account-details-list'}>
                    <form onSubmit={handleSubmit(handleAccept)}>
                        <Grid container item >
                            <Grid item xs={12}>
                                <FxApplicationBanner text={isCashBuilderPlus ? 'Passport Cash Builder+ Account Agreement' :'Passport Cash Builder Account Agreement'} />
                            </Grid>
                            <Grid item xs={12} className='fx-investment-account-agreement-header'>
                                <Typography>ACCOUNT INFO</Typography>
                            </Grid> 
                            <Grid item xs={12} paddingLeft={'1.5rem'} paddingRight={'1.5rem'} marginTop={'2.33rem'} className='fx-form-edit-profile'>
                                <FxTextEdit 
                                    register={{ ...register('nickname') }}
                                    className={errors.nickname ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                    control={control}
                                    id="account-identifier-form-card-nickname" 
                                    label="Account Nickname"
                                    name="nickname" 
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} className='fx-investment-account-agreement-header' marginBottom={'1rem'}>
                                <Typography>{isCashBuilderPlus ? 'Passport Cash Builder+ Account Agreement' :'Passport Cash Builder Account Agreement'}</Typography>
                            </Grid>
                          <Grid item xs={12} paddingLeft={'1.5rem'} paddingRight={'1.5rem'} marginTop={'2.33rem'}>
                            <FxAgreementLinkCard
                              agreementTypes={[`${isCashBuilderPlus ? 'CashBuilderPlusAccountAgreement' : 'CashBuilderAccountAgreement'}`, `${isCashBuilderPlus ? 'CashBuilderPlusRateCard' : 'CashBuilderRateCard'}`]}
                              onFileInfoReady={setAgreementInfo}
                            />
                          </Grid>
                          <Grid item container xs={12} className="fx-investment-account-agreement-terms">
                            {!!agreementInfo?.length && <Grid item xs={12} marginTop={'2.33rem'} className="fx-investment-account-agreement-terms-text fx-submit-agree">
                                <FxCheckEdit
                                    rules={{ required: 'Please click Agree' }}
                                    control={control}
                                    id="investment-account-terms-condition"
                                    name="InvestmentAccountTerms"
                                    className="fx-privacy-text"
                                    data={FeeDetailsTerms}
                                    row="vertical"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }} setValue={setValue} />
                            </Grid>}
                          </Grid>
                            <Grid item xs={12} sm={12} className="fx-investment-account-agreement-divider">
                                <Divider />
                            </Grid>                                             
                        </Grid>
                        
                        <Grid container spacing={2} marginTop={'2rem'}>
                            <Grid item xs={12} textAlign={'right'} paddingBottom='4rem'>
                                <FxButton variant='contained' className='fx-button fx-button-cancel' id="business-info-btn-back" onClick={() => props.onChange(1)}>Back</FxButton>
                                <span className="fx-padding-right-16" />
                                <FxButton 
                                    className={`fx-accept-button ${btnDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`} 
                                    disabled={btnDisabled} 
                                    type='submit'
                                    isSubmitting={isSubmitting}
                                >
                                   Accept
                                </FxButton>
                            </Grid>   
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
})