import React, { useMemo } from 'react'
import { Grid, Typography, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { ReactComponent as MdArrowForward } from "../../../../assets/svg/view-all-arrow-icon.svg";
import { ReactComponent as LeftDownArrow } from '../../../../assets/svg/arrow-left-downbig.svg'
import { ReactComponent as RightUpArrow } from '../../../../assets/svg/arrow-right-topbig.svg'
import { getKey } from '../../../../libs/utils/storageManager';
import { FxButton } from '../../../Action/FxButton';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { checkPermission, hasValue, truncateChars } from '../../../Utils/CommonBaseClass';
import { FxAccessDenied } from './FxAccessDenied';
import { useMediaQuery } from 'react-responsive';
import FxNoGridDataImage from '../../../Data/FxNoGridDataImage';
import { ReactComponent as HoldLedger } from '../../../../assets/svg/hold-ledger.svg';

/**
 * This component handles the list of recent ledger list
 */
export const HomeLedgerList: React.FC<any> = React.memo((props) => {

    const history = useHistory();
    ({ props } = RegisterComponent(props));
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    
    const selectedGroup: any = []

     /**
     * group by date array
     */
    const result = useMemo(() => {
        if (!props?.data || props.data.totalCount <= 0) {
            return null;
        }
        const reversedData = [...props.data.resources];
        const groupedData = reversedData?.slice(0, 10).reduce((acc, cur) => {
            const date = moment(new Date(cur.lastUpdatedOn)).format('MM/DD/YYYY');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(cur);
            return acc;
        }, {});

        return groupedData;
    }, [props?.data])
    
   /**
   * to set permission
   */
    const permission = useMemo(() => {
        return checkPermission({
            entity: "Accounts",
            name: "Ledger",
            operation: "view"
        });
    }, []);

    return (
        <>
            {permission ? <FxCardBody id="recent-ledger-list-Home-Card-Body" className="fx-home-ledger-list">
                {hasValue(result) && Object.keys(result)?.map((item: any) => {
                    return (
                        result[item].map((obj: any) => {
                            return (
                                <>
                                    {!selectedGroup.includes(item) ? <Grid container direction="row" spacing={2} >
                                        <Grid item xs={12} sm={12}>
                                            <Typography className='fx-home-ledger-list-headerText' variant='h6'>{moment(new Date(item)).format('MMM DD, YYYY')}</Typography>
                                        </Grid>
                                    </Grid> : (
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                    )}
                                    {<Grid className='fx-hidden'>{selectedGroup.push(item)}</Grid>}
                                    <Grid container direction="row" className="fx-home-ledger-list-column" alignItems='center'  >
                                        <Grid item className="fx-home-ledger-list-column-icon">
                                            {(obj?.ledgerType === "HOLD") && <Grid className={"fx-passport-debit-credit-svg"} ><HoldLedger /></Grid>}
                                            {(obj?.ledgerType !== "HOLD" && obj?.type === "DEBIT") && <Grid className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></Grid>}
                                            {(obj?.ledgerType !== "HOLD" && obj?.type === "CREDIT") && <Grid className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></Grid>}
                                        </Grid>
                                        <Grid item container xs className="fx-home-ledger-list-column-content">
                                            <Grid item container xs={12} alignItems='center'>
                                                <Grid item container xs>
                                                    {isSmallScreen ? <Grid item>
                                                        <Grid title={obj?.narration}> <Typography variant='h4'>{truncateChars(obj?.narration, 30)}&nbsp;</Typography></Grid>
                                                    </Grid> :
                                                        <Grid item>
                                                            <Grid title={obj?.narration}> <Typography variant='h4'>{truncateChars(obj?.narration, 70)}&nbsp;</Typography></Grid>
                                                        </Grid>}
                                                    {!isSmallScreen && <Grid item xs>
                                                        <Grid className='fx-home-ledger-list-column-status-mode'> &nbsp;
                                                        <FxPaymentModeStatus tooltip={true} id={'ledger-method'} value={ (obj?.ledgerType === "HOLD" || obj.ledgerType === 'RELEASE') ? "CARD" : obj?.scheduleClass === 'SYSTEM_FEE' ? "FEE" :  (obj?.ledgerType && obj?.ledgerType === 'FASTER_FUNDED') ? "FASTER FUNDED" : obj?.isAdjustment === true ? "ADJUSTMENTS" : obj?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                                <Grid item justifyContent="flex-end">
                                                    {(obj?.type === "DEBIT" && obj?.amount) && <FxCurrencyView value={obj?.amount} showMinus prefix={"$"} />}
                                                    {(obj?.type === "CREDIT" && obj?.amount) && <FxCurrencyView value={obj?.amount} prefix={"$"} showPlus />}
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs alignItems='center' spacing={2}>
                                                {isSmallScreen && <Grid item className='fx-grid-list-method'>
                                                    <Grid> &nbsp;<FxPaymentModeStatus tooltip={true} id={'ledger-method'} value={(obj?.ledgerType === "HOLD" || obj.ledgerType === 'RELEASE') ? "BOOK" : obj?.scheduleClass === 'SYSTEM_FEE' ? "FEE" : (obj?.ledgerType && obj?.ledgerType === 'FASTER_FUNDED') ? "FASTER FUNDED" : obj?.isAdjustment === true ? "ADJUSTMENTS" : obj?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus></Grid>
                                                </Grid>}
                                                {obj?.memo && <Grid item>
                                                    <Grid><Typography variant='h5'>Memo: {obj?.memo}&nbsp;</Typography></Grid>
                                                </Grid>}
                                                {obj?.schedule?.id && <Grid item>
                                                    <Grid><Typography variant='h5'>Txn ID: {obj?.schedule?.id}</Typography></Grid>
                                                </Grid>}
                                                {(obj?.batch?.id) && <Grid item>
                                                    <Grid><Typography variant='h5'>Batch ID: {(obj.batch.id)}</Typography></Grid>
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={12} className="fx-home-ledger-list-column-padding"></Grid>
                                    </Grid>

                                </>
                            )
                        })
                    )
                })}
                {((result === null && !getKey("selectedAccount")?.id) || (props?.data?.totalCount === 0)) && <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} textAlign={'center'}>
                        <FxNoGridDataImage />
                        <Typography className='fx-no-data'> No data to show </Typography>
                    </Grid>
                </Grid>}
            </FxCardBody> : <FxAccessDenied id={'recent-ledger-list-access-denied'} title={'You are not authorized to access this card'} />}
            {result !== null && permission && <FxCardFooter id="recent-ledger-list-Home-Card-Footer" className="fx-footer fx-footer-middle">
                <Grid container xs={12} justifyContent="center" alignItems="flex-start">
                    <FxButton variant="contained" className="fx-button fx-button-view-all" id="home-recent-ledger-list-view-all-button" onClick={() => history.push('/accountledgers')}> View All <MdArrowForward className="fx-footer-icon" /></FxButton>
                </Grid>
            </FxCardFooter>}
        </>

    )
})
