import React from 'react';
import { ReactComponent as CongratulationIcon } from '../../../assets/svg/CongratulationCard.svg';
import { FxDialogCard } from '../../Utils/FxDialogCard';
import { Grid } from '@mui/material';

interface IAcceptReviewAgreement {
    type?: string;
    onButtonClick?: (event: any) => void;
}

export const FxTreasureWelcomeCongratsCard: React.FC<IAcceptReviewAgreement> = React.memo(({ type, onButtonClick }) => {
    return (
        <Grid xs={12} className={'fx-congratulation-card'}>
            <FxDialogCard
                messageContent={
                    <Grid container justifyContent={'center'}>
                <p className='fx-congratulation-card-text1'>Your request for the Passport {type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : 'Cash Builder Account'} has been submitted, and</p>
                <p className='fx-congratulation-card-text1'> your account will be activated within minutes.</p>
            </Grid>
                }
                Icon={CongratulationIcon}
                title={'Congratulations!'}
                buttonText={'Continue to dashboard'}
                onButtonClick={onButtonClick}
            />
            
            
        </Grid>

    )
})