import React, { useEffect, useState } from 'react';
import 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Controller, ControllerProps, UseFormSetValue, UseFormResetField, UseFormRegisterReturn } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AUTH_STRINGS } from '../../../constants/strings';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';

Logger.debug("FxDateEdit.tsx", "Fx Date Edit initializing")
/**
 * Component used to edit date 
 */

type TFieldValues = Record<string, string | null>;

type FxDateEditPropsType = ControllerProps & DatePickerProps<any, any>;

interface FxDateEditProps extends Partial<FxDateEditPropsType>{
    id?: string;
    setValue: UseFormSetValue<TFieldValues>;
    resetField?: UseFormResetField<TFieldValues>;
    register?: UseFormRegisterReturn;
    name: string;
    rules?: any;
    onChange?:(event:any, name: any) =>void;
    required?: boolean;
    month?: any;
    day?: any;
    year?: any;
    variant?: string;
    type?: string;
    defaultCalendarMonth?:any
    ageLimitMessage?: string;
}

export const FxDateEdit: React.FC<FxDateEditProps> =
  (props) => {
    ({ props } = RegisterComponent(props));
    const [selectedDate, setSelectedDate] = useState<Date | any>(props.value ? props.value : props.defaultValue ? props.defaultValue : null);
    const [errMessage, setErrMessage] = useState<string>('');

    /**
     * handle change method to set value
     * @param date :value
     * @param name :field name
     */
    const handleDateChange = (date: any, name: any) => {
      setSelectedDate(date);
      let value;
      const dateIsAfterMaxDate = date && date.isValid() && props.maxDate && date.isAfter(props.maxDate, 'day');
      if (!date || !date?.isValid() || dateIsAfterMaxDate){
        if(dateIsAfterMaxDate) {
          setErrMessage(props.ageLimitMessage || AUTH_STRINGS.ERRORS.DATE_IS_AFTER_MAX_ALLOWED);
        } else {
          setErrMessage(AUTH_STRINGS.ERRORS.DATE_INVALID_REGEXP);
        }
          value = date;
        }
      else {
          value = moment(date?.$d).format("MM/DD/YYYY");
          setErrMessage('');
        }
        props.setValue(props.name, value);
      try {
        props.onChange && props.onChange(value, name);
      } catch (e) {
        Logger.error('FxDateEdit.tsx', 'An error occurred during onChange:', e);
      }
    }
    /**
     * use effect used to handle value changes
     */
    useEffect(() => {
      if (props.resetField && props.value === '') {
        setSelectedDate(null)
      }
      else if (props.value) {
        setSelectedDate(props.value)
      }
      else if (props?.defaultValue) {
        props.setValue(props.name, moment(props?.defaultValue).format("MM/DD/YYYY"));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetField, props.value])

    const validateDate = (value: any) => {
      const isRequired = props.required || (props.rules && props.rules.required);
      if (!value) {
        // If the field is not required and the value is undefined, the validation is successful
        return !isRequired;
      }
       return moment(value, "MM/DD/YYYY").isValid() || errMessage;
    }


    return (
      <Controller
        name={props.name}
        control={props.control ? props.control : undefined}
        rules={{
          ...(props.rules ? props.rules : { required: false }),
          validate: validateDate
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={props?.label}
              value={selectedDate}
              onChange={onChange = (newValue) => {
                handleDateChange(newValue, props?.name);
              }}
              className={error?.ref?.name === props.name ? "border-error-input fx-input-edit" : "fx-input-edit"}
              defaultCalendarMonth={props?.defaultCalendarMonth ? moment(props?.defaultCalendarMonth) : undefined}
              maxDate={props?.maxDate ? moment(props?.maxDate) : undefined} //maxDate={ageLimit(18)}
              minDate={props?.minDate ? moment(props?.minDate) : undefined} //minDate={new Date()}
              inputFormat={props?.month ? "MM/YYYY" : props?.day ? 'DD' : props?.year ? 'YYYY' : "MM/DD/YYYY"}
              views={props?.month ? ['year', 'month'] : props?.day ? ['day'] : props?.year ? ['year'] : ['year', 'month', 'day']}
              renderInput={(params: any) => <TextField {...params} InputLabelProps={{
                shrink: true
              }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: props?.month ? "MM/YYYY" : props?.day ? 'DD' : props?.year ? 'YYYY' : "MM/DD/YYYY"
                }}
                required={props?.required}
              />}
              disablePast={props?.disablePast}
              disableFuture={props?.disableFuture}
              disabled={props?.disabled}
            />
          </LocalizationProvider>
        )}
      />
    );
  }