/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import { ISource } from '../../../types/common.interfaces';
import { useHistory, useParams } from "react-router-dom";
import { clean, createCommonVerifyProps, getScheduleType, updateComponentParams } from "../../Utils/CommonBaseClass";
import { Controller, useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import { createUpdateCollect, getLocationData } from "./ScheduleGlobalFunctions";
import { ReactComponent as UploadIcon } from "../../../assets/svg/upload.svg";
import { accountLabelTransformation } from "./ScheduleGlobalFunctions";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-icon.svg";
import { FxButton } from "../../Action/FxButton";
import { ScheduleTopSection } from "./ScheduleTopSection";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import { AUTH_STRINGS } from "../../../constants/strings";
import { isAchControlAllowed } from "../../Utils/ThemeChecker";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

Logger.debug(
  "CreateMobileCheckDeposit.tsx",
  "create check deposit initializing"
);

/**
 * This component handles the collect creation for mobile check method
 */
export const CreateMobileCheckDeposit: React.FC<any> = React.memo((props) => {
  let context: any;

  ({ context, props } = RegisterComponent(props));

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch,
    setError,
    clearErrors,
    resetField
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [frontImageName, setFrontImageName] = useState("");
  const [backImageName, setBackImageName] = useState("");
  const [imgFront, setImgFront] = useState<any>();
  const [imgBack, setImgBack] = useState<any>();
  const [imageType, setImageType] = useState("Front");
  const [instantFunding, setInstantFunding] = useState(false);
  const [frontFileUploadError, setSetFrontFileUploadError] = useState<any>();
  const [backFileUploadError, setSetBackFileUploadError] = useState<any>();
  const pmLevelQuickSettle = getKey('isCheckQuickSettleEnabled');
  const accountLevelQuickSettle = getKey('accountLevelQuickSettle')?.check;
  const [location, setLocation] = useState<any>();
  const params = useParams<any>();
  const scheduleId = params?.id;

  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available

  const history = useHistory();

  const dispatch = useDispatch();
  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    setImgFront("");
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: { id: "send-frontfile-upload", files: [] },
    });
    setImgBack("");
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: { id: "send-backfile-upload", files: [] },
    });
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: getScheduleType(businessCustomer,'title') + ' Money' ,backButton:'/dashboard' } }});
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acc_src: ISource = {
    url: "account/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: "DESC",
        sortBy: "createdOn",
      },
      criteria: {
        filters: [
          {
            key: "status",
            operator: "nin",
            values: ["INACTIVE"],
          },
        ],
      },
    },
  };
  let collectId: any = "";

  /**
  * Method enables and disables the instant fund functionality
  * @param event : instant fund status
  */
  const handleInstantFund = (event: any) => {
    setInstantFunding(event);
  };

  /**
   * Function handles the form submission for creating collect
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {
      amount: data["amount"],
      method: "CHECK",
      type: "REGULAR",
      purpose: data["purpose"],
      processingDetail: {
        quickSettle: instantFunding
      }
    };
    req["destination"] = {
      account: {
        id: data["depositAccount"],
      },
    };

    if(data['location']){
      req['processingDetail']['location'] = {
        'id': data['location']
      }
    }

    const linkedArray: any = [];
    const frontObject: any = {
      purpose: "CHECK_DEPOSIT",
      document: {
        type: "CHECK_IMAGE_FRONT",
        name: frontImageName,
        base64encodedContent: frontImage,
      },
    };
    if (frontImage) {
      linkedArray.push(frontObject);
    }
    const backObject: any = {
      purpose: "CHECK_DEPOSIT",
      document: {
        type: "CHECK_IMAGE_BACK",
        name: backImageName,
        base64encodedContent: backImage,
      },
    };
    if (backImage) {
      linkedArray.push(backObject);
    }
    req["linkedDocument"] = linkedArray;
    req = clean(req);
    setIsLoading(true);
    let status = await createUpdateCollect(req);
    if (status?.headers?.url) {
      collectId = status.headers.url.split("/").pop();
    }
    setIsLoading(false);
    status = processAPIResponse(status);
    let successMessage = "Created Successfully";
    const redirectUrl=`/thanks/transactions/mobile-check-deposit/`;
    if (data.id && data.id !== undefined) {
      successMessage = "Updated Successfully";
    }
    if (status.status) {
      //api success
      if(status.responseData?.sessionId){
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        const scheduleType: any = getScheduleType(businessCustomer,'lowercase'); 
        const verifyProps = {
            ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, scheduleType),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:true
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    
        else{
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: { id: "send-frontfile-upload", files: [] },
          });
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: { id: "send-backfile-upload", files: [] },
          });
          FxSnackBar.show({
            autoHideDuration: 1000,
            severity: 'success',
            text: successMessage
          });
          history.push("/thanks/transactions/mobile-check-deposit/" + collectId);
        }  
    } 
    else{
       //api  failed
      FxSnackBar.show({
        text: status.message
      });
    }
     
  }
  /**
   * Function handles the event when the front image file is uploaded
   * @param value : file data
   */
  const uploadFrontCompleteInfo = (value: any) => {
    const file: any = value[value.length - 1];
    if (file) {
      setFrontImage(file.base64);
      setFrontImageName(file.name);
      loadImageUi(file.base64, "front");
      setSetFrontFileUploadError(null);
    } else {
      setFrontImage("");
      setFrontImageName("");
    }
  };


   /**
    * Method to render file upload error
    * @param err
    */
   const renderFileUploadError = (err:any): string => {
    if (err.type === 'maxFileSize') {
        return AUTH_STRINGS.ERRORS.CHECK_IMAGE_UPLOAD_SIZE_INVALID;
    }
    return err.message;
  }; 

  /**
   * Function handles the event when the back image file is uploaded
   * @param value : file data
   */
  const uploadBackCompleteInfo = (value: any) => {
    const file: any = value[value.length - 1];
    if (file) {
      setBackImage(file.base64);
      setBackImageName(file.name);
      loadImageUi(file.base64, "back");
      setSetBackFileUploadError(null);
    } else {
      setBackImage("");
      setBackImageName("");
    }
  };
  // data transformation function
  const dataTransformation = useCallback((data: any) => data?.map((item: any) => ({
    value: item.id,
    label: accountLabelTransformation(item)
  })) || [], []);

  // set the image type as front for displaying
  const loadFrontImage = useCallback(() => {
    setImageType("Front");
  }, []);
  // set the image type as back for displaying
  const loadBackImage = useCallback(() => {
    setImageType("Back");
  }, []);
  /**
   * Async function handles setting the image url for front and back image based on type
   */
  async function loadImageUi(data: any, type: any) {
    try {
      if (data) {
        const byteCharacters = window.atob(data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const imageBlob = new Blob(byteArrays, { type: "" });
        const imageObjectURL: any = URL.createObjectURL(imageBlob);
        if (type === 'front') {
          setImgFront(imageObjectURL);
        } else {
          setImgBack(imageObjectURL);
        }
      }
    } catch (err) {
      Logger.error("CreateMobileCheckDeposit.tsx", "error", err); 
    }
  }

  // gets called when deleting the front image
  const handleDeleteFront = () => {
    setImgFront("");
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: { id: "send-frontfile-upload", files: [] },
    });
    //loadImageUi('', 'front');
  };
  // gets called when deleting the back image
  const handleDeleteBack = () => {
    setImgBack("");
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: { id: "send-backfile-upload", files: [] },
    });

    //loadImageUi('', 'back');
  };

  //Redirect back to collect page on cancel
  const handleClose = () => {
    history.push("/dashboard");
  };

  const method_Tab = [
    {
      className: "fx-link-sel",
      title: "CHECK",
      link: "/transaction/collect/add/mobile-check-deposit",
      permission:{
        entity: "Accounts",
        name: "Check Collect",
        operation: "CREATE"
      }
    }
  ]

  if(businessCustomer){
    method_Tab.push({
      className: "",
      title: "CARD",
      link: "/transaction/collect/add/onetime-card",
      permission: {
        entity: "Accounts",
        name: "Card Collect",
        operation: "CREATE"
      }
    })
  }

  if(businessCustomer && isAchControlAllowed(getKey('collectAchControl'))){
    method_Tab.unshift({
      className: "",
      title: "ACH",
      link: "/transaction/collect/add/onetime-ach",
      permission: {
        entity: "Accounts",
        name: "ACH Collect",
        operation: "CREATE"
      }
    })
  }

  /**
   * The main summary data
   */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    [getScheduleType(businessCustomer,'title') + ' Amount'] : {
      id: "amount",
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : "",
    },
    To: {
      id: "to",
      value: accountLabelTransformation(getKey("selectedAccount")),
    },
    ...(businessCustomer
      ? { Purpose: { id: "purpose", value: watch("purpose") } }
      : { Memo: { id: "purpose", value: watch("purpose") } }),
    Via: {
      id: "method",
      value: "CHECK",
    },
  };
  if(getKey('isCheckQuickSettleEnabled') && businessCustomer) {
      summaryData['Faster Funding'] = {
          id: 'quickSettle',
          value: instantFunding ? 'Yes' : 'No',
      }
  }

  useEffect(()=>{
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context?.data?.body])


  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    depositAccount: context?.data?.body?.destination?.url?.split("/").pop() || '',
    purpose: context?.data?.body?.purpose
  }
  const tabClickFunction = () => {
      try{
      const data:any = {
          schedule: 'collect',
          type: 'one-time',
          formData: { amount: watch('amount'), purpose: watch('purpose')}
      }
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
      }
      catch(e){
        Logger.error("CreateMobileCheckDeposit.tsx", "error", e); 
      }
  }

  const isInstantFundingDisabled = !accountLevelQuickSettle || (accountLevelQuickSettle && !pmLevelQuickSettle);
        /**
                 * Function to handle the closing of the modal.
                 */
          
        const handleCloseModal = () => {
          const verifyProps = {...props.verifyProps}
          verifyProps['open'] = false;
          updateComponentParams(dispatch, props.id, { verifyProps });
        };
  return (
    <>
    <ScheduleLayout
      id='create-collect-onetime-check'
      type='collect'
      loading={isLoading}
      submitTitle={getScheduleType(businessCustomer,'title') + ' Money'}
      summaryData={summaryData}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleClose}
      saveEnabled={true}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection id="create-collect-mobile-check-deposit-schedule-top-section" schedule="collect" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataTransformation={dataTransformation} amountId="collect-amount" destinationId="depositAccount" purposeId="purpose"/>
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
        <FxLocationSelectComponent
            label='Location'
            register={{ ...register("location") }}
            control={control}
            rules={{ required: false }}
            id="create-collect-mobile-check-deposit-form-location"
            name="location"
            className="fx-input-edit"
            setError={setError}
            clearError={clearErrors}
            setValue={setValue}
            resetField={resetField}
            isEditable={!scheduleId}
            value={location ? {
              label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
              value: location?.id,
          }: null}
        />
      </Grid>}
      {/* <Grid item sm={12} xs={12}>
        <FxTextEdit
          register={{ ...register("amount") }}
          className={
            errors.amount
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          rules={setAmountValidationOptions}
          id="collect-amount"
          label="Amount*"
          name="amount"
          type="number"
          variant="outlined"
          prefix={"$"}
          showDecimal={true}
          setValue={setValue}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <FxMaterialSelect
          register={{ ...register("depositAccount") }}
          control={control}
          rules={{ required: true }}
          className={
            errors.depositAccount
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          id="depositAccount"
          name="depositAccount"
          dataTransformation={dataTransformation}
          readOnly={true}
          value={getKey("selectedAccount")?.id}
          source={acc_src}
          label="Destination Account*"
          setValue={setValue}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <FxTextEdit
          register={{ ...register("purpose") }}
          className={
            errors.purpose
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          rules={{ required: true }}
          id="purpose"
          label="Purpose *"
          name="purpose"
          variant="outlined"
        />
      </Grid> */}
      {businessCustomer && <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="reate-collect-mobile-check-deposit-form-card-tab" tabClick={tabClickFunction}
        />
      </Grid>}
      <Grid
        item
        xs={12}
        sm={12}
        justifyContent="space-between"
        alignContent="flex-start"
        container
        direction="row"
      >
        {businessCustomer && <Grid item xs={12} sm={12}>
          <Typography
            id="check-details"
            className="fx-create-schedule-subheading"
          >
            {"CHECK DETAILS"}
          </Typography>
        </Grid>}
        <Grid item xs={12} className="fx-schedule-imagecontainer">
          {/*               File button section starts here             */}
          <Grid item container xs={12}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                className="fx-schedule-create-tab-container"
              >
                <Grid>
                  <FxButton
                    className={
                      imageType === "Front"
                        ? "fx-link-sel"
                        : "fx-link"
                    }
                    id="check-image-front-button"
                    onClick={loadFrontImage}
                  >
                    Front
                  </FxButton>
                  <FxButton
                    className={
                      imageType === "Back"
                        ? "fx-link-sel"
                        : "fx-link"
                    }
                    id="check-image-back-button"
                    onClick={loadBackImage}
                  >
                    Back
                  </FxButton>
                </Grid>
              </Grid>
              <Grid item>
                {imgFront && imageType === "Front" && (
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    className="fx-schedule-check-image"
                  >
                    <Chip
                      label={frontImageName}
                      onDelete={() => {
                        handleDeleteFront();
                      }}
                      color="default"
                      deleteIcon={<CloseIcon />}
                    />
                  </Grid>
                )}
                {imgBack && imageType === "Back" && (
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    className="fx-schedule-check-image"
                  >
                    <Chip
                      label={backImageName}
                      onDelete={() => {
                        handleDeleteBack();
                      }}
                      color="default"
                      deleteIcon={<CloseIcon />}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              className="fx-schedule-imagecontainer-imageBox"
            >
              {imgFront && imageType === "Front" && (
                <img alt='' src={imgFront} width="100%"></img>
              )}
              {!imgFront && imageType === "Front" && (
              <Grid item container xs={12} className="fx-padding-32">
                  <Grid item xs={12}>
                    <UploadIcon />
                  </Grid>
                  <Grid item xs={12}>
                    <FxLabelView
                      id="upload-front-image"
                      className="fx-schedule-imagecontainer-descr"
                    >
                      Upload Front Image
                    </FxLabelView>
                  </Grid>
                  <Grid item xs={12}>
                    <FxLabelView
                      id="upload-front-image"
                      className="fx-schedule-imagecontainer-type"
                    >
                      jpeg, jpg
                    </FxLabelView>
                  </Grid>
                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={"frontFileId"}
                      control={control}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <FxFileUpload
                            register={{
                              ...register("frontFileId"),
                            }}
                            name="frontFileId"
                            passData={uploadFrontCompleteInfo}
                            id="send-frontfile-upload"
                            hide={true}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            disableDrop={true}
                            acceptedFiles={[".jpg", ".jpeg"]}
                            formData={{ type: "INVOICE" }}
                            setValue={setValue}
                            maxFileSize={1400000}
                            encode={true}
                            onMaxFileSizeExceed={()=> setSetFrontFileUploadError({type:'maxFileSize'})}
                          />
                          <div className={'error-message'}>
                            {frontFileUploadError && renderFileUploadError(frontFileUploadError)}
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {imgBack && imageType === "Back" && (
                <img alt='' src={imgBack} width="100%"></img>
              )}
              {!imgBack && imageType === "Back" && (
                <Grid item container xs={12} className="fx-padding-32">
                  <Grid item xs={12}>
                    <UploadIcon />
                  </Grid>
                  <Grid item xs={12}>
                    <FxLabelView
                      id="upload-back-image"
                      className="fx-schedule-imagecontainer-descr"
                    >
                      Upload Back Image
                    </FxLabelView>
                  </Grid>
                  <Grid item xs={12}>
                    <FxLabelView
                      id="upload-back-image"
                      className="fx-schedule-imagecontainer-type"
                    >
                      jpeg, jpg
                    </FxLabelView>
                  </Grid>
                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={"backFileId"}
                      control={control}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <FxFileUpload
                            register={{ ...register("backFileId") }}
                            passData={uploadBackCompleteInfo}
                            name="backFileId"
                            id="send-backfile-upload"
                            hide={true}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            disableDrop={true}
                            acceptedFiles={[".jpg", ".jpeg"]}
                            formData={{ type: "INVOICE" }}
                            setValue={setValue}
                            maxFileSize={1400000}
                            encode={true}
                            onMaxFileSizeExceed={()=> setSetBackFileUploadError({type:'maxFileSize'})}
                          />
                          <div className={'error-message'}>
                            {backFileUploadError && renderFileUploadError(backFileUploadError)}
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/*               File button section ends here             */}
          {/*               File upload section starts here             */}

          {/*               File upload section ends here             */}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography>
          NOTE
        </Typography>
        <ul className="fx-check-note">
          <li>Maximum supported Image size limit is 1.4MB.</li>
          <li>Deposited checks usually take 1 to 7 business days after review to appear in your account.</li>
          <li>We recommend you to keep the check with yourself for 30 days.</li>
        </ul>
      </Grid>
      {pmLevelQuickSettle && businessCustomer &&
      <Grid item xs={12} sm={12}>
          <FxSwitch id="create-collect-check-form-card-instant-funding" value={instantFunding} onClick={handleInstantFund} disabled={isInstantFundingDisabled}></FxSwitch>
          <FxLabel value="Faster Funding"></FxLabel>
        </Grid>
      }
    </ScheduleLayout>
    </>
  );
});
