import React, { useEffect } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as MultipleUser } from '../../../assets/svg/multiple-user-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { clean, handleAddressChange, handleKeyDownChange, handleZipChange, renderError, setEmailValidationOptions, setPhoneValidationOptions, setZipValidationOptions, updateComponentParams } from '../../Utils/CommonBaseClass';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxButton } from '../../Action/FxButton';
import FxCardFooter from '../../Container/FxCardFooter';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxDateEdit, FxTextEdit } from '../../Input';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { AUTH_STRINGS } from '../../../constants/strings';
import usa_state from '../../Utils/usa_states.json'
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
/**
 * component used to handle Buisness Location Details Page
 */
export const LocationDetailsSignupCard: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
        const { register, formState: { errors, isSubmitting }, setValue, control, setError, clearErrors, handleSubmit } = useForm();
        const httpClient = HttpClient.getClient();

        // To set default values for fields before loading of component
        useEffect(() => {
            if(props?.data?.length > 0){
                updateComponentParams(dispatch, props.id, {locationData: props?.data?.[0]})
                updateComponentParams(dispatch, props.id, {isPrimaryAddress: checkIfPrimaryAddress(props?.data?.[0],props?.businessDetails)})
                setValue('addressLine1',props?.data?.[0]?.address?.addressLine1);
                setValue('addressLine2',props?.data?.[0]?.address?.addressLine2);
                setValue('state',props?.data?.[0]?.address?.state);
                setValue('city',props?.data?.[0]?.address?.city);
                setValue('zip',props?.data?.[0]?.address?.zip);
            }else{
                updateComponentParams(dispatch, props.id, {isPrimaryAddress: false})
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        /**
         * Method to check if location address is similar to Primary Address
         */
        const checkIfPrimaryAddress = (item:any, businessDetails:any) => {
            const { address: itemAddress } = item || {};
            const { addressLine1, addressLine2, city, state, zip } = itemAddress || {};
            const { addressLine1: busAddressLine1, addressLine2: busAddressLine2, city: busCity, state: busState, zip: busZip } = businessDetails || {};
        
            return addressLine1 === busAddressLine1 &&
                (addressLine2 ? addressLine2 === busAddressLine2 :true) &&
                city === busCity &&
                state === busState &&
                zip === busZip;
        }

        const authorizedCheckBoxData = [
            { label: 'Same as Primary Address', value: 'isPrimaryAddress', checked: props?.isPrimaryAddress }
        ]

        /**
         * submit data
         */
        const onSubmit = async (data: any) => {
            let req: any = {};
            let status: any;
            const formattedPhoneNumber = data.phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            let address = {
                "addressLine1": data.addressLine1,
                "addressLine2": data.addressLine2,
                "city": data.city,
                "state": data.state,
                "zip": data.zip,
            }
            address = clean(address);
            req = {
                "doingBusinessAs": data.doingBusinessAs,
                "establishmentDate": data.establishmentDate,
                "website": data.website,
                "email": data.email,
                "phone": formattedPhoneNumber,
                "address": Object.keys(address).length > 0 ? address : undefined
            };
            req = clean(req);
            
            if(props?.data?.length > 0){
                status = await updateLocation(req,props?.data?.[0]?.id);
            }else{
                status = await addLocation(req);
            }
            status = processAPIResponse(status)
            if (status.status) {
                // api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: props?.data?.length > 0 ? 'Location Updated Successfully!' : 'Location Added Successfully!',
                });
                props?.onSubmit(req);
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
        * Method to call api  for create user
        * */
        async function addLocation(paylaoddata: any) {
           try {
               const data: any = await httpClient.post(getCustomerUrl('location',false), paylaoddata)
               return data;
           } catch (err) {
               Logger.error("LocationDetailsSignupCard.tsx", "error", err);
               return err;
           }
        }

        /**
        * Method to call api  for update user
        * */
        async function updateLocation(paylaoddata: any,id:any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('location/id/' + id,false), paylaoddata)
                return data;
            } catch (err) {
                Logger.error("LocationDetailsSignupCard.tsx", "error", err);
                return false;
            }
        }

        // Method handles the click operation for going back to business details screen
        const handleGoBackToDetailsClick = () => {
            props.goBackClick(1);
        }
        
        /**
        *Address line 1 validation constant
        **/ 
        const setAddressLine1Validation = {
            required: true,
            minLength: {
                value: 4,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            },
            maxLength: {
                value: 40,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            }
        }

        /**
        * handle change for check box
        * @param event :click event
        */
        const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target?.type === 'checkbox') {
                if (event.target.value === 'isPrimaryAddress') {
                    updateComponentParams(dispatch, props.id, {isPrimaryAddress: event.target.checked})
                    if(event.target.checked){
                        setValue('addressLine1',props?.businessDetails?.addressLine1);
                        setValue('addressLine2',props?.businessDetails?.addressLine2);
                        setValue('city',props?.businessDetails?.city);
                        setValue('state',props?.businessDetails?.state);
                        setValue('zip',props?.businessDetails?.zip);
                    }else if(props?.data?.length > 0){
                        setValue('addressLine1',props?.data?.[0]?.address?.addressLine1);
                        setValue('addressLine2',props?.data?.[0]?.address?.addressLine2);
                        setValue('state',props?.data?.[0]?.address?.state);
                        setValue('city',props?.data?.[0]?.address?.city);
                        setValue('zip',props?.data?.[0]?.address?.zip);
                    }else{
                        setValue('addressLine1','');
                        setValue('addressLine2','');
                        setValue('city','');
                        setValue('state','');
                        setValue('zip','');
                    }
                }
            }
        }

        return (
            <Grid container id='location-detail-card' xs={12} justifyContent='center'>
                <Grid item xs={12} sm={isSmallScreen?undefined:11} md={isSmallScreen?undefined:7}>
                    <FxCard id="location-detail-card-form-card" className={isSmallScreen?"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-signup-formkyc-mobile fx-business-owner":"fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-business-owner"}>
                        <div className="fx-form-edit-profile ">
                            <form id="business-details-form" onSubmit={handleSubmit(onSubmit)}>
                                <FxCardHeader id="location-detail-card-form-card-header">
                                    <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                                        <Grid item xs={12} flexWrap={'nowrap'} container>
                                            <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                                                <MultipleUser />
                                            </Grid>
                                            <Grid item>
                                                <Typography id="location-detail-card-form-card-title" variant="h3">
                                                    Add Business Location Details
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} marginTop={2.5}>
                                            <Typography variant="h6" id='location-detail-card-form-card-label' className={isSmallScreen?"fx-word-wrap":''}>
                                                Provide the location details for your business.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody id="signUp-location-detail-add-form-card-body" className="fx-info-card fx-margin-top-reducer fx-info-kyc-view" >
                                    <Grid container direction="row" spacing={2} className="fx-info-card-form " >
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("doingBusinessAs") }} className={errors.doingBusinessAs ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="doingBusinessAs" label="Doing Business as *" name="doingBusinessAs" variant="outlined" defaultValue={props?.data?.[0]?.doingBusinessAs} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} >
                                            <FxDateEdit register={{ ...register("establishmentDate") }} className={errors.establishmentDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="establishmentDate" label="Established On" name="establishmentDate" setValue={setValue} variant="outlined" defaultValue={props?.data?.[0]?.establishmentDate} disableFuture={true} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxLabel id="location-detail-details-label" className="fx-label-kyc" >ADDRESS</FxLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container alignItems="center" >
                                                <Grid item >
                                                    <FxCheckEdit register={{ ...register("isPrimaryAddress") }} onChange={handleChangeCheckBox} className='fx-label-kyc' control={control} data={authorizedCheckBoxData} id="signUp-location-detail-form-card-isPrimaryAddress" name="isPrimaryAddress" row="vertical" setValue={setValue} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"}  rules={setAddressLine1Validation} control={control} id="signUp-location-detail-addressLine1" label="Address Line 1 *" name="addressLine1"  onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} isEditable={!props?.isPrimaryAddress} defaultValue={props?.data?.[0]?.address?.addressLine2}/>
                                            <div className={'error-message'}>
                                                {errors.addressLine1 && renderError(errors.addressLine1)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-location-detail-addressLine2" label="Address Line 2" name="addressLine2" isEditable={!props?.isPrimaryAddress} defaultValue={props?.data?.[0]?.address?.addressLine2}/>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-location-detail-city" label="City *" name="city" isEditable={!props?.isPrimaryAddress} defaultValue={props?.data?.[0]?.address?.city}/>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FxMaterialSelect register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-location-detail-select-state" name="state" data={usa_state} label="State *" setValue={setValue} readOnly={props?.isPrimaryAddress} value={props?.data?.[0] ? props?.data?.[0]?.address?.state : ''}/>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="signUp-location-detail-zip" label="ZIP *" name="zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} isEditable={!props?.isPrimaryAddress} defaultValue={props?.data?.[0]?.address?.zip}/>
                                            <div className={'error-message'}>
                                                {errors.zip && renderError(errors.zip)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxLabel id="location-detail-details-label" className="fx-label-kyc" >YOUR LOCATION CONTACT INFO</FxLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FxTextEdit register={{ ...register("email") }} control={control} rules={setEmailValidationOptions(false)} className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} id="email" name="email" label="Email" defaultValue={props?.data?.[0]?.email}/>
                                            <div className={'error-message'}>
                                                {errors.email && renderError(errors.email)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(false)} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="phone" name="phone" label="Phone Number" defaultValue={props?.data?.[0]?.phone} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                            <div className={'error-message'}>
                                                {errors.phone && renderError(errors.phone)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                                <FxTextEdit register={{ ...register("website") }} className={errors.website ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{required: false}} id="signup-website" label="Website" name="website" defaultValue={props?.data?.[0]?.website}/>
                                            </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>   
                                </FxCardBody>
                                <FxCardFooter id="location-detail-card-Card-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer fx-footer-kyc">
                                        <FxButton
                                            disableRipple={false}
                                            variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="back-location-detail-card-btn"
                                            onClick={handleGoBackToDetailsClick}
                                        > GO BACK
                                        </FxButton>
                                        <Grid className='fx-padding-right-15'></Grid>
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id={"location-details-card-save-button"}
                                            type="submit"
                                            isSubmitting={isSubmitting}
                                        >
                                            Save & Continue
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </form>
                        </div>
                    </FxCard>
                </Grid>
            </Grid>
        )
    }
)
