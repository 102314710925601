/*
*@sarath
Fx Skelton component 
extends from the Material Skelton and 
enhances for the airbase requirements
*/
import React from "react";
import { Card, CardHeader, CardContent, Grid, Skeleton, CircularProgress, SkeletonProps } from "@mui/material";

//default skelton
export const FxSkelton: React.FC<SkeletonProps> = props => {
  return (
    <Card className={props.className ? props.className : "fx-skelton"}>
      <CardHeader
        avatar={
          <Skeleton animation="wave" variant="circular" width={40} height={40} />

        }
        title={
          <><Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />
          </>
        }
      />
      <Skeleton animation="wave" variant="rectangular" height={props.height && props.height} />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>

  )
}

//skelton for the datagrid list
export const FxSkeltonList: React.FC<SkeletonProps> = props => {
  let maxHight: number = 62.5;
  if (props.height) {
    try {
      maxHight = parseInt(props.height + "".replace('rem', ''))
    } catch (e) { }
  }


  const rows_hight = 45 * 0.0625;
  let rowCount: any = Math.floor((maxHight) / rows_hight);
  let rows: any = [];
  for (let i = 1; i <= rowCount; i++) {
    rows.push(i);
  }
  return (
    <Card className={props.className ? props.className : "fx-skelton"} style={{'width': props.width || '100%' }}>
      <table className="fx-table-skelton" width="100%">
        {/* card header 40px height */}
        <tr>
          <td width="15%" >
            <CardHeader
              avatar={
                <Skeleton animation="wave" variant="circular" width={16} height={16} />
              }
              title={
                <><Skeleton animation="wave" height={16} width="80%" /></>
              }
            /> </td>
          <td ><CardContent><Skeleton animation="wave" /></CardContent></td>
          <td width="15%"><CardContent><Skeleton animation="wave" /></CardContent></td>
          <td width="15%"><CardContent><Skeleton animation="wave" /></CardContent></td>
        </tr>
        {/* card list items height 44px  */}
        {
          rows.map(() => {
            return (
              <tr>
                <td >
                  <CardHeader
                    avatar={
                      <Skeleton animation="wave" variant="circular" width={16} height={16} />
                    }
                    title={
                      <><Skeleton animation="wave" height={16} width="80%" /></>
                    }
                  />
                </td>
                <td ><CardContent><Skeleton animation="wave" /></CardContent></td>
                <td ><CardContent><Skeleton animation="wave" /></CardContent></td>
                <td><CardContent><Skeleton animation="wave" /></CardContent></td>
              </tr>

            )
          })
        }
      </table>
    </Card>

  )
}

//skelton for the full page
export const FxSkeltonFullPage: React.FC<SkeletonProps> = () => {
  return (
    <Grid container>
      <Grid className="fx-skelton-full-page-loader-menu">
      </Grid>
      <Grid xs={true} item={true}>
        <div className="fx-skelton-full-page-loader">
          <CircularProgress />
        </div>
      </Grid>
    </Grid>
  );
}


/*
*@amankhurana
 Fx Skelton Right Info card 
*/

export const FxSkeltonRightInfoCard: React.FC<SkeletonProps> = props => {
  let count = 6; //default skeltons are 6
  if (props.height !== undefined) {
    let height = parseInt(props.height + "".replace('rem', ''))
    const heightByRem = 27 * 0.0625;
    count = Math.round(height / heightByRem);
  }
  let heightCount = [];
  for (let i = 0; i < count; i++) {
    heightCount.push(i);
  }

  return (
    <Card className={props.className ? props.className : "fx-skelton fx-skelton-details-right"}
          style={{ 'height': props.height ? props.height : '9.7rem', 'width': props.width || '100%' }}>
      <CardContent>
        <React.Fragment>
          {
            heightCount.map(() => {
              return <Grid container >
                <Grid item xs={12} >
                  <Skeleton animation="wave" height={7} />
                </Grid>
              </Grid>
            })
          }
        </React.Fragment>
      </CardContent>
    </Card>

  )
}

/*
*@amankhurana
 Fx Skelton left Info card 
*/

export const FxSkeltonLeftInfoCard: React.FC<SkeletonProps> = props => {
  let count = 6; //default skeltons are 6
  if (props.height !== undefined) {
    let height = parseInt(props.height + "".replace('rem', ''));
    const heightByRem = 27 * 0.0625;
    count = Math.round(height / heightByRem);
  }
  let heightCount = [];
  for (let i = 0; i < count; i++) {
    heightCount.push(i);
  }

  return (
    <Card className={props.className ? props.className : "fx-skelton fx-skelton-details"}
          style={{ 'height': props.height ? props.height : '9.7rem', 'width': props.width || '100%' }} >
      <CardContent>
        <React.Fragment>
          {
            heightCount.map((item: any) => {
              return <Grid container >
                <Grid item xs={5} className="fx-info-label"  >
                  <Skeleton animation="wave" height={7} />
                </Grid>
                <Grid className="fx-info-value" xs={6}>
                  <Skeleton animation="wave" height={7} />
                </Grid>
              </Grid>
            })
          }
        </React.Fragment>
      </CardContent>
    </Card>
  )
}