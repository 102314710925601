import { Typography } from '@mui/material';
import React from 'react'
import clsx from 'clsx';
import { useCallback } from 'react';
import { Grid, Modal, IconButton } from '@mui/material';
import { ReactComponent as SuccessIcon } from '../../../src/assets/svg/success-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { FxButton } from '../Action/FxButton';

interface IFxDialogModal {
  id: string;
  buttons?: any;
  onClose(): void;
  title?: string;
  open: boolean;
  className?: string;
  closeDisabled?: boolean;
  info?: string;
  hasCancelButton?: boolean;
  submitButtonText?: string;
  onSubmit?(): void;
  Icon?: React.ComponentType;
  isSuccess?: boolean;
}

/**
 * Generic dialog modal window
 */
export const FxDialogModal: React.FC<IFxDialogModal> = React.memo(({
                                                         onClose,
                                                         open,
                                                         className,
                                                         closeDisabled,
                                                         info,
                                                         title,
                                                         id,
                                                         submitButtonText,
                                                         onSubmit,
                                                         hasCancelButton,
                                                         Icon,
                                                         isSuccess
                                                       }) => {

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return <Modal
    id={`${id}-dialog-modal`}
    open={open}
    onClose={handleClose}
    disableEnforceFocus={true}
    disableAutoFocus={false}
    className={clsx('fx-modal fx-dialog-modal fx-modal-small2medium', className)}
  >
    <Grid className="modal-content">
      <Grid className="modal-body">
        <Grid className="fx-modal-close-icon">
          <IconButton
            id={`${id}-modal-close-button`}
            size="large"
            disabled={closeDisabled}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid>
          {Icon && <Grid item xs={12} container justifyContent={'center'} className={'fx-dialog-modal-icon fx-margin-bottom-2'}>
            {isSuccess ? <SuccessIcon /> : <Icon/>}
          </Grid>}
          {title && <Grid container justifyContent="center" item xs={12} className={'fx-margin-bottom-1'}>
              <Typography className={'fx-dialog-modal-title'} textAlign={'center'}>
                {title}
              </Typography>
          </Grid>}
          {info && <Grid container justifyContent="center" item xs={12}>
              <Typography className={'fx-dialog-modal-info'}  textAlign={'center'}>
                {info}
              </Typography>
          </Grid>}
          <Grid container justifyContent={'center'} spacing={2}>
            {hasCancelButton && !isSuccess && <Grid item>
                <FxButton
                    className="fx-button fx-button-cancel"
                    id={`${id}-modal-cancel`}
                    onClick={handleClose}
                >
                    CANCEL
                </FxButton>
            </Grid>}
            {onSubmit && submitButtonText && <Grid item>
                <FxButton
                    className="fx-button fx-button-theme"
                    id={`${id}-modal-submit`}
                    onClick={onSubmit}
                >
                  {submitButtonText}
                </FxButton>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Modal>
});
