/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Logger } from '../../../../libs/utils/logger';
import { JointTenancySignUp } from './JointTenancySignUp';

/**
 * Component: Signup join tenanct
 * Usage: User signup page
 */

Logger.debug("JointTenancySignUpWrapper.tsx", "Sign up joint tenancy initializing")

export const JointTenancySignUpWrapper: React.FC<any> = React.memo(() => {
         const source = {
        url: "/",
        method: "GET",
        data: {}
      } 
     return <JointTenancySignUp source={source} id={"joint-tenancy-user-signup"}/> });
