/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * file contains home level methods
 */
import { setVerificationStatus } from '../../components/Utils/CommonBaseClass';
import HttpClient from "../utils/httpClient";
import { getCustomerUrl } from "../utils/utils";
import UIFeatureImpl from './uiFeatures';

const httpClient = HttpClient.getClient();

/**
   * function to get customer details
   */
export async function getCustomerDetails() {
    const userTypeData: any = await httpClient.get(getCustomerUrl('/', false)).then(response => {
      return response.data
    })
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const jointTenancy = uiFeat.getJointTenancyFeature().available;

    if (businessCustomer) {
      setVerificationStatus(userTypeData?.business?.verification);
    }
    else if (jointTenancy) {
      setVerificationStatus(userTypeData?.verification);
    }
    else {
      setVerificationStatus(userTypeData?.individual?.verification);
    }
  }