import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CircularProgress, Divider, Grid } from '@mui/material';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { FxButton } from '../../Action/FxButton';
import { ReviewAgreementList } from './ReviewAgreementList';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import HttpClient from '../../../libs/utils/httpClient';
import { getKey } from '../../../libs/utils/storageManager';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxLink } from '../../Action/FxLink';

/**
 * This component handles the list of fee details list
 */


export const TreasureReviewAgreement: React.FC<any> = React.memo((props) => {

    ({ props } = RegisterComponent(props));
   
    const [btnDisabled, setBtnDisabled] = React.useState(true);
    const dispatch = useDispatch();    
    const [isLoading, setIsLoading] = useState(false);

    const { onChange } = props;

    /**
     * Method called before component loading
     * */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });     
        updateComponentParams(dispatch, props.id, { agreementDoc:[]});   
    }, [dispatch, props.id]);

    /**
     * Method to view terms and conditions file in new window
     */
    const gettermsandconditions = useCallback( (event:any) => {
        event.preventDefault();
        window.open('https://www.treasurefi.com/terms-of-use?_gl=1*e3r1i9*_gcl_au*OTEwNzAwNjc3LjE2OTI2NDA2NjA', '_blank');
    }, []);

    /**
     * Method to open privacy details window
     */
    const getprivacypolicy = useCallback( (event:any) => {
        event.preventDefault();
        window.open('https://www.treasurefi.com/privacy-policy?_gl=1*e3r1i9*_gcl_au*OTEwNzAwNjc3LjE2OTI2NDA2NjA', '_blank');
    }, []);

    const privacyPolicyData = [
        { 
            label: (<span>By submitting this form, you accept the <FxLink className={'fx-fee-agreement-link'} onClick={gettermsandconditions}><span className='fx-submit-agree-color'>terms and conditions</span></FxLink> to enable investments services. You also agree to <FxLink className={'fx-fee-agreement-link'} onClick={getprivacypolicy}><span className='fx-submit-agree-color'>privacy policy</span></FxLink> and receiving electronic communications regarding your account, and you certify that the information you provided is complete and accurate.</span>),
            value: 'yes'
        }
    ];

    const customerInfo = { ...props.data };
    const isLLC = customerInfo?.businessCategory === 'LLC' || customerInfo?.businessCategory === 'SINGLE_MEMBER_LLC';
    const treasureReviewAggreement = {
        "groupName": "Investment Advisor Agreements",
        "agreement": [
            {
                "name":"treasure_investment_management_agreement.pdf",
                "title": "Investment Management Agreement",
                "type": 'TREASURE_INVESTMENT_MANAGEMENT_AGREEMENT',
                "description": "Professional management of short term investments provided by an investment partner. Services not provided by Priority.",
                "isApproved": false,
                "url":"/assets/pdf/treasure_investment_management_agreement.pdf"
            },
            {
                "name":"treasure_entity_new_account_ria.pdf",
                "title": "Registered Investment Advisor Agreement",
                "type": 'TREASURE_REGISTERED_INVESTMENT_ADVISOR_AGREEMENT',
                "description": "Terms and Conditions of investment advisory services provided by RIA partner including fee disclosures. Services not provided by Priority.",
                "isApproved": false,
                "url":"/assets/pdf/treasure_entity_new_account_ria.pdf"
            },
            {
                "name": isLLC ? "treasure_entity_llc_agreement.pdf" : "treasure_entity_corporation_cash_account_agreement.pdf",
                "title":  isLLC ? "Treasure's entity LLC Agreement" : "Treasure's entity Corporation Cash Account Agreement",
                "type":  isLLC ? 'TREASURE_ENTITY_LLC_AGREEMENT' : 'TREASURE_ENTITY_CORPORATION_CASH_ACCOUNT_AGREEMENT',
                "description": isLLC ? "Agreement disclosing the business members and authorized signatories who will authorized and execute on behalf of the LLC.":"Agreement disclosing the business members and authorised signatories who will authorise and execute on behalf of the corporation.",
                "isApproved": false,
                "url": isLLC ? "/assets/pdf/treasure_entity_llc_agreement.pdf" : "/assets/pdf/treasure_entity_corporation_cash_account_agreement.pdf"
            }
        ]
    };

    /**
    * On accept t/c policy checkbox handler
    */
    const handleChange = (event: any) => {
        setBtnDisabled(!event.target.checked);
    }

    const agreementDoc = useMemo(() => {       
        return props?.agreementDoc || [];
    }, [props?.agreementDoc])

    const { register, handleSubmit, setValue, control } = useForm();

    const httpClient = HttpClient.getClient();

    /**
     * method to create api request and call signup api
     */
    const uploadReviewAgreement = async () => {
        const requestBody = {
            "investmentProvider": "TREASURE", // common name for advisor and broker???
            "signUpDetail": {
                "investmentObjective": (props.investmentObjective).toUpperCase(),
                "linkedDocument": [...agreementDoc]
            }
        }
        try {
            const data: any = await httpClient.post('/customer/id/' + getKey('customerId') + '/account/id/' + getKey("selectedAccount")?.id + '/portfolio/investmentProvider/signup', requestBody).then((response) => {
                return response
            })
                .catch((error) => {
                    return { ...error };
                })
            return data;
        }
        catch (e) { }
    }

    /**
     * method to handle On Submit of form
     */
    async function onSubmit() {
        setIsLoading(true)  
        let status = await uploadReviewAgreement();
        status = processAPIResponse(status)       

        if (status.status) {     
            setIsLoading(false)  
            props.onChange(5);
        } else {
            setIsLoading(false)  
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }    

    return (
        <Grid container item xs={12} direction="row" className='fx-review-agreement'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <ReviewAgreementList id="fee-ratecard-details" reviewAggreement={treasureReviewAggreement} agreementDoc={agreementDoc} />
                </Grid>
                <Grid container className='fx-review-agreement-check'>
                    <Grid item xs={12} className={'fx-review-agreement-section fx-submit-agree'}>
                        <FxCheckEdit 
                            register={{ ...register("privacyPolicyAccepted") }} 
                            // rules={{ required: "Please click Agree" }} 
                            control={control} onChange={(e: any) => { handleChange(e); }} 
                            id="privacy-policy-accepted" 
                            name="privacyPolicyAccepted" 
                            data={privacyPolicyData} 
                            className="fx-privacy-text" 
                            row="vertical" 
                            setValue={setValue} 
                            disabled={agreementDoc.length !== 3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <Divider />
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign={'right'} paddingBottom='4rem'>
                    <FxButton
                        variant='contained'
                        className='fx-button fx-button-cancel'
                        id="business-info-btn-back"
                        onClick={() => onChange(3)}
                    >
                        Back
                    </FxButton>
                    <span className="fx-padding-right-16" />
                    <FxButton
                        disableRipple={false}
                        className={btnDisabled ? "fx-button fx-button-theme-disabled" : "fx-button fx-button-theme"}
                        id={"create-external-account-form-card-save-button"}
                        type="submit"
                        disabled={btnDisabled}
                    >
                        {isLoading ? <CircularProgress size={20} /> : 'Submit'}
                    </FxButton>
                </Grid>
            </form>
        </Grid>
    )
})
