/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Snackbar, Link, Alert, SnackbarOrigin, AlertColor, SnackbarCloseReason } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxSnackBarView.tsx", "Fx Snack Bar initializing")

export type FxSnackBarViewProps = {
    open: boolean;
    /**
     * @default 6000
     */
    autoHideDuration: number;
    anchorOrigin: SnackbarOrigin;
    /**
     * @default 'error'
     */
    severity: AlertColor;
    text: string | string[];
    url?: string;
    linkText?: string;
    onClose: (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => void;
}

export const FxSnackBarView: React.FC<FxSnackBarViewProps> = (props) => {
    // Final message generation from text
    const finalMessage = useMemo(() => {
        if(typeof props.text === 'string') {
            return props.text;
        }
        return props.text?.map((message) => (
            <>
                {message}
                <br/>
            </>
        ));
    }, [props.text]);

    return (
        <Snackbar id='snack-bar'
            open={props.open}
            autoHideDuration={props.autoHideDuration}
            onClose={props.onClose}
            anchorOrigin={props.anchorOrigin}>
            <Alert severity={props.severity}
                variant={'filled'}
                onClose={props.onClose}
                action={
                    (props.linkText && props.url) && <Link href={props.url}  >
                        {props.linkText}
                    </Link>
                }>
                <div>
                    {finalMessage}
                </div>
            </Alert>
        </Snackbar>
    );
}
