import * as React from "react";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxDivider.tsx", "Fx Divider initializing")

/**
 * fx divider
 * @param props any
 */
export const FxDivider: React.FC<any> =
    (props: any) => {
        return (
            <div className="fx-divider"></div>
        )
    }