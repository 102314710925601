/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Logger } from '../../../libs/utils/logger';
import VirtualCardDetails from './VirtualCardDetails';

Logger.debug("ViewVirtualCardInfo.tsx", "View Virtual Card summary initializing");

/**
 * Component: View virtual card info
 * Usage: renders the view virtual card info from public url
 */
export const ViewVirtualCardInfo: React.FC<any> = React.memo(
    () => {
        const params = useParams<any>();
        const token = params.token;             

         //To get the virtual card detials
         const virtualCardSource = {
            url: 'virtualCard/details?token='+ token,
            method: "GET",
            baseUrl:true
        };

        return (
            <Grid id="view-virtual-card-info" item xs={12}>
                <VirtualCardDetails id="view-virtual-card-details-card" source={virtualCardSource} ></VirtualCardDetails>
            </Grid >
        )
    })
