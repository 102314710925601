/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Badge, BadgeProps } from"@mui/material";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxBadge.tsx", "Fx Badge initializing")

/**
 * Badge Component used for show information in the form of Badge
 */

interface FxBadgeProps extends Omit<BadgeProps, 'onClick'> {
    id?:any;
    value?: any;
    className?: any;
}

export const FxBadge: React.FC<FxBadgeProps> = React.memo(
    (props) => {

        return (
            <Badge {...props} 
                id={props?.id} 
                badgeContent={props?.value}
                className={props?.className ? props?.className : "fx-badge"}
            >
                {props?.children}
            </Badge>
        )
    })
