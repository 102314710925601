import React from 'react'; // we need this to make JSX compile
import { Grid, IconButton, Modal, Typography, Divider, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useMediaQuery } from "react-responsive";
import { FxTextEdit } from '../../Input';
import { useForm } from 'react-hook-form';
import { FxButton } from '../../Action/FxButton';
import { getKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch } from 'react-redux';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
const httpClient = HttpClient.getClient();

Logger.debug("ViewAccountBalanceDetails.tsx", "View Balance Detail Modal initializing")

/**
 * This component handles the redeem amount
 */
export const FxRedeemAmountModal: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })
        const currentInvestmentValue =  props?.portfolioData?.investmentProvider[0]?.currentInvestmentValue || 0;

        /**
         * Method handles the onclick event for closing modal
         */
        const handleClose = () => {
            if (props.onClose) {
                props.onClose();
            }
        };

        const { register, formState: { errors }, handleSubmit, control } = useForm();
        const dispatch = useDispatch();

        /**
         * onsubmit function for redeem form
         * @param data 
         */
        async function onSubmit(data: any) {
            let req = {
                "investmentProvider": "TREASURE",
                "amount": data?.redeemAmount
            }
            let status: any;
            status = await redeem(req);
            status = processAPIResponse(status);

            if (status.status) {
                handleRedeemClose();
                updateComponentParams(dispatch, 'investment-summary-card', { redeemSuccess: true, redeemSuccessMessage:{ amount:req?.amount}});
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'investment-instruction-list' } }); 
            } else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

        /**
         * Api call for redeem entity
         */
        async function redeem(req: any) {
            try {
                const data = await httpClient.post(getCustomerUrl('account/id/' + getKey('customerInitialAccount')?.id + '/portfolio/redeem'), req).then(response => {
                    return response;
                }).catch(error => {
                    return { ...error };
                })
                return data;
            } catch (err) {
                return err;
            }
        }

        /**
         * function for closing the modal
         */
         const handleRedeemClose = () => {
            props.onClose()
        };

        return (
            <Grid container className={"fx-container"}>
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={props.open}
                    onClose={handleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className={isTabScreen ? "fx-modal fx-modal-medium" : 'fx-modal fx-modal-small2medium'}
                >
                     <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-content fx-redeem-now-card" >
                        <div className="modal-body">
                            <FxCard className="fx-theme-passport">
                                <FxCardHeader>
                                    <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Grid item container xs={12} justifyContent="flex-start">
                                            <Typography variant="h2" className='fx-header-title'>
                                                Redeem
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Grid className="fx-modal-close-icon" item xs={1}>
                                            <IconButton
                                                onClick={handleClose}
                                                size="large">
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody className="fx-info-card" >
                                    <Grid container direction="column" spacing={2} className="fx-modal-form flex column" >
                                        <Grid item container xs={12} className='fx-investment-amount'>   
                                            <Grid item xs={12} className='fx-form-edit-profile'>
                                                <FxTextEdit
                                                    register={{ ...register("redeemAmount") }}
                                                    className={errors.redeemAmount ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                    control={control}
                                                    rules={{required: true}}
                                                    valuePattern={/-|\+|e|E/}
                                                    onWheel={(e:any)=>e.target.blur()}
                                                    id="fx-redeem-card-gross-revenue"
                                                    label="Amount*"
                                                    type="number" 
                                                    name="redeemAmount"
                                                    variant="outlined"
                                                    placeholder='0.00'
                                                />
                                            </Grid>
                                            <FormHelperText> Current Investment Value &nbsp;<FxCurrencyView value={currentInvestmentValue} prefix={"$"} /> </FormHelperText>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Typography variant="h5" className='fx-header-title fx-info-text'>
                                                By clicking on redeem now you authorise passport to send ypur redeem instruction to Treasure.
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Typography variant="h5" className='fx-header-title fx-info-text'>
                                                Note: Upon redeem the remaining amount will be rebalanced according to your investment portfolio allocations.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid container columnSpacing={2} justifyContent='end'>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-cancel"
                                                    title='Invest Now'
                                                    id="invest-now-button"
                                                    onClick={handleRedeemClose}
                                                >
                                                    CANCEL    
                                                </FxButton>   
                                            </Grid>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-theme"
                                                    title='Redeem'
                                                    id="redeem-button"
                                                    type="submit"
                                                >
                                                    REDEEM NOW
                                                </FxButton>    
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                </FxCardBody>
                            </FxCard>
                        </div>
                    </div>
                    </form>
                </Modal>
            </Grid>
        );
    })