/* eslint-disable  @typescript-eslint/no-explicit-any */  
import { Grid } from "@mui/material";
import React from "react";
import { FxCurrencyView } from "../../components/Input/FxCurrency/FxCurrencyView";
import { ReactComponent as LeftDownArrow } from '../../assets/svg/arrow-left-downbig.svg';
import { ReactComponent as RightUpArrow } from '../../assets/svg/arrow-right-topbig.svg';

/**
* @param item : Transaction detail
* @param selectedAccountId : Account id of the selected account
* @returns : JSX for transaction positive or negative amount
*/
export function getTransactionAmountJSX(item: any, selectedAccountId: any) {
    const negativeAmountJSX = <Grid item xs>
        <Grid><FxCurrencyView value={item?.amount} justifyContent="flex-end" prefix={"$"} showMinus /></Grid>
    </Grid>;
    const positiveAmountJSX = <Grid item xs>
        <Grid><FxCurrencyView value={item?.amount} justifyContent="flex-end" prefix={"$"} showPlus /></Grid>
    </Grid>;

    if (item?.method === 'BOOK') {
        return (selectedAccountId === item.source?.account?.id) ? negativeAmountJSX : ((selectedAccountId === item.destination?.account?.id) && positiveAmountJSX);
    } else {
        return (item.source?.account?.id) ? negativeAmountJSX : positiveAmountJSX;
    }
}

/**
* @param item : Transaction detail
* @param selectedAccountId : Account id of the selected account
* @returns : JSX for transaction positive or negative amount
*/
export function getMobileViewTransactionAmountJSX(item: any, selectedAccountId: any) {
    const negativeAmountJSX = <FxCurrencyView value={item.amount} justifyContent="flex-end" showMinus prefix={"$"}/>;
    const positiveAmountJSX = <FxCurrencyView value={item.amount} justifyContent="flex-end" showPlus prefix={"$"}/>;

    if (item?.method === 'BOOK') {
        return (selectedAccountId === item.source?.account?.id) ? negativeAmountJSX : ((selectedAccountId === item.destination?.account?.id) && positiveAmountJSX);
    } else {
        return (item.source?.account?.id) ? negativeAmountJSX : positiveAmountJSX;
    }
}

/**
* @param item : Transaction detail
* @param selectedAccountId : Account id of the selected account
* @returns : JSX for transaction Arrows
*/
export function getTransactionsArrowJSX(item: any, selectedAccountId: any) {
    const leftDownArrow = <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></Grid></Grid>;
    const rightUpArrow = <Grid item container xs={12} ><Grid className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></Grid></Grid>;
    if (item?.method === 'BOOK') {
        return (selectedAccountId === item.source?.account?.id) ? rightUpArrow : ((selectedAccountId === item.destination?.account?.id) && leftDownArrow);
    } else {
        return (item.source?.account?.id) ? rightUpArrow : leftDownArrow;
    }
}

/**
* @param item : Transaction detail
* @param selectedAccountId : Account id of the selected account
* @returns : JSX for transaction Arrows
*/
export function getMobileViewTransactionsArrowJSX(item: any, selectedAccountId: any) {
    const leftDownArrow = <div className={"fx-passport-debit-credit-svg"} ><LeftDownArrow /></div>;
    const rightUpArrow = <div className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></div>;
    if (item?.method === 'BOOK') {
        return (selectedAccountId === item.source?.account?.id) ? rightUpArrow : ((selectedAccountId === item.destination?.account?.id) && leftDownArrow);
    } else {
        return (item.source?.account?.id) ? rightUpArrow : leftDownArrow;
    }
}