import React, { useEffect, useState } from 'react'
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("ValidateExternalAccount.tsx", "validate external account initializing");

/**
 * Component: ValidateExternalAccount
 * Usage: For external account validation
 */
export const ValidateExternalAccount: React.FC<any> = React.memo(
    (props) => {

        let externalAccountData: any;
        const submitButton: any = 'Validate';
        const { register, handleSubmit, setValue, control } = useForm();
        const [isEdit, setIsEdit] = useState(false);
        const [ews, setEWS] = useState(false);
        const [preNote, setPreNote] = useState(false);
        const [ewsDefault, setEwsDefault] = useState(false);

        let context: any;
        ({ context, props } = RegisterComponent(props));
        externalAccountData = context?.data?.body;
        /**
         * Set the default data
         */
        if (context?.data?.body && !isEdit) {
            if (externalAccountData && externalAccountData.validateAccount) {
                // eslint-disable-next-line array-callback-return
                externalAccountData.validateAccount.map((item: any) => {
                    if (item.ews) {
                        setEWS(true);
                        setEwsDefault(true);
                    }
                })
            }
            if (externalAccountData && externalAccountData.prenote) {
                if(externalAccountData?.prenote?.prenoteValidation === 'ALWAYS')
                        setPreNote(true);
            }
            setIsEdit(true)
        }
        const dispatch = useDispatch()
        /**
         * Method to reset the component on unmount.so that when redirect back page it will call the api again
         * */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory();

        const ewsData = [
            { value: "EWS", label: "EWS", checked: ews }
        ]
        const prenoteData = [
            { value: "PRENOTE", label: "Prenote", checked: preNote }
        ];
        /**
         * Method to handle change event
         * @param event :event
         */
        const handleChange = (event: any) => {
            if (event.target.name === 'ews') {
                setEWS(event.target.checked);
            } if (event.target.name === 'prenote') {
                setPreNote(event.target.checked);
            }

        }

        async function onSubmit(data: any) {
            let req: any;
            let status: any;
            req = {
                    "validateAccount": [
                        {
                         ews
                        }
                    ]
            }
            if (data.id) {
                req.id = data.id;
            }
            if(data.prenote?.includes("PRENOTE"))
            {
                req['prenote'] = "ALWAYS"
            }
            else{
                req['prenote'] = "NEVER"
            }

            status = await validateExternalAccount(req);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'External Account Validation updated Successfully!',
                });
                history.push('/dashboard')
                setTimeout(()=>{
                    history.push('/externalaccount/view/' +externalAccountData['id'] )
                },100)

            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        /**
         * Method to call external account API to validate EA
         * @param payloadData : request payload
         */
        async function validateExternalAccount(payloadData: any) {
            try {
                const url =  'externalAccount/id/' + externalAccountData['id']
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("ValidateExternalAccount.tsx", "error", err);
                return false;
            }
        }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {isEdit && <form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="validate-ea-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-ea-modal" variant="h4">
                                            Validate Account
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="ea-create-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2}  >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <FxCheckEdit register={{ ...register("ewsValidation") }} disabled={ewsDefault} name={'ews'} id={'ews'} data={ewsData} control={control} onChange={handleChange} setValue={setValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FxCheckEdit  register={{ ...register("prenoteValidation") }} name={'prenote'}  id={'prenote'} control={control} data={prenoteData} onChange={handleChange} setValue={setValue} />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="ea-modal"
                                        onClick={() => props.close()}>
                                        Cancel
                                    </FxButton>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id={submitButton + "external-account"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                                submitButton
                                            )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>


                    </form>}
                </div>
            </div>
        );
    });
