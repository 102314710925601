import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Logger } from "../../libs/utils/logger";
const cssFxCardHeader = 'fx-card-header';

Logger.debug("FxCardHeader.tsx", "Fx Card Header initializing")

/**
 * <FxCardHeader> component 
 * using in header area
 * @param props 
 */
const FxCardHeader: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = props => {
  // wire the state to component if any
  const state = useSelector((state: any) => {
    if (props.id && state.context[props.id]) {
      return state.context[props.id];
    } else {
      return { config: { params: {} } };
    }
  });
  //merge props
  if (state.config.components) {
    props = { ...props, ...state.config.params }
  }
  return (
    <div id={props.id} className={`${cssFxCardHeader} ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  )
}
export default FxCardHeader;
