import React, { useEffect } from 'react'
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { clean, cleanWithEmptyValue, convertToPhoneRequestFormat, findAddressValidations, getEAOrAddressDetailsFromContact, handleKeyDownChange, handleZipChange, renderCityNameError, renderEAAddressLine1Error, renderEAAddressLine2Error, renderError, setCityNameValidation, setPhoneValidationOptions, setZipValidationOptions } from '../../Utils/CommonBaseClass';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import usa_state from '../../Utils/usa_states.json';

const httpClient = HttpClient.getClient();
interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("EditExternalAccountCard.tsx", "update external account initializing");

// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: EditExternalAccountCard
 * Usage: Update External Account
 */
export const EditExternalAccountCard: React.FC<any> = React.memo((props) => {
        let context: any;

        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, control, watch, setError, clearErrors } = useForm();
        const params = useParams<any>();
        let edit_ea_data: any;
        let contact_data: any
        const eaId = params.id
        if (context && context?.data && context?.data?.body) {
            contact_data =  { ...context.data.body }
            edit_ea_data ={...getEAOrAddressDetailsFromContact(contact_data,"EA", props?.eadetails?.eaId,false)};
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit External Account **' +edit_ea_data?.accountNumberLast4, backButton: `/payee/id/${props?.eadetails?.contactId}/externalaccount/view/${eaId}`} } });

        }

          /**
         * reset the component on unmount.so that when redirect back page it will call the api again
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        const [isLoading, setIsLoading] = React.useState(false);
        const submitButton = 'Save Changes';
        const history = useHistory();

        const renderNameError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            history.push(`/payee/id/${props?.eadetails?.contactId}/externalaccount/view/${eaId}`)
        };

        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            req = updateEARequest(data)
            const successMessage = 'External Account Updated Successfully!';
            status = await updateEA(clean(req));
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(`/payee/id/${props?.eadetails?.contactId}/externalaccount/view/${eaId}`)
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsLoading(false);

        }

        /**
        * Method to get the Outside passport request
        * @param data
        * @returns
        */
        function updateEARequest(data: any) {
            const object: any = {};
            let holderAddress = {
                "addressLine1": data?.addressLine1,
                "addressLine2": data?.addressLine2,
                "city": data?.city,
                "state": data?.state,
                "zip": data?.zip
            }
            cleanWithEmptyValue(holderAddress);
            const externalAccountRequest: any = {
                holderName: data?.holderName,
                id: eaId,
                holderPhone:data?.holderPhone&&convertToPhoneRequestFormat(data?.holderPhone)
            }
            if (holderAddress && Object.keys(holderAddress).length > 0 && Object.values(holderAddress).some(value => value !== null)) {
                externalAccountRequest['holderAddress'] = holderAddress;
            }
            cleanWithEmptyValue(externalAccountRequest);
            if (data.eaPurpose && data.eaPurpose !== '') {
                externalAccountRequest.purpose = data.eaPurpose;
            }
            if (data.accountValidation) {
                const validationObject: any = {}
                const preNoteObject:any={}
                if (data.accountValidation === "PRENOTE") {
                    preNoteObject.prenote= "ALWAYS";
                }
                else {
                    validationObject.ews = true;
                }
                externalAccountRequest.validateAccount = [];
                externalAccountRequest.validateAccount.push(validationObject)
                externalAccountRequest['prenote'] = preNoteObject.prenote
            }
            object.externalAccount = [externalAccountRequest];
            return object
        }

        /**
         * Method to call api  for create externalAccount
         * @param payloadData : request payload
         */
        async function updateEA(payloadData: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('contact/id/'+ props?.eadetails?.contactId,false), payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateContact.tsx", "error", err);
                return err;
            }
        }


     // ea validation data

        const EAValidation = [
            { label: 'Instant Verification', value: "EWS" },
            { label: 'Prenote', value: "PRENOTE" }
        ];
        return (
            <Grid container id="edit-payee-ea-main-grid" xs={12} className='fx-form-grid' >
                <Grid id="edit-payee-ea-first-grid" item xs={12} sm={8}>
                    <Grid id="edit-payee-ea-sub-grid" container spacing={1} >
                        <Grid id="edit-payee-ea-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {edit_ea_data && <><form id="edit-payee-ea-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="edit-payee-ea-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="edit-payee-ea-form-card-header">
                                        </FxCardHeader>
                                        <FxCardBody id="edit-payee-ea-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {<>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="payee-ea-edit-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined" defaultValue={edit_ea_data?.holderName ? edit_ea_data?.holderName : ''} />
                                                        <div className={'error-message'} >
                                                            {errors.holderName && renderNameError(errors.holderName)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxPhoneEdit register={register } className={errors.holderPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-external-account-form-card-holderphone-textbox" label="Holder Phone" name="holderPhone" variant="outlined" 
                                                        rules={setPhoneValidationOptions(false)} disableFlag={true}
                                                        setValue={setValue} defaultValue={edit_ea_data?.holderPhone ? edit_ea_data.holderPhone : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderPhone && renderNameError(errors.holderPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item  xs={12} sm={12} spacing={2}>
                                                        <fieldset className='fx-create-contact-address-container'>
                                                        <legend>Address Details</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: findAddressValidations(watch),maxLength:35 }} id="create-contact-form-card-addressline1-textbox" label="Address Line 1" name="addressLine1" variant="outlined" defaultValue={edit_ea_data?.holderAddress?.addressLine1 ? edit_ea_data.holderAddress.addressLine1 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine1 && renderEAAddressLine1Error(errors.addressLine1)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false,maxLength:35 }} id="create-contact-form-card-addressline2-textbox" label="Address Line 2" name="addressLine2" variant="outlined" defaultValue={edit_ea_data?.holderAddress?.addressLine2 ? edit_ea_data.holderAddress.addressLine2 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine2 && renderEAAddressLine2Error(errors.addressLine2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setCityNameValidation(findAddressValidations(watch))} id="create-contact-form-card-city-textbox" label="City" name="city" variant="outlined" defaultValue={edit_ea_data?.holderAddress?.city ? edit_ea_data.holderAddress.city : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: findAddressValidations(watch) }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-contact-form-card-state-textbox" name="state" data={usa_state} label="State" value={edit_ea_data?.holderAddress?.state ? edit_ea_data.holderAddress.state : ''}
                                                            setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(findAddressValidations(watch))} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-contact-form-card-state-textbox" name="zip" label="Zip" defaultValue={edit_ea_data?.holderAddress?.zip ? edit_ea_data.holderAddress.zip : ''} isEditable={true} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors); }} onKeyDown={(e: any) => {  handleKeyDownChange(e, setValue);}}/>
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                        </fieldset>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("eaPurpose") }} className={errors.eaPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="payee-ea-edit-purpose-textbox" label="Purpose" name="eaPurpose" variant="outlined"  setValue={setValue}
                                                            defaultValue={edit_ea_data?.purpose ? edit_ea_data?.purpose : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountValidation") }} rules={{ required: true }} className={errors.accountValidation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-ea-edit-account-validation" name="accountValidation" data={EAValidation} label="Validate Account *" value="EWS" setValue={setValue} defaultValue={edit_ea_data?.accountValidation ? edit_ea_data?.accountValidation : ''} />
                                                    </Grid>

                                                </>
                                                }
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="edit-payee-ea-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payee-ea-edit-cancel"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"payee-edit-ea-submit-button"}
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>

                                </form></>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });