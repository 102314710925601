import { useParams } from "react-router-dom";
import { CreateFundingRule } from "../CreateFundingRule";
import React from "react";
/**
 * Component: Edit Funding Rule
 * Usage: To Edit a previously existing Funding Rule
 */
export const EditFundingRuleAction = () => {
  interface RouteParams {
    id: string;
  }
  const params = useParams<RouteParams>();
  /**
   * get ID from  url
   */
  const id = params.id;
  /**
   * funding rule details API
   */
  const user_source = {
    url: "fundingRule/id/" + id,
    method: "GET",
  };
  const rand = Math.floor(Math.random() * 100) + 1;
  return (
    <>
      <CreateFundingRule id={rand + "Edit-funding-rule" + id} source={user_source} isFundingRuleEdit={true} ruleId={id}/>
    </>
  );
};
