import React, { CSSProperties, useState } from 'react'; // we need this to make JSX compile
import clsx from 'clsx';
import { useTheme, Drawer, Tooltip, ListItem, Grid, List, AppBar, Toolbar, CssBaseline, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AiOutlineDownload } from "react-icons/ai";
import { isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { ReactComponent as LogoPriority } from '../../assets/svg/Priority.svg';
import { ReactComponent as LogoPassport } from '../../assets/svg/logo/logo-passport.svg';
import { ReactComponent as OverViewIcon } from '../../assets/svg/House.svg';
import { ReactComponent as SchedulesIcon } from '../../assets/svg/Calendar.svg';
import { ReactComponent as TransactionIcon } from '../../assets/svg/Receipt.svg';
import { ReactComponent as AccountsIcon } from '../../assets/svg/UserList.svg';
import { ReactComponent as ExternalAccountsIcon } from '../../assets/svg/Bank.svg';

/**
 * Default layout
 */

const drawerWidth = 280;

//define styles
const useStyles = makeStyles()((theme) =>{
    return {
        root: {
            display: 'flex',
            width: '100%'
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 0,
            marginLeft: "-20px",
            top: "-10px"
        },
        hide: {
            display: 'none',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 44,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 28,
            },
        },
        toolbar: {
            marginTop: '-60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar as CSSProperties,
        },
        content: {
            flexGrow: 1,
            marginTop: -30,
        },
    }});

export const LayoutDefault: React.FC<any> =
    (props: any) => {

        const location = useLocation();
        const { classes } = useStyles();
        const theme = useTheme();
        const [open, setOpen] = useState(false);

        const [openTB, setOpenTB] = useState(false);

        const toggleDrawer = (event: any) => {
            if (
                event.type === "keydown" &&
                (event.key === "Tab" || event.key === "Shift")
            ) {
                return;
            }
            setOpenTB(!openTB);
        };


        const handleDrawer = () => {
            isMobile ? setOpenTB(false) : open ? setOpen(false) : setOpen(true);

        };
        const menuItems = [{ text: 'Overview', url: '/', id: 'overview' },
            { text: 'Transactions', url: '/transactions/collect', id: 'schedulelist' },
            { text: 'Payees', url: '/payees', id: 'payees' },
            { text: 'Payers', url: '/payers', id: 'payers' },
            { text: 'Accounts', url: '/accounts', id: 'accounts' },
            { text: 'External Accounts', url: '/externalaccounts', id: 'external-accounts' },
            { text: 'Transaction History', url: '/accountledgers', id: 'transaction-history' },
            { text: 'Downloads', url: '/downloads', id: 'downloads' },
            { text: 'Approvals', url: '/approvals', id: 'approvals' }
            // {text:'Profile',url:'/profile',id:'profile'}
        ]


        //drawer
        const drawer =
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className="portalIcon"><IconButton onClick={handleDrawer} size="large">
                    {open === false && <LogoPriority className="portal-menu-Icon" />}
                    <div className="fx-logo-passport-menu-icon">{open === true && <LogoPassport />}</div>
                </IconButton></div>
                <div className={classes.toolbar}>
                    {open && <IconButton className='iconButton-margin' onClick={handleDrawer} size="large">
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>}
                </div>


                <List className="fx-menu">
                    {menuItems.map((key, index) => {

                        return <ListItem className={"fx-menu-design" + (matchURL(location.pathname, key.url) === true ? ' fx-menu-design-selected ' : '')} component="a" href={key.url} button key={key.id} id={key.id}>
                            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                            {index === 0 && <Tooltip title="Overview"><ListItemIcon> <OverViewIcon /></ListItemIcon></Tooltip>}
                            {index === 1 && <Tooltip title="Schedules"><ListItemIcon> <SchedulesIcon /></ListItemIcon></Tooltip>}
                            {index === 2 && <Tooltip title="Payees"><ListItemIcon> <AccountBalanceWalletOutlinedIcon /></ListItemIcon></Tooltip>}
                            {index === 3 && <Tooltip title="Payers"><ListItemIcon> <AccountTreeOutlinedIcon /></ListItemIcon></Tooltip>}
                            {index === 4 && <Tooltip title="Accounts"><ListItemIcon> <AccountsIcon /></ListItemIcon></Tooltip>}
                            {index === 5 && <Tooltip title="External Accounts"><ListItemIcon> <ExternalAccountsIcon /></ListItemIcon></Tooltip>}
                            {index === 6 && <Tooltip title="Transaction History"><ListItemIcon> <TransactionIcon /></ListItemIcon></Tooltip>}
                            {/* {index === 5 && <Tooltip title="Profile"><ListItemIcon> <ProfileIcon /></ListItemIcon></Tooltip>} */}
                            {index === 7 && <Tooltip title="Downloads"><ListItemIcon className="fx-bulk-download-icon"> <AiOutlineDownload /></ListItemIcon></Tooltip>}
                            <ListItemText primary={open && key.text} />
                        </ListItem>
                    })}
                </List>
            </Drawer>

        //return the design
        return (
            <Grid container className ="grid">
                <React.Suspense fallback="Loading...">
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: open,
                            }, "fx-appbar-top")}
                        >
                            {isMobile && !openTB &&
                                <Toolbar >
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={toggleDrawer}
                                        className={classes.menuButton}
                                        size="large">
                                        <MenuIcon />
                                    </IconButton>
                                </Toolbar>
                            }
                        </AppBar>
                        {isMobile ? openTB ? drawer : '' : drawer}
                        <main className={classes.content}>
                            {props.routes}
                        </main>
                    </div>


                </React.Suspense>
            </Grid>
        );
    }
/**
 * function using to highlight the menu based on url
 * @param path
 * @returns the match index
 */

function matchURL(path: string, url: string) {
    let match = false;
    if (path === url) {
        match = true
    }
    else if (url === "/accounts" && path.includes("accountdetails/") && !(path.includes("externalaccountdetails/"))) {
        match = true
    }
    else if (url === "/externalaccounts" && path.includes("externalaccountdetails/")) {
        match = true
    }
    else if (url.includes("schedules/") && path.includes("schedules/")) {
        match = true
    }

    return match;
}