import React from "react";
import { Grid, Typography } from "@mui/material";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add-new.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { FxTextEdit } from "../../../Input";

/**
 * Component: Funding Rules Criteria Section
 * Usage: To display Criteria Section of Funding Rules Creation Screen
 */
export const FxFundingRuleCriteriaCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const paramOptions = [
    {
      label: "Tag(s)",
      value: "tags"
    }
  ];

  const operatorOptions = [
    {
      label: "Equal To",
      value: "in",
    }
  ];

  /**
   * Method to handle on change of Param field
   * @param event
   */
  const handleParamChange = (event: any) => {
    props.setValue(`funding-rule-criteria.${props.index}.operator`, 'in');
  };

  return (
    <Grid
      container
      item
      direction="row"
      className="fx-form-edit-profile"
      spacing={2}
      id={"add-funding-rule-criteria" + props.index}
      key={"criteria" + props.index}
    >
      <Grid
        item
        xs={0}
        display={"inline-flex"}
        alignItems={"center"}
        maxWidth={"fit-content"}
      >
        <Typography className="fx-summary-title" variant="h3">
          {props.index + 1}.
        </Typography>
      </Grid>
      <Grid item xs={7} display={'inline-flex'} justifyContent={"space-between"}>
        <Grid item xs>
          <FxMaterialSelect
            register={{
              ...props.register(`funding-rule-criteria.${props.index}.parameter`),
            }}
            control={props.control}
            className={
              props.errors.parameter
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            rules={{ required: true }}
            id={props.index + "-" + props.index + "-funding-rule-parameter"}
            name={`funding-rule-criteria.${props.index}.parameter`}
            data={paramOptions}
            label="Select Parameter"
            setValue={props.setValue}
            placeholder="Select"
            value={'tags'}
            onChange={handleParamChange}
          />
        </Grid>
        <Grid item xs marginLeft={'1rem'}>
          <FxTextEdit
            register={{
              ...props.register(`funding-rule-criteria.${props.index}.tagInput`),
            }}
            className={
              props.errors.tagInput
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            id={`funding-rule-criteria.${props.index}.tagInput`}
            name={`funding-rule-criteria.${props.index}.tagInput`}
            control={props.control}
            rules={{ required: true }}
            label="Tag Name*"
            setValue={props.setValue}
            defaultValue={props?.value?.key ? props?.value?.key?.split('.')[1] || "" : ""}
          />
        </Grid>
        <Grid item xs marginLeft={'1rem'}>
          <FxMaterialSelect
            register={{
              ...props.register(`funding-rule-criteria.${props.index}.operator`),
            }}
            control={props.control}
            className={
              props.errors.operator
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            rules={{ required: true }}
            id={props.index + "-" + props.index + "-funding-rule-operator"}
            name={`funding-rule-criteria.${props.index}.operator`}
            data={operatorOptions}
            label="Operator"
            setValue={props.setValue}
            value={'in'}
            placeholder="Select"
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <FxTextEdit
          register={{
            ...props.register(`funding-rule-criteria.${props.index}.value`),
          }}
          className={
            props.errors.value
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          id={`funding-rule-criteria.${props.index}.value`}
          name={`funding-rule-criteria.${props.index}.value`}
          control={props.control}
          rules={{ required: true }}
          label="Tag Value*"
          setValue={props.setValue}
          defaultValue={props?.value?.value ? props?.value?.value : ""}
        /> </Grid>
      <Grid
        item
        xs={"auto"}
        spacing={2}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-delete-condition-btn"}
            title="Delete"
            className={`fx-button fx-approval-button-icon fx-condition-btn`}
            variant="contained"
            onClick={props.handleRemove}
            startIcon={<DeleteIcon />}
          />
        </Grid>
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-add-condition-btn"}
            title="Add"
            className={`fx-button fx-approval-button-icon fx-condition-btn ${props.isAddButtonDisabled ? "fx-button-disabled" : ""
              }`}
            disabled={props.isAddButtonDisabled}
            variant="contained"
            onClick={props.handleAppend}
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
