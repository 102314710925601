import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DataGrid } from "../../../Data/DataGrid";
import FxCard from "../../../Container/FxCard";
import FxCardHeader from "../../../Container/FxCardHeader";
import FxCardBody from "../../../Container/FxCardBody";
import { IPrimaryFilterData } from "../../../Data/FxPrimaryFilter";
import { ViewProfile } from "../../Index/Passport/ViewProfile";
import {
  updateComponentParams,
} from "../../../Utils/CommonBaseClass";
import {
  IFilterColumns,
  IDataGridProps,
  IFilterFields,
} from "../../../../types/common.interfaces";
import { Filter } from "../../../Data/Filter";
import { Logger } from "../../../../libs/utils/logger";
import { FxPrimaryFilter } from "../../../Data/FxPrimaryFilter";
import { FxGridList } from "../../../Data/FxGridList";
import { useIsMobileOrTabletScreen } from "../../../../libs/utils/utils";

/**
 * Component: CustomerList
 * Usage: For Customer listing
 */

export const CustomerList: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSmallScreen = useIsMobileOrTabletScreen();


   /**
   * useEffect
   */
  useEffect(() =>{
    //component reset while initalising
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: { title: "Customers" },
      },
    });
    return ()=>{
      //filter reset
      updateComponentParams(dispatch, 'partner-customer-filter', { resetFilter: true });
    }
  },[dispatch,props.id])


  /**
   *   API for customer list
   */
  const src = {
    url: "/associatedCustomerList",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        sortBy: "createdOn",
        sortOrder: "desc",
      },
      criteria: {
        filters: [],
      },
    },
  };
  

  /**
   *   customer list columns
   */
  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "ID",
        enable: true,
        fieldName: "id",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "8rem" },
      },
      {
        label: "Type",
        enable: true,
        fieldName: "type",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "Name",
        enable: true,
        fieldName: "legalName",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "20rem" },
      },
      {
        label: "Status",
        enable: true,
        fieldName: "status",
        type: "fri://fieldtype/status",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "CIP Status",
        enable: true,
        fieldName: "cipStatus",
        type: "fri://fieldtype/status",
        default: true,
        style: { width: "10%" },
      },
      {
        label: "OFAC Status",
        enable: true,
        fieldName: "ofacStatus",
        type: "fri://fieldtype/status",
        default: true,
        style: { width: "10%" },
      },
      {
        label: "Created On",
        enable: true,
        fieldName: "createdOn",
        type: "fri://fieldtype/date",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "Last Updated On",
        enable: true,
        fieldName: "lastUpdatedOn",
        type: "fri://fieldtype/date",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "Last Updated By",
        enable: true,
        fieldName: "lastUpdated",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "15rem" },
      },
    ],
  };

  /**
   *   configuring grid column
   */
  const gridColumn: any = {
    title: "Select Columns to Show",
    columns: [
      {
        enable: true,
        fieldName: "header",
        type: "fri://fieldtype/header",
        xs: "12",
      },
      {
        enable: true,
        fieldName: "description",
        type: "fri://fieldtype/custom",
        xs: "12",
        alignItems: "center",
      },
      {
        enable: true,
        fieldName: "divider",
        type: "fri://fieldtype/custom",
        xs: "12",
      },
    ],
  };

  /**
   * Method to redirect to the customer details page
   * @param event :event object
   * @param data : customer data
   */
  function redirectDetailsPage(event: any, data: any) {
    try {
      const url = isSmallScreen
        ? `/customers/details/${data?.data?.id}`
        : `/customers/details/${data?.id}`;
      history.push(url);
    } catch (e) {}
  }

  /**
   * dataGrid props
   */
  const dataGridProps: IDataGridProps = {
    id: "partner-customer-card-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  /**
   * filter fields
   */
  const filterFields: IFilterFields = {
    "fields": [
        {
            "label": "Type",
            "fieldName": "type",
            "type": "fri://fieldtype/checkbox",
            "className": "filter-divider filter-tri-row-checkbox-divider",
            "data": [
                { "label": "BUSINESS", "value": "BUSINESS", "checked": false },
                { "label": "INDIVIDUAL", "value": "INDIVIDUAL", "checked": false },
            ]
        },
        {
          "label": "STATUS",
          "fieldName": "status",
          "type": "fri://fieldtype/checkbox",
          "data": [
            { label: "Active", value: "ACTIVE"},
            { label: "Suspended", value: "SUSPENDED" },
            { label: "Dormant", value: "DORMANT"},
            { label: "Terminated", value: "Terminated" }
          ],
        },
        
        {
          "label": "Created On",
          "toLabel": "To",
          "fieldName": "createdOn",
          "type": "fri://fieldtype/daterange",
          "data": [],
          "defaultValue": false
        },
        {
          "label": "Last Updated On",
          "toLabel": "To",
          "fieldName": "lastUpdatedOn",
          "type": "fri://fieldtype/daterange",
          "data": [],
          "defaultValue": false
  
        }
    ]
}

  /**
   * Method to transform data
   * @param data : customer data
   */
  function transformCustomerList(data: any) {
    try {
      let newData: any = [];
      // eslint-disable-next-line array-callback-return
      data?.map((item: any, i: number) => {
        newData[i] = { ...data[i] }
        newData[i].type=item.type
        newData[i].legalName=item?.business?.legalName? item?.business?.legalName:`${item?.individual?.firstName ||''} ${item?.individual?.middleName ||''} ${item?.individual?.lastName ||''}`
        newData[i].lastUpdated=item?.lastUpdatedBy?.username
        newData[i].ofacStatus=item?.business ? (item?.business?.verification?.ofacStatus ? item?.business?.verification?.ofacStatus : '') :
                              (item?.individual ? (item?.individual?.verification?.ofacStatus ? item?.individual?.verification?.ofacStatus : '') : '')
        newData[i].cipStatus=item?.business ? (item?.business?.verification?.cipStatus ? item?.business?.verification?.cipStatus : '') :
                              (item?.individual ? (item?.individual?.verification?.cipStatus ? item?.individual?.verification?.cipStatus : '') : '')
    });
      return newData;
    } catch (e) {
      Logger.error("CustomerList.tsx", "error", e);
      return false;
    }
  }

  /**
   * @param data : create customer grid mobile
   */
  const createCustomerGridItem = (item: any) => (
    <Grid item container xs={12} className="fx-grid-list-content">
      <Grid item container xs={12} alignItems="center">
        <Grid item container xs={12}>
          <Grid item xs={7} sm={6}>
            <Grid className="fx-info-label-value">
              <Typography> {item?.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  /**
   * Divider
   */
  const createCustomerDivider = () => (
    <Grid
      item
      container
      xs={12}
      alignItems={"center"}
      className="fx-divider-grid-list"
    >
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );

  /**
   * Method to transform data for mobile screen
   * @param data: Customer list
   * @returns :Grid Transformed data
   */
  const customerListGridTransformner = (data: any) => {
    const transformedData = data?.map((item: any) => ({
      data: item,
      description: createCustomerGridItem(item),
      divider: createCustomerDivider(),
    }));

    transformedData[0].header = (
      <Grid item className="fx-grid-list fx-padding-top-header">
        <Typography variant="h6">Customer List</Typography>
      </Grid>
    );
    return transformedData || [];
  };

  /**
   * primary filters data
   */
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: "ID", value: "id", operator: "eq" },
  ];

  return (
    <Grid id="partner-customer-grid" item xs={12}>
      <Grid
        container
        className="fx-top-header"
        id="partner-customer-top-grid"
        item
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Grid item>
          <h3>Customer</h3>
        </Grid>
        <ViewProfile />
      </Grid>
      <Grid container xs={12} className="fx-layout-list">
        <FxCard id="partner-customer-card" className="fx-theme-passport">
          <FxCardHeader
            id="partner-customer-card-header"
            className="fx-card-header"
          >
            <Grid
              id="partner-customer-card-header-first-grid"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid id="partner-customer-card-header-second-grid">
                <Grid item>
                  <FxPrimaryFilter
                    id="partner-customer-primary-filter"
                    name="customer-primary-filter"
                    data={primaryFilterData}
                    dataGridProps={dataGridProps}
                    defaultSelection="id"
                  />
                </Grid>
              </Grid>
              <Grid
                id="partner-customer-filter-grid"
                item
                container
                spacing={2}
                className="fx-action-block fx-inline-flex"
              >
                <Grid item>
                  <Filter
                    id="partner-customer-filter"
                    field={filterFields}
                    dataGridProps={dataGridProps}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="partner-customer-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="partner-customer-card-body-grid" item xs={12}>
              {!isSmallScreen ? (
                <DataGrid
                  id="partner-customer-card-list"
                  rowClick={redirectDetailsPage}
                  tableClass="fx-table-passport"
                  dataTransformer={transformCustomerList}
                  source={src}
                  column={column}
                  pagination={true}
                  pageNumber={1}
                  pageSize={25}
                  resultPerPage={25}
                />
              ) : (
                <FxGridList
                  id="partner-customer-card-list"
                  source={src}
                  dataTransformer={customerListGridTransformner}
                  column={gridColumn}
                  rowClick={redirectDetailsPage}
                  pagination={true}
                  pageNumber={1}
                  pageSize={25}
                  resultPerPage={25}
                />
              )}
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </Grid>
  );
});
