import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
/**
 * Component: AccountOpeningTermsCard
 * Usage: display terms and conditions document on first login
 */
export const AccountOpeningTermsCard: React.FC<any> = React.memo(() => {

  return (
    <Grid container id="opening-terms-Grid" item xs={12}>
      <>
        <Grid item xs={12} className="fx-terms-conditions">
          <Typography variant="h6" align="center"> <u>PASSPORT END-USER LICENSE AGREEMENT</u></Typography>
          <Typography>PLEASE READ THIS PASSPORT END-USER LICENSE AGREEMENT (THE “<u>AGREEMENT</u>”)
            CAREFULLY. THIS AGREEMENT SETS FORTH THE TERMS AND CONDITIONS THAT
            GOVERN YOUR USE OF THE PASSPORT PLATFORM (THE “<u>LICENSED APPLICATION</u>”)
            MADE AVAILABLE TO YOU BY PRIORITY TECHNOLOGY HOLDINGS, INC., A
            DELAWARE CORPORATION (“<u>LICENSOR</u>”). BY CLICKING THE “I AGREE” BUTTON,
            DOWNLOADING, INSTALLING, OR OTHERWISE USING THE LICENSED APPLICATION,
            YOU EXPRESSLY AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF
            YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT,
            YOU MAY NOT USE THE LICENSED APPLICATION AND SHOULD REMOVE IT FROM
            YOUR SYSTEM IF YOU HAVE DOWNLOADED IT. AS USED HEREIN, “YOU” MEANS ANY
            INDIVIDUAL WHO ACCESSES OR OTHERWISE USES THE LICENSED APPLICATION.</Typography>
          <Typography variant="h6">License</Typography>
          <Typography>Upon your acceptance of the terms and conditions set forth herein and subject to your payment of
            any applicable fees when due, Licensor grants to you a non-exclusive, non-transferable, non-sublicensable,
            limited license to install and use the Licensed Application for your internal use in the United States to
            collect, store and send money; and to process commercial payments. Such use of the Licensed Application is
            in machine-readable form only.</Typography>
          <Typography variant="h6">License Restrictions</Typography>
          <Typography>In addition to the other restrictions set forth herein, you expressly agree not to do any of the
            following: (i) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain
            access to the source code of the Licensed Application or any part thereof; (ii) work around technical
            limitations, or otherwise translate the Licensed Application, in whole or in part; (iii) create derivative
            works or improvements, whether or not patentable, of the Licensed Application or any portion thereof; (iv)
            transfer, lease, lend, assign, sublicense, distribute, publish, or otherwise make available the Licensed
            Application or any features or functionality to any other person or entity for any reason; (v) perform
            service bureau work, multiple-user licensing or time-sharing arrangements; (vi) remove, delete, alter or
            obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary
            rights notices from the Licensed Application, including any copy thereof; or (vii) remove, disable,
            circumvent or otherwise create or implement any workaround to any copy protection, rights management or
            security features in or protecting the Licensed Application. Use of the Licensed Application on a mobile
            device or media device not owned, operated and controlled by you constitutes copyright infringement and
            exceeds the scope of this license. Furthermore, you shall not use the Licensed Application in any manner
            that violates (a) any intellectual property rights of any person or entity or (b) any applicable law or
            regulation. In the event you violate the scope of the license rights granted herein, in addition to any
            other remedy that may be available to Licensor, your license will terminate, and you must uninstall the
            Licensed Application from your device(s).</Typography>
          <Typography variant="h6">Title</Typography>
          <Typography>Title to and ownership of the Licensed Application, including all improvements, updates and
            modifications thereto, and all related intellectual property rights, including, without limitation, all
            copyrights, trademarks, patents, trade secrets and other proprietary information, shall remain with
            Licensor. Other than the limited license granted herein, you have no other rights with respect to the
            Licensed Application, whether by estoppel, implication or otherwise.</Typography>
          <Typography variant="h6">Updates</Typography>
          <Typography>Licensor reserves the right to make modifications, updates and/or improvements to the Licensed
            Application at any time and without prior notice. All modifications, updates and/or improvements to the
            Licensed Application are subject to the terms and conditions of this Agreement. You agree that Licensor may
            use the information you submit to Licensor or through the Licensed Application, including without
            limitation, technical information about your device, to provide services to you and to improve or support
            the Licensed Application. Licensor reserves the right to modify, amend, or otherwise revise this Agreement
            from time to time. Such modifications, amendments or revisions will be posted on the Licensed Application.
            If you continue to use the Licensed Application following such posting, you are deemed to have agreed to
            such modifications, amendments or revisions.</Typography>
          <Typography variant="h6">Connectivity</Typography>
          <Typography>You understand that use of the Licensed Application will require a data network connection. You
            are solely responsible for acquiring the devices, communication lines and connection to a data network
            required for operating the Licensed Application and any costs associated therewith, including without
            limitation, data plans, carrier fees or usage costs.</Typography>
          <Typography variant="h6">DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY</Typography>
          <Typography>THE LICENSED APPLICATION IS PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT
            WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LICENSOR, ON ITS OWN BEHALF AND
            ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY
            DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE LICENSED
            APPLICATION, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
            FITNESS FOR A PARTICULAR PURPOSE, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
            PERFORMANCE, USAGE OR TRADE PRACTICE. LICENSOR DOES NOT WARRANT AND SPECIFICALLY DISCLAIMS ANY
            REPRESENTATIONS THAT THE LICENSED APPLICATION WILL CONFORM TO ANY DESCRIPTION, MEET YOUR REQUIREMENTS OR
            THAT THE OPERATION OF THE LICENSED APPLICATION WILL BE UNINTERRUPTED, VIRUS-FREE, ERROR-FREE, FREE OF
            DEFECTS, THAT DEFECTS IN THE LICENSED APPLICATION WILL BE CORRECTED, OR THAT THE SPEED OF THE SERVICES WILL
            BE SATISFACTORY TO YOU. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR, ITS AGENTS, OR EMPLOYEES
            SHALL CREATE ANY WARRANTY, AND YOU MAY NOT RELY ON ANY SUCH INFORMATION OR ADVICE. EXCEPT AS OTHERWISE
            EXPRESSLY PROVIDED HEREIN, THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE LICENSED APPLICATION IS
            WITH YOU.</Typography>
          <Typography>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE
            LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
            LIMITATIONS MAY NOT APPLY TO YOU.</Typography>
          <Typography>LICENSOR SHALL HAVE NO LIABILITY WITH RESPECT TO ITS OBLIGATIONS UNDER THIS AGREEMENT OR OTHERWISE
            FOR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INDIRECT, INCIDENTAL OR PUNITIVE DAMAGES. IN ANY EVENT, THE AGGREGATE
            LIABILITY OF LICENSOR UNDER THIS AGREEMENT FOR ANY REASON AND UPON ANY CAUSE OF ACTION OR CLAIM SHALL BE
            LIMITED TO THE AMOUNT PAID TO LICENSOR BY YOU HEREUNDER FOR THE SPECIFIC PORTION OF THE LICENSED APPLICATION
            GIVING RISE TO SUCH CAUSE OF ACTION OR CLAIM IN THE 12-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO SUCH
            CAUSE OR ACTION OR CLAIM. THESE LIMITATIONS APPLY TO ALL CAUSES OF ACTION OR CLAIMS IN THE AGGREGATE,
            INCLUDING, WITHOUT LIMITATION, BREACH OF CONTRACT, BREACH OF WARRANTY, INDEMNITY, NEGLIGENCE, STRICT
            LIABILITY, MISREPRESENTATION, AND OTHER TORTS.</Typography>
          <Typography>You may not use any superseded, outdated or uncorrected versions of the Licensed Application.
            Further, Licensor shall have no liability for loss of data or documentation, it being understood that you
            are responsible for reasonable backup precautions.</Typography>
          <Typography>You are responsible for compliance with the Payment Card Industry Data Security Standards
            (“<u>PCI-DSS</u>”) ”) in connection with your use of the Licensed Application. Licensor shall not be liable
            for, and you shall indemnify Licensor for, any fines or other liability incurred as a result of your
            non-compliance with PCI-DSS in connection with your use of the Licensed Application.</Typography>
          <Typography variant="h6">Termination</Typography>
          <Typography>Your license of the Licensed Application shall become effective upon your download, installation
            or use of the Licensed Application and continue until the earliest of (i) the termination of your agreement
            with Licensor with regard to the access to Licensed Application and the related services for any reason;
            (ii) immediately upon your failure to comply with any of the terms and conditions herein or (iii)
            immediately upon notice of termination, with or without cause, by Licensor. You may terminate the license at
            any time by uninstalling the Licensed Application. The terms and conditions of this Agreement which by their
            nature or by their express terms are intended to survive the expiration or termination of this Agreement
            shall survive any termination or expiration of this Agreement or the license granted hereunder.</Typography>
          <Typography variant="h6">Export Regulation</Typography>
          <Typography>You represent and warrant to Licensor that: (i) your legal address is, and will be during the term
            of this Agreement, in the United States; and (ii) you are not on any prohibited persons or entities lists
            maintained by the United States Office of Foreign Assets Control.</Typography>
          <Typography>The Licensed Application may be subject to U.S. export control laws, including the U.S. Export
            Administration Act and its associated regulations. You shall not, directly or indirectly, export, re-export
            or release the Licensed Application to, or make the Licensed Application accessible from, any jurisdiction
            or country to which export, re-export or release is prohibited by law, rule or regulation. You shall comply
            with all applicable federal laws, regulations and rules, and complete all required undertakings (including
            obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting,
            releasing or otherwise making the Licensed Application available outside the U.S., if such export, re-export
            or release is approved by the Licensor.</Typography>
          <Typography variant="h6">Contact Information</Typography>
          <Typography>The contact information of Licensor which you can use for any question and/or communication
            relating to the Licensed Application is: Priority Technology Holdings, Inc., 2001 Westside Parkway, Suite
            155, Alpharetta, Georgia 30004.</Typography>
          <Typography variant="h6">Data Ownership; License for Data</Typography>
          <Typography>In addition to the Licensed Application, Licensor retains all ownership and intellectual property
            rights to all programs, services, documents, and materials developed and delivered by Licensor under this
            Agreement. You retain all ownership and intellectual property rights in and to your data that you or your
            affiliates, customers, merchants, employees, officers, directors, agents, or contractors submit through the
            Licensed Application (collectively, “<u>Data</u>”). You hereby grant Licensor a royalty free, worldwide,
            perpetual right and license to use the Data in the performance of the services provided by Licensor in
            connection with the Licensed Application and to aggregate, compile, share, use, sell, resell, license,
            sublicense, distribute and/or publish the Data, internally or externally, for Licensor’s or its affiliates’
            own benefit or the benefit of third parties, provided that any such use of the Data by Licensor shall be
            performed in such a manner as to remove any personally identifiable information from the Data and that such
            external use or publication shall be consistent with applicable PCI-DSS. Such use of the Data by Licensor
            may include, but not be limited to, the following circumstances: to aggregate data to show trends about the
            general use of Licensor’s services, conduct general market analysis, and compile statistics and publish
            reports.</Typography>
          <Typography variant="h6">Other Agreements</Typography>
          <Typography>You acknowledge that this Agreement applies only to your use of the Licensed Application and does
            not replace any other agreements or terms and conditions that may be applicable to the services that you
            access through the use of the Licensed Application. It is your sole responsibility to comply with all other
            terms and conditions that may be applicable to such services.</Typography>
          <Typography variant="h6">General</Typography>
          <Typography>This Agreement shall be governed by and construed in accordance with the federal laws of the
            United States and the laws of the State of Georgia. The parties agree that the United Nations Convention on
            Contracts for the International Sale of Goods, as amended, shall not apply. Any action relating this
            Agreement shall be heard in the federal and state courts in Fulton County, State of Georgia, and you hereby
            expressly submit to the personal jurisdiction and venue of such courts.</Typography>
          <Typography>No waiver of this Agreement shall be binding unless it is in writing and signed by an authorized
            representative of Licensor. No written waiver of any provision of this Agreement shall be considered
            continuing or operate as a waiver of any other provision or subsequent breach of the same
            provision.</Typography>
          <Typography>The failure of Licensor to enforce any provision of this Agreement shall not be construed as a
            waiver or limitation of Licensor’s right to subsequently enforce and compel strict compliance with every
            provision of this Agreement.</Typography>
          <Typography>Licensor will not be responsible for delays or failures in performance resulting from acts beyond
            its control. Such acts shall include, but not be limited to, acts of God, strikes, supplier delays,
            lockouts, riots, acts of war, epidemics, governmental regulations imposed after the fact, fire,
            communication line failures, power failures, earthquakes or other disasters.</Typography>
          <Typography>In the event that any of the terms of this Agreement is declared to be invalid or void by any
            court or tribunal of competent jurisdiction, such term or terms shall be null and void and shall be deemed
            severed from this Agreement and all the remaining terms of this Agreement shall remain in full force and
            effect.</Typography>
          <Typography>The Section headings in this Agreement are for identification purposes only and shall not affect
            the interpretation of this Agreement or any part hereof.</Typography>
          <Typography>Licensor, as the owner of the Licensed Application, reserves the right, in its sole discretion, to
            grant licenses with rights to sublicense the Licensed Application on a non-exclusive basis to third
            parties.</Typography>
          <Typography variant="h6">Privacy Notice</Typography>
          <Typography>The Privacy Notice found in <u><Link
            href="https://prioritycommerce.com/privacy/">https://prioritycommerce.com/privacy/</Link></u> shall apply to
            the use of the Licensed
            Application.</Typography>
          <Typography></Typography>


                            {/* document viewer */}
                            {/* <Document file='/assets/pdf/Account_Opening_Terms.pdf'>
                                <Page pageNumber={1} />
                            </Document> */}
                        </Grid>
                       </> 
            </Grid >
        )
    })