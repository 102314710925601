/**
 * Component : AddTags
 * Usage     :Including adding,update and delete tags
 */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Button, Grid, IconButton, Modal, Typography, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import HttpClient from '../../../../libs/utils/httpClient';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxButton } from '../../../Action/FxButton';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from 'react-redux';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { FxTextEditWithChips } from '../../../Input/FxText/FxTextEditWithChips';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import { getKey } from '../../../../libs/utils/storageManager';
import FxSnackBar from '../../../Utils/fx-snack-bar';


Logger.debug("AddTags.tsx", "Account Tags initializing")


export const AddTags: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const { register,formState: {isSubmitting }, handleSubmit, setValue, control } = useForm();
        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })
        const dispatch = useDispatch();
        const httpClient = HttpClient.getClient();
        const {existingTags}=props

        /**
         * fetching account details from state, which includes tag details
         */
        const accDetails=useSelector((state: any) => {
            if (state.data['customer-basic-info']) {
                return state.data['customer-basic-info']?.params?.accountDetails;
            }
        });

        useEffect(() => {
            updateComponentParams(dispatch, props?.id, { 'existingTags': accDetails?.tags || [] });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        /**
         * function to handle modal close
         */
        const handleClose = () => {
            if(props.onClose){
                props.onClose();
            }
        };

        /**
         * function to handle submit
         * @param data 
         * @returns 
         */
        async function onSubmit(data: any) {
          try {
              let deleteTagStatus = await deleteExistingAccountTags();
              deleteTagStatus = processAPIResponse(deleteTagStatus);
      
              if (!deleteTagStatus.status) {
                  FxSnackBar.show({
                      autoHideDuration: 1000,
                      severity: 'success',
                      text: 'Tags Updation failed!'
                  });
                  handleClose();
                  return;
              }
      
              const payload = data?.tags === null || data?.tags === undefined
                  ? { tags: [...props?.existingTags] }
                  : data;
      
              let status = await updateAccountTags(payload);
              status = processAPIResponse(status);
      
              if (status.status) {
                  const res = await httpClient.get(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id));
                  updateComponentParams(dispatch, "customer-basic-info", { 'accountDetails': res.data });
                  FxSnackBar.show({
                      autoHideDuration: 1000,
                      severity: 'success',
                      text: 'Tags Updated Successfully!'
                  });
                  handleClose();
              } else {
                  FxSnackBar.show({
                      autoHideDuration: 1000,
                      severity: 'error',
                      text: 'Tags Updation failed!'
                  });
                  handleClose();
              }
          } catch (err) {
            FxSnackBar.show({text: 'Updating Tags Failed!'});
            handleClose();
            Logger.error("AddTags.tsx", "error", err);
            return err;
          }
        }

        /**
         * function to delete account tags
         * @returns 
         */
        async function deleteExistingAccountTags() {
          try {
              let payloadForDelete={tags:[...props?.existingTags]}
              const data: any = await httpClient.post(getCustomerUrl('account/id/' + getKey("selectedAccount")?.id+'/tag/delete' ,false), payloadForDelete).then(response => {
                  return response;
              })
                  .catch((error) => {
                      return { ...error };
                  })
              return data;
          } catch (err) {
              Logger.error("AddTags.tsx", "error", err);
              return err;
          }
        }

        /**
         * function to update account details
         * @param tags 
         * @returns 
         */
        async function updateAccountTags(tags: any) {
          try {
              const data: any = await httpClient.post(getCustomerUrl('account/id/' + getKey("selectedAccount")?.id ,false), tags).then(response => {
                  return response
              })
                  .catch((error) => {
                      return { ...error };
                  })
              return data;
          } catch (err) {
              Logger.error("AddTags.tsx", "error", err);
              return err;
          }
        }

        return (
          <Grid container className={"fx-container fx-account-manage-tags"}>
            <Modal
              style={{ overflow: "scroll" }}
              open={props.open}
              onClose={handleClose}
              disableEnforceFocus={true}
              disableAutoFocus={false}
              className={
                isTabScreen
                  ? "fx-modal fx-modal-medium"
                  : "fx-modal fx-modal-small2medium"
              }
            >
              <div className="modal-content">
                <div className="modal-body">
                  <form
                    id="account-manage-tags-submit"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FxCard  className="fx-theme-passport">
                      <FxCardHeader id="account-manage-tags-header">
                        <Grid
                          container
                          direction="row"
                          item
                          xs={12}
                          justifyContent="space-between"
                        >
                          <Grid
                            item
                            container
                            xs={11}
                            justifyContent="flex-start"
                          >
                            <Typography
                              id="title-account-manage-tags-modal"
                              variant="h4"
                            >
                              Manage TAGS
                            </Typography>
                          </Grid>
                          <Grid className="fx-modal-close-icon" item xs={1}>
                            <IconButton
                              onClick={handleClose}
                              id="account-manage-tags-modal-close-icon"
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </FxCardHeader>
                      <FxCardBody
                        id="account-manage-tags-modal-body"
                        className="fx-info-card"
                      >
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          className="fx-modal-form flex column"
                        >
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              className="fx-authorised-user-card"
                            >
                              <Grid item className="fx-autosearch-height">
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FxTextEditWithChips register={{ ...register("tags") }} rules={{ required: false }} className={"fx-text-edit-with-chips fx-input-edit"} control={control} id="add-tags-textbox" defaultValue={existingTags} setValue={setValue} name='tags' label="Add Tags"/>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      </FxCardBody>
                      {
                        <FxCardFooter
                          id="account-manage-tags-modal-Footer"
                          className="fx-footer"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            className="fx-modal-footer"
                          >
                            <Button
                              variant="contained"
                              className="fx-button fx-button-cancel"
                              id="cancel-button"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <span className="fx-padding-right-16" />
                            <FxButton
                              disableRipple={false}
                              className="fx-button fx-button-theme"
                              id={"account-manage-tags-submit-button"}
                              type="submit"
                              isSubmitting={isSubmitting}
                            >SUBMIT
                            </FxButton>
                          </Grid>
                        </FxCardFooter>
                      }
                    </FxCard>
                  </form>
                </div>
              </div>
            </Modal>
          </Grid>
        );
    })