import { Grid, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../libs/utils/utils';
const httpClient = HttpClient.getClient();
/**
 * Component used for showing international routing code text field based on swiftcode
 */
Logger.debug("FxSwiftCode.tsx", "Fx SwiftCode View initializing");
interface FxSwiftCodeProps {
    id: string;
    name: string;
    value?: string;
    defaultValue?: string;
    className?: string;
    error?: boolean;
    label?: React.ReactNode;
    placeholder?: string;
    rules?: any,
    register: any,
    control: any,
    readOnly?: boolean;
    isEditable?: boolean,
    variant?: "standard" | "filled" | "outlined" | undefined;
    setValue: UseFormSetValue<FieldValues>;
    onSwiftCodeChange: (event: any) => void;
}
export const FxSwiftCode: React.FC<FxSwiftCodeProps> = (props) => {
    const { id, name, setValue, control, rules, className, register, defaultValue, placeholder, label, variant, onSwiftCodeChange, isEditable } = props;
    const [searchValue, setSearchValue] = useState(defaultValue);
    const inputRef = useRef<any>(null);
    /**
     * use effect to fetch international routing code details based on default value
     */
    useEffect(() => {
        if(defaultValue){
            const url = "/internationalExternalAccount/swiftCode/" + defaultValue + "/detail";
            fetchSwiftCodeDetails(url);
            setValue(name, defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    /**
     * use effect to fetch international routing code details based on user entered value
     */
    useEffect(() => {
        const searchTimer = setTimeout(() => {
            if (inputRef?.current?.value?.length > 3 && inputRef?.current?.value === searchValue) {
                const url = "/internationalExternalAccount/swiftCode/" + searchValue + "/detail";
                fetchSwiftCodeDetails(url);
            }
            return () => {
                clearTimeout(searchTimer);
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, inputRef]);


    /**
     * Method to call api  for international routing code details
     * @param url : request url
     * @param base : whether to include base url or not
     * @returns res : api response
     */
    async function getSwiftCodeDetails(url: any, base = false) {
        try {
            return await httpClient.get(getCustomerUrl(url, base));
        } catch (err) {
            Logger.error("getSwiftCodeDetails.tsx", "error", err);
            return err;
        }
    }
    /**
     * Method to get the user entered text on change
     * @param search : user entered value
     */
    function onUserSearch(search: any) {
        setSearchValue(search.target.value);
        setValue('swiftCode', search.target.value);
    }
    /**
     * Method to set international routing code details and send to parent
     * @param url : request url
     */
    async function fetchSwiftCodeDetails(url: string) {
        const swiftCodeResp: any = await getSwiftCodeDetails(url);
        if (swiftCodeResp && !swiftCodeResp.errorMessage) {
            onSwiftCodeChange(swiftCodeResp.data);
        } else {
            onSwiftCodeChange({});
        }
    }
    return (
        <Controller
            name={name}
            control={control ? control : ''}
            rules={rules ? rules : {}}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Grid item >
                    <TextField
                        {...register}
                        variant={variant}
                        id={id + "swiftcode"}
                        name={name}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isEditable === false ? true : false}
                        placeholder={placeholder ? placeholder : ""}
                        className={className}
                        value={searchValue ? searchValue : ""}
                        type={'text'}
                        label={label ? label : ''}
                        onChange={onUserSearch}
                        inputRef={inputRef}
                        error={error ? error : false}
                    />
                </Grid>
            )}
        />
    )
};