import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useLocation, useParams } from 'react-router-dom';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxDelete } from '../../Data/FxDelete';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { addressFormatter } from '../../Utils/CommonBaseClass';
import moment from 'moment';


Logger.debug("ContactCardDetails.tsx", "Contact Card details initializing");
/**
 * Component: ContactCardDetails
 * Usage: To display contact card detail information
 */

export const ContactCardDetails: React.FC<any> = React.memo((props) => {
    const dispatch = useDispatch();
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const location: any = useLocation();
    const params = useParams<any>();
    const contactId = location.state?.contact?.id;
    const id = params?.id

    const card_information_data: any = {};
    const audit_field_data: any = {};
    const avs_details_data: any = {};
    const billing_details_data: any = {};
    let selectedCard: any = {};

    if(context?.data?.body) {
        selectedCard = context?.data?.body?.card.find((cardDetail: any) => cardDetail.id === +id);
        if(selectedCard) {
          if(card_information_data['id'] === undefined) {
            card_information_data['id'] = selectedCard.id;
            card_information_data['holderName'] = selectedCard?.holderName || '';
            card_information_data['cardNumber'] = '**' + selectedCard.cardNumberLast4;
            card_information_data['expiryDate'] = (+selectedCard.expiryMonth > 9  ? selectedCard.expiryMonth : '0' + selectedCard.expiryMonth) + '/' + selectedCard.expiryYear;
            card_information_data['status'] = selectedCard.status;
            audit_field_data['createdOn'] = selectedCard.createdBy?.username + ' on ' + moment(selectedCard.createdOn).format('MM/DD/YYYY');
            audit_field_data['lastUpdatedOn'] = selectedCard.lastUpdatedBy?.username + ' on ' + moment(selectedCard.lastUpdatedOn).format('MM/DD/YYYY');
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Card ' + (selectedCard && ('**' + selectedCard.cardNumberLast4)), backButton: '/payee/view/' + contactId } } });
          }
          billing_details_data['billingAddress'] = selectedCard?.billingAddress ? addressFormatter('', selectedCard?.billingAddress || {}) : '';
          billing_details_data['email'] = selectedCard?.cardHolder?.email || '';
          billing_details_data['phone'] = selectedCard?.cardHolder?.phone || '';

          avs_details_data['billingAddress'] = selectedCard?.avs ? addressFormatter('', selectedCard?.avs || {}) : '';
          avs_details_data['email'] = selectedCard?.avs?.email || '';
          avs_details_data['phone'] = selectedCard?.avs?.phone || '';
        }
    }

    /**
     * Method to rest the component on unmount.so that when redirect back page it will call the api again
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const card_information_left_section = [
        {
            label: 'ID',
            value: 'id'
        },
        {
            label: 'Card Holder',
            value: 'holderName',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Status',
            value: 'status',
            type: 'fri://fieldtype/status'
        },
    ];

    const card_information_right_section = [
        {
            label: 'Card Number',
            value: 'cardNumber',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Expiry Date',
            value: 'expiryDate',
            type: 'fri://fieldtype/default'
        }
    ];

    const audit_field_left_section = [
        {
            label: 'Created On',
            value: 'createdOn',
            type: 'fri://fieldtype/default'
        },
    ];

    const audit_field_right_section = [
        {
            label: 'Last Updated On',
            value: 'lastUpdatedOn',
            type: 'fri://fieldtype/default'
        },
    ];

  const avs_details_left_section = [
    {
      label: 'Address',
      value: 'billingAddress',
      type: 'fri://fieldtype/default'
    }
  ];

  const avs_details_right_section = [
    {
      label: 'Email',
      value: 'email',
      type: 'fri://fieldtype/default'
    },
    {
      label: 'Phone',
      value: 'phone',
      type: 'fri://fieldtype/default'
    },
  ];


  const billing_details_left_section = [
    {
      label: 'Address',
      value: 'billingAddress',
      type: 'fri://fieldtype/default'
    }
  ];

  const billing_details_right_section = [
    {
      label: 'Email',
      value: 'email',
      type: 'fri://fieldtype/default'
    },
    {
      label: 'Phone',
      value: 'phone',
      type: 'fri://fieldtype/default'
    },
  ];



    const deletePermission = {
        entity: "Other Entities",
        name: "Contact",
        operation: "DELETE"
    }

    return (
        <Grid id="contact-card-detail-grid" className="fx-margin-bottom-16" item xs={12} sm={8}>
            <FxCard id="contact-card-detail-info-card" className="fx-theme-passport">
                <FxCardHeader id='contact-card-detail-card-header'>
                    <Grid id="contact-card-detail-Grid2" item container justifyContent="space-between" xs={12}>
                        <Typography id="title-card-info" variant="h5">Card Details</Typography>
                        <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                            <Grid item>
                              <FxDelete id={selectedCard && selectedCard.id} permissions={deletePermission}
                                        buttonId={'payee-ea'} entity={'/contact/id/' + contactId + '/card'}
                                        buttonTitle="Delete" header={'Are you sure you want to delete this Card'}
                                        description="You can only take this action if there are no transactions scheduled on this Card. You cannot undo this action."
                                        successEntity="Card"
                                        redirectUrl={'/payee/view/' + contactId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="contact-card-detail-info-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid item xs={12}>
                            <FxInfoCard id="contact-card-detail-information-section" title="CONTACT INFORMATION" leftcolumn={card_information_left_section} rightcolumn={card_information_right_section} data={card_information_data} />
                        </Grid>
                       <Grid item xs={12}>
                            <FxInfoCard id="contact-card-detail-billing-details-information" title="BILLING DETAILS" leftcolumn={billing_details_left_section} rightcolumn={billing_details_right_section} data={billing_details_data} />
                       </Grid>
                       <Grid item xs={12}>
                            <FxInfoCard id="contact-card-detail-avs-details-information" title="AVS DETAILS" leftcolumn={avs_details_left_section} rightcolumn={avs_details_right_section} data={avs_details_data} />
                       </Grid>
                        <Grid item xs={12}>
                            <FxInfoCard id="contact-card-detail-audit-field-information" title="AUDIT FIELDS" leftcolumn={audit_field_left_section} rightcolumn={audit_field_right_section} data={audit_field_data} />
                        </Grid>
                </FxCardBody>
            </FxCard>
        </Grid >

    )
})
