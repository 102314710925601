import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FxButton } from '../../Action/FxButton';
import { DataGrid } from '../../Data/DataGrid';
import { ReactComponent as ThreeDotMenu } from '../../../assets/svg/threedot-menu-icon.svg';
import { addressFormatter, checkPermission, truncateChars, updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxDelete } from '../../Data/FxDelete';
import { ReactComponent as MenuDeleteIcon } from '../../../assets/svg/menu-delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import { FaPhone } from 'react-icons/fa';
import moment from 'moment';
Logger.debug("AddressListingCard.tsx", "Address Card initializing");

/**
 * Component: Address Listing Card
 * Usage: To display Address information
 */
export const AddressListingCard: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        let cur_date: any = '';
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        ({ props } = RegisterComponent(props));

        const addressData = props?.data?.mailingAddress || [];
        
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        interface RouteParams {
            id: string
        }
         const params = useParams<RouteParams>();

        const contactId = params.id

        /**
         *  Handling the click event
         *  */
        const handleClick = (event: any, id: any) => {
            updateComponentParams(dispatch, props?.id, { 'addressId': id, 'anchorEl': event.currentTarget })

        };

        const history = useHistory();
        const open = Boolean(props?.anchorEl);


        /**
         * Method handles the close event of the FxDelete component
         */
        const deleteShippingClose = () => {
            updateComponentParams(dispatch,props?.id,{'deleteShippingOpen':false})
        };

        /**
         * Method handles the change event of the menu click
         * @param event
         */
        const handleChange = (event: any) => {
             try{
                if (event.split(' ')[0] === 'Delete') {

                updateComponentParams(dispatch,props?.id,{'deleteShippingOpen':true,'anchorEl':null})
                }
                else  if (event.split(' ')[0] === 'Edit')
                {
                    if(event === 'Edit'){
                        history.push(`/payee/id/${contactId}/address/edit/${props?.addressId}`);
                    }
                }

            }
            catch(e){ }
        }

        const deletePermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"DELETE"
          }

        const editPermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"EDIT"
          }

        // declare menu options
        const options = [
            {
                id: 'address-delete-option',
                title: 'Delete Address',               
                icon: <MenuDeleteIcon />,
                permission:checkPermission(deletePermission)
            },
            {
                id: 'address-edit-option',
                title: 'Edit',                
                icon: <EditIcon />,
                permission:checkPermission(editPermission)
            }
        ];

        /**
         * Method handles used to get address action.
         * @param item
         */
        const getAddressAction=(item:any)=>{
            return (<Grid id="filter-Grid" className="fx-view-action" >
                {(checkPermission(editPermission) || checkPermission(deletePermission)) &&
                    <>
                        <FxButton
                            aria-label="more"
                            id={props.id + '_' + item?.id + '-button'}
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            className="fx-button fx-button-dotted fx-button-dotted-icons"
                            onClick={(event: any) => { handleClick(event, item.id) }}
                        >
                            <ThreeDotMenu />
                        </FxButton>
                        {<Menu
                            id="external-account-info-menu"
                            MenuListProps={{
                                'aria-labelledby': 'external-account-info-menu',
                            }}
                            anchorEl={props.anchorEl}
                            open={Boolean(props.anchorEl)}
                            onClose={handleClose}

                            className="fx-menu-dotted"
                        >
                            {(options.length > 0) && options.map((option: any) => (
                                <>
                                    {option.permission &&
                                        <MenuItem id={option.id} key={option} onClick={() => { handleChange(option.title) }}>
                                            <div className={option.className}>{option.icon} &nbsp;&nbsp; {option.title}</div>
                                        </MenuItem>
                                    }
                                </>
                            ))
                            }
                        </Menu>}
                    </>}
            </Grid>)
        }
        /**
         * close the delete popup
         */
        const handleClose = () => {

            updateComponentParams(dispatch,props?.id,{'anchorEl':null})

        };

        function redirectToCreatePage() {
            const shippedTo = props?.data?.contactType === 'BUSINESS' ? props?.data?.legalName: props?.data?.firstName + ' ' + props?.data?.lastName;
            history.push('/payee/address/add/' + contactId,{contact:{name:props?.data?.name,id:props?.data?.id, shippedTo:shippedTo}});
        }

         /**
         *  To generate the mobile row.
         *  @param item :row item object
         */
         const generateMobileRow = (item: any) => {
            let title: any;
            if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            }
            return {
                header: (
                    title ? <Grid item className='fx-grid-list fx-padding-top-header'><Typography variant='h6'>{title}</Typography></Grid> : <></>
                ),
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        <Grid item container xs={11} alignItems='center'>
                            <Grid item container xs={12}>                                        
                                <Grid item>                                            
                                    <Grid className='fx-info-label-value'>
                                        <Typography>{truncateChars(item?.name, 25)}</Typography>
                                    </Grid>
                                </Grid>                                                                                                            
                            </Grid>
                            <Grid item container xs={12} className='fx-info-label-value'>  
                                <Typography><FaPhone/>&nbsp; {item?.phone} </Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item className='fx-info-label-value'>
                                    <Typography>{truncateChars(addressFormatter('', item), 45)}</Typography>
                                </Grid>                                                  
                            </Grid>
                        </Grid>                       
                        <Grid item container xs={1} alignItems='center'>
                            {getAddressAction(item)}
                        </Grid>
                    </Grid> 
                ),
                divider: (
                    !title ? true : false
                ),        
            };
        }

        /**
         *  To generate the desktop row.
         *  @param item :row item object
         */
        const generateDesktopRow = (item: any) => {
            return {
                shippedTo: item?.name,
                phone: item?.phone,
                address: addressFormatter('', item),
                id: item?.id,
                lastUpdatedOn: item?.lastUpdatedOn,
                lastUpdatedBy: item?.lastUpdatedBy?.username,
                actions:getAddressAction(item)
            };
        }

        /**
         *  To get the list data as per device.
         */ 
        const addressList = useMemo(() => {
            if(isMobile){
                return addressData.map((item: any, i: any) => {                       
                    return generateMobileRow(item);
                })           
            }else{
                return addressData.map((item: any) => {
                    return generateDesktopRow(item);
                });
            }
            
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isMobile, addressData, options])        
       
        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Shipped To",
                    "enable": true,
                    "fieldName": "shippedTo",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Phone",
                    "enable": true,
                    "fieldName": "phone",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "address",
                    "enable": true,
                    "fieldName": "address",
                    "type": "fri://fieldtype/address",
                    "default": true
                },


                {
                    "label": "Last Updated On",
                    "enable": true,
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/date",
                    "default": true
                },
                {
                    "label": "Last Updated By",
                    "enable": true,
                    "fieldName": "lastUpdatedBy",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "",
                    "enable": true,
                    "fieldName": "actions",
                    "type": "fri://fieldtype/custom",
                    "style": { "width": "100px", "text-align": "center" },
                    "default": true
                },

            ]
        };

        // To get the mobile grid columns.
        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12",
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                },
            ]
          }

        /**
         * Delete address success handling
         */
        const handleDeleteOnSuccess = () => {
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'Shipping Address Deleted Successfully!',
            });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'payee-details-info-card'} });
            resetDefaultParams();
        }
        /**
         * Resetting the default params
         */
        function resetDefaultParams()
        {
            updateComponentParams(dispatch,props?.id,{
                anchorEl:null,
                deleteShippingOpen:false,
                addressId:'',
            })
        }

        return (
            <>
                <Grid id="payees-address-info-grid " className="fx-margin-bottom-16" item xs={12}  >
                    <FxCard id="payees-info-card" className="fx-theme-passport">
                        <FxCardHeader id={'payees-info-card#card-header'}>
                            <Grid id="payees-Grid2" item container justifyContent="space-between" xs={12} >
                                <Grid id="payees-Grid3"  >
                                    <Typography id="title-payees-info" variant="h4" >Shipping Address Details </Typography>
                                    <div id="div-grow-title-payees-list" className="fx-flex-grow" />
                                </Grid>
                                <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >

                                    <Grid item><FxButton id="payees-add-address-button" permissions={editPermission} variant="contained" className="fx-button fx-button-passport-border fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} >Add</FxButton></Grid>
                                     {props?.deleteShippingOpen && <FxDelete id={props?.addressId} buttonId={'payee-address'} entity={"/contact/id/" + contactId + "/mailingAddress"} buttonTitle="Delete Address" header={"Are you sure want to delete this Shipping Address?"} description="You can only take this action if there are no transactions scheduled on this address. You cannot undo this action." redirectUrl={'/payee/view/'+contactId}  successEntity="Shipping Address" open={props?.deleteShippingOpen} close={deleteShippingClose} onSuccess={handleDeleteOnSuccess}
 />}
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="home-recent-ea-card-body" className="fx-card-general">
                        {! isMobile ? <DataGrid id="payee-address-card-list" tableClass="fx-table-passport" data={addressList} column={column} />
                            : <FxGridList id="payee-address-card-list" data={addressList} column={gridColumn}/>    
                        }
                        </FxCardBody>
                    </FxCard>
                </Grid >
            </>
        )
    })
