import React, { useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { ISource } from '../../../types/common.interfaces';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import { addressFormatter } from '../../Utils/CommonBaseClass';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { FxButton } from '../../Action/FxButton';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { dataSourceTransformation } from '../Schedules/ScheduleGlobalFunctions';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();
interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("CreateDebitCardPhysicalPage.tsx", "CreateDebitCardPhysicalPage initializing")
interface InputErrType {
    type: string;
    message: string;
}
/**
 * This component handles the send creation through payee for book method
 */
export const CreateDebitCardPhysicalPage: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const [cardProgram, setCardProgram] = useState<any>();

        useEffect(() => {
            getCardProgramType()
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        let userData: any; // context?.data?.body;
        if (context && context.data && context.data.body) {
            userData = context.data.body;
        }

        const [btnDisabled, setBtnDisabled] = useState(true);
        const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
        let submitButton: any;
        const params = useParams<any>();
        const id = params.id
        let selectedCardHolder = '';
        let address = ''
        let primaryAddressId : number;
        if (userData) {
            selectedCardHolder = userData?.legalName ? userData?.legalName : userData?.firstName + ' ' + userData?.lastName
            if (userData?.mailingAddress) {
                const primaryAddress = userData?.mailingAddress.find((address: {isPrimary: boolean}) => address.isPrimary);
                if(primaryAddress){
                    primaryAddressId = primaryAddress?.id;
                    address = addressFormatter('', primaryAddress)
                }
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Issue a Debit Card', backButton: '/authorizedusers/view/'+id } } });
        }
        const dest_acc_src: ISource = {
            url: "account/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "status",
                            "operator": "nin",
                            "values": [
                                "INACTIVE"
                            ]
                        }
                    ]
                }
            }
        };

        let currentAccount: any = '';
        const setAmountValidationOptions = {
            required: false
        };

        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory();
        const isEdit = !!props.source
        const [isEditDataAvailable, setIsEditDataAvailable] = useState(false);
        const [fileInfo, setFileInfo] = useState<IFileInfo[]>();
        submitButton = 'Issue'
        let currentAccountBalance: any = '';
        if (getKey("selectedAccount")) {
            currentAccount = getKey("selectedAccount").id;
            currentAccountBalance = getKey("selectedAccount").balance.amount;

        }


        /**
         * setting isEditDataAvailabe state after getting data from context and setting the default value for fields from api data
         */
        if (userData && !isEditDataAvailable) {
            setIsEditDataAvailable(true)
        }

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let request: any;
            let status: any;

            let expressDelivery = false;
            if (data.expressDelivery && data.expressDelivery.length > 0) {
                expressDelivery = true;
            }
          const linkedDocuments = fileInfo?.map((file: IFileInfo) => {
            return {
              purpose: 'DEBIT_CARD_AGREEMENT',
              document: {
                type: file?.fileType,
                name: 'Terms&Condition.pdf',
                base64encodedContent: file?.base64
              }
            }
          })
            request = {
                type: 'DEBIT',
                shippingDetail: {
                    "address": {
                        "id": primaryAddressId
                    },
                    "expressDelivery": expressDelivery
                },

                linkedDocument: linkedDocuments,
                cardProgram: {
                    id: cardProgram[0]?.id
                }
            }
            if (context?.data?.body?.isBeneficialOwner === true) {
                request['cardholder'] = {
                    type: "BENEFICIAL_OWNER",
                    id: userData?.id
                }
            }
            else {
                request['cardholder'] = {
                    type: "AUTHORIZED_USER",
                    id: userData?.id
                }
            }
            setIsLoading(true);
            status = await issueDebitCard(request, data?.account);
            setIsLoading(false);
            if (status.status) {
                /**
                 * api success
                 */
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Debit Card Issued Successfully!',
                });
                history.push('/authorizedusers/view/'+id);
            }
            else {
                /**
                 * api  failed
                 */
                const errorMessage = status.message ? status.message : displayErrors(status);
                FxSnackBar.show({
                    text: errorMessage,
                });
            }
        };

        /**
         * To show api error
         * @param error
         */
        function displayErrors(error:any)
        {
            let errorMessage:any='';
            try{
            errorMessage=error.response && error.response.data && error.response.data.errors && error.response.data.errors.length>0?error.response.data.errors[0].message:error.response.data.errorMessage;
            }
            catch (e){}
            return errorMessage;
        }
        /**
         * Async function handles the api call for issueDebitCard
         * @param paylaoddata : form request
         * @returns : response object
         */
        async function issueDebitCard(paylaoddata: any, accountId: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('account/id/' + accountId + '/debitCard',false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateDebitCardPhysicalPage.tsx", "error", err);
                return err;
            }
        }

        const card_type_request:any={
            "pageSize": 25,
            "pageNumber": 1,
            "sortOptions": {
                "sortOrder": "DESC",
                "sortBy": "createdOn"
            },
            "criteria": {
                "filters": [ ]
            }
        }

         /**
        * async function to get debit card program list
        */
        async function getCardProgramType(){
            const card_type= await httpClient.post(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id +"/debitCard/program/list", false), card_type_request);
            cardTypeTransformation(card_type?.data)
        }

        /**
        * Function to transform data for card type select
        */
        function cardTypeTransformation(data: any) {
            try {
                const cardPrograms = data?.resources?.filter((option: any) => {
                    return option.isDefault
                })
                setCardProgram(cardPrograms)
            }
            catch (e) { }
        }

        const expressDeliveryData: any = [
            { label: 'Express Delivery', value: 'YES' },
        ];


        /**
         * debit terms & condition options
         */
        const debitCardTerms = [
            { label: 'I Agree to the Passport Debit Card Terms and Conditions', value: 'yes' }
        ];
        /**
         * Error render function
         * @param err
         * @returns
         */
        const renderError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return AUTH_STRINGS.ERRORS.AGREE_PLEASE_ENTER;
            }
            return err.message;
        };
        /**
    * Method to handle form change events
    * @param event :event object
    */
        const handleChangeReq = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target.checked === true) {
                setBtnDisabled(false)
            }
            else {
                setBtnDisabled(true)
            }
        }

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="create-send-account-transfer-grid" item xs={12} className='fx-form-grid'>
                <div className="fx-form-edit-profile flex column">
                    {isEdit && !dataLoadStatus && <FxSkeltonList height="50rem" /> }
                    {(isEdit === false || dataLoadStatus) &&
                        <form id="create-send-account-transfer-form" onSubmit={handleSubmit(onSubmit)}>
                        <FxCard id="create-send-account-transfer-card" className=" fx-card-general fx-theme-passport">
                            <FxCardHeader id="create-send-account-transfer-form-card-header" className="fx-card-header">
                            </FxCardHeader>
                            <FxCardBody id="create-send-account-transfer-form-card-body" className="fx-info-card" >
                                <Grid item container xs={12} className="fx-schedule-create-tab-container">
                                            <Grid item container xs={12} sm={12}>
                                                <Grid item>
                                                    <FxLabelView id="create-collect-onetime-card-form-card-choose-method-label" className="fx-margin-left-10">Card Type</FxLabelView>
                                                </Grid>

                                                <Grid item >
                                                    <FxButton
                                                        className="fx-link-sel"
                                                        id='create-collect-onetime-card-form-card-card-button'
                                                       >
                                                        PHYSICAL
                                                    </FxButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                <Grid container direction="row" justifyContent="space-between" className="flex column fx-container-create-schdule" >
                                    <Grid item container xs={12} sm={4} className="fx-select-grid" >
                                        <Grid item xs={12} container justifyContent="space-between">
                                            <Grid item xs={12} sm={12}>
                                                <Typography>SELECTED CARD HOLDER</Typography>
                                                <br></br>
                                                <b>{selectedCardHolder}</b>
                                                <br></br>
                                                <br></br>
                                                <Typography >Debit card will be delivered to cardholder's primary address</Typography>
                                                <br></br>
                                                <b>{address} </b>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} sm={8} spacing={2} className='fx-container-create-schdule-right'>
                                        <Grid item xs={12} sm={12}>
                                           <Typography className="filter-popover-label" >CARD INFORMATION </Typography>
                                        </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxMaterialSelect
                                                    control={control}
                                                    rules={{ required: true }}
                                                    register={{ ...register("account") }} id="create-send-account-transfer-form-card-account" dataTransformation={dataSourceTransformation} source={dest_acc_src} name="account" readOnly={(isEdit || currentAccount !== '') ? true : false} value={currentAccount}  label="Associated Passport Account" setValue={setValue} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} prefix="$" id="create-send-account-transfer-form-card-amount-textbox" label="Available Balance" name="amount" valuePattern={/-|\+|e|E/} type="number" variant="outlined" showDecimal={true} value={currentAccountBalance} isEditable={false}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FxTextEdit register={{ ...register("nickName") }} className={errors.nickName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="nickName" label="Card Nick Name" name="nickName" variant="outlined" />
                                            </Grid>
                                            <Grid item container xs={12} justifyContent="space-between" >
                                                <FxCheckEdit register={{ ...register("expressDelivery") }} control={control} className={"fx-input-edit"} data={expressDeliveryData} id="expressDelivery" name="expressDelivery" label="Express Delivery (additional fee $25)" row="vertical" defaultValue={'NO'} setValue={setValue} />
                                                <Grid item xs={12} className='fx-apply-debit-card-main-content-individual-shipping-info-text'>
                                                    <Typography>With this you incur an additional charge as per your fee schedule</Typography>
                                                </Grid>
                                            </Grid>
                                            <FxTextEdit register={{ ...register("id") }} className={"fx-hidden"} control={control} id="create-send-account-transfer-form-card-hidden-id-textbox" name="id" variant="outlined" defaultValue={userData?.id} type="hidden" />
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={12}>
                                                <FxAgreementLinkCard
                                                    agreementTypes={['DebitCard']}
                                                    onFileInfoReady={setFileInfo}
                                                />
                                            </Grid>
                                            <Grid item container xs={12} className="flex column fx-kyc-agree-label">
                                              {!!fileInfo?.length && <Grid item xs={12} className="">
                                                  <FxCheckEdit register={{ ...register("debitCardTerms") }} rules={{ required: "Please click Agree" }} control={control} id="debit-terms-condition" name="debitCardTerms" className="fx-privacy-text" data={debitCardTerms} row="vertical" onChange={(e: any) => { handleChangeReq(e); }} setValue={setValue} />
                                              </Grid>}
                                                <Grid item xs={12} className={'error-message'}>
                                                  {errors.debitCardTerms && renderError(errors.debitCardTerms)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="create-send-account-transfer-form-card-footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer" marginTop={2}>
                                    <FxButton
                                        disableRipple={false}
                                        className={btnDisabled ? "fx-button fx-button-disabled" : "fx-button fx-button-theme"}
                                        id={"create-send-account-transfer-form-card-" + submitButton + "-button"}
                                        type="submit"

                                        disabled={btnDisabled}
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                            submitButton
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </Grid>
        );
    });
