import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { FxChart } from '../../../Input/FxChart/FxChart';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { ISource } from '../../../../types/common.interfaces';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxChartCard } from './FxChartCard';


/**
 * This component handles the display of available balance via chart
 */
export const AvailableBalanceChart: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ props, context } = RegisterComponent(props));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: "chart-card-details", data: { setChartVariation: '1M' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * useEffect to set api values for ledger list in store
   */
  useEffect(() => {
    if(context?.data?.body){
      updateComponentParams(dispatch, "customer-basic-info", { 'ledgerListGraph': context?.data?.body })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.data?.body])

  /**
   * to get chartVariation details
   */
  const chartVariation = useSelector((state: any) => {
    if (state.data["chart-card-details"]) {
      return state.data["chart-card-details"]?.data?.setChartVariation
    }
  });

  const buttons: any = [
    {
      text: '1M',
      value: moment().subtract(1, 'months')
    },
    {
      text: '3M',
      value: moment().subtract(3, 'months')
    },
    {
      text: '6M',
      value: moment().subtract(6, 'months')
    },
    {
      text: '1Y',
      value: moment(new Date().setDate(new Date().getDate() - 364))
    }
  ]

  const permission = {
    entity: "Accounts",
    name: "Ledger",
    operation: "view"
  }

  /**
   * to get ledger card data
   */
  const ledgerListGraph = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']
    }
  });


  /**
   * function to dispatch updated button click value
   */
  function buttonClickInfo(data: any) {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: "chart-card-details", data: { setChartVariation: data?.text } } });
    onSubmit(data?.value)
  }

  /**
   * async function to call API
   * @param data :data
   */
  async function onSubmit(data: any) {
    await callApi(data)
  }
  /**
   * function to call API with changed source
   * @param filterData :data
   */
  async function callApi(filterData: any) {
    let startDate: any
    if (getKey("selectedAccount")?.activationDate) {
      startDate = moment(getKey("selectedAccount")?.activationDate) < moment(filterData) ? moment(filterData).format('MM/DD/YYYY') : moment(getKey("selectedAccount")?.activationDate).format('MM/DD/YYYY');
    }
    const src: ISource = {
      url: "account/id/" + getKey("selectedAccount")?.id + "/balanceHistory",
      method: "POST",
      data:
        {
          pageNumber: 1,
          pageSize: 25,
          sortOptions: {
            "sortOrder": "ASC",
            "sortBy": "balanceDate"
          },
          criteria: {
            filters: [
              {
                "operator": "gte",
                "key": "balanceDate",
                "values": [
                  startDate
                ]
              },
              {
                "operator": "lte",
                "key": "balanceDate",
                "values": [
                  moment().format('MM/DD/YYYY')
                ]
              }
            ]
          }
        }
    }
    dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id: props?.id, source: src } });
  }

  /**
   *chart data
   */
  const chartData = useMemo(() => {
    return ledgerListGraph?.params?.ledgerListGraph?.resources?.map((item: any) => {
      return {
        amount: item.closingBalance,
        date: item.balanceDate
      }}) || [];
  }, [ledgerListGraph?.params?.ledgerListGraph]);

  return (
    <FxChartCard permission={permission} title={'Available Balance'} onButtonClick={buttonClickInfo} buttons={buttons} chartVariation={chartVariation}>
      <FxChart id="home-my-account-balance-chart" showXAxisTicks={true} chartVariation={chartVariation}
               data={chartData} className={'fx-account-insight-graph-dashboard'} />
    </FxChartCard>
  )
})