import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardHeader from '../../../Container/FxCardHeader';
//icons
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/help-phone-icon.svg';
import { ReactComponent as MailIcon } from '../../../../assets/svg/help-mail-icon.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/svg/help-email-icon.svg';



/**
 * This component used to display help info and send request form
 */

export const HelpCenter: React.FC<any> = React.memo(() => {

  const dispatch = useDispatch()

  
  /**
   * useEffect to set title for page
   */
  useEffect(()=>{  
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Help and Support' } } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

 

  return (<>
    <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
      <Grid container className="fx-container" >
        <FxCard id="home-help-center-card" className="fx-theme-passport">
          <FxCardHeader>
          </FxCardHeader>
          <FxCardBody id="home-help-center-card-body" className="fx-card-general ">
            <Grid id="home-help-center-card-body-grid" item container justifyContent="space-between" className="fx-help-center" xs={12}  >
              <Grid item lg={4} xs={12} className="fx-help-center-grid" >
                <Grid container justifyContent="center" ><PhoneIcon /> </Grid>
                <Grid container justifyContent="center">
                  <Typography align='center' variant="h5" id="help-center-one-title" >CONTACT US TOLL FREE NUMBER</Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <Typography align='center' variant="h4" id="help-center-one-grid-title" > 1800-475-0811</Typography>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12} className="fx-help-center-grid" >
                <Grid item container justifyContent="center" xs={12}>
                  <EmailIcon />
                </Grid>
                <Grid container justifyContent="center"><Typography align='center' variant="h5" id="help-center-two-title" >EMAIL</Typography></Grid>
                <Grid container justifyContent="center"><Typography align='center' variant="h4" id="help-center-two-grid-title" > support@prioritypassport.com</Typography>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12} className="fx-help-center-grid" >
                <Grid item container justifyContent="center" xs={12}>
                  <MailIcon />
                </Grid>
                <Grid container justifyContent="center"><Typography align='center' variant="h5" id="help-center-three-title" >WRITE TO US</Typography></Grid>
                <Grid container justifyContent="center"><Typography align='center' variant="h4" id="help-center-three-grid-title" > Priority Technology Holdings Inc.</Typography></Grid>
                <Grid container justifyContent="center"><Typography align='center' variant="h6" id="help-center-three-grid-sub-title" >2001 Westside Parkway, Alpharetta GA 30004</Typography>
                </Grid>
              </Grid>
            </Grid>
          </FxCardBody>
        </FxCard></Grid>
    </Grid>
  </>)
})
