import React, { useEffect } from 'react'; // we need this to make JSX compile
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { LocationDetailsInfoCard } from './LocationDetailsInfoCard';

/**
 * Component: View Location Details
 * Usage: To display location detail cards
 */
export const ViewLocationDetails: React.FC<any> = React.memo((props) => {
        
    let context: any;
    ({ context, props } = RegisterComponent(props));
    let location_info: any = {};
    const dispatch = useDispatch()

    /**
    * set location information
    */
    if (context && context.data && context.data.body) {
        location_info = context.data.body;
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Location Information', backButton: '/company' } } });
    }

    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //check api call completed or not
    let contextState = context?.data_state;
    let dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

    return (
        <Grid item xs={12} container className="fx-container">
            {dataLoadStatus ?
                (context?.data?.body && <LocationDetailsInfoCard id="location-details-info-card" data={location_info} /> )
            :
                <div className={'fx-margin-bottom-2 fx-width-100'}>
                    <FxSkeltonList height="20rem" />
                </div>
            }
        </Grid>
    )
})
