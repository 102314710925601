import React from 'react';
import { FxModalBaseController, FxModalContentProps, FxModalOptionsType, FxModalReturnType, IFxModalBase } from './FxModalBaseController';

/**
 * Component for showing modal
 * Usage
 * Render only once in the root component
 * Important!
 * It is an abstract component and You have to create your own modals based on it using "show" method
 */
export default class FxModalBase extends React.Component {
    private static _fxModalBaseRef: IFxModalBase | null = null;

    public static show = <M, R> (Content: React.ComponentType<FxModalContentProps<M, R>>, options?: FxModalOptionsType): Promise<FxModalReturnType<R>> | undefined => {
        return this._fxModalBaseRef?.show(Content, options);
    }

    render = () => {
        return (
            <FxModalBaseController
                ref={(ref) => FxModalBase._fxModalBaseRef = ref}
            />
        );
    }
}
