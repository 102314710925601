import React, { useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../../assets/svg/Check icon.svg';
import { ReactComponent as Market } from '../../../assets/svg/Money_Market.svg';
import { ReactComponent as Income } from '../../../assets/svg/Manage_Income.svg';
import { FxButton } from '../../Action/FxButton';
import { FxLink } from '../../Action/FxLink';

/**
 * Component used inside the right drawer as a right layout 
 */

interface ITreasureWelcomeCardType {
    onChange: Function
}
export const FxTreasureWelcomeCard: React.FC<ITreasureWelcomeCardType> = React.memo((props) => {

    /**
    Method to redirect on treasure.
     */
    const onClickTreasureLink = useCallback( (event:any) => {
        event.preventDefault();
        window.open('https://www.treasurefi.com/', '_blank');
    }, []);

    return (
        <Grid xs={12} className="fx-treasure-card">

            <Grid item className="fx-treasure-card-wrapper">
                <Grid item container><Typography variant="h4" className="fx-treasure-card-wrapper-welcome">Invest with Treasure through your Passport Account</Typography></Grid>
                <Grid xs={12} className="fx-treasure-card-wrapper-subText">
                    <Grid container justifyContent={"center"} ><Typography className="fx-treasure-card-wrapper-subText-text">Turn your business's idle cash into revenue with Passport Deposit </Typography></Grid>
                    <Grid container justifyContent={"center"}><Typography className="fx-treasure-card-wrapper-subText-text">Account. Earn up to 6% yield
                    <FxLink className={'fx-fee-agreement-link'} onClick={onClickTreasureLink}><span className='fx-submit-agree-color'>* </span></FxLink>
                    </Typography></Grid>
                </Grid>
            </Grid>

            <Grid className='fx-treasure-card-manage'>
                <Grid className="fx-treasure-card-manage-text" container columnSpacing={2}>
                    <Grid item xs={1} className='fx-treasure-card-manage-text-logo'><CheckIcon /></Grid>
                    <Grid item xs={10}>
                        <Grid><Typography variant="h6">Managed Treasuries</Typography></Grid>
                        <Grid ><Typography>Earn higher returns with our specialized treasury investments product. We expertly identify the optimal T-Bill and TIPS maturities, offering higher returns, capital safety, and flexibility. </Typography></Grid>
                    </Grid>
                </Grid>
                <Grid className="fx-treasure-card-manage-text" container columnSpacing={2}>
                    <Grid item xs={1} className='fx-treasure-card-manage-text-logo' ><Market /></Grid>
                    <Grid item xs={10}>
                        <Grid><Typography variant="h6">Managed Money Market</Typography></Grid>
                        <Grid><Typography>Our sophisticated quantitative selection model continuously identifies and invest in the top-performing, government-backed money market funds with approximately zero principal risk. </Typography></Grid>
                    </Grid>
                </Grid>

                <Grid className="fx-treasure-card-manage-text" container columnSpacing={2}>
                    <Grid item xs={1} className='fx-treasure-card-manage-text-logo fx-treasure-card-manage-text-no-path-strock'><Income /></Grid>
                    <Grid item xs={10}>
                        <Grid><Typography variant="h6">Managed Income</Typography></Grid>
                        <Grid ><Typography>Boost your company's portfolio with our highest return product. Managed Income produces significantly higher returns with reduced risk and volatility. </Typography></Grid>
                    </Grid>
                </Grid>

                <Grid item container className="fx-treasure-card-manage-signup" marginTop='3rem'>
                    <FxButton className={"fx-button fx-button-theme"} id={"treasure-signup"} type="submit" onClick={() => props.onChange(2)}>LET'S GET STARTED</FxButton>
                </Grid>
            </Grid>
        </Grid>
    )
})