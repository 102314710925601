import React from 'react';
import { FxSnackBarController, FxSnackBarOptionsType, IFxSnackBar } from './FxSnackBarController';

/**
 * Component for showing toast messages
 * Usage
 * Render only once in the root component
 * Call show() or closeForce() static methods from everywhere
 */
export default class FxSnackBar extends React.Component {
    private static _fxSnackBarRef: IFxSnackBar | null = null;

    public static show = (options: FxSnackBarOptionsType) => {
        this._fxSnackBarRef?.show(options);
    }

    public static closeForce = () => {
        this._fxSnackBarRef?.closeForce();
    }

    render = () => {
        return (
            <FxSnackBarController
                ref={(ref) => FxSnackBar._fxSnackBarRef = ref}
            />
        );
    }
}
