import React, { useMemo } from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { checkPermission } from '../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import { AuthorisedUserDetails } from './AuthorisedUserDetails';
/**
 * Component: UserDetails
 * Usage: To display user cards
 */
export const AuthorisedUser = () => {
        interface RouteParams {
            id: string
        }
        const params = useParams<RouteParams>();
        /**
         *   get ID from  url
         */
           
        const id = params.id
       /**
        *  user details API
        */
        const user_source = {
            url: "/authorizedUser/id/" + id,
            method: "GET"
        }
        
        /**
        * to set permission
        */
        const permission = useMemo(() => {
            return checkPermission({
                entity: "Authorized User",
                name: "User",
                operation: 'View'
            });
        }, []);

        return (
            <>
                {permission ? <AuthorisedUserDetails id={"user-details-config-card" + id} source={user_source} ></AuthorisedUserDetails>
                    : <FxAccessDenied id={'user-details-access-denied'} title={'You are not authorized to access this page'} />
                }
            </> 
        )

    }
