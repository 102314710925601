/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useForm  } from 'react-hook-form';
import { Logger } from '../../../../libs/utils/logger';
import { useIsMobileScreen } from '../../../../libs/utils/utils';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import { FxTextEdit } from '../../../Input';
import { ReactComponent as MultipleUser } from '../../../../assets/svg/multiple-user-icon.svg';
import FxRadioEdit from '../../../Input/FxRadio/FxRadioEdit';
import FxCheckAvailabilityPPI from '../../../Data/FxCheckAvailabilityPPI';

/**
 * Component: AccountIdentifierSignup
 * This component contains the form to set the nickname and payment id
 */

Logger.debug("AccountIdentifierSignup.tsx", "Account Identifier Signup initializing")

export const AccountIdentifierSignup: React.FC<any> = React.memo((props) => {
  const isSmallScreen = useIsMobileScreen();
  const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm();
  const {data} = props;
  const [disabledSubmit, setDisableSubmit] = useState<boolean | undefined>(false);
  const [paymentType, setPaymentType] = useState(data?.paymentType?.value ? data?.paymentType?.value : 'Phone');

  const paymentTypes = [
    { label: props?.details?.phone || props?.details?.mobilePhone, value: "Phone", tag: "Phone" },
    { label: props?.details?.email, value: "Email", tag: "Email" },
    { label: "Create your own", value: "Custom" }
  ];

  /**
   * Method handles the change of the payment type
   * @param event
   */
  const handleTypeChange = (event: any) => {
    if (event.target.value) {
      setPaymentType(event.target.value)
    }

    if(event.target.value === 'Custom'){
      setDisableSubmit(true)
    } else{
      setDisableSubmit(false)
    }
  }

  /**
   * Method handles the form submission
   * @param formData
   */
  async function onSubmit(formData: any) {
    const phoneFormat = props?.details?.phone?.replace(/\D/g, '')
    const email = props?.details?.email
    const index = email?.indexOf('@')

    let ppiId = phoneFormat

    let customId = data?.paymentType?.ppi;
    if (paymentType === 'Custom') {
      ppiId = formData.paymentId
      customId = formData.paymentId;
    }
    else if(paymentType === 'Email'){
      ppiId = email.substring(0, index)
    }

    const req = {
      ppi: ppiId?.trim() + '@ppi',
      paymentType: {
        value: paymentType,
        ppi: customId?.trim()
      },
      nickname: formData.nickname
    }

    if (props.onSubmit) {
      props.onSubmit(req)
    }
  }

  /**
   * Method handles the click operation for going back to beneficial owner screen
   */
  const handleGoBackToDetailsClick = () => {
    props.goBackClick(3);
  }

  /**
   * Use effect to set the value of the payment id if the payment type is custom
   */
  useEffect(() => {
    if(data?.paymentType?.value === 'Custom' && data?.paymentType?.ppi){
      setValue('paymentId', data?.paymentType?.ppi)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Grid container className={'fx-signup-formkyc-account-identifier-form-card'} id="account-identifier-form-card-main-grid" xs={12} justifyContent='center' >
      <Grid item xs={12} sm={isSmallScreen ? undefined : 11} md={isSmallScreen ? undefined : 7}>
        <FxCard id="account-identifier-form-card" className={`fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc ${isSmallScreen ? "fx-signup-formkyc-mobile" : ""} fx-payment-details fx-no-bottom-padding`}>
          <div className="fx-form-edit-profile ">
            <form id="account-identifier-form" onSubmit={handleSubmit(onSubmit)}>
              <FxCardHeader id="account-identifier-form-card-header">
                <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                  <Grid item xs={12} container flexWrap={'nowrap'}>
                    <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                      <MultipleUser />
                    </Grid>
                    <Grid item>
                      <Typography id="account-identifier-card-form-title" variant="h3">
                        Set your account Identifier
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </FxCardHeader>
              <FxCardBody id="account-identifier-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                <Grid item xs={12}>
                  <Grid item xs={12} marginTop={2} marginBottom={3}>
                    <Typography className={'fx-signup-formkyc-subtitle fx-uppercase'}>
                      Set an account nickname
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FxTextEdit register={{ ...register('nickname') }}
                                className={errors.nickname ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                control={control}
                                id="account-identifier-form-card-nickname" label="Account Nickname"
                                name="nickname" variant="outlined"
                                defaultValue={props.data?.nickname}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} marginTop={3}>
                    <Typography className={'fx-signup-formkyc-subtitle fx-uppercase'}>
                      Set a pointer to your account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={`fx-signup-formkyc-info-text ${isSmallScreen ? "fx-word-wrap" : ""}`}>
                      Passport Payment Id is the address of the account. Link your passport account to your Passport Payment Id to seamlessly receive funds instantly.
                    </Typography>
                    <Typography variant="h6" className={`fx-signup-formkyc-info-text ${isSmallScreen ? "fx-word-wrap" : ""}`}>
                      On creation, you have the flexibility to share your Payment ID with anyone who has a Passport account to receive payments swiftly and securely.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} marginTop={3} marginBottom={1}>
                    <Typography className={'fx-signup-formkyc-subtitle-secondary fx-uppercase'}>
                      Please select one of the following options to set your passport payment ID
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} className="fx-info-card-form fx-info-card-form-left-padding">
                    <Grid item xs={12} className="fx-signup-formkyc-payment-id-options">
                      <FxRadioEdit register={{ ...register('paymentIdDetail') }} control={control}
                                   className={'fx-input-edit fx-signup-formkyc-radio-edit'} data={paymentTypes}
                                   id="account-identifier-form-card-radio" name="paymentIdDetail"
                                   defaultValue={paymentType} onChange={handleTypeChange} row={false}
                                   setValue={setValue} />
                    </Grid>
                    {paymentType === 'Custom' && <Grid item xs={8}>
                        <FxCheckAvailabilityPPI
                            id={"account-identifier-form-manage-ppi-check-availability"}
                            currentPPI={data?.paymentType?.ppi}
                            control={control}
                            fieldName={"paymentId"}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            register={register}
                            onVerificationFinished={(status) =>
                              setDisableSubmit(!status)
                            }
                        />
                    </Grid>}
                    <Grid item xs={12} marginY={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              </FxCardBody>
              <FxCardFooter id="account-identifier-form-card-footer" className="fx-footer">
                <Grid container direction="row" justifyContent="flex-end" className={isSmallScreen?"fx-modal-footer fx-footer-kyc":"fx-modal-footer"}>
                  <FxButton variant="contained"
                            className="fx-button fx-button-cancel"
                            id="account-identifier-form-card-go-back-btn"
                            onClick={handleGoBackToDetailsClick}>
                    GO BACK
                  </FxButton>
                  <span className="fx-padding-right-16" />
                  <FxButton
                    disableRipple={false}
                    className={!disabledSubmit ? "fx-button fx-button-theme" : "fx-button fx-button-disabled"}
                    id={"account-identifier-form-card-save-button"}
                    type="submit"
                    disabled={disabledSubmit}
                  >
                    Continue
                  </FxButton>
                </Grid>
              </FxCardFooter>
            </form>
          </div>
        </FxCard>
      </Grid>
    </Grid>
  )
})
