/**
 * string constants
 */
export const AUTH_STRINGS = {
  ERRORS: {
    EMAIL_REQUIRED: "Email is required",
    EMAIL_PLEASE_ENTER: "Please enter your email",
    MOBILE_NUMBER_PLEASE_ENTER: "Please enter your mobile number",
    CUSTOMER_EMAIL_PLEASE_ENTER: "Please enter email",
    CUSTOMER_PHONE_PLEASE_ENTER: "Please enter phone number",
    CUSTOMER_ZIP_PLEASE_ENTER: "Please enter ZIP Code",
    CUSTOMER_SSN_PLEASE_ENTER: "Please enter SSN",
    EMAIL_INVALID_REGEXP: "Invalid email address",
    PHONE_INVALID_REGEXP: "Invalid phone number",
    ZIP_INVALID_REGEXP: "Invalid ZIP Code",
    CVV_INVALID_REGEXP: "Invalid CVV Code",
    ACCOUNT_NUMBER_INVALID_REGEXP: "Invalid Account Number",
    EXT_REF_INVALID_REGEXP: "Invalid External Reference",
    NAME_INVALID_REGEXP: "Invalid Holder Name",
    ROUTING_NUMBER_INVALID_REGEXP: "Invalid Routing Number",
    WIRE_ROUTING_NUMBER_INVALID_REGEXP: "Invalid Wire Routing Number",
    SSN_INVALID_REGEXP: "Invalid SSN",
    EIN_INVALID_REGEXP: "Invalid EIN",
    DATE_INVALID_REGEXP: "Invalid Date",
    DATE_IS_AFTER_MAX_ALLOWED: "Date is after maximum allowed date",
    AGE_MORE_THAN_18: "Age should be more than 18 years",
    PASS_REQUIRED: "Password is required",
    AGREE_PLEASE_ENTER: "Please Agree Terms and conditions",
    PASS_PLEASE_ENTER: "Please enter your password",
    PASS_INVALID_LENGTH: "Password is too short - should be 8 chars minimum",
    PASS_INVALID_REGEXP: "Password must contain letter(s) and number(s)",
    PPI_INVALID_REGEXP: "May only contain one or more of the following characters: A-Z,a-z,0-9,.,-,_,',(,),/,&",

    FUllNAME_REQUIRED: "Full name is required",

    SUBSCRIBER_ACRONYM_PLEASE_ENTER: "Please enter your subscriber acronym",
    ACCOUNT_NUMBER_INVALID_LENGTH:
      "Account Number should not be more than 40 characters",
    NAME_INVALID_LENGTH: "Name should not be more than 45 characters",
    EXT_REF_INVALID_LENGTH:
      "External Reference should not be more than 45 characters",
    ROUTING_NUMBER_INVALID_LENGTH:
      "Routing number should be of 9 numeric value",
    WIRE_ROUTING_NUMBER_INVALID_LENGTH:
      "Wire Routing number should be of 9 numeric value",
    CARD_NUMBER_INVALID_LENGTH:
      "Card Number length should be of 13 to 17 characters",
    ADDRESS_LINE_1_INVALID_LENGTH:
      "Address Line 1 length should not exceed 128 characters",
    ADDRESS_LINE_2_INVALID_LENGTH:
      "Address Line 2 length should not exceed 128 characters",
    CITY_NAME_INVALID_LENGTH: "Enter a valid city Name",
    CITY_NAME_MAX_LENGTH:
    "City Name length should not exceed 25 characters",
    SET_PASS_INVALID_LENGTH:
      "Password is too short - should be 8 chars minimum",
    MIN_AMOUNT: "Amount should be greater than 0.01",
    MAX_AMOUNT: "Amount should be less than 99999999.99",
    OWNERSHIP_PERCENTAGE_INVALID_REGEXP: "Invalid Percentage",
    URL_INVALID_REGEXP: "Invalid Website URL",
    MIN_LENGTH_LABEL:'Display Label is too short - should be 3 chars minimum',
    MIN_OWNERSHIP_PERCENTAGE: "Ownership percentage should be minimum of 0%",
    MAX_OWNERSHIP_PERCENTAGE: "Ownership percentage should not be more than 100%",
    HOLDER_NAME_INVALID_LENGTH:
    "Holder Name length should not exceed 128 characters",
    REQUIRED: "This is required",
    PPI_INVALID_LENGTH:
      "Payment ID should not be more than 250 characters",
      ACCOUNT_NUMBER_IWP_INVALID_LENGTH:
      "Account Number should not be more than 34 characters",
      STATE_INVALID_LENGTH: "State length should not exceed 3 characters",
      CHECK_IMAGE_UPLOAD_SIZE_INVALID: "Check image size cannot exceed 1.4 MB",
    ADDRESS_LINE_1_LENGTH_VALIDATION:
      "Address Line 1 length should be a minimum of 4 and a maximum of 40 characters",
    SECONDARY_ID_ALIEN_REG_INVALID_LENGTH: "ID length should be 7-9 characters for Alien Reg.",
    SECONDARY_ID_PASSPORT_INVALID_LENGTH: "ID length should be 6-9 characters for Passport",
    ACCOUNT_NUMBER_NOT_VALID_LENGTH:"Account Number should not be more than 17 characters",
    RANK_NOT_VALID:"Rank should be between 1 and 100",
    EA_ADDRESS_LINE_1_INVALID_LENGTH:
    "Address Line 1 length should not exceed 35 characters",
    EA_ADDRESS_LINE_2_INVALID_LENGTH:
    "Address Line 2 length should not exceed 35 characters",
  }
};
