/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clean, getPPISupportedPaymentMethods, updateComponentParams, deleteUnwantedRequest, createCommonVerifyProps } from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createSendMyAccount, dataCardProgramTransformation, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { FxSuccess } from '../../Data/FxSuccess';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { ScheduleTopSection } from './ScheduleTopSection';
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { ScheduleVirtualCardHolderDetails } from './ScheduleVirtualCardHolderDetails';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("CreateSendOneTimeVirtualCard.tsx", "create send One Time Virtual Card initializing")

const acc_src = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

/**
 * This component handles the send creation through one time for book method
 */
export const CreateSendOneTimeVirtualCard: React.FC<any> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const history = useHistory()
        const { register, formState: { errors }, handleSubmit, setValue, watch, control, clearErrors, setError, resetField } = useForm();
        const sendMyAccountInfo: any = context?.data?.body;
        let sendMyAccountId: any = '';
        const params = useParams<any>();
        const passedOtherSendId = params.othersId;
        const scheduleId = params?.id;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available

        const card_program_src = {
          url: "account/id/" + getKey("selectedAccount")?.id + "/virtualCard/cardProgram/list",
          method: "POST",
          data: {
            pageSize: 25,
            pageNumber: 1,
            criteria: {
              filters: [],
            },
          },
        };
      /**
       * Api used to fetch authorized user list
       */
        const auth_src = {
          url: "/authorizedUser/list",
          method: "POST",
          data: {
            "pageNumber": 1,
            "pageSize": 25,
            "criteria": {
              "filters": [

              ]
          }
        }
        }

         /**
           * To get method tabs
         */
        const method_Tab = useMemo(() => [
            {
                title: 'ACH',
                link: '/transaction/send/onetime/add/ach',
                disabled: scheduleId ? true : false,
                permission: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CREATE"
                }
            },
            {
              title: "WIRE",
              link: "/transaction/send/onetime/add/wire",
              disabled: !!scheduleId,
              permission: {
                entity: "Accounts",
                name: "Wire Send",
                operation: "CREATE"
            }
            },
            {
              title: 'INTERNATIONAL WIRE',
              link: '/transaction/send/onetime/add/internationalWire',
              disabled: !!scheduleId,
              permission: {
                  entity: "Accounts",
                  name: "International Wire Send",
                  operation: "CREATE"
              }
            },
            {
                title: 'BOOK',
                link: '/transaction/send/onetime/add/book',
                disabled: scheduleId ? true : false,
                permission: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CREATE"
                }
            },
            {
                className: "fx-link-sel",
                title: "VIRTUAL CARD",
                link: "/transaction/send/onetime/add/virtualcard",
                disabled: scheduleId  ? true : false,
                permission: {
                  entity: "Accounts",
                  name: "Virtual Card Send",
                  operation: "CREATE"
                }
              }
        ], []);

        const [isloading, setIsloading] = useState(false);
        const [cardHolderValue, setCardHolderValue] = useState<any>(null);
        const [location, setLocation] = useState<any>();

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if (scheduleId) {
                const src = {
                  url: "/transaction/id/" + scheduleId,
                  method: "GET",
                };
                dispatch({
                  type: "DATA_API_PARAM_CHANGED",
                  payload: { id: "edit-send-to-one-time-virtual-card", source: src },
                });
                updateComponentParams(dispatch, "edit-send-to-one-time-virtual-card", {
                  isEdit: true,
                });
            } else {
                dispatch({
                  type: "DATA_UPDATE_COMPONENT_REDUCER",
                  payload: {
                    id: "page-title-details",
                    data: { title: "Send Money", backButton: "/dashboard" },
                  },
                });
              }
              const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
        }, []);

        /**
        * useEffect to set edit data details
        */
        useEffect(() => {
            if (context?.data?.body) {
                setFormValues(context?.data?.body);
            }
            if(context?.data?.body?.processingDetail?.location){
              getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
            }
        }, [context?.data?.body]);

        /**
        * Method setting the default values for the text edit component from the context data
        */
        function setFormValues(data: any) {
            setValue("amount", data?.amount);
            setValue("purpose", data?.purpose);
            setValue("virtualCardNickName", data?.virtualCardNickName)
        }

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {
                source: {
                    account: {
                        id: data.source
                    }
                },
                method: 'VIRTUAL_CARD',
                type: 'REGULAR',
                amount: data.amount,
                purpose: data.purpose,
                processingDetail:getProcessingDetail(data)
            };

            if(data.destinationEmail){
              req.destination = {
                email: data.destinationEmail
              }
            }

            if (props.isEdit) {
                req["id"] = context?.data?.body?.id;
                const unwantedRequestArray = ["source", "destination", "method", "type"];
                deleteUnwantedRequest(req, unwantedRequestArray);
            }

            req = clean(req);
            setIsloading(true);
            let status: any;
            status = await createSendMyAccount(req);
            if (status?.headers?.url) {
                sendMyAccountId = (status.headers.url).split('/').pop();
            }

            status = processAPIResponse(status)
            setIsloading(false);
            if (status.status) {
                //api success
                const successMessage = sendMyAccountInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
                  const redirectUrl = sendMyAccountInfo? `/transactions/send/${sendMyAccountInfo['id']}`: '/thanks/transactions/send-onetime-virtual-card/';
                  const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                  if(status.responseData?.sessionId){
                  
                  const verifyProps = {
                      ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
                      successMessage : successMessage,
                      redirectUrl: redirectUrl,
                      redirectUrlFromHeader:sendMyAccountInfo?false: true
                  }
                  updateComponentParams(dispatch, props.id, { verifyProps })
              }
              else{
                if (sendMyAccountInfo) {
                  FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Send Updated Successfully!',
                  });
                  history.push('/transactions/send/' + sendMyAccountInfo['id'])
                }
                else {
                  FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Send Created Successfully!',
                  });
                  history.push('/thanks/transactions/send-onetime-virtual-card/' + sendMyAccountId)
                }
            }
          }
            else {
                //api  failed
                FxSnackBar.show({
                  text: status.message,
                }); 
            }

        }

        /**
         * On close handler
         */
        const handleClose = () => {
            if (scheduleId) {
              history.push("/transactions/send/" + scheduleId);
            } else {
              history.push("/dashboard");
            }
          };

        /**
         * Transaction summary object
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: accountLabelTransformation(getKey('selectedAccount'))
            },
            'Purpose': {
                id: 'pirpose',
                value: watch('purpose'),
            },
            'Via': {
                id: 'method',
                value: 'Virtual Card',
            },
            'To Email':{
                id: 'to-email',
                value: watch('destinationEmail'),
            }
        };

        /**
         * On tab change handler
         */
        const tabClickFunction = () => {
            try{
              const data:any = {
                schedule: 'send',
                type: 'one-time',
                formData: { amount: watch('amount'), purpose: watch('purpose')}
              }
              dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch(e){
              Logger.error("CreateSendOneTimeVirtualCard.tsx", "error", e);     
            }
          }


        /**
         * On chnage card holder type
         */
        const onCardHolderChange =(value:any)=>{
          setCardHolderValue(value)
        }

         /**
         * This function is used to get processing details paylod.
         */
        const getProcessingDetail= (data: any) => {
          const processingDetail:any= {
            virtualCard: {
              cardProgram:{
                id:data.cardProgram
              },
              nickname: data.virtualCardNickName
            }
          };

          if(cardHolderValue){
            processingDetail.virtualCard.entity = cardHolderValue
          }
          if(data['location']){
            processingDetail['location'] = {
                'id' : data['location']
            }
          }

          return processingDetail;

        }
           /**
           * Function to handle the closing of the modal.
           */
          
        const handleCloseModal = () => {
          const verifyProps = {...props.verifyProps}
          verifyProps['open'] = false;
          updateComponentParams(dispatch, props.id, { verifyProps });
        };
        return (
          <>
            <ScheduleLayout
                id={'create-send-onetime-virtual-card'}
                type='send'
                loading={isloading}
                submitTitle={props.isEdit ? 'Save': 'Send Money'}
                summaryData={summaryData}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => handleClose()}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                {passedOtherSendId && <FxSuccess id="sucess-to-others" header={"Send added successfully"} description={"Send ID - " + passedOtherSendId} />}
                <ScheduleTopSection id="create-send-one-time-virtual-card-schedule-top-section" schedule="send" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={{}} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-onetime-book-form-card-amount-textbox" sourceId="create-send-onetime-book-form-card-source" purposeId="create-send-onetime-book-form-card-purpose-textbox"/>
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-send-one-time-virtual-card-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                          label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                          value: location?.id,
                      }: null}
                    />
                </Grid>}
                <ScheduleCreateMethodTab tabItems={getPPISupportedPaymentMethods(method_Tab, "onetime")} id="create-send-onetime-book-form-card-tab" tabClick={tabClickFunction}/>

                    <Grid id={props.id} item container xs={12} sm={12}>
                      <Grid xs={12} sm={12}>
                        <Typography id="contact-virtual-card-detail" className="fx-create-schedule-subheading">
                          VIRTUAL CARD DETAILS
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <fieldset id="contact-virtual-card-detail-field-set" className="fx-container-create-schdule-summery fx-container-create-schdule-middle-scroll">
                            <Grid item xs={12} sm={12}>
                              <FxTextEdit
                                register={{ ...register("virtualCardNickName") }}
                                className={
                                  errors.virtualCardNickName
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                control={control}
                                id="create-send-contact-wire-form-card-memo-textbox"
                                label="Card Nick Name"
                                name="virtualCardNickName"
                                variant="outlined"
                                defaultValue={
                                  context?.data?.body?.virtualCardNickName
                                    ? context?.data?.body["virtualCardNickName"]
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FxMaterialSelect
                                register={{ ...register("cardProgram") }}
                                id="create-send-contact-virtual-card-program"
                                name="cardProgram"
                                source={card_program_src}
                                control={control}
                                label="Select Card Program"
                                rules={{ required: true }}
                                dataTransformation={dataCardProgramTransformation}
                                value={""}
                                clearError={clearErrors}
                                className={
                                  errors.cardProgram
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                setValue={setValue}
                              />
                            </Grid>
                          </fieldset>
                        </Grid>
                    </Grid>

                      <ScheduleVirtualCardHolderDetails
                        id="create-send-one-time-virtual-card-schedule-virtual-card-holder-details"
                        source={auth_src}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        onCardHolderChange={onCardHolderChange}
                      />

                  <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        register={{ ...register("destinationEmail") }}
                        className={
                          errors.destinationEmail
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        control={control}
                        rules={{ required:true }}
                        id="notify-email-textbox"
                        label="Email*"
                        type="email"
                        name="destinationEmail"
                        variant="outlined"
                        defaultValue={context?.data?.body?.destinationEmail ? context.data.body.destinationEmail : ''}
                        isEditable={props.isEdit ? false : true}
                      />
                  </Grid>



                {/* <Grid item>
                    <FxTextEdit  register={{ ...register("memo") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-send-onetime-book-form-card-memo-textbox" label="Memo" name="memo" variant="outlined" defaultValue={context?.data?.body?.memo ? context.data.body['memo'] : ''} />
                </Grid> */}
            </ScheduleLayout>
          </>
    )});
