/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../assets/svg/add-new.svg";
import FxModalGeneric from "../../Utils/FxModalGeneric";
import { InvoiceDetails } from "./InvoiceDetails";
import FxCheckEdit from "../../Input/FxCheck/FxCheckEdit";
import { ReactComponent as TickIcon } from '../../../assets/svg/green-outline-tick.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/transaction-edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { useDispatch } from "react-redux";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";


Logger.debug(
  "CardCollectAdvancedOptions.tsx",
  "card collect advanced options form initializing"
);

type FieldValues = Record<string, string>;
type FormValueType = Record<string, any>;
type TFieldValues = Record<string, string | null>;

interface ICardCollectAdvancedOptions {
  id?: string,
  amount?: string,
  saveInvoiceFormData: (data: any) => void;
  invoiceDataAvailable: boolean,
  register: UseFormRegister<TFieldValues>,
  control: Control<FieldValues>,
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<TFieldValues>;
  watch: UseFormWatch<FormValueType>;
  getValues: UseFormGetValues<FormValueType>
  setInvoiceDataAvailable: (value: boolean) => void,
  invoiceData: any;
  openInvoiceDetails?: boolean,
  addInvoice?: boolean,
  addTip?: boolean,
  isEdit?: boolean,
  addSurcharge?: boolean,
  addTag?: boolean,
  billingAddressData?: any,
  isAddTagDisabled?: boolean
}

/**
 * This component handles the advanced options fields for one time collect for card method
 */
export const CardCollectAdvancedOptions: React.FC<ICardCollectAdvancedOptions> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  const dispatch = useDispatch();

  /**
   * useEffect to set the default properties for the accordian details
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
    updateComponentParams(dispatch, props?.id, { openInvoiceDetails : false, addInvoice : false, addSurcharge : false, addTip : false, addTag : false ,isEdit : false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  /**
   *  Method handles the opening of the Invoice Details Modal
   */
 
  const handleAdd = (event: any) => {
    updateComponentParams(dispatch, props?.id, { openInvoiceDetails : true })    
  };
  
  
  /**
   * Handling the edit button from collect create options
   */
  const handleEdit = () => {
    updateComponentParams(dispatch, props?.id, { isEdit : true, openInvoiceDetails : true })    
  }

  /**
   * Handles the closing action of the modal
   */
  const handleCloseModal = () => {
    updateComponentParams(dispatch, props?.id, { openInvoiceDetails : false })    
  }

  /**
   * Method to handle checkbox change event
   * @param event :event object
   */
  const handleCheckboxInterations = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    if (event.target?.type === 'checkbox') {
        const { name, checked } = event.target;
        const paramsToUpdate:any = {};

        if (name === 'addInvoice' || name === 'addSurcharge' || name === 'addTip' || name === 'addTag') {
            paramsToUpdate[name] = checked;
            updateComponentParams(dispatch, props?.id, paramsToUpdate);
        }
    }
};

/**
 * Invoice Buttons
 */
const addInvoiceCheckboxData = [
  {
      label: 'Add Invoice',
      value: 'yes'
  }
];


/**
 * Surcharge Button
 */
const addSurchargeCheckboxData = [
  {
      label: 'Add Surcharge',
      value: 'yes'
  }
];


/**
 * Tip Buttons
 */
const addTipCheckboxData = [
  {
      label: 'Add Tip',
      value: 'yes'
  }
];


/**
 * Tag Buttons
 */
const addTagCheckboxData = [
  {
      label: 'Add Tag',
      value: 'yes',
      checked: props?.addTag
  }
];

  /**
   * uncheck the add tag checkbox when the add tag is disabled
   */
  useEffect(() => {
    if (props.isAddTagDisabled) {
        updateComponentParams(dispatch, props?.id, { addTag: false });
        props.setValue('tagValue', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAddTagDisabled]);

/**
 * Handle Data Removal from invoice when its cleared
 */
const handleDataRemoval = () => {
  props?.saveInvoiceFormData({});
  props?.setInvoiceDataAvailable(false);
}



  return (
    <>
      <Grid item container xs={12} sm={12} marginTop={'1rem'}>
        <fieldset className="fx-container-create-schdule-summery fx-collect-card-advanced-options">
          <Grid item container xs={12} sm={12}>
            <Grid>
              <FxCheckEdit
                register={{ ...props.register("addInvoice") }}
                control={props.control}
                onChange={handleCheckboxInterations}
                data={addInvoiceCheckboxData}
                id="add-invoice-advanced-options-create-collect-onetime-form"
                name="addInvoice"
                label="Add Invoice"
                row="vertical"
                setValue={props.setValue}
                className="fx-collect-card-advanced-options-checkbox"
              />
            </Grid>

            { !props?.invoiceDataAvailable && props?.addInvoice && <Grid item xs={12} sm={12} marginTop={'0.5rem'} marginBottom={'0.5rem'}>
              <FxButton
                id={"add-invoice-details-button"}
                title="Add"
                className="fx-button fx-button-lite"
                variant="contained"
                onClick={(e: any) => handleAdd(e)}
                startIcon={<AddIcon />}
              >
                Add Invoice
              </FxButton>
            </Grid>}

            {/**
             * When data is present
             */}
            { 
            props?.invoiceDataAvailable && props?.addInvoice && 
            <Grid item xs={12} sm={12} marginTop={'0.5rem'} marginBottom={'0.5rem'} display={'flex'} alignItems={'center'}>
                <Grid item xs={6} justifyContent={'flex-end'}>
                  <Grid className="fx-data-saved-label">
                      <Grid className="fx-data-saved-label-icon">
                          <TickIcon/>
                      </Grid>
                      <Grid>
                          <Typography>
                          INVOICE ADDED
                          </Typography>
                      </Grid> 
                  </Grid>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-end'}>
                    <Grid display={'flex'} justifyContent={'flex-end'} gap={"1rem"}>
                        <Grid>
                        <FxButton id={"details-card-edit-button"}
                        variant="contained" className="fx-button  fx-button-action "
                         startIcon={<EditIcon />} onClick={handleEdit}>Edit</FxButton>
                        </Grid>
                        <Grid>
                        <FxButton id={"details-card-remove-button"}
                        variant="contained" className="fx-button  fx-button-action "
                         startIcon={<DeleteIcon />} onClick={handleDataRemoval}>Remove</FxButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid>
              <FxCheckEdit
                register={{ ...props.register("addSurcharge") }}
                control={props.control}
                onChange={handleCheckboxInterations}
                data={addSurchargeCheckboxData}
                id="add-surcharge-advanced-options-create-collect-onetime-form"
                name="addSurcharge"
                label="Add Surcharge"
                row="vertical"
                className="fx-collect-card-advanced-options-checkbox"
                setValue={props.setValue}
              />
            </Grid>

            {props?.addSurcharge && <Grid item xs={12} sm={12} marginTop={'0.5rem'} marginBottom={'0.5rem'}>
              <FxTextEdit
                    register={{ ...props.register('surchargeAmount') }} 
                    control={props.control}
                    className={props.errors.surchargeAmount ? 'border-error-input clear-number-field-arrow' : 'fx-input-edit fx-input-edit-text-layout clear-number-field-arrow'}
                    id="surcharge-amount-advanced-options-create-collect-onetime-form"
                    name="surchargeAmount" 
                    label={'Enter Amount'}
                    setValue={props.setValue}
                    readOnly={false}
                    type="number" variant="outlined" valuePattern={/-|\+|e|E/}
                    defaultValue={''}
                    showDecimal={true}
                    prefix="$"
                />
              </Grid>}

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            <Grid>
              <FxCheckEdit
                register={{ ...props.register("addTip") }}
                control={props.control}
                onChange={handleCheckboxInterations}
                data={addTipCheckboxData}
                id="add-tip-advanced-options-create-collect-onetime-form"
                name="addTip"
                label="Add Tip"
                className="fx-collect-card-advanced-options-checkbox"
                row="vertical"
                setValue={props.setValue}
              />
            </Grid>

            {props?.addTip && <Grid item xs={12} sm={12} marginTop={'0.5rem'} marginBottom={'0.5rem'}>
              <FxTextEdit
                    register={{ ...props.register('tipAmount') }} 
                    control={props.control}
                    className={props.errors.tipAmount ? 'border-error-input clear-number-field-arrow' : 'fx-input-edit clear-number-field-arrow fx-input-edit-text-layout'}
                    id="tip-amount-advanced-options-create-collect-onetime-form"
                    name="tipAmount" 
                    label={'Enter Amount'}
                    setValue={props.setValue}
                    readOnly={false}
                    type="number" variant="outlined" valuePattern={/-|\+|e|E/}
                    defaultValue={''}
                    showDecimal={true}
                    prefix="$"
                />
              </Grid>}
            
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid> 

            <Grid>
              <FxCheckEdit
                register={{ ...props.register("addTag") }}
                control={props.control}
                onChange={handleCheckboxInterations}
                data={addTagCheckboxData}
                id="add-tag-advanced-options-create-collect-onetime-form"
                name="addTag"
                label="Add Tag"
                row="vertical"
                disabled={props.isAddTagDisabled}
                className="fx-collect-card-advanced-options-checkbox"
                setValue={props.setValue}
                updateOptions={props.isAddTagDisabled}
              />
            </Grid>

            {props?.addTag && <Grid item xs={12} sm={12} marginTop={'0.5rem'} marginBottom={'0.5rem'}>
              <FxTextEdit
                    register={{ ...props.register('tagValue') }} 
                    control={props.control}
                    className={props.errors.tagValue ? 'border-error-input clear-number-field-arrow' : 'fx-input-edit fx-input-edit-text-layout clear-number-field-arrow'}
                    id="tag-amount-advanced-options-create-collect-onetime-form"
                    name="tagValue" 
                    label={'Enter Type'}
                    setValue={props.setValue}
                    readOnly={false}
                    variant="outlined"
                    defaultValue={''}
                />
              </Grid>}

          </Grid>
        </fieldset>
      </Grid>

      <FxModalGeneric
        id="card-collect-invoice-details-modal"
        open={props?.openInvoiceDetails ? props?.openInvoiceDetails  : false}
        onClose={handleCloseModal}
        componentProps={{amount: props?.amount, saveInvoiceFormData: props?.saveInvoiceFormData, invoiceDataAvailable: props?.invoiceDataAvailable, data: props?.isEdit ? props?.invoiceData : {}, billingAddressData: props?.billingAddressData}}
        className={"fx-modal-large"}
        component={InvoiceDetails}
        title="Invoice Details"
      ></FxModalGeneric>
    </>
  );
});
