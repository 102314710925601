import React from "react";
import { Typography, TypographyProps } from '@mui/material';
/**
 * Component used for Consent text
 */
type FxConsentTextProps = TypographyProps;
export const FxConsentText: React.FC<FxConsentTextProps> = ({ children, className }) => {
  return (
    <Typography className={`fx-consent-text ${className ? className : ''}`}>
      {children}
    </Typography>
  )
}