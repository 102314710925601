/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { Logger } from '../../../libs/utils/logger';


Logger.debug("Fx404.tsx", "Fx404 initializing")
/**
 * This component handles the wrong invitation page. Email link are getting redirected to this page after invitation code is not validated through api call
 */
export const Fx404: React.FC<any> = React.memo(() => {
       
        return (
            <Grid id="fx-404-grid" item xs={12} className="fx-404">
                <FxCard id="fx-404-card" >
                    <FxCardBody id="fx-404-card-body"  >
                        <Grid item container justifyContent="center" xs={12}>
                            <Typography variant="h3" id="fx-404-first-message" >4</Typography>
                            <Typography variant="h2" id="fx-404-second-message">0</Typography>
                            <Typography variant="h3" id="fx-404-third-message" >4</Typography>
                        </Grid>
                        <Grid item container justifyContent="center" xs={12} >
                        <Typography variant="h5" id="fx-404-fourth-message">Sorry, the page you requested was not found</Typography>
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
        )
    });

