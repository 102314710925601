import React from 'react'; // we need this to make JSX compile
import { CustomerMailingAddress } from '../../Details/CustomerMailingAddress';
import { getKey } from "../../../../libs/utils/storageManager";
/**
 * This component handles the rendering of the ScheduleCards
 */
export const MyMailingAddress = () => {
        const info_source = {
            url: "/customer/id/" + getKey('customerId') + "/mailingAddress",
            method: "GET"
        }

        return  <CustomerMailingAddress id="customer-my-mailing-address" source={info_source}></CustomerMailingAddress>

    }
