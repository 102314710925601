import React from "react";
import { Grid, ListItemIcon } from "@mui/material";
import { useDispatch } from "react-redux";
import { FxLink } from "../Action/FxLink";
import { FxPopOver } from "../Data/FxPopOver";
import { checkMenuDrawerPermission, getSelectedPath } from "./Passport";
import { RegisterComponent } from "../../libs/saga/dataSaga";
import { updateComponentParams } from "../Utils/CommonBaseClass";
import { ReactComponent as ManageIcon } from '../../assets/svg/hamburger-menu-icon.svg';
import { ReactComponent as ContactsIcon } from '../../assets/svg/contacts-emty.svg';
import { ReactComponent as AccountsIcon } from '../../assets/svg/bank-accounts.svg';
import { ReactComponent as TriangleUp } from '../../assets/svg/triangle-up-icon.svg';
import { ReactComponent as TriangleDown } from '../../assets/svg/triangle-down-icon.svg';
import { useLocation } from "react-router";

/**
 * This component handles the Manage Menu Options
 */
export const ManageMenu: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const { selectedAccount, drawerExpand, anchorEl, submenuExpand, onMenuSelect } = props
    const dispatch = useDispatch()
    const location = useLocation();

    /**
     * handle click away from popover
     */
    const handleClose = (event: any, item: any) => {
        event.stopPropagation();
        updateComponentParams(dispatch, props?.id, { anchorEl: null, submenuExpand: false })
        item !== 'backdropClick' && onMenuSelect && onMenuSelect(event);
    };
    const open = Boolean(anchorEl);
    /**
     * Options for manage menu
     */
    const menuOptions = [
        checkMenuDrawerPermission('payees') && { id: 'CONTACTS', label: 'Contacts', url: '/payees', icon: <ContactsIcon/>, className: 'fx-manage-menu-item' },
        checkMenuDrawerPermission('externalaccounts') && { id: 'EXTERNAL_ACCOUNT', label: 'External Accounts', url: '/externalaccounts', icon: <AccountsIcon/>, className: 'fx-manage-menu-item' }
    ].filter(Boolean);
    /**
      * Method to handle Manage Icon Click
      */
    const handleManageClick = (event: any) => {
        event.stopPropagation();
        updateComponentParams(dispatch, props?.id, { anchorEl: event.currentTarget, submenuExpand: true })
    }

    return (<>
      {!drawerExpand && <>
        {(checkMenuDrawerPermission('externalaccounts') || checkMenuDrawerPermission('payees')) && !(selectedAccount?.type === 'DEPOSIT' && location.pathname !== '/home') &&
            <li className={(!!open || getSelectedPath(location) === 6 || getSelectedPath(location) === 7) ? 'fx-menu-passport-collapse-menu-sel' : ''}>
                <FxLink id="menu-left-manage" title="Manage" tooltip={true} onClick={(e) => handleManageClick(e)}>
                    <ListItemIcon>
                        <ManageIcon />
                    </ListItemIcon>
                </FxLink>
            </li>}
      </>}
      {drawerExpand && <>
        {(checkMenuDrawerPermission('externalaccounts') || checkMenuDrawerPermission('payees')) && !(selectedAccount?.type === 'DEPOSIT' && location.pathname !== '/home') &&
            <li className={(!!open || getSelectedPath(location) === 6 || getSelectedPath(location) === 7) ? 'fx-menu-passport-sel fx-manage-menu-link' : 'fx-manage-menu-link'}>
                <Grid item container alignItems="center">
                    <FxLink id="menu-left-manage" onClick={(e) => handleManageClick(e)}>
                        <ListItemIcon>
                            <ManageIcon />
                        </ListItemIcon>
                        Manage
                        <Grid item  justifyContent='center' alignItems='center' display='flex'
                              className="fx-manage-menu-arrow-icon fx-sidenav-bottommenu-main-right">{!submenuExpand ? <TriangleUp /> : <TriangleDown />}</Grid>
                    </FxLink>
                </Grid>
            </li>}
      </>}
        <Grid item container xs={12}>
            <FxPopOver
                id={'manage-menu-list'}
                open={open}
                refElement={anchorEl}
                onClose={handleClose}
                className={`fx-manage-menu-popup ${!drawerExpand ? 'fx-sidenav-collapse-portal-switch-menu settings-nav' : 'fx-sidenav-expanded-popup'}`}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: drawerExpand ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: drawerExpand ? 'top' : 'bottom',
                    horizontal: drawerExpand ? 'center' : 'left',
                }}
                data={menuOptions}
            >
            </FxPopOver>
        </Grid>
    </>)
})