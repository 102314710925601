/* eslint-disable eqeqeq */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logger } from "../../../libs/utils/logger";
import HttpClient from "../../../libs/utils/httpClient";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { RoleInformation } from "./RoleInformation";
import { checkPermission, updateComponentParams } from "../../Utils/CommonBaseClass";
import { getCustomerUrl } from "../../../libs/utils/utils";
import { FxAccessDenied } from "../Index/Passport/FxAccessDenied";
const httpClient = HttpClient.getClient();

Logger.debug("CreateRole.tsx", "CreateRole initializing");
export const CreateRole: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const id = params.id
  useEffect(() => {
    if (id === undefined) {
      setIsLoaded(true)

    }
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const src = {
    url: "/role/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 50,
      "criteria": {
        "filters": [

        ]
      }
    }
  }

  /**
   * API call for roles get
   * @param id
   * @returns
   */
  async function getRoleData(id: any) {
    try {
      const allData: any = {}
      const data: any = await httpClient.get(getCustomerUrl('/role/id/' + id, false));

      if (data) {
        const childRole = data?.data
        allData['childRoleData'] = data?.data;
        if (childRole?.parentRole?.id) {

          const parentData = await httpClient.get(getCustomerUrl('/role/id/' + childRole?.parentRole?.id, false));
          if (parentData) {
            allData['parentRoleData'] = parentData?.data
          }
        }
      }
      return allData
    } catch (err) {
      Logger.error("rolelist.tsx", "error", err);
      return false;
    }
  }

  if (id) {
    getRoleData(id).then((data: any) => {
      if (data) {
        updateComponentParams(dispatch, "role-information", { allRoles: permissionDataTransfer(data) })
        setIsLoaded(true)
      }
    })
  }
  else {
    updateComponentParams(dispatch, "role-information", { allRoles: undefined })
  }
  /**
   * Method checking whether all the permissions are selected for an item
   * @param parentObject
   * @param childObject
   * @returns : returns true if all the permissions are selected and returns false if any of the item are not selected
   */
  const checkAllPermissionsSelected = (parentObject: any, childObject: any) => {
    try {
      if (parentObject?.name == childObject?.name) {
        return parentObject?.permissions?.length === childObject?.permissions?.length;
      }

    }
    catch (e) { 
      Logger.error("CreateRole.tsx", "error", e);
    }

  }


  /**
   * Method For data filling
   * @param allData
   * @returns
   */
  function permissionDataTransfer(allData: any) {
    const permissionTemplate: any = JSON.parse(JSON.stringify(allData['parentRoleData'] ? allData['parentRoleData'] : allData['childRoleData']));
    const childRolesData: any = JSON.parse(JSON.stringify(allData['childRoleData']));
    permissionTemplate?.restrictedEntity?.forEach((item: any) => {
      item.data?.forEach((dataItem: any) => {
        dataItem.permissions?.forEach((permissionItem: any) => {
          childRolesData.restrictedEntity?.forEach((apiPermissionItem: any) => {
            apiPermissionItem.data.forEach((permData: any) => {
              permData.permissions.forEach((permission: any) => {
                if (permission.entity === item.entity && permission.subEntity === dataItem.name && permission.operation === permissionItem.operation) {
                  permissionItem['value'] = 'true';
                }
              })
              if(checkAllPermissionsSelected(dataItem, permData)) {
                dataItem['checked'] = true;
              }
            })
          })


        })
      })
    })

    return permissionTemplate
  }
  const permissionData: any = {
    View: {
      entity: "Role",
      name: "Role",
      operation: "View"
    }
  }

  return (
    <>{isLoaded &&
      <Grid item sm={12} xs={12} className={'fx-create-role fx-form-grid'}>
        {(checkPermission(permissionData.View)) && <Grid className="fx-permissions">
          {/* Role information Component */}
          <RoleInformation id="role-information" source={src} />
        </Grid>}
      </Grid>
    }
      {!checkPermission(permissionData.View) && <FxAccessDenied id={props?.id + "-access-denied"} title={'You are not authorized to access this page'} />}
    </>
  );
});
