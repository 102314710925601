/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-computed-key */
import React, { useRef, useState } from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { Grid, CircularProgress, Typography, Link } from '@mui/material';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive'
import ReCAPTCHA from "react-google-recaptcha";
import moment from 'moment';
import { AUTH_STRINGS } from '../../../constants/strings';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { Logo } from '../../Utils/Logo';
import { FxButton } from '../../Action/FxButton';
import { clean, convertToPhoneRequestFormat, maskEmail, renderPhoneDisplayError, setPhoneValidationOptions } from '../../Utils/CommonBaseClass';
import { ReactComponent as BackIcon } from '../../../assets/svg/signup-back.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxLink } from '../../Action/FxLink';
import DEFAULT_CONFIG from '../../../configs/default.json'
import { processAPIResponse, pushGTM } from '../../../libs/utils/utils';
import { FxOtpInput } from '../../Input/FxOtpInput/FxOtpInput';
import Copyright from '../Auth/Copyright';
import { FxResendOtp } from '../../Data/FxResendOtp';
import { FxButtonGroup } from '../../Action/FxButtonGroup';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import clsx from "clsx";
import FxLocker, { IFxLocker } from '../../Action/FxLocker';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { CustomerType } from '../../../types/common.interfaces';

Logger.debug("SignUpFlow.tsx", "Signup flow initializing")

interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: SignupFlow
 * Usage: User signup flow page
 */
export const SignUpFlow: React.FC<any> = React.memo(
    (props) => {
        const lockerRef = useRef<IFxLocker>(null);
        ({ props } = RegisterComponent(props));
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError, resetField, watch } = useForm();
        // default data to be passed to the state
        const defDetails: any = {
            verifyScreen: false,
            channel: 'EMAIL',
            step: 1,
            isLoading: false,
            link: false,
            isTokenVerified: true,
            customerType: props.details.type ? props.details.type : CustomerType.INDIVIDUAL,
            captchaToken: false
        }
        const history = useHistory();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        // details state holds the data for this page
        const [details, setDetails] = useState<any>(defDetails);
        // formReq state holds the request for the sign up form
        const [formReq, setFormReq] = useState<any>();
        // token state holds the token in the response of the validating api
        const [token, setToken] = useState<any>();

        const individualCustomer = details?.customerType === CustomerType.INDIVIDUAL || details?.customerType === CustomerType.JOINT_TENANCY
        // setting default values from the link
        if (props.details) {
            setValue('inviteCode', props.details.inviteCode)
            setValue('inviteCodeB', props.details.inviteCode)
            if (props.details.email) {
                setValue('email', props.details.email)
                setValue('emailB', props.details.email)
            }
            if (props.details.phoneNumber) {
                setValue('mobileNumber', props.details.phoneNumber)
                setValue('mobileNumberB', props.details.phoneNumber)
            }
        }

        // radio options for account type
        const typeData = [
            { id: 'individual', value: 'INDIVIDUAL', label: 'INDIVIDUAL', selected: details?.customerType === 'INDIVIDUAL' ? true : false },
            { id: 'business', value: 'BUSINESS', label: 'BUSINESS', selected: details?.customerType === CustomerType.BUSINESS ? true : false }
        ];

        // Method handles the api call for verifying invite code, phone number and email details
        async function verifyDetails(payloadData: any, inviteCode: any) {
            try {
                const httpClientNoBearer = HttpClient.newClientCustomHeadersNoBearer({})
                HttpClient.unsetToken();
                const data: any = await httpClientNoBearer.post('/invite/' + inviteCode + '/validate', payloadData).then(response => {
                    setToken(response?.data?.token)
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        // Method handles the api call for creating customer
        async function createCustomer(payloadData: any) {
            try {
                const httpClientNoBearer = HttpClient.newClientCustomHeadersNoBearer({})

                HttpClient.unsetToken();
                const data: any = await httpClientNoBearer.post('/customer/signUp', payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        // Email error render function
        const renderEmailError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return AUTH_STRINGS.ERRORS.EMAIL_PLEASE_ENTER;
            }
            return err.message;
        };

        // Error render function
        const renderError = (err: InputErrType): string => {
            if (err.type === 'required') {
                return 'This is required';
            }
            return err.message;
        };
        // Email error render function
        const setEmailValidationOptions = {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };
        // Method handles the resend code functionality
        const handleResendCode = async () => {
            resetField("enterCode");
            lockerRef.current?.lock();
            const verifyDetailsReq: any = {
                email: individualCustomer ? formReq['email'] : formReq['emailB'],
                phone: individualCustomer ? convertToPhoneRequestFormat(formReq['mobileNumber']) : convertToPhoneRequestFormat(formReq['mobileNumberB']),
                countryCode: individualCustomer ? formReq['mobileNumber-select']?.replace('+', '') : formReq['mobileNumberB-select']?.replace('+', '')
            }
            if(formReq['username']){
                verifyDetailsReq['portalAccess'] = {
                    username: formReq['username']
                }
            }
            let status: any = await verifyDetails(verifyDetailsReq, individualCustomer ? formReq['inviteCode'] : formReq['inviteCodeB']);
            status = processAPIResponse(status)
            if (status.status) {
                // otp send success
                FxSnackBar.show({
                    severity: 'success',
                    text: 'Otp sent successfully',
                });
            }
            else {
                //otp send failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }


        // Code Error render function
        const renderCodeError = (err: InputErrType): string => {
            if (err.type === "required") {
                return "Please enter the OTP";
            }
            return err.message;
        };

        // Method handles to redirect to go to back
        const handleBack = () => {
            setDetails({
                ...details,
                ['step']: 1,
                ['verifyScreen']: false,
                ['captchaToken']: false
            });
        }
        // Method handles the onchange of the account type
        const handleTypeChange = (item: any) => {
            try {
                if (item.value) {
                    setDetails({
                        ...details,
                        ['customerType']: item.value
                    })
                    setValue('type', item.value)
                }
            }
            catch (e) { 
                Logger.error("SignUpFlow.tsx", "error", e); 
            }
        }
        // Method handles the onchange event of the captcha
        const handleCaptchaChange = (value: any) => {
            try {
                setDetails({
                    ...details,
                    ['captchaToken']: value ? true : false
                })
                if (value) {
                    clearErrors('captcha');
                }
            }
            catch (e) { 
                Logger.error("SignUpFlow.tsx", "error", e); 
            }
        }

        // Method to handle on submit event for sign up form
        async function onSubmit(data: any) {
            if (!details?.captchaToken) {
                setError("captcha", {
                    type: "manual",
                    message: "Please verify that you are not a robot",
                });
                return;
            }
            setDetails({
                ...details,
                ['phoneNumber']: individualCustomer ? data['mobileNumber'] : data['mobileNumberB'],
                ['email']: individualCustomer ? data['email'] : data['emailB'],
                ['request']: data
            });
            setFormReq(data);
            let req: any = {
                email: individualCustomer ? data['email'] : data['emailB'],
                phone: individualCustomer ? convertToPhoneRequestFormat(data['mobileNumber']) : convertToPhoneRequestFormat(data['mobileNumberB']),
                countryCode: individualCustomer ? data['mobileNumber-select']?.replace('+', '') : data['mobileNumberB-select']?.replace('+', ''),
                sessionMetadata: props.sessionMetadata
                }
            req = clean(req);
            setDetails({
                ...details,
                ['isLoading']: true
            })
            let status = await verifyDetails(req, individualCustomer ? data['inviteCode'] : data['inviteCodeB']);
            setDetails({
                ...details,
                ['isLoading']: false
            })
            status = processAPIResponse(status)
            if (status.status) {
                //api success
                setTimeout(() => {
                    resetField('enterCode');
                    setDetails({
                        ...details,
                        ['verifyScreen']: true,
                        ['step']: 2,
                        ['phoneNumber']: individualCustomer ? data['mobileNumber'] : data['mobileNumberB'],
                        ['email']: individualCustomer ? data['email'] : data['emailB'],
                        ['channel']: props.details?.email ? 'SMS' : 'EMAIL',
                    });
                    // configuring gtm for user sign up otp verify page
                    const gtmData = {
                        event: "userInvitationOtpVerifyTrigger",
                        "userInvitationCode": props.details.inviteCode,
                        "userType": details?.customerType
                    }
                    pushGTM(gtmData)
                }, 1000);
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        // Method to handle on submit event for verify mobile number form
        async function onSubmitVerify(data: any) {
            let req: any;
            if (details?.customerType === CustomerType.BUSINESS) {
                req = {
                    type: CustomerType.BUSINESS,
                    business: {
                        beneficialOwner:[
                            {
                                firstName: formReq['firstNameB'],
                                lastName: formReq['lastNameB'],
                                countryCode: formReq['mobileNumberB-select']?.replace('+', ''),
                                mobilePhone: convertToPhoneRequestFormat(formReq['mobileNumberB']),
                                email: formReq['emailB'],
                                actAsAuthorizedSignatory: "true",
                            }
                        ],
                        legalName: formReq['legalName'],
                    },
                    inviteCode: formReq['inviteCodeB'],
                    otpDetails: {
                        otp: data['enterCode'],
                        token: token
                    },
                    sessionMetadata: props.sessionMetadata
                }
                formReq['username'] === "" && delete req?.business?.beneficialOwner?.[0]?.portalAccess
            }
            else if(details?.customerType === CustomerType.JOINT_TENANCY)
            {
                req = {
                    type: CustomerType.JOINT_TENANCY,
                    owners: [{
                        isPrimaryOwner:true,
                        email: formReq['email'],
                        mobilePhone: convertToPhoneRequestFormat(formReq['mobileNumber']),
                        firstName: formReq['firstName'],
                        lastName: formReq['lastName'],
                        countryCode: formReq['mobileNumber-select']?.replace('+', ''),
                        portalAccess: {
                            grantAccess: true
                        }
                    }],
                    inviteCode: formReq['inviteCode'],
                    otpDetails: {
                        otp: data['enterCode'],
                        token: token
                    },
                    sessionMetadata: props.sessionMetadata
                }
                formReq['username'] === "" && delete req?.owners?.portalAccess

            }else
            {
                req = {
                    type: details?.customerType,
                    individual: {
                        email: formReq['email'],
                        mobilePhone: convertToPhoneRequestFormat(formReq['mobileNumber']),
                        firstName: formReq['firstName'],
                        lastName: formReq['lastName'],
                        countryCode: formReq['mobileNumber-select']?.replace('+', ''),
                    },
                    inviteCode: formReq['inviteCode'],
                    otpDetails: {
                        otp: data['enterCode'],
                        token: token
                    },
                    sessionMetadata: props.sessionMetadata
                }
                formReq['username'] === "" && delete req?.individual?.portalAccess

            }
            req = clean(req);
            setDetails({
                ...details,
                ['isLoading']: true
            })
            let status = await createCustomer(req);
            setDetails({
                ...details,
                ['isLoading']: false
            })
            status = processAPIResponse(status)
            if (status.status) {
                //api success
                // configuring gtm for user sign up success page
                let gtmData: any;
                if (details?.customerType === CustomerType.BUSINESS) {
                    gtmData = {
                        event: "userSignupSuccessTrigger",
                        "userInvitationCode": formReq['inviteCodeB'],
                        "userType": details?.customerType,
                        "firstName": formReq['firstNameB'],
                        "lastName": formReq['lastNameB'],
                        "legalName": formReq['legalName']
                    }
                }
                else {
                    gtmData = {
                        event: "userSignupSuccessTrigger",
                        "userInvitationCode": formReq['inviteCode'],
                        "userType": details?.customerType,
                        "firstName": formReq['firstName'],
                        "lastName": formReq['lastName']
                    }
                }
                pushGTM(gtmData)
                setTimeout(() => {
                    history.push('/signup/success');
                }, 1000);
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
                if(status.errorCode !== 'EC-BL-0102'){
                    setDetails({
                        ...details,
                        ['step']: 1,
                        ['verifyScreen']: false,
                        ['captchaToken']: false
                    });               
                }
            }
        };

        return (
            <Grid item xs={12} container direction="row" className="fx-signup">
                <Grid item xs={12} container direction="row" alignContent="flex-start" sm={isSmallScreen ? undefined : 12}  >
                    <div className="login-page">
                        <Grid xs={12} sm={10} container justifyContent="center">
                            <Grid xs={12} sm={10} md={5} className={"login-page-content-signup"}>
                                {/* logo */}
                                <div className="login-logo">
                                    <Logo showFull />
                                </div>
                                <Grid item xs={12}>
                                    {/*             Title for sign up first screen                     */}
                                    {!details?.verifyScreen && <Typography id="signup-title" className="fx-login-text" align="center">Get Started with Passport!</Typography>}
                                    {/*             Title for signup otp verify screen                     */}
                                    {details?.verifyScreen && <Grid item container xs={12} >
                                        <Grid item xs={12}>&nbsp;</Grid>
                                        <Grid >
                                            <FxLink onClick={handleBack} id='back-icon' className="fx-signup-back-icon">
                                                <BackIcon />
                                            </FxLink>
                                        </Grid>
                                        <Grid item xs={10} sm={10} >
                                            <Typography id="signup-title" align='center' variant="h3">{props.details?.phoneNumber ? 'Verify your email' : 'Verify mobile number'}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                                {!details?.verifyScreen && <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="login-page-content-card">
                                        <>
                                            <Grid item container>
                                                {!props.details.type && <Grid item xs={12}>
                                                    <FxButtonGroup id="signup-button-group" options={typeData} onClick={handleTypeChange} />
                                                </Grid>}
                                                <Grid item xs={12} >&nbsp;</Grid>
                                                {/*             Individual Container                                 */}
                                                { individualCustomer && <><Grid item xs={12} className ="fx-hidden">
                                                    <FxTextEdit register={{ ...register("inviteCode") }} type="text" className={errors.inviteCode ? "border-error-input fx-input-edit login-email" : props.details?.inviteCode ? "fx-input-edit login-email fx-input-disabled" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="inviteCode_ind" label="Invite Code *" name="inviteCode" defaultValue={props.details?.inviteCode ? props.details.inviteCode : ''} isEditable={props.details?.inviteCode ? false : true} />
                                                    <div className={'error-message'}>
                                                        {errors.inviteCode && renderError(errors.inviteCode)}
                                                    </div>
                                                </Grid>
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit login-email" : props.details?.email ? "fx-input-edit login-email fx-input-disabled" : "fx-input-edit login-email"} control={control} rules={setEmailValidationOptions} id="signup-email_ind" label="Email *" name="email" defaultValue={props.details?.email ? props.details.email : ''} isEditable={props.details?.email ? false : true} />
                                                        <div className={'error-message'}>
                                                            {errors.email && renderEmailError(errors.email)}
                                                        </div>
                                                    </Grid>
                                                    {/* Commented for this sprint and need in next sprint */}
                                                    {/* <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("username") }} type="text" className={errors.username ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: false }} id="username" label="Username" name="username" />
                                                    </Grid>&nbsp; */}
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("firstName") }} type="text" className={errors.firstName ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="firstName_ind" label="First Name *" name="firstName" />
                                                        <div className={'error-message'}>
                                                            {errors.firstName && renderError(errors.firstName)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("lastName") }} type="text" className={errors.lastName ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="lastName_ind" label="Last Name *" name="lastName" />
                                                        <div className={'error-message'}>
                                                            {errors.lastName && renderError(errors.lastName)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxPhoneEdit register={register} className={errors.mobileNumber ? "border-error-input " : props.details?.phoneNumber ? "  fx-input-disabled" : " "} control={control} id="mobileNumber_ind" label="Mobile *" name="mobileNumber" rules={setPhoneValidationOptions(true)} defaultValue={props.details?.phoneNumber} isEditable={props.details?.phoneNumber ? false : true} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                        <div className={'error-message'}>
                                                            {errors.mobileNumber && renderPhoneDisplayError(errors.mobileNumber)}
                                                        </div>
                                                    </Grid>
                                                    </>}
                                                {/*             Business Container                                 */}
                                                {details?.customerType === CustomerType.BUSINESS && <>
                                                    <Grid item xs={12} className ="fx-hidden">
                                                        <FxTextEdit register={{ ...register("inviteCodeB") }} type="text" className={errors.inviteCodeB ? "border-error-input fx-input-edit login-email" : props.details?.inviteCode ? "fx-input-edit login-email fx-input-disabled" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="inviteCode_bus" label="Invite Code *" name="inviteCodeB" defaultValue={props.details?.inviteCode ? props.details.inviteCode : ''} isEditable={props.details?.inviteCode ? false : true} />
                                                        <div className={'error-message'}>
                                                            {errors.inviteCodeB && renderError(errors.inviteCodeB)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("emailB") }} type="email" className={errors.emailB ? "border-error-input fx-input-edit login-email" : props.details?.email ? "fx-input-edit login-email fx-input-disabled" : "fx-input-edit login-email"} control={control} rules={setEmailValidationOptions} id="signup-email_bus" label="Email *" name="emailB" defaultValue={props.details?.email ? props.details.email : ''} isEditable={props.details?.email ? false : true} />
                                                        <div className={'error-message'}>
                                                            {errors.emailB && renderEmailError(errors.emailB)}
                                                        </div>
                                                    </Grid>
                                                    {/* Commented for this sprint and need in next sprint */}
                                                    {/* <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("username") }} type="text" className={errors.username ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: false }} id="username" label="Username" name="username" />
                                                    </Grid>
                                                    &nbsp; */}
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("firstNameB") }} type="text" className={errors.firstNameB ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="firstName_bus" label="First Name *" name="firstNameB" />
                                                        <div className={'error-message'}>
                                                            {errors.firstNameB && renderError(errors.firstNameB)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("lastNameB") }} type="text" className={errors.lastNameB ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="lastName_bus" label="Last Name *" name="lastNameB" />
                                                        <div className={'error-message'}>
                                                            {errors.lastNameB && renderError(errors.lastNameB)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxPhoneEdit register={register} className={errors.mobileNumberB ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} id="mobileNumber_bus" label="Mobile *" name="mobileNumberB" rules={setPhoneValidationOptions(true)} defaultValue={props.details?.phoneNumber} isEditable={props.details?.phoneNumber ? false : true} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                        <div className={'error-message'}>
                                                            {errors.mobileNumberB && renderPhoneDisplayError(errors.mobileNumberB)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FxTextEdit register={{ ...register("legalName") }} type="text" className={errors.legalName ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={{ required: true }} id="legalName_bus" label="Legal Business Name *" name="legalName" />
                                                        <div className={'error-message'}>
                                                            {errors.legalName && renderError(errors.legalName)}
                                                        </div>
                                                    </Grid>
                                                    
                                                    </>}
                                                <Grid item xs={12} sm={12} container>
                                                    <Grid item xs={12} sm={8} >
                                                        <ReCAPTCHA className="fx-recaptcha"
                                                            sitekey={DEFAULT_CONFIG['SITE_KEY']}
                                                            onChange={handleCaptchaChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography id="captcha-description" variant="h5">Google Captcha prevents hackers and robots from accessing your Passport account.</Typography>
                                                    </Grid>
                                                    {errors.captcha && <div className={'error-message'}>
                                                        {renderError(errors.captcha)}
                                                    </div>}
                                                </Grid>
                                            </Grid>
                                        </>
                                        <div className="flex login-buttonarea">
                                            <FxButton
                                                disableRipple={false}
                                                className={"fx-button login-signin"}
                                                id="signup-signin"
                                                type="submit"
                                            >
                                                {details?.isLoading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                    'Sign Up'
                                                )}

                                            </FxButton>
                                        </div>
                                        <span className="login-page-content-card-terms">By clicking "Sign Up" I agree to Passport's  <a target="_blank" id="terms-of-service" href="https://prioritycommerce.com/wp-content/uploads/2022/10/Passport-End-User-License-Agreement.pdf"> Terms of Use </a> , <a target="_blank" id="privacy-policy" href="https://prioritycommerce.com/privacy"> Privacy Policy </a> and to receive electronic communication about my accounts and services per <a target="_blank" id="terms-of-service" href="https://prioritycommerce.com/wp-content/uploads/2022/10/Passport-End-User-License-Agreement.pdf">  Passport's Electronic Communication Agreement.</a> </span>
                                    </div>
                                </form>}
                                {/* Verification code section starts here */}
                                {details?.verifyScreen &&
                                    <form onSubmit={handleSubmit(onSubmitVerify)}>
                                        <div className="login-page-content-card fx-form-edit-profile">
                                            <Grid className="flex column login-page-content-card-content">
                                                <Grid item xs={12}>
                                                    <Typography id="verify-code-title" className="form-label fx-login-info-label">{props.details?.phoneNumber ?
                                                        'We have sent an OTP to ' + maskEmail(details?.email) + ' to verify details' : 'We have sent an OTP to registered mobile number ' + details?.phoneNumber}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction="row" >
                                                    <Grid item xs={12} className="login-page-content-card-content-padding">
                                                        {/* <FxTextEdit register={{ ...register("enterCode") }} type="number" className={errors.enterCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="verify-code" label="Enter Code" name="enterCode" /> */}
                                                        <FxOtpInput
                                                            register={{ ...register("enterCode") }}
                                                            className={
                                                                errors.enterCode
                                                                    ? "border-error-input fx-input-edit"
                                                                    : "fx-input-edit"
                                                            }
                                                            control={control}
                                                            rules={{ required: true }}
                                                            id="verify-code"
                                                            name="enterCode"
                                                        />
                                                        {errors.enterCode && (
                                                            <div className={"error-message"}>
                                                                {renderCodeError(errors.enterCode)}
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid container alignItems="center">
                                                    <FxLocker
                                                        initiallyLocked
                                                        ref={lockerRef}
                                                        renderLocked={(remainedTime) => (
                                                            <Grid container>
                                                                <Grid item>
                                                                    Resend OTP in &nbsp;
                                                                </Grid>
                                                                <Grid item className='login-page-content-card-content-remained'>
                                                                    {`${moment.utc(remainedTime * 1000).format('mm:ss')}s`}
                                                                </Grid>
                                                            </Grid>
                                                        )}>
                                                        <Grid>
                                                            Didn't get it?
                                                        </Grid>
                                                        <Grid item justifyContent="center">
                                                            <FxResendOtp id="resend-code" className={"login-page-content-card-content-resndbtn"} onClick={handleResendCode} buttonName="Resend OTP" />
                                                        </Grid>
                                                    </FxLocker>
                                                </Grid>
                                                <div className="flex login-buttonarea">
                                                    <FxButton
                                                        disabled={watch('enterCode')?.length === 6 ? false : true}
                                                        disableRipple={false}
                                                        className={ clsx ("fx-button", "login-signin " , watch('enterCode')?.length === 6 ? "" : "fx-button-theme-disabled")}
                                                        id="forgot-password-signin"
                                                        type="submit"
                                                    >
                                                        {details?.isLoading ? (
                                                            <CircularProgress
                                                                size={20}
                                                                style={{
                                                                    color: 'white',
                                                                }}
                                                            />
                                                        ) : (
                                                            'VERIFY AND SIGN UP'
                                                        )}
                                                    </FxButton>
                                                </div>
                                            </Grid>
                                        </div>
                                    </form>
                                }
                                {/* Verification code section ends here */}
                                {!details?.verifyScreen && <Grid item xs={12} className={"login-page-bottom"}>
                                    <span className="login-page-bottom-bold" >Already have an account? <Link href="/login" >Login</Link></span>
                                    <Copyright />
                                </Grid>}
                            </Grid>
                        </Grid>
                        {details?.verifyScreen && <Grid item xs={12} className={"login-page-bottomstatic"}>
                           <Copyright />
                        </Grid>}
                    </div>
                </Grid >
            </Grid >
        );
    });
