/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import HttpClient from '../../../../libs/utils/httpClient';
import { getSubtypeDescriptionByName, month_options, updateComponentParams } from '../../../Utils/CommonBaseClass';


Logger.debug("TaxDetailInfoCard.tsx", "Tax Detail Info Card initializing");
/**
 * @author Ritish
 * Component: Tax Detail Info Card
 * Usage: To display the Tax Related information
 */

// Helper function to create section columns
const createSectionColumns:any = (columns: { label: string, value: string }[]) => columns;

interface ITaxDetailInfoCard {
    id: string;
    data: any;
    irsTaxData?: any;
}

const httpClient = HttpClient.getClient();

export const TaxDetailInfoCard: React.FC<ITaxDetailInfoCard> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        ({ props } = RegisterComponent(props));
        let irsTaxData: any;

        /**
         * Method cals the taxPayment/irsTaxType API to get the Tax Type Data
         * @returns IRS Details
         */
        async function getIrsTaxDetailData() {
            try {
              const customerDetails = await httpClient.get('taxPayment/irsTaxType').then(response => {
                return response.data?.irsTaxType;
            })
            return customerDetails;
            } catch (e) {
              Logger.error("FxAccountTransferForm.tsx", "Error while fetching account details", e);
              return null;
            }
          }

          /**
           * Wrapper method to call the taxPayment/irsTaxType API Method
           */
          async function apiCall(){
            irsTaxData = await getIrsTaxDetailData();
            updateComponentParams(dispatch, props.id, { irsTaxData: irsTaxData })
          }
        
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            apiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        // declare tax payer details information left section columns
        const tax_payer_details_information_section_left = createSectionColumns([
            {
                label: 'Tax Identification Number(TIN)',
                value: 'tin',
            },
            {
                label: 'Taxpayer Name Control',
                value: 'taxpayerNameControl',
            },
        ]);

        // declare tax payer details information left section columns
        const tax_payer_details_information_section_right = createSectionColumns([
            {
                label: 'Taxpayer Name',
                value: 'taxpayerName',
            }
        ]);
       
        // declare tax details information left section columns
        const tax_details_information_section_left = createSectionColumns([
            {
                label: 'Tax Authority',
                value: 'taxAuthority',
            },
            {
                label: 'Tax Type',
                value: 'taxType',
            },
            {
                label: 'Sub type',
                value: 'taxSubtype',
            },
            {
                label: 'Tax Period',
                value: 'taxPeriod',
            },
        ]);

        // declare tax details information left section columns
        const tax_details_information_section_right = createSectionColumns([
            {
                label: 'Tax Amount',
                value: 'taxAmount',
                type: 'fri://fieldtype/currency'
            },
            {
                label: 'Penalty Amount',
                value: 'penaltyAmount',
                type: 'fri://fieldtype/currency'
            },
            {
                label: 'Interest Amount',
                value: 'interestAmount',
                type: 'fri://fieldtype/currency'
            }
        ]);


        /**
         * This method processes the Tax Type Data received from the API and converts it into a component friendly form
         * @param taxTypeString 
         * @returns 
         */
        const processTaxType = (taxTypeString: string) => { 
            const taxTypePrefix = taxTypeString.substring(0, 4); 
            const subtypeCode = taxTypeString.substring(4);
          
            for (const taxType of props?.irsTaxData) {
                if ((taxType.taxTypePrefix === taxTypePrefix) && taxType.taxTypePrefix) {
                    const subtypeExists = taxType.validSubType.some((subtype: any) => subtype.name === subtypeCode);
                    if (subtypeExists) {
                        return taxType.formNumber+' '+taxType.formName;
                    }
                  }
              }
            // If no match found, return an error or null
            return null;
          }

        /**
         * This method processes the Tax Type Data received from the API and converts it into a component friendly form
         * to store in the tax subType node
         * @param taxTypeString 
         * @returns 
         */
        const processTaxSubtype = (taxSubtype: string) => {
            const subtypeCode = taxSubtype.substring(4);
            return getSubtypeDescriptionByName(subtypeCode, props?.irsTaxData)
        }

        /**
         * This method checks if the TaxTypePrefix provided by the API includes Quarters or not
         * @param taxTypePrefix 
         * @returns 
         */
        function checkIsValidQuarter(taxType: string): boolean {
            const taxTypePrefix = taxType?.substring(0,4);
            const subtypeCode = taxType?.substring(4);
            const taxTypeNode = props?.irsTaxData?.find(
                (taxType: any) => taxType?.taxTypePrefix === taxTypePrefix && taxType?.validSubType?.some(
                  (subtype: any) => subtype?.name === subtypeCode
                )
              );

            return taxTypeNode && taxTypeNode?.validMonths?.length === 4 && taxTypeNode?.validMonths?.every((month: any) => ['03', '06', '09', '12'].includes(month));
          }

        /**
         * This method returns the corresponding Quarter Name as per the Month Number 
         * @param monthValue 
         * @returns 
         */
        function validQuarterLabel(monthValue: string): string | null {
            const quarterOptions = [
                {label: 'Quarter 1', value: '03'},
                {label: 'Quarter 2', value: '06'},
                {label: 'Quarter 3', value: '09'},
                {label: 'Quarter 4', value: '12'},
            ]
            
            const option = quarterOptions?.find((option: any) => option?.value === monthValue);
            return option ? `${option.label},` : null;
        }

        /**
         * This method helps in getting the corresponding Month Name from the Month Number value given by the GET API
         * @param value 
         * @returns 
         */
        function getMonthOrQuarterLabel(taxDetails: { taxType: string } , monthValue: string): string | null {
            const isQuarterValid: boolean = checkIsValidQuarter(taxDetails?.taxType);

            if(isQuarterValid){
                return validQuarterLabel(monthValue)
            }

            const monthOptions = month_options;
            const foundMonth = monthOptions.find(month => month.value === monthValue);
            return foundMonth ? foundMonth.label : null;
          }

        /**
         * This method is used to transform purpose of transaction.
        */
        const dataTransformerForPurpose = (data: any) => {
            const newData = { ...data };
            newData.tin = data?.processingDetail?.taxDetail?.tinLast4 ? "*****"+data?.processingDetail?.taxDetail?.tinLast4 : ''
            newData.taxpayerNameControl = data?.processingDetail?.taxDetail?.taxpayerNameControl ? data?.processingDetail?.taxDetail?.taxpayerNameControl : ''
            newData.taxpayerName = data?.processingDetail?.taxDetail?.taxpayerName ? data?.processingDetail?.taxDetail?.taxpayerName : ''
            newData.taxAuthority = data?.processingDetail?.taxDetail?.taxAuthority ? data?.processingDetail?.taxDetail?.taxAuthority : ''
            newData.taxAmount = data?.amountDetails?.taxAmount ? data?.amountDetails?.taxAmount : ''
            newData.penaltyAmount = data?.amountDetails?.penaltyAmount ? data?.amountDetails?.penaltyAmount : ''
            newData.interestAmount = data?.amountDetails?.interestAmount ? data?.amountDetails?.interestAmount : ''
            newData.taxPeriod = data?.processingDetail?.taxDetail?.taxMonth ? getMonthOrQuarterLabel(data?.processingDetail?.taxDetail, data?.processingDetail?.taxDetail?.taxMonth) + ' 20' + data?.processingDetail?.taxDetail?.taxYear : ''
            newData.taxType = data?.processingDetail?.taxDetail?.taxType ? processTaxType(data?.processingDetail?.taxDetail?.taxType) : ''
            newData.taxSubtype = data?.processingDetail?.taxDetail?.taxType ? processTaxSubtype(data?.processingDetail?.taxDetail?.taxType) : ''
            return newData;
        }
       
        
        return (
            <>
                {/** Tax Payer Details Card */}
                {props?.irsTaxData && <FxInfoCard 
                    id={"tax-payer-details-info-card-"+props.id} 
                    title="TAXPAYER DETAILS" 
                    leftcolumn={tax_payer_details_information_section_left} 
                    rightcolumn={tax_payer_details_information_section_right} 
                    data={props?.data} 
                    dataTransformer={dataTransformerForPurpose}
                />}
                
                {/** Tax Details Card */}
                {props?.irsTaxData && <FxInfoCard 
                    id={"tax-details-info-card-"+props.id} 
                    title="TAX DETAILS" 
                    leftcolumn={tax_details_information_section_left} 
                    rightcolumn={tax_details_information_section_right} 
                    data={props?.data} 
                    dataTransformer={dataTransformerForPurpose}
                />}

            </>
        )
    }
)
