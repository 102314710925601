/**
 * Component:Lead Detail Card
 * This component handles the  Leads edit component
 */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditLeadsInvite } from './EditLeadInvite';

type leadType = {
    id: string;
}
export const EditLeadInviteHome: React.FC<leadType> = React.memo((props) => {

    const dispatch = useDispatch();
    const params = useParams<any>();

    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'lead-details-edit-page' } });

    }, [dispatch])

    const src = {
        url: `/lead/id/${params?.id}`,
        method: "GET"
    }

    return (
            <EditLeadsInvite id="lead-details-edit-page" source={src} isInviteCliked={true} />
    )
})
