import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { Logo } from '../../Utils/Logo';
import { FxMerchantCreationDrawerLayout } from '../../DrawerPages/DrawerLayaouts/FxMerchantCreationDrawerLayout';
import { AddMerchant } from './AddMerchant';
import { FxCongratulationCard } from '../../Data/FxCongratulationCard';
import { useHistory } from 'react-router-dom';

Logger.debug("FxMerchantDrawer.tsx", "Direct Funded Merchant Creation Form initializing")

/**
 * Component used inside the right drawer to create Merchant
 */

interface IFxMerchantDrawer {
    onDrawerClose: (event: any, payload?: any) => void;
    merchantId?:string
}

export const FxMerchantDrawer: React.FC<IFxMerchantDrawer> = ({ onDrawerClose }) => {

    const [merchantCreated, setMerchantCreated] = useState(false);

    /**
     * This code snippet handles the Visual text that needs to be displayed on the Left Side of the drawer layout
     */
    const merchantLayoutHeader = <>
        <h2>Activate Card&nbsp;</h2>
        <h2>Payments on your</h2>
        <div>
            <Logo />
        </div>
        <h2>Account</h2>
    </>

    /**
     * Method handles the action to be performed when the Continue button is hit
     * @param event 
     */
    const goToCompanyInfoPage = (event:any) => {
        onDrawerClose(event,{});
    }

    /**
     * The note message to be displayed below the 'Continue' Button on the Drawer
     */
    const noteMessage = 'Your application is submitted successfully. The underwriting process is underway and may take 3-4 days for activation. Thank you for your patience.';

    return (
        <Grid container id="fx-df-merchant-creation-grid">
            <Grid item className={'fx-drawer-left-content'}>
                <FxMerchantCreationDrawerLayout id="df-merchant-creation-grid-left-layout" layoutHeader={merchantLayoutHeader}/>
            </Grid>
            <Grid item xs justifyContent={"center"} className={'fx-drawer-right-content fx-merchant-create-form-main-grid'}>
                { !merchantCreated ? <AddMerchant id="df-merchant-creation-form" setMerchantCreated={setMerchantCreated} onDrawerClose={onDrawerClose}/> : <FxCongratulationCard message={'You have successfully created the merchant.'} backButtonText={"Continue"}  onButtonClick={goToCompanyInfoPage} iconName="merchant" noteContent={noteMessage}/>}
            </Grid>
        </Grid>
    )
}