import React from 'react';
import { Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
Logger.debug("FxSSNView.tsx", "Fx SSN View initializing")
/**
 * Component used to view SSN values
 */
interface ISSNView {
  id: string;
  value?: any;
  character?: any;
  className?: any;
  unMask?: boolean;
  tooltip?: any;
}
export const FxSSNView: React.FC<ISSNView> = ({ value, tooltip, character, className, unMask = false, id }) => {
  const format = (value: any, unMask: boolean) => {
    value = value.toString().replace(/\D/g, '');
    if (value.length <= 3) {
      return value;
    }
    else if (value.length <= 4) {
      return "****" + value;
    } else if (value.length <= 5) {
      return value.replace(/^(\d{3})-?(\d{1,2})$/, '$1-$2');
    } else {
      if (unMask) {
        return value.replace(/^(\d{3})-?(\d{2})-?(\d{1,10})$/, '$1-$2-$3');
      }
      else {
        return value.replace(/^(\d{3})-?(\d{2})-?(\d{1,10})$/, '***-**-$3');
      }
    }
  };
  return <Typography title={tooltip ? format(value, unMask) : ''} id={id} className={className ? className : ''}>{!unMask ? (character ? (format(value, unMask).replace(/\*/g, character)) : (format(value, unMask))) : (format(value, unMask))}</Typography>;
}