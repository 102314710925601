/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../libs/utils/logger';
import { ReactComponent as ResendIcon } from '../../assets/svg/resend-icon.svg';

Logger.debug("FxResendOtp.tsx", "fx resend otp initializing")

interface FxResendOtpProps extends PropsWithChildren<React.HTMLAttributes<HTMLAnchorElement>> {
    resendStatus?: boolean;
    buttonName?: string;
}

/**
 * component for resend otp
 */
export const FxResendOtp: React.FC<FxResendOtpProps> = React.memo(
    (props) => {
        return (
            <Grid className={"fx-resend-otp"}>
                <a id={props.id} className={props.resendStatus? "fx-blur "+props.className:props.className}  onClick={props.onClick}>
                    <ResendIcon />{props.buttonName}
                </a>
            </Grid>
        );
    });
    