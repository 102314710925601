import React from 'react'
import { getKey } from '../../../libs/utils/storageManager';
import { AlertDrawerComponent } from './AlertDrawerComponent';

/**
 * Component: Alert Drawer
 * Usage: To display list of alerts in a drawer
 */

export const AlertDrawer: React.FC<any> = React.memo((props) => {

  const src = {
    url: "/authorizedUser/id/"+ getKey('authUserId') + "/alert/list",
    method: "POST",
    // expiry: 3600,
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "sortOptions": {
        "sortBy": "createdOn",
        "sortOrder": "DESC"
      },
      "criteria": {
        "filters" : [
          {
            "operator": "eq",
            "key": "status",
            "values": [
              "UNREAD" 
            ]
          }
        ] 
      }
    }
  }

  return (
    props?.alertsOpen && <AlertDrawerComponent id='alert-drawer-component' source={src} alertsOpen={props?.alertsOpen} handleClose={props?.handleAlertsClose} showUnread={true} />
  )
})
