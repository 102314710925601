/* eslint-disable  @typescript-eslint/no-explicit-any */
import { put, takeLatest } from "redux-saga/effects";

function* getEntityInfo(action: any) {
  yield put({ type: "SET_ENTITY_INFO", value: action.payload, entity: action.entity });
}

export default function* entityInfoSaga() {
  yield takeLatest("GET_ENTITY_INFO", getEntityInfo);
}
