/**
 * Copying code and hide or display value
 */
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { FxCopyToClipboard } from '../../Data/FxCopyToClipboard';
import { ReactComponent as VerifiedIcon } from '../../../assets/svg/check-verified.svg';
import { ReactComponent as VisibilityOff } from '../../../assets/svg/eye-visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../../assets/svg/eye-visibility-on.svg';
import { truncateChars } from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
Logger.debug("FxLabelWithCopy.tsx", "Fx label with copy initializing")
interface IFxLabelWithCopyProps {
    id?: any;
    value: any;
    truncateLength?: any
}
const FxLabelWithCopy: React.FC<IFxLabelWithCopyProps> =
    ({ id, value, truncateLength }) => {
        const password = value;
        const [showPassword, setShowPassword] = useState(false);
        const [copied, setCopied] = useState(false);
        /**
         * password toogle event
         */
        const handleTogglePassword = () => {
            setShowPassword(!showPassword);
        };
        /**
         * copying password
         * @param e 
         */
        const handelClick = (e: any) => {
            e.stopPropagation();
            setCopied(true);
        };
        /**
         * Covert value to password char
         * @param value 
         * @returns 
         */
        function setPasswordChar(value: any, truncateLength?: number): string {
            try {
                value = truncateChars(value, truncateLength ? truncateLength : 20);
                return value
                    .split('-')
                    .map((item: string) => '*'.repeat(item.length))
                    .join('-') || '';
            } catch (error) {
                return ''; 
            }
        }
        

        return (
            <Grid>
                <div className='fx-label-with-copy'>
                    <div >
                        {!!password &&
                            <Typography variant="body1" className="fx-label-with-copy-passwod">
                                {showPassword ? truncateChars(password, truncateLength ? truncateLength : 20) : setPasswordChar(password)}
                            </Typography>
                        }
                    </div>
                    <Typography
                        variant="caption"
                        className='fx-label-with-copy-label'
                        onClick={handleTogglePassword}
                    >
                        {showPassword ? <VisibilityOff className={'fx-password-icon'} /> : <VisibilityOn className={'fx-password-icon'} />}
                        <FxCopyToClipboard onClick={handelClick}
                            hideSnackbar={true}
                            id="passport-customer-token-copy-to-clipboard"
                            value={value}
                            message="Copied to clipboard"
                        />
                    </Typography>
                </div>
                {copied && <Grid container alignItems={'center'} className="fx-label-with-copy-copied"><VerifiedIcon /><Typography>API Key copied</Typography></Grid>}
            </Grid>
        );
    };

export default FxLabelWithCopy;
