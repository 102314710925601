import React from "react";
import { OutlinedTextFieldProps, Grid } from "@mui/material";
import { UseFormRegisterReturn, UseFormSetValue, ControllerProps } from 'react-hook-form';
import { Logger } from "../../../libs/utils/logger";
import { FxTextEdit } from "../../Input";

/**
 * Component used for edit text
 */
Logger.debug("FxTextEdit.tsx", "Fx Text Edit initializing")

type FxTextEditType = ControllerProps & OutlinedTextFieldProps;

type TFieldValues = Record<string, string>;

interface FxTextEditProps extends Partial<FxTextEditType> {
    name: string;
    maxLength?: number;
    min?: number | string;
    setValue?: UseFormSetValue<TFieldValues>;
    onChange?: (event: any, data?: any) => void;
    register?: UseFormRegisterReturn;
    isEditable?: boolean;
    valuePattern?: any;
    suffix?: string;
    showDecimal?: boolean;
    passwordIcon?: boolean;
    max?: number;
    suffixIcon?: React.ElementType;
    prefixIcon?: React.ElementType;
    notAllowPastDate?: boolean;
    notAllowFutureDate?: boolean;
    readOnly?: boolean;
    formatDate?: string;
    onWheel? : React.WheelEventHandler<HTMLDivElement> | undefined,
}


export const FxPercentageTextField: React.FC<FxTextEditProps> =
  (props) => {


    return (
        <Grid item xs className="fx-percentage-textbox">
            <FxTextEdit
                register={props?.register}
                className={props?.className || "fx-input-edit clear-number-field-arrow"}
                control={props?.control}
                id={props?.id}
                name={props?.name}
                variant="outlined"
                suffix='%'  
                type="number"
                valuePattern={/-|\+|e|E/}
                isEditable={props.isEditable} 
                onWheel={props.onWheel}    
                placeholder={props.placeholder}
                value={props.value}
            />
        </Grid>
    )
}