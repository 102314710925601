/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import FxCardBody from '../../../../Container/FxCardBody';
import { maskAccountNumber, stateValueFormatter, validateCoOwnerData } from '../../../../Utils/CommonBaseClass';
import { FxDelete } from '../../../../Data/FxDelete';
import FxCard from '../../../../Container/FxCard';
import { FxSectionInfoCard } from '../../../Cards/FxSectionInfoCard';
import FxCardHeader from '../../../../Container/FxCardHeader';

//icons
import { ReactComponent as TriangleDown } from '../../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../../assets/svg/triangle-up-icon.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/edit-new.svg';
import { useMediaQuery } from 'react-responsive';
import { JointTenancyCoOwnerEdit } from './JointTenancyCoOwnerEdit';

//component used to view individual owner details

export const JointTenancyCoOwnerCardView: React.FC<any> = React.memo((props) => {

    const [showDetails, setShowData] = useState(false);
    const [deleteBusinessOwner, setDeleteBusinessOwnerOpen] = useState(false);
    const [editView, setEditView] = useState(false);
    const [editDetails, setEditDetails] = useState<any>();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    const ownerProfileDetails = [
        {
            label: 'First Name',
            value: 'firstName'
        },
        {
            label: 'Middle Name',
            value: 'middleName'
        },
        {
            label: 'Last Name',
            value: 'lastName'
        },
        {
            label: 'Date of Birth',
            value: 'dob'
        },
        {
            label: 'SSN',
            value: 'ssn'
        },
        {
            label: 'Phone Number',
            value: 'mobilePhone'
        },
        {
            label: 'Email',
            value: 'email'
        }
    ]

    const ownerProfileAddress = [
        {
            label: 'Address Line 1',
            value: 'addressLine1'
        },
        {
            label: 'Address Line 2',
            value: 'addressLine2'
        },
        {
            label: 'City',
            value: 'city'
        },
        {
            label: 'State',
            value: 'state'
        },
        {
            label: 'Zip',
            value: 'zip'
        }
    ]
    /**
     * handle business owner view
     */
    const showHandleDetails = () => {
        setShowData(!showDetails)
    }
    
    const { item, editOpen, index } = props;

    //To open Co owner Card in Edit Mode
    useEffect(() => {
        if (!validateCoOwnerData(item)) {
            setEditView(true);
            setEditDetails(item);
            if (editOpen) {
                editOpen(index, true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, item])

    //set individual owner details
    const ownerDetails: any = useMemo(() => {
        const data: any = { ...props?.item }
        data['ssn'] = maskAccountNumber(data.ssn, false)
        data['state'] = stateValueFormatter(data?.state)      
        return data
    }, [props?.item]);  

    //remove individual owner details
    const remove = (index: number) => {
        setDeleteBusinessOwnerOpen(false);
        const myArray: any = [...props?.data];
        const deleteEntity = myArray[index];

        myArray.splice(index, 1);
        if (props?.updatedData) {
            props?.updatedData(deleteEntity.actionIndex, myArray)
        }
    };

    /**
    * Method handles the close event of the FxDelete component
    */
    const deleteBusinessOwnerClose = () => {
        setDeleteBusinessOwnerOpen(false);
    };
    /**
     * method to handle edit data and edit page
     * @param data :data
     */
    const editBusinessOwner = (data: any) => {
        setShowData(false)
        setEditView(true)
        setEditDetails(data)
        if (props?.editOpen) {
            props?.editOpen(index, true)
        }
    }
    /**
     * method to handle cancel edit
     */
    const cancelEdit = () => {
        setEditView(false)
        if (props?.editOpen) {
            props?.editOpen(index, false)
        }
    }
    /**
     * method to submit data od edit form
     */
    const submit = (data: any, req: any) => {
        if (props.onSubmit) {
            props.onSubmit(data, req, 'edit', props?.index)
        }
    }
    return (
        <Grid container id='individual-owner-card' xs={12} className='fx-business-owner-view'>
            <FxCard id="signUp-individual-owner-form-card-view" className={isSmallScreen ? "fx-theme-passport fx-business-owner-view-border" : "fx-theme-passport"}>
                <FxCardHeader>
                  <Typography className='fx-new-invite-owner-profile-label'>{props?.index + 1 + ". "} Co-Owner Information</Typography>
                    <Grid item xs >
                        <Grid container justifyContent="flex-end">
                            {!editView && <Grid item className="fx-info-kyc-view-topbutton">
                                <IconButton
                                    id={"btn-show-hide-ico"}
                                    className="fx-button fx-border-gray"
                                    onClick={showHandleDetails}
                                    size="large">{showDetails ? <TriangleUp /> : <TriangleDown />}</IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <div className="fx-form-edit-profile">
                    <FxCardBody id="signUp-beneficialOwner-form-card-body" className="fx-info-card fx-info-kyc-view" >
                        <Grid container direction="row" className="fx-form-edit-profile " xs={12}>
                            <Grid item container xs={12}>
                                {!editView && <><Grid item xs={12} >
                                    <Grid item sm={12} xs={12}>
                                        <Typography id="individual-owner-name-label" variant="h5" >{(props?.item.firstName + ' ' + props?.item.lastName)}</Typography>
                                    </Grid>
                                </Grid>
                                    <Grid item xs={12} >
                                        <Typography id="individual-owner-name-label" variant='h6' className="" >{props?.item.mobilePhone}</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography id="individual-owner-name-label" variant='h6' className="" >{props?.item.email}</Typography>
                                    </Grid>
                                    <Grid item xs={12} >&nbsp;</Grid>
                                    <Grid item container xs={12} alignItems="center" >
                                        <Grid item container xs={6}>
                                        </Grid>
                                        {!editView &&
                                            <Grid item container justifyContent='flex-end' spacing={2} xs={6}>
                                                <Grid item>
                                                    <IconButton
                                                        id={"edit-individual-owner"}
                                                        className="fx-button fx-button-action-no-border fx-svg-theme"
                                                        onClick={() => { editBusinessOwner(props?.item) }}
                                                        size="large"><EditIcon /> &nbsp; EDIT</IconButton>
                                                </Grid>
                                                {<Grid item>
                                                    {!props?.item?.id && <FxDelete entity="individualowner" className={'fx-button fx-button-action-no-border fx-svg-theme'} id={'delete-individual-owner'} buttonId={'delete-individual-owner-submit'} buttonTitle="REMOVE" header={`Are you sure you want to remove ${item.firstName} ${item.lastName} as the Co-Owner?`} description="You cannot undo this action." successEntity="Co-Owner" noEntity={true} open={deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                    {props?.item?.id && <FxDelete entity="beneficialOwner" className={'fx-button fx-button-action-no-border fx-svg-theme'} noRedirection={true} buttonId={'delete-individual-owner-submit'} buttonTitle="REMOVE" header={`Are you sure you want to remove ${item.firstName} ${item.lastName} as the Co-Owner?`} description="You cannot undo this action." successEntity="Co-Owner" id={props?.item?.id} open={deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                </Grid>}
                                            </Grid>}
                                    </Grid>

                                    {showDetails && !editView && <Grid item xs={12}>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"individual-owner-info-card"} title={'BASIC INFORMATION'} column={ownerProfileDetails} data={ownerDetails} />
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"individual-owner-address-info-card"} title={"PRIMARY ADDRESS"} column={ownerProfileAddress} data={ownerDetails} />
                                    </Grid>}
                                </>}
                                {editView && editDetails && <Grid item xs={12}>
                                    <JointTenancyCoOwnerEdit data={editDetails} index={props?.index} cancel={cancelEdit} primaryOwnerDetails={props?.primaryOwnerDetails} onSubmit={submit} />
                                </Grid>}
                            </Grid>
                        </Grid>
                    </FxCardBody>
                </div>
            </FxCard>
        </Grid>
    );
}
)
