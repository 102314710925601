import { Logger } from "../../libs/utils/logger";
import { getKey } from "../../libs/utils/storageManager";
import { ReactComponent as LightningIcon } from '../../assets/svg/lightning-icon.svg'
import React from "react";
import { useMediaQuery } from "react-responsive";
import { FxInfoTooltip } from '../Utils/FxInfoTooltip';
import { ReactComponent as DarkInfoIcon } from '../../assets/svg/darkgrey-info-icon.svg';
import { useIsMobileScreen } from "../../libs/utils/utils";
import { SwitchCustomerMenu } from "../Page/Index/Passport/SwitchCustomerMenu";
import UIFeatureImpl from "../../libs/services/uiFeatures";


Logger.debug("ThemeChecker.tsx", "Theme Checker initializing");
const uiFeat = UIFeatureImpl.getInstance();

/**
 * Component contains the Generic functions to manipulate the Filters and Columns for Transactions and Ledger Listing screen as per the theme
 */


/**
 * Check if the theme is Green then return true else false
 */
export function checkIsMXM(){
  return getKey("theme") === "mxm";
}

/**
 * Function to remove the Batch ID Nodes from the filters
 */
export function isBatchRequired(data: any) {   
  if (!uiFeat.getBatchId().available) {
    const index = data.fields.findIndex(
      (field: any) => field.fieldName === "trimmedBatchId"
    );
    if (index !== -1) {
      data.fields.splice(index, 1);
    }
  } 
    return data;
}

/**
 * Function to check wheather we need to show batchId column
 */
export function setColumnsBasedOnSettings(data: any) {
  if (!uiFeat.getBatchId().available ) {
    const columns = data.columns;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].fieldName === "batchId") {
        delete columns[i]
      }
    }
    data.columns = columns;
  } 
    return data;
}

/**
 * Function to display tooltip for avalaible amount
 */
export const ShowPPIToolTip=(ppiDetails: any) =>{
  return <FxInfoTooltip  arrow placement={'bottom-start'} className={'fx-ppi-info-tooltip'} title={`Payment ID \n ${ppiDetails?.ppi || ''}`}>
  <DarkInfoIcon className="fx-dark-grey-info fx-accountinfo-info-icon"></DarkInfoIcon>
</FxInfoTooltip>
}

/**
 * Function used to display the Thunder Icon and the Faster Funded Label when the MXM theme is being used
 */
export const ShowFasterFundingAsPerTheme = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const fastFundAvailable = uiFeat.getFasterFunding().available

  const fasterFundingNode = (
    fastFundAvailable && isMobile
      ? <span className="fx-quick-settle-icon fx-quick-settle-icon-mobile">
          <LightningIcon />
        </span>
      : fastFundAvailable
        ? (
            <>
              <span className="fx-quick-settle-icon">
                <LightningIcon />
              </span>
              <mark className={"fx-status-filled fx-hover-card"}>
                <span className="fx-quick-settle-text">Faster Funded</span>
              </mark>
            </>
          )
        : null
  );

  return fasterFundingNode;
}

/**
 * Function used to display the Red Thunder Icon when the MXM theme is being used and an adjustment is recieved
 */
export const ShowAdjustmentIcon = () => {
  const isMobile = useIsMobileScreen();
  const fastFundAvailable = uiFeat.getFasterFunding().available
 
  /**
   * this statement will initialize the adjustmentNode variable with the red lightning icon JSX
   * when MXM theme is enabled with specific styling for mobile and pc screens
   */
  const adjustmentNode = (
    fastFundAvailable && isMobile
      ? <span className="fx-adjustment-icon fx-quick-settle-icon-mobile">
          <LightningIcon />
        </span>
      : fastFundAvailable
        ? (
            <>
              <span className="fx-adjustment-icon">
                <LightningIcon />
              </span>
            </>
          )
        : null
  );

  return adjustmentNode;
}

export default ShowFasterFundingAsPerTheme;

/**
 * Check the ACH Control based on the specified condition
 * @param {string} condition - The condition to check ('Yes', 'No', 'OWN_ACCOUNT_ONLY')
 * @returns {boolean} - Whether the ACH Control matches the specified condition
 */
export function checkAchControl(key: string, condition: string) {
  return key === condition;
}

// Check if ACH Control is allowed
export const isAchControlAllowed = (key: string) => {
  return checkAchControl(key,'Yes');
}


// Check if ACH Control is not allowed
export const isAchControlNotAllowed = (key: string) => {
  return checkAchControl(key,'No');
}

// Check if ACH Control is allowed only for owned account
export const isAchControlOwnAccountOnly = (key: string) => {
  return checkAchControl(key,'OWN_ACCOUNT_ONLY');
}

// Check if any ACH method is allowed
export const isAnyAchControl = (key: string) => {
  return checkAchControl(key,'Yes') || checkAchControl(key,'OWN_ACCOUNT_ONLY');
}

/**
 * Method renders switch customer selection in menu expand
 * @param props : props 
 * @returns 
 */
export const switchCustomerMenu = (props: any) => {
  return <SwitchCustomerMenu drawerExpand={true} onMenuSelect={props?.toggleDrawer} id="switch-customer-menu" ></SwitchCustomerMenu>
}
/**
* Method renders switch customer selection in menu collapse
* @param props : props 
* @returns 
*/
export const switchCustomerSecondMenu = (props: any) => {
  return <SwitchCustomerMenu id="switch-customer-menu" drawerExpand={props.drawerExpand} ></SwitchCustomerMenu>
}