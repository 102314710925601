import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as CloneIcon } from '../../../assets/svg/clone-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { FxDeactivateFundingRule } from './DeactivateFundingRule';
import { DataGrid } from '../../Data/DataGrid';
import { IFilterColumns } from '../../../types/common.interfaces';
import { checkPermission, removeUnderScoreConvertTitleCase, toTitleCase, truncateChars } from '../../Utils/CommonBaseClass';
import { getKey } from '../../../libs/utils/storageManager';
import usa_state from '../../Utils/usa_states.json';
import { Logger } from '../../../libs/utils/logger';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component: Split Funding Detail
 * Usage: To display details of split funding detail
 */

export const SplitFundingDetail: React.FC<any> = React.memo((props) => {

    //#region Variable Declarations
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory<any>()
    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    let splitFundingData: any;
    const uiFeat = UIFeatureImpl.getInstance();
    const checkIsPartner=uiFeat.getPartnerFeature().available;
    
    if (context && context.data && context.data.body) {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: context.data.body.name, backButton: history?.location?.state?.backUrl ? history?.location?.state?.backUrl : "/fundingrules/active" } } });
        splitFundingData = { ...context.data.body };
        splitFundingData['fundingRuleId'] = splitFundingData?.parentRuleId ? splitFundingData?.parentRuleId : splitFundingData?.id;
        splitFundingData['createdInfo'] =splitFundingData?.createdOn ? moment(splitFundingData?.createdOn).format('MM/DD/YYYY'): '-';
        splitFundingData['createdBy'] = splitFundingData?.createdBy?.username;
        splitFundingData['overridable'] = splitFundingData?.isOverridable ? 'Yes': 'No';
        splitFundingData['isRecoupable'] = splitFundingData?.isRecoupable ? 'Yes': 'No';
        splitFundingData['applicable'] = splitFundingData?.method ? (splitFundingData?.method).toString() : '-';
        splitFundingData['transactionComponent']= splitFundingData?.transactionComponent?.toLowerCase() === 'total_amount' ? 'Total Amount': splitFundingData?.transactionComponent?.toLowerCase() === 'subtotal' ? 'Sub Total' : toTitleCase(splitFundingData?.transactionComponent);
        splitFundingData['owner']= splitFundingData['ownership'] ?  toTitleCase(splitFundingData?.ownership) : '-';
        splitFundingData['association']= splitFundingData['association']?.filter((ele:any)=> ele?.key !== 'PROGRAM_AFFILIATE_ID');
    }


    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    interface RouteParams {
        id: string
    }

    //get ID from  url 
    const params = useParams<RouteParams>();
    const id = params.id

    /**
     * Method to handle Clone Button
     */
    const handleClone = () => {
        try {
            history.push('/fundingrule/clone', { ruleId: { id: context?.data?.body?.id } });
        }
        catch (e) { }
    }

    const policy_detail_left_section = [
        {
            label: 'ID',
            value: 'fundingRuleId',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Name',
            value: 'name',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Applicable Transaction Method',
            value: 'applicable',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Overridable',
            value: 'overridable',
            type:  'fri://fieldtype/default'
        }
    ];

    const policy_detail_right_section = [
        {
            label: 'Status',
            value: 'status',
            type: 'fri://fieldtype/status'
        },
        {
            label: 'Ownership',
            value: 'owner',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Applicable Transaction Component',
            value: 'transactionComponent',
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Enable Recoup',
            value: 'isRecoupable',
            type: 'fri://fieldtype/default'
        }
    ];

    const audit_field_left_section = [
        {
            label: 'Created On',
            value: 'createdInfo',
        }
    ];

    const audit_field_right_section = [
        {
            label: 'Created By',
            value: 'createdBy',
        }
    ];

      /**
   *  Funding Rules list columns
   * */
  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "TRANSFER TO",
        enable: true,
        fieldName: "category",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "12rem" },
      },
      {
        label: checkIsPartner? "ACCOUNT TAG": "ACCOUNT INFO",
        enable: true,
        fieldName: "account",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "TAG NAME",
        enable: true,
        fieldName: "tagName",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "12rem" },
      },
      {
        label: "TAG MAPS TO",
        enable: true,
        fieldName: "tagMapsTo",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "PERCENTAGE",
        enable: true,
        fieldName: "splitPercentage",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "12rem" },
      },
      {
        label: "AMOUNT",
        enable: true,
        fieldName: "amount",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "10rem" },
      }
    ],
  };

  /**
   * Function to return the Account Detail node as per the recieved ppi, account id or contact
   */
  function getAccountDetailNode(item:any){
    return checkIsPartner || splitFundingData?.ownership ==='INHERITED'?  (<Typography>{item?.account?.tag ? item?.account?.tag : '-' }</Typography>) : (
        <Grid title={item?.account?.accountNumberLast4? (getKey('CustomerName') +' ****'+ item?.account?.accountNumberLast4 ):item?.contact?.name ? (item?.contact?.name +" " + item?.contact?.ppi ?item?.contact?.ppi : ''):''}>
            <Typography>
                <span>{item?.account?.accountNumberLast4? (getKey('CustomerName') +' ****'+ item?.account?.accountNumberLast4 ):item?.contact?.name ? (item?.contact?.name +" " + item?.contact?.ppi ? truncateChars(item?.contact?.ppi, 15): ''):'-'}&nbsp;</span>
            </Typography>
        </Grid>
    )
  }


      /**
     *  To get the list data as per split rule actions.
     */
      let actions_data: any = [];
        let newData: any = []; 
        if (splitFundingData && splitFundingData?.action) {
                actions_data = [...splitFundingData.action]
           newData = actions_data.map((item: any) => {
               return {
                   id: item?.id,
                   splitPercentage: item?.percentage ? (item.percentage+'%'):'-',
                   amount: item?.amount ? ('$'+item?.amount):'-',
                   account: getAccountDetailNode(item),
                   tagName: item?.dynamic?.tagName? <Grid title={item?.dynamic?.tagName}><Typography><span>{item?.dynamic?.tagName ? truncateChars(item?.dynamic?.tagName, 15): ''}&nbsp;</span></Typography></Grid> : '-',
                   tagMapsTo: item?.dynamic?.tagMapsTo && item?.dynamic?.tagMapsTo === 'ppi' ? 'PPI' : item?.dynamic?.tagMapsTo === 'accountId' ? 'Account ID' : '-',
                   category: checkIsPartner || splitFundingData?.ownership==='INHERITED' ? (item?.transferTo==='PROGRAM_AFFILIATE' ? 'Partner' : item?.transferTo=== 'CUSTOMER' ? 'Customer': (item?.transferTo ==='PICK_FROM_TAG' || item?.dynamic)  ? 'Pick from Tag' : 'PM' )  : (item?.account ? 'My Account' : item?.contact ? 'My Contact' : item?.dynamic ? 'Pick from Tag' : '-')
               }
           })
        }

    /**
     * Method to handle Clone Button
     */
         const handleEdit = () => {
            try {
                const fundingRuleId = context?.data?.body?.id;
                if (fundingRuleId) {
                history.push('/fundingrule/edit/'+fundingRuleId);
                }
            }
            catch (e) { 
                Logger.error("SplitFundingDetail.tsx", "error", e);
            }
        }

    return (
        <>
            <Grid container className={'fx-approval-policy-detail'}>

                <Grid item xs={12} sm={9}>
                    <FxCard className="fx-theme-passport">
                        <FxCardHeader id="split-funding-rules-header">
                            <Grid container item xs={12} justifyContent="space-between">
                                <Typography variant="h3" >Basic Info</Typography>
                                <Grid>

                                    {splitFundingData?.ownership?.toLowerCase() === 'self' && <FxButton id="split-funding-rules-edit-button" title="Clone" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<CloneIcon />} onClick={handleClone}
                                         permissions={{
                                            entity: checkIsPartner? "Partner Portal" : "Funding Rule",
                                            name:"Funding Rule",
                                            operation:"CREATE"
                                          }}>
                                        Clone
                                    </FxButton>}
                                    <span className='fx-padding-right-16'></span>
                                    {splitFundingData?.ownership?.toLowerCase() === 'self'  && splitFundingData?.status === 'ACTIVE' && <FxButton id="split-funding-rules-edit-button" title="Edit" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EditIcon />} onClick={handleEdit}
                                         permissions={{
                                            entity: checkIsPartner? "Partner Portal" : "Funding Rule",
                                            name:"Funding Rule",
                                            operation:"EDIT"
                                          }}>
                                        Edit
                                    </FxButton>}
                                    <span className='fx-padding-right-16'></span>
                                     {checkPermission({
                                          entity: checkIsPartner? "Partner Portal" : "Funding Rule",  name: "Funding Rule", operation: 'Deactivate'
                                        }) && splitFundingData && splitFundingData?.status === 'ACTIVE' && (splitFundingData?.ownership === 'SELF' || (splitFundingData?.ownership !== 'SELF' && splitFundingData?.isOverridable===true))  && <FxDeactivateFundingRule domId={'delete-splitfunding-rule'} id={id} entity={'fundingRule'} buttonTitle={!isSmallScreen ? "Deactivate" : ''} header={"Are you sure you want to deactivate this rule? "} description="This rule will remain active for any Card batches that are currently in progress." redirectUrl={'/fundingrules/active'} buttonName={'YES, DEACTIVATE IT'} />}
                                    </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="split-funding-rules-Card-Body" className="fx-home-ledger-list">

                            <Grid id={"policy-detail"} item xs={12} marginBottom='1rem'>
                                <FxInfoCard id="split-funding-rules-section-info-card" title="RULE DETAILS" leftcolumn={policy_detail_left_section} rightcolumn={policy_detail_right_section} data={splitFundingData}></FxInfoCard>
                            </Grid>

                            <Grid id={"policy-detail"} item xs={12}>
                                <FxInfoCard id="split-funding-rules-section-info-card-second" title="AUDIT FIELDS" leftcolumn={audit_field_left_section} rightcolumn={audit_field_right_section} data={splitFundingData}></FxInfoCard>
                            </Grid>
                            {(splitFundingData?.method.includes("CARD") || checkIsPartner) && splitFundingData?.association?.length > 0 && (checkIsPartner?  splitFundingData?.ownership : splitFundingData?.ownership === 'SELF')? 
                              <><Grid item xs={12} marginBottom='1rem' className={'fx-approval-policy-detail-condition'}>
                                  <Grid item xs className='fx-home-ledger-list-headerText fx-padding-header' display='flex' alignItems='center'>
                                   <Typography variant='h6'>ASSOCIATION</Typography>
                                  </Grid>
                                </Grid> 
                                {splitFundingData?.association?.map((item: any, i: number) => {
                                    // Fetching the state labels based on the state value
                                    const transformedState = item?.key?.toLowerCase() === 'customer_enrolled_state' && item?.value?.length
                                    ? item.value.map((ele:any) => usa_state.find((state) => ele === state.value)?.label).join(', '): '';
                                    const transformedMccCode = item?.key?.toLowerCase() === 'merchant_industry' && item?.value?.length && props?.mccCodelist
                                    ? item.value.map((ele:any) => {const foundMcc = props?.mccCodelist?.find((mcc:any) => ele === mcc?.code)
                                         return `${foundMcc?.description || ''} (${ele})`})?.join(', '): '';
                                    
                                    const  associationValue = transformedState || transformedMccCode || truncateChars(item?.value?.join(', '), 100) || '';
                                    const  associationName= item?.key ? item?.key === 'MERCHANT_MID' ? 'Merchant MID': removeUnderScoreConvertTitleCase(item?.key):'';
                                    return <Grid item xs={12} marginBottom='1rem' className={'fx-approval-policy-detail-condition fx-margin-one-px'}>
                                        <Grid container direction="row" className={" fx-home-ledger-list-column-pointer-event"}>
                                            <Grid item container xs className="fx-home-ledger-list-column-content">
                                                <Grid item container xs={12} alignItems='center' paddingBottom={'1rem'} display={'flex'}>
                                                    <Grid xs={2.5} marginLeft={'0.7rem'}><Typography variant='h4' className='fx-association-section-key-text'> {associationName}</Typography></Grid>
                                                    <Grid xs={9} title={item?.key?.toLowerCase() === 'customer_enrolled_state' ? transformedState: item?.key?.toLowerCase() === 'merchant_industry' ? transformedMccCode: item?.value }><Typography variant='h4' className='fx-association-section-value-text'><span>{item?.value ? truncateChars(associationValue,100) : ''}&nbsp;</span></Typography></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                 })}</> :(splitFundingData?.method.includes("CARD") || checkIsPartner) && (splitFundingData?.method.length ===1 || (checkIsPartner && splitFundingData?.method.length > 0))&& (splitFundingData?.association?.length === 0 || splitFundingData?.ownership === 'INHERITED' )?
                                <FxCardBody id="split-funding-rules-Card-Body-second" className="fx-home-ledger-list">
                                    <Grid item xs={12} marginBottom='1rem' className={'fx-approval-policy-detail-condition'}>
                                        <Grid item xs className='fx-home-ledger-list-headerText fx-padding-header' display='flex' alignItems='center'>
                                            <Typography variant='h6'>ASSOCIATION</Typography>
                                        </Grid>
                                    </Grid>
                                  <Typography variant="h4" >{`This rule will be applicable to all ${checkIsPartner ? 'Customers':'Merchants'}.`}</Typography>
                            </FxCardBody>: <></>
                            }

                        </FxCardBody>
                    </FxCard>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <FxCard className="fx-theme-passport">
                        <FxCardHeader id="split-funding-rules-header-second">
                            <Grid container item direction="row" justifyContent="space-between">
                                <Typography variant="h3" >Rule Condition</Typography>
                            </Grid>
                        </FxCardHeader>
                        {splitFundingData?.condition?.length > 0 ? <FxCardBody id="split-funding-rules-Card-Body-second" className="fx-home-ledger-list">
                            {splitFundingData?.condition?.map((item: any, i: number) => {
                                const OperatorValue = item?.key?.split('.')[1];
                                // item.value = item.value.includes(',')?item.value.split(','):item.value;
                                return <Grid item xs={12} marginBottom='1rem' className={'fx-approval-policy-detail-condition'}>
                                    <Grid container xs={12}>
                                        <Grid item xs className='fx-home-ledger-list-headerText fx-padding-header' display='flex' alignItems='center'>
                                            <Typography variant='h6'>PARAMETER {i + 1}</Typography>
                                            <Grid title={`Tag(${OperatorValue ? OperatorValue : ''})`}><Typography variant='h6' className={'fx-approval-policy-detail-condition-operator'} paddingLeft='1rem'><span>Tag ({OperatorValue ? truncateChars(OperatorValue, 100): ''})&nbsp;</span></Typography></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" className={"fx-home-ledger-list-column fx-home-ledger-list-column-pointer-event"}>
                                        <Grid item container xs className="fx-home-ledger-list-column-content">
                                            <Grid item container xs={12} alignItems='center'>
                                                <Typography>Value : </Typography>
                                                <Grid title={item?.value}><Typography variant='h6' className='fx-home-ledger-list-headerText fx-padding-header-width-changes fx-approval-policy-detail-chip'><span>{item?.value ? truncateChars(item?.value, 100): ''}&nbsp;</span></Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })}  </FxCardBody>:
                            <FxCardBody id="split-funding-rules-Card-Body-second" className="fx-home-ledger-list">
                                  <Typography variant="h4" >This rule will be applicable to all incoming transactions.</Typography>
                                
                            </FxCardBody>}
                    </FxCard>
                </Grid>

                <Grid item xs={12} sm={9}>
                    <FxCard className="fx-theme-passport">
                        <FxCardHeader id="split-funding-split-header-second">
                            <Grid container item direction="row" justifyContent="space-between">
                                <Typography variant="h3" >Rule Action</Typography>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody
                            id="funding-rules-list-card-body"
                            className="fx-card-body-no-border-no-shadow"
                        >
                            <Grid id="funding-rules-list-criteria-card-body-grid" item xs={12}>
                                <DataGrid
                                    id="funding-rules-list-criteria-card"
                                    tableClass="fx-table-passport"
                                    data={newData}
                                    column={column}
                                    pagination={false}
                                    pageNumber={1}
                                    pageSize={25}
                                    resultPerPage={25}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                                <Grid item xs={12} sm={12} className="fx-dark-grey-info-card" marginTop={'1rem'}>
                                  <span>
                                    {`Note: All debit transactions (including refunds, chargebacks, fees, reserves, returns etc) will be debited from the configured account. Recoup of funds in case of “funds reversal” is possible only if the transaction amount was initially distributed across the accounts of the customer subject to the rule.`}
                                    &nbsp;
                                  </span>
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </FxCard>
                </Grid>
            </Grid>
        </>
    )
})
