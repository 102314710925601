import React from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxTreasureWelcomeCongratsCard } from './FxTreasureWelcomeCongratsCard';

Logger.debug("TreasureAcceptReviewAgreement.tsx", "Review Accept Agreement initializing")


interface IAcceptReviewAgreement {
    type?: string;
    onDrawerClose: (event: any) => void;
}

export const FxTreasureCongratulationCard: React.FC<IAcceptReviewAgreement> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    /**
     * function to handle Button Click
     * @param event 
     */
    const goToDashboard = (async (event: any) => {
        props.onDrawerClose(event);
    })

    return (
        <Grid container item xs={12} direction="row" className='fx-review-auth-signatory-details'>
            <FxTreasureWelcomeCongratsCard type={props?.type} onButtonClick={goToDashboard} />
        </Grid>

    )
})