/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import { getUserToken, setUserToken, getKey } from './storageManager';

declare global {
    interface Window {
        API_URL: any;
    }
}

const API_URL = window.API_URL;

/**
 * http client for API calls
 */
class HttpClient {

    private static client: HttpClient;

    httpClient: any;
    private constructor() {
        //this.httpClient = newClient();
    }

    /**
     * @returns http client to perform api calls
     */
    static getClient() {
        if (HttpClient.client == null) {
            HttpClient.client = new HttpClient();

            //cannot set null token in redirection or page reload
            //check if token exist then use the token
            const token = HttpClient.token();
            if (token) {
                HttpClient.setToken(token);
            }
            else {
                HttpClient.setToken("");
            }

        }
        return HttpClient.client;
    }
    static token() {
        return getUserToken();
    }

    /**
     *
     * @returns url with customer base path
     */
    static custUrl() {
        const customerId = getKey("customerId");
        console.log("custUrl customerId", customerId)
        if (customerId) {
            return API_URL + 'customer/id/' + customerId;
        }
        return API_URL;
    }

    /**
     * @returns seesion token
     */
    static sessionToken() {
        let retval = "";
        const sesstoken = getKey("sessionToken");
        if (sesstoken && sesstoken.token) {
            retval = sesstoken.token
        }
        return retval;
    }

    /**
     * create an axios instance of http client
     */
    static newClient(): AxiosInstance {
        const httpClient = axios.create({
            baseURL: API_URL,
            headers: HttpClient.getHeaders()
        });
        return httpClient;
    }

    /**
     * get the headers
     */
    private static getHeaders(){
        let headers:any = {}
        if (HttpClient.token()) {
            headers = { 'Authorization': "Bearer " + HttpClient.token(), "Session-Token": HttpClient.sessionToken() }
            //check user is proxy
            if(getKey('isProxyLogin') === true){
                headers['isProxy'] ='true';
                if(getKey('goBackPortal') === 'CSG'){
                    headers['customerId'] =getKey('customerId')
                }
            }
        }
        return headers;
    }

    /**
    * create an axios instance to call external API
    */
    static externalUrl(headers: any): AxiosInstance {
        const httpClient = axios.create(headers);
        return httpClient;
    }

    /**
     * * create an axios instance of http client
     * using when and http client with custom header required
     * @param headers
     */
    static newClientCustomHeaders(headers: any): AxiosInstance {
        const httpClient = axios.create({
            baseURL: API_URL,
            headers: { 'Authorization': "Bearer " + HttpClient.token(), "Session-Token": HttpClient.sessionToken(), ...headers }

        });
        return httpClient;
    }


    /**
     * * create an axios instance of http client
     * using when and http client with custom header required
     * without any bearer tojem
     * @param headers
     */
    static newClientCustomHeadersNoBearer(headers: any): AxiosInstance {
        const httpClient = axios.create({
            baseURL: API_URL,
            headers: { ...headers }

        });
        return httpClient;
    }
    /**
     * * create an axios instance of http client
     * using when and http client with  blob
     * @param headers
     */
    static newClientBlob(): AxiosInstance {
        const httpClient = axios.create({
            baseURL: API_URL,
            responseType: 'blob',
            headers: { 'Authorization': "Bearer " + HttpClient.token(), "Session-Token": HttpClient.sessionToken() }

        });
        return httpClient;
    }

    /**
     * set the token
     */
    static setToken(token: string) {
        setUserToken(token);
        HttpClient.client.httpClient = HttpClient.newClient();

    }

    /**
     * unset the client for login
     */
    static unsetToken() {
        HttpClient.setToken("");
    }

    /**
     * update the http client
     */
    static updateClient(){
        HttpClient.client.httpClient = HttpClient.newClient();
    }

    /***
     * bellow methods internally calling axios methods
     * like get post put delete etc
     */
    async get(...args: any) {
        return this.httpClient.get(...args);

    }
    async post(...args: any) {
        return this.httpClient.post(...args);
    }
    async put(...args: any) {
        return this.httpClient.put(...args);

    }
    async delete(...args: any) {
        return this.httpClient.delete(...args);

    }
}

export default HttpClient;