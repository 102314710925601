import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxAccountNumberView } from '../../Input/FxAccountNumber/FxAccountNumberView';
import FxTextView from '../../Input/FxText/FxTextView';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxAddressView } from '../../Input/FxAddress/FxAddressView';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { FxAcceptedCurrencyView } from '../../Input/FxAcceptedCurrency/FxAcceptedCurrencyView';
import { FxLink } from '../../Action/FxLink';
import { FxCustomComponent } from '../../Input/FxCurrency/FxCustomComponent';

Logger.debug("FxInfoCard.tsx", "InfoCard initializing")
/**
 * This component handles the display of the details in a section of the card
 * Data displayed in label/value pairs
 * 
 * *****
 * props
 * ******* 
 * id:id
 * noTitle: true/false - to show/hide title
 * title: title for the card
 * leftColumn,rightColumn: split left/right column fields and corresponding properties
 * column: column fields in full width
 */
export const FxInfoCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        /**
         * rest the component on unmount, so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        let apiInfo: any = {};
        /**
         * set context if API source passed as props
         * @return data object - transform data if data transformer passed
         */
        if (context && context.data && context.data.body) {
            apiInfo = props.dataTransformer ? props.dataTransformer(context.data.body) : context.data.body;
        }
        /**
         * set props data
         * @return data object
         */
        if (props.data !== undefined) {
            apiInfo = props.dataTransformer ? props.dataTransformer(props.data) :props.data;
        }

        /**
         * Method to return column fields
         * @param item: column field object
         * @param index: column index
         */
        function getColumnFields(item: any, index: any) {
            return (<Grid item xs sm={7} className={isSmallScreen ? "fx-break-word" : ""}>
                {(item.type === 'fri://fieldtype/default' || !item.type) && <FxTextView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} toolTipValue={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} truncateChars={item.truncateChars} valueTransformer={item.valueTransformer}></FxTextView>}
                {item.type === 'fri://fieldtype/accountnumber' && <FxAccountNumberView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} unMask={item.unMask ? item.unMask : false}></FxAccountNumberView>}
                {item.type === 'fri://fieldtype/status' && <FxStatus id={"status-info-" + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} fill={true} className="fx-status"></FxStatus>}
                {item.type === 'fri://fieldtype/date' && <FxDateTimeView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} format={item.format ? item.format : "MM/DD/YYYY"}></FxDateTimeView>}
                {item.type === 'fri://fieldtype/currency' && <FxCurrencyView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} prefix={item.prefix ? item.prefix : "$"}></FxCurrencyView>}
                {item.type === 'fri://fieldtype/address' && <FxAddressView tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} className={item.className ? item.className : ''}></FxAddressView>}
                {item.type === 'fri://fieldtype/paymentmodestatus' && <FxPaymentModeStatus id={"paymentmodestatus-info-" + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} fill={false} noColor={item?.color  === true ? false : true} className="fx-status"></FxPaymentModeStatus>}
                {item.type === 'fri://fieldtype/acceptedCurrency' && <FxAcceptedCurrencyView tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} className={item.className ? item.className : ''}></FxAcceptedCurrencyView>}
                {item.type === 'fri://fieldtype/hyperlink' && <FxLink redirect={item.url ? item.url : ""} className={item.className ? item.className : "fx-link"} title={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} >{resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''}</FxLink>}
                {item.type === 'fri://fieldtype/custom' && <FxCustomComponent id={item.label + '-' + index} >{item.value}</FxCustomComponent>}
                
            </Grid>)
        }
        /**
         * Checking the UI is mobile or other devices
         */
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        /**
         * Method splits the path if it is not an array, compares the value with api value and set the data 
         *@param path: path to split
         *@param obj: field object 
         */
        function resolve(path: any, obj: any, separator = '.') {
            const properties = Array.isArray(path) ? path : path.split(separator)
            return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj)
        }
        return (
            <Grid id={props.id} container className={`fx-info-label-value-container ${props.className ? props.className : ''}`}>
                {!props.notiltle && (!isSmallScreen || props.title) && <Grid item xs={12}>
                    <Typography variant="h5" id={props.id + '_' + props.title} className="">{props.title} &nbsp;</Typography>
                </Grid>}
                <Grid container direction="row">
                    <Grid xs={12} sm={6} item>
                        {/* loop through left side column fields */}
                        {props?.leftcolumn?.map((item: any, index: any) => {
                            return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                                className={"fx-info-label-value"}>
                                <Grid xs sm={5} item>{item.label}</Grid>
                                {getColumnFields(item, index)}
                            </Grid>
                        })}
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        {/* loop through right side column fields */}
                        {props?.rightcolumn?.map((item: any, index: any) => {
                            return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                                className={"fx-info-label-value"}>
                                <Grid xs sm={5} item>{item.label}</Grid>
                                {getColumnFields(item, index)}
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                {/* loop through column fields full width*/}
                {props?.column?.map((item: any, index: any) => {
                    return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                        className={"fx-info-label-value"}>
                        <Grid xs sm={5} item>{item.label}</Grid>
                        {getColumnFields(item, index)}
                    </Grid>
                })}
            </Grid>
        )
    })
