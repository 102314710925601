import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { RegisterComponent } from '../../libs/saga/dataSaga';
import { Logger } from '../../libs/utils/logger';
import { useDispatch } from 'react-redux';
import {  updateComponentParams } from '../Utils/CommonBaseClass';
import FxModalGeneric from '../Utils/FxModalGeneric';
import { SetAsPrimary } from '../Page/CoOwner/SetAsPrimatyOwner';
Logger.debug("FxConfirmationBox.tsx", "FxConfirmationBox modal initializing")

/**
 * This component handles the confirmation box
 */
interface IFxConfirmationBoxPropery {
    id: any;
    open: boolean;
    confirmationMessage: any;
    apiUrl?: ApiUrl;
    submitButtonText?: string
    ConfirmIcon?: any;
    description?: any;
    resetFormId?: string;
    successText: string;
    class?: string;
    modalProps?:any
    ownerFullName?: string;
}
interface ApiUrl {
    url: string;
    method: any;
    payload?: any
}
export const FxConfirmationBox: React.FC<IFxConfirmationBoxPropery> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));

        const dispatch = useDispatch();
        /**
         * Closing the pop up
         */
        const handleCloseModal = () => {
            updateComponentParams(dispatch, props.id, {   open: false  });
          };
  
        return (
            <Grid container className={"fx-container"}>
                     <FxModalGeneric
            id={`fx-confirmation`}
            open={props?.open}
            onClose={handleCloseModal}
            componentProps={{ ...props }}
            className={'fx-modal-small2medium'}
            component={SetAsPrimary}
        >
        </FxModalGeneric>
                   
            </Grid>
        );
    })