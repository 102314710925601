import React, { useState } from "react";
import { Logger } from "../../../libs/utils/logger";
import { Grid, Modal } from "@mui/material";
import { ReactComponent as SelectIcon } from "../../../assets/svg/unselect-icon.svg";
import { ReactComponent as FreezeIcon } from "../../../assets/svg/freeze-debit-card-icon.svg";
import { ReactComponent as SettingIcon } from "../../../assets/svg/settings-icon.svg";
import { ReactComponent as BlockReplaceIcon } from "../../../assets/svg/block-replace-action-icon.svg";
import { FxButton } from "../../Action/FxButton";
import { ActivateDebitCard } from "./ActivateDebitCard";
import { ManageUsageCard } from "./ManageUsageCard";
import { UnFreezeDebitCard } from "./UnFreezeDebitCard";
import {BlockAndReplaceDebitCard} from "./BlockAndReplaceDebitCard";
import { checkPermission, updateComponentParams } from "../../Utils/CommonBaseClass";
import { EnablePhysicalCard } from "./EnablePhysicalCard";
import FxModalGeneric from "../../Utils/FxModalGeneric";
import { ReissueCard } from "./ReissueCard";
import { FxSuccessCard } from "./FxSuccessCard";
import { useDispatch } from "react-redux";
import { getKey } from "../../../libs/utils/storageManager";
import { useHistory } from "react-router";
import { ReactComponent as EyeVisibilityOnIcon } from "../../../assets/svg/eye-visibility-on.svg";
import { ReactComponent as EyeVisibilityOffIcon } from "../../../assets/svg/eye-visibility-off.svg";
import { AuthenticationVerificationModal } from "../AuthenticationModal/AuthenticationVerificationModal";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { FreezeDebitCard } from "./FreezeDebitCard";
import HttpClient from "../../../libs/utils/httpClient";
import { ReactComponent as ReissueIcon } from "../../../assets/svg/reissue-button-icon.svg";

Logger.debug("DebitCardActions.tsx", "Debit Card Actions initializing");

/**
 * Component: DebitCardActions
 * Usage: To display Debit Card Action Buttons
 */

export const DebitCardActions: React.FC<any> = React.memo((props) => {
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [freezeModalOpen, setFreezeModalOpen] = useState(false);
  const [unfreezeModalOpen, setUnFreezeModalOpen] = useState(false);
  const [handleBlockAndReplaceOpen, sethandleBlockAndReplaceOpen] = useState(false);
  const [usageModalOpen, setUsageModalOpen] = useState(false);
  const [enablePhysicalCardModalOpen, setEnablePhysicalCardModalOpen] = useState(false);
  const [handleReissueOpen, setHandleReissueOpen] = useState(false);
  const [successCardOpen, setSuccessCardOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [showCardInfoModalOpen, setShowCardInfoModalOpen] = useState(false);
  const [verifyProps, setVerifyProps] = useState({});
  const [showCardDetails, setShowCardDetails] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const httpClient = HttpClient.getClient();

  function checkDebitCardPermission(data:any) {
    const permissionObject: any = {
      'activate_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Activate"
      },
      'cancel_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Cancel"
      },
      'block_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Block"
      },
      'freeze_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Freeze"
      },
      'unfreeze_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Unfreeze"
      },
      'manage_usage_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Manage_Usage"
      },
      'reissue_debit_card': {
        entity: "Accounts",
        name: "Debit Card",
        operation: "Reissue"
      },
    }
    return checkPermission(permissionObject[data]);
  }

  /**
   * Handle the acivation event
   * @param event emitted by action taken on the activation of debit card
   */
  function handleActivation(event: any) {
    setActivationModalOpen(true);
  }

  /**
   * Handle the enable physical card event
   * @param event emitted by action taken on enabling of physical card
   */
  function handleEnablePhysicalCard(event: any) {
    setEnablePhysicalCardModalOpen(true);
  }

  /**
   * Method to call api for get Debit Card Image
  */
  async function getCardImage() {
    try {
        const url =  '/account/id/' + getKey('selectedAccount')?.id + '/debitCard/id/' + props.data.id + '/image';
        const data: any = await httpClient.get(getCustomerUrl(url,false))
        return data;
    } catch (err) {
        Logger.error("GetCardImage.tsx", "error", err);
        return err;
    }
  }

  /**
   * Method to handle post mfa success
   */
  function handlePostSuccess(response:any){
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'digital-card-image' } });
    updateComponentParams(dispatch, "digital-card-image", { 'image': response?.data?.image, showImage : true })
    setShowCardDetails(false);
  }

  /**
   * Handle the show card info event
   */
  async function handleShowCardInfo() {
    let status = await getCardImage();
    status = processAPIResponse(status);
    if (status.status) {
      //api success
      if(status.responseData?.sessionId){
        setShowCardInfoModalOpen(true);
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';  
        setVerifyProps({
          subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to verify.`,
          url: getCustomerUrl("/account/id/" +getKey("selectedAccount")?.id +"/debitCard/id/" + props.data.id + "/image"),
          resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
          initiationDetails: status.responseData,
          postSuccessEvent: handlePostSuccess,
          method: 'GET'
        });
      }
    }
  }

  /**
 * Handle the enable physical card event
 * @param event emitted by action taken on enabling of physical card
 */
  function handleHideCardInfo(event: any) {
    setShowCardDetails(true);
    updateComponentParams(dispatch, "digital-card-image", { showImage : false })
  }

  /**
   * Handle the block and replace event
   * @param event event emitted by action taken on replace and block button
   */
  function handleBlockAndReplace(event: any) {
    sethandleBlockAndReplaceOpen(true);
  }

  /**
   * Handle the reissue event
   * @param event event emitted by action taken on reissue button
   */
  function handleReissue(event: any) {
    setHandleReissueOpen(true);
  }

  /**
   * Handle the frezze card event
   * @param event event emitted by action taken on freeze button
   */

  function handleFreeze(event: any) {
    setFreezeModalOpen(true);
  }

  /**
   * Handle the unfreeze card event
   * @param event event emitted by action taken on unfreeze button
   */
  function handleUnFreeze(event: any) {
    setUnFreezeModalOpen(true);
  }

  /**
   * Handle the manage usage pop-up events
   * @param event : event emitted when user select manage button
   */
  function handleUsage(event: any) {
    setUsageModalOpen(true);
  }

  //debit card source
  const debit_card_source = {
    url: "/account/id/" + getKey("selectedAccount")?.id + "/debitCard/id/"+ props.data.id,
    method: "GET" 
  }

  /**
   * Handle the success of enable physical card
   */
  function handleSuccess(){
    setSuccessCardOpen(false);
    if(buttonText.toLowerCase() === 'close'){
      updateComponentParams(dispatch,'debit-card-details-card',{});
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "debit-card-details-card", source: debit_card_source},
      });
    }else{
      history.push('/debitCards');
    }
  }

  return (
    <>
      <Grid id="debit-card-actions-Grid " className="" item xs={12}>
        <>
          {props?.data?.properties?.isDigitalFirst && (props.data.status === "ACTIVE" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED" || props.data.status === "CANCELLED") && <Grid container item xs={12} justifyContent='center' marginTop='2rem'>
            {showCardDetails ? 
              <Grid item xs={6} sm={6}>
              <FxButton
                  id={"show-card-info-button"}
                  className={`fx-button  fx-button-action fx-buttom-full-width ${props.data.status === "CANCELLED" ? 'fx-button-disabled' : ''}`}
                  startIcon={<EyeVisibilityOnIcon />}
                  onClick={handleShowCardInfo}
                  disabled={props.data.status === "CANCELLED"}
              >
                  Show Card Info
              </FxButton>
              </Grid> :
              <Grid item xs={6} sm={6}>
              <FxButton
                  id={"hide-card-info-button"}
                  className="fx-button  fx-button-action fx-buttom-full-width"
                  startIcon={<EyeVisibilityOffIcon />}
                  onClick={handleHideCardInfo}
              >
                  Hide Card Info
              </FxButton>
              </Grid>}
          </Grid>}

          {checkDebitCardPermission('activate_debit_card') && props.data.status === "SHIPPED" && (
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              direction="row"
              paddingTop={"2rem"}
            >
              <FxButton
                id={"activate-debit-card-button"}
                className="fx-button fx-button-action fx-buttom-full-width"
                startIcon={<SelectIcon />}
                onClick={handleActivation}
              >
                Activate
              </FxButton>
            </Grid>
          )}
          {checkDebitCardPermission('activate_debit_card') && props.data.status === "ACTIVE" && props?.data?.properties?.isDigitalFirst && !props?.data?.properties?.cardPresentTransactionsEnabled &&
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              direction="row"
              paddingTop={"2rem"}
            >
              <FxButton
                id={"enable-physical-debit-card-button"}
                className="fx-button fx-button-action fx-buttom-full-width"
                startIcon={<SelectIcon />}
                onClick={handleEnablePhysicalCard}
              >
                Enable Physical Card
              </FxButton>
            </Grid>
          }

          {(props.data.status === "ACTIVE" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED" || props.data.status === "CANCELLED") && (
            <Grid >
              <Grid
                container
                item
                xs={12}
                spacing={2}
                display="flex"
                flexGrow={1}
                justifyContent="space-between"
                paddingTop={"2rem"}
              >
                {checkDebitCardPermission('block_debit_card') && <Grid item xs={12} sm={12}>
                  <FxButton
                    id={"block-replace-debit-card-button"}
                    className={`fx-button  fx-button-action fx-buttom-full-width ${props.data.status === "CANCELLED" ? 'fx-button-disabled' : ''}`}
                    startIcon={<BlockReplaceIcon />}
                    onClick={handleBlockAndReplace}
                    disabled={props.data.status === "CANCELLED"}
                  >
                    Block & Replace
                  </FxButton>
                </Grid>}

                {checkDebitCardPermission('reissue_debit_card') && <Grid item xs={6} sm={6}>
                  <FxButton
                    id={"reissue-debit-card-button"}
                    className={`fx-button  fx-button-action fx-buttom-full-width ${(props.data.status === "CANCELLED" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED") ? 'fx-button-disabled' : ''}`}
                    startIcon={<ReissueIcon />}
                    onClick={handleReissue}
                    disabled={props.data.status === "CANCELLED" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED"}
                  >
                    Reissue
                  </FxButton>
                </Grid>}

                <Grid item xs={6} sm={6}>
                  {props.data.status !== 'FREEZED' ?
                  checkDebitCardPermission('freeze_debit_card') && 
                    <FxButton
                      id={"freeze-debit-card-button"}
                      className={`fx-button  fx-button-action fx-buttom-full-width ${props.data.status === "CANCELLED" ? 'fx-button-disabled' : ''}`}
                      startIcon={<FreezeIcon />}
                      onClick={handleFreeze}
                      disabled={props.data.status === "CANCELLED"}
                    >
                      Freeze Card
                    </FxButton> 
                  : 
                  checkDebitCardPermission('unfreeze_debit_card') && <FxButton
                      id={"unfreeze-debit-card-button"}
                      className={`fx-button  fx-button-action fx-buttom-full-width ${props.data.status === "CANCELLED" ? 'fx-button-disabled' : ''}`}
                      startIcon={<FreezeIcon />}
                      onClick={handleUnFreeze}
                      disabled={props.data.status === "CANCELLED"}
                    >
                      Unfreeze Card
                    </FxButton>}
                </Grid>
              </Grid>

              {(props.data.status === "ACTIVE" || props.data.status === "CANCELLED" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED") && checkDebitCardPermission('manage_usage_debit_card') && <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                direction="row"
                paddingTop={"2rem"}
              >
                <FxButton
                  id={"handle-usage-debit-card-button"}
                  className={`fx-button  fx-button-action fx-buttom-full-width ${(props.data.status === "CANCELLED" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED") ? 'fx-button-disabled' : ''}`}
                  startIcon={<SettingIcon />}
                  onClick={handleUsage}
                  disabled={props.data.status === "CANCELLED" || props.data.status === "FREEZED" || props.data.status === "FREEZE_INITIATED"}
                >
                  Manage Usage
                </FxButton>
              </Grid>}
            </Grid>
          )}
        </>
      </Grid>

      <Modal
        style={{ overflow: "scroll" }}
        open={activationModalOpen}
        onClose={() => {
          setActivationModalOpen(false);
        }}
        disableEnforceFocus={true}
        disableAutoFocus={false}
        className="fx-modal fx-modal-medium2large"
      >
        <ActivateDebitCard
          debitCardId={props.data.id}
          setDescription={props.setDescription}
          id={"activate-debit-card"}
          setSuccess={props.setSuccess}
          close={() => {
            setActivationModalOpen(false);
          }}
        />
      </Modal>

      <FxModalGeneric
        id={"show-card-info"}
        open={showCardInfoModalOpen}
        onClose={() => {
          setShowCardInfoModalOpen(false);
        }}
        componentProps={{ ...verifyProps }}
        className={'fx-modal-small2medium'}
        component={AuthenticationVerificationModal}
      >
      </FxModalGeneric>

      <FxModalGeneric
        id={"enable-physical-card"}
        open={enablePhysicalCardModalOpen}
        onClose={() => {
          setEnablePhysicalCardModalOpen(false);
        }}
        componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
        className={'fx-modal-small2medium'}
        component={EnablePhysicalCard}
      >
      </FxModalGeneric>

      <FxModalGeneric
        id={"fx-success-card"}
        open={successCardOpen}
        onClose={() => {
          setSuccessCardOpen(false);
        }}
        componentProps={{ title: title, description: description, handleClick: handleSuccess, buttonText: buttonText }}
        className={'fx-modal-small2medium'}
        component={FxSuccessCard}
      >
      </FxModalGeneric>

      <FxModalGeneric
          id={"freeze-debit-card"}
          open={freezeModalOpen}
          onClose={() => {
            setFreezeModalOpen(false);
          }}
          componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, data: props.data, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
          className={'fx-modal-small2medium'}
          component={FreezeDebitCard}
        >
      </FxModalGeneric>

      <FxModalGeneric
          id={"unfreeze-debit-card"}
          open={unfreezeModalOpen}
          onClose={() => {
            setUnFreezeModalOpen(false);
          }}
          componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, data: props.data, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
          className={'fx-modal-small2medium'}
          component={UnFreezeDebitCard}
        >
      </FxModalGeneric>

      <FxModalGeneric
          id={"manage-usage-debit-card"}
          open={usageModalOpen}
          onClose={() => {
            setUsageModalOpen(false);
          }}
          componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, data: props.data, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
          className={'fx-modal-small2medium'}
          component={ManageUsageCard}
        >
      </FxModalGeneric>

      <FxModalGeneric
          id={"block-and-replace-card"}
          open={handleBlockAndReplaceOpen}
          onClose={() => {
            sethandleBlockAndReplaceOpen(false);
          }}
          componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, data: props.data, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
          className={'fx-modal-small2medium'}
          component={BlockAndReplaceDebitCard}
        >
      </FxModalGeneric>

      <FxModalGeneric
          id={"reissue-physical-card"}
          open={handleReissueOpen}
          onClose={() => {
            setHandleReissueOpen(false);
          }}
          componentProps={{ debitCardId: props.data.id, setSuccess: setSuccessCardOpen, data: props.data, setTitle: setTitle, setDescription: setDescription, setBtnText: setButtonText }}
          className={'fx-modal-small2medium'}
          component={ReissueCard}
        >
      </FxModalGeneric>
    </>
  );
});
