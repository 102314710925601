/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, Typography, Divider, Badge, Box } from '@mui/material';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { LogoMenu } from '../../../Utils/Logo';
import { FxLink } from '../../../Action/FxLink';
import { ReactComponent as Verified } from '../../../../assets/svg/verify-filled-icon.svg';
import { getKey } from '../../../../libs/utils/storageManager';
import { useMediaQuery } from 'react-responsive';


/**
 * Component: BusinessLeftBarSignup
 */
export const BusinessLeftBarSignup: React.FC<any> = React.memo((props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  // declaring the menu options
  const options: any = [
    {
      title: "Business Details",
      action: "business_details",
      order: 1
    },
    {
      title: "Location Details",
      action: "location_details",
      order: 2
    },
    {
      title: "Business Owner",
      action: "beneficial_owner",
      order: 3
    }
  ];
  if(getKey('isPPIEnabled') === 'TRUE'){
    options.push({title: "Account Identifier",action: "account_identifier",order: 4 })
  }
  options.push({title: "Review and Submit",action: "review_and_submit",order: 5 })

  //Method handles the sub menu click
  const handleChildTitleClick = (mainOrder: any, subOrder: any) => {
    if (props.onClick && props.data['businessDetailsData']) {
      props.onClick(mainOrder, subOrder)
    }
  }

  return (
    <>
    {
      !isSmallScreen?
      <Grid id="login-promo-Home-Grid" item xs={12} >
      <FxCard id="login-promo-Home-Card" className="fx-login-promo">
        <FxCardHeader>
          <Grid container direction="row" justifyContent="space-between" >
            <Grid item xs={12} >
              <Typography id="business-owner-card-left-card-icon" variant="h3" >
                <Grid className="fx-logo-passport-menu-icon"><FxLink redirect="/dashboard"><LogoMenu /></FxLink></Grid>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography id="business-owner-card-left-card-title" variant="h2" >
                Open Passport Account
              </Typography>
            </Grid>
          </Grid>
        </FxCardHeader>
        <FxCardBody id="login-promo-Home-Card-Body" className="fx-card-general">
          <Grid item container xs={12} className='fx-signup-stage'>
            <Grid xs={12}>
              <Typography id="business-owner-card-left-card-sub-title" variant="h6" >
                VERIFY YOUR BUSINESS
              </Typography>
            </Grid>

            {options.map((link: any, index: any) => {
              return (
                <>
                  {link.title === 'Review and Submit' && <Grid item xs={12}>
                    <Grid>&nbsp;</Grid>
                    <Typography id="business-owner-card-left-card-sub-title" variant="h6" >
                      SUBMIT
                    </Typography>
                  </Grid>}
                  <Grid item xs={12}><ul id={'login-promo-icon-' + link.action}  >
                    <li className={props.activeMainMenu === link.order ? 'fx-signup-stage-selected' : ''}>
                      <Grid item container xs={12} alignItems="center">
                        <Grid item>
                          <Badge badgeContent={index + 1}  >
                            <Box className='fx-signup-stage-icon' > {(((props?.activeMainMenu === 2 + 3 || props?.activeMainMenu === 2 + 2 || props?.activeMainMenu === 2 + 1 || props?.activeMainMenu === 2) && link.title !== 'Review and Submit' && link.title !== 'Location Details' && link.title !== 'Business Owner' && link.title !== 'Account Identifier') || (props?.activeMainMenu === 3  && link.title !== 'Review and Submit' && link.title !== 'Business Details' && link.title !== 'Account Identifier' && link.title !== 'Business Owner') || (props?.activeMainMenu === 4  && link.title !== 'Review and Submit' && link.title !== 'Business Details' && link.title !== 'Account Identifier') || (props?.activeMainMenu === 5  && link.title !== 'Review and Submit'  && link.title !== 'Business Details')) &&  <Verified />}</Box>
                          </Badge>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5" className={props.activeMainMenu === link.order ? 'fx-selected-list fx-list-item' : 'fx-list-item'}>
                            {link.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <ul>
                        {link.childOptions?.map((childLink: any) => {
                          return <li>
                            <Typography variant="h6" onClick={() => handleChildTitleClick(link.order, childLink.order)} className={props.activeMainMenu === link.order && props.activeSubMenu === childLink.order ? 'fx-selected-list' : ''}>{childLink.title}
                            </Typography></li>
                        })}
                      </ul>
                    </li>
                  </ul></Grid>
                </>
              )
            })}
          </Grid>
          <Grid xs={12}>
            <Typography id="signup-left-bar-note" className={'fx-signup-left-bar-note'} >
              Note: Your progress is auto-saved.
            </Typography>
          </Grid>
        </FxCardBody>
      </FxCard>
      </Grid>
      :
      <Grid id="login-promo-Home-Grid" item xs={12} >
      <FxCard id="login-promo-Home-Card" className="fx-login-promo fx-login-promo-topmenu-mobile">
        
        <FxCardBody id="login-promo-Home-Card-Body" className="fx-card-general fx-card-general-mobile">
          <Grid item container xs={12} className='fx-signup-stage fx-signup-stage-mobile'>
            <Grid xs={12}>
            </Grid>
            {options.map((link: any, index: any) => {
              return (
                <>
                  <Grid item xs={12}><ul id={'login-promo-icon-' + link.action} className="fx-login-promo-list-box" >
                    <li className={props.activeMainMenu === link.order ? 'fx-signup-stage-selected' : ''}>
                      <Grid item container xs={12} alignItems="center">
                        <Grid item className="fx-login-promo-list-box-item">
                          <Badge badgeContent={index + 1}  >
                            <Box className='fx-signup-stage-icon' > {(((props?.activeMainMenu === 2 + 3 || props?.activeMainMenu === 2 + 2 || props?.activeMainMenu === 2 + 1 || props?.activeMainMenu === 2) && link.title !== 'Review and Submit' && link.title !== 'Location Details' && link.title !== 'Business Owner' && link.title !== 'Account Identifier') || (props?.activeMainMenu === 3  && link.title !== 'Review and Submit' && link.title !== 'Business Details' && link.title !== 'Account Identifier' && link.title !== 'Business Owner') || (props?.activeMainMenu === 4  && link.title !== 'Review and Submit' && link.title !== 'Business Details' && link.title !== 'Account Identifier') || (props?.activeMainMenu === 5  && link.title !== 'Review and Submit'  && link.title !== 'Business Details')) &&  <Verified />}</Box>
                          </Badge>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5" className={props.activeMainMenu === link.order ? 'fx-selected-list' : ''}>
                            {link.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <ul>
                        {link.childOptions?.map((childLink: any) => {
                          return <li>
                            <Typography variant="h6" onClick={() => handleChildTitleClick(link.order, childLink.order)} className={props.activeMainMenu === link.order && props.activeSubMenu === childLink.order ? 'fx-selected-list' : ''}>{childLink.title}
                            </Typography></li>
                        })}
                      </ul>
                    </li>
                  </ul></Grid>
                </>
              )
            })}
          </Grid>
        </FxCardBody>
      </FxCard>
      </Grid>
    }
    </>
    
  )
})
