/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Chip, Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { ISource } from '../../../types/common.interfaces';
import { Controller, useForm } from 'react-hook-form';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import HttpClient from '../../../libs/utils/httpClient';
import { useHistory, useParams } from 'react-router-dom';
import { checkPermission, clean, convertEndData, convertToOnDayFormat, convertToTitleCaseAndAppendWithS, createCommonVerifyProps, deleteUnwantedRequest, formatDate, renderError, transformRecurringRequestGlobal, updateComponentParams } from '../../Utils/CommonBaseClass';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createSendMyAccount, dataSourceTransformation, getLocationData } from './ScheduleGlobalFunctions';
import { FxSuccess } from '../../Data/FxSuccess';
import { FxSwitch } from '../../Action/FxSwitch';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { FxRecurring } from './Recurring/FxRecurring';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxFileUpload } from '../../Input/FxFile/FxFileUpload';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { ScheduleTopSection } from './ScheduleTopSection';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
const httpClient = HttpClient.getClient();

const acc_src: ISource = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

const deliveryModeData = [
    { label: 'Two Day', value: 'TWO_DAY' },
    { label: 'Overnight', value: 'OVERNIGHT' },
    { label: 'Standard', value: 'STANDARD' }
];

const docTypeData = [
    { label: 'Contract', value: 'CONTRACT' }
];

Logger.debug("CreateSendAccountCHECK.tsx", "create send Account Check initializing")
/**
 * This component handles the send creation through my account for check method
 */
export const CreateSendAccountCHECK: React.FC<any> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const history = useHistory()
        const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, setError, watch } = useForm();
        const sendMyAccountInfo: any = context?.data?.body;
        let sendMyAccountId: any = '';
        let customerInfo: any;
        const params = useParams<any>();
        const scheduleId = params?.id;
        const passedmaiingAddressId = params.mailingaddressid;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
        const individual = uiFeat.getIndividualCustomerFeature().available;
        const isEditMode = !!scheduleId;
        const method_Tab = useMemo(() => [
            {
                title: 'ACH',
                link: '/transaction/send/add/ach',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CREATE"
                  },
                  {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "CREATE"
                  }]
            },
            {
                title: 'WIRE',
                link: '/transaction/send/add/wire',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "CREATE"
                  },{
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "CREATE"
                  }]
            },
            {
                title: 'INTERNATIONAL WIRE',
                link: '/transaction/send/add/internationalWire',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "CREATE"
                  },{
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "CREATE"
                  }]
            },
          ...(!individual ? [{
                title: 'CHECK',
                className: 'fx-link-sel',
                link: '/transaction/send/add/check',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "CREATE"
                  },{
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "CREATE"
                  }]

            }] : []),
            {
                title: 'Book',
                link: '/transaction/send/transfer',
                disabled: isEditMode,
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CREATE"
                  },{
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "CREATE"
                  }]
            }
        ], [isEditMode]);

        const isEdit = !!props.source;
        const [isloading, setIsloading] = useState(false);
        const [customeData, setCustomeData] = useState<{value: string, label: string}[]>();
        const [repeatStatus, setRepeatStatus] = useState(false);
        const [allowDuplicate, setAllowDuplicate] = useState(false);
        const [recurringFormData, setRecurringFormData] = useState<any>();
        const [fileUploadData, setFileUploadData] = useState<any>();
        const [location, setLocation] = useState<any>();

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            customerApi();
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-check-form-card-file-id-file-upload', 'files': [] } });
            if (!isEdit) {
                setValue('type', 'REGULAR')
            }
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
            if (!scheduleId) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Send Money',backButton:'/dashboard' } }});
                return;
            }
            const src = {
                url: "/transaction/id/" + scheduleId,
                method: "GET",
            };
            dispatch({
                type: "DATA_API_PARAM_CHANGED",
                payload: { id: "edit-send-to-account-check", source: src },
            });
            
        }, []);
        /**
        * Method triggers if the recurring updates
        */
        const handleUpdateRecurring = (data: any) => {
            setRecurringFormData(data);
        }

        /**
         * Method enables and disables the allow duplicate
         * @param event : allow duplicate status
         */
        const handleAllowDuplicate = (event: any) => {
            setAllowDuplicate(event)
        }
        /**
         * Method enables and disables the repeat functionality
         * @param event : repeat status
         */
        const handleRepeat = (event: any) => {
            setRepeatStatus(event)
        }

        /**
         * Function handles cancel button click
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push("/transactions/send/" + context.data.body.id);
            } else {
                history.push("/dashboard");
            }
        };

        /**
         * Async function handles the api call for getting customer address for check withdrawal
         */
        async function customerApi() {
            try {
                await httpClient.get(getCustomerUrl('/',false)).then((response: any) => {
                    if (response.error) {
                        FxSnackBar.show({
                            text: 'Please try again',
                        });
                    }
                    else {
                        customerInfo = response.data;
                        setCustomeData(getMailingAddress(customerInfo?.business ? customerInfo.business.mailingAddress : customerInfo?.individual?.mailingAddress));
                    }
                })
            }
            catch (err) {
                Logger.error("CreateSendAccountCHECK.tsx", "error", err);
            }
        }

        const getMailingAddress = useCallback((data: any) => data.map((item: any) => ({
            value: item?.id,
            label: item?.addressLine1 + " " +
                (item?.addressLine2 ? item?.addressLine2 + ' ' : '') + item?.city + ' ' + item?.state + ' ' + item?.zip
        })), []);

        /**
         * Method to set file upload data
         * @param event :event object
         */
        function passdata(event: any) {
            try {
                setFileUploadData(event)
                if (event.length > 0 && errors['fileError']) {
                    clearErrors('fileError');
                }
            }
            catch (e) { 
                Logger.error("CreateSendAccountCHECK.tsx", "error", e);
            }
        }
        /**
         * Method to remove file upload data
         * @param id : file id
         */
        const removefile = (id: any) => {
            const newval = fileUploadData.filter((item: any) => item.id !== id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-check-form-card-file-id-file-upload', 'files': newval } });
            setFileUploadData(newval)
        }

        /**
         * Method handles setting form values for edit schedule scenario
         * @param data :
         */
        const setFormValues = (data: any) => {
                setValue("amount", data?.amount);
                setValue("purpose", data?.purpose);
                setValue("destination", customeData?.find(({value}) => value === data?.destination?.address?.id)?.label);
                setValue("deliveryMode", data?.processingDetail?.deliveryMode);
                setValue("memo", data?.processingDetail?.memo);
                setAllowDuplicate(data?.allowDuplicate);
        };

        /**
         * useEffect to set edit data details
         */
        useEffect(() => {
            if(!context?.data?.body) {
                return;
            }
            setFormValues(context?.data?.body);
            if(context?.data?.body?.processingDetail?.location){
                getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
              }          
        }, [context?.data?.body, customeData]);

        /**
         * Async function handles the create send form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let request: any = {};
            let recurringRequest: any = {};
            if (!isEdit && !data.destination) {
                setError("destination", {
                    type: "manual",
                    message: "Destination is required",
                });
                return;
            }
            if (repeatStatus) {
                recurringRequest = transformRecurringRequestGlobal(data, recurringFormData)
                req = {
                    name: data?.name,
                    transactionDetail: recurringRequest['transactionDetail'],
                    action: []
                }
                req['transactionDetail']['startDate'] = data?.startDate;
                request = {
                    transaction: {
                        source: {
                            account: {
                                id: data.source
                            }
                        },
                        method: 'CHECK',
                        type: 'REGULAR',
                        scheduleDate: moment(formatDate(new Date())).format('MM/DD/YYYY'),
                        amount: data.amount,
                        purpose: data.purpose,
                        allowDuplicate: allowDuplicate
                    }

                };
                request['transaction']['destination'] = {
                    address: {
                        id: data.destination
                    }
                }
                request['transaction']['processingDetail'] = {};
                if (data['deliveryMode'] || data['checkNumber']) {
                    if (data['deliveryMode'] && data['memo']) {
                        request['transaction']['processingDetail']['deliveryMode'] = data.deliveryMode
                        request['transaction']['processingDetail']['memo'] = data.memo
                    }
                    if (data['deliveryMode'] && !data['memo']) {
                        request['transaction']['processingDetail']['deliveryMode'] = data.deliveryMode
                    }
                    if (data['checkNumber']) {
                        request['transaction']['processingDetail']['checkNumber'] = data.checkNumber;
                        request['transaction']['processingDetail']['memo'] = data.memo
                    }

                    if (data.receivingPayee && !isEdit) {
                        request['transaction']['processingDetail']['recipient'] = {};
                        request['transaction']['processingDetail']['recipient']['payee'] = {};
                        request['transaction']['processingDetail']['recipient']['payee']['id'] = data.receivingPayee
                    }
                }
                if(data['location']){
                    request['transaction']['processingDetail']['location'] = {
                        'id' : data['location']
                    }
                }
                req['action'].push(request)
                if (fileUploadData?.length) {
                    req["linkedDocument"] = fileUploadData.map((item: any) => ({
                      purpose: "Authorization",
                      document: { id: item.id }
                    }));
                }
            }
            else {
                req = {
                    source: {
                        account: {
                            id: data.source
                        }
                    },
                    method: 'CHECK',
                    type: 'REGULAR',
                    scheduleDate: moment(formatDate(new Date())).format('MM/DD/YYYY'),
                    amount: data.amount,
                    purpose: data.purpose,
                    allowDuplicate: allowDuplicate
                };
                req['destination'] = {
                    address: {
                        id: data.destination
                    }
                }
                req['processingDetail'] = {};
                if (data['deliveryMode'] || data['checkNumber']) {
                    if (data['deliveryMode'] && data['memo']) {
                        req['processingDetail']['deliveryMode'] = data.deliveryMode
                        req['processingDetail']['memo'] = data.memo
                    }
                    if (data['deliveryMode'] && !data['memo']) {
                        req['processingDetail']['deliveryMode'] = data.deliveryMode
                    }
                    if (data['checkNumber']) {
                        req['processingDetail']['checkNumber'] = data.checkNumber;
                        req['processingDetail']['memo'] = data.memo
                    }

                    if (data.receivingPayee && !isEdit) {
                        req['processingDetail']['recipient'] = {};
                        req['processingDetail']['recipient']['payee'] = {};
                        req['processingDetail']['recipient']['payee']['id'] = data.receivingPayee
                    }

                }
                if(data['location']){
                    req['processingDetail']['location'] = {
                        'id' : data['location']
                    }
                }
            }
            if (isEditMode) {
                req["id"] = context?.data?.body?.id;
                const unwantedRequestArray = ["source", "destination", "method", "type"];
                deleteUnwantedRequest(req, unwantedRequestArray);
            }
            req = clean(req);
            setIsloading(true);
            let status: any;
            status = await createSendMyAccount(req);
            if (status?.headers?.url) {
                sendMyAccountId = (status.headers.url).split('/').pop();
            }

            status = processAPIResponse(status)
            setIsloading(false);
            if (status.status) {
                //api success
                const successMessage = sendMyAccountInfo ? 'Send Updated Successfully!' : repeatStatus ? 'Recurring Created Successfully!' : 'Send Created Successfully!';
                const redirectUrl = sendMyAccountInfo ? '/transactions/send/' + sendMyAccountInfo['id'] : repeatStatus ? '/thanks/transactions/send-check-recurring/' : '/thanks/transactions/send-check/';
                const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                if(status.responseData?.sessionId){
                   
                    const verifyProps = {
                    ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
                    successMessage: successMessage,
                    redirectUrl: redirectUrl,
                    redirectUrlFromHeader: sendMyAccountInfo ? false : true,
                    postSuccessEvent: handlePostSuccessEvent,
                    };
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                    if (sendMyAccountInfo) {
                        FxSnackBar.show({
                            autoHideDuration: 1000,
                            severity: 'success',
                            text: 'Send Updated Successfully!',
                        });
                        history.push('/transactions/send/' + sendMyAccountInfo['id']);
                    }
                    else {
                        FxSnackBar.show({
                            autoHideDuration: 1000,
                            severity: 'success',
                            text: repeatStatus ? 'Recurring Created Successfully!' : 'Send Created Successfully!',
                        });
                        if (repeatStatus) {
                            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-send-account-check-form-card-file-id-file-upload', 'files': [] } });
                        }
                        if (repeatStatus) {
                            history.push('/thanks/transactions/send-check-recurring/' + sendMyAccountId)
                        }
                        else {
                            history.push('/thanks/transactions/send-check/' + sendMyAccountId)
                        }
                    }
                }
               
            }
            else {
                //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
            }
        }

        /**
         * The main summary data
         */
        const amount = watch('amount');
        const destinationId = watch('destination');
        const destinationLabel = useMemo(() => {
            const address = customeData?.find(({value}) => value === destinationId);
            if(address) {
                return (address.label);
            } else {
                if(destinationId) {
                    return destinationId
                }
            }
        }, [destinationId, customeData]);
        const summaryData: Record<string, TransactionSummaryFieldType | string | undefined> = {
            'Send Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: accountLabelTransformation(getKey('selectedAccount'))
            },
            'To Address': {
                id: 'to-address',
                value: destinationLabel,
            },
            ...(businessCustomer
            ? { Purpose: { id: "purpose", value: watch("purpose") } }
            : { Memo: { id: "purpose", value: watch("purpose") } }),
            'Via': {
                id: 'method',
                value: 'CHECK',
            },
            'Delivery Mode': {
                id: 'delivery-mode',
                value: watch("deliveryMode"),
            }
        };

        if(businessCustomer) {
            summaryData['Memo'] = {
                id: 'memo',
                value: watch("memo"),
            }
        }

        /**
         * Recurring related summary data
         */
        const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
            if(!repeatStatus || !recurringFormData) {
                return {}
            }
            const {repeatEveryFormData, onDayStatus, onDateStatus} = recurringFormData;
            const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
              ? convertToTitleCaseAndAppendWithS(
                  recurringFormData?.repeatEveryFormData?.repeatEverySelect
                )
              : '';
            const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
                'Repeat every': {
                    id: 'repeat-every',
                    value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
                },
            }
            if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
                result['On'] = {
                    id: 'repeat-on',
                    value: convertToOnDayFormat(recurringFormData),
                };
            }
            const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
            const ends = recurringFormData?.endFormData
            ? convertEndData(recurringFormData?.endFormData)
            : '';
            if(ends || endOn) {

            }
            result['Ends'] = endOn ? {
                id: 'repeat-ends',
                value: ends,
                ValueComponent: ({value}) => (
                    <>
                        On <FxDateTimeView value={value} format=" MMM DD, yyyy"/>
                    </>
                )
            } : {
                id: 'repeat-ends',
                value: ends,
            };
            return result;
        }, [recurringFormData, repeatStatus]);

        const topSectionDefaultValues = {
            amount: context?.data?.body?.amount,
            source: context?.data?.body?.source?.id,
            purpose: context?.data?.body?.purpose
          }
          const tabClickFunction = () => {
            try{
              const data:any = {
                schedule: 'send',
                type: 'my-account',
                formData: { amount: watch('amount'), purpose: watch('purpose')}
              }
              dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch(e){
                Logger.error("CreateSendAccountCHECK.tsx", "error", e);
            }
          }
            /**
             * Function to handle the closing of the modal.
             */
                
            const handleCloseModal = () => {
                const verifyProps = {...props.verifyProps}
                verifyProps['open'] = false;
                updateComponentParams(dispatch, props.id, { verifyProps });
            };

             /** 
            *This function is called upon successful completion of a post operation.
            *It dispatches an action to update component parameters related to file uploads.
            */
            const handlePostSuccessEvent=()=>{
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'reate-send-account-check-form-card-file-id-file-upload', 'files': [] } });
            }
        return (
          <>
            <ScheduleLayout
                id={'create-send-account-check'}
                type='send'
                loading={isloading}
                submitTitle={repeatStatus ? 'Create' : 'Send Money'}
                summaryData={{...summaryData, ...summaryRepeatData}}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={handleClose}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                {passedmaiingAddressId && <FxSuccess id="sucess-mailing-address" header={"Mailing Address added successfully"} description={"ID - " + passedmaiingAddressId} />}
                <ScheduleTopSection id="create-send-account-check-schedule-top-section" schedule="send" type="my-account" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataSourceTransformation={dataSourceTransformation} amountId="create-send-account-ach-form-card-amount-textbox" sourceId="create-send-contact-ach-form-card-source" purposeId="create-send-contact-ach-form-card-purpose-textbox"/>
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-send-account-check-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                {/* <Grid item>
                    <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} prefix="$" id="create-send-account-ach-form-card-amount-textbox" label="Amount*" name="amount" type="number" variant="outlined" defaultValue={context?.data?.body?.amount ? context.data.body['amount'] : ''} showDecimal={true} setValue={setValue} />
                </Grid>
                <Grid item>
                    <FxMaterialSelect register={{ ...register("source") }} id="create-send-contact-ach-form-card-source" name="source"
                        source={acc_src} control={control} rules={{ required: true }}
                        dataTransformation={dataSourceTransformation}
                        value={context?.data?.body?.source?.id ? context.data.body.source.id : getKey("selectedAccount")?.id} readOnly={true} label="Source Account" setValue={setValue} />
                </Grid>
                <Grid item>
                    <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true, maxLength: 128 }} id="create-send-contact-ach-form-card-purpose-textbox" label="Purpose*" name="purpose" variant="outlined" defaultValue={context?.data?.body?.purpose ? context.data.body['purpose'] : ''} />
                </Grid> */}
                <Grid item>
                    <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-account-check-form-card-tab" tabClick={tabClickFunction}/>
                </Grid>
                <Grid item>
                {isEditMode ? (
                    <FxTextEdit
                        register={{ ...register("destination") }}
                        className={
                            errors.destination
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        control={control}
                        id="create-send-account-ach-form-card-destination-textbox"
                        label="Destination Address*"
                        name="destination"
                        variant="outlined"
                        isEditable={false}
                    />
                ) : (
                    <FxMaterialSelect register={{ ...register("destination") }} control={control}
                        rules={{ required: true }} id="create-send-account-check-form-card-destination" name="destination" value={passedmaiingAddressId ? passedmaiingAddressId : ''} data={customeData} label="Select Destination Address" setValue={setValue} />
                )}
                </Grid>
                <Grid item>
                    <FxMaterialSelect register={{ ...register("deliveryMode") }} control={control} rules={{ required: true }} id="create-send-account-check-form-card-delivery-mode" name="deliveryMode" data={deliveryModeData} value={context?.data?.body?.processingDetail?.deliveryMode ? context.data.body['processingDetail']['deliveryMode'] : 'STANDARD'} label="Delivery Mode" setValue={setValue} />
                </Grid>
                {businessCustomer && <Grid item>
                    <FxTextEdit register={{ ...register("memo") }} className={errors.memo ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-send-account-check-form-card-memo-textbox" label="Memo" name="memo" variant="outlined" defaultValue={context?.data?.body?.memo || ''} />
                </Grid>}
                <Grid item>
                    <FxSwitch id='create-send-account-check-form-card-allow-duplicate' value={allowDuplicate} onClick={handleAllowDuplicate}></FxSwitch> <FxLabel value="Allow Duplicate"></FxLabel>
                </Grid>
                {(!isEditMode && checkPermission({entity: "Recurring Transaction",name: "Check Send",operation: "Create" })) && (
                    <Grid item>
                        <FxSwitch id='repeat-switch' value={repeatStatus} onClick={handleRepeat}></FxSwitch> <FxLabel value="Repeat"></FxLabel>
                    </Grid>
                )}
                {repeatStatus && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <FxDateEdit
                            register={{ ...register("startDate") }}
                            className={
                                errors.startDate
                                ? "border-error-input fx-input-edit"
                                : "fx-input-edit"
                            }
                            control={control}
                            id="create-send-account-check-form-card-startDate"
                            name="startDate"
                            type="date"
                            variant="outlined"
                            label={"Start Date *"}
                            setValue={setValue}
                            disablePast={true}
                            resetField={resetField}
                            rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxTextEdit
                                register={{ ...register("name") }}
                                className={
                                    errors.name
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                control={control}
                                rules={{ required: true }}
                                id="create-send-account-check-form-card-name-textbox"
                                label="Name *"
                                name="name"
                                variant="outlined"
                                />
                        </Grid>

                <Grid item xs={12} sm={12}>
                        <FxRecurring
                            id="create-send-account-check-form-card-fx-recurring"
                            updateTrigger={handleUpdateRecurring}
                            startDate={watch("startDate")}
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={12}>
                        <Typography className="filter-popover-label">
                            LINKED DOCUMENTS
                        </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        &nbsp;
                        </Grid>
                        <Grid
                        item
                        container
                        xs={12}
                        spacing={1}
                        alignItems="center"
                        >
                        <Grid item xs={12} sm={8}>
                            <FxMaterialSelect
                                register={{ ...register("docType") }}
                                id="create-send-account-check-form-card-doc-type"
                                control={control}
                                rules={{ required: false }}
                                name="docType"
                                data={docTypeData}
                                value={"CONTRACT"}
                                readOnly={true}
                                label="Select a document type"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className="fx-choose-file-button">
                            <Controller
                            name={"fileId"}
                            control={control}
                            rules={{ required: false }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                <FxFileUpload
                                    register={{ ...register("fileId") }}
                                    name="fileId"
                                    passData={passdata}
                                    id="create-send-account-check-form-card-file-id-file-upload"
                                    onChange={(e: any) => {
                                    onChange(e);
                                    }}
                                    value={
                                    fileUploadData ? fileUploadData : value
                                    }
                                    acceptedFiles={[
                                    ".pdf",
                                    ".docx",
                                    ".jpg",
                                    ".txt",
                                    ".png",
                                    ".jpeg",
                                    ".xls",
                                    ".wav",
                                    ".xlsx",
                                    ".tiff",
                                    ".doc",
                                    ".rtf",
                                    ".bmp",
                                    ".efx",
                                    ".csv",
                                    ".kswps",
                                    ".wps",
                                    ]}
                                    formData={{ type: "CONTRACT" }}
                                    setValue={setValue}
                                    maxFileSize={10485760}
                                />
                                </>
                            )}
                            />
                        </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                        <FxLabelView
                            className="fx-label-small"
                            id="create-send-account-check-form-card-file-types-supported"
                        >
                            (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                            .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                        </FxLabelView>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <div className={"error-message"}>
                            {errors.fileError && renderError(errors.fileError)}
                        </div>
                        </Grid>
                        {fileUploadData && (
                        <Grid item container>
                            {fileUploadData.map((key: any) => {
                            return (
                                <Chip
                                icon={<InsertDriveFileTwoToneIcon />}
                                label={key["name"]}
                                onDelete={() => removefile(key["id"])}
                                color="primary"
                                variant="outlined"
                                deleteIcon={<DeleteIcon />}
                                />
                            );
                            })}
                        </Grid>
                        )}
                    </Grid>
                </Grid>
                </>
                )}
            </ScheduleLayout>
          </>
        );
    });
