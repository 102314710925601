import React from 'react'; // we need this to make JSX compile
import { AccountCard } from './AccountCard';
import { useParams } from "react-router-dom";
/**
 * This component handles the rendering of AccountCard component
 */
export const AccountDetails =
    () => {
        interface RouteParams {
            id: string
        }
        const params = useParams<RouteParams>();
        const id = params.id
        const account_source = {
            url: "/account/id/" + id,
            method: "GET"
        }

        const accountCardId = "account-details-config-card" + id;
        return (
            <>
                <AccountCard id={accountCardId} source={account_source}></AccountCard>
            </>
        )

    }
