import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { Filter } from '../../Data/Filter';
import { getKey } from '../../../libs/utils/storageManager';
import { maskAccountNumber } from '../../Utils/CommonBaseClass';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';

import { IFilterColumns, IDataGridProps, IFilterFields } from '../../../types/common.interfaces';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import FxRightDrawer from '../../Utils/fx-right-drawer';
import { IApplyDebitCardPayload } from '../../DrawerPages/FxApplyDebitCard/FxApplyDebitCard';
import { FxConsumerDebitCardApply } from '../../DrawerPages/FxApplyDebitCard/FxConsumerDebitCardApply';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * This component handles the list of Customer DebitCard List
 */
export const CustomerDebitCardList: React.FC<any> = React.memo(
    (props) => {
        const history = useHistory()
        const dispatch = useDispatch()
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        const isDebitCardDisplayed = ((getKey('isIssueDebitCardIndividualAllowed') === "TRUE" && individualCustomer)||(getKey('isIssueDebitCardBusinessAllowed') === "TRUE" && businessCustomer)) && getKey("selectedAccount")?.isCustomerOwned === true && (getKey("selectedAccount")?.status !== 'INACTIVE' && getKey("selectedAccount")?.status !== 'CLOSURE_INITIATED' && getKey("selectedAccount")?.status !== 'CLOSED') ;
       


        //rest the component while first time loading
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Debit Card' } } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const src = {
            url: "account/id/"+getKey("selectedAccount")?.id+"/debitCard/list",
            method: "POST",
            data: {
              "pageNumber": 1,
              "pageSize": 25,
              "criteria": {
                  "filters": []
              }
            }
        }

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Card ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Card Holder Name",
                    "enable": true,
                    "fieldName": "cardHolderName",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "CARD #",
                    "enable": true,
                    "fieldName": 'account',
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Status",
                    "enable": true,
                    "fieldName": 'status',
                    "type": "fri://fieldtype/status",
                    "default": true
                },
                {
                    "label": "Status Reason",
                    "enable": true,
                    "fieldName": 'statusReason',
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Issued",
                    "enable": true,
                    "fieldName": "createdInfo",
                    "type": "fri://fieldtype/custom",
                    "default": true
                }
            ]
        }
        /**
        * Method to transform data
        * @param data
        */

        function transformList(data: any) {
            try {

                const newData: any = [];
                data?.forEach((item: any, i: number) => {
                    newData[i] = { ...data[i] }
                    if (item) {
                        newData[i].cardHolderName = item?.cardHolder?.name
                        newData[i].issued = item?.lastUpdatedOn + '' + item?.lastUpdatedBy?.username
                        newData[i].account = maskAccountNumber(item?.cardLast4, false);
                        newData[i].createdInfo = <><FxDateTimeView tooltip={true} value={item['createdOn']} format={"MMM DD, yyyy"}></FxDateTimeView>
                            <span>By {item['createdBy']['username']}</span></>
                    }
                });

                return newData;
            }
            catch (e) {
                return false;
            }
        }

        const datagridProps: IDataGridProps = {
            id: "customer-debit-card-list",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
        };

        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "Created On",
                    "toLabel": "To",
                    "fieldName": "createdOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Last Updated On",
                    "toLabel": "To",
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                }
            ]
        }

        /**
        * Method handles the redirection to details page
        * @param event : event parameter
        * @param data : row data
        */
        function redirectDetailsPage(event: any, data: any) {
            history.push(`/debit-card/details/${data.id}`);
        }

          /**
     * Method used to open right drawer to apply for debit card for Consumers
     */
        const handleAddNewClick = () => {
            FxRightDrawer.show<IApplyDebitCardPayload>(({ onClose }) => {
                return <FxConsumerDebitCardApply onDrawerClose={onClose} />
            })
        };

       const isAddNewDisabled = getKey("accountStatus") === 'false';

        return (
            <Grid id="customer-debit-card-list-first-grid" item xs={12}>
                <Grid container xs={12}>
                    <FxCard id="customer-debit-card-list-card" className="fx-theme-passport">
                        <FxCardHeader>
                            <Grid id="customer-debit-card-list-header-first-grid" spacing={2} className="fx-action-block fx-inline-flex" item container justifyContent="flex-end" xs={12}>
                                {isDebitCardDisplayed && <Grid item>
                                    <FxButton id="customer-debit-card-list-add-new-button" onClick={handleAddNewClick} disabled={isAddNewDisabled} className={isAddNewDisabled ? 'fx-button fx-button-disabled fx-svg-theme' :'fx-button fx-button-theme fx-svg-theme'} startIcon={<AddIcon />} >ADD NEW</FxButton>
                                </Grid>}
                                <Grid id="customer-debit-card-list-card-header-filter-grid" item >
                                    <Filter id="customer-debit-card-list-filter" field={filterFields} dataGridProps={datagridProps} permissions={{entity: "Accounts",name: "Debit Card",operation: "View"}} hiddenParameter={['cardAccount.id']}></Filter>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="customer-debit-card-list-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="customer-debit-card-list-card-body-grid" item xs={12}>
                                <DataGrid id="customer-debit-card-list" permissions={{entity: "Accounts",name: "Debit Card",operation: "View"}} tableClass="fx-table-passport" dataTransformer={transformList} source={src} column={column} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="customer-debit-card-list-card-footer">
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })



