/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { FxTextEdit } from "../../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { useForm } from "react-hook-form";


Logger.debug("FxRepeatEveryRecurring.tsx", "Fx Repeat Every Recurring initializing")

const repeatEverySelectData = [
    { label: 'Calendar Day', value: 'CALENDAR_DAY' },
    { label: 'Business Day', value: 'BUSINESS_DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
    { label: 'Year', value: 'YEAR' }
];

/**
 *  This component handles the fx repeat every recurring
 */
export const FxRepeatEveryRecurring: React.FC<any> = React.memo(
    (props: any) => {
        const { setValue } = useForm();


        // Method handles the onchange event for type selection
        const handleChange = (event: any) => {
            try {
                if (props.updateRepeatEveryTrigger) {
                    props.updateRepeatEveryTrigger(event.target.value)
                }
                if (props.updateFormData) {
                    props.updateFormData({name: 'repeatEverySelect', value: event.target.value})
                }

            }
            catch (e) { 
                Logger.error("FxRepeatEveryRecurrings.tsx", "error", e);    
            }
        }
        useEffect(() => {
            if (props.updateFormData && !props?.data) {
                props.updateFormData({name: 'repeatEverySelect', value: 'CALENDAR_DAY'})
            }

        },[]);
        /***
        * to get default data in edit
        */
        useEffect(() => {
            setValue('repeatEvery', props?.data?.interval)
        }, [props?.data]);

        // Method handles the on blur event in the repeat every field
        const handleOnBlur = (value: any) => {
            if (props.updateFormData) {
                props.updateFormData({name: 'repeatEvery', value: value})
            }
        }

        return (
            <Grid item container xs={12} className="fx-repeat-payment-repeat">
                <Grid item xs={4}>
                    <FxTextEdit register={{ ...props?.register("repeatEvery") }} className={props?.errors.repeatEvery ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} control={props?.control} id={props.id + '-textbox'} label="Repeat Every*" name="repeatEvery" type="number" variant="outlined" defaultValue={props?.data?.interval} setValue={props?.setValue} onBlur={handleOnBlur} min={1}/>
                </Grid>
                <Grid item xs={8}>
                    <FxMaterialSelect register={{ ...props?.register('repeatEverySelect') }} className={props?.errors.repeatEverySelect ? "border-error-input fx-input-edit" : "fx-input-edit"} control={props?.control} onChange={handleChange} id={props.id + '-select'} name="repeatEverySelect" rules={{ required: true }} value={props?.data?.frequency ?? 'CALENDAR_DAY'} data={repeatEverySelectData} readOnly={!!props?.data} setValue={props?.setValue} />
                </Grid>
            </Grid>
        )
    })
