import React from 'react';
import { Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxAccountNumberView.tsx", "Fx SSN View initializing")
/**
 * Component used to format AccountNumbers
 */
interface IFxAccountNumberView {
    id: string;
    value?: any;
    className?: any;
    unMask?: boolean;
    tooltip?: any;
}
export const FxAccountNumberView: React.FC<IFxAccountNumberView> = ({ value, tooltip, className, unMask = false, id }) => {
    /**
     * Method used to format account numbers
     * @param value :value
     * @param unMask :boolean
     * @returns 
     */
    const format = (value: any, unMask: boolean) => {
        if (value != null) {
            if (unMask === true) {
                return value;
            }
            value = value.toString();
            if (value.length >= 4 && value.charAt(0) !== '#') {
                return '****' + value.substring(value.length - 4, value.length);
            } else {
                return value;
            }
        } else {
            return '-';
        }
    };
    return (
        <Typography title={tooltip ? format(value, unMask) : ''} id={id} className={className ? className : ''}>{(format(value, unMask))}</Typography>
    )
}