import React, { useEffect, useRef } from 'react';

export interface FxVisibilityIndicatorProps {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    threshold?: number;
    rootMargin?: string;
    onVisibilityChange?(customView: boolean): void;
}

/**
 * The component let's us know is it visible or not
 */
export const FxVisibilityIndicator = React.memo(({threshold = 1.0, rootMargin, className, style, children, onVisibilityChange}: FxVisibilityIndicatorProps) => {
    const divRef = useRef<HTMLDivElement>(null);
    const onVisibilityChangeRef = useRef(onVisibilityChange);
    onVisibilityChangeRef.current = onVisibilityChange;

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => onVisibilityChangeRef.current?.(entry.isIntersecting), {
            threshold,
            rootMargin,
        });
        if(!divRef.current) {
            return;
        }
        observer.observe(divRef.current);
        return () => {
            observer.disconnect();
        }
    }, [threshold, rootMargin]);

    return (
        <div
            ref={divRef}
            style={style}
            className={className}
        >
            {children}
        </div>
    );
});
