import React from "react";
import { Alert } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";


Logger.debug("FxApplicationBanner.tsx", "Fx Application Banner initializing")

export interface IFxApplicationBanner{
  text : string
}

//FX Application Banner Component
export const FxApplicationBanner: React.FC<IFxApplicationBanner> = (props) => {
  Logger.info("FxApplicationBanner.tsx", "bannerprops", props);

  const {text} = props;

  return (
    <Alert variant="filled" icon={false} className='fx-application-banner' >
        {text}
    </Alert>
  );
};
