/* eslint-disable  @typescript-eslint/no-explicit-any */
import React , { useState } from 'react'
import { Divider, Grid } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';

Logger.debug("FxDateButtonGroupModal.tsx", "Date Button Group initializing")
/**
 * This component handles the date button group recurring schedules
 */
export const FxDateButtonGroupModal: React.FC<any> = React.memo(
    (props) => {
        const [selectedDates, setSelectedDates] = useState<any>(props?.data ? props?.data : []);

        /**
         * draw button grid
         * @returns
         */
        function getGridData() {
            const obj: any = []
            for (let i = 1; i <= 31; i++) {
                obj.push(<Grid xs spacing={1} className={'fx-margin-bottom-04'}>
                    <FxButton id={"date" + i} variant="contained" className={selectedDates.indexOf(i) !== -1 ? 'fx-button fx-button-theme' : "fx-button fx-button-passport-border"} onClick={() => { buttonClick(i) }} >{i}</FxButton>
                </Grid>)
            }
            return obj
        }
        /**
         * handle date button click
         * @param value :value
         */
        const buttonClick = (value: any) => {
            const selectedInfo = [...selectedDates];
            const index = selectedInfo.indexOf(value);
            if (index !== -1) {
                selectedInfo.splice(index, 1);
            } else {
                selectedInfo.push(value);
            }
            setSelectedDates(selectedInfo);
        }

        /**
         * Method to close modal
         */
        const closeModal = () => {
            if (props?.close) {
                props?.close()
            }
        }
        /**
         * handle modal submit data
         */
        const submitData = () => {
            if (props.updateFormData) {
                props.updateFormData(selectedDates)
                props?.close()
            }
        }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    <FxCard className="fx-theme-passport">
                        <FxCardBody id="date-selecter-modal-body" className="fx-info-card" >
                            <Grid container direction="row" xs={12} className="fx-modal-form" >
                                <Grid item container xs={12}>
                                    {getGridData()}
                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <Divider />
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="date-selector-modal-Footer" className="fx-footer">
                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                <Grid className='fx-padding-right-15'>
                                    <FxButton
                                        variant="contained"
                                        className="fx-button"
                                        id="cancel-date-modal"
                                        onClick={() => { closeModal() }}
                                    > Cancel
                                    </FxButton>
                                </Grid>
                                <span className="fx-padding-right-16" />
                                <FxButton
                                    disableRipple={false}
                                    className="fx-button fx-button-theme"
                                    id={"submit-date"}
                                    type="submit"
                                    onClick={() => { submitData() }}
                                >
                                    SUBMIT
                                </FxButton>
                            </Grid>
                        </FxCardFooter>
                    </FxCard>
                </div>
            </div>
        )
    });
