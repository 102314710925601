import React, { useState, useRef } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { Logger } from "../../../libs/utils/logger";
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { handleKeyDownChange } from "../../Utils/CommonBaseClass";


/**
 * Component used for account number input
 */
Logger.debug("FxExternalAccountNumberEdit.tsx", "FxExternalAccountNumberEdit initializing")

interface FxExternalAccountNumberEditProps {
    id: string;
    name: string;
    value?: string;
    defaultValue?: String;
    className?: string;
    error?: boolean;
    label?: React.ReactNode;
    placeholder?: string;
    rules?: any,
    register: any,
    control: any,
    variant?: "standard" | "filled" | "outlined" | undefined;
    setError: UseFormSetError<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
}

/**
 * @param props FxExternalAccountNumberEditProps
 * Component can be used for edit account number.
 */
export const FxExternalAccountNumberEdit: React.FC<FxExternalAccountNumberEditProps> =
    (props) => {
        const { id, name, setValue, control, rules, register, defaultValue, placeholder, label, variant } = props;

        const className = (props.className ? props.className : 'fx-input-edit')
        const [editing, setEditing] = useState(false);
        const [showIcon, setShowIcon] = useState(false);
        const [last4Digit, setLast4Digit] = useState(defaultValue);
        const textBoxRef = useRef<any>();
        const initialValue = defaultValue?.includes('*') ? '' : defaultValue;

        function onMouseOver() {
            setShowIcon(true)
        }

        function onMouseOut() {
            setShowIcon(false)
        }
        /**
         * Function to get value change 
         * @param e :data
         */
        function onBlur(e: any) {
            try {
                const value = e.target.value;
                if (value) {
                    setEditing(false);
                    setValue(name, value);
                    const last4Digit = '*****' + value.substr(value.length - 4);
                    setLast4Digit(last4Digit);
                }
            }
            catch (e) { }
        }

        /**
        * Method to handle changes on event for ssn
        */
        const onChangeHandler = (event: any) => {
            try {
                event.persist();
                const targetName = event.target.name;
                if (targetName === name) {
                    if (event.target.value.includes('*')) {
                        setValue(name, '')
                    } else {
                        setValue(name, event.target.value)
                    }
                }

            }
            catch (e) { }
        }

        /**
        * On edit click handler
        */
        const handleClickEditIcon = (value: any) => {
            setEditing(true);
            setTimeout(() => {
                textBoxRef?.current?.focus();
            }, 300)

        };

        return <>
            {!editing && last4Digit !== '' ?
                <TextField
                    variant={variant}
                    id={`${id}-view`}
                    name={`${name}-input`}
                    disabled={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={last4Digit}
                    placeholder={placeholder ? placeholder : ""}
                    className="fx-input-edit"
                    type={'text'}
                    onMouseEnter={onMouseOver}
                    onMouseLeave={onMouseOut}
                    label={label ? label : ''}
                    InputProps={
                        {
                            endAdornment: showIcon && (
                                <InputAdornment position="end">
                                    <IconButton
                                        className="fx-icon-button"
                                        onClick={handleClickEditIcon}
                                        id={`${id}-view-icon`}
                                        size="large">
                                        {<EditIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }
                /> :
                <Controller
                    name={name}
                    control={control ? control : ''}
                    rules={rules ? rules : {}}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            {...register}
                            variant={variant}
                            id={id}
                            name={name}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!editing && last4Digit !== ''}
                            defaultValue={initialValue}
                            placeholder={placeholder ? placeholder : ""}
                            className={className}
                            type={'text'}
                            onBlur={onBlur}
                            label={label ? label : ''}
                            value={(props.value === '' ? props.value : (props.value ? props.value : value))}
                            onChange={onChangeHandler}
                            onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }}
                            inputRef={textBoxRef}
                            error={error ? error : false}
                        />
                    )}
                />
            }
        </>;
    }