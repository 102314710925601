import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { FxTreasureInfoLayout } from './FxTreasureInfoLayout';
import { ReactComponent as TreasureIcon } from '../../../assets/svg/TreasureIcon.svg';
import { FxTreasureWelcomeCard } from './FxTreasureWelcomeCard';
import { getKey } from '../../../libs/utils/storageManager';
import { LogoAccount } from '../../Utils/Logo';
import { FxTreasureLeftMenu } from './FxTreasureLeftMenu';
import { FxBusinessInfo } from './FxBusinessInfo';
import { FxAuthSignatoryDetail } from './FxAuthSignatoryDetail';
import { TreasureReviewAgreement } from './TreasureReviewAgreement';
import { TreasureAcceptReviewAgreement } from './TreasureAcceptReviewAgreement';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import HttpClient from '../../../libs/utils/httpClient';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { clean } from '../../Utils/CommonBaseClass';

Logger.debug("FxTreasureDrawer.tsx", "sign up info")

interface ITreasureDrawer {
    onDrawerClose: (event: any) => void;
    customerInfo : any;
    setPortfolioCreated : any
}

export const FxTreasureDrawer: React.FC<ITreasureDrawer> = (props) => {
    ({ props } = RegisterComponent(props));
    const [step, setStep] = useState<number>(1);
    const [formReq, setFormReq] = useState<any>();
    const [formData, setFormData] = useState<any>();
    const [investmentObjective, setInvestmentObjective] = useState('');

    const httpClient = HttpClient.getClient();

    const customerInfo = props?.customerInfo?.business;
    const [ownerDetails, setOwnerDetails] = useState({
        taxDetail: {},
        financialDetail: {},
        beneficialOwner: {},
        foreignFinancialInstituion: false,
        industryType: ''
    });
    const [loading, setLoading] = useState(false);

    const treasureLayoutHeader = <>
        <h1>Opt in for&nbsp;</h1>
        <div ><TreasureIcon /></div>
        <h1>Investment &nbsp;</h1>
        <h1>Account Now! &nbsp;</h1>
    </>;

    useEffect(() => {
        getCustomerDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * To get customer detail to manage the state for onboard drawer component
    */
    async function getCustomerDetail() {
        const customerDetails = await httpClient.get('customer/id/' + getKey('customerId')).then(response => {
            return response;
        }).catch((error) => {
            return { ...error };
        })
        if(customerDetails.data?.business) {
            setOwnerDetails({
                ...ownerDetails,
                taxDetail: customerDetails?.data?.business?.taxDetail,
                financialDetail: customerDetails?.data?.business?.financialDetail,
                beneficialOwner: customerDetails?.data?.business?.beneficialOwner[0],
                foreignFinancialInstituion: customerDetails?.data?.business?.foreignFinancialInstituion || false,
                industryType: customerDetails?.data?.business?.industryType[0] || '',
            })
        }
    }

    /**
     * ON change handler
    */
    function onChange(step: number) {
        setStep(step);
        if(step === 6){
            props.setPortfolioCreated(step)
        }
    }

    /**
     * Go to dashboard button handler
    */
    const goToDashboard = (async (event: any) => {
        onChange(6)
        props.onDrawerClose(event);
    })

    /**
     * Method handles the onsubmit event of the business details page
     * @param data 
     * @param req 
     */
     const handleBusinessInfoSubmit = (data: any, req: any) => {
        setInvestmentObjective(req.investmentObjective)
        setFormData({
            ...formData,
            businessInfoData: data
        });
        setFormReq({
            ...formReq,
            businessInfoReq: req
        });
        onChange(3);
    }

    /**
     * Method handles the onsubmit event of the Authorized Signatory Details page
     * @param req 
     */
    const handleAuthSignatorySubmit = (req: any) => {
        setFormReq({
            ...formReq,
            authSignatoryReq: req
        });
        updateCustomerDetails(req);
    }

    /**
     * Method to Call Update Customer API
     * @param req 
     */
    async function updateCustomerDetails(req:any) {
        await transformRequest(req)
    }

    /**
     * Method transforms the request from various pages for api call
     * @param authSigInfo 
     * @returns 
     */
    async function transformRequest(authSigInfo:any){
        let benficailOwnerInfo = customerInfo.beneficialOwner.map((item: any) => {
            if(item.actAsAuthorizedSignatory === true){
                return {
                    id: item.id,
                    mailingAddress: item.mailingAddress,
                    ...authSigInfo
                }
            }
            else {
                return null
            }
        })

        let req:any = {
            business: {
                "description": customerInfo?.description ? customerInfo?.description : undefined,
                "industryType": [formReq['businessInfoReq']['industryType']],
                "financialDetail": formReq['businessInfoReq']['financialDetail'],
                "taxDetail": formReq['businessInfoReq']['taxDetail']['taxClassification'] ? formReq['businessInfoReq']['taxDetail'] : undefined,
                "foreignFinancialInstituion": formReq['businessInfoReq']['foreignFinancialInstituion'],
                "beneficialOwner": benficailOwnerInfo
            },
        }
        setLoading(true)
        let status: any;
        req = clean(req);
        status = await updateCustomerInformationRequest(req);
        status = processAPIResponse(status)
        if (status.status) {
            setLoading(false)
            setTimeout(() => {
                onChange(4);
            }, 1000);
        }
        else {
            setLoading(false)
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
            return false;
        }
    }


    /**
     * API call function
     * @param req :request
     * @returns
     */
    async function updateCustomerInformationRequest(req: any) {
        try {
            const data: any = await httpClient.post('customer/id/' + getKey('customerId'), req).then(response => {
                return response
            })
                .catch((error) => {
                    return { ...error };
                })
            return data;
        } catch (err) {
            return err;
        }
    }

    return (
        <Grid container id="treasure-account" >
            <Grid item className={'fx-drawer-left-content fx-treasure-drawer-left-content'}>
                <Grid item xs={12} className={`fx-treasure-left-layout-header  ${step !== 1 ? 'fx-treasure-left-layout-header-grey' : ''}`}>
                <Grid item className='fx-account-summary-card-center-account-logo fx-logo'> <LogoAccount /></Grid> &nbsp;
                    <Grid item xs={10}>
                        <Typography variant='h2' className={'fx-welcome'}>Welcome!</Typography>
                        <Typography variant='h3' className={'fx-username'}>{getKey('CustomerName')}</Typography>
                    </Grid>
                </Grid>
                {step === 1 && <FxTreasureInfoLayout layoutHeader={treasureLayoutHeader} />}
                {step !== 1 && <FxTreasureLeftMenu step={step}/>}
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                {step === 1 && <FxTreasureWelcomeCard onChange={onChange} />}
                {step === 2 && <FxBusinessInfo onChange={onChange} data={customerInfo} businessDetail={formData?.businessInfoData} onSubmit={handleBusinessInfoSubmit} ownerDetails={ownerDetails} setOwnerDetails={setOwnerDetails}/>}
                {step === 3 && <FxAuthSignatoryDetail onChange={onChange} data={customerInfo} onSubmit={handleAuthSignatorySubmit} loading={loading} ownerDetails={ownerDetails} setOwnerDetails={setOwnerDetails}/>}
                {step === 4 && <TreasureReviewAgreement id="treasure-review-agreement" onChange={onChange} data={customerInfo} investmentObjective={investmentObjective}/>}
                {step === 5 && <TreasureAcceptReviewAgreement onDrawerClose={goToDashboard} />}
            </Grid>
        </Grid>
    )
}