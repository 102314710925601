import React from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as VerificationIcon } from '../../../assets/svg/otp-verification-icon.svg';
import { ReactComponent as DividerIcon } from "../../../assets/svg/divider-icon.svg";
import { clean } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { getKey } from "../../../libs/utils/storageManager";
import FxSnackBar from "../../Utils/fx-snack-bar";
const httpClient = HttpClient.getClient();

Logger.debug("UnFreezeDebitCard.tsx", "UnFreeze Debit Card initializing");
/**
 * This component handles the UnFreeze action of the Debit Card
 */
export const UnFreezeDebitCard: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, control, formState: { isSubmitting } } = useForm();

  const { debitCardId, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {
    let status: any;
    let request: any;

    request = {};

    if(data.comment){
      request.comment = data.comment;
    }

    status = await unfreezeDebitCard(clean(request));
    status = processAPIResponse(status)

    if(status.status){
      setTitle('Request Sent') 
      setDescription('Your Request to Unfreeze card is placed successfully.');
      setSuccess(true);
      setBtnText('Close');
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
    props.onClose();
  }


  /**
   * Method to call api for UnFreezing Debit Card
   * @param payloadData : request payload
   */
  async function unfreezeDebitCard(payloadData: any) {
    try {
      const url =
        "/account/id/" +
        getKey("selectedAccount")?.id +
        "/debitCard/id/" +
        debitCardId +
        "/unfreeze";
      const data: any = await httpClient.post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("UnFreezeDebitCard.tsx", "error", err);
      return err;
    }
  }

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className="fx-unfreeze-debit-card-modal">
          <FxCardHeader id="replace-debit-card-header" className="fx-card-header">
            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  <VerificationIcon />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="fx-replace-debit-card-modal-title" align="center" className="fx-replace-debit-card-modal-title" >
                    Unfreeze Card
                  </Typography>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="unfreeze-debit-card-modal-body" className="fx-info-card">
            <Grid item xs={12} className="fx-modal-form flex column">
              <Typography id="title-delete-modal" variant="h5" className="fx-unfreeze-debit-card-modal-subtext">
                Unfreezing the card will remove the temporary block placed on your card. You will be able to use your card for transactions once it is unfreezed.
              </Typography>
            </Grid>
            <Grid item xs={12} className="fx-modal-form flex column">
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("comment") }}
                  type="text"
                  passwordIcon={true}
                  className="fx-input-edit"
                  control={control}
                  rules={{ required: false }}
                  id="comment"
                  label={"Comment"}
                  name="comment"
                  variant="outlined"
                />
              </Grid>
              <span className="fx-replace-debit-card-modal-divider">
                <DividerIcon />
              </span>
            </Grid>
          </FxCardBody>
          <FxCardFooter id="unfreeze-debit-card-modal-Footer" className="fx-footer">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                type="submit"
                className="fx-button fx-button-theme"
                id={"unfreeze-debit-card-submit-button"}
                isSubmitting={isSubmitting}
              >
                SUBMIT
              </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>
    </Grid>
  );
});
