/**
 * AdminPreferences.tsx
 * This component handles the setting of the preferences for otp and two step authentication
 */
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { updateComponentData } from "../../Utils/CommonBaseClass";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxSwitch } from "../../Action/FxSwitch";
import { useForm } from "react-hook-form";
import FxCheckEdit from "../../Input/FxCheck/FxCheckEdit";
import { FxButton } from "../../Action/FxButton";

interface IAdminPreferences {
    id: string;
}




const userData = [
    { label: 'RICHARD JACOB 1 (ID 1234)', value: '1234' },
    { label: 'RICHARD JACOB 2 (ID 2341)', value: '2345' },
    { label: 'RICHARD JACOB 3 (ID 3412)', value: '3456' },
    { label: 'RICHARD JACOB 4 (ID 1234)', value: '4567' },
    { label: 'RICHARD JACOB 5 (ID 2341)', value: '5678' },
    { label: 'RICHARD JACOB 6 (ID 3412)', value: '6789' }
]

export const AdminPreferences: React.FC<IAdminPreferences> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const {
        register,
        setValue,
        control,
        watch
    } = useForm();
    useEffect(() => {
        updateComponentData(dispatch, 'page-title-details', { title: 'Admin Preferences' })
        setValue('otpVerification', true)
        setValue('twoStepAuthentication', true)
        setValue('allUsers', 'FALSE')
        setValue('fewUsers', 'TRUE')
        setValue('collect', 'COLLECT')
        setValue('send', 'SEND')
        setValue('mobileEmail', 'MOBILE_EMAIL')
        setValue('userList',userData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const collectData = [
        { label: 'Collect txn creation - all methods', value: 'COLLECT', checked: watch('collect')?.length > 0 ? true : false }
    ]

    const sendData = [
        { label: 'Send txn creation - all methods', value: 'SEND', checked: watch('send')?.length > 0 ? true : false }
    ]

    const mobileEmailData = [
        { label: 'Mobile/ e-mail ID change', value: 'MOBILE_EMAIL', checked: watch('mobileEmail')?.length > 0 ? true : false }
    ]
    /**
     * Function to handle changes in OTP verification status
     * @param value : switch value
     */
    const handleOtpVerificationChange = (value: any) => {
        setValue('otpVerification', value)
        if (value === false) {
            setValue('collect', '')
            setValue('send', '')
            setValue('mobileEmail', '')
        }
    }


    return (
        <Grid item container spacing={2}>
            <Grid item container className="fx-admin-preferences">
                <Grid item xs={12} sm={12} className="fx-admin-preferences-sub-title-grid">
                    <Typography id={'admin-preferences-otp-verification-sub-title'} className="fx-admin-preferences-sub-title">OTP VERIFICATION</Typography>
                </Grid>
                <Grid item container>&nbsp;</Grid>
                <Grid item container xs={12} sm={12}>
                    <Grid item xs={1} sm={1} className="fx-admin-preferences-switch-grid"><FxSwitch id='otp-verification' value={watch('otpVerification')} onClick={handleOtpVerificationChange}></FxSwitch></Grid>
                    <Grid item xs={6} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <Typography id={'admin-preferences-otp-verification-switch-title'} className="fx-admin-preferences-switch-title">OTP Verification</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography id={'admin-preferences-otp-verification-switch-sub-title'} className="fx-admin-preferences-switch-sub-title">By enabling OTP verification you can add a extra layer of security</Typography>
                            {watch('otpVerification') && <><Grid item container xs={12} sm={12} className="fx-admin-preferences-otp-verify-grid">
                                <FxCheckEdit
                                    register={{ ...register("collect") }}
                                    className={watch('collect')?.length > 0 ? 'fx-input-edit fx-admin-preferences-otp-checkbox-checked' : 'fx-input-edit fx-admin-preferences-otp-checkbox-unchecked'} control={control}
                                    id="admin-preferences-otp-verify-collect"
                                    name="collect"
                                    data={collectData}
                                    setValue={setValue}
                                />
                            </Grid>
                                <Grid item container xs={12} sm={12} className="fx-admin-preferences-otp-verify-grid">
                                    <FxCheckEdit
                                        register={{ ...register("send") }}
                                        className={watch('send')?.length > 0 ? 'fx-input-edit fx-admin-preferences-otp-checkbox-checked' : 'fx-input-edit fx-admin-preferences-otp-checkbox-unchecked'} control={control}
                                        id="admin-preferences-otp-verify-send"
                                        name="send"
                                        data={sendData}
                                        setValue={setValue}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={12} className="fx-admin-preferences-otp-verify-grid">
                                    <FxCheckEdit
                                        register={{ ...register("mobileEmail") }}
                                        className={watch('mobileEmail')?.length > 0 ? 'fx-input-edit fx-admin-preferences-otp-checkbox-checked' : 'fx-input-edit fx-admin-preferences-otp-checkbox-unchecked'} control={control}
                                        id="admin-preferences-otp-verify-mobile-email"
                                        name="mobileEmail"
                                        data={mobileEmailData}
                                        setValue={setValue}
                                    />
                                </Grid></>}
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container>&nbsp;</Grid>
                {/* <Grid item xs={12} sm={12} className="fx-admin-preferences-sub-title-grid">
                    <Typography id={'two-step-authentication-sub-title'} className="fx-admin-preferences-sub-title">TWO STEP AUTHENTICATION</Typography>
                </Grid>
                <Grid item container>&nbsp;</Grid>
                <Grid item container xs={12} sm={12} className="fx-admin-preferences-switch-grid">
                    <Grid item xs={1} sm={1}><FxSwitch id='two-step-authentication' value={watch('twoStepAuthentication')} onClick={handleTwoStepAuthenticationChange}></FxSwitch></Grid>
                    <Grid item xs={6} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <Typography id={'two-step-authentication-switch-title'} className="fx-admin-preferences-switch-title">Two Step Authentication</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography id={'two-step-authentication-switch-sub-title'} className="fx-admin-preferences-switch-sub-title">Two step authentication for login</Typography>
                            {watch('twoStepAuthentication') && <><Grid item xs={12} sm={12} className="fx-admin-preferences-otp-verify-grid">
                                <FxRadioEdit register={{ ...register("allUsers") }} className={watch('allUsers') === 'FALSE' ? 'fx-input-edit fx-admin-preferences-radio-unchecked' : 'fx-input-edit fx-admin-preferences-radio-checked'} control={control} data={allUsersData} id="allUsers" name="allUsers" setValue={setValue} onChange={handleAllUsersChange} defaultValue={watch('allUsers')} />
                            </Grid>
                                <Grid item xs={12} sm={12} className="fx-admin-preferences-otp-verify-grid">
                                    <Grid item><FxRadioEdit register={{ ...register("fewUsers") }} className={watch('fewUsers') === 'TRUE' ? 'fx-input-edit fx-admin-preferences-radio-checked' : 'fx-input-edit fx-admin-preferences-radio-unchecked'} control={control} data={fewUsersData} id="fewUsers" name="fewUsers" setValue={setValue} onChange={handlefewUsersChange} defaultValue={watch('fewUsers')} /></Grid>
                                    
                                    {watch('fewUsers') === 'TRUE' && <Grid item className="fx-admin-preferences-user-selection-grid"><FxMaterialMultipleSelect name='userList'
                                        control={control}
                                        id="userList"
                                        data={userData}
                                        isEdit={true}
                                        value={watch('userList')}
                                        label=''
                                        canSearch={true}
                                        register={{ ...register("userList") }}
                                        setValue={setValue} /> </Grid>}
                                </Grid></>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container>&nbsp;</Grid> */}
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer fx-admin-preferences-footer">
                <FxButton
                    disableRipple={false}
                    className={"fx-button fx-button-theme"}
                    id={"admin-preferences-submit-button"}
                    type="submit"
                >
                    Submit
                </FxButton>
            </Grid>
        </Grid>
    )
})