import React, { useState } from 'react'; // we need this to make JSX
import { FxButton } from '../../Action/FxButton';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { AUTH_STRINGS } from '../../../constants/strings';
import { FxEmailEdit } from '../../Input';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import { FxPassword } from '../../Input/FxPassword/FxPassword';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import HttpClient from '../../../libs/utils/httpClient';
import { useHistory, useLocation } from 'react-router-dom';
import { FxHidden } from '../../Input/FxHiden/FxHidden';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();
/**
 * Component: attemptRegister
 * Usage: for user registration
 */

interface InputErrType {
    type: string;
    message: string;
}
//Email validation criteria
interface EmailValidationOption {
    required: boolean;
    pattern: {
        value: any;
        message: string;
    };
    maxLength: number;
}
//password validation criteria
interface PasswordValidationOption {
    required: boolean;
    minLength: number;
    maxLength: number;
    pattern?: {
        value: any;
        message: string;
    };
}
/**
 * Method to call signup API
 * @param paylaoddata : request payload
 */
async function attemptRegister(paylaoddata: any) {
    let data: any;
    try {
        data = await httpClient.post('auth/signup', paylaoddata).then(response => {
            return response.data
        }).catch(error => {
            return error && error.response && error.response.data ? error.response.data : null;
        })
        if (!data) {
            data = { errorCode: "invalid_input_data", errorMessage: "an unexpected error occurred please try again later!" }
        }

    } catch (err) {
        console.log("error", err)
    }
    return data;
}
const UserRegistration = (): JSX.Element => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const location = useLocation();

    const token = location.pathname.split("/")[3];
    let emailId: any;
    let firstName: any;
    let lastName: any;
    try {
        const qparams = new URLSearchParams(window.location.search);
        emailId = qparams.get('email')
        firstName = qparams.get('firstName')
        lastName = qparams.get('lastName')

    } catch (err) {
        console.log("error", err)
    }

    const [isloading, setIsloading] = useState(false);
    const history = useHistory();

    /**
     * Method to handle form submit event
     * @param data :form data
     */
    async function onSubmit(data: any) {
        setIsloading(true);
        const status = await attemptRegister(data);
        setIsloading(false);
        if (status) {
            //auth success
            if (!status.errorDescription && !status.errorCode) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'User registered successfully',
                });
                history.push("/login");
            }
            else {
                FxSnackBar.show({
                    text: status?.errorDescription || 'Please try again later',
                });
            }
        }
        else {
            //auth failed
            FxSnackBar.show({
                text: 'Please try again',
            });
        }
    };


    const renderPassError = (err: InputErrType): string => {
        if (err.type === 'required') {
            return AUTH_STRINGS.ERRORS.PASS_PLEASE_ENTER;
        }
        if (err.type === 'minLength') {
            return AUTH_STRINGS.ERRORS.PASS_INVALID_LENGTH;
        }
        return err.message;
    };

    const renderEmailError = (err: InputErrType): string => {
        if (err.type === 'required') {
            return AUTH_STRINGS.ERRORS.EMAIL_PLEASE_ENTER;
        }
        return err.message;
    };

    const setEmailValidationOptions: any = (): EmailValidationOption => {
        return {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };
    };

    const setPassValidationOptions: any = (): PasswordValidationOption => {
        return {
            required: true,
            minLength: 8,
            maxLength: 100
        };
    };


    return (
        <div className="login-page">
            <div className="login-page-content fx-margin-top-50">
                <div className="airbase-logo">
                    {/* header */}
                    {/* eslint-disable-next-line*/}
                    <a className="registration-head">
                        Create Your Account</a>
                </div>
                <div className="airbase-logo">
                     {/*eslint-disable-next-line*/}
                    <a className="registration-suntext">
                        Your coworkers are waiting!
                </a>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-page-content-card">
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="Email" />
                            <FxEmailEdit
                                id="email"
                                name="email"
                                className="fx-input-edit login-email"
                                inputRef={register(setEmailValidationOptions())}
                                error={errors.email}
                                viewType="outlined"
                                value={emailId}
                            />
                            <div className={'error-message'}>
                                {errors.email && renderEmailError(errors.email)}
                            </div>
                        </div>
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="First name" />
                            <FxTextEdit
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                className="fx-input-edit login-email"
                                error={errors.firstName}
                            />
                            <div className={'error-message'}>
                                {errors.firstName && renderEmailError(errors.firstName)}
                            </div>
                        </div>
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="Last Name" />
                            <FxTextEdit
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                className="fx-input-edit login-email"
                                error={errors.lastName}
                            />
                            <div className={'error-message'}>
                                {errors.lastName && renderEmailError(errors.lastName)}
                            </div>
                        </div>
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="Password" />
                            <FxPassword
                                id="password"
                                name="password"
                                inputRef={register(setPassValidationOptions())}
                                error={errors.password}
                                className="fx-input-edit login-password"
                                viewType="outlined"
                            />
                            <div className={'error-message'}>
                                {errors.password &&
                                    renderPassError(errors.password)}
                            </div>
                        </div>
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="Confirm Password" />
                            <FxPassword
                                id="repeatPassword"
                                name="repeatPassword"
                                inputRef={register(setPassValidationOptions())}
                                error={errors.password}
                                className="fx-input-edit login-password"
                                viewType="outlined"
                            />
                            <div className={'error-message'}>
                                {errors.repeatPassword &&
                                    renderPassError(errors.repeatPassword)}
                            </div>
                            <FxHidden
                                id="token"
                                name="token"
                                className="fx-input-edit"
                                value={token}

                            />
                        </div>
                        <div className="flex login-buttonarea">
                            <FxButton
                                id="login-signin"
                                type="submit"
                                className="login-signin"
                            >
                                {isloading ? (
                                    <CircularProgress
                                        size={20}
                                        style={{
                                            color: 'white',
                                        }}
                                    />
                                ) : (
                                        'Create Account'
                                    )}
                            </FxButton>
                        </div>
                    </div>
                </form>
            </div>

        </div>

    );
};

export default UserRegistration;
