/* eslint-disable  @typescript-eslint/no-explicit-any */
import { put, takeLatest, takeEvery, select } from "redux-saga/effects";
import { State } from "../reducer/rootReducer";

const getContext: any = (state: State) => state.context;
function* getComponentSaga(action: any) {
  yield put({ type: "CREATE_COMPONENT", value: action });
}

/**
 *
 * This will ensure the page JSON is created and ready for processing by AppPage component
 * @param portal
 * @param page
 */

const getPage = (state: State) => state.config.page;

function* getPageJSON(action: any): any {
  const page = action.payload.fri;
  const pageData = yield select(getPage);
  if (pageData[page].json === undefined) {
    // call api saga to get JSON
    //yield put({type: "PAGE_JSON_SET", payload: { fri: page, json: combineJSON() }});
  } else {
    //json is already there.. just set the status to ready
    yield put({
      type: "LOAD_PAGE_JSON_READY",
      payload: { fri: page, json: pageData[page].json }
    });
  }
}

function* generatePageContext(action: any): any {
  //loop through JSON, identify each component,
  const context: any = {};
  //loop through all components in JSON,
  // let pageData = yield select(getPage);
  // const json = pageData[action.payload.fri].json;
  const component = "fri::toolbar::c1";
  context[component] = {
    config: {
      // read from JSON
    },
    data: {}
  };
  yield put({
    type: "PAGE_CONTEXT_INITIALIZE",
    payload: { fri: action.payload.fri, context: context }
  });
}

function* makePageReady(action: any) {
  yield generatePageContext(action);
}

function* setContextSaga(action: any) {
  yield put({ type: "SET_MANUAL_CONTEXT", value: action.payload });
}
function* setContextSagaHard(action: any) {
  yield put({ type: "SET_MANUAL_CONTEXT_HARD", value: action.payload });
}

function* setContextIfNullSaga(action: any): any {
  const context = yield select(getContext);
  if (context && context[action.payload.data.id]) {
    return;
  }
  yield put({ type: "SET_MANUAL_CONTEXT", value: action.payload.data });
}

export default function* componentSaga() {
  yield takeLatest("CREATE_COMPONENT_SAGA", getComponentSaga);
  yield takeLatest("LOAD_PAGE", makePageReady);
  yield takeLatest("LOAD_PAGE_JSON", getPageJSON);
  yield takeEvery("SET_CONTEXT", setContextSaga);
  yield takeEvery("SET_CONTEXT_HARD", setContextSagaHard);
  yield takeEvery("SET_CONTEXT_IF_NULL", setContextIfNullSaga);
}
