/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import React from 'react';
import { FxSwitch } from '../Action/FxSwitch';
import FxLabel from '../Input/FxLabel/FxLabelView';

export const FxFasterFundingSwitch: React.FC<any> = (props) => {
    return (
        <Grid item xs={12} sm={12}>
            <FxSwitch id="instant-funding-switch" value={props?.instantFunding} onClick={props?.handleInstantFund} disabled={props?.disabled}></FxSwitch>
            <FxLabel value="Faster Funding"></FxLabel>
        </Grid>
    )
}