/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxImage from '../../Data/FxImage';
import VirtualCardBackgroundImages from '../../../assets/images/VirtualCardBackgroundImage.png';
import VirtualCardLoader from '../../../assets/images/VirtualCardLoader.gif';
import VirtualCardLoaderGreen from '../../../assets/images/VirtualCardLoaderGreen.gif';
import { checkIsMXM } from '../../Utils/ThemeChecker';


Logger.debug("VirtualCardLoadingImage.tsx", "Virtual card image loading initializing")

/**
 * This component handles virtual card loading image
 */
export const VirtualCardLoadingImage: React.FC<any> = React.memo(
    (props) => {

        return (
            <Grid className='fx-virtual-card-block' item xs={12}>
                <FxImage id="virtual-card-image" src={VirtualCardBackgroundImages}/>
                <FxImage className={'virtual-card-loader'} id="virtual-card-loader" src={checkIsMXM() ? VirtualCardLoaderGreen:VirtualCardLoader}/>
            </Grid>
            );
    });
