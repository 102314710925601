import React from 'react';
import { ReactComponent as VisaLogo } from '../../../src/assets/svg/visa-image.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { FxButton } from '../Action/FxButton';

interface IFxApplyDebitCardButton {
    disabled?: boolean;
    onClick: () => void;
}

export const FxApplyDebitCardButton: React.FC<IFxApplyDebitCardButton> = ({ disabled, onClick }) => {

    return (
        <FxButton onClick={()=>!disabled && onClick()} id="apply-for-debit-card-button" className={`fx-arrow-button fx-apply-for-debit-card-button ${disabled ? 'disabled' : ''}`}>
            <div>
                <VisaLogo className="fx-visa-logo" />
                <p>
                    <span>Apply for Debit Card Now</span>
                </p>
            </div>
            <ArrowRight className="fx-arrow-icon" />
        </FxButton>
    )
}