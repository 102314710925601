import React from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as EnthusiasticImg } from '../../../assets/svg/Enthusiastic-pana.svg';
import { ReactComponent as PassportLogoMXM } from '../../../assets/svg/logo/passportNameLogoGreenBlue.svg';
import { getKey } from '../../../libs/utils/storageManager';
import { useMediaQuery } from 'react-responsive';

/**
 * Component used inside the right drawer as a left layout for account activation flow
 */

export const FxAccountActivationLayout: React.FC = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    return (
        <Grid container className={'fx-drawer-left-layout fx-account-activation-left-layout'}>
            <Grid container item>
            <Grid item xs={12} className={'fx-left-layout-header'}>
                <h2>Welcome!</h2>
                <h2>{getKey('CustomerName')}</h2>
            </Grid>
            {
                isSmallScreen ? <Grid item display={isSmallScreen ? 'flex' : ''} alignItems={'center'} width={'100%'}>
                <Grid item className={'fx-left-layout-image'} >
                    <EnthusiasticImg />
                </Grid>
                <Grid item className={'fx-left-layout-passport'}>
                        <h1>Activate & Set</h1>
                        <PassportLogoMXM/>
                        <h1>as your</h1>
                        <h1>Funding Account</h1>
                        <h1>to gain access to</h1>
                </Grid>
            </Grid> :<>
                    <Grid item className={'fx-left-layout-image'} >
                        <EnthusiasticImg />
                    </Grid>
                    <Grid item className={'fx-left-layout-passport'}>
                        <h1>Activate & Set</h1>
                        <PassportLogoMXM/>
                        <h1>as your</h1>
                        <h1>Funding Account</h1>
                        <h1>to gain access to</h1>
                    </Grid>
                </>
            }
            <Grid item className={'fx-funding-gain-access'}>
                {
                     <h1><span className='fx-funding-gain-access-priority'>Pri</span><span>ority Capital</span></h1>
                }
            </Grid>
            </Grid>
        </Grid>
    )
}