import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxInvestmentAccountGetStarted } from './FxInvestmentAccountGetStarted ';
import { FxInvestmentAccountAgreement } from './FxInvestmentAccountAgreement';
import { FxInvestmentAccountCongratulation } from './FxInvestmentAccountCongratulation';

Logger.debug("FxInvestmentAccountDrawer.tsx", "sign up info")

interface IInvestmentAccountDrawer {
    type? : string;
    onDrawerClose: (event: any) => void;
}

export const FxInvestmentAccountDrawer: React.FC<IInvestmentAccountDrawer> = (props) => {
    ({ props } = RegisterComponent(props));
    const [step, setStep] = useState<number>(1);  

    /**
     * ON change handler
    */
    function onChange(step: number) {
        setStep(step);
    }

    /**
     * Go to dashboard button handler
    */
    const goToDashboard = (async (event: any) => {
        props.onDrawerClose(event);
    })

    return (
        <Grid container id="treasure-account" >            
            {step === 1 && <FxInvestmentAccountGetStarted type={props?.type} onChange={onChange} />}         
            {step === 2 && <FxInvestmentAccountAgreement type={props?.type} onChange={onChange} />}
            {step === 3 && <FxInvestmentAccountCongratulation id='treasure-investment-account-congratulation' type={props?.type} onDrawerClose={goToDashboard} />}
        </Grid>
    )
}