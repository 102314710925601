/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { toTitleCase } from "../../../libs/utils/string";
import { FxDelete } from '../../Data/FxDelete';

//icons
import { ReactComponent as MenuIcon } from '../../../assets/svg/gear-icon-svg.svg';
import { FxButton } from '../../Action/FxButton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ValidateEA } from './ValidateEA';
import { addressFormatter, getEAOrAddressDetailsFromContact } from '../../Utils/CommonBaseClass';
import moment from 'moment';

Logger.debug("EADetailsCard.tsx", "External Account details initializing");

/**
 * Component: EADetailsCard
 * Usage: To display external account information
 */

export const EADetailsCard: React.FC<any> = React.memo((props) => {
        //#region Variables Declarations
        const history = useHistory();
        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let contact_data: any;
        let external_account_data: any;
        const params = useParams<any>();
        const contactId = params?.contactId;
        const id = params.id
        const [validateEAOpen, setValidateEAOpen] = React.useState(false);
        const options: any = [];
        let edit: boolean = true;
        let ews: boolean = false;
        let preNote: boolean = false;

        const componentState = useSelector((state: any) => {
            if (state.data['ea-details-info-card']) {
                return state.data['ea-details-info-card']
            } else {
                return { config: { params: {} } };
            }
        });

        if (context && context?.data && context?.data?.body) {
            contact_data = { ...context.data.body }
            if (contact_data?.externalAccount) {
                external_account_data = { ...getEAOrAddressDetailsFromContact(contact_data, "EA", id, false) };
            }
            if (external_account_data?.accountNumberLast4) {
                external_account_data.accountNumber = '** ' + external_account_data?.accountNumberLast4;
                external_account_data.accountNumberTitle = '** ' + external_account_data?.accountNumberLast4
            }
            external_account_data.createdInfo = (external_account_data['createdBy']['username'] + '\nOn ' + moment(external_account_data['createdOn']).format('MM/DD/YYYY'));
            external_account_data.updatedInfo = (external_account_data['lastUpdatedBy']['username'] + '\nOn ' + moment(external_account_data['lastUpdatedOn']).format('MM/DD/YYYY'));
            external_account_data.holderType = external_account_data?.holderType ? toTitleCase(external_account_data?.holderType) : '';
            external_account_data.bankAccountType = external_account_data?.type ? toTitleCase(external_account_data?.type) : '';
            external_account_data.holderAddress=external_account_data?.holderAddress?addressFormatter("",external_account_data.holderAddress):'';
            if (external_account_data?.routingNumber && external_account_data?.bankInfo) {
                external_account_data.routingNumberInfo = external_account_data?.routingNumber + ' (' + external_account_data?.bankInfo?.name + ')';
                external_account_data.bankName = external_account_data?.bankInfo?.name;
            }
            if(external_account_data?.type)
            {
                external_account_data.type=toTitleCase(external_account_data?.type)
            }
            if (external_account_data?.validateAccount) {
                // eslint-disable-next-line array-callback-return
                external_account_data?.validateAccount.map((element: any) => {
                    if (element?.ews) {
                        ews = !!element?.ews;
                    }
                    if (element?.prenote) {
                        preNote = !!element?.prenote;
                    }
                })
            }
        }
        /**
            * Method to rest the component on unmount.so that when redirect back page it will call the api again
            */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, [])

        
        useEffect(() => {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'External Account '+ external_account_data?.accountNumberTitle, backButton: '/payee/view/'+componentState?.data?.body?.id} } });
        }, [external_account_data])

        /**
         * Handle close event
         */
        const handleClose = () => {
            setValidateEAOpen(false);

        };

        /**
         * Transform data
         */
        if (external_account_data) {
            try {

                if ((external_account_data['status'] === 'ACTIVE')) {
                    edit = false;

                }
                else if (external_account_data['status'] === 'BLOCKED') {
                    edit = false;

                }
                //  if (isValidate) {
                options.push({
                    id: 'externalaccount-validate-option',
                    title: 'Validate EA',
                    icon: <MenuIcon />
                })
                //}

            } catch (e) {
                Logger.error("EADetailsCard.tsx","external_account_data",e);
            }


        }

    const external_account_bank_section_left_one = [
        {
            label: 'Holder Type',
            value: 'holderType'
        },
        {
            label: 'Holder Phone',
            value: 'holderPhone'
        }
    ];


    const external_account_bank_section_right_one = [
        {
            label: 'Holder Name',
            value: 'holderName'
        },
        {
            label: 'Holder Address',
            value: "holderAddress"
        },
    ];
    const external_account_bank_section_left_two = [
        {
            label: 'Account Status',
            value: 'status',
            type: 'fri://fieldtype/status'
        },
        {
            label: 'Account Number',
            value: 'accountNumber'
        },
        {
            label: 'Account Type',
            value: 'bankAccountType'
        },

    ];
    const external_account_bank_section_right_two = [
        {
            label: 'Routing Number',
            value: 'routingNumberInfo'
        },
        {
            label: 'Wire Routing Number',
            value: 'wireRoutingNumberInfo'
        }
    ];
    const external_account_bank_section_left_three = [
        {
            label: 'Created',
            value: 'createdInfo'
        }

    ];
    const external_account_bank_section_right_three = [
        {
            label: 'Last Updated',
            value: 'updatedInfo'
        }

    ];
        const EAValidation = [
            { label: 'Instant Verification', value: "EWS" },
            { label: 'Prenote', value: "PRENOTE" }
        ];

        const editPermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"EDIT"
          }

          const deletePermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"DELETE"
          }
        
        return (
            <Grid id="external-account-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="deposit-info-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'dexternal-account-info-card#card-header'}>
                        <Grid id="external-account-Grid2" item container justifyContent="space-between" xs={12} >
                        <Typography id="external-account-title-label" variant="h4" >External Account Information</Typography>
                            <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                                {edit && <Grid item> <FxButton id="payee-edit-ea-button" permissions={editPermission} variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<EditIcon />} onClick={() => history.push(`/payee/id/${contactId}/externalaccount/edit/${id}`)}>Edit</FxButton></Grid>}
                                <Grid item><FxButton id="externalaccount-validate-button" variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<MenuIcon />} onClick={() => setValidateEAOpen(true)}>Validate EA</FxButton></Grid>
                                <Grid item><FxDelete id={external_account_data?.id} permissions={deletePermission} buttonId={'payee-ea'} entity={'/contact/id/' + contactId + '/externalAccount'} buttonTitle="Delete EA" header={"Are you sure want to delete this External Account?"} description="You can only take this action if there are no transactions scheduled on this External account. You cannot undo this action." successEntity="External Account"
                                    redirectUrl={'/payee/view/'+contactId} /></Grid>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="deposit-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                            {external_account_data && <><Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-external-account-basic-info-one" + external_account_data['id']} title="BASIC INFORMATION" leftcolumn={external_account_bank_section_left_one} rightcolumn={external_account_bank_section_right_one} data={external_account_data} />
                            </Grid>
                            <Grid item xs={12}>
                                    <FxInfoCard id={"section-info-card-external-account-basic-info-two" + external_account_data['id']} title="ACCOUNT INFORMATION" leftcolumn={external_account_bank_section_left_two} rightcolumn={external_account_bank_section_right_two}  data={external_account_data} />
                                </Grid>
                                <Grid item xs={12}><FxInfoCard id={"section-info-card-external-account-basic-info-three"+ external_account_data['id']} title="AUDIT DETAILS" leftcolumn={external_account_bank_section_left_three} rightcolumn={external_account_bank_section_right_three} data={external_account_data} /></Grid>
                            </>}
                        </Grid>
                    </FxCardBody>
                </FxCard>
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={validateEAOpen}
                    onClose={handleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
               {<ValidateEA contactSource={contactId} id={'payee-validate-ea'} data={external_account_data} eaSource={EAValidation} close={handleClose} />}
                </Modal>
            </Grid >

        )
    })
