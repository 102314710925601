import React, { useEffect, useState } from "react";
import { Logger } from "../../../libs/utils/logger";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { FxButtonGroup } from "../../Action/FxButtonGroup";
import { FundingRulesList } from "./FundingRulesList";
import { useHistory } from "react-router";

Logger.debug(
  "InactiveFundingRule.tsx",
  "Inactive Funding Rule Queue Listing initializing"
);

/**
 * Component: Inactive Funding Rule Queue
 * Usage: Listing of the Inactive Funding Rule Queue
 */
export const InactiveFundingRuleQueue: React.FC<any> = React.memo((props) => {
  const [listingType, setListingType] = useState("inactive");
  const dispatch = useDispatch();
  const history = useHistory();

  //API for FUnding Rule list for inactive rule
  let src = {
    url: "/fundingRule/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        "sortBy": "lastUpdatedOn",
        "sortOrder": "desc"
      },
      criteria: {
        filters: [
          {
            operator: "eq",
            key: "status",
            values: ['INACTIVE'],
          }
        ],
      },
    },
  };


  //type of Funding Rules Listing Options
  const typeData = [
    { id: 'active', value: 'ACTIVE', label: 'ACTIVE', selected: false, path: '/fundingrules/active' },
    { id: 'inactive', value: 'INACTIVE', label: 'INACTIVE',  selected: true, path: '/fundingrules/inactive' } 
   ];
  /**
   * useEffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: { id: "page-title-details", data: { title: "Funding Rules" } },
    });
  }, [dispatch]);

  /**
  * Method handles the onchange of the Inactive Funding Queue List type
  */
  const handleTypeChange = (event: any, updatedFromModal = false) => {
    if (listingType !== event.id) {
      setListingType(event.id);
      history.push(event.path);
    }
  };

  return (
    <Grid id="inactive-funding-rule-queue-grid" item xs={12}>
      <Grid className="fx-tab-border">
        <FxButtonGroup
          id="inactive-funding-queue-list-card-button-group"
          options={typeData}
          onClick={(event: any) => handleTypeChange(event)}
        />
      </Grid>

      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <FundingRulesList src={src} listingType={listingType}/>
    </Grid>
  );
});
