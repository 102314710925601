import React from 'react';
import { Grid, ListItemIcon } from '@mui/material';
import { FxLink } from '../../Action/FxLink';
import { getSelectedPath } from '../Passport';
import { LogoMenu } from '../../Utils/Logo';
import { useLocation } from 'react-router';

//Icons
import { ReactComponent as DashboardIcon } from '../../../assets/svg/leftmenu-dashboard.svg';
import { ReactComponent as LeadsIcon } from '../../../assets/svg/leads-icon.svg';
import { ReactComponent as CustomerIcon } from '../../../assets/svg/partner-customer-icon.svg';
import { ReactComponent as LogoRightIcon } from '../../../assets/svg/leftmenu-logo-right.svg';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { drawerCollapseTitle, swtichPortalSecondMenu } from '../../Utils/ThemeCheckerPartner';

/**
 * This Component handles the Partner drawer collapse state
 */
export const PartnerDrawerCollapse: React.FC<any> = React.memo((props) => {
  const location = useLocation();
  return (
    <Grid item container className={"fx-menu-passport-collapse"} justifyContent="center" alignItems='flex-start' >
      <Grid item xs={12}>
        <Grid item container>
          <Grid item className="fx-menu-passport-collapse-logo" xs={12}>
            <FxLink redirect="/leads-home"><LogoMenu DrawerCollapse /></FxLink>
          </Grid>
          {drawerCollapseTitle(props)}
          <Grid item xs={12} className="fx-menu-passport-collapse-expandicon" id="expand-menu" >
            <LogoRightIcon onClick={props.toggleDrawer} />
          </Grid>
          <Grid item xs={12} className="fx-menu-passport-collapse-menu">
            <ul>
              {checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" }) && <li className={getSelectedPath(location) === 1 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-dashboard" redirect="/leads-home" title="Dashboard" tooltip={true}><ListItemIcon> <DashboardIcon /></ListItemIcon></FxLink></li>}
              {checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "List" }) && <li className={getSelectedPath(location) === 2 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-leads" redirect="/leads" title="Leads" tooltip={true}><ListItemIcon> <LeadsIcon /></ListItemIcon></FxLink></li>}
              {checkPermission({ entity: "Partner Portal", name: "Customer", operation: "List" }) &&<li className={getSelectedPath(location) === 3 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-customers" redirect="/customers" title="Customers" tooltip={true}><ListItemIcon> <CustomerIcon /></ListItemIcon></FxLink></li>}
            </ul>
          </Grid>
          <Grid className='fx-sidenav-collapse' justifyContent='flex-end' alignItems='center' flexDirection='column' display='flex'>
             {swtichPortalSecondMenu(props)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})