import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButtonGroupMultiSelect } from '../../Action/FxButtonGroupMultiSelect';

// Component used to draw FxMultiSelectWeek fields

interface FxMultiSelectWeekProps {
    id: string;
    week?: boolean;
    month?: boolean;
    data: any;
    updateFormData: (value: any) => void;
    register?: UseFormRegisterReturn;
    control?: Control<any>;
    name: string;
    label: string;
}

export const FxMultiSelectWeek: React.FC<FxMultiSelectWeekProps> = (props) => {
    ({ props } = RegisterComponent(props));
    const [activeData, setMyActiveData] = useState([]);

    //options
    const updateWeekOptions = useMemo(() => {
        const buttonGroupWeekOptions: any = []
        buttonGroupWeekOptions.push(
            { id: 'M', value: 'MONDAY', label: 'M', selected: props?.data?.includes('MONDAY') },
            { id: 'TU', value: 'TUESDAY', label: 'T', selected: props?.data?.includes('TUESDAY') },
            { id: 'W', value: 'WEDNESDAY', label: 'W', selected: props?.data?.includes('WEDNESDAY') },
            { id: 'TH', value: 'THURSDAY', label: 'T', selected: props?.data?.includes('THURSDAY') },
            { id: 'F', value: 'FRIDAY', label: 'F', selected: props?.data?.includes('FRIDAY') },
            { id: 'SA', value: 'SATURDAY', label: 'S', selected: props?.data?.includes('SATURDAY') },
            { id: 'SU', value: 'SUNDAY', label: 'S', selected: props?.data?.includes('SUNDAY') }
        )
        return buttonGroupWeekOptions
    }, [props?.data])
    //options
    const updateYearOptions = useMemo(() => {
        const buttonGroupYearOptions: any = []
        buttonGroupYearOptions.push(
            { id: 'Jan', value: 'JANUARY', label: 'Jan', selected: props?.data?.includes('JANUARY') },
        { id: 'Feb', value: 'FEBRUARY', label: 'Feb', selected: props?.data?.includes('FEBRUARY') },
        { id: 'Mar', value: 'MARCH', label: 'Mar', selected: props?.data?.includes('MARCH') },
        { id: 'Apr', value: 'APRIL', label: 'Apr', selected: props?.data?.includes('APRIL') },
        { id: 'May', value: 'MAY', label: 'May', selected: props?.data?.includes('MAY') },
        { id: 'Jun', value: 'JUNE', label: 'Jun', selected: props?.data?.includes('JUNE') },
        { id: 'Jul', value: 'JULY', label: 'Jul', selected: props?.data?.includes('JULY') },
        { id: 'Aug', value: 'AUGUST', label: 'Aug', selected: props?.data?.includes('AUGUST') },
        { id: 'Sep', value: 'SEPTEMBER', label: 'Sep', selected: props?.data?.includes('SEPTEMBER') },
        { id: 'Oct', value: 'OCTOBER', label: 'Oct', selected: props?.data?.includes('OCTOBER') },
        { id: 'Nov', value: 'NOVEMBER', label: 'Nov', selected: props?.data?.includes('NOVEMBER') },
        { id: 'Dec', value: 'DECEMBER', label: 'Dec', selected: props?.data?.includes('DECEMBER') }
        )
        return buttonGroupYearOptions
    }, [props?.data])

    /**
     * handle button group click
     * @param item :data
     */
    const handleButtonGroupClick = (item: any) => {
        const selectedInfo: any = [...activeData];
        const index = selectedInfo.indexOf(item.value);
        if (index !== -1) {
            selectedInfo.splice(index, 1);
        } else {
            selectedInfo.push(item.value);
        }
        setMyActiveData(selectedInfo)
        if (props.updateFormData) {
            props.updateFormData(selectedInfo)
        }
    }
    /***
     * to get default data in edit
     */
    useEffect(() => {
        if (props?.data !== undefined) {
            setMyActiveData(props?.data)
        }
    }, [props?.data])

    return (
        <Grid container direction="row"  >
            {props?.week === true && <Grid item xs={12}>
                <FxButtonGroupMultiSelect id={props.id + "-week-button-group"} options={updateWeekOptions} onClick={handleButtonGroupClick} />
            </Grid>}
            {props?.month === true && <Grid item xs={12}>
                <FxButtonGroupMultiSelect id={props.id + "-month-button-group"} options={updateYearOptions} onClick={handleButtonGroupClick} />
            </Grid>}
        </Grid>
    )
}
