import * as React from "react";
import { Typography } from "@mui/material";
import moment from 'moment';
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxDateView.tsx", "Fx Date View initializing")

/**
 * Datetime View Component
 */
declare interface IFxDateTimeView {
  id?: string;
  value?: any;
  format?: any;
  className?: string;
  tooltip?: boolean;
}

// export datetime
export const FxDateTimeView: React.FC<IFxDateTimeView> = React.memo((props) => {
  let format = props.format;
  if (format && format.includes("MM:SS")) {
    //in format like MM/DD/YYYY HH:MM:SS
    //MM:SS MM belongs to month and SS to millisecond
    //but expects a min and second MM/DD/YYYY HH:mm:ss
    //is the correct one
    format = format.toString().replace("MM:SS", "mm:ss")
  }

  const dateText = !props.value || props.value === '-' || !moment(props.value).isValid() ? '-'
    : moment(props.value).format(format);

  return (
    <Typography
      id={props.id}
      className={props.className}
      title={props.tooltip ? dateText : ''}
    >
      {dateText}
    </Typography>
  );
});
