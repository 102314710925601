import React, { useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { Filter } from '../../Data/Filter';
import { getKey } from '../../../libs/utils/storageManager';
import { IFilterColumns, IDataGridProps, IFilterFields } from '../../../types/common.interfaces';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { useDispatch } from 'react-redux';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { truncateChars } from '../../Utils/CommonBaseClass';
import { FxInfoTooltip } from '../../Utils/FxInfoTooltip';

/**
 * This component handles the list of Transaction List
 */
export const InvestmentInstructionList: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const selectedAccount = getKey("selectedAccount");

        /**
        * useeffect to load title while loading page
        */
        useEffect(() => {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Investment Instructions', backButton: '/portfolio' }} });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        /**
        * useEffect to reset the filter while unmount the page
        */
        useEffect(() => () => {
            updateComponentParams(dispatch, 'investment-instruction-list-card-filter', { resetFilter: true });
        }, [dispatch]);

        /**
       * useMemo to get api results for investment history.
       */
        const src = useMemo(() => {
            if (selectedAccount) {
                return {
                    url: '/account/id/' + getKey("selectedAccount")?.id + '/portfolio/investmentHistory',
                    method: "POST",
                    data: {
                        "limit": 10,
                        "getTotalCount": false,
                        "pageNumber": 1,
                        "pageSize": 25,
                        "criteria": {
                            "filters": [
                            ]
                        },
                        "sortOptions": {
                            "sortBy": "lastUpdatedOn",
                            "sortOrder": "desc"
                        }
                    }
                }
            } else {
                return {
                    url: "",
                    method: "POST",
                    data: {}
                }
            }

        }, [selectedAccount]);

        /**
        * Columns for investment history list desktop view.
        */
        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "statusDate",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    "style": { "width": "12rem" }
                },

                {
                    "label": "instruction",
                    "enable": true,
                    "fieldName": "investmentProvider",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "40rem" }
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/custom",
                    "default": true
                },
                {
                    "label": "STATUS REASON",
                    "enable": true,
                    "fieldName": "statusReason",
                    "type": "fri://fieldtype/custom",
                    "default": true
                },
                {
                    "label": "AMOUNT",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "text-align": "end" }
                }
            ]
        }

        /**
        * Columns for investment history list mobile view.
        */
        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        /**
         * @param item : investment item
        * To Extract naration.
        */
        const extractNaration=(item:any)=>{
            let narration = ''
            const type = item?.type?.toUpperCase();

            const instructionMap = [
                {key:'INVEST',value:'Investment'},
                {key:'REBALANCE',value:'Rebalance'},
                {key:'REDEEM',value:'Redeem'}
            ];        

            const instructionType = instructionMap.find(x=>x.key === type);
            if (instructionType) {
                narration = truncateChars(`${instructionType.value} Instruction to ${item.investmentProvider}`, isSmallScreen ? 25 : 45) + ` (Txn Ref: ${item.id})`;
            }   

            return narration;
        }

        /**
         * @param data : investment list
        * Extract investment history items.
        */
        function investmentInstructionList(data: any) {
            let newData: any = [];
            let amount: any;
            // eslint-disable-next-line array-callback-return
            data?.map((item: any, i: number) => {
                newData[i] = { ...data[i] }

                newData[i].statusDate = item.statusDate;
                newData[i].status = <>
                    <FxInfoTooltip arrow title={item.statusReason} placement={'bottom-start'} className={'fx-status-tooltip'}>
                        <span><FxStatus value={item.status} fill={true} className="fx-status" /></span>
                    </FxInfoTooltip>
                </>
                amount = item.amount
                newData[i].investmentProvider = <><span>{extractNaration(item)} &nbsp;</span>
                    { item?.allocate && <Grid item container xs alignItems='center'>
                        <Grid item>
                            <Grid><Typography className='fx-home-ledger-list-portfolio-text'>Money Market ({item?.allocate?.managedMoneyMarket ? item?.allocate?.managedMoneyMarket : 0}%)</Typography></Grid>
                        </Grid>
                        <Grid item>
                            <Grid marginLeft={1}><Typography className='fx-home-ledger-list-portfolio-text'>Managed Treasuries ({item?.allocate?.managedTreasuries ? item.allocate.managedTreasuries : 0}%)</Typography></Grid>
                        </Grid>
                        <Grid item>
                            <Grid marginLeft={1}><Typography className='fx-home-ledger-list-portfolio-text'>Managed Income ({item?.allocate?.managedIncome ? item.allocate.managedIncome : 0}%)</Typography></Grid>
                        </Grid>
                    </Grid>}
                </>
                newData[i].statusReason = <span><Typography>{item.statusReason} </Typography></span>
                newData[i].amount = item?.amount ? <FxCurrencyView value={amount} justifyContent="flex-end" showPlus={item?.type?.toUpperCase() === 'INVEST' ? true:false} showMinus={item?.type?.toUpperCase() === 'REDEEM' ? true:false} prefix={"$"} /> : <Grid container justifyContent="flex-end"><Grid item>-</Grid></Grid>;


            });
            return newData;
        }


        /**
        * @param data : Transaction List
        * @returns : transformed data
        */
        const investmentInstructionGridTransformer = (data: any) => {
            let newData: any = [];
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container alignItems='center'>
                        <Grid item xs={10}>
                            <Grid item >
                                <Grid title={item?.investmentProvider}><Typography variant='h3'>{extractNaration(item)}&nbsp;</Typography></Grid>
                            </Grid>  
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container xs justifyContent='end'>
                                <Grid item xs>
                                    <Grid><FxCurrencyView value={item?.amount} justifyContent="flex-end" prefix={"$"} showPlus={item?.type?.toUpperCase() === 'INVEST' ? true:false} showMinus={item?.type?.toUpperCase() === 'REDEEM' ? true:false} /></Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item container xs={12} spacing={1} alignItems='center'>
                            <Grid item className="fx-grid-list-status">
                                <FxStatus id="invest-instruction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                            </Grid>
                            <Grid item>
                                <Grid title={item?.statusReason}><Typography >{item.statusReason}&nbsp;</Typography></Grid>
                            </Grid>                           
                        </Grid>
                    </Grid>
                </Grid>
            })
            return newData;
        }

        const datagridProps: IDataGridProps = {
            id: "investment-instruction-list-schedule",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
        };

        /**
       * Filter fields for investment history.
       */
        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "Date",
                    "toLabel": "To",
                    "fieldName": "statusDate",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                },
                {
                    "label": "Amount",
                    "fieldName": "amount",
                    "data": [],
                    "type": "fri://fieldtype/amountrange",
                },
                {
                    "label": "Reference Id",
                    "fieldName": "id",
                    "type": "fri://fieldtype/textfield",
                    "data": [],
                },
                {
                    "label": "Type",
                    "fieldName": "type",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { "label": "Invest", "value": "INVEST", "checked": false },
                        { "label": "Redeem", "value": "REDEEM", "checked": false },
                    ]
                },
            ]
        }

        /**
        * Method handles the redirection to details page
        * @param event : event parameter
        * @param data : row data
        */
        function redirectDetailsPage(event: any, data: any) {

        }

        return (

            <Grid id="investment-instruction-list-first-grid" item xs={12}>
                <Grid container xs={12} className="fx-layout-list" >
                    <FxCard id="investment-instruction-list-card" className="fx-theme-passport">
                        <FxCardHeader>
                            <Grid id="investment-instruction-list-header-first-grid" item container justifyContent="flex-end" xs={12}>
                                <Grid id="investment-instruction-list-card-header-filter-grid" item className="fx-inline-flex" >
                                    <Filter id="investment-instruction-list-card-filter" field={filterFields} dataGridProps={datagridProps} ></Filter>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="investment-instruction-list-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="investment-instruction-list-card-body-grid" item xs={12}>
                                {!isSmallScreen ? <DataGrid id="investment-instruction-list-schedule" tableClass="fx-table-passport" dataTransformer={investmentInstructionList} source={src} column={column} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                                    : <FxGridList id="investment-instruction-list-schedule" source={src}  dataTransformer={investmentInstructionGridTransformer} column={gridColumn} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />}
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="investment-instruction-list-card-footer">
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })
