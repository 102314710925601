/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Logger } from '../../../../libs/utils/logger';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { maskAccountNumber } from '../../../Utils/CommonBaseClass';
import { getKey } from '../../../../libs/utils/storageManager';
Logger.debug("SourceInfoCard.tsx", "SourceInfoCard initializing")
/**
 * This component handles the view of the send details
 */
export const SourceInfoCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;

        ({ context, props } = RegisterComponent(props));
        let sourceInfo: any = {};
        //   check if destination data is available
        if (context && context.data && context.data.body) {
            sourceInfo = { ...context.data.body };
            if(sourceInfo)
            {
                sourceInfo['accountInfo']=maskAccountNumber(sourceInfo['accountNumber'],false)
                sourceInfo['customerId']=getKey('id')

            }
        }

        const source_account_section = [
            {
                label: 'Account Number',
                value: 'accountInfo'
            }
        ];

        const source_account_section_two = [
            {
                label: 'Account ID',
                value: 'id'
            }
        ];

        const exteranl_account_section_card_data = [
            {
                label: 'Card Number',
                value: 'cardNumberLast4',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Brand',
                value: 'brand'
            },
            {
                label: 'Holder Name',
                value: 'holderName'
            },
        ];

        return (
            <>{sourceInfo['accountInfo'] ?
                <FxInfoCard id={"rec-details-card-destination-information-refund-section-info-card-component"} title={props.label} rightcolumn={source_account_section_two} leftcolumn={source_account_section} data={sourceInfo} /> :
                <FxInfoCard id={"rec-details-card-destination-information-refund-section-info-card-component"} title={props.label} leftcolumn={exteranl_account_section_card_data} data={sourceInfo} />
            }</>
        )
    })
