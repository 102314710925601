/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { Logger } from "../../../../libs/utils/logger";
import FxCard from "../../../Container/FxCard";
import FxCardHeader from "../../../Container/FxCardHeader";
import FxCardBody from "../../../Container/FxCardBody";
import { FxTextEdit } from "../../../Input";
import FxCardFooter from "../../../Container/FxCardFooter";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as RightUpArrow } from "../../../../assets/svg/right-up-arrow-with-circle.svg";
import { ReactComponent as LeftDownArrow } from "../../../../assets/svg/left-down-arrow-with-circle.svg";
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { FxInfoCard } from "../../Cards/FxInfoCard";
import HttpClient from "../../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../../libs/utils/utils";
import FxSnackBar from "../../../Utils/fx-snack-bar";
import { useHistory } from "react-router";

const httpClient = HttpClient.getClient();


Logger.debug("ApprovalConfirmation.tsx", "Approval Confirmation initializing");
/**
 * This component handles the Approval Confirmation 
 */
export const ApprovalConfirmation: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, control, getValues } = useForm();
  const history = useHistory();
  const [transactionInfo, setTransactionInfo] = useState();
  const [isloadingReject, setIsloadingReject] = React.useState(false);
  const [isloadingApprove, setIsloadingApprove] = React.useState(false);

  useEffect(() => {
    handleTransactionInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * Async function handles the approving the approval
   */
  async function onSubmit(data: any) {
    let req: any = {};

    setIsloadingApprove(true);

    if (data.comment) {
      req = {
        comment: data.comment
      }
    }

    let status: any;
    status = await approveApproval(req);
    setIsloadingApprove(false);
    status = processAPIResponse(status);
    if (status.status) {
      props.close();
      props.setApprovalStatus('APPROVED');
      props.openDetailModal();
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }

  }

  /**
   * Handle Reject response
   */
  async function handleReject(event: any): Promise<void> {
    let req: any = {};
    setIsloadingReject(true);
    if(getValues('comment')) {
      req = {
        comment: getValues('comment'),
      }
    }

    let status: any;
    status = await rejectApproval(req);
    status = processAPIResponse(status);
    setIsloadingReject(false);
    if (status.status) {
      props.close();
      props.setApprovalStatus('REJECTED');
      props.openDetailModal();
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
   * Api call for rejecting an approval
   */
  async function rejectApproval(req: any) {
    try {
      const data = await httpClient.post(getCustomerUrl('/transaction/id/' + props.data.transactionId + '/reject'), req);
      return data;
    } catch (err) {
      return err;
    }
  }

  /**
  * Api call for approval
  */
  async function approveApproval(req: any) {
    try {
      const data = await httpClient.post(getCustomerUrl('/transaction/id/' + props.data.transactionId + '/approve'), req);
      return data;
    } catch (err) {
      return err;
    }
  }

  /**
  * Api call for get transaction info
  */
  async function getTransactionInfo() {
    try {
      const data = await httpClient.get(getCustomerUrl('/transaction/id/' + props.data.transactionId))
      return data;
    } catch (err) {
      return err;
    }
  }

  /**
  * Handle transaction info response
  */
  async function handleTransactionInfo() {
    const status = await getTransactionInfo();
    if (status.data) {
      const trnsactionInfo = {...status.data, createdBy: status.data?.createdBy?.username, memo: (status.data?.processingDetail?.memo || '')};
      setTransactionInfo(trnsactionInfo);
    }
  }

  const processing_details_section: any = [
    {
      label: "Via",
      value: "method",
    },
    {
      label: "Purpose",
      value: "purpose",
    },
    {
      label: "Memo",
      value: "memo",
    },
    {
      label: "Created On",
      value: "createdOn",
      format:"MMM DD, YYYY",
      type: "fri://fieldtype/date",
    },
    {
      label: "Created By",
      value: "createdBy"     
    },
  ];

  /**
  * to redirect to transaction detail
  */
  function handleTransactionDetail() {
    history.push(`/transactions/send/${props.data.transactionId}`, {backUrl:'/pendingApprovals'});
  }

  return (
    <div className="modal-content">
      <div className="fx-policy-approval-popup modal-body">
        {
          <form onSubmit={handleSubmit(onSubmit)}>
            <FxCard className="fx-policy-confirmation-data-card">
              <FxCardHeader
                id="approval-confirmation-header"
                className="fx-card-header"
              >
                  <Grid className="fx-modal-close-icon">
                    <IconButton
                      onClick={props.close}
                      id="approval-confirmation-modal-close-icon"
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
              </FxCardHeader>
              <FxCardBody
                id="approval-confirmation-modal-body"
                className="fx-info-card"
              >
                <Grid
                  container
                  direction="row"
                  className="fx-modal-form flex column"
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    xs={12}
                    className={"fx-card-circle"}
                  >
                    {props.data.entity === 'COLLECT' ? <LeftDownArrow /> : <RightUpArrow />}
                  </Grid>
                  <Grid container justifyContent="center" item xs={12}>
                    <Typography
                      id="approval-confirmation-modal"
                      className="fx-approval-confirmation-title"
                    >
                      Send Money Request
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    item
                    xs={12}
                    className="fx-approval-confirmation-description"
                  >
                    <Typography>
                      You cannot Undo this action
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} className="fx-approval-confirmation-popup-grid2">
                  <Grid item className="fx-digest-grid">
                    <FxCurrencyView
                      className="balance-amount"
                      id="approval-confirmation-currencyview"
                      value={props.data.amount?.props.value}
                      prefix={"$"}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <Typography
                      id="reference-title-policy-confirmation-modal"
                      variant="subtitle1"
                      className="fx-approval-confirmation-reference-number"
                      onClick={handleTransactionDetail}
                    >
                      REF #{props.data.transactionId}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className="fx-transaction-summery-details"
                >
                  <Grid item xs={12}>
                    <div className="schedule-detail-summary-card-divider"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      id="approval-confirmation-card#processing-header"
                      className="fx-transaction-summery-details-title"
                    >
                      Transaction Summary
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    key="schedule-processing-info-container"
                    direction="row"
                    xs={12}
                  >
                    <FxInfoCard
                      notiltle={true}
                      id={"approval-confirmation-info-card-processing-details"}
                      column={processing_details_section}
                      data={transactionInfo}
                    ></FxInfoCard>
                  </Grid>
                  <Grid
                    item
                    key="schedule-processing-info-container"
                    direction="row"
                    xs={12}
                  >
                    <FxTextEdit
                      register={{ ...register("comment") }}
                      control={control}
                      className={"fx-input-edit"}
                      rules={{ required: false }}
                      id={"approval-comment-textbox"}
                      label="Comment"
                      name={"comment"}
                    />

                    <Grid container xs={12} className={'fx-policy-confirmation-padding'}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </FxCardBody>
              <FxCardFooter
                id="policy-approval-modal-Footer"
                className="fx-footer"
              >
                <Grid
                  container
                  spacing={2}
                  className="fx-modal-footer"
                >
                  <Grid item xs={6} container>
                    <FxButton
                      disableRipple={false}
                      variant="contained"
                      id={"approval-reject-button"}
                      className={"fx-button fx-button-action fx-svg-theme fx-button-delete fx-buttom-full-width fx-button-action-reject"}
                      onClick={handleReject}
                    >                      
                      {isloadingReject ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: 'red',
                          }}
                        />
                      ) : (
                        'Reject'
                      )}
                    </FxButton>
                  </Grid>
                  <Grid item xs={6} container>
                    <FxButton
                      disableRipple={false}
                      className="fx-button fx-buttom-full-width fx-button-theme"
                      id={"approval-accept-button"}
                      type="submit"
                    >
                      {isloadingApprove ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: 'white',
                          }}
                        />
                      ) : (
                        'Approve'
                      )}                      
                    </FxButton>
                  </Grid>
                </Grid>
              </FxCardFooter>
            </FxCard>
          </form>
        }
      </div>
    </div>
  );
});
