import React from 'react';
import { Typography } from '@mui/material';
import { FxSecondary } from '../../Action/FxSecondary';
import { Logger } from '../../../libs/utils/logger';
import { truncateChars } from '../../Utils/CommonBaseClass';

Logger.debug("FxTextView.tsx", "Fx Text View initializing")

/**
 * Component used to view text values
 */
export enum TextType {
    VALUE,
    STATIC,
    BOTH,
}
interface IHyperLink {
    url: any;
    target?: any;
    type?: TextType;
    title: string;
    seperator?: string;
}
interface IModal {
    id: any;
    type?: TextType;
    title: string;
    seperator?: string;
}
interface ITextView {
    value?: any;
    className?: any;
    variant?: any;
    hyperLink?: IHyperLink;
    modal?: IModal;
    context?: any;
    staticWord?: any;
    id?: any;
    tooltip?: any;
    toolTipValue?: any;
    truncateChars?: number;
    valueTransformer?:(value:any)=>any;
}
const FxTextView: React.FC<ITextView> = (props) => {
    return (
        <Typography title={props.tooltip && props.toolTipValue ? (props.toolTipValue !== "undefined" ? props.toolTipValue : "") : props.tooltip ? (props.valueTransformer ? props.valueTransformer(props.value) : props.value) : ''} variant={props.variant} id={props.id ? props.id : ''} className={props.className ? props.className : 'fx-newline'}><FxSecondary value={props.valueTransformer ? props.valueTransformer(getValue(props.value, props.truncateChars)) : getValue(props.value, props.truncateChars)} hyperLink={props.hyperLink} modal={props.modal} staticWord={props.staticWord}></FxSecondary></Typography>
    )

}
export default FxTextView;
/**
 * Method used to get values and set value
 * @param val :value
 * @returns :value
 */
function getValue(val: any, chars: any ) {
    let value = val;
    try {
        if (typeof value != 'string' && typeof value != 'number') {
            value = ' ';
        }
    }
    catch (e) {
        Logger.error("FxTextView.tsx", "error", e);
    }
    return !!chars ? truncateChars(value, chars) : value;
}

