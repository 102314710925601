import React, { useEffect } from 'react';
import { RadioGroup, Radio, FormControlLabel, FormLabel, FormGroup } from '@mui/material';
import { UseFormSetValue, UseFormRegisterReturn, Controller, ControllerProps } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxRadioEdit.tsx", "Fx Radio Edit initializing")

/**
 * Component used to view and edit radio button
 */

type TFieldValues = Record<string, string>;

interface FxRadioProps extends Partial<ControllerProps> {
    id?: string;
    value?: any;
    setValue?: UseFormSetValue<TFieldValues>;
    register?: UseFormRegisterReturn;
    disabled?: boolean;
    row?: string | boolean;
    label?: string;
    data: any;
    onChange?: (event:any, data?: any) =>void;
    name: string;
    className?: string;
}

const FxRadioEdit: React.FC<FxRadioProps> =
  (props) => {

    /**
     * use effect to set default value
     */
    useEffect(()=>{
      if (props.setValue) {
        props.setValue(props.name, props?.defaultValue)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.defaultValue])


    function handleChange(event: any) {
      if (props.setValue) {
        props.setValue(props.name, event.target.value)
      }
      if (props.onChange) {
        props.onChange(event);
      }
    }

    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules ? props.rules : { required: false }}
        render={({ field: { onChange, value } }) => (
          <FormGroup row={props.row === 'vertical' ? false : true}><FormLabel id="demo-radio-buttons-group-label">{props.label}</FormLabel>
            <RadioGroup aria-label={props.name} name={props.name} id={props.id} className={props?.className || 'fx-group-radio'} value={props.value ? props.value : (value ? value : (props.defaultValue ? props.defaultValue : ''))} onChange={handleChange} row={props.row === false ? false : true} >
              {props.data?.map((option: any, i: number) => {
                return <FormControlLabel disabled={props.disabled ?? option.disabled ?? false} name={props.name} control={<Radio id={props.id + '-' + option.value} />} label={option.label} id={'radio-label-' + props.id} value={option.value} />
              })}
            </RadioGroup>
          </FormGroup>
        )}
      />
    )
  }
export default FxRadioEdit;




