/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxImage from '../../Data/FxImage';
import { useDispatch, useSelector } from 'react-redux';
import { FxButton } from '../../Action/FxButton';
import { VirtualCardLoadingImage } from './VirtualCardLoadingImage';

Logger.debug("ViewVirtualCardImage.tsx", "view virtual card Image initializing")

/**
 * This component handles the list of the linked documents
 */
export const ViewVirtualCardImage: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch()

        const schedule_info = props.data;

        const [viewCard, setViewCard] = useState(false);

        /**
        * Function to fatch data from api
        */
         function fetchAPIData(scheduleData: any) {
            const virtualCard= scheduleData?.processingDetail?.virtualCard;

            if (virtualCard && virtualCard?.url) {
                const url = virtualCard?.url?.replace("v1/", "")
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
                dispatch({ type: "DATA_API_REQUESTED", payload: { id: props.id, source: { url: url+'/image', method: 'GET', baseUrl: true } } });
            }
        }

         /**
        * Function to be called before loading the component
        */
         useEffect(() => {
            if(schedule_info){
                fetchAPIData(schedule_info)
            }
        }, [])

        /**
        * Function to be called before loading the component
        */
           useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, [])

        /**
        * to get virtual Card
        */
           const virtualCardImage = useSelector((state: any) => {
            const virtualCardData = state.data[props.id];
            if (virtualCardData?.data?.body) {
                return virtualCardData?.data?.body
            }
        });

        return (
            <Grid container direction="row" justifyContent='center' spacing={2}>
                <Grid item xs={12}>
                 </Grid>
                {viewCard && <Grid className='fx-virtual-card-view' item xs={12}>
                   { virtualCardImage?.image ?
                    <FxImage id="virtual-card-image" src={virtualCardImage?.image ? 'data:image/png;base64,'+virtualCardImage?.image :''} /> : 
                    <VirtualCardLoadingImage/>}
                 </Grid>
                }

            <Grid item xs={12}>
                    <FxButton id={"-details-card-cancel-button"} variant="contained" className="fx-button  fx-button-action " onClick={()=>{setViewCard(!viewCard)}}>{viewCard?'Hide Card':'View Card'}</FxButton>
                 </Grid>
            </Grid>
        )
    });
