/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { DataGrid } from '../../Data/DataGrid';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download.svg';
import { getImageDownload } from './DownloadImage';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useMediaQuery } from 'react-responsive';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxGridList } from '../../Data/FxGridList';


Logger.debug("ViewLinkedDocuments.tsx", "view documents initializing")

/**
 * Method handles the transformation of the linked documents data
 * @param data : linked documents data
 * @returns : transformed data
 */
const transformLinkedDocuments = (data: any) => (data?.map((item: any) => ({
    ...item,
    documentName: item.document.name,
    documentType: item.document.type,
    status: item.status,
})) || []);

/**
 * This component handles the list of the linked documents
 */
export const ViewLinkedDocuments: React.FC<any> = React.memo(
    (props) => {
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        let fileName: any;
        let fileType: any;
        ({ props } = RegisterComponent(props));
        let ApiData: any;
        let linkedDocuments: any = [];
        let mobileDoc:any;

        if (props?.data) {
            ApiData = props.data;
            if (ApiData?.externalAccount?.linkedDocument) {
                linkedDocuments = transformLinkedDocuments(ApiData.externalAccount.linkedDocument);
                mobileDoc= ApiData.externalAccount.linkedDocument
            }
            if (ApiData?.linkedDocument) {
                linkedDocuments = transformLinkedDocuments(ApiData.linkedDocument);
                mobileDoc=ApiData.linkedDocument
            }
        }


        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Type",
                    "enable": true,
                    "fieldName": "documentType",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Name",
                    "enable": true,
                    "fieldName": "documentName",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Status",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true
                }, {
                    "enable": true,
                    "fieldName": "icon",
                    "icon": <DownloadIcon />,
                    "type": "fri://fieldtype/image",
                    "onIconClick":true,
                    "className":"fx-row-icon fx-cursor-pointer",
                    "default": true
                }

            ]
        };

        /**
         * Async function handles the api call for downloading the file
         * @param event : event parameter
         * @param data : row data
         */
        async function downloadFile(event: any, data: any) {

            const httpClientBlob = HttpClient.newClientBlob();
            const downloadObj = data;
            
            if (downloadObj) {
                if (downloadObj.document && downloadObj.document.id && downloadObj.document.name) {
                    fileName = downloadObj.document.name?.split('.')[0];
                    fileType = downloadObj.document.name?.split('.')[1];
                    await httpClientBlob.get('document/id/' + downloadObj.document.id).then((response: any) => {
                        if (response.data) {
                            getImageDownload(response.data, fileName, "." + fileType)
                        }

                    })

                }
            }
        }
        /**
         * Layout for Document Id
         * @param id 
         * @returns 
         */
        const createDocumentId = (id: string) => (
            <Grid item container xs={12}>
            <Grid item>
            <Typography variant='h4'>ID : {id}</Typography>
            </Grid>
            </Grid>
            );
        /**
        * Layout for Document Type and Status
        * @param id 
        * @returns 
        */
        const createDocumentTypeAndStatus = (type: string, status: any) => (
            <Grid item container xs={12}>
                <Grid item>
                    <Typography variant='h4'>{type}</Typography>
                </Grid>
                <Grid item>
                    <FxStatus id="recurring-history-status" value={status} fill={true} className="fx-status" />
                </Grid>
            </Grid>
        );
        /**
         * Layout for Document download button
         * @param id 
         * @returns 
         */
        const createDocumentNameAndDownloadButton = (name: string, item: any) => (
            <> <Grid item container xs={11}>
                <Grid item container xs={10}>
                    <Typography variant='h4'> {name}</Typography>
                </Grid>
            </Grid>
                <Grid item container xs={1} alignItems='center'>
                    <Grid >
                        <IconButton onClick={(e: any) => downloadFile(e, item)}>
                            <DownloadIcon />
                        </IconButton>
                    </Grid>
                </Grid></>
        );
        /**
         * Layout for Divider
         * @param id 
         * @returns 
         */
        const createDivider = () => (
            <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
                <Grid item xs>
                    <Divider />
                </Grid>
            </Grid>
        );
        /**
         * @param data : View document List for mobile
         * @returns : transformed data
         */
        const docListGridTransformerMobile = (data: any) => {
            const transformedData = data?.map((item: any) => ({
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        <Grid item container xs={11} alignItems='center'>
                            {createDocumentId(item.document?.id)}
                            {createDocumentTypeAndStatus(item.document?.type, item?.status)}
                            {createDocumentNameAndDownloadButton(item.document?.name, item)}
                        </Grid>
                    </Grid>
                ),
                divider: createDivider(),
            }));

            return transformedData || [];
        };
       
          const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
              {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
              },
              {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
              }
        
            ]
          }

        return (
            <Grid id="view-linked-documents-Grid" item xs={12}>
                <Grid id="view-linked-documents-Grid-b" item xs={12}>
                    <form>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="view-linked-header">
                                <Grid container item direction="row" justifyContent="space-between">
                                    <Typography id="title-view-linked-documents" variant="h4" >Documents </Typography>
                                </Grid>

                            </FxCardHeader>
                            <FxCardBody id="view-linked-document-list-Card-Body" className="fx-card-body-no-border-no-shadow">
                                <Grid id="all-account-document-list-DataGrids" item xs={12}>
                                    {!isSmallScreen ?<DataGrid id="datagrid-view-documents-view-linked-list" tableClass="fx-table-passport" data={linkedDocuments} column={column} pagination={false} pageNumber={1} pageSize={25} resultPerPage={25} iconClick={downloadFile} />
                                    :<FxGridList id="datagrid-view-documents-view-linked-list"   data={docListGridTransformerMobile(mobileDoc)} column={gridColumn} /> 
                                    }
                                </Grid>
                            </FxCardBody>
                        </FxCard>


                    </form>
                </Grid>
            </Grid>
        )
    });
