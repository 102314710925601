import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import SetPassword from './SetPassword';
import { useParams } from 'react-router-dom';
/**
 * Component: VerifySetPassword
 * Usage: renders the set password component and pass token
 */


Logger.debug("VerifySetPassword.tsx", "verify set password initializing")

export const VerifySetPassword: React.FC<any> = React.memo(() => {
        const params = useParams<any>();
        const token = params.token;
        //verify user token API
        const pass_source = {
            url: "/user/verifyUserToken",
            method: "POST",
            data: {
                userType: 'CUSTOMER',
                token: token
            }
        }
        

        return (
            <Grid id="set-password-Grid" item xs={12}>
                <SetPassword id="set-password" source={pass_source} token={token}></SetPassword>
            </Grid >
        )
    })
