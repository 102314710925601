/* eslint-disable react-hooks/exhaustive-deps */
import { AccordionDetails } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { Accordion } from '@mui/material';
import { Divider } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { AUTH_STRINGS } from '../../../constants/strings';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import countries from '../../../libs/utils/country/country.json';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { ISource } from '../../../types/common.interfaces';
import { FxButton } from '../../Action/FxButton';
import { FxSwitch } from '../../Action/FxSwitch';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import FxVerifyPPI from '../../Data/FxVerifyPPI';
import { FxAutoComplete } from '../../Input/FxAutoComplete/FxAutoComplete';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import FxRadioEdit from '../../Input/FxRadio/FxRadioEdit';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import { FxSwiftCode } from '../../Input/FxSwiftCode/FxSwiftCode';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { generateAVSDetails } from './ContactGlobalFunctions';
import {
    clean,
    convertToPhoneRequestFormat,
    handleAddressChange,
    handleCvvChange,
    handleKeyDownChange,
    handleZipChange,
    redirectForm,
    renderError,
    renderHolderNameError,
    renderNameError,
    renderPhoneError,
    renderStateError,
    setPhoneValidationOptions,
    setRoutingNumberValidation,
    setWireRoutingNumberValidation,
    setZipValidationOptions,
    updateComponentParams,
    renderAddressLine1Error,
    renderAddressLine2Error,
    renderCityNameError,
    renderCardNumberError,
    setCVVValidation,
    setCardNumberValidation,
    setCityNameValidation,
    setAddressLine2Validation,
    setAddressLine1Validation,
    findAddressValidations,
    renderEAAddressLine1Error,
    renderEAAddressLine2Error,
    cleanWithEmptyValue
} from '../../Utils/CommonBaseClass';
import { ReactComponent as DownArrow } from '../../../assets/svg/down-black-triangle-arrow.svg';
import { ReactComponent as UpArrow } from '../../../assets/svg/up-black-triangle-arrow.svg';
import currencies from '../../Utils/currencies.json';
import FxSnackBar from '../../Utils/fx-snack-bar';
import usa_state from '../../Utils/usa_states.json';
import { FxCardNumberEdit } from '../../Input/FxCardNumber/FxCardNumberEdit';
import { FxDateEdit } from '../../Input';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { AVSDetailsForm } from '../Schedules/AVSDetailsForm';
const httpClient = HttpClient.getClient();
interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("CreateExternalAccount.tsx", "create external account new initializing");

// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: CreateExternalAccount
 * Usage: create external account
 */
export const CreateContact: React.FC<any> = React.memo((props) => {
        let context: any;
        let contactId: any = '';
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, resetField, getValues, watch } = useForm();
        const params = useParams<any>();
        const createType = params.type;
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
        const currencyFixedOptions = [{value:'USD', label:'USD'}]
        const [saveEnabled, setSaveEnabled] = useState<boolean | undefined>(true);
        const [avsDetailOpen, setAvsDetailOpen] = useState<boolean>(false);
        //New States added for International Wire form
        const [routingCodeDetails, setRoutingCodeDetails] = React.useState<any>();
        // States ended for International Wire form
        const mandateFieldForCardTransaction = getKey('mandateFieldForCardTransaction') || '';
        const isEnableBillPaymentVisible = getKey('isEnableBillPaymentVisible') === 'true';

        /**
          * reset the component
          * */

        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Contact', backButton: '/payees' } } });
            updateComponentParams(dispatch, props?.id, {passportPaymentId:{ enabled: false, verified: false }})
            let  selectedPaymentMthod:any = '';
            if(isEnableBillPaymentVisible && businessCustomer){
                selectedPaymentMthod =['MONEYGRAM']
                setValue('contactMethod',['MONEYGRAM'])
            }
            updateComponentParams(dispatch, props?.id, { type: 'BUSINESS', recipientName: '', holderName: '', firstName: '', lastName: '', selectedPaymentMthod: selectedPaymentMthod })
        }, [])

        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })

        const history = useHistory();
        const submitButton = "Add Contact"

        // Account type data
        const accountTypeData = [
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' }
        ];
        // account purpose data
        const accountPurposeData = [
            { label: 'Consumer', value: 'CONSUMER' },
            { label: 'Corporate', value: 'CORPORATE' }
        ];


        // API request for bank list
        const bank_src: ISource = {
            url: "list/bank",
            method: "POST",
            expiry: 300,
            baseUrl: true,
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters": [
                    ]
                }
            }
        };


        /**
         *  Handles the Address Verification section toggle
         */
         const toggleAvsDetail = () => {
           setAvsDetailOpen(prevState => !prevState);
         }


        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberIWPError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_IWP_INVALID_LENGTH;
            }
            return err.message;
        };
        const checkBoxError = (err: InputErrType): string => {
            return 'Field is Required';
        };

        /**
        * Error Handling
        * Display Label
        * @param err
        * @returns
        */
        const renderLabelError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
            }
            else
                if (err.type === 'minLength') {
                    return AUTH_STRINGS.ERRORS.MIN_LENGTH_LABEL;
                }

            return err.message;
        };

        // routing number validation error messages
        const renderRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        //wire routing  number validation error messages
        const renderWireRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        // account number validation constant
        const setAccountNumberValidation = {
            required: true,
            maxLength: 40
        }
        // account number validation for IWP constant
        const setAccountNumberValidationIWP = {
            required: true,
            maxLength: 34
        }
        // account display label validation
        const displayLabelValidation = {
            required: true,
            maxLength: 40,
            minLength: 3
        }
        // name validation
        const nameValidation = {
            required: true,
            maxLength: 45
        }

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            const link = redirectForm(createType)
            history.push(link)

        };

        /**
         * Method to handle passport payment change
         * @param event :event type
         */
        const handlePassportPaymentSwitchChange = (event: any) => {
            updateComponentParams(dispatch, props?.id, { passportPaymentId:{...props?.passportPaymentId, enabled: event }})
            if(!event){
                setValue('contactMethod', getValues('contactMethod'))
            }
        }

        /**
         * Method to transform routing number data as dropdown options
         * @param data : routing number data
         */
        function routingNumberTransformer(data: any) {
            try {
               return data?.map((item: any) => {
                    return { label: item.routingNumber + ' (' + item.name + ')', value: item.routingNumber };
                })
            }
            catch (e) {
                console.log('err', e)
            }

        }
        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            updateComponentParams(dispatch, props?.id, { 'isloading': true });
            let req: any = {};
            let status: any;
            if (contactType === 'WITHIN') {
                req = withinPassportRequest(data)
            }
            else {
                req = outSidePassportRequest(data)
            }

            let successMessage = 'Contact Created Successfully!';
            if (context?.data?.body?.id) {

                successMessage = 'Contact Updated Successfully!';
            } else {

                status = await createContact(clean(req));
                if (status?.headers?.url) {
                    contactId = (status.headers.url).split('/').pop();
                }
            }

            status = processAPIResponse(status)
            let redirectPage: any;
            if (status.status) {
                redirectPage = redirectBackToPage(createType);
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(redirectPage);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            updateComponentParams(dispatch, props?.id, { 'isloading': false });
        }
        /***
         * Redirect page
         */
        function redirectBackToPage(createType: any) {
            let redirectPage = '';
            const object: any = {
                'send-payee-ach': '/transaction/send/contact/add/ach/',
                'send-payee-wire': '/transaction/send/contact/add/wire/',
                'send-payee-check': '/transaction/send/contact/add/check/',
            }

            redirectPage = object[createType] !== undefined ? object[createType] + contactId : '/thanks/transactions/create-payee/' + contactId
            return redirectPage;
        }
        /**
        * Method to get the Within the passport request
        * @param data
        * @returns
        */
        function withinPassportRequest(data: any) {
            return {
                code: data.contactCode,
                name: data.contactLabel
            };
        }

        /**
        * Method to get the Outside passport request
        * @param data
        * @returns
        */
        function outSidePassportRequest(data: any) {
            let request: any = {};
            const object: any = {};
            object.name = data.contactLabel;
            let holderAddress = {
                "addressLine1": data?.addressLine1,
                "addressLine2": data?.addressLine2,
                "city": data?.city,
                "state": data?.state,
                "zip": data?.zip
            }
            cleanWithEmptyValue(holderAddress);
            if (props.passportPaymentId.enabled) {
                object.ppi = data.ppi + '@ppi';
            } else{
                let externalAccountRequest: any = {
                    accountNumber: data?.accountNumber,
                    routingNumber: data?.routingNumber,
                    holderType: data?.accountPurpose,
                    type: data?.accountType,
                    holderName: data?.holderName,
                    holderPhone:data?.holderPhone&&convertToPhoneRequestFormat(data?.holderPhone)
                }
                if (holderAddress && Object.keys(holderAddress).length > 0 && Object.values(holderAddress).some(value => value !== null)) {
                    externalAccountRequest['holderAddress'] = holderAddress;
                }
                clean(externalAccountRequest);
//Object to handle international wire form request
                const internationalExternalAccountRequest: any = {
                    holderType: data.internationalHolderType,
                    type: data.internationalAccountType,
                    swiftCode: data.swiftCode,
                    ...(routingCodeDetails?.internationalCodeLabel && data.internationalRoutingCode && {internationalRoutingCode: data.internationalRoutingCode}),
                    holderName: data.internationalAccountHolderName,
                    accountNumber: data.internationalAccountNumber,
                    acceptedCurrency: data?.acceptedCurrency?.map((ele:any)=>ele.value).filter((currency: any) => currency !== "Select All") || currencyFixedOptions.map((ele:any)=>ele.value),
                    "holderAddress": {
                      state: data.internationalCountry !== "US" ? data?.nonUnitedState : data?.unitedState,
                      country: data.internationalCountry,
                      city: data.internationalCity,
                      zip: data.internationalZip,
                      addressLine1: data.internationalAddressLine1,
                      addressLine2: data.internationalAddressLine2,
                    }
                }
                if(internationalExternalAccountRequest?.holderAddress?.state === ''){
                     delete internationalExternalAccountRequest.holderAddress.state;
                }
                if(internationalExternalAccountRequest?.holderAddress?.zip === ''){
                     delete internationalExternalAccountRequest.holderAddress.zip;
                }
                if (data.wireRoutingNumber && data.wireRoutingNumber !== '') {
                    externalAccountRequest.wireRoutingNumber = data.wireRoutingNumber;
                }
                if (data.eaPurpose && data.eaPurpose !== '') {
                    externalAccountRequest.purpose = data.eaPurpose;
                }
                if (data.internationalPurpose && data.internationalPurpose !== '') {
                    internationalExternalAccountRequest.purpose = data.internationalPurpose;
                }
                if (data.accountValidation ) {
                    const validationObject:any={}
                    const preNoteObject:any={}
                    if(data.accountValidation === "PRENOTE"){
                        preNoteObject.prenote= "ALWAYS";
                    }
                    else{
                    validationObject.ews=true;
                    }
                    externalAccountRequest.validateAccount=[];
                    externalAccountRequest.validateAccount.push(validationObject);
                    externalAccountRequest['prenote'] = preNoteObject.prenote
                }
                data.contactMethod.forEach((element: any, i: any, row: any) => {

                    if (element === 'ACH' || element === 'WIRE') {
                        object.externalAccount = [externalAccountRequest];
                    }
                    else if(element === 'VIRTUAL_CARD'){
                        object.email = data.contactEmail;
                    }
                    else if(element === 'INTERNATIONAL_WIRE'){
                        object.internationalExternalAccount = [internationalExternalAccountRequest];
                    }
                    else if (element === 'CHECK') {
                        object.mailingAddress = [{
                            addressLine1: data.addressLine1,
                            addressLine2: data.addressLine2,
                            city: data.city,
                            state: data.state,
                            zip: data.zip,
                            countryCode: data['recipientPhone-select'],
                            phone: data.recipientPhone ? convertToPhoneRequestFormat(data.recipientPhone) : '',
                            name: data.recipientName,
                        }]

                        if (i + 1 === row.length) {

                            return object;
                        }
                    } else if(element === 'CARD') {
                        clearErrors("cardNumber");
                        let cardObj: any;
                        if (data['cardNumber']) {
                            cardObj = JSON.parse(data['cardNumber'])
                            if (!cardObj.success) {
                                setError("cardNumber", {
                                    type: "manual",
                                    message: cardObj.message,
                                });
                            }
                        }
                        const expiryDate = new Date(data.expiryDate);
                        const month = expiryDate.getMonth() + 1;
                        const year = expiryDate.getFullYear();
                        const holderName = data.cardFirstName && data.cardFirstName ? `${data.cardFirstName} ${data.cardLastName}` : undefined;
                        object.card = [{
                            holderName,
                            cardNumber: cardObj?.value,
                            brand: mandateFieldForCardTransaction.includes('brand') ? cardObj?.type : undefined,
                            cvv: data.cvv || undefined,
                            expiryMonth: month,
                            expiryYear: year,

                        }]

                       // Prepare the billing address details if they exist
                        if(data.cardBillingAddressLine1 || data.cardBillingAddressLine2 || data.cardBillingCity || data.cardBillingState || data.cardBillingZip) {
                            object.card[0]['billingAddress'] = {
                                addressLine1: data.cardBillingAddressLine1 || undefined,
                                addressLine2: data.cardBillingAddressLine2 || undefined,
                                city: data.cardBillingCity || undefined,
                                state: data.cardBillingState || undefined,
                                zip: data.cardBillingZip || undefined,
                                country: data.cardBillingCountry || undefined
                            }
                        }

                       // Prepare the card holder details if they exist
                        if (data.cardBillingPhone || data.cardBillingEmail || data.cardFirstName || data.cardLastName) {
                          let formattedPhoneNumber;
                          if (data.cardBillingPhone) {
                            formattedPhoneNumber = convertToPhoneRequestFormat(data.cardBillingPhone);
                          }

                          object.card[0]['cardHolder'] = {
                            phone: formattedPhoneNumber || undefined,
                            email: data.cardBillingEmail || undefined,
                            firstName: data.cardFirstName || undefined,
                            lastName: data.cardLastName || undefined,
                            name: holderName
                          }
                        }

                        // Prepare the AVS (Address Verification System) details
                        const avs = generateAVSDetails(data);
                        const avsDetails = clean(avs);

                        // Add AVS details to the card object if they exist
                        if(Object.keys(avsDetails).length > 0){
                            object.card[0]['avs'] = avsDetails;
                        }
                    }
                })

                object.contactType = data.contactType ? data.contactType : 'BUSINESS';
                if (object.contactType !== 'BUSINESS') {
                    object.firstName = data.firstName;
                    object.lastName = data.lastName;
                }
                else {
                    object.legalName = data.legalName;
                }
            }

            request = object;

            return request

        }

        /**
         * Method to call api  for create externalAccount
         * @param payloadData : request payload
         */
        async function createContact(payloadData: any) {
            try {
                const url =  '/contact'
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateContact.tsx", "error", err);
                return err;
            }
        }

        // contact types
        const contactTypes = [
            { label: 'BUSINESS', value: "BUSINESS", checked: true },
            { label: 'INDIVIDUAL', value: "INDIVIDUAL", checked: false }
        ];
        let contactType = 'OUTSIDE'

        /**
         * To get payment mode
         */
        const getPaymentMode =()=>{
            const PaymentModes:any = [
                { label: 'ACH', value: "ACH" , checked: props?.selectedPaymentMthod?.includes('ACH')},
                { label: 'WIRE', value: "WIRE", checked: props?.selectedPaymentMthod?.includes('WIRE') },
                { label: 'INTERNATIONAL WIRE', value: "INTERNATIONAL_WIRE", checked: props?.selectedPaymentMthod?.includes('INTERNATIONAL_WIRE') },
                { label: 'CHECK', value: "CHECK", checked: props?.selectedPaymentMthod?.includes('CHECK') }
            ];

            if (businessCustomer) {
                PaymentModes.push({ label: 'VIRTUAL CARD', value: "VIRTUAL_CARD", checked: props?.selectedPaymentMthod?.includes('VIRTUAL_CARD') });
            
                if (uiFeat.getContactCardMethod().available) {
                    PaymentModes.push({ label: 'CARD', value: "CARD", checked: props?.selectedPaymentMthod?.includes('CARD') });
                }
            
                if (isEnableBillPaymentVisible) {
                    PaymentModes.push({ label: 'MONEYGRAM', value: "MONEYGRAM", checked: true, disabled: true });
                }
            } 

            return PaymentModes;
        }

        /**
         * payment methods
         */
        const PaymentModes = useMemo(() => getPaymentMode(), [props]);

        /**
         * Method handles contact type change
         *  */
        const handleTypeChange = (event: any) => {
            updateComponentParams(dispatch, props?.id, { 'type': event.target.value, recipientName: '', holderName: '', firstName: '', lastName: '', })
            setValue('recipientName', '')
            setValue('holderName', '')
            setValue('legalName', '')
            setValue('firstName', '')
            setValue('lastName', '')

        }

        /**
         * Method to handle changes on event
         */

        const handleChange = () => {
            updateComponentParams(dispatch, props?.id, { 'selectedPaymentMthod': getValues('contactMethod') })
        }


        /**
        *  Address line 1 validation constant for card
        * */ 
        const setCardAddressLine1Validation = {
            required: mandateFieldForCardTransaction.includes('billingaddress') ? true : false,
            minLength: {
                value: 4,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            },
            maxLength: {
                value: 40,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            }
        }


        /**
         * city name validation constant for card
         */
        const setCardCityNameValidation = {
            required: mandateFieldForCardTransaction.includes('billingaddress') ? true : false,
            minLength: 2
        }

        /**
         * holder name validation constant
         */
        const setHolderNameValidation = {
            required: true,
            maxLength: 128
        }

        // external account validation
        const EAValidation = [
            { label: 'Instant Verification', value: "EWS" },
            { label: 'Prenote', value: "PRENOTE" }
        ];
        /**
         *  Method handles blur event on first name field
         * @param value
         */
        const legalNameOnBlur = (value: any) => {
            try {
                setValue('recipientName', value)
                setValue('holderName', value)
                updateComponentParams(dispatch, props?.id, { 'recipientName': value, 'holderName': value })
            }
            catch (e) { }
        }
        /**
         * Method handles blur event on first name field
         * @param value
         */
        const firstNameOnBlur = (value: any) => {
            try {
                const newValue = value + ' ' + props?.lastName
                setValue('recipientName', newValue)
                setValue('holderName', newValue)
                updateComponentParams(dispatch, props?.id, { 'recipientName': newValue, 'holderName': newValue })
            }
            catch (e) { }
        }
        /**
         * Method handles blur event on last name field
         * @param value
         */
        const lastNameOnBlur = (value: any) => {
            try {
                const newValue = props?.firstName + ' ' + value;
                setValue('recipientName', newValue)
                setValue('holderName', newValue)
                updateComponentParams(dispatch, props?.id, { 'recipientName': newValue, 'holderName': newValue })
            }
            catch (e) { }
        }
        /**
         * Method handles change event on recipient name field
         * @param event
         */
        const handleRecipientChange = (event: any) => {
            try {
                setValue('recipientName', event.target.value)
                updateComponentParams(dispatch, props?.id, { 'recipientName': event.target.value })
            }
            catch (e) { }
        }
        /**
         * Method handles change event on holder name field
         * @param event
         */
        const handleHolderNameChange = (event: any) => {
            try {
                setValue('holderName', event.target.value)
                updateComponentParams(dispatch, props?.id, { 'holderName': event.target.value })
            }
            catch (e) { }
        }
        /**
         * Method handles change event on first name field
         * @param event
         */
        const handleFirstNameChange = (event: any) => {
            try {
                setValue('firstName', event.target.value)
                updateComponentParams(dispatch, props?.id, { 'firstName': event.target.value })
            }
            catch (e) { }
        }
        /**
         * Method handles change event on last name field
         * @param event
         */
        const handleLastNameChange = (event: any) => {
            try {
                setValue('lastName', event.target.value)
                updateComponentParams(dispatch, props?.id, { 'lastName': event.target.value })
            }
            catch (e) { }
        }

        /**
         * Method handles the validation for schedule date
         */
        const scheduleDateValidation = {
            required: true,
            minDate: (new Date())
        };


  /**
   * This Method handles the population of the Holder Address Values inside the AVS address details
   */
  const populateHolderAddressValuesInAvsDetails = () => {
    setValue('avsAddressLine1', getValues('cardBillingAddressLine1'));
    setValue('avsCity', getValues('cardBillingCity'));
    setValue('avsState', getValues('cardBillingState'));
    setValue('avsZip', getValues('cardBillingZip'));
    setValue('avsEmail', getValues('cardBillingEmail'));
    setValue('avsPhone', getValues('cardBillingPhone'));
    setValue('avsFirstName', getValues('cardFirstName'));
    setValue('avsLastName',  getValues('cardLastName'));
  }
  return (
            <Grid container id="create-payees-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="create-payees-first-grid" item xs={12} sm={12} spacing={2}>
                    <Grid id="create-payees-sub-grid" container justifyContent="center" alignItems="center" className="flex column" >
                        <Grid id="create-payees-second-grid" item xs={12} sm={isSmallScreen ? 8 : 6}>
                        <div className="fx-form-edit-profile flex column">
                                <form id="create-payees-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-payees-form-card" className="fx-theme-passport">
                                        <FxCardBody id="create-payees-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {<>
                                                    {<> <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("contactLabel") }} className={errors.contactLabel ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={displayLabelValidation} control={control} id="displayLabel" label="Display Label*" name="contactLabel" variant="outlined" defaultValue={context?.data?.body?.contactLabel ? context.data.body.contactLabel : ''} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.contactLabel && renderLabelError(errors.contactLabel)}
                                                        </div>
                                                    </Grid>
                                                    </>}
                                                    {getKey("isPPIEnabled")?.toLowerCase() === 'true' && <Grid item xs={12} sm={12}>
                                                        <FxSwitch id="create-payee-check-form-card-passport-payment-switch" value={props?.passportPaymentId?.enabled} onClick={handlePassportPaymentSwitchChange}></FxSwitch>{" "}<FxLabel value="Use Payment ID"></FxLabel>
                                                    </Grid>}
                                                    {props.passportPaymentId && props.passportPaymentId.enabled &&
                                                        <Grid item xs={12} sm={12}>
                                                            <fieldset className="fx-container-create-schdule-summery">
                                                                <Grid item xs={12} sm={8}>
                                                                    <FxVerifyPPI
                                                                        id={"verfify-ppi"}
                                                                        control={control}
                                                                        fieldName={"ppi"}
                                                                        errors={errors}
                                                                        watch={watch}
                                                                        register={register}
                                                                        onVerificationFinished={(status) => {
                                                                                setSaveEnabled(status ? status : false)
                                                                            }
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </fieldset>
                                                        </Grid>
                                                    }
                                                    {!props?.passportPaymentId?.enabled &&
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography className="filter-popover-label" >CONTACT TYPE</Typography>
                                                                <FxRadioEdit register={{ ...register("contactType") }} control={control} className={errors.contactType ? "border-error-input fx-input-edit" : "fx-input-edit"} data={contactTypes} id="create-payees-form-card-customer-type-radio" name="contactType" onChange={handleTypeChange} defaultValue={'BUSINESS'} row="vertical" setValue={setValue} />
                                                            </Grid>
                                                            {props?.type === 'INDIVIDUAL' && <> <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                    rules={nameValidation} control={control} id="create-payees-form-card-firstName-textbox" label="First Name*"
                                                                    name="firstName" variant="outlined" defaultValue={context?.data?.body?.firstName ? context.data.body.firstName : ''} onBlur={firstNameOnBlur} onChange={handleFirstNameChange} value={props?.firstName} />
                                                                <div className={'error-message'}>
                                                                    {errors.firstName && renderLabelError(errors.firstName)}
                                                                </div>
                                                            </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("lastName") }} className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                        rules={nameValidation} control={control} id="create-payees-form-card-lastName-textbox" label="Last Name*"
                                                                        name="lastName" variant="outlined" defaultValue={context?.data?.body?.lastName ? context.data.body.lastName : ''} onBlur={lastNameOnBlur} onChange={handleLastNameChange} value={props?.lastName} />
                                                                    <div className={'error-message'}>
                                                                        {errors.lastName && renderLabelError(errors.lastName)}
                                                                    </div>
                                                                </Grid></>}
                                                            {props?.type === 'BUSINESS' && <> <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("legalName") }} className={errors.legalName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                    rules={nameValidation} control={control} onBlur={legalNameOnBlur} id="create-payees-form-card-legalName-textbox" label="Legal Name*"
                                                                    name="legalName" variant="outlined" defaultValue={context?.data?.body?.legalName ? context.data.body.legalName : ''} />
                                                                <div className={'error-message'}>
                                                                    {errors.legalName && renderNameError(errors.legalName)}
                                                                </div>
                                                            </Grid>
                                                            </>}
                                                        </>}
                                                    {!props?.passportPaymentId?.enabled && <>

                                                        <Grid item xs={12} sm={12}>
                                                            <Typography className="filter-popover-label" >PAYMENT MODE</Typography>
                                                            <FxCheckEdit register={{ ...register("contactMethod") }} rules={{ required: true }} name="contactMethod" control={control} id='create-payees-method' data={PaymentModes} updateOptions={true} onChange={handleChange} setValue={setValue}
                                                           />
                                                            <div className={'error-message'}>
                                                                {errors.contactMethod && checkBoxError(errors.contactMethod)}
                                                            </div>
                                                        </Grid>
                                                    </>}
                                                    {!props?.passportPaymentId?.enabled && (props?.selectedPaymentMthod?.includes('ACH') || props?.selectedPaymentMthod?.includes('WIRE')) && <>  <Grid item xs={12} >
                                                        <Typography className="filter-popover-label">EXTERNAL ACCOUNT DETAILS</Typography>
                                                    </Grid>
                                                  {<Grid item xs={12} sm={6}>
                                                      <FxMaterialSelect register={{ ...register("accountPurpose") }} control={control} rules={{ required: true }} className={errors.accountPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} id="account-purpose" data={accountPurposeData} name="accountPurpose" readOnly={false} label="Holder Type" setValue={setValue} value="CORPORATE" />
                                                  </Grid>}
                                                  <Grid item xs={12} sm={6}>
                                                      <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setHolderNameValidation} id="holdername" label="Holder Name*" name="holderName" variant="outlined" onChange={handleHolderNameChange} value={props?.holderName} />
                                                      <div className={'error-message'}>
                                                          {errors.holderName && renderHolderNameError(errors.holderName)}
                                                      </div>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12}>
                                                        <FxPhoneEdit register={register } className={errors.holderPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-external-account-form-card-holderphone-textbox" label="Holder Phone" name="holderPhone" variant="outlined" 
                                                        rules={setPhoneValidationOptions(false)} disableFlag={true}
                                                        setValue={setValue} defaultValue={context?.data?.body?.holderPhone ? context.data.body.holderPhone : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderPhone && renderNameError(errors.holderPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item  xs={12} sm={12} spacing={2}>
                                                        <fieldset className='fx-create-contact-address-container'>
                                                        <legend>Address Details</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: findAddressValidations(watch),maxLength:35 }} id="create-contact-form-card-addressline1-textbox" label="Address Line 1" name="addressLine1" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine1 ? context.data.body.holderAddress.addressLine1 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine1 && renderEAAddressLine1Error(errors.addressLine1)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false,maxLength:35 }} id="create-contact-form-card-addressline2-textbox" label="Address Line 2" name="addressLine2" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine2 ? context.data.body.holderAddress.addressLine2 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine2 && renderEAAddressLine2Error(errors.addressLine2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setCityNameValidation(findAddressValidations(watch))} id="create-contact-form-card-city-textbox" label="City" name="city" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.city ? context.data.body.holderAddress.city : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: findAddressValidations(watch) }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-contact-form-card-state-textbox" name="state" data={usa_state} label="State" value={context?.data?.body?.holderAddress?.state ? context.data.body.holderAddress.state : ''}
                                                            setValue={setValue} readOnly={false} placeholder="Select"/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(findAddressValidations(watch))} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-contact-form-card-state-textbox" name="zip" label="Zip" defaultValue={context?.data?.body?.holderAddress?.zip ? context.data.body.holderAddress.zip : ''} isEditable={true} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors); }} onKeyDown={(e: any) => {  handleKeyDownChange(e, setValue);}}/>
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                        </fieldset>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant='h5'>Holder’s address and phone number are mandatory to send a wire payment to this account.</Typography>
                                                   </Grid>
                                                  <Grid xs={12} sm={12}>
                                                      <Typography className='fx-create-contact-sub-title'>BANK ACCOUNT INFORMATION</Typography>
                                                  </Grid>
                                                  {<Grid item xs={12} sm={6}>
                                                      <FxMaterialSelect register={{ ...register("accountType") }} rules={{ required: true }} className={errors.accountType ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="account-type" name="accountType" data={accountTypeData} label="Account Type" value="SAVINGS" readOnly={false} setValue={setValue} />
                                                  </Grid>}
                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAccountNumberValidation} control={control} id="account-number" label="Account Number*" name="accountNumber" variant="outlined" defaultValue={context?.data?.body?.accountNumber ? context.data.body.accountNumber : ''} />
                                                            <div className={'error-message'}>
                                                                {errors.accountNumber && renderAccountNumberError(errors.accountNumber)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxLabelView className="fx-label-top" id="routing-number-label">Routing Number*</FxLabelView>
                                                            <FxAutoComplete register={{ ...register("routingNumber") }} control={control} rules={setRoutingNumberValidation(true)} id="routing-number" name="routingNumber" source={bank_src} searchBy={'searchPattern'} className={errors.routingNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} value={context?.data?.body?.routingNumber ? { label: context.data.body.routingNumber, value: context.data.body.routingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} />
                                                            <div className={'error-message'}>
                                                                {errors.routingNumber && renderRoutingNumberError(errors.routingNumber)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography className="fx-label-top" id="wire-routing-number-label">Wire Routing Number</Typography>
                                                            <FxAutoComplete register={{ ...register("wireRoutingNumber") }} control={control} rules={setWireRoutingNumberValidation(false)} id="wire-routing-number" name="wireRoutingNumber" source={bank_src} searchBy={'searchPattern'} value={context?.data?.body?.wireRoutingNumber ? { label: context.data.body.wireRoutingNumber, value: context.data.body.wireRoutingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} />
                                                            <div className={'error-message'}>
                                                                {errors.wireRoutingNumber && renderWireRoutingNumberError(errors.wireRoutingNumber)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect register={{ ...register("accountValidation") }} rules={{ required: true }} className={errors.accountValidation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="account-validation" name="accountValidation" data={EAValidation} label="Validate Account *" value="EWS" setValue={setValue} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("eaPurpose") }} className={errors.eaPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-payees-ea-purpose" label="Purpose" name="eaPurpose" variant="outlined" />
                                                        </Grid>
                                                    </>
                                                    }
                                                    {!props?.passportPaymentId?.enabled  && props?.selectedPaymentMthod?.includes('CHECK') &&
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxLabelView id="customer-signup-individual-info-label-primary-address" className="" >SHIPPING ADDRESS DETAILS</FxLabelView>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("recipientName") }} className={errors.recipientName ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={nameValidation} control={control} id="recipientName" label="Shipped To*" name="recipientName" variant="outlined" value={props?.recipientName} onChange={handleRecipientChange} />
                                                                <div className={'error-message'}>
                                                                    {errors.recipientName && renderNameError(errors.recipientName)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                {/* <FxPhoneEdit register={{ ...register("recipientPhone") }} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="home-help-center-form-card-phone-phoneedit" rules={phoneNumberValidation} label="Recipient Phone" placeholder="Recipient Phone" name="recipientPhone" variant="outlined" setValue={setValue} format="###-###-####" /> */}
                                                                <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} setValue={setValue} setError={setError} clearErrors={clearErrors} className={errors.recipientPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="recipientPhone" name="recipientPhone" label="Phone Number*" />
                                                                <div className={'error-message'}>
                                                                    {errors.recipientPhone && renderPhoneError(errors.recipientPhone)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAddressLine1Validation(true)} id={'addressLine1'} label="Address Line 1*" name={'addressLine1'} variant="outlined" defaultValue={props?.data?.addressLine1 ? props?.data?.addressLine1 : ''} onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                                                <div className={'error-message'}>
                                                                    {errors.addressLine1 && renderError(errors.addressLine1)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'addressLine2'} label="Address Line 2" name={'addressLine2'} variant="outlined" defaultValue={props?.data?.addressLine2 ? props?.data?.addressLine2 : ''} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(true)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="city" name="city" label="City *" defaultValue={context?.data?.body?.card?.billingAddress?.city} isEditable={true} />
                                                                <div className={'error-message'}>
                                                                    {errors.city && renderCityNameError(errors.city)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="state" name="state" data={usa_state} label="State *" readOnly={false}  setValue={setValue} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(true)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="zip" name="zip" label="ZIP *" defaultValue={context?.data?.body?.card?.billingAddress?.zip} isEditable={true} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                                                <div className={'error-message'}>
                                                                    {errors.zip && renderError(errors.zip)}
                                                                </div>
                                                            </Grid>

                                                            <Grid item xs={12} sm={!isSmallScreen ? 6 : 12}></Grid>
                                                        </>
                                                    }
                                                    {!props?.passportPaymentId?.enabled  && props?.selectedPaymentMthod?.includes('VIRTUAL_CARD') &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxLabelView id="customer-signup-individual-info-label-email-address" className="" >EMAIL ADDRESS</FxLabelView>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxTextEdit
                                                                register={{ ...register("contactEmail") }}
                                                                className={
                                                                errors.contactEmail
                                                                    ? "border-error-input fx-input-edit"
                                                                    : "fx-input-edit"
                                                                }
                                                                control={control}
                                                                rules={{ required:true }}
                                                                id="notify-email-textbox"
                                                                label="Email*"
                                                                type="email"
                                                                name="contactEmail"
                                                                variant="outlined"
                                                                defaultValue={context?.data?.body?.contactEmail ? context.data.body.contactEmail : ''}
                                                            />
                                                        </Grid>
                                                    </>
                                                    }
                                                     {!props?.passportPaymentId?.enabled  && props?.selectedPaymentMthod?.includes('INTERNATIONAL_WIRE') &&
                                                    <>
                                                     <Grid item xs={12} sm={12}>
                                                                <FxLabelView id="customer-signup-individual-info-label-iea-contact" className="" >INTERNATIONAL EXTERNAL ACCOUNT DETAILS</FxLabelView>
                                                     </Grid>
                                                     <Grid item xs={12} sm={12} container spacing={2}>
                                                    <>

                                                        <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect
                                                                register={{ ...register("internationalHolderType") }}
                                                                control={control}
                                                                rules={{
                                                                    required: props.isEdit ? false : true,
                                                                }}
                                                                className={
                                                                    errors.internationalHolderType
                                                                        ? "border-error-input fx-input-edit"
                                                                        : "fx-input-edit"
                                                                }
                                                                id="create-iea-contact-form-card-account-purpose"
                                                                data={accountPurposeData}
                                                                name="internationalHolderType"
                                                                readOnly={props.isEdit ? true : false}
                                                                label="Holder Type"
                                                                setValue={setValue}
                                                                value={
                                                                    context?.data?.body?.destination?.internationalExternalAccount
                                                                        ?.holderType
                                                                        ? context?.data?.body?.destination?.internationalExternalAccount?.holderType
                                                                        : "CORPORATE"
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEdit
                                                                register={{ ...register("internationalAccountHolderName") }}
                                                                className={
                                                                    errors.internationalAccountHolderName
                                                                        ? "border-error-input fx-input-edit"
                                                                        : "fx-input-edit"
                                                                }
                                                                control={control}
                                                                rules={{ required: props.isEdit ? false : true }}
                                                                id="create-contact-onetime-holdername-internationalWire-onetime-form-card-amount-textbox"
                                                                label="Holder Name*"
                                                                name="internationalAccountHolderName"
                                                                type="text"
                                                                variant="outlined"
                                                                defaultValue={
                                                                    context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                                        ? context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                                        : ""
                                                                }
                                                                isEditable={props.isEdit ? false : true}
                                                            />
                                                        </Grid>
                                                    </>
                                                    </Grid>
                                                    <fieldset className="fx-container-create-schdule-summery">
                                                    <legend>Holder Address Details</legend>
                                                    <Grid item container xs={12} sm={12} spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxTextEdit register={{ ...register('internationalAddressLine1') }} control={control} className={errors['internationalAddressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'iea-contact-add-addressline1-textbox'} label="Address Line 1" name={'internationalAddressLine1'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine1 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine1 : ''} 
                                                            isEditable={props.isEdit ? false : true}/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxTextEdit register={{ ...register('internationalAddressLine2') }} control={control} className={errors['internationalAddressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'iea-contact-address-add-addressline2-textbox'} label="Address Line 2" name={'internationalAddressLine2'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine2 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine2 : ''}
                                                            isEditable={props.isEdit ? false : true}/>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("internationalCity") }} control={control} rules={{ required: true }} className={errors.internationalCity ? "border-error-input fx-input-edit" : "fx-input-edit"} id="iea-contact-address-add-city-textbox" name="internationalCity" label="City*" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city : ''} isEditable={props.isEdit ? false : true} />
                                                        </Grid>
                                                        {watch('internationalCountry') === "US"? <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect register={{ ...register("unitedState") }} rules={{ required: false }} className={errors.unitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="iea-contact-address-add-state-textbox" name="unitedState" data={usa_state} label="State" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""}
                                                                setValue={setValue} readOnly={props.isEdit ? true : false} />
                                                        </Grid> : <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("nonUnitedState") }} control={control} rules={{ required: false, maxLength: 3 }} className={errors.nonUnitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} id="iea-contact-address-add-city-textbox" name="nonUnitedState" label="State" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""} isEditable={props.isEdit ? false : true} />
                                                            <div className={'error-message'}>{errors.nonUnitedState && renderStateError(errors.nonUnitedState)}</div>
                                                        </Grid>}
                                                        <Grid item xs={12} sm={6}>
                                                            <FxMaterialSelect register={{ ...register("internationalCountry") }} rules={{ required: true }} className={errors.internationalCountry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="iea-contact-address-add-country-textbox" name="internationalCountry" data={countries} label="Country*" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country : ""}
                                                                setValue={setValue} readOnly={props.isEdit ? true : false} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("internationalZip") }} control={control} rules={{ required: false }} className={errors.internationalZip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="iea-contact-address-add-zip-textbox" name="internationalZip" label="Zip Code" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.zip || ""} isEditable={props.isEdit ? false : true} />
                                                        </Grid>
                                                    </Grid>
                                                    </fieldset>
                                                    <Grid item xs={12} sm={12}>
                                                                <FxLabelView id="customer-signup-individual-info-label-iea-contact-bank" className="" >BANK ACCOUNT INFORMATION</FxLabelView>
                                                     </Grid>
                                                     <Grid item xs={12} sm={12} container spacing={2}>
                                                    <>

                                                    <Grid item container xs={12} sm={12} spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect
                                                            register={{ ...register("internationalAccountType") }}
                                                            className={
                                                                errors.internationalAccountType
                                                                    ? "border-error-input fx-input-edit"
                                                                    : "fx-input-edit"
                                                            }
                                                            rules={{ required: true }}
                                                            control={control}
                                                            id="iea-contact-form-card-account-type"
                                                            name="internationalAccountType"
                                                            data={accountTypeData}
                                                            value={
                                                                context?.data?.body?.destination?.internationalExternalAccount?.type
                                                                    ? context?.data?.body?.destination?.internationalExternalAccount?.type
                                                                    : "SAVINGS"
                                                            }
                                                            label="Account Type*"
                                                            setValue={setValue}
                                                            readOnly={props.isEdit ? true : false}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} className="fx-contact-user-card">
                                                          <FxMaterialMultipleSelect name='acceptedCurrency'
                                                             selectAllLabel={'Select All' }
                                                             control={control} 
                                                             isEdit={props.isEdit ? true : false} 
                                                             id="iea-contact-form-acceptedcurrency"
                                                             data={currencies} 
                                                             fixedOptions={currencyFixedOptions}
                                                              rules={{ required: currencyFixedOptions ? false : true }}
                                                              limitTags={3}
                                                              readOnly={props.isEdit ? true : false}
                                                              label='Accepted Currency *'
                                                              canSearch= {false}
                                                              register={{ ...register("acceptedCurrency") }}
                                                              setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                          <FxSwiftCode 
                                                             register={{ ...register("swiftCode") }}
                                                              className={errors.swiftCode ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                              control={control}
                                                              rules={{ required: true }} 
                                                              id="create-iea-contact-swiftcode"
                                                              label="Swift Code*" 
                                                              name="swiftCode" 
                                                              variant="outlined"
                                                              setValue={setValue}
                                                              onSwiftCodeChange={(data)=> setRoutingCodeDetails(data)} />
                                                    </Grid>
                                                    {routingCodeDetails?.internationalCodeCharacterLength !== 0 && routingCodeDetails?.internationalCodeLabel &&<Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("internationalRoutingCode") }} className={errors.internationalRoutingCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} 
                                                            rules={{ required: false }}  id="iea-contact-add-routingcode-number-textbox"
                                                            label={routingCodeDetails?.internationalCodeLabel || "International Routing Code"}
                                                            name="internationalRoutingCode" variant="outlined" 
                                                            defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.internationalRoutingCode ? context?.data?.body?.destination?.internationalExternalAccount?.internationalRoutingCode : ''} 
                                                             />
                                                    </Grid>}
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("internationalAccountNumber") }} className={errors.internationalAccountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAccountNumberValidationIWP} control={control} id="iea-contact-add-account-number-textbox" label="International Bank Account Number*" name="internationalAccountNumber" variant="outlined" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.accountNumber ? context?.data?.body?.destination?.internationalExternalAccount?.accountNumber : ''} isEditable={props.isEdit ? false : true} />
                                                        <div className={'error-message'}>
                                                            {errors.internationalAccountNumber && renderAccountNumberIWPError(errors.internationalAccountNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                            <FxTextEdit register={{ ...register("internationalPurpose") }} className={errors.internationalPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-iea-contact-purpose" label="Purpose" name="internationalPurpose" variant="outlined" isEditable={props.isEdit ? false : true}/>
                                                        </Grid>
                                                    </Grid>
                                                  
                                                    </>
                                                    </Grid>
                                                    </>}
                                                    {
                                                        !props?.passportPaymentId?.enabled  && businessCustomer && props?.selectedPaymentMthod?.includes('CARD') && 
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxLabelView id="card-option-label" className="" >CARD DETAILS</FxLabelView>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <FxTextEdit register={{ ...register("cardFirstName") }} className={errors.cardFirstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: !!mandateFieldForCardTransaction.includes('holdername'), maxLength: 45 }} id="card-holder-first-name" label={mandateFieldForCardTransaction.includes('holdername') ? "First Name*" : "First Name"} name="cardFirstName" variant="outlined" setValue={setValue} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <FxTextEdit register={{ ...register("cardLastName") }} className={errors.cardLastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: !!mandateFieldForCardTransaction.includes('holdername'), maxLength: 45 }} id="card-holder-last-name" label={mandateFieldForCardTransaction.includes('holdername') ? "Last Name*" : "Last Name"} name="cardLastName" variant="outlined" setValue={setValue} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxCardNumberEdit isEditable={true} register={{ ...register("cardNumber") }} className={errors.cardNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number" control={control} setValue={setValue} setError={setError}
                                                                    clearErrors={clearErrors} rules={setCardNumberValidation(true)} id="contact-card-number" label="Card Number*" placeholder="Card Number" name="cardNumber" variant="outlined" maxLength={20} />
                                                                <div className={'error-message'}>
                                                                    {errors.cardNumber && renderCardNumberError(errors.cardNumber)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <FxDateEdit register={{ ...register("expiryDate") }} className={errors.expiryDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={scheduleDateValidation} id="card-expiry-date" label="Expiry Date*" name="expiryDate" type="date" variant="outlined" setValue={setValue} month={true} disablePast={true} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <FxTextEdit register={{ ...register("cvv") }} className={errors.cvv ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" control={control} rules={setCVVValidation(mandateFieldForCardTransaction.includes('cvv'))} id="card-cvv" label={mandateFieldForCardTransaction.includes('cvv') ? "CVV*" : "CVV"} name="cvv" variant="outlined" maxLength={4} onChange={(e: any) => { handleCvvChange(e, setValue, setError, clearErrors) }}/>
                                                                <div className={'error-message'}>
                                                                    {errors.cvv && renderError(errors.cvv)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item container xs={12} sm={12}>
                                                                <fieldset className="fx-container-create-schdule-summery">
                                                                    <legend>Billing Address</legend>
                                                                    <Grid item container xs={12} sm={12} spacing={2}>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <FxTextEdit register={{ ...register("cardBillingAddressLine1") }} control={control} rules={setCardAddressLine1Validation} className={errors.cardBillingAddressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-address-line1" name="cardBillingAddressLine1" label={mandateFieldForCardTransaction.includes('billingaddress') ? "Address Line 1*" : "Address Line 1"} setValue={setValue} />
                                                                            <div className={'error-message'}>
                                                                                {errors.cardBillingAddressLine1 && renderAddressLine1Error(errors.cardBillingAddressLine1)}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <FxTextEdit register={{ ...register("cardBillingAddressLine2") }} control={control} rules={setAddressLine2Validation(false)} className={errors.cardBillingAddressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-address-line2" name="cardBillingAddressLine2" label="Address Line 2" setValue={setValue} />
                                                                            <div className={'error-message'}>
                                                                                {errors.cardBillingAddressLine2 && renderAddressLine2Error(errors.cardBillingAddressLine2)}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FxTextEdit register={{ ...register("cardBillingCity") }} control={control} rules={setCardCityNameValidation} className={errors.cardBillingCity ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-city" name="cardBillingCity" label={mandateFieldForCardTransaction.includes('billingaddress') ? "City*" : "City"} setValue={setValue} />
                                                                            <div className={'error-message'}>
                                                                                {errors.cardBillingCity && renderCityNameError(errors.cardBillingCity)}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                          <FxMaterialSelect
                                                                            register={{ ...register('cardBillingState') }}
                                                                            rules={{ required: !!mandateFieldForCardTransaction.includes('billingaddress') }}
                                                                            className={errors.cardBillingState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                            control={control}
                                                                            id="card-billing-state-select"
                                                                            name="cardBillingState"
                                                                            data={usa_state}
                                                                            label={mandateFieldForCardTransaction.includes('billingaddress') ? 'State*' : 'State'}
                                                                            readOnly={props.isEdit}
                                                                            setValue={setValue}
                                                                          />
                                                                          <div
                                                                            className={'error-message'}>{errors.cardBillingState && renderStateError(errors.cardBillingState)}
                                                                          </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FxMaterialSelect
                                                                                register={{ ...register('cardBillingCountry') }}
                                                                                rules={{ required: !!mandateFieldForCardTransaction.includes('billingaddress') }}
                                                                                className={errors.cardBillingCountry ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                                control={control}
                                                                                id="card-billing-address-add-country-textbox"
                                                                                name="cardBillingCountry"
                                                                                data={countries}
                                                                                label={mandateFieldForCardTransaction.includes('billingaddress') ? "Country*" : "Country"}
                                                                                setValue={setValue}
                                                                                value={'US'}
                                                                                readOnly={true} />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FxTextEdit
                                                                                register={{ ...register('cardBillingZip') }}
                                                                                control={control}
                                                                                rules={setZipValidationOptions(!!mandateFieldForCardTransaction.includes('billingaddress'))}
                                                                                className={errors.cardBillingZip ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                                id="card-billing-zip"
                                                                                name="cardBillingZip"
                                                                                label={mandateFieldForCardTransaction.includes('billingaddress') ? 'ZIP*' : 'ZIP'}
                                                                                onChange={(e: any) => {
                                                                                  handleZipChange(e, setValue, setError, clearErrors)
                                                                                }} onKeyDown={(e: any) => {
                                                                              handleKeyDownChange(e, setValue)
                                                                            }} />
                                                                            <div className={'error-message'}>
                                                                              {errors.cardBillingZip && renderError(errors.cardBillingZip)}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FxTextEdit
                                                                                register={{ ...register("cardBillingEmail") }}
                                                                                className={
                                                                                  errors.cardBillingEmail
                                                                                    ? "border-error-input fx-input-edit"
                                                                                    : "fx-input-edit"
                                                                                }
                                                                                control={control}
                                                                                rules={{ required: false}}
                                                                                id="card-billing-email"
                                                                                label={'Email'}
                                                                                type="email"
                                                                                name="cardBillingEmail"
                                                                                variant="outlined"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FxPhoneEdit register={register}
                                                                                         control={control}
                                                                                         rules={setPhoneValidationOptions(false)}
                                                                                         setValue={setValue}
                                                                                         setError={setError}
                                                                                         clearErrors={clearErrors}
                                                                                         className={errors.cardBillingPhone ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                                         id="card-billing-phone"
                                                                                         name="cardBillingPhone"
                                                                                         label={'Mobile Phone'}
                                                                            />
                                                                            <div className={'error-message'}>
                                                                              {errors.cardBillingPhone && renderPhoneError(errors.cardBillingPhone)}
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </fieldset>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider />
                                                            </Grid>
                                                            <Grid item container xs={12}>
                                                                <Accordion
                                                                    expanded={avsDetailOpen}
                                                                    onChange={() => toggleAvsDetail()}
                                                                    className={"fx-additional-card-info-accordian"}
                                                                >
                                                                    <AccordionSummary className="fx-additional-card-info-accordian-header-section">
                                                                        <Grid container xs={12}>
                                                                            <Grid item xs={11} sm={11} justifyContent={"flex-start"}>
                                                                                <Typography
                                                                                    className="fx-summary-title"
                                                                                    variant="subtitle1"
                                                                                >
                                                                                    ADDRESS VERIFICATION SERVICE
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={1}
                                                                                justifyContent={"flex-end"}
                                                                                textAlign={"right"}
                                                                            >
                                                                              {avsDetailOpen ? <UpArrow/> : <DownArrow/>}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <AVSDetailsForm
                                                                            register={register}
                                                                            watch={watch}
                                                                            control={control}
                                                                            errors={errors}
                                                                            setValue={setValue}
                                                                            clearErrors={clearErrors}
                                                                            setError={setError}
                                                                            handlePopulateAVSDetails={populateHolderAddressValuesInAvsDetails}
                                                                        />
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </>
                                                    }
                                                </>}
                                                <Grid item xs={12}>
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="create-payees-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="add-payee-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    disabled= {props?.passportPaymentId?.enabled ? !saveEnabled : false}
                                                    className={!props?.passportPaymentId?.enabled ? "fx-button fx-button-theme" : saveEnabled ? "fx-button fx-button-theme" : "fx-button fx-button-disabled"}
                                                    id={"add-payees-submit-button"}
                                                    type="submit"
                                                    isSubmitting={props.isloading}
                                                >
                                                  {submitButton}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </form>
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
            </Grid>
        )
    });