import React from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { capitalize } from 'lodash';
import { Logger } from '../../../libs/utils/logger';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { ContactInfoCard } from './ContactInfoCard';

Logger.debug("ContactDetails.tsx", "Contact Details initializing");

/**
 * Component: ContactDetails
 * Usage: To display contact profile cards
 */
export const ContactDetails: React.FC<any> = React.memo(() => {
        const account_info: any = {};

        interface RouteParams {
            id: string
        }

        //get ID from page url
        const params = useParams<RouteParams>();
        const id = params.id
        const contact_source = {
            url:  "/contact/id/" + id,
            method: "GET"
        }

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container className="fx-top-header" id="account-details-container-grid" item justifyContent="space-between" alignItems="center" xs={12} >
                    <Grid item className="fx-inline-flex" alignItems="center">
                        <Typography id="title-account-details" variant="h3" ><h3>External Account ({ })</h3>
                        </Typography>
                        <div className="fx-header-status">
                            <FxStatus id="linkedExternalAccount-status" value={capitalize(account_info?.status)} fill={false} className="fx-status"></FxStatus>
                        </div>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <ContactInfoCard id="payee-details-info-card" source={contact_source} />

                </Grid>
            </Grid>
        )
    })