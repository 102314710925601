import { Grid } from "@mui/material";
import React, { useState } from "react";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { FxPopOver } from "../../../Data/FxPopOver";
import FxAvatar from "../../../Input/FxAvatar/FxAvatar";
import { ReactComponent as TriangleDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../assets/svg/triangle-up-icon.svg';
import FxTextView from "../../../Input/FxText/FxTextView";
import { checkPermission } from "../../../Utils/CommonBaseClass";
import { useIsMobileOrTabletScreen, useIsMobileScreen } from "../../../../libs/utils/utils";
import { FxLink } from "../../../Action/FxLink";
import { getKey } from "../../../../libs/utils/storageManager";
import { checkIsPartner } from "../../../Utils/ThemeCheckerPartner";
import UIFeatureImpl from "../../../../libs/services/uiFeatures";

/**
 * This component handles the welcome selection in left menu
 */
export const FxWelcomeListMenu: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const isMobileOrTabScreen = useIsMobileOrTabletScreen();
    const isSmallScreen = useIsMobileScreen();

    const [anchorEl, setAnchorEl] = useState(null);
    const [welcomeExpanded, setWelcomeExpanded] = useState(false);
    const uiFeat = UIFeatureImpl.getInstance();
    const individualCustomer = uiFeat.getIndividualCustomerFeature().available

    /**
     * handle click away from popover
     */
    const handleClose = (event: any, item: any) => {
        event.stopPropagation();
        setAnchorEl(null);
        setWelcomeExpanded(false);
        item !== 'backdropClick' && props?.onMenuSelect && props?.onMenuSelect(event);
    };
    const open = Boolean(anchorEl);

    /**
     * handle click on menu
     */
    const handleClick = (event: any) => {
        event.stopPropagation();
        props?.drawerExpand && setWelcomeExpanded(true);
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to check permission
     */
    function checkMenuDrawerPermission(data: any) {
        const permissionObject: any = {
            'authorisedusers': {
                entity: "Authorized User",
                name: "User",
                operation: "View"
            },
            'roles': {
                entity: "Role",
                name: "Role",
                operation: "View"
            },
            'apitoken': {
                entity: "Role",
                name: "Role",
                operation: "View"
            },
            'approvalpolicy': {
                entity: "Approval Policy",
                name: "Approval Policy",
                operation: "View"
            },
            'fundingRule' : {
                entity: checkIsPartner() ? "Partner Portal" :"Funding Rule",
                name: "Funding Rule",
                operation: 'View'
            }
        }
        return checkPermission(permissionObject[data]);
    }

    /**
     * Options for welcome menu
     */
    const menuOptions = () => {
        // for next task
        // const preferenceOptions = [
        //     { id: 'Admin Preferences', label: 'Admin Preferences', url: '/admin-preferences' },
        // ];

        // The default menu options 
        const baseOptions = [
            { id: 'User Profile', label: 'User Profile', url: '/profile' },
        ];

        const helpMenu= !isMobileOrTabScreen ? [{ id: 'Help & Support', label: 'Help & Support', url: '/help-center' }]:[]

        // Conditionally adding a welcome option based on drawer expand
        const welcomeOption = !props.drawerExpand
            ? [{
                id: 'Welcome',
                subLabel: <FxTextView id="menu-left-user-profile" className={'fx-welcome-menu-username'} value={`${props.userName}`}></FxTextView>,
                label: 'Welcome!',
                className: 'fx-sidenav-smalltext-user',
            }] : [];

            
           
            const manageAlertsOption = [{ id: 'alert-preferences', label: 'Alert Preferences', url: '/alertpreferences' }];
            
            const fundingRuleOption = checkMenuDrawerPermission('fundingRule') && getKey('enableFundingRule') === 'true' ? [{ id: 'Funding Rules', label: 'Funding Rules', url: '/fundingrules/active' }] : [];
            if (individualCustomer && getKey('loginType')?.toLowerCase() !== 'partner'){
                return [...baseOptions, ...manageAlertsOption, ...fundingRuleOption, ...helpMenu, ...welcomeOption];
            } else if (getKey('loginType') === 'PARTNER' && props?.selectedPortal === 'LEADS') {
                return [...fundingRuleOption, ...welcomeOption];
            } else {
                return [...baseOptions, ...manageAlertsOption, ...getKey('loginType')?.toLowerCase() !== 'partner' ? fundingRuleOption:[], ...helpMenu, ...welcomeOption];
            } 
    }

    return (<>
        {!props.drawerExpand && <>
            <Grid item xs={12} className='fx-sidenav-collapse-close-popover' onClick={handleClick}>
                  <Grid item xs={12} container justifyContent='end'>
                    <FxLink id="menu-left-user-profile"><FxAvatar id="avtar-collapse" className="fx-sidenav-collapse-close-popover-welcome fx-sidenav-collapse-close-popover-welcome-avatar" value={props?.userName} /></FxLink>
                </Grid>
            </Grid>
        </>}

        {props?.drawerExpand && <Grid item className={`fx-sidenav-bottommenu-item ${welcomeExpanded ? 'fx-sidenav-expanded-theme-sel' : 'fx-sidenav-expanded-theme-not-sel'}`} onClick={handleClick}>
          <FxLink id="menu-left-user-profile" className={`fx-sidenav-bottommenu-main `} >
            <Grid item xs={12} container justifyContent='space-between' alignItems='center' paddingTop='1rem'>
              <Grid item xs={!isSmallScreen?2: 1} justifyContent='center' alignItems='center'><FxAvatar id="avtar-collapse" value={props?.userName} className={`${welcomeExpanded ? 'fx-sidenav-expanded-avatar-theme' : 'fx-sidenav-expanded-avatar-not-theme'} fx-sidenav-bottommenu-left`}/></Grid>
              <Grid className='fx-sidenav-bottommenu-middle' item xs={!isSmallScreen?7:11}><div className="fx-menu-passport-bottommenu-smalltext">Welcome!</div><div>{props?.userName}</div></Grid>
              <Grid item justifyContent='center' alignItems='center' display='flex' xs={!isSmallScreen?2: 1} className='fx-sidenav-bottommenu-main-right'>{!welcomeExpanded ? <TriangleUp /> : <TriangleDown />}</Grid>
            </Grid>
          </FxLink>
        </Grid>}
        <Grid item container xs={12}>
            <FxPopOver
                id={'welcome-list-menu'}
                open={open}
                refElement={anchorEl}
                onClose={handleClose}
                className={`${!props.drawerExpand ? 'fx-sidenav-collapse-welcome-menu' : 'fx-sidenav-expanded-popup'}`}
                anchorOrigin={{
                    vertical: props.drawerExpand ? 'top' : 'bottom',
                    horizontal: props.drawerExpand ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: props.drawerExpand ? 'bottom' : 'bottom',
                    horizontal: props.drawerExpand ? 'center' : 'left',
                }}
                data={menuOptions()}
            >
            </FxPopOver>
        </Grid>
    </>)
})