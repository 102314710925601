/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormReset, UseFormSetValue, UseFormWatch, useFieldArray } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { FxButton } from "../../Action/FxButton";
import { FxLink } from "../../Action/FxLink";

Logger.debug("InvoiceItemTable.tsx", "Invoice Items Table Initializing");

type FormValueType = Record<string, any>;
type TFieldValues = Record<string, string | null>;

interface IInvoiceItemTable {
  id: string;
  register: UseFormRegister<TFieldValues>,
  watch: UseFormWatch<FormValueType>;
  setValue: UseFormSetValue<any>;
  control: Control<any>,
  errors: FieldErrors<TFieldValues>;
  data?: any;
  getValues: UseFormGetValues<FormValueType>;
  reset : UseFormReset<any>;
  handleAmountChange?: any;
}

/**
 * This component handles the rendering of the Invoice Items Table when user creates an invoice for user while creating collect.
 */
export const InvoiceItemTable: React.FC<IInvoiceItemTable> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  const { setValue, getValues, data, handleAmountChange, reset, control } = props;

  /**
   * create a Form Field Array
   */
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "invoice-items",
    shouldUnregister: true,
  });

  /**
   * Method to add Field
   */
  const handleAppend = useCallback(() => {
    append({
      description: '',
      commodityCode: '',
      productCode: '',
      quantity: '',
      unitOfMeasure: '',
      unitCost: '',
      discountAmount: '',
      taxAmount: '',
      totalAmount: ''
    });
  }, [append]);


  useEffect(() => {
    if(fields.length === 0)
      handleAppend();
  }, [fields.length, handleAppend]);

  /**
   * useEffect to load the api data into the useFieldArray Object
   */
  useEffect(() => {
    if(data && Array.isArray(data)){
      const modifiedData = data.map((item: any) => {
        const unitCost = item.unitCost ? Number(item.unitCost) : 0;
        const quantity = item.quantity ? Number(item.quantity) : 1;
        const discountAmount = item.discountAmount ? Number(item.discountAmount) : 0;
        const taxAmount = item.taxAmount ? Number(item.taxAmount) : 0;
        const totalValue = unitCost * quantity - discountAmount + taxAmount;
        return {...item, totalAmount: totalValue};
      });

      reset({
        'invoice-items' : modifiedData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Headers for the invoice details table
   */
  const columnHeaders = [
    { label: "Name", marginLeft: "1rem", xs:"2" },
    { label: "Description", marginLeft: "0rem", xs:"3"},
    { label: "Item Code", marginLeft: "1.5rem", xs:"2" },
    { label: "Quantity", marginLeft: "0.5rem", xs:"2" },
    { label: "Measure Unit", marginLeft: "1rem", xs:"2" },
    { label: "Unit Price", marginLeft: "1rem" , xs:"2"},
    { label: "Discount", marginLeft: "1rem", xs:"2" },
    { label: "Tax", marginLeft: "1rem", xs:"2" },
    { label: "Total Amount",  marginLeft: "1rem", xs:"2" },
    { label: "", marginLeft: "1rem", xs:"1" },
  ];

  /**
   * Method to handle the opening and closing of the split rule accordian
   */
  const handleAddInvoiceItem = () => {
        handleAppend();
    }


    /**
     * Method to handle the removal of items(rows) in the split rule section
     * @param index
     * @param length
     */
    const handleDelete = (index: any, length: any) => {
        if(length > 1){
            remove(index);
        }
      };


  /**
  * Calculating the toatl amount for each row
  */
  const handleChange = (e:any, index:any) => {
    const { name, value } = e.target;
    setValue(name, value);

    const unitCost = Number(getValues(`invoice-items.${index}.unitCost`));
    const quantity = Number(getValues(`invoice-items.${index}.quantity`));
    const discountAmount = Number(getValues(`invoice-items.${index}.discountAmount`));
    const taxAmount = Number(getValues(`invoice-items.${index}.taxAmount`));

    const totalValue = unitCost * quantity - discountAmount + taxAmount;

    setValue(`invoice-items.${index}.totalAmount`, totalValue);
    handleAmountChange && handleAmountChange();
  }


  return (
    <>
      <Grid item container xs={12} sm={12} width={'inherit'}>
        <fieldset className="fx-container-create-schdule-summery" >
          <legend>Invoice Items</legend>
          <Grid item container xs={12} sm={12}>
            <Grid
              xs={12}
              sm={12}
              className="fx-invoice-items-table-headers"
              display={"flex"}
              width={"130vh"}
              gap={"1.5rem"}
              padding={"0.5rem"}
            >
              {columnHeaders.map((header) => (
                <Grid
                  item
                  xs={parseInt(header.xs)}
                  marginLeft={header.marginLeft}
                >
                  <Typography>{header.label}</Typography>
                </Grid>
              ))}
            </Grid>

            {fields.map((field:any, index:any) => {
              return (
                <Grid
                  id={`invoice-table-row-`+index}
                  className="fx-form-edit-profile"
                  display={"flex"}
                  gap={"1.5rem"}
                  padding={"1rem"}
                  key={field.id}
                >
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.description`),
                      }}
                      control={props?.control}
                      rules={{ required: false }}
                      className={
                        props?.errors.description
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`invoice-items.${index}.description`}
                      name={`invoice-items.${index}.description`}
                      setValue={props?.setValue}
                      isEditable={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.commodityCode`),
                      }}
                      control={props?.control}
                      rules={{ required: false }}
                      className={
                        props?.errors.commodityCode
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`invoice-items.${index}.commodityCode`}
                      name={`invoice-items.${index}.commodityCode`}
                      setValue={props?.setValue}
                      isEditable={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.productCode`),
                      }}
                      className={
                        props?.errors.productCode
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.productCode`}
                      name={`invoice-items.${index}.productCode`}
                      setValue={props.setValue}
                      defaultValue={
                        props?.data?.productCode ? props?.data?.productCode : ""
                      }
                      variant="outlined"
                      valuePattern={/-|\+|e|E/}
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.quantity`),
                      }}
                      className={
                        props?.errors.quantity
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.quantity`}
                      name={`invoice-items.${index}.quantity`}
                      setValue={props.setValue}
                      defaultValue={
                        props?.data?.quantity ? props?.data?.quantity : ""
                      }
                      variant="outlined"
                      valuePattern={/-|\+|e|E/}
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onChange={(e)=>handleChange(e,index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.unitOfMeasure`),
                      }}
                      control={props?.control}
                      rules={{ required: false }}
                      className={
                        props?.errors.unitOfMeasure
                          ? "border-error-input fx-input-edit"
                          : "fx-input-edit"
                      }
                      id={`invoice-items.${index}.unitOfMeasure`}
                      name={`invoice-items.${index}.unitOfMeasure`}
                      setValue={props?.setValue}
                      isEditable={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.unitCost`),
                      }}
                      className={
                        props?.errors.unitCost
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.unitCost`}
                      name={`invoice-items.${index}.unitCost`}
                      setValue={props.setValue}
                      defaultValue={
                        props?.data?.unitCost ? props?.data?.unitCost : ""
                      }
                      variant="outlined"
                      valuePattern={/-|\+|e|E/}
                      type="number"
                      prefix="$"
                      showDecimal={true}
                      onWheel={(e: any) => e.target.blur()}
                      onChange={(e)=>handleChange(e,index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.discountAmount`),
                      }}
                      className={
                        props?.errors.discountAmount
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.discountAmount`}
                      name={`invoice-items.${index}.discountAmount`}
                      setValue={props.setValue}
                      variant="outlined"
                      defaultValue={
                        props?.data?.discountAmount ? props?.data?.discountAmount : ""
                      }
                      prefix="$"
                      valuePattern={/-|\+|e|E/}
                      type="number"
                      showDecimal={true}
                      onWheel={(e: any) => e.target.blur()}
                      onChange={(e)=>handleChange(e,index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.taxAmount`),
                      }}
                      className={
                        props?.errors.tax
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.taxAmount`}
                      name={`invoice-items.${index}.taxAmount`}
                      setValue={props.setValue}
                      variant="outlined"
                      defaultValue={props?.data?.taxAmount ? props?.data?.taxAmount : ""}
                      prefix="$"
                      valuePattern={/-|\+|e|E/}
                      type="number"
                      showDecimal={true}
                      onWheel={(e: any) => e.target.blur()}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FxTextEdit
                      register={{
                        ...props?.register(`invoice-items.${index}.totalAmount`),
                      }}
                      className={
                        props?.errors.totalAmount
                          ? "border-error-input fx-input-edit clear-number-field-arrow"
                          : "fx-input-edit clear-number-field-arrow"
                      }
                      control={props?.control}
                      rules={{ required: false }}
                      id={`invoice-items.${index}.totalAmount`}
                      name={`invoice-items.${index}.totalAmount`}
                      variant="outlined"
                      type="number"
                      prefix="$"
                      showDecimal={true}
                      isEditable={false}

                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FxButton
                      id={index + "-" + index + "-delete-condition-btn"}
                      title="Delete"
                      className={`fx-button fx-approval-button-icon fx-condition-btn ${fields.length === 1 ? "fx-button-disabled" : "" }`}
                      variant="contained"
                      onClick={ () => handleDelete(index, fields.length) }
                      startIcon={<DeleteIcon />}
                    />
                  </Grid>
                </Grid>
              );
            })}

            <FxLink onClick={handleAddInvoiceItem} className="fx-add-invoice-item-link">
              <Typography>+ Add Items</Typography>
            </FxLink>
            
          </Grid>

        </fieldset>
      </Grid>
    </>
  );
});
