/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Logger } from "../../libs/utils/logger";
import { getKey } from "../../libs/utils/storageManager";
import { IconButton } from '@mui/material';
import { ReactComponent as ReadmeIcons } from '../../assets/svg/readme-icon.svg';
import React from "react";


Logger.debug("ReadMeLink.tsx", "Portal Guide");
declare global {
    interface Window {
      README_CONFIG: object;
    }
  }

const readMeConfig: any = window.README_CONFIG;

/**
 * Component contains the Generic functions to manipulate the readMe icon with token
 */


/**
 * Function used to create readMe Token 
 */
const getReadMeTokenUrl = () =>{
       const readMeUrl= readMeConfig['url']
    return readMeUrl+ '?auth_token=' + getKey('readMeToken')
}

/**
 * Function used to display the Read Me icon
 */
export const ShowReadme = (props: any) => {
  const readMeIcon =  (
       <span><IconButton
                className="fx-profile-icon fx-readme"
                id="menu-top-readme-button"
                  onClick ={() => window.open(getReadMeTokenUrl(),'_blank')}
                title={'Portal Guide'}
                aria-label={'Portal Guide'}
                size="large"> <ReadmeIcons />
            </IconButton>
        </span> 
  );

  return readMeIcon;
}

export default ShowReadme;