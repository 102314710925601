/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { useHistory } from 'react-router-dom';
import { removeUnderScoreConvertTitleCase } from '../../../Utils/CommonBaseClass';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { addressFormatter, getEAOrAddressDetailsFromContact, getEditSchedulePath } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { ScheduleActions } from '../ScheduleActions';
import { SendDestinationInfo } from './SendDestinationInfo';
import { sourceDataTransformer } from '../../Schedules/ScheduleGlobalFunctions';
import { useIsMobileScreen } from '../../../../libs/utils/utils';
import { ShowTxnId } from './ShowTxtId';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { MerchantLocationCard } from '../../Merchants/MerchantLocationCard';
import { TaxDetailInfoCard } from './TaxDetailInfoCard';



Logger.debug("SendInfoCard.tsx", "Send  Details initializing")

const audit_details_section = [
    {
        label: 'Created',
        value: 'createdInfo'
    }
];

const audit_details_section_two = [
    {
        label: 'Last Updated',
        value: 'updatedInfo'
    }
];

const schedule_check_attributes_section_two = [
    {
        label: 'Transaction Date',
        value: 'scheduleDate'
    },
    {
        label: 'Delivery Mode',
        value: 'processingDetail.deliveryMode'
    }
];



const source_account_section = [
    {
        label: 'Account ID',
        value: 'id'
    }
];

const source_account_section_two = [
    {
        label: 'Account',
        value: 'accountInfo'
    }
];



const schedule_virtual_card_attributes_section_two = [
{
    label: 'Transaction Date',
    value: 'scheduleDate',
    type: 'fri://fieldtype/date'
},
{
    label: 'Status Date',
    value: 'statusDate',
    type: 'fri://fieldtype/date'
}];




/**
 * This component handles the view of the send details
 */
export const SendInfoCard: React.FC<any> = React.memo(

    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        let destinationInfo: any = props?.destinationInfo?.data?.body;
        const sourceDetails: any = props?.sourceInfo?.data?.body
        let location_source: any;

        const history = useHistory<any>()

        const scheduleId = props?.data?.id;
        const scheduleInfo = props?.data;
       
        
        const src: any = {
            url: `transaction/id/${props?.data?.feeAgainstTransaction?.id}`,
            method: "GET",
        }

      /**
       * right column data for TRANSACTION DETAILS for card method
       */
      const schedule_info_external_card_right_section = [
        // to show the batch number and batch id for card method if it is not a refund transaction
          ...(scheduleInfo?.type !== 'REFUND' ? [{
          label: 'Batch Number',
          value: 'processingDetail.batchNumber'
        },
        {
          label: 'Batch ID',
          value: 'batchId'
        }] : []),
        // to show the statement descriptor for card method if it is not a refund transaction and statement descriptor is enabled
        ...(scheduleInfo?.type !== 'REFUND' && uiFeat.getTransactionFeatures().statementDescriptor ? [{
          label: 'Statement Descriptor',
          value: 'processingDetail.statementDescriptor'
        }] : [])
      ];


        const schedule_attributes_section = [
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            },
            {
                label: 'Auth Type',
                value: 'processingDetail.authType'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'

            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];

        const schedule_wire_attributes_section = [
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];

        const schedule_check_attributes_section = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
        
        ];

        const schedule_virtual_card_attributes_section = [
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
        
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];

        const schedule_internationalWire_attributes_section = [
            {
                label: 'Transaction ID',
                value: 'id'
            },
            {
                label: businessCustomer ? 'Purpose' : 'Memo',
                value: 'purpose'
            },
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
        ];
        
        if(businessCustomer){
            if(!scheduleInfo?.isTaxPayment){
                schedule_wire_attributes_section.push({
                    label: 'Memo',
                    value: 'processingDetail.memo'
                });
            }
        
            schedule_check_attributes_section.push({
                label: 'Memo',
                value: 'processingDetail.memo'
            })
        }

        /**
        * left column data for TRANSACTION DETAILS for card method and REFUND Type
        */
        const schedule_info_external_card_left_section = useMemo(() => {
            const section_one = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            },
            {
                label: 'Purpose',
                value: 'purpose'
            }
        ]
        
        const parentNode = scheduleInfo?.processingDetail?.parent;
        if (parentNode && parentNode.id) {
            section_one.unshift({
                label: 'Parent Transaction ID',
                value: 'processingDetail.parent.id'
            })
        }
        return section_one
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [scheduleInfo?.processingDetail?.parent])

        if (scheduleInfo) {
            if (scheduleInfo?.processingDetail?.processingMode) {
                scheduleInfo.processingMode = scheduleInfo.processingDetail.processingMode === 'FORWARD' ? 'Next Day' : 'Same Day';
            }
            if(scheduleInfo?.processingDetail?.parent?.id){
                scheduleInfo.parentTxnId=scheduleInfo?.processingDetail?.parent?.id ? `Collect (ID ${scheduleInfo?.processingDetail?.parent?.id})` : '';
            }
        }

        //added for contact ea and address changes
        if (destinationInfo?.resourceName === 'contact') {
            const id = scheduleInfo?.method !== 'CHECK' ? scheduleInfo?.destination?.contact?.externalAccount?.id : scheduleInfo?.destination?.contact?.mailingAddress?.id;

            if(scheduleInfo?.method === 'INTERNATIONAL_WIRE'){
                const destinations = getEAOrAddressDetailsFromContact(destinationInfo, "EIA", scheduleInfo?.destination?.contact?.internationalExternalAccount?.id, false);
                destinationInfo = { ...destinationInfo }
                destinationInfo.internationalExternalAccount = destinations;
            }
            if (id) {
                const destination = scheduleInfo?.method !== 'CHECK' ? getEAOrAddressDetailsFromContact(destinationInfo, "EA", id, false) : getEAOrAddressDetailsFromContact(destinationInfo, "ADDRESS", id, false);
                if (scheduleInfo?.method !== 'CHECK') {
                    destinationInfo = { ...destinationInfo }
                    destinationInfo.externalAccount = destination;
                }
                else {
                    destinationInfo = { ...destinationInfo }
                    destinationInfo.mailingAddress = destination;
                }
            }
        }

        if(props?.data?.processingDetail?.location){
            const url = props?.data?.processingDetail?.location.url.split('/v1/')[1]
            location_source = {
                url: url,
                method: "GET",
                baseUrl:true
            }
        }


        /**
        * Method to display title based on collect type
        */
        function getTitle() {
            let title: any;
            const methodName = scheduleInfo?.method === 'VIRTUAL_CARD' ? 'VIRTUAL CARD' : scheduleInfo?.method === 'INTERNATIONAL_WIRE' ? 'INTERNATIONAL WIRE' : scheduleInfo?.method;

            if (scheduleInfo?.method === 'CHECK') {
                if (scheduleInfo?.destination?.address?.url) {
                    title = {
                        header: `Send to ${destinationInfo !== undefined ? addressFormatter("", destinationInfo && destinationInfo) : ''}`,
                        method: methodName
                    };
                }
                else if (scheduleInfo?.destination?.address?.addressLine1) {
                    title = {
                        header: `Send to ${addressFormatter("", scheduleInfo?.destination?.address)}`,
                        method: methodName
                    };
                }
                else if (scheduleInfo?.destination?.payeeName) {
                    title = {
                        header: `Send to ${scheduleInfo.destination.payeeName}`,
                        method: methodName
                    };
                }
            }
            if (scheduleInfo?.method === 'ACH' || scheduleInfo?.method === 'WIRE') {
                if (props?.data?.destination?.externalAccount?.holderName) {
                    title = {
                        header: `Send to ${props?.data?.destination?.externalAccount?.holderName}`,
                        method: methodName
                    };
                }
                if (destinationInfo) {
                    title = {
                        header: `Send to ${destinationInfo?.holderName}`,
                        method: methodName
                    };
                }
            }
            if (scheduleInfo?.method === 'INTERNATIONAL_WIRE') {
                if (props?.data?.destination?.internationalExternalAccount?.holderName) {
                    title = {
                        header: `Send to ${props?.data?.destination?.internationalExternalAccount?.holderName}`,
                        method: methodName
                    };
                }
                if (destinationInfo) {
                    title = {
                        header: `Send to ${destinationInfo?.holderName}`,
                        method: methodName
                    };
                }
            }
            if (destinationInfo?.resourceName === 'contact') {
                title = {
                    header: `Send to ${destinationInfo?.name}`,
                    method: methodName
                };
            }
            if (scheduleInfo?.method === 'BOOK') {
                if (scheduleInfo?.destination?.contact?.ppi) {
                    title = {
                        header: `Send to ${scheduleInfo?.destination?.contact?.resourceName}`,
                        method: methodName
                    };
                } else if (scheduleInfo?.feeAgainstTransaction) {
                    title = {
                        header: scheduleInfo?.purpose?.endsWith("Fee") ? `${scheduleInfo?.purpose}` : `${scheduleInfo?.purpose} Fee`,
                        method: methodName
                    };
                } else {
                    if (destinationInfo?.nickName) {
                        title = {
                            header: `Send to ${destinationInfo?.nickName || destinationInfo?.resourceName}`,
                            method: methodName
                        };
                    }
                }
            }
            if (scheduleInfo?.destination?.ppi) {
                title = {
                    header: `Send to ${scheduleInfo?.destination?.ppi}`,
                    method: methodName
                };
            }
            if ((scheduleInfo?.method === 'CARD' && scheduleInfo.type === 'REFUND' && !scheduleInfo?.destination)) {
                title = {
                    header: 'Send to External',
                    method: methodName
                };
            }
            if ((scheduleInfo?.method === 'CARD' && scheduleInfo.type === 'REFUND' && scheduleInfo?.destination && !scheduleInfo?.destination?.terminal)) {
                title = {
                    header: 'Send to External',
                    method: methodName
                };
            }
            if ((scheduleInfo?.method === 'CARD' && scheduleInfo.type === 'REFUND' && scheduleInfo?.destination?.terminal)) {
                title = {
                    header: 'Send',
                    method: methodName
                };
            }
            if (scheduleInfo?.method === 'VIRTUAL_CARD') {
                title = {
                    header: `Send to ${destinationInfo?.name || scheduleInfo?.destination?.name || scheduleInfo?.destination?.email}`,
                    method: methodName
                };
            }

            return title
        }

        /**
         * Method to return Virtual Card and International wire Info
         */
        const dataTransformerForCardInfo = (data: any) => {
            const newData = { ...data };
            newData.method = newData?.method === 'VIRTUAL_CARD' ? 'VIRTUAL CARD' : newData?.method === 'INTERNATIONAL_WIRE' ? 'INTERNATIONAL WIRE' : newData.method;

            return newData;
        }

        /**
         * This method is used to transform purpose of transaction.
        */
        const dataTransformerForPurpose = (data: any) => {
            const newData = { ...data };
            newData.batchId = data?.processingDetail?.batch?.id;
            newData.purpose = ((newData?.type === 'EXTERNAL' || newData?.type === 'REFUND') && newData?.source?.account?.id) ? ('REFUND - Ref: ' + newData.purpose) : newData.purpose
            return newData;
        }

        /**
         * This method is used to transform tax related nodes of transaction.
        */
        const dataTransformerForTaxNodes = (data: any) => {
            const newData = { ...data };
            newData.isTaxPayment = data?.isTaxPayment ? 'Yes' : 'No'
            return newData;
        }

        /**
         * This method is used to section two column for wire based on type status
        */
        const schedule_wire_attributes_section_two = useMemo(() => {
            const section_two = [];

            const status = props?.data?.status;
            if (status === 'PROCESSING' || status === 'FAILED' || status === 'COMPLETED') {
                section_two.push({
                    label: 'Originator Name',
                    value: 'processingDetail.originator'
                })
            }
            if(props?.data?.isTaxPayment){
                section_two.push({
                    label: 'Tax Payment',
                    value: 'isTaxPayment' 
                })
            }
            return section_two;
        }, [props?.data?.status])

        /**
         * This method is used to section two column for internation wire based on type status
        */
        const schedule_internationalWire_attributes_section_two = useMemo(() => {
            const section_two = [
                {
                    label: 'Transaction Date',
                    value: 'scheduleDate',
                    type: 'fri://fieldtype/date'
                },
                {
                    label: 'Memo',
                    value: 'processingDetail.memo'
                }
            ];

            const status = props?.data?.status;
            if (status === 'PROCESSING' || status === 'FAILED' || status === 'COMPLETED') {
                section_two.push({
                    label: 'Originator Name',
                    value: 'processingDetail.originator'
                })
            }
            return section_two;
        }, [props?.data?.status])

        /**
         * Method to return TRANSACTION DETAILS based on type
         */
         const scheduleInfoSection = useMemo(()=> {
            const schedule_attributes_section_two = [
            {
                label: 'Processing Mode',
                value: props?.data?.destination?.contact?.ppi ? '' : 'processingMode',
            }
        ];

        if(businessCustomer){
            schedule_attributes_section_two.push({
                label: 'Memo',
                value: 'processingDetail.companyDescription'
            });
        }

        const schedule_book_attributes_section_two:any = [];

        const schedule_book_attributes_section:any = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'Status Reason',
                value: 'statusReason',
                valueTransformer: removeUnderScoreConvertTitleCase
            }
        ];

        let purposeField: Object = {
            label: businessCustomer ? 'Purpose' : 'Memo',
            value: 'purpose'
        }
        
             if (props?.data?.feeAgainstTransaction) {

                 purposeField = {
                     ...purposeField,
                     value: <>{props?.data?.purpose} <ShowTxnId source={src} id={'fee-transaction-schedule' + props?.data?.feeAgainstTransaction?.id} txnId={props?.data?.feeAgainstTransaction?.id} /></>,
                     url:'/transactions/send/'+props?.data?.feeAgainstTransaction?.id,
                     type: 'fri://fieldtype/custom',
                 }
             }
             const memoField : Object = {

                label: 'Memo',
                value: 'processingDetail.memo'
            }
            if(!props?.data?.feeAgainstTransaction && businessCustomer){
                schedule_book_attributes_section_two.push(memoField);
            }
    
        schedule_book_attributes_section_two.push(purposeField);
            if (props?.data && props.data.method === 'ACH') {
                // eslint-disable-next-line eqeqeq
                if (props.data?.processingDetail?.authType != 'PHONE' && businessCustomer) {
                    schedule_attributes_section_two.push({
                        label: 'Addenda',
                        value: 'processingDetail.addenda.0',
                    })
                }
                if (props?.data && props.data.type === 'REVERSAL') {
                    schedule_attributes_section_two.push({
                        label: 'Parent Transaction id',
                        value: 'parentTxnId',
                    })
                }

                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_attributes_section} rightcolumn={schedule_attributes_section_two} data={scheduleInfo} />)
            } else if (props.data && props.data.method === 'WIRE') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_wire_attributes_section} rightcolumn={schedule_wire_attributes_section_two} data={props.data} dataTransformer={dataTransformerForTaxNodes}/>)
            } else if (props.data && props.data.method === 'CHECK') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_check_attributes_section} rightcolumn={schedule_check_attributes_section_two} data={props.data} />)
            } else if (props.data && props.data.method === 'BOOK') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_book_attributes_section} rightcolumn={schedule_book_attributes_section_two} data={props.data} />)
            }else if (props?.data?.method === 'CARD') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_info_external_card_left_section} rightcolumn={schedule_info_external_card_right_section} dataTransformer={dataTransformerForPurpose} data={props.data}  />)
            }
            else if (props.data && props.data.method === 'INTERNATIONAL_WIRE') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_internationalWire_attributes_section} rightcolumn={schedule_internationalWire_attributes_section_two} dataTransformer={dataTransformerForCardInfo} data={props.data} />)
            }
            if (props?.data && props.data.method === 'VIRTUAL_CARD') {
                return (<FxInfoCard id={"send-details-card-destination-schedule-attributes-one-" + scheduleId + "-section-info-card-component"} title="TRANSACTION DETAILS" leftcolumn={schedule_virtual_card_attributes_section} rightcolumn={schedule_virtual_card_attributes_section_two} dataTransformer={dataTransformerForCardInfo} data={scheduleInfo}  />)
            }
            return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
        /**
         * Method handles the display of the source information based on various conditions
         * if the url is getting from the source data then the second section info card will be rendered else the
         * first section info card will be rendered
         * @returns : section info card component
         */
        function sourceData() {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: getTitle(), backButton : history?.location?.state?.backUrl ? history?.location?.state?.backUrl : "/transactions", url: history?.location?.state?.url, tab: 'TRANSACTION', jsonContent: true } } });
            return (<FxInfoCard id="section-info-card-send-source-infocard" title="SOURCE INFORMATION" leftcolumn={source_account_section} rightcolumn={source_account_section_two} dataTransformer={sourceDataTransformer} data={sourceDetails} />)
        }

        /**
         * Method handles the redirection to the edit page
         */
        function redirectToEditPage() {
            const path = getEditSchedulePath(props.data)
            history.push(path);
        }

        return (
            <Grid id="send-details-grid" item xs={12}>
                <FxCard id="send-details-card" className="fx-theme-passport">
                    <FxCardHeader id={'send-details-card-header'}>
                        <Grid id="send-details-card-header-grid" item container justifyContent="space-between" xs={12}  alignItems={'center'}>
                            <Grid id="send-details-card-header-second-grid">
                                {useIsMobileScreen()?<Grid className="fx-inline-flex" ><Typography id="send-details-card-title" variant="h3" >
                                Transaction ID:
                                </Typography><Typography id="send-details-card-title" variant="h3" >
                                {scheduleId}
                                </Typography></Grid>:<Typography id="send-details-card-title" variant="h3" >
                                Transaction ID: {scheduleId}
                                </Typography>}

                            </Grid>
                            {!scheduleInfo?.feeAgainstTransaction && <Grid id="collect-info-transaction-filter-grid" item container spacing={2} className="fx-action-block">
                                {(scheduleInfo?.type !== 'REVERSAL') && <Grid id="send-details-card-header-third-grid" item container justifyContent="space-between">
                                    <Grid item className="fx-schedule-action-buttons"><ScheduleActions id='send-details-card-schedule-actions-' schedule={scheduleInfo} type="send" redirect={redirectToEditPage} /></Grid>
                                </Grid>}
                            </Grid>}
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="send-details-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                            {scheduleInfoSection}
                            {sourceData()}
                            <SendDestinationInfo id='send-destination-details' destinationInfo={destinationInfo} scheduleInfo={scheduleInfo} />
                        </Grid>
                        {location_source && <Grid item xs={12}>
                            {/* display location information*/}
                            <MerchantLocationCard id={"send-details-card-location-information-" + scheduleId + "-section-info-card-component"} source={location_source} />
                        </Grid>}
                        {/** display the Tax Detail Information */}
                        {scheduleInfo?.method === 'WIRE' && scheduleInfo?.isTaxPayment && <Grid item xs={12}><TaxDetailInfoCard id={"wire-send-details-tax-wire-details-information-" + scheduleId + "-section-info-card-component"} data={scheduleInfo} /></Grid>}
                        <Grid item xs={12}><FxInfoCard id={"send-details-card-destination-audit-details-one-" + scheduleId + "-section-info-card-component"} title="AUDIT DETAILS" leftcolumn={audit_details_section} rightcolumn={audit_details_section_two} data={props.data} /></Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
