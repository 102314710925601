import React from 'react'
import { Grid, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as EnableCardIcon } from '../../../assets/svg/enable-physical-card-icon.svg';   
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { getKey } from '../../../libs/utils/storageManager';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("EnablePhysicalCard.tsx", "Enable Physical Card initializing")

/**
 * This component handles the Enabling of Physical Card
 */
export const EnablePhysicalCard: React.FC<any> = React.memo((props) => {
        const { handleSubmit, formState: { isSubmitting } } = useForm();
        
        const { debitCardId, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

        /**
         * Async function handles the form submission
         */
        async function onSubmit(data: any) {
            let status: any;
            
            status = await enablePhysicalCard();
            status = processAPIResponse(status);
            
            if(status.status){
                setTitle('Physical Card Enabled Successfully');
                setDescription('Your Request to Enable Physical Card is placed successfully.');
                setBtnText('Close');
                setSuccess(true);
            } else {
              //api  failed
              FxSnackBar.show({
                text: status.message,
              });
            }
            props.onClose();
        }

        /**
         * Method to call api for Activating Debit Card
         * @param payloadData : request payload
         */
        async function enablePhysicalCard() {
            try {
                const url =  '/account/id/' + getKey('selectedAccount')?.id + '/debitCard/id/' + debitCardId + '/enablePhysicalCard';
                const data: any = await httpClient.post(getCustomerUrl(url,false))
                return data;
            } catch (err) {
                Logger.error("EnablePhysicalCard.tsx", "error", err);
                return err;
            }
        }

        return (
            <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FxCard className='fx-digital-first-card-modal'>
                        <FxCardHeader id="enable-physical-card-modal-header" className="fx-card-header fx-modal-header">
                            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                                <Grid item container xs={12} justifyContent="center">
                                    <EnableCardIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography id="enable-physical-card-modal-title" align="center" className="fx-enable-physical-card-modal-title" >
                                        Enable Physical Card
                                    </Typography>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="enable-physical-card-modal-body" className="fx-info-card">
                            <Grid container item xs={12} className="fx-modal-form flex column" >
                                <Typography id="enable-physical-card-modal-subtext" variant="h5" textAlign={"center"} paddingBottom='0.75rem'>
                                    By taking this action, you're confirming that you have received the physical card and want to activate it. Once activation is completed, both the physical and digital cards can be used for transactions.
                                </Typography>
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="enable-physical-card-modal-Footer" className="fx-footer">
                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                <Button
                                    variant="contained"
                                    className="fx-button fx-button-cancel"
                                    id="cancel-button"
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                                <span className="fx-padding-right-16" />
                                <FxButton
                                    disableRipple={false}
                                    className="fx-button fx-button-theme"
                                    id={"enable-physical-card-button"}
                                    type="submit"
                                    isSubmitting={isSubmitting}
                                >
                                    SUBMIT
                                </FxButton>
                            </Grid>
                        </FxCardFooter>
                    </FxCard>
                </form>
            </Grid>
        );
    });
