import React, { useCallback, useEffect, useMemo } from 'react';
import FxCard from '../../Container/FxCard';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FxCardHeader from '../../Container/FxCardHeader';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { getCustomerUrl, useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import moment from 'moment';
import { DashboardSkeleton } from '../../Page/Index/Passport/DashboardSkeleton';
import { HomeTreasureListCard } from '../Treasure/HomeTreasureListCard';
import { QuickAccountTransfer } from '../../Page/Index/Passport/QuickTransfer/QuickAccountTransfer';
import { DepositPlusAccountInfoCard } from './DepositPlusAccountInfoCard';
import { Logger } from '../../../libs/utils/logger';
import { DepositPlusTransactionSuccessCard } from './DepositPlusTransactionSuccessCard';
const httpClient = HttpClient.getClient()

Logger.debug("FxDepositPlusAccountDashboard.tsx", "FxDepositPlusAccountDashboard initializing");

/**
 * Component for Cash Builder+ Account Dashboard
 */
export const FxDepositPlusAccountDashboard: React.FC<any> = (props) => {
    
    ({ props } = RegisterComponent(props)); 

    const dispatch = useDispatch()
    const isSmallScreen = useIsMobileOrTabletScreen();


    /** 
    * to get accountList details
    */
    const accountListInfo = useSelector((state: any) => state?.data['account-list-sub-menu'] || null);
    const selectedAccount = accountListInfo?.params?.selectedOption;

    /**
    * useEffect to set default address 
    */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        updateComponentParams(dispatch, props?.id, { selectedAccount:null});       
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Dashboard' } } });
        updateComponentParams(dispatch,props.id,{'showQuickTransfer': true, 'isWithdrawal': false})
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    /**
     * Method helps in identifying that whether it is a withdrawal or not
     */
    const checkIsWithdrawal = (isWithdrawal: boolean) => {
        updateComponentParams(dispatch,props.id,{'isWithdrawal': isWithdrawal, 'destinationAccountData': undefined, 'showWithdrawalNote': false})
    }

    /**
     * Function sets the destination account in the state
     */
    const setDestinationAccountData = (accountData: any) => {
        updateComponentParams(dispatch,props.id,{'destinationAccountData': accountData})

        if(props?.isWithdrawal && (accountData !== undefined)){
            updateComponentParams(dispatch,props.id,{'showWithdrawalNote': true})
        }else{
            updateComponentParams(dispatch,props.id,{'showWithdrawalNote': false})
        }
    }

    /**
    * useEffect to set default address 
    */
    useEffect(() => {
        if(selectedAccount){
            getAccountDetails(selectedAccount.id);
            getLedgerList(selectedAccount.id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'home-ledger-list', 'account': selectedAccount.id } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]) 

    const ledger_request = {
        pageNumber: 1,
        pageSize: 25,
        sortOptions: {
          "sortOrder": "desc",
          "sortBy": "lastUpdatedOn"
        },
        criteria: {}
    }

    /**
    * function to get account data and storage quick settle configuration
    * @param id :account id
    */
    async function getAccountDetails(id: number) {
        try {
            const res = await httpClient.get(getCustomerUrl("account/id/" + id));                  
            dispatch({ type: "DATA_UPDATE_COMPONENT", payload: { id: "deposit-plus-account-balance-card", data: res.data } });
        } catch (e) {
            Logger.error("FxDepositPlusAccountDashboard.tsx",e);
        }
    }  
    
    /**
    * function to get account data and storage quick settle configuration
    * @param id :account id
    */
    async function getLedgerList(id: number) {
        try {
            const filters: any = [];
            filters.push(
                {
                  "operator": "gte",
                  "key": "lastUpdatedOn",
                  "values": [
                    moment().subtract(1, 'months').format('MM/DD/YYYY')
                  ]
                },
                {
                  "operator": "lte",
                  "key": "lastUpdatedOn",
                  "values": [
                    moment().format('MM/DD/YYYY')
                  ]
                },
                {
                    "operator": "eq",
                    "key": "customView",
                    "values": [ "true" ]
                },
                {
                  "operator": "eq",
                  "key": "account.id",
                  "values": [
                    getKey("selectedAccount")?.id
                  ]
                },
                {
                    "operator": "eq",
                    "key": "isRealTime",
                    "values": [
                        "true"
                    ]
                })
              ledger_request.criteria = { filters }

            const ledger_list = await httpClient.post(getCustomerUrl("account/id/" + id + '/ledger/list', false), ledger_request);
            updateComponentParams(dispatch, "customer-basic-info", { 'ledgerList': ledger_list?.data })           
        } catch (e) { }
    }  
    
    const onTransferAmountFinishedHandle = useCallback(() => {
        if(selectedAccount){
            getAccountDetails(selectedAccount.id);
            getLedgerList(selectedAccount.id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'home-ledger-list', 'account': selectedAccount.id } });
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [selectedAccount]);    
    
    const accountActivationInProgress = useMemo(() => {
        if (selectedAccount && selectedAccount.status === 'INACTIVE') {
            return <Grid item xs={12}>
            <FxCard className='fx-theme-passport'>
                <FxCardHeader>
                    <Grid container direction="row" xs={12}   >
                        <Grid item xs={12}>
                            <Grid item container >                                   
                                <Grid item xs container justifyContent="flex-start">
                                    <Grid item xs ={12} >
                                        <Typography variant='h3'>Account Activation in Progress!</Typography>
                                    </Grid>
                                    <Grid item xs={12} > 
                                        <Typography variant='h6'>It may take few minutes to complete the activation. You will receive an email once activation is successful.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>                           
                </FxCardHeader>
            </FxCard>
        </Grid>
        }

        return null;

    }, [selectedAccount])  

    /**
     * Method to handle close of Success Card
     */
    const handleClose = () => {
        updateComponentParams(dispatch,props.id,{'showQuickTransfer': true, 'isWithdrawal': false})
    }

    return (
        <>
            {!!props?.showSkeleton ? <DashboardSkeleton/> : 
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className={"fx-passport-home fx-container"} >
                {accountActivationInProgress}
                <Grid container className="fx-container-left" >
                    <Grid container xs={12} >
                        <Grid item xs={12}>
                            <DepositPlusAccountInfoCard id="deposit-plus-account-balance-card" />
                        </Grid>
                        <Grid item xs={12} >
                            <HomeTreasureListCard id={'home-deposit-plus-recent-transaction-list-card'} />
                        </Grid>
                        {isSmallScreen && <Grid item xs={12} >
                            {props?.showQuickTransfer ? 
                                <QuickAccountTransfer 
                                    id="deposit-plus-quick-transfer-card" 
                                    onTransferAmountFinishedHandle={onTransferAmountFinishedHandle} 
                                    checkIsWithdrawal={checkIsWithdrawal}
                                    isWithdrawal={props?.isWithdrawal}
                                    setDestinationAccountData={setDestinationAccountData}
                                    showWithdrawalNote={props?.showWithdrawalNote}
                                    destinationAccountData={props?.destinationAccountData}
                                /> : 
                                <DepositPlusTransactionSuccessCard 
                                    id='deposit-plus-transaction-success-card' 
                                    headerText='Amount' 
                                    amount={props?.amount}
                                    title='Transfer Successful!' 
                                    description={props?.description}
                                    handleOnClose={handleClose}
                                />
                            }
                        </Grid>}
                    </Grid>
                </Grid>
                {!isSmallScreen && <Grid container className="fx-container-right">
                    <Grid container xs={12} >
                        <Grid item xs={12} >
                            {props?.showQuickTransfer ? 
                                <QuickAccountTransfer 
                                    id="deposit-plus-quick-transfer-card" 
                                    onTransferAmountFinishedHandle={onTransferAmountFinishedHandle}
                                    checkIsWithdrawal={checkIsWithdrawal}
                                    isWithdrawal={props?.isWithdrawal}
                                    setDestinationAccountData={setDestinationAccountData}
                                    showWithdrawalNote={props?.showWithdrawalNote}
                                    destinationAccountData={props?.destinationAccountData}
                                /> : 
                                <DepositPlusTransactionSuccessCard 
                                    id='deposit-plus-transaction-success-card' 
                                    headerText='Amount' 
                                    amount={props?.amount}
                                    title={props?.isWithdrawal ? 'Transfer Initiated!' : 'Transfer Successful!'} 
                                    description={props?.description}
                                    handleOnClose={handleClose}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>}
        </>
    )
}
