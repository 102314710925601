/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import { FxButton } from '../../../Action/FxButton';
import FxCardBody from '../../../Container/FxCardBody';
import { FxInfoCard } from '../../Cards/FxInfoCard';
import { ReactComponent as EditIcon } from '../../../../assets/svg/edit-new.svg';
import { ReactComponent as ViewEventDetailIcon } from '../../../../assets/svg/eye-visibility-on.svg';
import { ReactComponent as DisableIcon } from '../../../../assets/svg/stop.svg';
import { ReactComponent as EnableIcon } from '../../../../assets/svg/check-verify.svg';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { toTitleCase, updateComponentParams } from '../../../Utils/CommonBaseClass';
import FxModalGeneric from '../../../Utils/FxModalGeneric';
import { useHistory } from 'react-router';
import moment from 'moment';
import { DataGrid } from '../../../Data/DataGrid';
import { IFilterColumns } from '../../../../types/common.interfaces';
import { FxSuccessCard } from '../../DebitCard/FxSuccessCard';
import { EnableWebhook } from './Actions/EnableWebhook';
import { DisableWebhook } from './Actions/DisableWebhook';
import { FxSkeltonList } from "../../Cards/FxSkelton";

/**
 * Component: Webhook Detail
 * Usage: To display details of Webhook
 */

interface IWebhookDetail {
  id: string;
  source: any;
  eventList: any;
  setSuccessCardOpen?: boolean;
  setTitle?: string;
  setDescription?: string;
  setButtonText?: string;
  enableWebhookModalOpen?: boolean;
  disableWebhookModalOpen?: boolean;
}

export const WebhookDetail: React.FC<IWebhookDetail> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory();
    let webhookDetailData:any = {}; 

    useEffect(()=>{
      updateComponentParams(dispatch, props?.id, { setSuccessCardOpen: false, setTitle: '', setDescription: '',  setButtonText: ''})
    },[dispatch, props.id])

    /**
     * setting the GET Webhook API data
     */
  if (context?.data?.body) {
    webhookDetailData = {...context?.data?.body}
    webhookDetailData['createdInfo'] = webhookDetailData['createdBy']['username'] + '\nOn ' + moment(webhookDetailData['createdOn']).format('MM/DD/YYYY');
    webhookDetailData['updatedInfo'] = webhookDetailData['lastUpdatedBy']['username'] + '\nOn ' + moment(webhookDetailData['lastUpdatedOn']).format('MM/DD/YYYY');
  }

    useEffect(()=>{
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Webhook Info', backButton: "/webhooks" } } });
    },[dispatch])

  /**
   * Webhook Basic Info - Left Section Columns
   */
  const webhook_detail_left_section = [
    {
      label: 'ID',
      value: 'id',
    },
    {
        label: 'Name',
        value: 'name',
    },
    {
      label: 'URL',
      value: 'url',
    }
  ];

  /**
   * Webhook Basic Info - Right Section Columns
   */
  const webhook_detail_right_section = [
   
    {
      label: 'Description',
      value: 'description',
    },
    {
      label: 'Status',
      value: 'status',
      type: 'fri://fieldtype/status'
    },
    {
      label: 'Status Reason',
      value: 'statusReason',
    }
  ];

  /**
   * Webhook Detail Audit Info - Left Section Columns
   */
  const audit_field_left_section = [
    {
      label: 'Created',
      value: 'createdInfo',
    }
  ];

  /**
   * Webhook Detail Audit Info - Right Section Columns
   */
  const audit_field_right_section = [
    {
      label: 'Last Updated',
      value: 'updatedInfo',
    }
  ];

  /**
   * Method to handle the opening of the Enable Webhook Modal
   */
  const handleEnableWebhookAction = () => {
    updateComponentParams(dispatch, props?.id, { enableWebhookModalOpen: true });
  }

  /**
   * Method to handle the opening of the Disable Webhook Modal
   */
  const handleDisableWebhookAction = () => {
    updateComponentParams(dispatch, props?.id, { disableWebhookModalOpen: true });
  }

  /**
   * Method to handle the closing of the Enable Webhook Modal
   */
  const handleEnableWebhookModalClose = () => {
    updateComponentParams(dispatch, props?.id, { enableWebhookModalOpen: false });
  }

  /**
   * Method to handle the closing of the Disable Webhook Modal
   */
  const handleDisableWebhookModalClose = () => {
    updateComponentParams(dispatch, props?.id, { disableWebhookModalOpen: false });
  }

  /**
   * Method to redirect to the Event Detail Listing
   */
  const handleViewEventDetailAction = () => {
    history.push(`/webhook/eventDetail/list`, { webhook: { id: context?.data?.body?.id, eventList: props?.eventList } });
  }

  /**
   *  Subscribed Events columns
   * */
  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "Entity",
        enable: true,
        fieldName: "entity",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "15rem" },
      },
      {
        label: "Events",
        enable: true,
        fieldName: "events",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "12rem" },
      }
    ],
  };

  /**
   * Method sets the metadata for the FxSuccess Modal
   * @param setSuccessCardOpen 
   * @param setTitle 
   * @param setDescription 
   * @param setButtonText 
   */
  const setSuccessPopupConfigurations = (setSuccessCardOpen:any, setTitle: any, setDescription:any, setButtonText:any) => {
    updateComponentParams(dispatch, props?.id, { setSuccessCardOpen: setSuccessCardOpen, setTitle: setTitle, setDescription: setDescription,  setButtonText: setButtonText})
  }

  /**
   * Method calculates the total number of Events Subscribed, the total events available, and the entity corresponding to which the events were subscribed
   * @param dataArray 
   * @param eventArray 
   * @returns 
   */
  function getEntityAndEventCount(dataArray: any, eventArray:any) {
    const newArray = [];
    for (const item of dataArray) {
      const entityName = item.entity;
      const matchingEvents = item?.eventName?.filter((eventName:any) => eventArray?.includes(eventName)).length;
      const totalEvents = item.eventName.length;
      newArray.push({ entityName, matchingEvents, totalEvents });
    }
    return newArray;
  }

  const finalArray = getEntityAndEventCount(props?.eventList, webhookDetailData.subscribedEventType);

  let newData: any = [];

  /**
   * Creating the new transformed array from the finalArray to support the Component
   */
  newData = finalArray.map((item: any) => {
    return {
        entity: toTitleCase(item?.entityName),
        events: item?.matchingEvents+ '/' + item?.totalEvents
    }
  });

  /**
   * source data for the Webhook GET api
   */
  const webhook_source = {
    url: "webhookSubscription/id/" + webhookDetailData?.id,
    method: "GET",
  };

  /**
   * Method handles the actions to be done after the submission of the form, i.e navigate to the updated detail screen or go to the listing
   */
  const handlePostSubmissionActions = () => {
    if(props?.setButtonText === 'Go to Webhook Details'){
      updateComponentParams(dispatch,'webhook-detail-info-card-'+context?.data?.body?.id,{});
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: 'webhook-detail-info-card-'+context?.data?.body?.id, source: webhook_source},
      });
    }else{
      history.push('/webhooks');
    }
  }

  /**
   * Handle the success action of the FxSuccess Popup Modal
   */
  function handleSuccess(){
    updateComponentParams(dispatch, props?.id, { setSuccessCardOpen: false })
    handlePostSubmissionActions();
  }

  /**
   * Method handles the Webhook Basic Info Edit Action
   */
  const handleWebhookBasicInfoEditAction = () => {
    history.push('/webhook/edit/' + webhookDetailData?.id, { tag: 'basic_info' });
  }

  /**
   * Method handles the Webhook Event Related Info Edit Action
   */
  const handleWebhookEventInfoEditAction = () => {
    history.push('/webhook/edit/' + webhookDetailData?.id, { tag: 'event_info' });
  }

  /**
   * Permission Object for Webhook Add Button
   */
  const editWebhookPermission = {
    entity: "Other Entities",
    name: "Webhook",
    operation: "Edit"
  }

  /**
   * Permission Object for Webhook Enable Button
   */
  const enableWebhookPermission = {
    entity: "Other Entities",
    name: "Webhook",
    operation: "Enable"
  }

  return (
    <>
      {(!context?.data?.body) && <FxSkeltonList height="40rem" />}
      {(context?.data?.body) && <Grid container>
        <Grid item xs={12} sm={9}>
          <FxCard className="fx-theme-passport">
            <FxCardHeader id="webhook-detail-card-header">
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="h3">Webhook Info</Typography>
                <Grid>
                  {webhookDetailData?.status === 'BLOCKED' || webhookDetailData?.status === 'DISABLED' ? <FxButton id="webhook-detail-card-enable-button" title="Enable Webhook" permissions={enableWebhookPermission} className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EnableIcon/>} onClick={handleEnableWebhookAction}>
                    ENABLE WEBHOOK
                  </FxButton> :
                  <FxButton id="webhook-detail-card-disable-button" permissions={enableWebhookPermission} title="Disable Webhook" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<DisableIcon/>} onClick={handleDisableWebhookAction}>
                    DISABLE WEBHOOK
                  </FxButton>}
                  <span className='fx-padding-right-16'></span>
                  <FxButton id="webhook-detail-card-edit-button" title="Edit" permissions={editWebhookPermission} className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EditIcon/>} onClick={handleWebhookBasicInfoEditAction}>
                    EDIT
                  </FxButton>
                </Grid>
              </Grid>
            </FxCardHeader>
            <FxCardBody id="webhook-detail-card-Card-Body" className="fx-home-ledger-list">
              <Grid id={"webhook-detail"} item xs={12} marginBottom='1rem'>
                <FxInfoCard id="webhook-detail-card-section-info-card" title="BASIC INFO" leftcolumn={webhook_detail_left_section} rightcolumn={webhook_detail_right_section} data={webhookDetailData}></FxInfoCard>
              </Grid>

              <Grid id={"webhook-detail"} item xs={12}>
                <FxInfoCard id="webhook-detail-card-section-info-card-second" title="AUDIT INFO" leftcolumn={audit_field_left_section} rightcolumn={audit_field_right_section} data={webhookDetailData}></FxInfoCard>
              </Grid>
            </FxCardBody>
          </FxCard>
        </Grid>

        {props?.eventList && <Grid item xs={12} sm={9}>
          <FxCard className="fx-theme-passport">
            <FxCardHeader id="webhook-detail-card-header-second">
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Typography variant="h3">Event</Typography>
                <Grid>
                  <FxButton id="webhook-detail-card-view-event-details-button" title="Enable Webhook" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<ViewEventDetailIcon/>} onClick={handleViewEventDetailAction}>
                    VIEW EVENT DETAILS
                  </FxButton>
                  <span className='fx-padding-right-16'></span>
                  <FxButton id="webhook-detail-card-edit-event-details-button" title="Edit" permissions={editWebhookPermission} className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EditIcon/>} onClick={handleWebhookEventInfoEditAction}>
                    EDIT
                  </FxButton>
                </Grid>
              </Grid>
            </FxCardHeader>
            <FxCardBody id="webhook-detail-card-Card-Body-second" className="fx-home-ledger-list">
              <Grid id="webhook-detail-card-Card-Body-grid" item xs={12}>
                <DataGrid
                    id="webhook-details-card-event-table-card"
                    tableClass="fx-table-passport"
                    data={newData}
                    column={column}
                    pagination={false}
                    pageNumber={1}
                    pageSize={25}
                    resultPerPage={25}
                />
              </Grid> 
            </FxCardBody>
          </FxCard>
        </Grid>}

    
        {/** Enable Webhook Modal */}
        <FxModalGeneric
            id={"enable-webhooks-modal"}
            open={props?.enableWebhookModalOpen ?? false}
            onClose={handleEnableWebhookModalClose}
            className={'fx-modal-small2medium'}
            component={EnableWebhook}
            componentProps={{ webhookId: webhookDetailData?.id, setSuccessPopupConfigurations: setSuccessPopupConfigurations }}
        >
        </FxModalGeneric>

        {/** Disable Webhook Modal */}
        <FxModalGeneric
            id={"disable-webhooks-modal"}
            open={props?.disableWebhookModalOpen ?? false}
            onClose={handleDisableWebhookModalClose}
            className={'fx-modal-small2medium'}
            component={DisableWebhook}
            componentProps={{ webhookId: webhookDetailData?.id, setSuccessPopupConfigurations: setSuccessPopupConfigurations }}
        >
        </FxModalGeneric>

        {/**
         * Modal for Success Popup
         */}
        <FxModalGeneric
        id={"fx-success-card"}
        open={props?.setSuccessCardOpen ?? false}
        onClose={() => {
          updateComponentParams(dispatch, props?.id, { setSuccessCardOpen: false });
          handlePostSubmissionActions();
        }}
        componentProps={{ title: props?.setTitle, description: props?.setDescription, buttonText: props?.setButtonText, handleClick: handleSuccess }}
        className={'fx-modal-small2medium'}
        component={FxSuccessCard}
      >
      </FxModalGeneric>
      </Grid>}
    </>
  )
})
