import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment';
import { Logger } from '../../../../libs/utils/logger';
import { getKey } from '../../../../libs/utils/storageManager';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import { FxChart } from '../../../Input/FxChart/FxChart';
import { FxChartCard } from './FxChartCard';
import HttpClient from '../../../../libs/utils/httpClient';
const httpClient = HttpClient.getClient()


/**
 * This component handles the display of total interest received via chart
 */
export const TotalInterestReceivedChart: React.FC<any> = React.memo(({account}) => {

  const [interestHistoryData, setInterestHistoryData] = useState<any>({});
  const [chartVariation, setChartVariation] = useState('1M');

  /**
   * function to get interest history
   */
  async function getInterestHistory(date: string) {
    try {
      // Initialize startDate with the provided date
      // startDate is used to fetch data from the date of account activation (if it's after the provided date) or from the provided date itself.
      let startDate = date;
      const activationDate = getKey("selectedAccount")?.activationDate;
      // Check if activationDate exists and is after the provided date
      if (activationDate && moment(activationDate).isAfter(moment(date))) {
        // If so, set startDate to the formatted version of activationDate
        startDate = moment(activationDate).format('MM/DD/YYYY');
      }

      const reqBody = {
        pageNumber: 1,
        pageSize: 25,
        sortOptions: {
          'sortOrder': 'asc',
          'sortBy': 'createdOn'
        },
        criteria: {
          filters: [
            {
              "operator": "gte",
              "key": "processDate",
              "values": [startDate]
            },
            {
              "operator": "lte",
              "key": "processDate",
              "values": [moment().format('MM/DD/YYYY')]
            }
          ]
        }
      }
      const res = await httpClient.post(getCustomerUrl(`account/id/${account?.id}/interestHistory/list`, false), reqBody);
      setInterestHistoryData(res?.data);
    }
    catch (err) {
      Logger.error("TotalInterestReceivedChart.tsx", "error", err);
      return err;
    }
  }

  /**
   * useEffect to get interest history
   */
  useEffect(() => {
    if(account?.id){
      getInterestHistory(dates['1M']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[account?.id]);

  /**
   * dates for chart
   */
  const dates = {
    '1M':moment().subtract(1, 'months').format('MM/DD/YYYY'),
    '3M':moment().subtract(3, 'months').format('MM/DD/YYYY'),
    '6M':moment().subtract(6, 'months').format('MM/DD/YYYY'),
    '1Y':moment(new Date().setDate(new Date().getDate() - 364)).format('MM/DD/YYYY')
  }

  /**
   * buttons for chart
   */
  const buttons: any = [
    {
      text: '1M'
    },
    {
      text: '3M'
    },
    {
      text: '6M'
    },
    {
      text: '1Y'
    }
  ]

  const permission = {
    entity: "Accounts",
    name: "Ledger",
    operation: "view"
  }

  /**
   * chart data based on interest history
   */
  const chartData = useMemo(() => {
    return interestHistoryData.resources?.map((item: any) => {
      return {
        amount: item.interestAmount,
        date: item.processDate
      }
    }) || [];
    }, [interestHistoryData]);


  /**
   * button click function
   */
  function onButtonClick(data: any) {
    const date = dates[data.text as keyof typeof dates];
    setChartVariation(data.text);
    getInterestHistory(date);
  }

  return (
    <FxChartCard permission={permission} title={'Total Interest Received'} onButtonClick={onButtonClick} buttons={buttons} chartVariation={chartVariation}>
      <FxChart id="home-my-account-balance-chart" showXAxisTicks={true} chartVariation={chartVariation}
               data={chartData} className={'fx-account-insight-graph-dashboard'} isMonthly={true} />
    </FxChartCard>
  )
})