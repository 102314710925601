import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FiSend as SendIcon } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { ReactComponent as ManageIcon } from '../../assets/svg/manage-icon.svg';
import { ReactComponent as CollectIcon } from '../../assets/svg/collect-money-icon.svg';
import { getKey } from '../../libs/utils/storageManager';
import { FxLink } from '../Action/FxLink';
import UIFeatureImpl from '../../libs/services/uiFeatures';

export const TopBarActions: React.FC<any> = React.memo((props) => {
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  
  const closeMenu = () => {
    props.handleClose()
  }

  let _is_wallet_enabled : boolean = false;

  const walletData = useSelector((state: any) => {
    if (state.data['id-isWalletEnabled']) {
        return state.data['id-isWalletEnabled']
    } else {
        return { config: { params: {} } };
    }
});

_is_wallet_enabled = walletData && walletData['data'] ? walletData['data'] : false;

  return (
    <Grid container className="fx-container-top-move-money" xs={12} spacing={1} alignItems ="flex-start" >
      <Box className='fx-dropdown-shape' />
      {businessCustomer ? <>
        <Grid item container sm={4} xs={12} className="fx-move-money-left">
          <Grid item container xs={12}>
            <Grid item container xs={12}>
              <Grid item className="fx-icon-move-money"><CollectIcon /></Grid>
              <Grid item><FxLink onClick={closeMenu} redirect="/collect"><Typography variant="h1" >Collect</Typography></FxLink></Grid>
            </Grid>
            <ul>
              <li> <FxLink onClick={closeMenu} redirect="/transaction/collect/add/my-account-ach" id="topbar-collect-from-my-account-link">From My Account</FxLink></li>
              {getKey('merchantActiveStatus')==='true' && <li> <FxLink onClick={closeMenu} redirect="/transaction/collect/add/onetime-card" id="topbar-collect-money-onetime-card-link">From Others</FxLink></li>}
            </ul>
          </Grid>
        </Grid>
        <Grid item container sm={4} xs={12} className="fx-move-money-middle" >
          <Grid item container xs={12}>
            <Grid item className="fx-icon-move-money"><SendIcon /></Grid>
            <Grid item><FxLink onClick={closeMenu} redirect="/send"><Typography variant="h1" >Send Money to</Typography></FxLink></Grid>
          </Grid>
          <Grid item xs={7}>
            <ul>
              <li><FxLink onClick={closeMenu} redirect="/transaction/send/add/ach" id="topbar-send-money-my-account-link">My Account</FxLink></li>
              <li><FxLink onClick={closeMenu} redirect="/transaction/send/onetime/add/ach" id="topbar-send-money-to-one-time-link">One Time Send</FxLink></li>
             {_is_wallet_enabled && <li><FxLink onClick={closeMenu} redirect="/transaction/send/wallet/add/transfer" id="topbar-send-money-to-wallet-link">Shared Wallets</FxLink></li> } 
              <li><FxLink onClick={closeMenu} redirect="/transaction/send/contact/add/ach" id="topbar-send-money-to-payee-link">To Contact</FxLink></li>
            </ul>
          </Grid>
        </Grid>
        <Grid item container sm={4} xs={12} className="fx-move-money-right">
          <Grid item container xs={12}>
            <Grid item container xs={12}>
              <Grid item><Grid className="fx-icon-move-money"><ManageIcon /></Grid></Grid>
              <Grid item><Typography variant="h1" >Manage</Typography></Grid>
            </Grid>
            <ul>
              <li><FxLink onClick={closeMenu} redirect="/externalaccounts" id="topbar-manage-external-accounts-link">My External Accounts</FxLink></li>
              <li><FxLink onClick={closeMenu} redirect="/mailingaddresses" id="topbar-manage-mailing-address-link">My Mailing Addresses </FxLink></li>
              {_is_wallet_enabled && <li><FxLink onClick={closeMenu} redirect="/wallet/mine" id="topbar-manage-wallet-link">Manage Your Wallet </FxLink></li>}
             <li><FxLink onClick={closeMenu} redirect="/payees" id="topbar-manage-payee-link">Manage Contact </FxLink></li>
              {/* <li><FxLink onClick={closeMenu} redirect="/cards" id="topbar-my-cards-link">My Cards</FxLink></li> */}
            </ul>
          </Grid>
        </Grid>
      </>
        : <>
        <Grid item container sm={4} xs={12} className="fx-move-money-left">
          <Grid item container xs={12}>
          </Grid>
        </Grid>
          <Grid item container sm={4} xs={12} className="fx-move-money-middle" >
            <Grid item container xs={12}>
              <Grid item className="fx-icon-move-money"><SendIcon /></Grid>
              <Grid item><FxLink onClick={closeMenu} redirect="/send"><Typography variant="h1" >Send Money to</Typography></FxLink></Grid>
            </Grid>
            <Grid item xs={7}>
              <ul>
                <li><FxLink onClick={closeMenu} redirect="/transaction/send/add/ach" id="topbar-send-money-my-account-link">My Account</FxLink></li>
                <li><FxLink onClick={closeMenu} redirect="/transaction/send/onetime/add/ach" id="topbar-send-money-to-one-time-link">One Time Send</FxLink></li>
                {_is_wallet_enabled && <li><FxLink onClick={closeMenu} redirect="/transaction/send/wallet/add/transfer" id="topbar-send-money-to-wallet-link">Shared Wallets</FxLink></li>}
                <li><FxLink onClick={closeMenu} redirect="/transaction/send/contact/add/ach" id="topbar-send-money-to-payee-link">To Contact</FxLink></li>
              </ul>
            </Grid>
          </Grid>
          <Grid item container sm={4} xs={12} className="fx-move-money-middle">
            <Grid item container xs={12}>
              <Grid item container xs={12}>
                <Grid item><Grid className="fx-icon-move-money"><ManageIcon /></Grid></Grid>
                <Grid item><Typography variant="h1" >Manage</Typography></Grid>
              </Grid>
              <ul>
                <li><FxLink onClick={closeMenu} redirect="/externalaccounts" id="topbar-manage-external-accounts-link">My External Accounts</FxLink></li>
                <li><FxLink onClick={closeMenu} redirect="/mailingaddresses" id="topbar-manage-mailing-address-link">My Mailing Addresses </FxLink></li>
                {_is_wallet_enabled && <li><FxLink onClick={closeMenu} redirect="/wallet/mine" id="topbar-manage-wallet-link">Manage Your Wallet </FxLink></li>}
              <li><FxLink onClick={closeMenu} redirect="/payees" id="topbar-manage-payee-link">Manage Contact </FxLink></li>
              </ul>
            </Grid>
          </Grid>
        </>}
    </Grid>
  )
})