import React, { useEffect, useState } from 'react';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../libs/utils/utils';
const httpClient = HttpClient.getClient();

/**
 * Component used for showing international routing code label based on swiftcode
 */
Logger.debug("FxRoutingCodeLabel.tsx", "Fx Routing Code Label View initializing");

interface FxRoutingCodeLabelProps {
    defaultValue?: string;
}

export const FxRoutingCodeLabel: React.FC<FxRoutingCodeLabelProps> = ({ defaultValue }) => {
    const [label, setLabel] = useState('');

    /**
     * use effect to fetch international routing code label based on default value
     */
    useEffect(() => {
        if(defaultValue){
        const url = "/internationalExternalAccount/swiftCode/" + defaultValue + "/detail";
        getInternationalRoutingCodeLabel(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    /**
     * Method to call api  for international routing code details
     * @param url : request url
     * @param base : whether to include base url or not
     * @returns res : api response
     */
    async function getSwiftCodeDetails(url: any, base = false) {
        try {
            return await httpClient.get(getCustomerUrl(url, base));
        } catch (err) {
            Logger.error("getSwiftCodeDetails.tsx", "error", err);
            return err;
        }
    }
    /**
     * Method to set international routing code label
     * @param url : request url
     */
    async function getInternationalRoutingCodeLabel(url: string) {
        const response: any = await getSwiftCodeDetails(url);
        if (response && response.data && response.data.internationalCodeLabel) {
            setLabel(response.data.internationalCodeLabel);
        }
    }
    return (<>{label}</>);
}