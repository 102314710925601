import React, { useState } from 'react';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { PieChart, Pie, Cell } from 'recharts';
import { FxButton } from '../../Action/FxButton';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxInvestmentAllocationEditModal } from './FxInvestmentAllocationEditModal';
import { useSelector } from 'react-redux';
import { ReactComponent as EditAllocation} from '../../../assets/svg/edit-allocation.svg';
import moment from 'moment';

export const FxInvestmentAllocationCard: React.FC<any> = (props) => {
    const portfolioStatusData = useSelector((state: any) => state.data['investment-account-status']?.data?.portfolioStatusData || null);
    const [openInvestNow, setOpenInvestNow] = useState(false);

    const chartData: {name: string, value: number}[] = [];

    /**
    * Method to build pie chart data and invested amount for allocation
    */
    const allocation: any = {};
    let isAmountInvested = false;
    props?.portfolioData?.investmentProvider[0]?.allocation.forEach((allocationData: any) => {
        let key = Object.keys(allocationData)[0];
        allocation[key] = allocationData[key];
        chartData.push({name: key, value: +allocationData[key].currentPercent});

        if(+allocationData[key].currentPercent > 0) {
            isAmountInvested = true;
        }
    })

    // for unallocated percent to show in graph
    if(props?.portfolioData?.investmentProvider[0]?.unallocated) {
        chartData.push({name: 'unallocated', value: +props?.portfolioData?.investmentProvider[0]?.unallocated.percent || 0});
    }

    const handleInvestNowOpen = () => {
        setOpenInvestNow(true);
    };
    const handleInvestNowClose = () => {
        setOpenInvestNow(false);
    }

    return (
        <>
            <FxCard className="fx-theme-passport fx-investment-allocation-card">
                <FxCardBody>
                    <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center' marginBottom={'1rem'}>
                        <Grid>
                            <Typography variant='h5' className='fx-title'>Investment Allocation</Typography>
                            {
                                props?.portfolioData?.investmentProvider[0]?.lastUpdatedOn && <Typography className='fx-description'>Last updated On {moment(new Date(props?.portfolioData?.investmentProvider[0]?.lastUpdatedOn)).format('MMM DD, YYYY')}</Typography>
                            }
                        </Grid>
                        { portfolioStatusData?.status === 'ACTIVE' && <Grid>
                            <FxButton 
                                className='fx-button fx-button-theme' 
                                onClick={handleInvestNowOpen}
                            >
                                <EditAllocation /><span className='fx-edit-btn-text'>Edit</span> 
                            </FxButton>
                        </Grid>}
                    </Grid>
                    <Grid display='flex' alignItems='center' paddingY='1rem' className='fx-pi-chart'>
                        <Grid marginRight='2rem'>
                            <PieChart width={110} height={110}>
                                <Pie
                                    data={(chartData.length && isAmountInvested && chartData) || [{name: 'no-investment', value: 10}]}
                                    innerRadius={38}
                                    outerRadius={55}
                                    fill="#F8FAFB"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {isAmountInvested && chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} className={entry.name} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </Grid>
                        <Grid flexGrow='1' paddingLeft='3rem'>
                            <Grid item xs={12} className='fx-investment-allocation-section'>
                                <Grid>
                                    <Box className='sqaure-dot medium theme-color' marginRight='1rem' marginTop='0.5rem'></Box>
                                </Grid>
                                <Grid display='flex' flexGrow={1} justifyContent='space-between'>
                                    <Grid>
                                        <Typography variant='h5' className='fx-section-heading'>Managed Money Market</Typography>
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Current Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedMoneyMarket?.currentPercent || '0.00'} %</Typography>
                                        </Grid>
                                        {/* need to show based on condition when investment is in progress and hide if completed */}
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Target Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedMoneyMarket?.targetPercent || '0.00'} %</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <FxCurrencyView value={allocation?.managedMoneyMarket?.currentAmount || '0.00'} prefix={"$"} />
                                        <Typography className="fx-investment-summary-card-percentage">${allocation?.managedMoneyMarket?.returnAmount || '0.00'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid item xs={12} className='fx-investment-allocation-section'>
                                <Grid>
                                    <Box className='sqaure-dot medium manage-treasure-color' marginRight='1rem' marginTop='0.5rem'></Box>
                                </Grid>
                                <Grid display='flex' flexGrow={1} justifyContent='space-between'>
                                    <Grid>
                                        <Typography variant='h5' className='fx-section-heading'>Managed Treasuries</Typography>
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Current Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedTreasuries?.currentPercent || '0.0'} %</Typography>
                                        </Grid>
                                        {/* need to show based on condition when investment is in progress and hide if completed */}
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Target Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedTreasuries?.targetPercent || '0.00'} %</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <FxCurrencyView value={allocation?.managedTreasuries?.currentAmount || '0.00'} prefix={"$"} />
                                        <Typography className="fx-investment-summary-card-percentage">${allocation?.managedTreasuries?.returnAmount || '0.00'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid item xs={12} className='fx-investment-allocation-section'>
                                <Grid>
                                    <Box className='sqaure-dot medium manage-income-color' marginRight='1rem' marginTop='0.5rem'></Box>
                                </Grid>
                                <Grid display='flex' flexGrow={1} justifyContent='space-between'>
                                    <Grid>
                                        <Typography variant='h5' className='fx-section-heading'>Managed Income</Typography>
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Current Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedIncome?.currentPercent || '0.00'} %</Typography>
                                        </Grid>
                                        {/* need to show based on condition when investment is in progress and hide if completed */}
                                        <Grid display='flex' className='fx-section-sub-heading'>
                                            <Typography>Target Investment</Typography>
                                            <Typography className='fx-section-sub-heading-amount'>{allocation?.managedIncome?.targetPercent || '0.00'} %</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <FxCurrencyView value={allocation?.managedIncome?.currentAmount || '0.00'} prefix={"$"} />
                                        <Typography className="fx-investment-summary-card-percentage">${allocation?.managedIncome?.returnAmount || '0.00'}</Typography>  
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid item xs={12} className='fx-investment-allocation-section'>
                                <Grid>
                                    <Box className='sqaure-dot medium unallocated-color' marginRight='1rem' marginTop='0.5rem'></Box>
                                </Grid>
                                <Grid display='flex' flexGrow={1} justifyContent='space-between'>
                                    <Grid>
                                        <Typography variant='h5' className='fx-section-heading'>Unallocated</Typography>
                                    </Grid>
                                    <Grid>
                                        <FxCurrencyView value={props?.portfolioData?.investmentProvider[0]?.unallocated.amount || '0.00'} prefix={"$"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </FxCardBody>
            </FxCard>
            {openInvestNow &&
                <FxInvestmentAllocationEditModal
                    id="modal-invest-now"
                    open={openInvestNow}
                    onClose={handleInvestNowClose}
                    allocation={allocation}
                />
            }

        </>
    )
}
