/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { getKey } from '../../../libs/utils/storageManager';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
/**
 * This Component handles the transaction summary template creation for contact send ach
 */
export const TransactionSummaryTemplateContactSendAch: React.FC<any> = React.memo((props) => {


   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
         <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
            <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
         </Grid>
         <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
            <Grid item container>
               <Grid id={'contact-send-ach-amount-summary-label'} item xs> Send Amount  </Grid>
               <Grid id={'contact-send-ach-amount-summary-value'} item container xs justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
            </Grid>
            <Grid item container>
               <Grid id={'contact-send-ach-from-summary-label'} item xs>From</Grid>
               <Grid id={'contact-send-ach-from-summary-value'} item xs container justifyContent='flex-end'>{props?.from} </Grid>
            </Grid>
            <Grid item container>
               <Grid id={'contact-send-ach-to-summary-label'} item xs>To </Grid>
               <Grid id={'contact-send-ach-to-summary-value'} item xs container justifyContent='flex-end'>{props?.to}</Grid>
            </Grid>
            <Grid item container>
               <Grid id={'contact-send-ach-purpose-summary-label'} item xs>Purpose </Grid>
               <Grid id={'contact-send-ach-purpose-summary-value'} item xs container justifyContent='flex-end'>{props?.withPurpose}</Grid>
            </Grid>
            <Grid item container>
               <Grid id={'contact-send-ach-method-summary-label'} item xs>Via </Grid>
               <Grid id={'contact-send-ach-method-summary-value'} item xs container justifyContent='flex-end'>{props?.via} </Grid>
            </Grid>
            {!props.isppi ? <Grid item container>
               <Grid id={'contact-send-ach-contact-external-account-summary-label'} item xs>To External Account  </Grid>
               <Grid id={'contact-send-ach-contact-external-account-summary-value'} item xs container justifyContent='flex-end'>{props?.viaTo} </Grid>
            </Grid> : <Grid item container>
               <Grid id={'contact-send-ach-ppi-summary-label'} item xs>To PPI  </Grid>
               <Grid id={'contact-send-ach-ppi-summary-value'} item xs container justifyContent='flex-end'>{props?.ppi} </Grid>
            </Grid>}
            <Grid item container>
               <Grid id={'contact-send-ach-processing-mode-summary-label'} item xs>Processing Mode  </Grid>
               <Grid id={'contact-send-ach-processing-mode-summary-value'} item xs container justifyContent='flex-end'>{props?.processingMode ==='FORWARD' ?'Next Day':props?.processingMode} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs>Memo </Grid>
               <Grid item xs container justifyContent='flex-end'>{props?.memo} </Grid>
            </Grid>
            {props.repeatStatus && <><Grid item container>
               <Grid id={'contact-send-ach-repeat-every-summary-label'} item xs>Repeat every</Grid>
               <Grid id={'contact-send-ach-repeat-every-summary-value'} item xs container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
            </Grid>
               {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid id={'contact-send-ach-repeat-on-summary-label'} item container><Grid item xs>On</Grid>
                  <Grid id={'contact-send-ach-repeat-on-summary-value'} item xs container justifyContent='flex-end'>{props?.on}</Grid>
               </Grid>}
               <Grid item container>
                  <Grid id={'contact-send-ach-repeat-ends-summary-label'} item xs>Ends</Grid>
                  <Grid id={'contact-send-ach-repeat-ends-summary-value'} item xs container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                     props?.ends ? ' ' + props?.ends : ''}</Grid>
               </Grid></>}
         </Grid>
      </Grid>
      )
})
/**
 * This Component handles the transaction summary template creation for contact send wire
 */
export const TransactionSummaryTemplateContactSendWire: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs  id={'contact-send-wire-amount-summary-label'}> Send Amount  </Grid>
            <Grid item container xs  id={'contact-send-wire-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-wire-form-summary-label'}>From</Grid>
            <Grid item xs id={'contact-send-wire-form-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-wire-to-summary-label'}>To </Grid>
            <Grid item xs id={'contact-send-wire-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-wire-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'contact-send-wire-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-wire-via-summary-label'}>Via </Grid>
            <Grid item xs id={'contact-send-wire-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         {!props.isppi ? <Grid item container>
            <Grid item xs id={'contact-send-wire-external-account-summary-label'}>To External Account  </Grid>
            <Grid item xs container id={'contact-send-wire-external-account-amount-summary-value'} justifyContent='flex-end'>{props?.viaTo} </Grid>
         </Grid> : <Grid item container>
            <Grid id={'contact-send-wire-ppi-summary-label'} item xs>To PPI  </Grid>
            <Grid id={'contact-send-wire-ppi-summary-value'} item xs container justifyContent='flex-end'>{props?.ppi} </Grid>
         </Grid>}
         <Grid item container>
            <Grid item xs id={'contact-send-wire-memo-summary-label'}>Memo  </Grid>
            <Grid item xs id={'contact-send-wire-memo-summary-value'} container justifyContent='flex-end'>{props?.memo} </Grid>
         </Grid>
         {props.allowDuplicate &&
           <Grid item container>
           <Grid item xs id={'contact-send-wire-allowduplicate-summary-label'}>Allow Duplicate  </Grid>
           <Grid item xs id={'contact-send-wire-allowduplicate-summary-value'} container justifyContent='flex-end'>{props?.allowDuplicate} </Grid>
        </Grid>}
         {props.repeatStatus && <><Grid item container>
            <Grid item xs id={'contact-send-wire-repeat-every-summary-label'}>Repeat every</Grid>
            <Grid item xs id={'contact-send-wire-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
         </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item id={'contact-send-wire-on-summary-label'} container><Grid item xs>On</Grid>
               <Grid item xs id={'contact-send-wire-on-summary-value'} container justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
            <Grid item container>
               <Grid item xs id={'contact-send-wire-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'contact-send-wire-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                  props?.ends ? ' ' + props?.ends : ''}</Grid>
            </Grid></>}
      </Grid>
   </Grid>
  )
})

/**
 * This Component handles the transaction summary template creation for contact send check
 */
export const TransactionSummaryTemplateContactSendCheck: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs id={'contact-send-check-amount-summary-label'}> Send Amount  </Grid>
            <Grid item container xs id={'contact-send-check-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-check-from-summary-label'}>From</Grid>
            <Grid item xs id={'contact-send-check-from-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-check-to-summary-label'}>To </Grid>
            <Grid item xs id={'contact-send-check-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-check-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'contact-send-check-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-check-via-summary-label'}>Via </Grid>
            <Grid item xs id={'contact-send-check-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         {!props.isppi ? <Grid item container>
            <Grid item xs id={'contact-send-check-address-summary-label'}>To Address  </Grid>
            <Grid item xs id={'contact-send-check-address-summary-value'} container justifyContent='flex-end'>{props?.viaTo} </Grid>
         </Grid> : <Grid item container>
            <Grid id={'contact-send-check-ppi-summary-label'} item xs>To PPI  </Grid>
            <Grid id={'contact-send-check-ppi-summary-value'} item xs container justifyContent='flex-end'>{props?.ppi} </Grid>
         </Grid>}
         <Grid item container>
            <Grid item xs id={'contact-send-check-mode-summary-label'}>Delivery Mode  </Grid>
            <Grid item xs id={'contact-send-check-mode-summary-value'} container justifyContent='flex-end'>{props?.deliveryMode} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-check-memo-summary-label'}>Memo  </Grid>
            <Grid item xs id={'contact-send-check-memo-summary-value'}container justifyContent='flex-end'>{props?.memo} </Grid>
         </Grid>
         {props.repeatStatus && <><Grid item container>
            <Grid item xs id={'contact-send-check-repeat-every-summary-label'}>Repeat every</Grid>
            <Grid item xs id={'contact-send-check-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
         </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item container id={'contact-send-check-on-summary-label'}><Grid item xs>On</Grid>
               <Grid item xs id={'contact-send-check-on-summary-label'} container justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
            <Grid item container>
               <Grid item xs id={'contact-send-check-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'contact-send-check-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                  props?.ends ? ' ' + props?.ends : ''}</Grid>
            </Grid></>}
      </Grid>
   </Grid>)
})

/**
 * This Component handles the transaction summary template creation for contact send book
 */
export const TransactionSummaryTemplateContactSendBook: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs id={'contact-send-book-amount-summary-label'}> Send Amount  </Grid>
            <Grid item container xs id={'contact-send-book-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-book-from-summary-label'}>From</Grid>
            <Grid item xs id={'contact-send-book-from-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-book-to-summary-label'}>To </Grid>
            <Grid item xs id={'contact-send-book-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-book-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'contact-send-book-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-book-via-summary-label'}>Via </Grid>
            <Grid item xs id={'contact-send-book-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         {props.isppi && <Grid item container>
            <Grid id={'contact-send-book-ppi-summary-label'} item xs>To PPI  </Grid>
            <Grid id={'contact-send-book-ppi-summary-value'} item xs container justifyContent='flex-end'>{props?.ppi} </Grid>
         </Grid>}
         {props.repeatStatus && <><Grid item container>
            <Grid item xs id={'contact-send-book-repeat-every-summary-label'}>Repeat every</Grid>
            <Grid item xs id={'contact-send-book-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
         </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item container id={'contact-send-book-on-summary-label'}><Grid item xs>On</Grid>
               <Grid item xs id={'contact-send-book-on-summary-label'} container justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
            <Grid item container>
               <Grid item xs id={'contact-send-book-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'contact-send-book-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                  props?.ends ? ' ' + props?.ends : ''}</Grid>
            </Grid></>}
      </Grid>
   </Grid>)
})

/**
 * This Component handles the transaction summary template creation for contact send virtual card
 */
export const TransactionSummaryTemplateContactSendVirtualCard: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs  id={'contact-send-virtual-card-amount-summary-label'}> Send Amount  </Grid>
            <Grid item container xs  id={'contact-send-virtual-card-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-virtual-card-form-summary-label'}>From</Grid>
            <Grid item xs id={'contact-send-virtual-card-form-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-virtual-card-to-summary-label'}>To </Grid>
            <Grid item xs id={'contact-send-wire-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-virtual-card-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'contact-send-virtual-card-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-virtual-card-via-summary-label'}>Via </Grid>
            <Grid item xs id={'contact-send-virtual-card-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         {props.isppi && <Grid item container>
            <Grid id={'contact-send-virtual-card-ppi-summary-label'} item xs>To PPI  </Grid>
            <Grid id={'contact-send-virtual-card-ppi-summary-value'} item xs container justifyContent='flex-end'>{props?.ppi} </Grid>
         </Grid>}
         {props.repeatStatus && <><Grid item container>
            <Grid item xs id={'contact-send-virtual-card-repeat-every-summary-label'}>Repeat every</Grid>
            <Grid item xs id={'contact-send-virtual-card-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
         </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item id={'contact-virtual-card-wire-on-summary-label'} container><Grid item xs>On</Grid>
               <Grid item xs id={'contact-send-virtual-card-on-summary-value'} container justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
            <Grid item container>
               <Grid item xs id={'contact-send-virtual-card-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'contact-send-virtual-card-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                  props?.ends ? ' ' + props?.ends : ''}</Grid>
            </Grid></>}
      </Grid>
   </Grid>
  )
})

/**
 * This Component handles the transaction summary template creation for my account send wire
 */
export const TransactionSummaryTemplateMyAccountSendWire: React.FC<any> = React.memo((props) => {
   return (<Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display">
            <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
               <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
            </Grid>
            <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-amount-summary-label'}> Send Amount  </Grid>
                  <Grid item container xs id={'my-account-send-wire-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
               </Grid>
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-from-summary-label'}>From</Grid>
                  <Grid item xs container id={'my-account-send-wire-from-summary-value'} justifyContent='flex-end'>{props?.from} </Grid>
               </Grid>
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-to-summary-label'}>To</Grid>
                  <Grid item xs id={'my-account-send-wire-to-summary-value'} container justifyContent='flex-end'>{props?.to} </Grid>
               </Grid>
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-purpose-summary-label'}>Purpose</Grid>
                  <Grid item xs id={'my-account-send-wire-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose} </Grid>
               </Grid>
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-via-summary-label'}>Via </Grid>
                  <Grid item xs id={'my-account-send-wire-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
               </Grid>
               <Grid item container>
                  <Grid item xs id={'my-account-send-wire-memo-summary-label'}>Memo </Grid>
                  <Grid item xs id={'my-account-send-wire-memo-summary-value'} container justifyContent='flex-end'>{props?.memo} </Grid>
               </Grid>
                {props.repeatStatus && <><Grid item container>
                  <Grid item xs id={'my-account-send-wire-repeat-every-summary-label'}>Repeat every</Grid>
                  <Grid item xs id={'my-account-send-wire-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
               </Grid>
               {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item container><Grid item xs id={'my-account-send-wire-on-summary-label'}>On</Grid>
                  <Grid item xs id={'my-account-send-wire-on-summary-value'} container justifyContent='flex-end'>{props?.on}</Grid>
               </Grid>}
               <Grid item container>
                  <Grid item xs  id={'my-account-send-wire-ends-summary-label'}>Ends</Grid>
                  <Grid item xs  id={'my-account-send-wire-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                     props?.ends ? ' ' + props?.ends : ''}</Grid>
               </Grid></>}
               </Grid>
           </Grid>)
})

/**
 * This Component handles the transaction summary template creation for my account send book
 */
export const TransactionSummaryTemplateMyAccountSendBook: React.FC<any> = React.memo((props) => {
   return (<Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display">
            <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
               <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
            </Grid>
            <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
            <Grid item container>
               <Grid item id={'my-account-send-book-amount-summary-label'} xs> Send Amount  </Grid>
               <Grid item container id={'my-account-send-book-amount-summary-value'} xs justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-send-book-from-summary-label'}>From</Grid>
               <Grid item xs id={'my-account-send-book-from-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-send-book-to-summary-label'}>To</Grid>
               <Grid item xs id={'my-account-send-book-to-summary-value'} container justifyContent='flex-end'>{props?.to} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-send-book-purpose-summary-label'}>Purpose</Grid>
               <Grid item xs id={'my-account-send-book-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-send-book-via-summary-label'}>Via </Grid>
               <Grid item xs id={'my-account-send-book-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs>Memo </Grid>
               <Grid item xs container justifyContent='flex-end'>{props?.memo} </Grid>
            </Grid>
            {props.repeatStatus && <><Grid item container>
               <Grid item xs id={'my-account-send-book-repeat-every-summary-label'}>Repeat every</Grid>
               <Grid item xs id={'my-account-send-book-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
            </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item container><Grid item xs id={'my-account-send-book-on-summary-label'}>On</Grid>
               <Grid item xs container id={'my-account-send-book-on-summary-value'} justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
            <Grid item container>
               <Grid item xs id={'my-account-send-book-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'my-account-send-book-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                     props?.ends ? ' ' + props?.ends : ''}</Grid>
               </Grid></>}
            </Grid>
         </Grid>)
})




/**
 * This Component handles the transaction summary template creation for my account collect
 */
export const TransactionSummaryTemplateCollectMyAccount: React.FC<any> = React.memo((props) => {
   return (<Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display">
            <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
               <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
            </Grid>
            <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
            <Grid item container>
               <Grid item xs id={'my-account-collect-amount-summary-label'}> Collect Amount  </Grid>
               <Grid item id={'my-account-collect-amount-summary-value'} container xs justifyContent='flex-end'><FxCurrencyView value={props?.collectAmount} prefix={"$"} /></Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-collect-to-summary-label'}>To</Grid>
               <Grid item xs  id={'my-account-collect-to-summary-value'}container justifyContent='flex-end'>{props?.to} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-collect-from-summary-label'}>From</Grid>
               <Grid item xs id={'my-account-collect-from-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-collect-purpose-summary-label'}>Purpose</Grid>
               <Grid item xs id={'my-account-collect-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-collect-via-summary-label'}>Via </Grid>
               <Grid item xs id={'my-account-collect-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs id={'my-account-collect-processing-mode-summary-label'}>Processing Mode </Grid>
                <Grid item xs id={'my-account-collect-processing-mode-summary-value'} container justifyContent='flex-end'>{props?.processingMode ==='FORWARD' ?'Next Day':props?.processingMode}</Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'my-account-collect-memo-summary-label'}>Memo </Grid>
               <Grid item xs id={'my-account-collect-memo-summary-value'} container justifyContent='flex-end'>{props?.memo} </Grid>
            </Grid>
               {props.showInstantFunding &&
            <Grid item container>
               <Grid item xs id={'my-account-collect-instant-funding-summary-label'}>Faster Funding </Grid>
               <Grid item xs id={'my-account-collect-instant-funding-summary-value'} container justifyContent='flex-end'>{props?.quickSettle} </Grid>
            </Grid>}
            </Grid>
         </Grid>)
})



/**
 * This Component handles the transaction summary template creation for my account collect
 */
export const TransactionSummaryTemplateCollectOneTimeCard: React.FC<any> = React.memo((props) => {
   return (<Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display">
            <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
               <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
            </Grid>
            <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-amount-summary-label'}> Collect Amount  </Grid>
               <Grid item id={'collect-one-time-card-amount-summary-value'} container xs justifyContent='flex-end'><FxCurrencyView value={props?.collectAmount} prefix={"$"} /></Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-to-summary-label'}>To</Grid>
               <Grid item xs id={'collect-one-time-card-to-summary-value'} container justifyContent='flex-end'>{props?.to} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-from-summary-label'}>From</Grid>
               <Grid item xs id={'collect-one-time-card-from-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-purpose-summary-label'}>Purpose</Grid>
               <Grid item xs id={'collect-one-time-card-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-via-summary-label'}>Via </Grid>
               <Grid item xs id={'collect-one-time-card-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-merchant-summary-label'}>Merchant </Grid>
               <Grid item xs id={'collect-one-time-card-merchant-summary-value'} container justifyContent='flex-end'>{props?.merchant} </Grid>
            </Grid>
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-statement-descriptor-summary-label'}>Statement Descriptor </Grid>
               <Grid item xs={5} id={'collect-one-time-card-statement-descriptor-summary-value'} container justifyContent='flex-end'>{props?.statementDescriptor} </Grid>
            </Grid>
               {props.showInstantFunding &&
            <Grid item container>
               <Grid item xs id={'collect-one-time-card-instant-funding-summary-label'}>Faster Funding </Grid>
               <Grid item xs={5} id={'collect-one-time-card-instant-funding-summary-value'} container justifyContent='flex-end'>{props?.quickSettle} </Grid>
            </Grid>}
            </Grid>
         </Grid>)
})

export type TransactionSummaryFieldType = {
   id: string;
   value: any;
   ValueComponent?: React.ComponentType<{id?: string; value: any}>;
}

export type TransactionSummaryPropsType = {
   id?: string;
   [fieldName: string]: string | TransactionSummaryFieldType | undefined;
};

/**
 * This Component renders transaction summary with dynamically
 */
export const TransactionSummary: React.FC<TransactionSummaryPropsType> = React.memo(({id, children, ...data}) => {
   return (
      <Grid id={`${id}-summary`} item container xs={12} className="fx-container-create-schdule-summery">
         <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
            <Typography id={`${id}-title`} variant={'h3'}>Transaction Summary</Typography>
         </Grid>
         <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
            {Object.entries(data).map(([fieldName, FieldValue]) => {
               const idPart = typeof FieldValue === 'object' ? FieldValue.id : '';
               return (
                  <Grid key={fieldName} item container>
                     <Grid id={`${id}-${idPart}-summary-label`} item xs>
                        {`${fieldName} `}
                     </Grid>
                     <Grid id={`${id}-${idPart}-summary-value`} item container xs justifyContent='flex-end'>
                        {typeof FieldValue === 'object' && FieldValue.ValueComponent ? (
                           <FieldValue.ValueComponent value={FieldValue.value}/>
                        ) : typeof FieldValue === 'object' ? FieldValue.value : FieldValue}
                     </Grid>
                  </Grid>
               )})}
         </Grid>
      </Grid>
   );
});

/**
 * This Component handles the transaction summary template creation for collect check
 */
export const TransactionSummaryTemplateCollectCheck: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs id={'collect-check-amount-summary-label'}> Collect Amount  </Grid>
            <Grid item id={'collect-check-amount-summary-value'} container xs justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-check-to-summary-label'}>To </Grid>
            <Grid item xs id={'collect-check-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-check-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'collect-check-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-check-via-summary-label'}>Via </Grid>
            <Grid item xs id={'collect-check-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         {getKey('isCheckQuickSettleEnabled') &&
         <Grid item container>
            <Grid item xs id={'collect-check-instant-funding-summary-label'}>Faster Funding </Grid>
            <Grid item xs id={'collect-check-instant-funding-summary-value'} container justifyContent='flex-end'>{props?.quickSettle} </Grid>
         </Grid>}
      </Grid>
   </Grid>)
})

/**
 * This Component handles the transaction summary template creation for one time collect ach
 */
export const TransactionSummaryTemplateCollectOneTimeACH: React.FC<any> = React.memo((props) => {
   return (<Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display">
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-amount-summary-label'}>Collect Amount  </Grid>
            <Grid item id={'collect-one-time-ach-amount-summary-value'} container xs justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-to-summary-label'}>To</Grid>
            <Grid item xs id={'collect-one-time-ach-to-summary-value'} container justifyContent='flex-end'>{props?.to} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-purpose-summary-label'}>Purpose</Grid>
            <Grid item xs id={'collect-one-time-ach-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-via-summary-label'}>Via </Grid>
            <Grid item xs id={'collect-one-time-ach-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-holder-name-summary-label'}>Account Holder Name</Grid>
            <Grid item xs id={'collect-one-time-ach-holder-name-summary-value'} container justifyContent='flex-end'>{props?.accountHolderName} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-account-type-summary-label'}>Account Type</Grid>
            <Grid item xs id={'collect-one-time-ach-account-type-summary-value'} container justifyContent='flex-end'>{props?.accountType} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-holder-type-summary-label'}>Holder Type</Grid>
            <Grid item xs id={'collect-one-time-ach-holder-type-summary-value'} container justifyContent='flex-end'>{props?.holderType} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-account-number-summary-label'}>Account Number</Grid>
            <Grid item xs id={'collect-one-time-ach-account-number-summary-value'} container justifyContent='flex-end'>{props?.accountNumber} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-routing-number-summary-label'}>Routing Number</Grid>
            <Grid item xs id={'collect-one-time-ach-routing-number-summary-value'} container justifyContent='flex-end'>{props?.routingNumber} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-processing-mode-summary-label'}>Processing Mode</Grid>
            <Grid item xs id={'collect-one-time-ach-processing-mode-summary-value'} container justifyContent='flex-end'>{props?.processingMode ==='FORWARD' ?'Next Day':props?.processingMode} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-authorization-type-summary-label'}>Authorization Type</Grid>
            <Grid item xs id={'collect-one-time-ach-authorization-type-summary-value'} container justifyContent='flex-end'>{props?.authorizationType} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-categorye-summary-label'}>Category</Grid>
            <Grid item xs id={'collect-one-time-ach-categorye-summary-value'} container justifyContent='flex-end'>{props?.category} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-memo-summary-label'}>Memo</Grid>
            <Grid item xs id={'collect-one-time-ach-memo-summary-label'} container justifyContent='flex-end'>{props?.memo} </Grid>
         </Grid>
         {props.showInstantFunding &&
         <Grid item container>
            <Grid item xs id={'collect-one-time-ach-instant-funding-summary-label'}>Faster Funding</Grid>
            <Grid item xs id={'collect-one-time-ach-instant-funding-summary-label'} container justifyContent='flex-end'>{props?.quickSettle} </Grid>
         </Grid>}
      </Grid>
   </Grid>)
})

/**
 * This Component handles the transaction summary template creation for contact send international wire
 */
 export const TransactionSummaryTemplateContactSendInternationalWire: React.FC<any> = React.memo((props) => {
   return (
      <Grid id={props.id} item container xs={12} className="fx-container-create-schdule-summery fx-mobile-no-display" >
      <Grid item xs={12} sm={12} className="fx-container-create-schdule-summery-title">
         <Typography id={props.id + '-title'} variant={'h3'}>Transaction Summary</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className="fx-container-create-schdule-summery-content" >
         <Grid item container>
            <Grid item xs  id={'contact-send-internationalwire-amount-summary-label'}> Send Amount  </Grid>
            <Grid item container xs  id={'contact-send-internationalwire-amount-summary-value'} justifyContent='flex-end'><FxCurrencyView value={props?.sendAmount} prefix={"$"} /></Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-form-summary-label'}>From</Grid>
            <Grid item xs id={'contact-send-internationalwire-form-summary-value'} container justifyContent='flex-end'>{props?.from} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-to-summary-label'}>To </Grid>
            <Grid item xs id={'contact-send-internationalwire-to-summary-value'} container justifyContent='flex-end'>{props?.to}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-purpose-summary-label'}>Purpose </Grid>
            <Grid item xs id={'contact-send-internationalwire-purpose-summary-value'} container justifyContent='flex-end'>{props?.withPurpose}</Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-via-summary-label'}>Via </Grid>
            <Grid item xs id={'contact-send-internationalwire-via-summary-value'} container justifyContent='flex-end'>{props?.via} </Grid>
         </Grid>
        <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-external-account-summary-label'}>To International External Account  </Grid>
            <Grid item xs container id={'contact-send-wire-external-account-amount-summary-value'} justifyContent='flex-end'>{props?.viaTo} </Grid>
         </Grid>
         <Grid item container>
            <Grid item xs id={'contact-send-internationalwire-memo-summary-label'}>Memo  </Grid>
            <Grid item xs id={'contact-send-internationalwire-memo-summary-value'} container justifyContent='flex-end'>{props?.memo} </Grid>
         </Grid>
         {props.allowDuplicate &&
           <Grid item container>
           <Grid item xs id={'contact-send-internationalwire-allowduplicate-summary-label'}>Allow Duplicate  </Grid>
           <Grid item xs id={'contact-send-internationalwire-allowduplicate-summary-value'} container justifyContent='flex-end'>{props?.allowDuplicate} </Grid>
        </Grid>}
         {props.currency &&
           <Grid item container>
           <Grid item xs id={'contact-send-internationalwire-allowduplicate-summary-label'}>Currency  </Grid>
           <Grid item xs id={'contact-send-internationalwire-allowduplicate-summary-value'} container justifyContent='flex-end'>{props?.currency} </Grid>
        </Grid>}
         {props.repeatStatus && <><Grid item container>
            <Grid item xs id={'contact-send-internationalwire-repeat-every-summary-label'}>Repeat every</Grid>
            <Grid item xs id={'contact-send-internationalwire-repeat-every-summary-value'} container justifyContent='flex-end'>{props?.repeatEvery} {props?.repeatEverySelect}</Grid>
         </Grid>
            {(props.onDayStatus || props.onDateStatus || props?.repeatEverySelect === 'Weeks') && <Grid item id={'contact-send-wire-on-summary-label'} container><Grid item xs>On</Grid>
               <Grid item xs id={'contact-send-internationalwire-on-summary-value'} container justifyContent='flex-end'>{props?.on}</Grid>
            </Grid>}
         <Grid item container>
               <Grid item xs id={'contact-send-internationalwire-ends-summary-label'}>Ends</Grid>
               <Grid item xs id={'contact-send-internationalwire-ends-summary-value'} container justifyContent='flex-end'>    {props?.endOn ? <>On &nbsp;<FxDateTimeView value={props?.ends} format="MMM DD, yyyy"></FxDateTimeView></> :
                  props?.ends ? ' ' + props?.ends : ''}</Grid>
         </Grid></>}
      </Grid>
   </Grid>
  )
})
