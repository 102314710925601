import React, { useEffect } from 'react';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { Divider, Grid, Typography } from '@mui/material';
import { FxButton } from '../../Action/FxButton';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxInfoTooltip } from '../../Utils/FxInfoTooltip';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { truncateChars } from '../../Utils/CommonBaseClass';
import FxCardFooter from '../../Container/FxCardFooter';
import { ReactComponent as MdArrowForward } from "../../../assets/svg/view-all-arrow-icon.svg";
import { useHistory } from 'react-router';
import moment from 'moment';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxNoGridDataImage from '../../Data/FxNoGridDataImage';
import { getKey } from '../../../libs/utils/storageManager';
import { useDispatch } from 'react-redux';

export const FxInvestmentInstructionList: React.FC<any> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    let context: any;
    let cur_date: any = '';
    ({ context, props } = RegisterComponent(props));

      /**
     * useEffect to set default address 
     */
      useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });      
       
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    const isSmallScreen = useIsMobileOrTabletScreen();
     
     const result = context?.data?.body?.resources || [];
    
     const extractRowData =(item:any)=>{
        let title: any;
        if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
            cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
        }

        let narration = ''       
        const instructionMap = [
            {key:'INVEST',value:'Investment'},
            {key:'REBALANCE',value:'Rebalance'},
            {key:'REDEEM',value:'Redeem'}
        ];        

        const instructionType = instructionMap.find(x=>x.key === item?.type?.toUpperCase());
        if (instructionType) {
            narration = truncateChars(`${instructionType.value} Instruction to ${item.investmentProvider}`, isSmallScreen ? 25 : 45) + ` (Txn Ref: ${item.id})`;
        }   

        const allocate = item?.allocate;
        return <Grid id={"recent-treasure-transaction-activity-list-Home-Grid"} item xs={12}>
                { title ? 
                    <Grid container xs={12} direction="row">
                        <Grid item xs className='fx-home-ledger-list-headerText'> <Typography variant='h6'>{title}</Typography></Grid>
                    </Grid> : (
                    <Grid item xs={12} sm={12}>
                        <Divider />
                    </Grid>
                )}
                <Grid container direction="row" className="fx-home-ledger-list-column fx-home-ledger-list-column-pointer-event" >
                    <Grid item container xs className="fx-home-ledger-list-column-content">
                        <Grid item container xs={12} alignItems='center'>
                            <Grid item container xs={8}>
                                <Grid item>
                                    <Typography variant='h4'>{narration}</Typography>
                                </Grid>

                            </Grid>
                            {!isSmallScreen && <Grid item container xs={2}>
                                <FxInfoTooltip title={item?.statusReason} arrow placement={'bottom-start'} className={'fx-status-tooltip'}>
                                    <span><FxStatus id="treasure-portfolio-status" value={item.status} fill={true} className="fx-status"></FxStatus></span>
                                </FxInfoTooltip>
                            </Grid>}
                            <Grid item container xs={isSmallScreen ? 4 : 2} justifyContent='end'>
                                <Grid item xs>
                                     <Grid> {item.amount ? <FxCurrencyView value={item.amount} justifyContent="flex-end" prefix={"$"}  showPlus={item?.type?.toUpperCase() === 'INVEST' ? true:false} showMinus={item?.type?.toUpperCase() === 'REDEEM' ? true:false} />  : <Grid container justifyContent='flex-end'>-</Grid>}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {allocate && <Grid item container xs alignItems='center'>
                            <Grid item>
                                <Grid><Typography className='fx-home-ledger-list-portfolio-text'>Money Market ({item?.allocate?.managedMoneyMarket ? item?.allocate?.managedMoneyMarket :0}%)</Typography></Grid>
                            </Grid>
                            <Grid item>
                                <Grid marginLeft={1}><Typography className='fx-home-ledger-list-portfolio-text'>Managed Treasuries ({allocate?.managedTreasuries ?allocate.managedTreasuries :0}%)</Typography></Grid>
                            </Grid>
                            <Grid item>
                                <Grid marginLeft={1}><Typography className='fx-home-ledger-list-portfolio-text'>Managed Income ({allocate?.managedIncome ?allocate.managedIncome :0}%)</Typography></Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid item container xs={12} sm={12} className="fx-home-ledger-list-column-padding"></Grid>
                </Grid>
        </Grid>
     }

    return (
        <>
            <FxCard id="home-treasure-transaction-list-card-Home-Card" className="fx-card-general fx-theme-passport fx-investment-insturation-card" >
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5' className='fx-title'>Investment Instructions</Typography>
                    </Grid>                    
                    <Grid item xs={12} className="fx-flex-grow">
                        <FxCardBody id="recent-treasure-transaction-activity-list-Home-Card-Body" className="fx-home-ledger-list">
                            {result?.map((item:any)=> extractRowData(item))}        
                            {(!getKey("selectedAccount")?.id || (context?.data?.body?.totalCount === 0)) && <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} textAlign={'center'}>
                                    <FxNoGridDataImage />
                                    <Typography className='fx-no-data'> No data to show </Typography>
                                </Grid>
                                </Grid>
                            }                   
                        </FxCardBody>
                        {context?.data?.body?.totalCount >= 0 && <FxCardFooter id="treasure-portfolio-activity-list-Home-Card-Footer" className="fx-footer fx-footer-middle">
                            <Grid container xs={12} justifyContent="center" alignItems="flex-start">
                                <FxButton variant="contained" className="fx-button fx-button-view-all" id="home-portfolio-list-view-all-button" onClick={() => history.push('/investmentInstructions')}> View All <MdArrowForward className="fx-footer-icon" /></FxButton>
                            </Grid>
                        </FxCardFooter>}
                    </Grid>
                </Grid>
            </FxCard>
        </>
    )
}
