import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { BeneficialOwners } from './BeneficialOwners';
import { CompanyInfoCard } from './CompanyInfoCard';
import { CustomerAddress } from './CustomerAddress';
import { MerchantDetails } from './MerchantDetails';
import { LocationDetails } from './LocationDetails';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component used to view company profile informations
 */

export const CompanyProfile: React.FC = () => {
        const dispatch = useDispatch()
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        /**
         * useeffect to load title while loading page
         */
        useEffect(()=> {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: businessCustomer ? 'Company Information' :'Customer Information'} } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const info_source = {
            url: "/",
            method: "GET"
        }

        const merchant_source = {
            url: "/merchant/list",
            method: "POST",
            data:
            {
                pageNumber: 1,
                pageSize: 25,
                criteria: {
                    filters: []
                }
            }
        }

        const location_source = {
            url: "/location/list",
            method: "POST",
            data: {
                pageNumber: 1,
                pageSize: 5,
                sortOptions: {
                    sortBy: "id",
                    sortOrder: "asc"
                },
                criteria: {
                    filters: [
                        {
                            operator: "eq",
                            key: "status",
                            values: [
                                "ACTIVE"
                            ]
                        }
                    ]
                }
            }
        }

        /**
        * function to check permission 
        */
        function checkViewPermission(data: any) {
            const permissionObject: any = {
                'company_card_beneficialOwner': {
                    entity: "Business Customer",
                    name: "Beneficial Owner",
                    operation: "View"
                },
                'company_card_address': {
                    entity: "Other Entities",
                    name: "Mailing Address",
                    operation: "View"
                },
                'company_card_merchant': {
                    entity: "Other Entities",
                    name: "Merchant",
                    operation: "View"
                },
                'business_customer': {
                    entity: "Business Customer",
                    name: "Business Customer",
                    operation: 'View'
                }
            }
            return checkPermission(permissionObject[data])
        }

        
        return (
            <>
                <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                    <Grid container xs={12} sm={8} className="fx-container-left" >
                        {checkViewPermission("business_customer") ? <CompanyInfoCard id="company-info-card-profile" source={info_source}></CompanyInfoCard>: null}
                        {checkViewPermission("business_customer") ? <LocationDetails id="company-info-card-location" source={location_source}></LocationDetails>: null}
                        {(businessCustomer && checkViewPermission("company_card_beneficialOwner"))? (<BeneficialOwners id="company-profile-beneficial-owners-comp-card" /> ):  null }
                        {businessCustomer && checkViewPermission("company_card_merchant")? <MerchantDetails id="company-profile-merchant-details" source={merchant_source} />:  null }
                        {(checkViewPermission("company_card_address"))?  <CustomerAddress id="company-profile-address-comp-card"></CustomerAddress> :  null }
                    </Grid>
                    <Grid container xs={12} sm={4} className="fx-container-right">
                    </Grid>
                </Grid>
            </>
        )
    }
