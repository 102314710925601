import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-xcode";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { InitializeComponent } from "../../Utils/ComponentInitializer";
import { SagaHelper } from "../../../libs/utils/sagaHelper";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxCodeEditor.tsx", "Fx Code Editor initializing")

/**
 * Supported props
 */
interface FxCodeEditorProps {
  id?: string;
  inputRef?: any;
  fieldName?: any;
  rows?: any;
  className?: any;
  placeholder?: any;
  value?: any;
  onChange?: any;
  onLoad?: any;
  theme?: string;
  mode?: string;
  fontSize?: number;
  register?: any;
  height?: any;
  width?: any;
  reset?: boolean;
}
/***
 * FxCodeEditor : Enum component 
 * Module : AceEditor
 * Code editor component, Hidden TextField is used to pass the value to the FxForm
*/
export const FxCodeEditor: React.FC<FxCodeEditorProps> = (props) => {
  /**
   * Initialize Component and set the context
   */
  ({ props } = InitializeComponent(props));
  const dispatch = useDispatch();

  /**
   * 
   * @param content 
   * Update the prop when then value is changed and set the value in the hidden text field
   */
  const onChange = (content: any) => {
    new SagaHelper(dispatch).UpdateComponentMultipleParams(props.id + "", { value: content })
  };


  return (
    <div>
      <AceEditor
        placeholder={props.placeholder}
        mode={props.mode ? props.mode : "json"}
        theme={props.theme ? props.theme : "xcode"}
        name={"fxcodeeditor-" + props.fieldName}
        onLoad={props.onLoad}
        onChange={onChange}
        fontSize={props.fontSize ? props.fontSize : 14}
        value={props.value ? props.value : ""}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        ref={props.inputRef}
        height={props.height}
        width={props.width ? props.width : "auto"}
        className={clsx('fx-code', props.className)}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 1,
        }}
      />

      <TextField
        id={props.id}
        name={props.fieldName}
        value={
          props.value
        }
        inputRef={props.inputRef}
        className="fx-hidden"
      />

    </div>
  );
};
