import { useDispatch } from "react-redux";
import HttpClient from "../../../libs/utils/httpClient";
import { Logger } from "../../../libs/utils/logger";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import FxSnackBar from "../../Utils/fx-snack-bar";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxButton } from "../../Action/FxButton";
import CloseIcon from '@mui/icons-material/Close';
import { FxTextEdit } from "../../Input";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ReactComponent as AlertIcon } from "../../../assets/svg/alertWarningTriangle.svg";
import { FxMultiSelectChekEditAutoComplete } from "../../Input/FxMultiSelectChekEditAutoComplete/FxMultiSelectChekEditAutoComplete";
import FxLabelWithCopy from "../../Input/FxLabelWithCopy/FxLabelWithCopy";
const httpClient = HttpClient.getClient();


/**
 * Component: GenerateAPIToken
 * Usage: For Generate API token
 */

Logger.debug("GenerateAPIToken.tsx", "Generate API Token initializing");

export const GenerateAPIToken: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control } = useForm();

        const role_src = {
            url: "role/list",
            method: "POST",
            data: {
                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters":
                        [{
                            "key": "name",
                            "operator": "like",
                            "values": [
                                ""
                            ]
                        }]
                }
            }
        };
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { apiSuccess: false })
            getRoles(role_src.data, true);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        /**
            * Method used to call request roles
            * @param body :form data
            * @returns
            */
        async function getRoles(payloadData: any, isOnload = false) {
            try {
                await httpClient.post(getCustomerUrl(role_src.url, false), payloadData).then(response => {
                    if (response.data && response.data.resources && response.data.resources.length > 0) {
                        let transformedData: any = []
                        transformedData = customerDataTransformation(response.data.resources);
                        updateComponentParams(dispatch, props?.id, { rolesData: transformedData })
                        return transformedData;
                    }
                    return response.data.resources;
                })
            } catch (err) {
                Logger.error("GenerateAPIToken.tsx", "error", err);
                return err;
            }
        }
        /**
           * To transform the data
           * @param data: Data received from api
           */
        function customerDataTransformation(data: any) {
            return data?.length ? data.map((role: any) => {
                return { value: role.id, label: `${role.name} ID: ${role.id}` };
            }) : [];
        }

        /**
          * To do the api call based on the user search
          * @param searchValue: user entered value
          */
        async function onRoleSearch(searchValue: any) {
            if (searchValue) {
                const payloadData = {
                    "pageSize": 25,
                    "pageNumber": 1,
                    "criteria": {
                        "filters": [
                            {
                                "key": "name",
                                "operator": "like",
                                "values": [
                                    searchValue
                                ]
                            }
                        ]
                    }
                }

                return getRoles(payloadData);
            }
        }
        /**
         * Get selected roles ids
         * @param roles 
         * @returns 
         */
        function getRolesIds(roles: any) {
            return roles.map((item: any) => item?.value?.toString());
        }

        /***
         * Submitting the results
         */
        async function onSubmit(data: any) {
            let response: any;
            try {
                let req: any = {
                    keyName: data?.tokenName,
                    roleList: getRolesIds(data.role)
                }
                response = await createAPI(req);
                let status = processAPIResponse(response)
                if (status.status) {
                    //api success
                    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'customer-api-token-list-card-data' } });
                    updateComponentParams(dispatch, props?.id, { apiSuccess: true, generatedkey: response?.data?.token })
                }
                else {
                    //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }

            } catch (e) {

            }

        }
        /**
         * Method to call external account API to validate EA
         * @param payloadData : request payload
         */
        async function createAPI(payloadData: any) {
            try {
                const url = '/token'
                const data: any = await httpClient.post(getCustomerUrl(url, false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("createAPI", "error", err);
                return false;
            }
        }
        /**
         * Handle close
         */
        const handleClose = () => {
            props.close()
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'customer-api-token-list' } });
        }
        return (
            <div className="modal-content fx-generate-api-token" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)} className="fx-form-edit-profile">
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="generate-api-token-modal-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="generate-api-token-modal-title" variant="h4">
                                            Generate API Key
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='generate-api-token-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="generate-api-token-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2}  >
                                    <Grid item xs={12} className={'fx-margin-bottom-1'}>
                                        <Divider />
                                    </Grid>
                                    {!props?.apiSuccess ? <><Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("tokenName") }} control={control} rules={{ required: true }} className={errors.tokenName ? "border-error-input fx-input-edit" : "fx-input-edit"} id="generate-api-token-tokenname" name="tokenName" label="Key Name *" isEditable={true} />
                                    </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="filter-popover-label" >ASSIGN ROLES</Typography>
                                        </Grid>
                                        <Grid item xs={12} className='fx-assign-roles'>
                                            <FxMultiSelectChekEditAutoComplete
                                                register={{ ...register("role") }}
                                                id="generate-api-token-role"
                                                className={errors.role ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                placeholder="Search roles..."
                                                name='role'
                                                data={props?.rolesData}
                                                control={control}
                                                rules={{ required: true }}
                                                dataTransformation={customerDataTransformation}
                                                onChange={onRoleSearch}
                                                isEdit={true}
                                                label='Role *'
                                                setValue={setValue} />
                                        </Grid></>
                                        :
                                        <>
                                            <Grid item xs={12} className={'fx-generate-api-token-sub-title'}>
                                                <Typography>Here is your API key</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FxLabelWithCopy value={props?.generatedkey} truncateLength={25}></FxLabelWithCopy>
                                            </Grid>
                                            <Grid item container xs={12} className={'fx-generate-api-token-alert'}>
                                                <Grid item xs={'auto'}>
                                                    <AlertIcon />
                                                </Grid>
                                                <Grid item xs className={'fx-generate-api-token-alert-message'}>
                                                    <Typography>For security reasons, you cannot view or copy this key after closing this window. Only your key name will be visible</Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} className={'fx-margin-bottom-1 fx-margin-top-2 fx-padding-top-0'}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="generate-api-token-modal-footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    {!props?.apiSuccess && <><FxButton variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="generate-api-token-cancel-btn"
                                        onClick={() => props.close()}>
                                        Cancel
                                    </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id="save-edit-generate-api-key-btn"
                                            type="submit"
                                            isSubmitting={isSubmitting}
                                        > GENERATE
                                        </FxButton></>}
                                    {
                                        props?.apiSuccess && <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id="save-edit-generate-api-key-btn"
                                            onClick={() => handleClose()}
                                            isSubmitting={isSubmitting}
                                        > GO TO API KEY LISTING
                                        </FxButton>
                                    }
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });




