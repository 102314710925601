import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Control, FieldErrors, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ReactComponent as DownArrow } from '../../../assets/svg/down-black-triangle-arrow.svg';
import { ReactComponent as UpArrow } from '../../../assets/svg/up-black-triangle-arrow.svg';
import countries from '../../../libs/utils/country/country.json';
import { Logger } from '../../../libs/utils/logger';
import { FxButton } from '../../Action/FxButton';
import { FxCardNumberEdit } from '../../Input/FxCardNumber/FxCardNumberEdit';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import {
  handleKeyDownChange,
  handleZipChange,
  renderError,
  renderPhoneError,
  setZipValidationOptions,
  setPhoneValidationOptions,
  renderAddressLine1Error,
  renderAddressLine2Error,
  renderCityNameError,
  setCVVValidation,
  setCardNumberValidation,
  renderCardNumberError,
  setCityNameValidation,
  setAddressLine2Validation,
  setAddressLine1Validation
} from '../../Utils/CommonBaseClass';
import usa_state from '../../Utils/usa_states.json';
import { AVSDetailsForm } from './AVSDetailsForm';


Logger.debug("AdditionalAVSInfo.tsx", "Addition Holder Detail and AVS info popup initializing");

type FieldValues = Record<string, string>;
type FormValueType = Record<string, any>;
type TFieldValues = Record<string, string | null>;

interface IAdditionalAVSInfo {
  id: string,
  open?: boolean,
  onClose: () => void,
  className?: string,
  metadata?: {
    amount?: string,
    register?: UseFormRegister<TFieldValues>,
    control?: Control<FieldValues>,
    setValue?: UseFormSetValue<any>;
    errors?: FieldErrors<TFieldValues>;
    cardNumber?: string,
    expiry?: string,
    cvv?: string,
    firstName?: string,
    lastName?: string,
    saveAdditionalAvsData: (data:any)=>any,
    data?: any,
    watch?: UseFormWatch<FormValueType>;
    isEdit?: boolean
  }
}


/**
 * This component handles the collect creation through payer via one time card method where payer can add extra information for their card
 */
export const AdditionalAVSInfo: React.FC<IAdditionalAVSInfo> = React.memo(({metadata, onClose}) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    control,
    setError,
    watch
  } = useForm();

  /**
   * Initializing the properties with default values for the address fields in the accordions
   */
  const [addressSame, setAddressSame] = useState<boolean>(false);
  const [cardDetailsOpen, setCardDetailsOpen] = useState<boolean>(true);
  const [cardHolderDetailOpen, setCardHolderDetailOpen] = useState<boolean>(false);
  const [avsDetailOpen, setAvsDetailOpen] = useState<boolean>(false);


  /**
   * Initializing the properties with default values for the address fields in the accordions when data is present already
   */
  useEffect(() => {
    setAddressSame(!!metadata?.data?.addressSame);
  }, [metadata?.data?.addressSame]);

  /**
   * handling the card values
   * @param cardNumber card number
   * @returns 
   */
  const handleCardComponentValue = (cardNumber:any) => {
    const data = JSON.parse(cardNumber);
    return data["value"];
  }


  /**
   * Handle Card Detail in case of expiry
   * @param exp expiry date
   * @returns 
   */
  const handleIncomingExpiryDate = (exp:any) => {
    return moment(exp, "DD/MM/YYYY").format("DD/MM/YYYY");
  }


  /**
   * initializing the card details properties received from the parent component
   */
  const cardRelatedDetails = {
    cardNumber: metadata?.cardNumber ? handleCardComponentValue(metadata?.cardNumber) : '',
    expiryDate: metadata?.expiry ? handleIncomingExpiryDate(metadata?.expiry) : '',
    cvv: metadata?.cvv || '',
    firstName: metadata?.firstName || '',
    lastName: metadata?.lastName || '',
  }

  const additionalInfoModalData = metadata?.data || {};

  /**
   * Async function handles the on submit of form
   * @param data : form request
   */
  async function saveAvsData(data:any) {
    data = { ...data, addressSame : addressSame }
    metadata?.saveAdditionalAvsData(data);
    onClose && onClose();
  }

  /**
   * Function to handle Close Button of the invoice popups
   */
  const handleClose = () => {
    onClose && onClose();
  };

  /**
   * Method handles the validation for schedule date
   */
  const scheduleDateValidation = {
    required: false,
    minDate: new Date(),
  };

  /**
   * Card details section toggle
   */
  const toggleCardDetails = () => {
    setCardDetailsOpen(prevState => !prevState);
  }

   /**
   * Handles the Card Holder details section toggle
   */
   const toggleCardHolderDetails = () => {
     setCardHolderDetailOpen(prevState => !prevState);
   }


   /**
   *  Handles the Address Verification section toggle
   */
  const toggleAvsDetail = () => {
     setAvsDetailOpen(prevState => !prevState);
  }

  /**
   * This Method handles the population of the Holder Address Values inside the AVS address details
   */
  const populateHolderAddressValuesInAvsDetails = () => {
    setValue('avsAddressLine1', getValues('holderAddressLine1'));
    setValue('avsCity', getValues('holderCity'));
    setValue('avsCountry', getValues('holderCountry'));
    setValue('avsState', getValues('holderState'));
    setValue('avsEmail', getValues('holderEmail'));
    setValue('avsPhone', getValues('holderPhone'));
    setValue('avsZip', getValues('holderZip'));
    setValue('avsFirstName', cardRelatedDetails.firstName || "");
    setValue('avsLastName', cardRelatedDetails.lastName || "");
  }


  return (
      <Grid
        container
        className={"fx-container  fx-advanced-options-card-collect"}
      >
        <Grid container className="fx-info-card ">
          {/** Card Details Details Accordian */}
          <Grid container xs={12} className={'fx-form-edit-profile'}>
            <Accordion
              expanded={cardDetailsOpen}
              onChange={() => toggleCardDetails()}
              className={"fx-additional-card-info-accordian"}
            >
              <AccordionSummary className="fx-additional-card-info-accordian-header-section">
                <Grid container xs={12}>
                  <Grid item xs={11} justifyContent={"flex-start"}>
                    <Typography
                      className="fx-summary-title"
                      variant="subtitle1"
                    >
                      CARD DETAILS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    {cardDetailsOpen ? <UpArrow/> : <DownArrow/>}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container xs={12}>
                  <fieldset className="fx-container-create-schdule-summery">
                    <legend>Basic Details</legend>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <FxCardNumberEdit
                          register={{ ...register('cardNumber') }}
                          className={
                            errors.cardNumber
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          type="number"
                          control={control}
                          setValue={setValue}
                          setError={setError}
                          value={cardRelatedDetails.cardNumber || ""}
                          defaultValue={cardRelatedDetails.cardNumber || ""}
                          clearErrors={clearErrors}
                          isEditable={false}
                          rules={setCardNumberValidation(false)}
                          id="create-one-time-collect-card-card-number-textbox"
                          label="Card Number*"
                          placeholder="Card Number"
                          name="cardNumber"
                          variant="outlined"
                          maxLength={20}
                        />
                        <div className={"error-message"}>
                          {errors.cardNumber &&
                            renderCardNumberError(errors.cardNumber)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxDateEdit
                          register={{ ...register("expiryDate") }}
                          className={
                            errors.expiryDate
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          control={control}
                          rules={scheduleDateValidation}
                          id="create-collect-onetime-card-form-card-expiry-date-textbox"
                          label="Expiry Date*"
                          name="expiryDate"
                          readOnly={true}
                          type="date"
                          variant="outlined"
                          value={cardRelatedDetails.expiryDate || ""}
                          setValue={setValue}
                          disabled={true}
                          month={true}
                          disablePast={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("cvv") }}
                          className={
                            errors.cvv
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          type="password"
                          control={control}
                          rules={setCVVValidation(false)}
                          id="create-collect-onetime-card-form-card-cvv-textbox"
                          label="CVV*"
                          name="cvv"
                          variant="outlined"
                          defaultValue={cardRelatedDetails.cvv || ""}
                          maxLength={3}
                          isEditable={false}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/** Card Holder Details Accordion */}
          <Grid container xs={12} className={'fx-form-edit-profile'} marginTop={"1rem"}>
            <Accordion
              expanded={cardHolderDetailOpen}
              onChange={() => toggleCardHolderDetails()}
              className={"fx-additional-card-info-accordian"}
            >
              <AccordionSummary className="fx-additional-card-info-accordian-header-section">
                <Grid container xs={12}>
                  <Grid item xs={11} justifyContent={"flex-start"}>
                    <Typography
                      className="fx-summary-title"
                      variant="subtitle1"
                    >
                      CARD HOLDER DETAILS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    {cardHolderDetailOpen ? <UpArrow/> : <DownArrow/>}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container xs={12}>
                  <fieldset className="fx-container-create-schdule-summery fx-invoice-details-address">
                    <legend>Holder Billing Address</legend>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("holderFirstName") }}
                          control={control}
                          rules={{ required: false }}
                          className={
                            errors.holderFirstName
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-first-name-textbox"
                          name="holderFirstName"
                          label="First Name*"
                          setValue={setValue}
                          isEditable={false}
                          defaultValue={cardRelatedDetails.firstName || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("holderLastName") }}
                          control={control}
                          rules={{ required: false }}
                          className={
                            errors.holderLastName
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-last-name-textbox"
                          name="holderLastName"
                          label="Last Name*"
                          setValue={setValue}
                          defaultValue={cardRelatedDetails.lastName || ""}
                          isEditable={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FxTextEdit
                          register={{ ...register("holderAddressLine1") }}
                          control={control}
                          rules={setAddressLine1Validation(false)}
                          className={
                            errors.holderAddressLine1
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-address-line-1-textbox"
                          name="holderAddressLine1"
                          label="Address Line 1"
                          defaultValue={additionalInfoModalData.holderAddressLine1 || ''}
                          setValue={setValue}
                          isEditable={true}
                        />
                        <div className={"error-message"}>
                          {errors.holderAddressLine1 &&
                            renderAddressLine1Error(errors.holderAddressLine1)}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <FxTextEdit
                          register={{ ...register("holderAddressLine2") }}
                          control={control}
                          rules={setAddressLine2Validation(false)}
                          className={
                            errors.holderAddressLine2
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-address-line-2-textbox"
                          name="holderAddressLine2"
                          label="Address Line 2"
                          isEditable={true}
                          defaultValue={additionalInfoModalData.holderAddressLine2 || ''}
                          setValue={setValue}
                        />
                        <div className={"error-message"}>
                          {errors.holderAddressLine2 &&
                            renderAddressLine2Error(errors.holderAddressLine2)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("holderCity") }}
                          control={control}
                          rules={setCityNameValidation(false)}
                          className={
                            errors.holderCity
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-city-textbox"
                          name="holderCity"
                          label="City"
                          isEditable={true}
                          setValue={setValue}
                          defaultValue={additionalInfoModalData.holderCity || ''}
                        />
                        <div className={"error-message"}>
                          {errors.holderCity && renderCityNameError(errors.holderCity)}
                        </div>
                      </Grid>
                      {watch('holderCountry') === "US" ?
                        <Grid item xs={12} sm={6}>
                          <FxMaterialSelect register={{ ...register('holderState') }}
                                            rules={{ required: false }}
                                            className={errors.holderState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                            control={control}
                                            id="additional-info-holder-state-select"
                                            name="holderState"
                                            data={usa_state}
                                            label="State"
                                            value={additionalInfoModalData.holderState || ''}
                                            setValue={setValue} />
                        </Grid>
                        : <Grid item xs={12} sm={6}>
                          <FxTextEdit
                            register={{ ...register('holderState') }}
                            control={control}
                            rules={{ required: false}}
                            className={errors.holderState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                            id="additional-info-holder-state-textbox"
                            name="holderState"
                            label="State"
                            defaultValue={additionalInfoModalData.holderState || ''}
                          />
                        </Grid>}
                      <Grid item xs={12} sm={6}>
                      <FxMaterialSelect register={{ ...register("holderCountry") }} rules={{ required: false }} 
                      className={errors.holderCountry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} 
                      id="additional-info-holder-country-textbox" name="holderCountry" data={countries.slice(1)} label="Country" 
                      setValue={setValue} readOnly={ true} value={'US'}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("holderZip") }}
                          control={control}
                          rules={setZipValidationOptions(false)}
                          className={
                            errors.holderZip
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-zip-textbox"
                          name="holderZip"
                          label="ZIP"
                          onChange={(e: any) => {
                            handleZipChange(e, setValue, setError, clearErrors);
                          }}
                          onKeyDown={(e: any) => {
                            handleKeyDownChange(e, setValue);
                          }}
                          isEditable={true}
                          defaultValue={additionalInfoModalData.holderZip || ''}
                        />
                        <div className={"error-message"}>
                          {errors.holderZip && renderError(errors.holderZip)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("holderEmail") }}
                          className={
                            errors.holderEmail
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          control={control}
                          rules={{ required: false }}
                          id="additional-info-holder-email-textbox"
                          label="Email"
                          name="holderEmail"
                          type="email"
                          variant="outlined"
                          isEditable={true}
                          defaultValue={additionalInfoModalData.holderEmail || ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxPhoneEdit
                          register={register}
                          control={control}
                          rules={setPhoneValidationOptions(false)}
                          setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          className={
                            errors.holderPhone
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="additional-info-holder-phone-textbox"
                          name="holderPhone"
                          label="Mobile Phone"
                          defaultValue={additionalInfoModalData.holderPhone || ''}
                        />
                        <div className={"error-message"}>
                          {errors.holderPhone &&
                            renderPhoneError(errors.holderPhone)}
                        </div>
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/** AVS Details Accordian */}
          <Grid container xs={12} className={'fx-form-edit-profile'} marginTop={"1rem"}>
            <Accordion
              expanded={avsDetailOpen}
              onChange={() => toggleAvsDetail()}
              className={"fx-additional-card-info-accordian"}
            >
              <AccordionSummary className="fx-additional-card-info-accordian-header-section">
                <Grid container xs={12}>
                  <Grid item xs={11} sm={11} justifyContent={"flex-start"}>
                    <Typography
                      className="fx-summary-title"
                      variant="subtitle1"
                    >
                      ADDRESS VERIFICATION SERVICE
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    {avsDetailOpen ? <UpArrow/> : <DownArrow/>}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <AVSDetailsForm
                  register={register}
                  watch={watch}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  defaultValues={additionalInfoModalData}
                  setError={setError}
                  handlePopulateAVSDetails={populateHolderAddressValuesInAvsDetails}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/** Footer Area - Cancel & Submit Buttons */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          className="fx-modal-footer"
        >
          <FxButton
            variant="contained"
            className="fx-button fx-button-cancel"
            id="add-additonal-avs-info-form-card-cancel-button"
            onClick={handleClose}
          >
            Cancel
          </FxButton>
          <span className="fx-padding-right-16" />
          <FxButton
            disableRipple={false}
            className={"fx-button fx-button-theme"}
            id={"create-invoice-form-card-submit-button"}
            isSubmitting={isSubmitting}
            onClick={handleSubmit((data) => saveAvsData(data))}
          >
              SUBMIT
          </FxButton>
        </Grid>
      </Grid>
  );
});
