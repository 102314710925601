import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { CreateContact } from './CreateContact';
/**
 * This component loads the Create Contact page
 */

export const ContactAddHome: React.FC =
    () => {
        /**
         * Default params for create contact
         */
        let objectParams: any = {
            openFxSnackBar: false,
            isloading: false,
            type: 'BUSINESS',
            recipientName: '',
            holderName: '',
            firstName: '',
            lastName: '',
            selectedPaymentMthod: [],
            fxSnackbarProps: {
                severity: "" as any,
                text: ""
            }
        }
        return (
            <Grid item container xs={12}>
                <Grid item xs={12} sm={12} >
                    <CreateContact id={"create-contact-component"} {...objectParams} ></CreateContact>
                </Grid>
            </Grid>

        )
    }
