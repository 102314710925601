import React from 'react';
import { ReactComponent as startedCongratulationCard } from '../../../assets/svg/LetsStartedCongratulationCard.svg';
import { FxDialogCard } from '../../Utils/FxDialogCard';
import { Grid, Typography } from '@mui/material';

interface IAcceptReviewAgreement {
    onButtonClick?: (event: any) => void;
}

export const AcceptReviewAgreement: React.FC<IAcceptReviewAgreement> = React.memo(({ onButtonClick }) => {
    return (
        <Grid xs={12} className={'fx-congratulation-card'}>
            <FxDialogCard
                messageContent={
                    <Grid container justifyContent={'center'} >
                    {/* <Typography className='fx-signup-stage-description'>Your request for the Passport Investment account has been submitted,and your account will be activated within minutes</Typography>\ */}
                    <p className='fx-congratulation-card-text1'>Your Treasure Investment account application has been successfully submitted</p>
                        <p className='fx-congratulation-card-text1'> and is currently under review with the investment advisor.</p>
                    
                </Grid>
                }
                Icon={startedCongratulationCard}
                title={'Congratulations!'}
                buttonText={'Continue to dashboard'}
                onButtonClick={onButtonClick}
            />
            <Grid container justifyContent={'center'}>
                <Typography className='fx-congratulation-card-text2'>It may take 3-5 days to complete the process. You can access the cash builder account dashboard to get further updates. </Typography>
            </Grid>
            
        </Grid>

    )
})