import React, { useEffect } from "react";
import { FormControl, FormControlProps, TextField, Autocomplete } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Controller, UseFormRegisterReturn, UseFormSetValue, ControllerProps } from "react-hook-form";
import clsx from "clsx";
import { ReactComponent as Selection } from '../../../assets/svg/select-arrow.svg';

/**
 * Component used for Auto search 
 */

type FxSelectAutoSearchType = ControllerProps & FormControlProps;

type TFieldValues = Record<string, any[]>;

interface FxSelectAutoSearchProps extends Partial<FxSelectAutoSearchType> {
    dataTransformation?: (data: any) => any;
    readOnly?: boolean;
    label: string;
    noResources?: any;
    name: string;
    setValue: UseFormSetValue<TFieldValues>;
    register: UseFormRegisterReturn;
    value?: any;
    data: any;
    source?: {
        method: string;
        url: string;
        baseUrl?: boolean;
    }
}

export const FxSelectAutoSearch: React.FC<FxSelectAutoSearchProps> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        let selectOptions: any = []
        /**
         * to set default value
         */
        useEffect(() => {
            if (props?.value && props.setValue) {
                props.setValue(props.name, props.value)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props?.value]);
        /**
         * function to set changed data
         * @param event :event
         * @param data :data
         */
        function handleChange(event: any, data: any) {
            if (props.setValue) {
                props.setValue(props.name, data.value)
            }
            if (props.onChange) {
                props.onChange(data);
            }
        }
        /**
         * Method used to maipulate data from api 
         * @returns :selected options
         */
        function dataTransformation() {
            let Options: any = [];
            if (props.dataTransformation && context?.data?.body?.resources) {
                Options = props.dataTransformation(context.data.body.resources);
            }
            else if (props.dataTransformation && context?.data?.body && props.noResources) {
                Options = props.dataTransformation(context.data.body);
            }
            else if (props.dataTransformation && context?.data?.body?.settings) {
                Options = props.dataTransformation(context.data.body.settings);
            }
            return Options;
        }

        selectOptions = props.source ? dataTransformation() : props.data ? props.data : [];

        /**
         * function to get label of default data
         * @param value :value
         * @param options :options
         * @returns label
         */
        function getLabel(value: any, options: any) {
            let label: any;
            options?.forEach((item: any) => {
                if (item.value === value) {
                    label = item.label
                }
            })
            return label
        }
        return (
            <FormControl variant="outlined" className={clsx("fx-input-edit", props.className)}>
                {selectOptions.length > 0 && <Controller
                    name={props.name}
                    control={props.control}
                    rules={props.rules}
                    render={({ field: { onChange, value }, fieldState }) => {
                        return (
                            <div className="fx-select-autosearch">
                                <Autocomplete
                                    id={props?.id}
                                    disablePortal
                                    disabled={props?.readOnly ? props?.readOnly : false}
                                    disableClearable={true}
                                    options={selectOptions}
                                    isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
                                    getOptionLabel={(option: any) => option?.label || ''}
                                    defaultValue={{ label: getLabel(props?.value, selectOptions), value: props?.value }}
                                    onChange={onChange = (event, value) => { handleChange(event.target.value, value) }}
                                    renderOption={(props, option: any, { selected }) => (
                                        <li {...props}>
                                            {option?.label}
                                        </li>
                                    )}
                                    renderInput={(params:any) => <TextField {...params}
                                        label={props?.label}
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                    popupIcon={<Selection />}
                                />
                            </div>
                        )
                    }}
                />}
            </FormControl>
        )
    })