/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react';
import { Grid, Typography, IconButton, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("DeactivateSchedule.tsx", "Deactivate Schedule initializing")
/**
 * This component handles the deactivate schedules
 */
export const DeactivatePolicy: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, control } = useForm();
        /**
         * Async function handles the api call for deactivating the policy
         * @param req : form request
         * @returns : response object
         */
        async function deactivatePolicy(req: any) {

            try {
                const url: any = 'approvalPolicy/id/' + props.approvalPolicyId + '/deactivate' ;
                const data: any = await httpClient.post(getCustomerUrl(url,false), req)
                return data;
            } catch (err) {
                Logger.error("deactivatePolicy.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles the cancelling form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                'comment': data.comment
            }
            let status: any = await deactivatePolicy(req);
            status = processAPIResponse(status);
            if (status.status) {
                //api success              
                props?.close(true);               
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

        // function for closing the modal
        function handleClose(){
            props?.close();
        }

        return (
            <div className="modal-content fx-policy-modal">
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="deactivate-policy-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-deactivate-policy-modal" className={'fx-policy-modal-title'}>
                                          Do you want to deactivate this policy ?
                                        </Typography>
                                        <Typography id="sub-title-deactivate-policy-modal" marginTop='0.5rem' className={'fx-policy-modal-info'}>
                                         New transactions would not require approvals, if you proceed.You can undo this action.
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='deactivate-policy-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="deactivate-policy-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit
                                            register={{ ...register("comment") }}
                                            className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            control={control}
                                            rules={{ required: true }}
                                            id="comment"
                                            label={"Comment *"}
                                            name="comment"
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="right" className="fx-modal-footer">
                                        <Button variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id={"cancel-policy-button"}
                                            onClick={handleClose}>
                                            Cancel
                                </Button>
                                        <span className="fx-padding-right-16" />
                                        <Button
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id={"deactivate-policy-button"}
                                            type="submit"
                                        >
                                           Deactivate Policy
                                        </Button>
                                    </Grid>
                                </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
