import React, { useEffect } from 'react'
import { Grid, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import { FxSkeltonList } from '../Cards/FxSkelton';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();
Logger.debug("EditContact.tsx", "Edit contact initializing");

/**
 * Component: EditContact
 * Usage: Update the Contact
 */
export const EditContact: React.FC<any> = React.memo((props) => {

        //#region Variable Declarations
        let context: any;
        let edit_contact_data: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, control } = useForm();

        const params = useParams<any>();
        const history = useHistory();

        useEffect(() =>() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        //#region Validations

        // Name validation constant
        const nameValidation = {
            required: false,
            maxLength: 45
        }

       //#region Assign Values to Variable
        const id = params.id
        if (context?.data?.body?.id) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Contact', backButton: '/payee/view/'+id} } });
            edit_contact_data = { ...context?.data?.body }
        }

        //#region Handle Functions
        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/payee/view/' + context.data.body.id)
            } else {
                history.push('/payees')
            }
        };

        /**
      * Method to handle on submit request
      * @param data : form data
      */
        async function onSubmit(data: any) {

            let req: any = {};
            let status: any;
            req = updateContactRequest(data)

            const successMessage = 'Contact Updated Successfully!';
            updateComponentParams(dispatch,props?.id,{'isloading':true})
            status = await updateContact(req);
            status = processAPIResponse(status);
            updateComponentParams(dispatch,props?.id,{'isloading':false});
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push('/payees');
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }

        }

        /**
         * Method to get the Ouside passport request
         * @param data
         * @returns
         */
        function updateContactRequest(data: any) {
            let request:any = {
                name: data.contactLabel
            }

            if(edit_contact_data?.contactType === 'INDIVIDUAL'){
                request.firstName = data.firstName;
                request.lastName = data.lastName;
            }else{
                request.legalName = data.legalName;
            }

            return request;
        }
        /**
         * Method to call api to update the contact
         * @param paylaoddata : request payload
         */
        async function updateContact(paylaoddata: any) {
            try {
                const url = '/contact/id/' + id
                const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("EditContact.tsx", "error", err);
                return err;
            }
        }

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid container id="edit-payees-main-grid" xs={12} className='fx-grid-list-content'>
                <Grid id="edit-payees-first-grid" item xs={12} sm={12} spacing={2}>
                    <Grid id="edit-payees-sub-grid" container justifyContent="center" alignItems="center" className="flex column">
                        <Grid id="edit-payees-second-grid" item xs={12} sm={8}>
                            <div className="fx-form-edit-profile flex column">
                                {<form id="edit-payees-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="edit-payees-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="edit-payees-form-card-header">
                                        </FxCardHeader>
                                        {dataLoadStatus ?
                                            <>
                                            <FxCardBody id="edit-payees-form-card-body"
                                                        className="fx-info-card fx-margin-top-reducer">
                                                <Grid container direction="row" spacing={1} className="fx-info-card-form">
                                                    {<>
                                                        {edit_contact_data && <>  <Grid item xs={12} sm={6}>
                                                            <FxTextEdit register={{ ...register("contactLabel") }}
                                                                        className={errors.contactLabel ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                        rules={nameValidation} control={control}
                                                                        id="edit-payees-form-card-display-label-textbox"
                                                                        label="Display Label*" name="contactLabel"
                                                                        variant="outlined"
                                                                        defaultValue={edit_contact_data?.name ? edit_contact_data?.name : ''}
                                                                        isEditable={true} />
                                                        </Grid> </>}
                                                        {(edit_contact_data?.contactType === 'BUSINESS' || edit_contact_data?.legalName) && <>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("legalName") }}
                                                                            className={errors.legalName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                            rules={nameValidation} control={control}
                                                                            id="create-payees-form-card-legalName-textbox"
                                                                            label="Legal Name*"
                                                                            name="legalName" variant="outlined"
                                                                            defaultValue={edit_contact_data?.legalName ? context.data.body.legalName : ''}
                                                                            isEditable={true} />
                                                            </Grid>
                                                        </>}
                                                        {(edit_contact_data?.contactType === 'INDIVIDUAL' || edit_contact_data?.firstName) && <>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("firstName") }}
                                                                            className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                            rules={nameValidation} control={control}
                                                                            id="edit-payees-form-card-firstname-textbox"
                                                                            label="First Name*"
                                                                            name="firstName" variant="outlined"
                                                                            defaultValue={edit_contact_data?.firstName ? edit_contact_data?.firstName : ''}
                                                                            isEditable={true} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("lastName") }}
                                                                            className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                            rules={nameValidation} control={control}
                                                                            id="edit-payees-form-card-lastname-textbox"
                                                                            label="Last Name*"
                                                                            name="lastName" variant="outlined"
                                                                            defaultValue={edit_contact_data?.lastName ? edit_contact_data?.lastName : ''}
                                                                            isEditable={true} />
                                                            </Grid></>}
                                                    </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                </Grid>
                                            </FxCardBody>
                                            <FxCardFooter id="edit-payees-form-card-footer" className="fx-footer">
                                                <Grid container direction="row" justifyContent="flex-end"
                                                      className="fx-modal-footer">
                                                    <FxButton variant="contained"
                                                              className="fx-button fx-button-cancel"
                                                              id="payee-edit-button"
                                                              onClick={handleClose}>
                                                        Cancel
                                                    </FxButton>
                                                    <span className="fx-padding-right-16" />
                                                    <FxButton
                                                        disableRipple={false}
                                                        className="fx-button fx-button-theme"
                                                        id={"payee-edit-submit-button"}
                                                        type="submit"
                                                        isSubmitting={props.isloading}
                                                    >
                                                        {props.submitButton}
                                                    </FxButton>
                                                </Grid>
                                            </FxCardFooter>
                                            </>
                                            :
                                            <FxSkeltonList height="10rem" />
                                        }
                                    </FxCard>
                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });