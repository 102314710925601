import React, { useEffect } from "react";
import { UseFormRegisterReturn, UseFormSetValue, ControllerProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { SelectProps } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ISource } from '../../../types/common.interfaces';
import { useDispatch } from "react-redux";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";

/**
 * Component used for location selection
 */
type FxLocationSelectComponentType = ControllerProps & SelectProps;

type TFieldValues = Record<string, string>;

export interface ISelectData {
    label: number | string | React.ReactElement;
    value: string | number;
    operator?: string
}

interface FxLocationSelectComponentProps extends Partial<FxLocationSelectComponentType> {
    name: string;
    helperText?: string;
    value?: any;
    setError: UseFormSetError<TFieldValues>;
    setValue: UseFormSetValue<TFieldValues>;
    clearError: UseFormClearErrors<TFieldValues>;
    onChange?: (event: any) => void;
    setParam?: (event: any) => void;
    onFocus?: (event: any) => void;
    register: UseFormRegisterReturn;
    data?: ISelectData[];
    dataTransformer?: (data: any) => any;
    noResources?: any;
    source?: ISource;
    prefix?: any;
    hidePlaceholderInOptions?: boolean;
    filterOptions?: any[];
    resetField: any;
    searchBy?: string;
    isEditable: boolean;
}

const FxLocationSelectComponent: React.FC<FxLocationSelectComponentProps> = React.memo((props) => {

    const dispatch = useDispatch();
    ({ props } = RegisterComponent(props));

    const { id, name, placeholder, label, value, className, register , control, rules, setValue, resetField, setError, clearError, setParam, isEditable } = props;
        
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const source = {
        url: "location/list",
        method: "POST",
        data: {
            pageNumber: 1,
            pageSize: 5,
            criteria: {
            filters: [
                {
                key: "status",
                operator: "eq",
                values: ["ACTIVE"]
                },
            ],
            },
        },
    }

    const filterOptions: any = props?.filterOptions || [{ label: "Doing Business As", value: "doingBusinessAs", operator: "like" }];

    /**
     * Function handles transformation of destination data
     * @param data : destination data array
     * @returns : array
     */
    function dataTransformer(data: any) {
        if(props?.dataTransformer){
            props?.dataTransformer(data);
        }else{
            return data?.map((item: any) => {
                const { address, doingBusinessAs, id } = item;
                const { addressLine1, addressLine2, city, state, zip } = address;
                const address2String = addressLine2 ? addressLine2 + ', ' : '';
        
                const label = `${doingBusinessAs} (${addressLine1}, ${address2String}${city}, ${state}, ${zip})`;
        
                return {
                    label,
                    value: id,
                };
            }) || [];
        }
    }

    const searchBy = props?.searchBy || "doingBusinessAs";

      return (
            <>
                <FxLabel className="fx-label-top" id={id+'label'}>{label}</FxLabel>
                <FxAutoComplete
                    register={register}
                    control={control}
                    rules={rules}
                    id={id+'search'}
                    name={name}
                    source={source}                        
                    searchBy={searchBy}
                    className={className ? className : 'fx-input-edit'}
                    dataTransformer={dataTransformer}
                    setError={setError}
                    clearError={clearError}
                    setValue={setValue}
                    resetField={resetField}
                    filterOptions={filterOptions}
                    placeholder={placeholder || 'Search Location...'}
                    isEditable={isEditable}
                    setParam={setParam}
                    value={value}
                />
            </>
        )
    }
)

export default FxLocationSelectComponent;






