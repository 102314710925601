/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Logger } from '../../../libs/utils/logger';
import { Grid, Typography } from '@mui/material';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { getKey } from '../../../libs/utils/storageManager';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import UIFeatureImpl from '../../../libs/services/uiFeatures';


Logger.debug("ScheduleVirtualCardHolderDetails.tsx", "ScheduleVirtualCardHolderDetails initializing")
/**
 * This component handles the top section of the schedules
 */

const cardHolderTypeData=[
    { label: "Authorized User", value: "Authorised_User" },
  ]

export const ScheduleVirtualCardHolderDetails: React.FC<any> = React.memo(
    (props) => {
      let context: any;
      ({ context, props } = RegisterComponent(props));
        const {register, control, errors, setValue, clearErrors} = props;
        const dispatch = useDispatch();
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available
        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-send-contact-virtual-authorized_users' } });
        },[]);

    /**
     * Function used to fetch default value from state
     */
    const authState = useSelector((state: any) => {
      if (state.data['create-send-contact-virtual-authorized_users']) {
          return state.data['create-send-contact-virtual-authorized_users']
      } else {
          return { config: { params: {} } };
      }
   });

   /**
     * On change handler for card holder
     */
   const onCardHolderChange=(event: any)=>{
    const value = event.target.value;
    props?.onCardHolderChange({
      type:"AUTHORIZED_USER",
      id:value
    })       
  }
  
   /**
    * Function used to dispatch authorized user list from api for dropdown
    * and dispatch default value
    */
   useEffect(() => {
      if(context?.data?.body?.resources){
        const authData:any = [{ label: 'Select', value: ''}]
        context?.data?.body?.resources?.forEach((item: any) => {
            if (item && item.actAsAuthorizedSignatory) {
               dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": "create-send-contact-virtual-authorized_users", data: item.id } });
               setValue('authorizedUser', item.id)
            }
            const label = item.firstName + ' ' + (item.middleName ? item.middleName + ' ' : ' ') + item.lastName
            authData.push({ 'label': label, 'value': item.id })
        })
        updateComponentParams(dispatch, props.id, { 'data': authData })
      }
    },[context?.data]);

        return (
            <>
                <Grid id={props.id} item container xs={12} sm={12}>
                      <Grid xs={12} sm={12}>
                        <Typography id="contact-virtual-card-detail" className="fx-create-schedule-subheading">
                          CARD HOLDER DETAILS
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <fieldset id="contact-virtual-card-detail-field-set" className="fx-container-create-schdule-summery fx-container-create-schdule-middle-scroll">
                            { businessCustomer &&
                                <Grid item xs={12} sm={12}>
                                <Grid item xs={12} sm={12}>
                                  <FxMaterialSelect
                                      register={{ ...register("card_holder_type") }}
                                      className={
                                        errors.card_holder_type
                                          ? "border-error-input fx-input-edit"
                                          : "fx-input-edit"
                                      }
                                      rules={{ required: true }}
                                      control={control}
                                      id="create-send-contact-virtual-form-card-holder-type"
                                      name="card_holder_type"
                                      data={cardHolderTypeData}
                                      readOnly={true}
                                      value={'Authorised_User'}
                                      label="Card Holder Type*"
                                      setValue={setValue}
                                    />
                                  </Grid>
                              </Grid>
                            }

                            { individualCustomer &&
                                <Grid item xs={12} sm={12}>
                                <FxTextEdit
                                    register={{ ...register("cardHolderName") }}
                                    className={
                                    errors.cardHolderName
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                    }
                                    control={control}
                                    id="create-send-contact-virtual-card-holder-name-textbox"
                                    label="Card Holder Name"
                                    type="text"
                                    name="cardHolderName"
                                    variant="outlined"
                                    defaultValue={getKey('CustomerIndividualFullName')}
                                    isEditable={false}
                                />
                              </Grid>
                            }
                            {context?.params?.data && <Grid item xs={12} sm={12}>
                              <FxMaterialSelect
                                register={{ ...register("authorizedUsers") }}
                                id="create-send-contact-virtual-authorized_users"
                                name="authorizedUser"
                                control={control}
                                data={context?.params?.data}
                                value={authState?.data}
                                label="Select Authorized User"
                                rules={{ required: true }}
                                noResources={true}
                                clearError={clearErrors}
                                className={
                                  errors.beneficialOwner
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                setValue={setValue}
                                onChange={onCardHolderChange}
                              />
                            </Grid>}
                          </fieldset>
                        </Grid>
                    </Grid>
            </>
        )
    });
