import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, IconButton, Modal, Typography, Divider, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useMediaQuery } from "react-responsive";
import { FxTextEdit } from '../../Input';
import { useForm } from 'react-hook-form';
import { FxButton } from '../../Action/FxButton';
import { dataSourceTransformation, dataSourceTransformationTreasure, setAmountValidationOptions } from '../../Page/Schedules/ScheduleGlobalFunctions';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, updateComponentParams } from '../../Utils/CommonBaseClass';
import moment from 'moment';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { LogoAccount } from '../../Utils/Logo';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { AuthenticationVerificationModal } from '../../Page/AuthenticationModal/AuthenticationVerificationModal';
import { getKey } from '../../../libs/utils/storageManager';

const httpClient = HttpClient.getClient();

Logger.debug("FxInvestmentAccountTransferModal.tsx", "View Balance Detail Modal initializing")

/**
 * This component handles the View Balance Detail Modal
 */
export const FxInvestmentAccountTransferModal: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch()

        const { register, formState: { errors }, handleSubmit, setValue, control, watch, getValues } = useForm();

        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })        

        /**
       * useEffect to set default address 
       */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { fromAccount: null, fromAccountDetails:null, verifyProps })
            updateComponentParams(dispatch, 'investment-account-balance-card', { 'transferSuccess': false, transferSuccessMessage:{amount:null,
                description:null}})


            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
         * Method handles the onclick event for closing modal
        */
        const handleClose = (success: any, payload:any) => {
            if (props.onClose) {
                props.onClose(success);
                if(success){
                    updateComponentParams(dispatch, 'investment-account-balance-card', { 'transferSuccess': true, transferSuccessMessage:{amount:payload?.amount,
                        description:payload?.description}
                       })
                }
                
            }
        };

        /**
         * to get accountList details
        */
        const accountListInfo = useSelector((state: any) => state.data['customer-basic-info'] || null);

        const accountList = accountListInfo?.params?.accountList

        /**
        * useMemo to get options
        */
        const fromAccountOptions = useMemo(() => {
            return dataSourceTransformationTreasure(accountList?.resources || []);
        }, [accountList]);

        /**
        * useMemo to get options
        */
        const toAccountOptions = useMemo(() => {
            let accounts = accountList?.resources || [];
            if (props?.fromAccount) {
                const fromAccount = accounts.find((x:any)=>x.id === props.fromAccount);
                if(fromAccount?.type === 'CASH_BUILDER'){
                    accounts = accounts.filter((x: any) => (x.id !== props.fromAccount && !(x.type === 'CASH_BUILDER_PLUS')))
                }else{
                    accounts = accounts.filter((x: any) => x.type === 'CASH_BUILDER')
                }
                
            }
            return dataSourceTransformation(accounts)
        }, [accountList, props?.fromAccount]);

        /**
         * function to disable transfer button
         */
        const setTransferButtonDisabled = useMemo(() => {
          if(getValues('amount') && getValues('from') && getValues('to')){
            return false
          }  
          return true 
           // eslint-disable-next-line react-hooks/exhaustive-deps  
        },[watch()])

        const onFromAccountChangeHandler = async (event: any) => {
            const selectedAccount = event.target.value;
            updateComponentParams(dispatch, props?.id, { fromAccount: selectedAccount })

            await getAccountDetails(selectedAccount)
            
        }

        /**
        * Create transfer transaction
        */
        const createTranferTransaction = async (payload: any) => {
            try {
                const data: any = await httpClient.post(getCustomerUrl('/transaction', false), payload).then(response => {
                    return response
                }).catch((error) => {
                    return { ...error };
                })
                return data;
            }
            catch (err) {
                Logger.error("FxInvestmentAccountTransferModal.tsx", "error", err);
                return err;
            }
        }

          /**
        * function to get account data and storage quick settle configuration
        * @param id :account id
        */
        async function getAccountDetails(id: number) {
            try {
                const res = await httpClient.get(getCustomerUrl("account/id/" + id));       
                updateComponentParams(dispatch, props?.id, { fromAccountDetails: res.data })           
            } catch (e) { }
        } 

         /**
        * Get helper text for from accunt balance.      
        */
        const fromAccountHelpText = useMemo(()=>{
            const accountDetails = props?.fromAccountDetails;           
            return props?.fromAccount ? (
                <FormHelperText>
                    {'Available Balance'}&nbsp;
                    <FxCurrencyView value={accountDetails?.availableBalance?.amount} prefix={"$"} />
                </FormHelperText>
            ) : null;

        },[props?.fromAccountDetails, props?.fromAccount]);  

        /**
        * Extract fx success description.
        * @param from : from account id
        * @param to : to account id
        */
        const extractTransferScuccessDescription=(from:any, to:any)=>{
            const accountLabels:any = {};
            fromAccountOptions.forEach((account:any) => {
              accountLabels[account.value] = account.label;
            });
            const fromLabel = accountLabels[from];
            const toLabel = accountLabels[to];

            return `Money Transferred from ${fromLabel} to ${toLabel}`;
        }

        /**
        * Async function handles the create send form submission
        * @param data : form request
        */
        async function onSubmit(data: any) {       
            const payload = {
                amount: data.amount,
                method: "BOOK",
                type: "REGULAR",
                source: {
                    account: {
                        id: data.from
                    }
                },
                destination: {
                    account: {
                        id: data.to
                    }
                },
                scheduleDate: moment(formatDate(new Date())).format('MM/DD/YYYY'),
                allowDuplicate: true,
                purpose: "Investment",
                //allowDuplicate:false               
            }

            let status = await createTranferTransaction(payload);
            status = processAPIResponse(status)
            
            if (status.status) {
                //api success   
                const postSuccessActions = () => {
                    handleClose(true, {
                        amount:data.amount,
                        description:extractTransferScuccessDescription(data.from,data.to),                    
                    });
                }
                if(status.responseData?.sessionId){
                    const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                    const verifyProps = {
                      open: true,
                      channelTitle:`To verify quick send for $${payload.amount}, Passport will send you a one-time code. Choose how to receive your code.`,
                      subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to complete verification.`,
                      url: getCustomerUrl('/transaction', false),
                      req: payload,
                      initiationDetails: status.responseData,
                      resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
                      postSuccessEvent: postSuccessActions,
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                  }
                  else{
                    postSuccessActions();
                  } 
            } else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
         /**
         * Function to handle the closing of the modal.
         */
        const handleCloseModal = () => {
            updateComponentParams(dispatch, props.id, { verifyProps: { open: false } });
        };

        return ( 
            <Grid container className={"fx-container"}>
                <FxModalGeneric
                    id="investment-account-transfer-otp"
                    open={props.verifyProps?.open}
                    onClose={handleCloseModal}
                    componentProps={props.verifyProps}
                    className={'fx-modal-small2medium'}
                    component={AuthenticationVerificationModal}
                >
                </FxModalGeneric>
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={props.open}
                    onClose={()=>handleClose(false, null)}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className={isTabScreen ? "fx-modal fx-modal-medium" : 'fx-modal fx-modal-small2medium'}
                >
                    <div className="modal-content fx-invest-now-card" >
                        <form id={`investment-account-transfer-form`} className="fx-form-edit-profile flex column" onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body">
                                <FxCard className="fx-theme-passport">
                                    <FxCardHeader>
                                        <Grid container direction="row" item xs={12} justifyContent="space-between">
                                            <Grid item container xs={11} justifyContent="flex-start">
                                                <Typography variant="h2" className='fx-header-title'>
                                                    Transfer
                                                </Typography>
                                            </Grid>
                                            <Grid className="fx-modal-close-icon" item xs={1}>
                                                <IconButton
                                                    onClick={()=>handleClose(false, null)}
                                                    size="large">
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </FxCardHeader>
                                    <FxCardBody className="fx-info-card fx-investment-account-transfer-modal-body" >
                                        <Grid container direction="column" spacing={2} className="fx-modal-form flex column" >
                                            <Grid item container xs={12} className='fx-investment-amount'>
                                                <FxTextEdit register={{ ...register("amount") }}
                                                    className={errors['amount'] ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                    control={control} rules={setAmountValidationOptions} prefix="$"
                                                    id="quick-transfer-send-book-form-card-amount-textbox" label="Amount*" name="amount"
                                                    type="number" variant="outlined" valuePattern={/-|\+|e|E/}
                                                    showDecimal={true} setValue={setValue} onWheel={(e: any) => e.target.blur()}
                                                    placeholder='0.00'
                                                />
                                            </Grid>
                                            <Grid item container xs={12} className='fx-investment-amount'>
                                                <FxMaterialSelect
                                                    name={"from"}
                                                    control={control}
                                                    rules={{ required: true }} register={{ ...register("from") }}
                                                    id="investment-account-transfer-from"                                                                                           
                                                    label='From'
                                                    data={fromAccountOptions}
                                                    setValue={setValue}
                                                    onChange={onFromAccountChangeHandler}
                                                    placeholder={'Select Passport A/c'}
                                                    prefix={<LogoAccount />}
                                                />     
                                                {fromAccountHelpText}                                           
                                            </Grid>
                                            <Grid item container xs={12} className='fx-investment-amount'>
                                                <FxMaterialSelect
                                                    name={"to"}
                                                    control={control}
                                                    rules={{ required: true }} register={{ ...register("to") }}
                                                    id="investment-account-transfer-to"                                                   
                                                    label='To'
                                                    data={props?.fromAccount ? toAccountOptions : []}
                                                    setValue={setValue}
                                                    placeholder={'Select Passport A/c'}
                                                    prefix={<LogoAccount />}
                                                />
                                            </Grid>
                                            <Grid item container xs={12}>
                                                <Typography variant="h5" className='fx-header-title'>
                                                    By clicking Transfer now, I authorize Passport to initiate the money movement detailed above.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid container columnSpacing={2} justifyContent='end'>
                                                <Grid item>
                                                    <FxButton
                                                        className="fx-button fx-button-cancel"
                                                        title='Invest Now'
                                                        id="invest-now-button"
                                                        onClick={()=>handleClose(false, null)}
                                                    >
                                                        CANCEL
                                                    </FxButton>
                                                </Grid>
                                                <Grid item>
                                                    <FxButton
                                                        className={setTransferButtonDisabled ? "fx-button fx-button-disabled": "fx-button fx-button-theme"}
                                                        title='Transfer'
                                                        id="tranfer-button"
                                                        type='submit'
                                                        disabled={setTransferButtonDisabled}
                                                    >
                                                        TRANSFER NOW
                                                    </FxButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FxCardBody>
                                </FxCard>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Grid>
        );
    })