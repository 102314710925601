/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid,  Typography,  CircularProgress, Divider} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Logger } from "../../../libs/utils/logger";
import HttpClient from "../../../libs/utils/httpClient";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxButton } from "../../Action/FxButton";
import { RolePermissions } from "./RolePermissions";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { ReactComponent as EditIcon } from '../../../assets/svg/transaction-edit.svg';
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import FxSnackBar from "../../Utils/fx-snack-bar";
const httpClient = HttpClient.getClient();

Logger.debug("RoleInformation.tsx", "Role information initializing");
export const RoleInformation: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    resetField,
    setError,
    clearErrors
  } = useForm();

  const submitButton = "Save Role";
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const src = {
    url: "/role/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 50,
      "baseUrl": true,
      "criteria": {
        "filters": [

        ]
      }
    }
  }
  const dispatch = useDispatch();
  const params = useParams<any>();
  const id = params.id
  const rolesData:any=[];
  let selectedValue=''
  let preSelectedRole = ''
  if(context?.data?.body)
  {
    context?.data?.body?.resources?.forEach((option:any)=>{
      rolesData.push({ value: option.id, label:option.name})
      if(option.name.toLowerCase() ==='admin') {
        selectedValue = option.id
        preSelectedRole = option.name
      }
    })
  }
  /**
   * Method to handle changes on event
   * */
  const handleChange = (value: any) => {
    const source = {
      method: 'GET',
      url: "/role/id/" + value.value
    }
    updateComponentParams(dispatch, "role-permissions", { valueChanged:  value.value })
    const newProps: any = { id: 'role-permissions', source: source, roleId:  value.value };
    dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
  }

  /**
   * Method to handle cancel click event
   * */
  const handleCancelClick = () => {
       history.push('/roles')
  }

  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props?.id } });
  }, [])

  useEffect(()=>{
    if(context?.data?.body)
    {
      if (id) {
        setIsEdit(true)
        setIsView(true)

        const source = {
          method: 'GET',
          url:  "/role/id/" + id
        }
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'View Role', backButton: '/roles' } } });
        if(props.allRoles)
        {
          const newProps: any = { id: 'role-permissions', source: source, roleId: id };
          dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
        }
      } else
        if(selectedValue !== '' && id === undefined)
        {
          const source = {
            method: 'GET',
            url: "/role/id/" + selectedValue
          }
          const newProps: any = { id: 'role-permissions', source: source, roleId: selectedValue };
          dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
          dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Role', backButton: '/roles' } } });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context?.data?.body,props.allRoles])


    const history = useHistory();
  /**
   * Method to handle on submit request
   * */
  async function onSubmit(data: any) {

    const request=setRolesRequest(data)
    updateComponentParams(dispatch,props?.id,{'isloading':true})
    let successMessage = 'Role Created Successfully!';
        let roleStatus:any
        if(!id)
        {
        roleStatus=   await createRole(request);
        }
        else
        {
          successMessage = 'Role Updated Successfully!';
          roleStatus=   await updateRole(request);
        }

    const status = processAPIResponse(roleStatus)

    if (status.status) {
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: successMessage,
      });
      updateComponentParams(dispatch,props?.id,{'isloading':false});
      history.push('/roles')
    }
    else {
      FxSnackBar.show({
        text: status.message,
      });
      updateComponentParams(dispatch,props?.id,{'isloading':false});
    }

  }
  let  permissionIds:any=[]
  const rolePermissionsDefaultCheckedState = useSelector((state: any) => {
    if (state.data['role-permissions-default-checked']) {
        return state.data['role-permissions-default-checked']
    } else {
        return { config: { params: {} } };
    }
});

 /**
    * Method fetching account object access data from state
    */
 const accountObjectAccessState = useSelector((state: any) => {
  if (state.data['account-object-access']) {
      return state.data['account-object-access']
  } else {
      return { config: { params: {} } };
  }
});



  /**
   * Method checking any of the permission item gets selected
   */

  const checkPermission = (permissionArray: any) => {
      const permissions: any = []
      if (rolePermissionsDefaultCheckedState?.data) {
          rolePermissionsDefaultCheckedState?.data.forEach((item: any) => {
              item.forEach((row: any) => {
                  permissions.push(row)
              })
          })

      }
      if (permissionArray) {
          permissionArray?.forEach((item: any) => {
              item.forEach((row: any) => {
                  permissions.push(row)
              })
          })
      }
      if ((permissions.length > 0) || (accountObjectAccessState?.data && getIdFromArray(accountObjectAccessState?.data).length > 0)) {
          setCanSubmit(false);
      } else {
          setCanSubmit(true);
      }
  }


  /**
   * Binding the request
   */
    const componentState = useSelector((state: any) => {
      permissionIds = []
      if(props?.rolesData) {
          props?.rolesData?.restrictedEntity?.forEach((item: any) => {
          if (state.data[item?.entity] && state.data[item?.entity]['params'] && state.data[item?.entity]['params']['tableData']) {
           permissionIds.push(getPermissionIds(state.data[item?.entity]['params']['tableData']))
          }
       })
      }
      return permissionIds;
  });
  const getIdFromArray = (data: any) => {
    try{
      if(!data){
        return;
      }
      const newData: any = [];
      data?.forEach((item: any)=>{
        if(item.id && item.checked){
          newData.push(item.id)
        }
      })
      return newData;
    }
    catch(e){
      Logger.error("RoleInformation.tsx", "error", e);
    }
  }

  useEffect(() => {
    if(componentState || accountObjectAccessState){
      checkPermission(permissionIds);
    }
  }, [componentState,accountObjectAccessState])

  function getPermissionIds(data: any) {
    const permissionIds: any = [];
    if (data) {
        data.forEach((item: any) => {
            item?.permissions.forEach((permission: any) => {
                if (permission.value && permission.value === 'true') {
                    permissionIds.push(permission.id)
                }
            })
        })
    }
    return permissionIds
}


/**
 * Method for handle click
 * @param event
 */
  const handleClick = () => {
    setIsView(false)
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Role', backButton: '/roles' } } });
    setIsEdit(false)

  }

   /**
     * Method fpor creating the roles request
     * @param data
     * @returns
     */
  function setRolesRequest(data:any)
  {
    const permissions:any=[]
    if(rolePermissionsDefaultCheckedState?.data)
    {
      rolePermissionsDefaultCheckedState?.data.forEach((item:any)=>{
       item.forEach((row:any)=>{
        permissions.push(row)
       })
      })

    }
    if(permissionIds)
    {
      permissionIds.forEach((item:any)=>{
        item.forEach((row:any)=>{
          permissions.push(row)
         })
      })
    }
    const request:any={
      name:data.roleName,
      description: data.roleDescription !== "" ? data.roleDescription: undefined,
      restrictedEntity:[]
    }

    request['parentRole']={      
      id: data.parentRole ? data.parentRole : (props.parentRole && props.parentRole?.id ? props.parentRole?.id : selectedValue)
    }

    if(permissionIds)
    {
      request['restrictedEntity']=permissions
    }
    request['restrictedObject'] = [];
    if(accountObjectAccessState?.data && getIdFromArray(accountObjectAccessState?.data).length>0){
      request['restrictedObject'].push({ id: getIdFromArray(accountObjectAccessState?.data), entity: 'Account'})
    }
    else {
      delete request['restrictedObject'];
      request['hasGlobalObjectAccess']= true;
    }
    return request
  }
     /**
     * Method for creating the roles
     * @param payloadData
     * @returns
     */
  async function createRole(payloadData: any) {
    try {
        const data: any = await httpClient.post(getCustomerUrl('/role',false), payloadData).then(response => {
            return response
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {
        Logger.error("createRole", "error", err);
        return err;
    }
}
   /**
     * Method for updating the roles
     * @param payloadData
     * @returns
     */
async function updateRole(payloadData: any) {
  try {
      const data: any = await httpClient.post(getCustomerUrl('/role/id/'+id, false), payloadData).then(response => {
          return response
      })
          .catch((error) => {
              return { ...error };
          })
      return data;
  } catch (err) {
      Logger.error("createRole", "error", err);
      return err;
  }
}

  useEffect(() => {
    if(id !== undefined){
      if(props.roleName){
        setValue('roleName',props.roleName)
      }
      if(props.roleDescription){
        setValue('roleDescription',props.roleDescription)
      }
    }
  }, [props.roleName,props.roleDescription])

  /**
  * Method to transform Parent Role data as dropdown options
  * @param data
  */
function parentRoleTransformer(data: any) {
  try {
      return data ? data.map((item: any) => {
          return { label: item.name, value: item.id };
      }) : []
  }
  catch (e) {
    Logger.error("RoleInformation.tsx", "error", e);
  }

}


  return (
     <>
       {  rolesData && rolesData.length>0 &&
         <form id="create-role-form" onSubmit={handleSubmit(onSubmit)}>
              <FxCard id="create-role-form-card" className="fx-theme-passport">
                <FxCardHeader id="create-role-form-card-header">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    xs={12}
                  >

                  <Grid item xs={11} >
                  </Grid>
                  <Grid id="collect-info-transaction-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">

            </Grid>
            <Grid item  alignItems="flex-end"
            >
              {isEdit &&
                <FxButton id="create-role-form-card-edit" variant="contained" className="fx-button  fx-button-action " permissions={{entity: "Role",name: "Role",operation: "Edit"}} startIcon={<EditIcon />} onClick={handleClick}>Edit</FxButton>
              }
            </Grid>

                  </Grid>

                </FxCardHeader>
                <FxCardBody
                  id="create-user-form-card-body"
                  className="fx-info-card"
                >
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className="fx-form-edit-profile flex column"
                  >
                    <Grid item sm={12 } xs={12}>
                    <Typography id="title-usermodal" variant="h6" className={'fx-role-header'}>
                      Role Information
                    </Typography>
                    <br></br>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FxTextEdit
                        register={{ ...register("roleName") }}
                        isEditable={!isEdit}
                        className={
                          errors.roleName
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        control={control}
                        rules={{ required: true }}
                        id="create-role-form-card-role-name-textbox"
                        label="Role Name*"
                        name="roleName"
                        variant="outlined"
                        defaultValue={
                          props?.roleName && (isEdit || isView)
                            ? props?.roleName
                            : ""
                        }
                      />
                    </Grid>
                {id === undefined && <Grid item xs={12} sm={6} className="parent-role">
                  <Typography className="fx-label-top" id="create-role-form-card-role-type">Parent Role*</Typography>
                  <FxAutoComplete register={{ ...register("parentRole") }}
                    control={control}
                    id="create-role-form-card-role-type" name="parentRole"
                    source={src} searchBy={'name'} setParam={handleChange}
                    value={preSelectedRole}
                    placeholder={preSelectedRole}
                    dataTransformer={parentRoleTransformer} setValue={setValue} resetField={resetField} setError={setError}
                    clearError={clearErrors} defaultOperator={'like'}
                  />
                </Grid>}
                    <Grid item xs={12} sm={6}>
                      <FxTextEdit
                        register={{ ...register("roleDescription") }}
                        isEditable={!isEdit}
                        className={
                          errors.roleDescription
                            ? "border-error-input fx-input-edit"
                            : "fx-input-edit"
                        }
                        control={control}
                        rules={{ required: false }}
                        id="create-role-form-card-role-description-textbox"
                        label="Role Description"
                        name="roleDescription"
                        variant="outlined"
                        defaultValue={
                          props?.roleDescription && (isEdit || isView)
                            ? props?.roleDescription
                            : ""
                        }
                      />
                    </Grid>
                     { <RolePermissions  readOnly={isEdit} id="role-permissions" data={props?.allRoles}  />}
                    </Grid>
                </FxCardBody>
                <FxCardFooter
                  id="create-user-form-card-footer"
                  className="fx-footer"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    className="fx-modal-footer"
                  >
                      <Grid item xs={12} className="fx-divider-account">
                          <Divider />
                      </Grid>
                    <FxButton
                      variant="contained"
                      className="fx-button fx-button-cancel"
                      id="create-user-form-card-cancel-button"
                      onClick={handleCancelClick}
                                     >
                      Cancel
                    </FxButton>
                    <span className="fx-padding-right-16" />
                    {!isView && <FxButton
                      disableRipple={false}
                  className = {canSubmit ? "fx-button fx-button-disabled" : "fx-button fx-button-theme"}
                  id={"create-user-form-card-" + submitButton + "-button"}
                  type="submit"
                  disabled={canSubmit}
                    >
                      {props?.isloading  ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: "white",
                          }}
                        />
                      ) : (
                        submitButton
                      )}
                    </FxButton>}
                  </Grid>
                </FxCardFooter>
              </FxCard>
            </form>
}
            </>

  );
});
