import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch, useSelector } from 'react-redux';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';

    /**
     * Component used to view Customer Beneficial Owners list
     */
    const column: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "ID",
                "enable": true,
                "fieldName": "id",
                "type": "fri://fieldtype/default",
                "default": true,
                "style": { "width": "20%" }
            },
            {
                "label": "Name",
                "enable": true,
                "fieldName": "fullName",
                "type": "fri://fieldtype/default",
                "default": true,
                "style": { "width": "25%" }

            },
            {
                "label": "Owner Percentage",
                "enable": true,
                "fieldName": "ownershipPercentage",
                "type": "fri://fieldtype/default",
                "default": true,
                "style": { "width": "20%" }
            },
            {
                "label": "Type",
                "enable": true,
                "fieldName": "type",
                "type": "fri://fieldtype/default",
                "default": true
            },
        ]
    }

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }

        ]
    }
    /**
    * Method to transform data
    * @param data
    * @returns transformed data object for mobile view
    */
    const newDataMobile =(data:any)=>{
        var length=data?.length-1;
        const transformedData = data?.map((item: any,index:any) => (
            length===index?{
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createDocumentDetails(item)}
                    </Grid>
                )}
            :
            { description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createDocumentDetails(item)}
                    </Grid>
                ),divider: createDivider(),
            }));
        return transformedData || [];
    };

    /**
     * Grid lay out for mobile
     * @param item 
     * @returns 
     */
    const createDocumentDetails = (item: any) => (
    
        <> <Grid item container xs={12} alignItems='center'>

        <Grid item container xs={10}>
                <Typography  variant='h4'>ID:{item?.id}</Typography>
        </Grid>

        <Grid item container xs={2}>
            <Typography variant='h4' justifyContent="flex-end"> {item?.ownershipPercentage}%</Typography>  
        </Grid>

        <Grid item container xs={12}>
            <Typography variant='h4'> {item?.fullName}</Typography>
        </Grid>

        <Grid item container xs={12}>
            <Typography variant='h4'> {item?.type}</Typography>
        </Grid>
    </Grid>
    </>
    );

    /**
    * function to create divider to separate data
    */
    const createDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    );

export const BeneficialOwners: React.FC<any> = React.memo(
    (props: any) => {
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        const componentstate = useSelector((state: any) => {
            if (state.data["company-info-card-profile"]) {
                return state.data["company-info-card-profile"]
            } else {
                return { config: { params: {} } };
            }
        });
        let beneficial_owners_data: any = [];
        let newData: any = [];
        if (componentstate && componentstate.data && componentstate.data.body && componentstate.data.body.business && componentstate.data.body.business.beneficialOwner) {
            if (componentstate.data.body?.business?.beneficialOwner) {
                beneficial_owners_data = [...componentstate.data.body.business?.beneficialOwner]
            }
            /**
             * mapping used to format values from API and to load data in datagrid
             */
           newData = beneficial_owners_data.map((item: any) => {
               return {
                   id: item.id,
                   fullName: item.fullName,
                   ownershipPercentage: item.businessDetails.ownershipPercentage,
                   type: item.actAsAuthorizedSignatory === true ? 'Authorized Signatory, Beneficial Owner' : 'Beneficial Owner'
               }
           })
        }
        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //check api call completed or not
        const contextState = componentstate?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="company-profile-beneficial-owners-grid" item xs={12}>
                <FxCard id="company-profile-beneficial-owners-card" className=" fx-theme-passport">
                    <FxCardHeader id={'company-profile-beneficial-owners-card-header'} >
                        <Grid id="company-profile-beneficial-owners-card-header-grid" item container justifyContent="space-between" xs={12} >
                            <Typography id="company-profile-beneficial-owners-card-title" variant="h4" >Beneficial Owners</Typography>
                        </Grid>
                        <div id="company-profile-beneficial-owners-card-header-div" className="fx-flex-grow" />
                    </FxCardHeader>
                    <FxCardBody id="company-profile-beneficial-owners-card-body" className="fx-card-body-no-border-no-shadow">
                    {
                    dataLoadStatus ? (
                        !isMobile ? (
                        componentstate?.data?.body ? (
                            <DataGrid id="company-profile-beneficial-owners-card-list" tableClass="fx-table-passport"  data={newData} column={column}/>
                        ) : (
                            <FxSkeltonList height="5rem" />
                        )
                        ) : (
                        <FxGridList id="company-profile-beneficial-owners-card-list"data={newDataMobile(newData)} column={gridColumn}/>)
                    ) : (
                        <FxSkeltonList height="5rem" />
                    )
                    }
                    </FxCardBody>
                    <FxCardFooter id="company-profile-beneficial-owners-card-footer" className="fx-footer">
                        <Grid></Grid>
                    </FxCardFooter>
                </FxCard>
            </Grid>
        )
    })
