
/**
 * Component:LeadDetails
 * This component handles the  Leads View component
 */
import { Grid } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { LeadPreFilledInviteInfoView } from './LeadPreFilledInviteInfoView';
import { LeadBeneficialOwnerListView } from './LeadBenificialOwnerListView';
import { LeadMailingAddressListView } from './LeadMailingAddressListView';
import { useDispatch } from 'react-redux';
import { ViewInviteStatus } from '../Customer/ViewInviteStatus';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import moment from 'moment';
import { addressFormatter } from '../../../Utils/CommonBaseClass';

type ILeadDetailsView = {
    id: string;
    source: any;
    invitStatus:any
}
export const LeadDetailsView: React.FC<ILeadDetailsView> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    let benifilaOwner:any=[];
    let mailingAddress:any=[];

    if(context?.data?.body?.business)
    {
        context?.data?.body?.business?.beneficialOwner?.forEach((item:any)=>{
           
            benifilaOwner.push({
                id:item?.id?item?.id:'',
                name:(item?.firstName ? item?.firstName : '') + ' ' + (item?.lastName ? item?.lastName : ''),
                ownerPercentage:item?.businessDetails?.ownershipPercentage? item?.businessDetails?.ownershipPercentage:'',
                type:'Benificial Owner'
            })
        
        })
    
        context?.data?.body?.business?.mailingAddress?.forEach((item:any)=>{
            if(item.isPrimary===false){
            mailingAddress.push({
                id:item?.id?item?.id:'',
                address:addressFormatter('',item)
            })
        }
        })
    }
    else if(context?.data?.body?.individual){
        context?.data?.body?.individual?.mailingAddress?.forEach((item:any)=>{
            if(item.isPrimary===false){
            mailingAddress.push({
                id:item?.id?item?.id:'',
                address:addressFormatter('',item)
            })
        }
        })
    }
    useEffect(() => {
            let titleName:any=context?.data?.body?.business?context?.data?.body?.business?.legalName:context?.data?.body?.individual?.firstName
            if (titleName===undefined){
                titleName=context?.data?.body?.email;
            }
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:`View Lead Info`, backButton: '/leads' } } });
    }, [dispatch, context?.data?.body])


    
    
    /**
     * set Invite data
     */
    const details = useMemo(() => ({
        name: context?.data?.body?.business && context?.data?.body?.business?.legalName? context?.data?.body?.business?.legalName : context?.data?.body?.individual && context?.data?.body?.individual?.firstName?context?.data?.body?.individual?.firstName + ' ' + context?.data?.body?.individual?.lastName:'',
        date: moment(context?.data?.body?.lastUpdatedOn).format('MMM DD, yyyy')
    }), [context?.data?.body]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                {/* {context?.data?.body?.status!=='DRAFT' &&<Grid xs={12} sm={12}>
                    <LeadInviteDetailsView id={'lead-invite-details-card'} data={context?.data?.body} />
                </Grid>} */}
                {context?.data?.body &&   <Grid xs={12} sm={12}>
                    <LeadPreFilledInviteInfoView id={'lead-pre-filled-invite-info-card'} data={context?.data?.body} />
                </Grid>}
                <Grid xs={12} sm={12}>
                    {context?.data?.body?.business && <LeadBeneficialOwnerListView id={'lead-beneficial-owner-list-card'} data={benifilaOwner} />}
                </Grid>
                {mailingAddress &&<Grid xs={12} sm={12}>
                    <LeadMailingAddressListView id={'lead-mailing-address-list-card'} data={mailingAddress} />
                </Grid>}
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid xs={12} sm={12}>
                    {context?.data?.body?.status && <ViewInviteStatus id="partner-customer-view-invite-status" inviteStatus={props?.invitStatus?.data?.toLowerCase()} data={details} resendData={context?.data?.body}></ViewInviteStatus>}
                </Grid>
                {/* <Grid xs={12} sm={12}>
                    <LeadAccountInfoDetails id={'lead-account-info-card'} {...accountInfoSrc} />
                </Grid> */}
            </Grid>
        </Grid>
    )
})
