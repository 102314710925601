import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router';

/**
 * This component handles redirected to a page
 */
export const FxRedirectTo: React.FC<any> = React.memo(() => {
    const params = useParams<any>();
    const history = useHistory();
    /**
     * redirect to specified url
     */
    const redirectLink = params['redirectTo'];
    useEffect(() => {
        history.push('/' + redirectLink)
    }, [history, redirectLink])
    return (
        <></>
    )
});

