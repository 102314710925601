/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FxMultiSelectWeek } from "../../../Input/FxMultiSelectWeek/FxMultiSelectWeek";


Logger.debug("FxYearRecurring.tsx", "Fx year Recurring initializing")

/**
 *  This component handles the fx year recurring
 */
export const FxYearRecurring: React.FC<any> = React.memo(
    (props: any) => {
        const { register, control } = useForm();
        // Method passes the form data for month component
        const handleUpdateFormData = (value: any) => {
            if(props.updateFormData){
                props.updateFormData(value)
            }
        }

        return (
            <Grid item container xs={12} className="fx-repeat-payment-year">
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={12}>
                    <FxMultiSelectWeek register={{ ...register("monthRange") }} control={control}  id="month-range" name="monthRange" label="" month={true} data={props?.data} updateFormData={handleUpdateFormData}/>
                </Grid>
            </Grid>
        )
    })
