import * as React from "react";
import { TextField } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxEmailEdit.tsx", "Fx Email Edit initializing")

/**
 * Component used to edit email
 */
interface FxTextFieldProps {
  id?: string;
  value?: string;
  name?: string;
  className?: string;
  error?: boolean;
  readonly?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  labelColor?: string;
  inputRef?: any;
  rounded?: boolean;
  borderColor?: string;
  searchIcon?: boolean;
  noGrow?: boolean;
  helperText?: string;
  viewType?: "standard" | "filled" | "outlined" | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
}

export const FxEmailEdit: React.FC<FxTextFieldProps> =
  (props) => {
    return (
      <TextField className={props.className} id={props.id} type="email"
        inputRef={props?.inputRef}
        error={props?.error}
        variant={props?.viewType}
        name={props?.name}
        value={props.value}
        onChange={props.onChange}
      />
    )
  };
