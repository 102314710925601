import React from "react";
import { Menu, MenuProps, MenuItem, ListItemIcon } from '@mui/material';
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxMenu.tsx", "Fx Menu initializing")

interface IOption {
    id?: string;
    title: string,
    icon?: React.ReactElement
}

interface FxMenuProps extends MenuProps {
    id: string;
    icons?: boolean;
    openMenu?: boolean;
    options: IOption[];
    menuShadow?: boolean;
    onMenuClick: (e: any) => void;
    data?: any;
    anchorOrigin?: any;
    transformOrigin?: any;
}

/**
 * fx menu
 * @param props FxMenuProps
 */
export const FxMenu: React.FC<FxMenuProps> =
    (props) => {
        /**
         * Method handles the closing of the menus
         */
         const handleClose = (event: any, reason: any) => {
             try {
                 if (props.onClose) {
                     props.onClose(event, reason);
                 }
             } catch (e) {
             }
         };
        /**
         * Method handles the clicking event of the menus
         */
         const handleChange = (title: any) => {
            try{
                if(props.onMenuClick){
                    props.onMenuClick(title);
                }
            }
            catch(e){}
        };

        const className = props.menuShadow === true ? props.className? props.className + " fx-menu-dotted": "fx-menu-dotted" : "fx-menu-dotted fx-menu-no-shadow";
        return (
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                open={props.open}
                onClose={handleClose}
                className={className}
                anchorOrigin={
                    props.anchorOrigin ? props.anchorOrigin : {
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                transformOrigin={
                    props.transformOrigin ? props.transformOrigin : undefined}
                PaperProps={{sx: props.sx}}
            >
                {props.options?.map((option: any) => (
                    <MenuItem id={props.id + '-' + option.title} key={props.id + '-' + option.title}
                        onClick={() => handleChange(option.title)}
                    >
                        <ListItemIcon>
                            {props?.icons && option.icon}
                        </ListItemIcon>
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        )
    }