import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Logger } from '../../../libs/utils/logger';
import { DepositFundsCard } from './DepositFundsCard';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
Logger.debug("AccountConfigurationsCard.tsx", "Account Configuration initializing")

/**
 * This component renders the various components for displaying account details and handles the Account Statement Download
 */
export const AccountCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const isMobileOrTabScreen = useIsMobileOrTabletScreen()

        return (
            <Grid container className={"fx-container"}>
                <Grid container xs={12} alignItems="flex-start" justifyContent="space-between"  >
                    {context?.data?.body && <>
                        <Grid container xs={isMobileOrTabScreen ? 12 : 7} className="fx-container-left" >
                            <DepositFundsCard id="account-deposit-funds-one-card" data={context.data.body}></DepositFundsCard>
                        </Grid>
                       </>}
                </Grid>
            </Grid>
        );
    })