
/**
 * Component: ContactDetails
 * Usage: To display co-owner details
 */
import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import { CoOwnerDetailsInfo } from './CoOwnerDetailsInfo';

Logger.debug("CoOwnerDetails.tsx", "CoOwnerDetails  initializing");

export const CoOwnerDetails: React.FC<any> = React.memo(() => {
        interface RouteParams {
            id: string
        }

        //get ID from page url
        const params = useParams<RouteParams>();
        const id = params.id
        const contact_source = {
            url:  "/owner/id/" + id,
            method: "GET"
        }

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <CoOwnerDetailsInfo id="co-owner-details-info-card" source={contact_source} />
                </Grid>
            </Grid>
        )
    })