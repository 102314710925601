import { useEffect } from 'react';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Divider } from '@mui/material';
import { ReactComponent as PassportLogoMXM } from '../../../assets/svg/logo/passportNameLogoGreenBlue.svg';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { AgreementsEnum } from '../../../types/common.interfaces';
import { FxButton } from '../../Action/FxButton';
import { FxCongratulationCard } from '../../Data/FxCongratulationCard';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { FeeDetailsList } from '../../Page/Index/Passport/FeeDetailsList';
import { FxAccountActivationLayout } from '../DrawerLayaouts/FxAccountActivationLayout';
import { FxInfoLayout } from '../DrawerLayaouts/FxInfoLayout';
import { FxLink } from '../../Action/FxLink';
import HttpClient from '../../../libs/utils/httpClient';
import { getKey, setKey } from '../../../libs/utils/storageManager';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

Logger.debug("FxAccountActivation.tsx", "account activation initializing")

/**
 * Component used inside the right drawer to activate the account
 */


export interface IAccountActivationPayload {
    isActivated: boolean;
}

interface IFxAccountActivation {
    FeeData: any;
    onDrawerClose: (event: any, payload: IAccountActivationPayload) => void;
}

export const FxAccountActivation: React.FC<IFxAccountActivation> = ({ onDrawerClose }) => {

    const { register, setValue, control, getValues } = useForm();
    const [btnDisabled, setBtnDisabled] = React.useState(true);
    const [isAccepted, setIsAccepted] = React.useState(false);
    const [isFundingTermsEnabled, setIsFundingTermsEnabled] = React.useState(false);
    const [documentId, setDocumentID] = React.useState('');
    const httpClient = HttpClient.getClient();
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
    const merchantId =  getKey('mxmMerchant')?.id || getKey('customerInitialMerchantId');
    setKey('accountEnabledNow', true);
    const history = useHistory();


    const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
    const accountCount=accountListInfo?.data?.body?.totalCount
    const accountId = accountCount > 1 ? accountListInfo?.params?.selectedOption?.id : getKey('customerInitialAccount')?.id;
    const accountAgreementConfigurations = getKey('accountAgreementConfigurations') || [];
    const rateCardAgreementName = businessCustomer ? AgreementsEnum.COMMERCIAL_RATE_CARD : AgreementsEnum.CONSUMER_RATE_CARD;
    const accountAgreement = accountAgreementConfigurations.find((config: any) => config.name === AgreementsEnum.ACCOUNT_AGREEMENT)?.value || '';
    const rateCardAgreement = accountAgreementConfigurations.find((config: any) => config.name === rateCardAgreementName)?.value || '';

      /**
    Method to view license agreement pdf file in new window --code Added on-24-08-2023--
     */
    const getpassportagrement = useCallback( (event:any) => {
        event.preventDefault();
        window.open('https://prioritycommerce.com/wp-content/uploads/2022/10/Passport-End-User-License-Agreement.pdf', '_blank');
    }, []);

      /**
    Method to open privacy details window --code Added on-24-08-2023--
     */
    const getpolicy = useCallback( (event:any) => {
        event.preventDefault();
        window.open('https://prioritycommerce.com/privacy/', '_blank');
    }, []);

    const FeeDetailsTerms = [
        {
            label: (
                <span>By submitting this form, you agree to the Passport Account Agreement terms and conditions to enable banking services. You also agree to<FxLink className={'fx-fee-agreement-link'} onClick={getpassportagrement}><span className='fx-submit-agree-color'> Passport’s end user license agreement</span></FxLink >,<FxLink className={'fx-fee-agreement-link'} onClick={getpolicy}><span className='fx-submit-agree-color'> privacy policy</span>
                    </FxLink> and receiving electronic communications regarding your account, and you certify that the information you provided is complete and accurate.</span>),
            value: 'yes'
        }
    ];

    const EnableFasterFundingTerms = [
        {
            label: (
                <span>I want to link my Passport account to MX<span className='sup'>TM</span> Merchant  & enable Funding via Passport.
                    {isFundingTermsEnabled ? <></>: <span className='fx-funding-gain-access-terms-sub'> (If you don't check this option, you won't be eligible for Priority Capital.)</span>}</span>),
            value: isFundingTermsEnabled
        }
    ];


    /**
     * Method to handle form change events
     * @param event :event object
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        setBtnDisabled(!event.target.checked)
    }

    useEffect(()=> {
        const getFileId = async () => {
            const account_agreement: any = {
                "pageNumber": 1,
                "pageSize": 1,
                "sortOptions": {
                    "sortBy": "lastUpdatedOn",
                    "sortOrder": "desc"
                },
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "settingName",
                            "values": [
                                "ACCOUNT_AGGREEMENT_DEFAULT_DOCUMENT"
                            ]
                        }
                    ]
                }
            }
            try {
                await httpClient.post('/programManager/getProgramManagerSetting', account_agreement).then(response => {
                    setDocumentID(response?.data?.resources?.[0]?.settingValue)
                });
            } catch (e) { }
        }
        getFileId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    /**
     * method to call Activate Account API
     * @returns :data
     */
    const activateAccount = async () => {
        const payload: any = {
            "linkedDocument": [
                {
                    "purpose": "AUTHORIZATION",
                    "document": {
                        "id": documentId
                    }
                }
            ]
        }
        try {
            const data: any = await httpClient.post(getCustomerUrl(`account/id/${accountId}/activate`), payload).then((response) => {
                return response
            })
                .catch((error) => {
                    Logger.error("FxAccountActivation.tsx", "error", error);
                    return { ...error };
                })
            return data;
        }
        catch (e) { }
    }

    /**
     * Method call after the success of account api and set the parameters
     */
    const setAccountAttributes = () =>{
        updateComponentParams(dispatch, 'home-overlay-page', { 'accountStatus': true })
        setKey("accountStatus", 'true');
        let selectedAccount:any={...getKey('selectedAccount')};
        selectedAccount.status='ACTIVE';
        setKey('selectedAccount',selectedAccount);
        if(getKey('goPriorityCapitalUrl') === undefined || !isFundingTermsEnabled) {
            setIsAccepted(true);
            history.push('/redirect/dashboard')
        }
    }

    /**
     * Method call after the success of merchant api and set the open the congrats page for priority Capital
     */
    const setMerchantAttributes = () =>{
        setKey('mxmFastFunding', true );
        setKey('fundingViaPassport', true );
        if(getKey('goPriorityCapitalUrl')){
            onDrawerClose({}, {isActivated: true});
            history.push('/redirect/dashboard');
            setTimeout(()=>{
                updateComponentParams(dispatch, 'customer-home-passport', { 'openPipeRedirectionModal': true })
            }, 2000)        }
    }

    /**
     * Method to check API status
     */
    const accept = async () => {
        let status = await activateAccount();
        status = processAPIResponse(status)

        //function to call the enable fast funding api for merchant only if the 2nd checkbox is checked
        if(isFundingTermsEnabled){
            let merchantStatus = await enable();
            merchantStatus = processAPIResponse(merchantStatus)
            if((status.status && (merchantStatus.status))){
                setAccountAttributes();
                setMerchantAttributes();
            } else {
                FxSnackBar.show({
                    text: merchantStatus?.message,
                });
            }

        } else if(status.status){
            setAccountAttributes();
        } else {
            //api  failed
            FxSnackBar.show({
                text: status?.message,
            });
        }
    }

     /**
     * async function to enable merchant fast funding
     */
     const enable = async () => {
        const req:any={
            "configuration": {
                "passportFunding": {
                    "status": "INITIATED"
                },
                "quickSettle": false
            },
        }

        try {
            const data: any = await httpClient.post(getCustomerUrl(`merchant/id/${merchantId}`), req);
            return data;
        }
        catch (e) {
            Logger.error("FxAccountActivationMerchantApi.tsx", "error", e);
            return e;
        }
    }

    const goToDashboard = (event: any) => {
        onDrawerClose(event, {isActivated: true});
    }

    const accountActivationLayoutHeader = <>
        <h2>Get Corporate Debit card &nbsp;</h2>
        <div>
        <h2>with &nbsp;</h2>
            <PassportLogoMXM />
        </div>
    </>
     /**
    Method to open Passport-Account-Agreement pdf file in new window --code Added on-24-08-2023--
     */
    const getFileInfo = useCallback( (event:any) => {
        event.preventDefault();
        window.open(accountAgreement, '_blank');
    }, []);

     /**
    Method to open Fee and rate card information pdf file in new window
     */
    const getFileInfoFeeAndRateCard = useCallback( (event:any) => {
        event.preventDefault();
        window.open(rateCardAgreement, '_blank');
    }, []);
    
    return (
        <Grid container id="account-activation" className="fx-account-activation">
            <Grid item className={'fx-drawer-left-content'}>
                {!isAccepted ?
                    <FxAccountActivationLayout />
                    :
                    <FxInfoLayout layoutHeader={accountActivationLayoutHeader} />
                }
            </Grid>
            <Grid container item xs justifyContent={"center"} className={'fx-drawer-right-content'}>
                {!isAccepted ?
                    <Grid container item>
                        <FeeDetailsList id="fee-ratecard-details"/>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} className={'fx-fee-activation'}>
                            <Typography className={'fx-fee-agreement'}><FxLink className={'fx-fee-agreement-link'} onClick={getFileInfo}><span className='normal-font'>Click here</span></FxLink> to view the Passport Account Agreement with <FxLink className={'fx-fee-agreement-link'} onClick={getFileInfoFeeAndRateCard}><span className='normal-font'>fee and rate card information</span></FxLink></Typography>
                        </Grid>
                        <Grid item xs={12} className={'fx-submit-agree'}>
                            <FxCheckEdit register={{ ...register('FeeDetailsTerms') }}
                                         rules={{ required: 'Please click Agree' }} control={control}
                                         id="debit-terms-condition" name="FeeDetailsTerms"
                                         className="fx-privacy-text" data={FeeDetailsTerms} row="vertical"
                                         onChange={(e: any) => {
                                             handleChange(e);
                                         }} setValue={setValue} />
                        </Grid>
                        {<Grid item xs={12} className={'fx-submit-agree'}>
                            <FxCheckEdit
                            register={{ ...register('EnableFasterFundingTerms') }}
                            rules={{ required: 'Please click Agree' }} control={control}
                            id="faster-funding-terms-condition" name="EnableFasterFundingTerms"
                            onChange={()=>setIsFundingTermsEnabled(!isFundingTermsEnabled)}
                            className="fx-privacy-text" data={EnableFasterFundingTerms} row="vertical"
                            setValue={setValue}
                            updateOptions={true} />
                        </Grid>}
                        <Grid item xs={12} textAlign={'right'} paddingBottom={isSmallScreen ? '1rem' : ''}>
                            <FxButton className={`fx-accept-button ${btnDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`} disabled={btnDisabled} onClick={accept}>Accept</FxButton>
                        </Grid>
                    </Grid>
                    :
                    <FxCongratulationCard message={'Your Passport account is now active.'}
                                          onButtonClick={goToDashboard} iconName="accountActivation" backButtonText={'Continue to dashboard'}/>
                }
            </Grid>
        </Grid>
    )
}
