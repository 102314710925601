import React from 'react'; // we need this to make JSX compile
import {  Grid, Typography,  } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useHistory } from 'react-router-dom';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxButton } from '../../Action/FxButton';
import moment from 'moment';

Logger.debug("UserInfoCard.tsx", "External Account InfoCard initializing");
/**
 * Component: UserInfoCard
 * Usage: To display user information
 */
export const AuthorisedUserInfoCard: React.FC<any> = React.memo((props) => {

        ({ props } = RegisterComponent(props));

        const history = useHistory();

        let user_data: any;
        /**
         * tranform and set user details API
         */
        if (props?.data) {
            user_data = { ...props.data }
            user_data.userFullName = (user_data.firstName ? user_data.firstName : '') + ' ' + (user_data.middleName ? user_data.middleName : '') + ' ' + (user_data.lastName ? user_data.lastName : '');
            if (user_data.userType && user_data.userType === 'API_USER') {
                user_data.userTypeText = 'API User'
            } else {
                user_data.userTypeText = 'Portal User'
            }
            user_data.status = 'ACTIVE'
            user_data['createdInfo'] = user_data['createdBy']['username'] + '\nOn ' + moment(user_data['createdOn']).format('MM/DD/YYYY');
            user_data['updatedInfo'] = user_data['lastUpdatedBy']['username'] + '\nOn ' + moment(user_data['lastUpdatedOn']).format('MM/DD/YYYY');
           
            user_data['primaryAddress'] =  user_data['mailingAddress']? user_data['mailingAddress'].find((address: {isPrimary: boolean}) => address.isPrimary): "";
            user_data['last4ssn'] = user_data['last4ssn'] ? `****${user_data['last4ssn']}` : ''
            user_data['username'] = user_data['portalAccess']['username'];
        }


        const user_info_section_left = [
            {
                label: 'Username',
                value: 'username'
            },
            {
                label: 'DOB',
                value: 'dob',
                type: 'fri://fieldtype/date',
              
            },
            {
                label: 'Email',
                value: 'email'
            },
            {
                label: 'Address',
                value: 'primaryAddress',
                type: 'fri://fieldtype/address',
            }


        ]
        const user_info_section_right = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            {
                label: 'SSN',
                value: 'last4ssn'
            },
            {
                label: 'Phone',
                value: 'mobilePhone'
            }
        ]

        const basic_info_details_section_audit_left = [
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ]
        const basic_info_details_section_audit_right = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }
        ]

        const editPermission = {
            entity: "Authorized User",
            name: "User",
            operation: 'Edit'
        }

        return (
            <Grid id="user-info-Grid" container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container">
                <Grid id="user-info-Grid" container xs={12}>
                    <FxCard id="user-info-card" className="fx-theme-passport">
                        <FxCardHeader id={'user-info-card#card-header'} className="fx-card-header">
                            <Grid id="user-Grid2" item container justifyContent="space-between" xs={12}>
                                <Grid id="filter-Grid" item className="fx-inline-flex">
                                    <Typography id="user-infocard-title-label" variant="h4" >User Information</Typography>
                                    <div id="user-infocard-title-info-div" className="fx-flex-grow" />
                                </Grid>
                                <Grid>
                                     {/* edit , suspend buttons */}
                                    <FxButton id="edit-user-button" permissions={editPermission} variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<EditIcon />} onClick={() => history.push("/authorizedusers/edit/" + props?.data?.id)}>Edit</FxButton>
                                </Grid>
                            </Grid>
                        </FxCardHeader>

                        <FxCardBody id="user-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                            <Grid container item xs={12} direction="row" >
                                {/* info card section */}
                                <Grid item xs={12}>
                                    <FxInfoCard id="section-info-card-user-list-details" title="BASIC INFORMATION" leftcolumn={user_info_section_left} rightcolumn={user_info_section_right} data={user_data} ></FxInfoCard>
                                </Grid>
                                <Grid item xs={12}>
                                    {<FxInfoCard id={"audit-fields-info-card"} title="AUDIT FIELDS" leftcolumn={basic_info_details_section_audit_left} rightcolumn={basic_info_details_section_audit_right} data={user_data} />}
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </FxCard>
                </Grid >
            </Grid>

        )
    })
