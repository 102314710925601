/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { toTitleCase } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("ResumeSchedule.tsx", "Resume Schedule initializing")
/**
 * This component handles the resuming recurring schedules
 */
export const ResumeSchedule: React.FC<any> = React.memo(
    (props) => {
        const { register, handleSubmit, control } = useForm();
        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory()

        /**
         * Async function handles the api call for pausing a recurring schedule
         * @param req : form request
         * @returns : response object
         */
        async function ResumeSchedule(req: any) {

            try {
                const url: any = 'transaction/' + props.schedule + '/id/' + props.scheduleId + '/resume';
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("ResumeSchedule.tsx", "error", err);
                return err;
            }
        }
        /**
         * Async function handles the resume form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            const req: any = {
                'comment': data.comment
            }
            setIsLoading(true);
            let status: any = await ResumeSchedule(req);
            setIsLoading(false);
            status = processAPIResponse(status)
            const backurl = '/recurring';
            const successMessage = toTitleCase(props.schedule) + 'Resumed Successfully';
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(backurl)
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="resume-schedule-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-resume-schedule-modal" variant="h4">
                                            Are you sure to resume this {toTitleCase(props.schedule)} ?
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='resume-schedule-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="resume-schedule-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("comment") }} className="fx-input-edit" control={control} rules={{ required: false }} id="comment" label="Comment" name="comment" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="resume-schedule-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme fx-button-delete"
                                        id={"resume-" + props.schedule + "-schedule"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                                'Resume Recurring'
                                            )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
