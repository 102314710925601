import React from 'react';
import { FxDialogModal } from '../../../Data/FxDialogModal';
import { ReactComponent as LetterIcon } from '../../../../../src/assets/svg/letter-icon.svg';

export type ReminderType = 'reminder' | 'activationReminder';
interface IReminderModal {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  isSuccess?: boolean;
  type: ReminderType;
  recipientEmail: string;
}

export const ReminderModal: React.FC<IReminderModal> = ({
                                                          isOpen,
                                                          onClose,
                                                          onSubmit,
                                                          isSuccess,
                                                          type,
                                                          recipientEmail
                                                        }) => {

  const reminders = {
    reminder: {
      title: 'Do you want Send a Reminder?',
      info: `A reminder will be send via email at ${recipientEmail}`,
      successTitle: 'Email Sent Successfully',
      successInfo: `We have successfully sent Passport sign up Reminder to ${recipientEmail}`,
      submitButtonText: 'SEND'
    },
    activationReminder: {
      title: 'Are you sure you want to Send a Account Activation Reminder ?',
      info: 'You cannot undo this action.',
      successTitle: 'Activation Reminder Sent Successfully',
      successInfo: `We have Successfully sent account activation Reminder to ${recipientEmail}`,
      submitButtonText: 'SEND REMINDER'
    }
  }

  return <FxDialogModal
    id="reminder-modal"
    open={isOpen}
    onClose={onClose}
    hasCancelButton={!isSuccess}
    onSubmit={onSubmit}
    submitButtonText={!isSuccess  ? reminders[type].submitButtonText : 'GO TO INVITES'}
    title={!isSuccess ? reminders[type].title : reminders[type].successTitle}
    info={!isSuccess ? reminders[type].info : reminders[type].successInfo}
    Icon={LetterIcon}
    isSuccess={isSuccess}
  />
}