import { AccordionDetails } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { Accordion } from '@mui/material';
import { useState } from 'react';
import React, { useEffect } from 'react'
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import countries from '../../../libs/utils/country/country.json';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { generateAVSDetails } from './ContactGlobalFunctions';
import {
  clean,
  handleCvvChange,
  handleKeyDownChange,
  handleZipChange,
  renderError,
  setZipValidationOptions,
  renderAddressLine1Error,
  renderAddressLine2Error,
  renderCityNameError,
  setCVVValidation,
  setCardNumberValidation,
  renderCardNumberError,
  convertToPhoneRequestFormat,
  renderPhoneError,
  setPhoneValidationOptions,
  setCityNameValidation,
  setAddressLine2Validation,
  setAddressLine1Validation
} from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxCardNumberEdit } from '../../Input/FxCardNumber/FxCardNumberEdit';
import { FxDateEdit } from '../../Input';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import usa_state from '../../Utils/usa_states.json';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { getKey } from '../../../libs/utils/storageManager';
import { AVSDetailsForm } from '../Schedules/AVSDetailsForm';
import { ReactComponent as DownArrow } from '../../../assets/svg/down-black-triangle-arrow.svg';
import { ReactComponent as UpArrow } from '../../../assets/svg/up-black-triangle-arrow.svg';
const httpClient = HttpClient.getClient();

Logger.debug("CreateContactCard.tsx", "create new card initializing");

/**
 * Component: CreateContactCard
 * Usage: create multiple card
 */
export const CreateContactCard: React.FC<any> = React.memo(
    (props) => {
        //#region Variables Declarations
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, watch, getValues } = useForm();
        const [isLoading, setIsLoading] = React.useState(false);
        const submitButton = 'Add Card';
        const history = useHistory();
        const params = useParams<any>();
        const mandateFieldForCardTransaction = getKey('mandateFieldForCardTransaction') || '';
        const [avsDetailOpen, setAvsDetailOpen] = useState<boolean>(false);

        //#region Assigning values to variable
        const contactId = params.id
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Card', backButton: '/payee/view/' + contactId } } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        /**
         * Method handles the validation for schedule date
         */
        const scheduleDateValidation = {
            required: true,
            minDate: (new Date())
        };

      /**
       *  Handles the Address Verification section toggle
       */
      const toggleAvsDetail = () => {
        setAvsDetailOpen(prevState => !prevState);
      }



        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            setIsLoading(true);
            let status: any;
            const object: any = {};

            clearErrors("cardNumber");
            let cardObj: any;
            if (data['cardNumber']) {
                cardObj = JSON.parse(data['cardNumber'])
                if (!cardObj.success) {
                    setError("cardNumber", {
                        type: "manual",
                        message: cardObj.message,
                    });
                }
            }

            const expiryDate = new Date(data.expiryDate);
            const month = expiryDate.getMonth() + 1;
            const year = expiryDate.getFullYear();

            const holderName = data.cardFirstName && data.cardFirstName ? `${data.cardFirstName} ${data.cardLastName}` : undefined;
            object.card = [{
                holderName,
                cardNumber: cardObj?.value,
                brand: mandateFieldForCardTransaction.includes('brand') ? cardObj?.type : undefined,
                cvv: data.cvv || undefined,
                expiryMonth: month,
                expiryYear: year,
            }]

            // Prepare the billing address details if they exist
            if(data.cardBillingAddressLine1 || data.cardBillingAddressLine2 || data.cardBillingCity || data.cardBillingState || data.cardBillingZip) {
                object.card[0]['billingAddress'] = {
                    addressLine1: data.cardBillingAddressLine1 || undefined,
                    addressLine2: data.cardBillingAddressLine2 || undefined,
                    city: data.cardBillingCity || undefined,
                    state: data.cardBillingState || undefined,
                    zip: data.cardBillingZip || undefined,
                    country: data.cardBillingCountry || undefined
                }
            }

            // Prepare the card holder details if they exist
            if (data.cardBillingPhone || data.cardBillingEmail || data.cardFirstName || data.cardLastName) {
              let formattedPhoneNumber;
              if (data.cardBillingPhone) {
                formattedPhoneNumber = convertToPhoneRequestFormat(data.cardBillingPhone);
              }

              object.card[0]['cardHolder'] = {
                phone: formattedPhoneNumber || undefined,
                email: data.cardBillingEmail || undefined,
                name: holderName,
                firstName: data.cardFirstName || undefined,
                lastName: data.cardLastName || undefined,
              }
            }

            // Prepare the AVS (Address Verification System) details
            const avs = generateAVSDetails(data);
            const avsDetails = clean(avs);

            // Add AVS details to the card object if they exist
            if(Object.keys(avsDetails).length > 0){
              object.card[0]['avs'] = avsDetails;
            }

            status = await addCard(clean(object));

            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Card added successfully!',
                });
                history.push('/payee/view/' + contactId);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
         * Method to call api for create new card
         * @param payloadData : request payload
         */
        async function addCard(payloadData: any) {
            try {
                const url = '/contact/id/' + contactId;
                const data: any = await httpClient.post(getCustomerUrl(url, false), payloadData).then(response => {
                    return response
                })
                .catch((error) => {
                    return { ...error };
                })
                return data;
            } catch (err) {
                Logger.error("CreateContact.tsx", "error", err);
                return err;
            }
        }

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleCancel = () => {
            history.push('/payee/view/' + contactId)
        };


      /**
       * This Method handles the population of the Holder Address Values inside the AVS address details
       */
      const populateHolderAddressValuesInAvsDetails = () => {
        setValue('avsAddressLine1', getValues('cardBillingAddressLine1'));
        setValue('avsCity', getValues('cardBillingCity'));
        setValue('avsState', getValues('cardBillingState'));
        setValue('avsZip', getValues('cardBillingZip'));
        setValue('avsEmail', getValues('cardBillingEmail'));
        setValue('avsPhone', getValues('cardBillingPhone'));
        setValue('avsFirstName', getValues('cardFirstName'));
        setValue('avsLastName',  getValues('cardLastName'));
      }

        return (
            <Grid container id="create-payees-card-main-grid" xs={12}>
                <Grid item xs={12} sm={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
                <Grid id="create-payees-card-first-grid" item xs={12} sm={8}>
                    <Grid id="create-payees-card-sub-grid" container spacing={1} >
                        <Grid id="create-payees-card-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {<form id="create-payees-card-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-payees-card-form-card" className="fx-theme-passport">
                                        <FxCardBody id="create-payees-card-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxLabelView id="add-card-label-text">CARD DETAILS</FxLabelView>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FxTextEdit register={{ ...register("cardFirstName") }} className={errors.cardFirstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: !!mandateFieldForCardTransaction.includes('holdername'), maxLength: 45 }} id="card-holder-first-name" label={mandateFieldForCardTransaction.includes('holdername') ? "First Name*" : "First Name"} name="cardFirstName" variant="outlined" setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FxTextEdit register={{ ...register("cardLastName") }} className={errors.cardLastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: !!mandateFieldForCardTransaction.includes('holdername'), maxLength: 45 }} id="card-holder-last-name" label={mandateFieldForCardTransaction.includes('holdername') ? "Last Name*" : "Last Name"} name="cardLastName" variant="outlined" setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxCardNumberEdit isEditable={true} register={{ ...register("cardNumber") }} className={errors.cardNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} type="number" control={control} setValue={setValue} setError={setError}
                                                            clearErrors={clearErrors} rules={setCardNumberValidation(true)} id="contact-card-number" label="Card Number*" placeholder="Card Number" name="cardNumber" variant="outlined" maxLength={20} />
                                                        <div className={'error-message'}>
                                                            {errors.cardNumber && renderCardNumberError(errors.cardNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxDateEdit register={{ ...register("expiryDate") }} className={errors.expiryDate ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={scheduleDateValidation} id="card-expiry-date" label="Expiry Date*" name="expiryDate" type="date" variant="outlined" setValue={setValue} month={true} disablePast={true} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("cvv") }} className={errors.cvv ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" control={control} rules={setCVVValidation(mandateFieldForCardTransaction.includes('cvv'))} id="card-cvv" label={mandateFieldForCardTransaction.includes('cvv') ? "CVV*" : "CVV"} name="cvv" variant="outlined" maxLength={4} onChange={(e: any) => { handleCvvChange(e, setValue, setError, clearErrors) }}/>
                                                        <div className={'error-message'}>
                                                            {errors.cvv && renderError(errors.cvv)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={12}>
                                                        <fieldset className="fx-container-create-schdule-summery">
                                                            <legend>Billing Address</legend>
                                                            <Grid item container xs={12} sm={12} spacing={2}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxTextEdit register={{ ...register("cardBillingAddressLine1") }} control={control} rules={setAddressLine1Validation(mandateFieldForCardTransaction.includes('billingaddress'))} className={errors.cardBillingAddressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-address-line1" name="cardBillingAddressLine1" label={mandateFieldForCardTransaction.includes('billingaddress') ? "Address Line 1*" : "Address Line 1"} setValue={setValue} />
                                                                    <div className={'error-message'}>
                                                                        {errors.cardBillingAddressLine1 && renderAddressLine1Error(errors.cardBillingAddressLine1)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxTextEdit register={{ ...register("cardBillingAddressLine2") }} control={control} rules={setAddressLine2Validation(false)} className={errors.cardBillingAddressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-address-line2" name="cardBillingAddressLine2" label="Address Line 2" setValue={setValue} />
                                                                    <div className={'error-message'}>
                                                                        {errors.cardBillingAddressLine2 && renderAddressLine2Error(errors.cardBillingAddressLine2)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("cardBillingCity") }} control={control} rules={setCityNameValidation(mandateFieldForCardTransaction.includes('billingaddress'))} className={errors.cardBillingCity ? "border-error-input fx-input-edit" : "fx-input-edit"} id="card-billing-city" name="cardBillingCity" label={mandateFieldForCardTransaction.includes('billingaddress') ? "City*" : "City"} setValue={setValue} />
                                                                    <div className={'error-message'}>
                                                                        {errors.cardBillingCity && renderCityNameError(errors.cardBillingCity)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                  <FxSelectAutoSearch
                                                                    register={{ ...register('cardBillingState') }}
                                                                    rules={{ required: !!mandateFieldForCardTransaction.includes('billingaddress') }}
                                                                    className={errors.cardBillingState ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                    control={control} id="card-billing-state"
                                                                    name="cardBillingState" data={usa_state}
                                                                    label={mandateFieldForCardTransaction.includes('billingaddress') ? 'State *' : 'State'}
                                                                    readOnly={false} setValue={setValue} />
                                                                </Grid>

                                                              <Grid item xs={12} sm={6}>
                                                                <FxMaterialSelect
                                                                  register={{ ...register('cardBillingCountry') }}
                                                                  rules={{ required: !!mandateFieldForCardTransaction.includes('billingaddress') }}
                                                                  className={errors.cardBillingCountry ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                  control={control}
                                                                  id="card-billing-address-add-country-textbox"
                                                                  name="cardBillingCountry"
                                                                  data={countries}
                                                                  label={mandateFieldForCardTransaction.includes('billingaddress') ? "Country*" : "Country"}
                                                                  setValue={setValue}
                                                                  value={'US'}
                                                                  readOnly={true} />
                                                              </Grid>
                                                              <Grid item xs={12} sm={6}>
                                                                <FxTextEdit
                                                                  register={{ ...register('cardBillingZip') }}
                                                                  control={control}
                                                                  rules={setZipValidationOptions(!!mandateFieldForCardTransaction.includes('billingaddress'))}
                                                                  className={errors.cardBillingZip ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                  id="card-billing-zip" name="cardBillingZip"
                                                                  label={mandateFieldForCardTransaction.includes('billingaddress') ? 'ZIP*' : 'ZIP'}
                                                                  onChange={(e: any) => {
                                                                    handleZipChange(e, setValue, setError, clearErrors)
                                                                  }} onKeyDown={(e: any) => {
                                                                  handleKeyDownChange(e, setValue)
                                                                }} />
                                                                <div className={'error-message'}>
                                                                  {errors.cardBillingZip && renderError(errors.cardBillingZip)}
                                                                </div>

                                                              </Grid>
                                                              <Grid item xs={12} sm={6}>
                                                                <FxTextEdit
                                                                  register={{ ...register("cardBillingEmail") }}
                                                                  className={
                                                                    errors.cardBillingEmail
                                                                      ? "border-error-input fx-input-edit"
                                                                      : "fx-input-edit"
                                                                  }
                                                                  control={control}
                                                                  rules={{ required: false}}
                                                                  id="card-billing-email"
                                                                  label={'Email'}
                                                                  type="email"
                                                                  name="cardBillingEmail"
                                                                  variant="outlined"
                                                                />
                                                              </Grid>
                                                              <Grid item xs={12} sm={6}>
                                                                <FxPhoneEdit register={register}
                                                                             control={control}
                                                                             rules={setPhoneValidationOptions(false)}
                                                                             setValue={setValue}
                                                                             setError={setError}
                                                                             clearErrors={clearErrors}
                                                                             className={errors.cardBillingPhone ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                                                             id="card-billing-phone"
                                                                             name="cardBillingPhone"
                                                                             label={'Mobile Phone'}
                                                                />
                                                                <div className={'error-message'}>
                                                                  {errors.cardBillingPhone && renderPhoneError(errors.cardBillingPhone)}
                                                                </div>
                                                              </Grid>
                                                            </Grid>
                                                        </fieldset>
                                                    </Grid>
                                                </>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                              <Grid item container xs={12}>
                                                <Accordion
                                                  expanded={avsDetailOpen}
                                                  onChange={() => toggleAvsDetail()}
                                                  className={"fx-additional-card-info-accordian"}
                                                >
                                                  <AccordionSummary className="fx-additional-card-info-accordian-header-section">
                                                    <Grid container xs={12}>
                                                      <Grid item xs={11} sm={11} justifyContent={"flex-start"}>
                                                        <Typography
                                                          className="fx-summary-title"
                                                          variant="subtitle1"
                                                        >
                                                         ADDRESS VERIFICATION SERVICE
                                                        </Typography>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={1}
                                                        justifyContent={"flex-end"}
                                                        textAlign={"right"}
                                                      >
                                                        {avsDetailOpen ? <UpArrow/> : <DownArrow/>}
                                                      </Grid>
                                                    </Grid>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <AVSDetailsForm
                                                      register={register}
                                                      watch={watch}
                                                      control={control}
                                                      errors={errors}
                                                      setValue={setValue}
                                                      clearErrors={clearErrors}
                                                      setError={setError}
                                                      handlePopulateAVSDetails={populateHolderAddressValuesInAvsDetails}
                                                    />
                                                  </AccordionDetails>
                                                </Accordion>
                                              </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="create-payees-card-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payees-card-cancel-button"
                                                    onClick={handleCancel}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id="payees-add-card-submit-button"
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });