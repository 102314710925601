/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { IScheduleMenuItem } from '../../../types/common.interfaces';
import { FxLink } from '../../Action/FxLink';

type PropsType = {
    isEdit: boolean;
    menuItems: IScheduleMenuItem[];
}

export const ScheduleTabView: React.FC<PropsType> = React.memo(({ isEdit, menuItems }: PropsType) => {
    const isSmallScreen = useIsMobileOrTabletScreen();

    return (
        <Tabs aria-label="wrapped label tabs example" className="fx-schedule-tab-view">
            {menuItems.map((menuItem: any, index: number) => (
              <Grid item md={12} className={`fx-schedule-tab-container-grid ${menuItem.selected ? `fx-selected` : `fx-not-selected`} `}>
                <Grid key={menuItem.name} item md={12} className={`fx-schedule-tab-view-grid`}>
                  <FxLink id={'schedule-menu-' + menuItem.name + '-link'} redirect={isEdit ? '' : menuItem.link} className={menuItem.selected ? "fx-schedule-tab-view-sel" : ''}>
                    <Tab
                      value={index}
                      label={<Grid item container className='middle-column' spacing={1} justifyContent="space-between" xs={12} md={12}>
                        <Grid item container className='fx-schedule-left-menu-left-column' xs={isSmallScreen ? 12 : 2}>
                          <Grid item xs>
                            {menuItem.icon}
                          </Grid>
                        </Grid>
                        <Grid item container xs={isSmallScreen ? 12 : 10}>
                          <Grid item xs={12} textAlign={isSmallScreen ? 'center' : 'start'} className='fx-schedule-tab-title' >
                            <Typography>{menuItem.title}</Typography>
                          </Grid>
                          <Grid item xs className='fx-schedule-tab-narration'>
                            <Typography textAlign={isSmallScreen ? 'center' : 'start'} variant="subtitle1"  >{menuItem.narration}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>}
                      disabled={isEdit}
                    />
                  </FxLink>
                </Grid>
              </Grid>
           )
            )}
        </Tabs>
    )
})
