/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Logger } from '../../../../libs/utils/logger';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { DataGrid } from '../../../Data/DataGrid';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../../Data/FxGridList';
import { removeUnderScoreConvertTitleCase } from '../../../Utils/CommonBaseClass';
Logger.debug("RecurringHistoryListingCard.tsx", "RecurringHistoryListingCard initializing");
/**
 * Component: RecurringHistoryListingCard
 * Usage: To display Recurring History
 */

export const RecurringHistoryListingCard: React.FC<any> = React.memo(() => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        interface ScheduleParams {
            id: string
        }
        const params = useParams<ScheduleParams>();
        const id = params.id
        const src = {
            url: "/transaction/recurring/id/" + id + "/history",
            method: "POST",
            data: {
                "pageNumber": 1,
                "pageSize": 25,
                "criteria": {
                    "filters": [
                    ]
                }
            }
        }
        /**
            * Function handles transformation of destination data
            * @param data : destination data array
            * @returns : array
            */
        function transformData(data: any) {
            try {

                let optionObj: any = {};
                const optionArray: any = [];
                // eslint-disable-next-line array-callback-return
                data?.map((item: any) => {
                    optionObj = {}
                    optionObj = {
                        id: item.transaction.id,
                        amount: item.transaction.amount,
                        status: item.transaction.status,
                        statusReason: item.transaction.statusReason,
                        createdOn: item.createdOn,
                    }
                    optionArray.push(optionObj);
                })
                return optionArray;
            } catch (e) {
                Logger.error("RecurringHistoryCard.tsx", "error", e);
             }
        }
        /**
         * Layout for Recurring Transaction Id
         * @param id 
         * @returns 
         */
    const createTransactionDetails = (transaction: any) => (
        <Grid item container xs={12}>
            <Grid item>
                <Typography variant='h4'>ID: {transaction?.id}</Typography>
            </Grid>


        </Grid>
    );
        /**
         * Layout for Recurring Transaction Status reason and Amount
         * @param id 
         * @returns 
         */
    const createTransactionStatusReason = (transaction: any) => (
        <Grid item container xs={12}>
            <Grid item>
                <Typography variant='h4'> {removeUnderScoreConvertTitleCase(transaction?.statusReason)}</Typography>
            </Grid>
            <Grid item container xs justifyContent='center'>
                <Grid item xs>
                    <Grid item xs>
                        <Grid>
                            <FxCurrencyView value={transaction.amount} justifyContent="flex-end" prefix={"$"} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
        /**
         * Layout for Recurring Transaction Status
         * @param id 
         * @returns 
         */
    const createTransactionStatus = (transaction: any) => (
        <Grid item container xs={12}>
            <Grid item>
                <FxStatus id="recurring-history-status" value={transaction?.status} fill={true} className="fx-status"></FxStatus>
            </Grid>
        </Grid>
    );
        /**
         * Layout for Recurring Transaction Processed on
         * @param id 
         * @returns 
         */
    const createProcessedOn = (createdOn: string) => (
        <Grid item container xs={12}>
            <Grid item>
                <Typography variant='h4'> {createdOn}</Typography>
            </Grid>
        </Grid>
    );
        /**
         * Layout for Recurring Transaction Divider
         * @param id 
         * @returns 
         */
    const createDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs ><Divider /></Grid>
        </Grid>
    );
        /**
         * @param data : Recurring history List for mobile
         * @returns : transformed data
         */
    const historyListGridTransformerMobile = (data: any) => {
        const transformedData = data?.map((item: any) => ({
            description: (
                <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={12} alignItems='center'>
                        {createTransactionDetails(item.transaction)}
                        {createTransactionStatusReason(item.transaction)}
                        {createTransactionStatus(item.transaction)}
                        {createProcessedOn(item.createdOn)}
                    </Grid>
                </Grid>
            ),
            divider: createDivider(),
        }));

        return transformedData || [];
    };

              const gridColumn: any = {
                "title": "Select Columns to Show",
                "columns": [
                  {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                  },
                  {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                  }
            
                ]
              }

        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Transaction ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Amount",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/currency",
                    "default": true
                },
                {
                    "label": "Status",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true
                },
                {
                    "label": "Status Reason",
                    "enable": true,
                    "fieldName": "statusReason",
                    "type": "fri://fieldtype/default",
                    "default": true
                },


                {
                    "label": "Processed On",
                    "enable": true,
                    "fieldName": "createdOn",
                    "type": "fri://fieldtype/date",
                    "default": true
                },
            ]
        };

       
        
        const permissionArray: any = [
            {
                entity: "Recurring Transaction",
                name: "Wire Send",
                operation: "View"
            },
             {
                entity: "Recurring Transaction",
                name: "Check Send",
                operation: "View"
            },
             {
                entity: "Recurring Transaction",
                name: "Book Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "Ach Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "International Wire Send",
                operation: "View"
            },
            {
                entity: "Recurring Transaction",
                name: "Virtual Card Send",
                operation: "View"
            }
        ]

        return (
            <>
                <Grid id="recurring-deposit-info-grid " item xs={12}  >
                    <FxCard id="recurring-depositinfo-card" className="fx-theme-passport">
                        <FxCardHeader id={'recurring-depositinfo-card#card-header'}>
                            <Grid id="recurring-depositGrid2" item container justifyContent="space-between" xs={12} >
                                <Grid id="recurring-depositGrid3"  >
                                    <Typography id="title-recurring-depositinfo" variant="h4" >Recurring History  </Typography>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="home-recent-ea-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="home-recent-ea-card-list-DataGrids" item xs={12}>
                                { !isSmallScreen? <DataGrid id="recurring-history-card-list" multiplePermission={true} dataTransformer={transformData} permissions={permissionArray} tableClass="fx-table-passport" source={src} column={column} />
                                :<FxGridList id="recurring-history-card-list" source={src} permissions={permissionArray} dataTransformer={historyListGridTransformerMobile} column={gridColumn} /> }
                            </Grid>
                        </FxCardBody>
                    </FxCard>
                </Grid >
            </>
        )
    })
