import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { Logo } from '../../Utils/Logo';
import { getKey, setKey } from '../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxTreasureStartedLayout } from './FxTreasureStartedLayout';
import { FxTreasureCongratulationCard } from './FxTreasureCongratulationCard';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { useDispatch } from 'react-redux';
import { LogoAccount } from '../../Utils/Logo';
import { useHistory } from 'react-router';
const httpClient = HttpClient.getClient()

Logger.debug("FxInvestmentAccountCongratulation.tsx", "sign up info")

interface ITreasureDrawer {
    id:string,
    type?: string;
    onDrawerClose: (event: any) => void;
}

export const FxInvestmentAccountCongratulation: React.FC<ITreasureDrawer> = (props) => {
    ({ props } = RegisterComponent(props));  
   
    const dispatch = useDispatch()
    const history = useHistory();

    const treasureLayoutHeader = <>
        <h1>Turn your&nbsp;</h1>
        <h1>business's idle cash &nbsp;</h1>
        <h1>into revenue with&nbsp;</h1>
        <div className='fx-drawer-left-logo'><Logo showFull /></div>
        <h1>{props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : 'Cash Builder Account'}&nbsp;</h1>
        <h1>Now! &nbsp;</h1>
    </>;
   
    /**
     * Go to dashboard button handler
    */
    const handleDrawerCloseAndRedirect = (async (event: any) => {
        await getCustomerAccountList().then(()=>{
            props.onDrawerClose(event);
            if(props?.type === 'CASH_BUILDER_PLUS'){
                history.push('/cashbuilder')
            }else{
                history.push('/portfolio')
            }
        });
        
    })

    /**
     * async function to get debit card program list
     */
    async function getCustomerAccountList(){
        const filterRequest ={
            pageSize: 25,
            pageNumber: 1,
            sortOptions: {
                sortOrder: "DESC",
                sortBy: "createdOn"
            },
            criteria: {
                filters: []
            }
        };

        const accounts = await httpClient.post(getCustomerUrl("account/list", false), filterRequest);  
        updateComponentParams(dispatch, "customer-basic-info", { 'accountList': accounts?.data })
        const selectedAccount = accounts?.data?.resources?.find((x:any)=> x.type === props?.type);   
        updateComponentParams(dispatch, props?.id, { selectedAccount:selectedAccount})
        setKey("selectedAccount", selectedAccount);

        setTimeout(()=>{
            updateComponentParams(dispatch, 'account-list-sub-menu', { 'selectedOption': selectedAccount })
            updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': true })
        },400)
    }

    return (
        <Grid container id="treasure-account" >
            <Grid item className={'fx-drawer-left-content fx-drawer-left-content-grey-background'}>
                <Grid item xs={12} className={'fx-treasure-left-layout-header'}>
                    <Grid item xs={2} className={'fx-logo'}>
                        <LogoAccount />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='h2' className={'fx-welcome'}>Welcome!</Typography>
                        <Typography variant='h3' className={'fx-username'}>{getKey('CustomerName')}</Typography>
                    </Grid>
                </Grid>
                <FxTreasureStartedLayout type={props?.type} layoutHeader={treasureLayoutHeader} />
                
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                <FxTreasureCongratulationCard type={props?.type} onDrawerClose={handleDrawerCloseAndRedirect} />
            </Grid>
        </Grid>
    )
}