import React, { useMemo } from "react";
import { Switch, SwitchProps } from"@mui/material";
import { Logger } from "../../libs/utils/logger";
import { checkPermission } from "../Utils/CommonBaseClass";

Logger.debug("FxSwitch.tsx", "Fx Switch initializing")

/**
 * Switch Component used for enabling and disabling something
 */

interface FxSwitchProps extends Omit<SwitchProps, 'onClick'> {
    permissions?:any;
    value?: boolean;
    onClick?(checked: boolean): void;
    noTick?: boolean;
    isThin?: boolean;
    disabled?: boolean;
}

export const FxSwitch: React.FC<FxSwitchProps> = React.memo(
    (props) => {
        /**
         * Method handles the click event of switch
         * @param event : click event parameter
         */
        const handleOnclick = (event: any) => {
            props.onClick?.(event.target.checked);
        }

        /**
        * to set permission
        */
        const permission = useMemo(() => {
           return checkPermission(props?.permissions);
        }, [props?.permissions]);

        return (
            <>
                {permission ? <Switch {...props} id={props.id} defaultChecked={props.defaultChecked} checked={props.value}
                    className={`fx-switch ${!props.noTick ? 'fx-switch-tick' : ''} ${props.isThin ? 'fx-switch-thin' : ''}`} disabled={props.disabled? props.disabled: false}
                    onClick={handleOnclick} /> : <></>}
            </>

        )
    })