import React from 'react';
import { useParams } from "react-router-dom";
import { CreateAuthorisedUser } from './CreateAuthorisedUser';
/**
 * Component: EditUser
 * Usage: To edit user details
 */
export const EditAuthorisedUser = () => {
    interface RouteParams {
        id: string
    }
    const params = useParams<RouteParams>();
    /**
     * get ID from  url
     */
    const id = params.id
    /**
     * user details API
     */
    const user_source = {
        url: "/authorizedUser/id/" + id,
        method: "GET"
    }
    const rand = Math.floor(Math.random() * 100) + 1;
    return (
        <>
            <CreateAuthorisedUser id={rand + 'edit-user' + id} source={user_source} />
        </>
    )

}
