import React, { useState } from 'react'
import { Button, Grid, Modal, Typography, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { toTitleCase } from '../Utils/CommonBaseClass';
import { Logger } from '../../libs/utils/logger';
import HttpClient from '../../libs/utils/httpClient';
import FxCardHeader from '../Container/FxCardHeader';
import FxCard from '../Container/FxCard';
import FxCardBody from '../Container/FxCardBody';
import FxCardFooter from '../Container/FxCardFooter';
import { getCustomerUrl, processAPIResponse } from '../../libs/utils/utils';
import { ReactComponent as ThanksIcon } from '../../assets/svg/thanks.svg';
import FxSnackBar from '../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("FxSuccess.tsx", "fx success initializing")

interface FxSuccessProps {
    id?: string;
    header?: string;
    footer?: string;
    subHeader?: any;
    subTitle?:any;
    description?: string;
    redirect?: string;
    redirectUrl?: string;
    entity?: string;
    setClose?:any;
    className?: string;
}
/**
 * Generic success modal window for different entity
 */
export const FxSuccess: React.FC<FxSuccessProps> = React.memo(
    (props) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { formState: { errors }, handleSubmit } = useForm();

        const [successOpen, setSuccessOpen] = useState(true);
        const history = useHistory()
        const handleClose = () => {
            props?.setClose();
        };

        // function for closing the modal
        const handleDeleteClose = () => {
            if(props.redirect){
                history.push(props.redirect);
            }
            setSuccessOpen(false)
        };

        // onsubmit function for delete form
        async function onSubmit(data: any) {
            let status: any;
            status = await deleteEntity(props.id);
            status = processAPIResponse(status)
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: toTitleCase(props.entity) + ' Deleted Successfully!',
                });
                if(props.redirectUrl) {
                    history.push(props.redirectUrl)
                }
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };
        //Api call for delete entity
        async function deleteEntity(id: any) {
            try {
                const data: any = await httpClient.delete(getCustomerUrl(props.entity + '/id/' + id,true)).then(response => {
                    return response;
                })
                .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("FxDelete.tsx", "error", err);
                return false;
            }
        }

        /**
         * Component structure
         */
        return <>
            <Modal
                style={{ overflow: 'scroll' }}
                open={successOpen}
                onClose={handleClose}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                className={`fx-modal fx-modal-small ${props.className ? props.className : ''}`}
            >
                <div className="modal-content" >
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCard>
                              <FxCardHeader id="create-del-header" className="fx-card-header">
                                <Grid container className="fx-modal-close-icon" justifyContent="flex-end">
                                  <IconButton onClick={handleDeleteClose} id='ea-modal-close-icon' size="large">
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </FxCardHeader>
                                    <Grid container xs={12} className={'fx-modal-header-content'} direction="row" spacing={1} >
                                    {props?.subTitle &&
                                        <Grid container justifyContent="center" direction="row" item xs={12} className="fx-delete-title">
                                            <Typography id="subheader-modal" className={'fx-modal-subtitle'}  variant="h3" >
                                                {props?.subTitle}
                                            </Typography>
                                        </Grid>}
                                        {props.subHeader &&
                                        <Grid container direction="row"  justifyContent="center" item xs={12} className="fx-delete-title">
                                            <Typography id="subheader-modal" className={'fx-modal-subheader'} variant="h3" >
                                                {props.subHeader}
                                            </Typography>
                                        </Grid>}</Grid>
                                <FxCardBody id="del-create-modal-body" className="fx-info-card fx-delete-info" >
                                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                                        <Grid item container justifyContent="center" xs={12}>
                                            <Grid item container justifyContent="center" xs={12}>
                                                <ThanksIcon className="fx-icon" />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                                            <Typography id="title-delete-modal" variant="h4" className="fx-modal-title">
                                                {props.header}
                                            </Typography>
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={12} className="fx-delete-description">
                                            <Typography id="title-delete-grid-two" variant="h5" className={'fx-modal-description'} paddingBottom={'2rem'} paddingTop={'0.5rem'}>
                                                {props.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="center" className="fx-modal-footer">
                                        <Button variant="contained"
                                            className="fx-button fx-button-theme"
                                            id="del-modal"
                                            onClick={handleDeleteClose}>
                                            {props.footer? props.footer : 'GOT IT'}
                                </Button>
                                    </Grid>
                                </FxCardFooter>
                            </FxCard>


                        </form>
                    </div>
                </div>
            </Modal>
        </>;
    });
