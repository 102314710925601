import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as ManageUsageIcon } from "../../../assets/svg/manage-usage-icon.svg";
import { ReactComponent as DividerIcon } from "../../../assets/svg/divider-icon.svg";
import { clean } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { getKey } from "../../../libs/utils/storageManager";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
const httpClient = HttpClient.getClient();

Logger.debug("ManageUsageCard.tsx", "Manage Usage Card initializing");
/**
 * This component handles the Usage of the Debit Card
 */
export const ManageUsageCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  const { register, handleSubmit, control, setValue, formState: { isSubmitting, errors } } = useForm();

  const [onlineMaxLimit, setOnlineMaxLimit] = React.useState();
  const [retailMaxLimit, setRetailMaxLimit] = React.useState();
  const [atmMaxLimit, setAtmMaxLimit] = React.useState();
  const [cardData, setCardData] = useState([]);

  const { debitCardId, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

   useEffect(() => {
     getMaxLimits();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {
    let status: any;
    let request: any;

    request = {
      "transactionLimit": [
        {
            "frequency": "DAILY",
            "location": "DOMESTIC",
            "transactionType": "ATM",
            "amount": data.atmTransactionLimit,
        },
        {
            "frequency": "DAILY",
            "location": "DOMESTIC",
            "transactionType": "ONLINE",
            "amount": data.onlineTransactionLimit,
        },
        {
            "frequency": "DAILY",
            "location": "DOMESTIC",
            "transactionType": "RETAIL",
            "amount": data.retailTransactionLimit,
        }
    ]
    };

    status = await manageUsageApiCall(clean(request));
    status = processAPIResponse(status)

    if (status.status) {
      setTitle('Transaction Limits Updated');
      setDescription('Transaction limits of your debit card has been successfully updated.');
      setSuccess(true);
      setBtnText('Close');
    }else {
      //api  failed
      FxSnackBar.show({
          text: status.message,
      });
    }
    props.onClose();
  }

  /**
   * Method to call api for set Usage limit
   * @param payloadData : request payload
   */
  async function manageUsageApiCall(payloadData: any) {
    try {
      const url =
        "/account/id/" +
        getKey("selectedAccount")?.id +
        "/debitCard/id/" +
        debitCardId +
        "/limit";
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("ManageUsage.tsx", "error", err);
      return err;
    }
  }

  /**
  * Api call for get max limits info
  */
  async function getMaxLimitsApiCall() {
    try {
      const data = await httpClient.get(getCustomerUrl("/account/id/" + getKey("selectedAccount")?.id + "/debitCard/id/" + debitCardId + "/limit"))
      return data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Method handles the max limit api call and setting the max values in tdhe state
   */
  async function getMaxLimits() {
    let status = await getMaxLimitsApiCall();
    setCardData(status.data.transactionLimit);
    if (status.data) {
      status?.data?.transactionLimit?.forEach((node: any) => {
        if(node?.transactionType === 'ATM'){
          setAtmMaxLimit(node.maxAmountLimit);
          setValue('atmTransactionLimit', node.amount);
        }
        else if(node?.transactionType === 'RETAIL'){
          setRetailMaxLimit(node.maxAmountLimit);
          setValue('retailTransactionLimit', node.amount);
        }
        else if(node?.transactionType === 'ONLINE'){
          setOnlineMaxLimit(node.maxAmountLimit);
          setValue('onlineTransactionLimit', node.amount);
        }
      });
    }
  }
  

  return (<>
    {cardData.length > 0 && <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className="fx-manage-card-usage-modal">
          <FxCardHeader id="manage-card-usage-header" className="fx-card-header">
            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  <ManageUsageIcon />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="manage-card-usage-modal-title" align="center" className="fx-manage-card-usage-modal-title" >
                    Manage Usage
                  </Typography>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="manage-usage-card-modal-body" className="fx-info-card">
            <Grid
              container
              direction="row"
              spacing={2}
              className="fx-modal-form flex column"
              paddingLeft={"1rem"}
            >
              <Grid container xs={12} sm={12}>
                <Typography id="title-delete-modal" variant="body1">
                  ATM Withdrawal Limits (Max Limit: ${atmMaxLimit})
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("atmTransactionLimit") }}
                  type="number"
                  className={errors.atmTransactionLimit ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  control={control}
                  rules={{ required: true }}
                  id="atmTransactionLimit"
                  label="Transaction Limit*"
                  name="atmTransactionLimit"
                  variant="outlined"
                  onWheel={(e:any)=>e.target.blur()}
                  valuePattern={/-|\+|e|E/} min={0.01}
                  prefix="$"
                />
              </Grid>

              <Grid container xs={12} sm={12}>
                <Typography id="title-delete-modal" variant="body1">
                  Retail Limits (Max Limit: ${retailMaxLimit})
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("retailTransactionLimit") }}
                  type="number"
                  passwordIcon={true}
                  className={errors.retailTransactionLimit ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  control={control}
                  rules={{ required: true }}
                  id="retailTransactionLimit"
                  label="Transaction Limit*"
                  name="retailTransactionLimit"
                  variant="outlined"
                  onWheel={(e:any)=>e.target.blur()}
                  valuePattern={/-|\+|e|E/} min={0.01}
                  prefix="$"
                />
              </Grid>

              <Grid container xs={12} sm={12}>
                <Typography id="title-delete-modal" variant="body1">
                  Online Limits (Max Limit: ${onlineMaxLimit})
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("onlineTransactionLimit") }}
                  type="number"
                  passwordIcon={true}
                  className={errors.onlineTransactionLimit ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  control={control}
                  rules={{ required: true }}
                  id="onlineTransactionLimit"
                  label="Transaction Limit*"
                  name="onlineTransactionLimit"
                  variant="outlined"
                  onWheel={(e:any)=>e.target.blur()}
                  valuePattern={/-|\+|e|E/} min={0.01}
                  prefix="$"
                />
              </Grid>
              <Typography id="title-replace-debit-card-modal" variant="h5" className="fx-manage-card-usage-modal-note">
                Note: Transaction limit resets daily at 00:00hr UTC.
              </Typography>
              <span className="fx-manage-card-usage-modal-divider">
                <DividerIcon />
              </span>
            </Grid>
          </FxCardBody>
          <FxCardFooter id="manage-usage-card-modal-Footer" className="fx-footer">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"manage-usage-card-submit-button"}
                type="submit"
                isSubmitting={isSubmitting}
              >
                SUBMIT
              </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>
    </Grid>}
    </>
  );
});
