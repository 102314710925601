import React, { useEffect, useState } from 'react'
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AUTH_STRINGS } from '../../../constants/strings';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();
interface InputErrType {
    type: string;
    message: string;
}

Logger.debug("VerifyMicroDeposit.tsx", "verify micro deposit initializing")
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: VerifyMicroDeposit
 * Usage: To verify micro deposit amount
 */
export const VerifyMicroDeposit: React.FC<any> = React.memo((props) => {

        let externalAccountData: any;
        const submitButton: any = 'Verify';

        const { register, formState: { errors }, handleSubmit, control } = useForm();
        const [isEdit, setIsEdit] = useState(false);
        const setAmountValidationOptions = {
            required: true,
            min: 0.01,
            max: 0.99
        };

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const [accountNumber, setAccountNumber] = useState(context?.data?.body?.accountNumberLast4 ? '**' + context.data.body.accountNumberLast4 : '');

        let accNumber: any = "";
        externalAccountData = context?.data?.body;
        /**
         * Set default data
         *  */
        if (context?.data?.body && !isEdit) {
            if (context.data.body.card?.cardNumberLast4) {
                accNumber = '** ' + context.data.body.card.cardNumberLast4;
            } else if (context.data.body?.accountNumberLast4) {
                accNumber = '** ' + context.data.body.accountNumberLast4
            }
            setAccountNumber(accNumber);
            setIsEdit(true);
        }
        const dispatch = useDispatch()
        /**
         * Method to rest the component on unmount.so that when redirect back page it will call the api again
         * */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const [isLoading, setIsLoading] = useState(false);

        /**
         * Method to  render error
         * */
        const renderError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to handle form submit event
         * @param data :form data
         */
        async function onSubmit(data: any) {
            let req: any;
            let status: any;
            req = {
                "microDeposit": {
                    "creditAmount": {
                        "amount1": data.amount1
                    }
                }
            }
            if(data.amount2 && data.amount2 !== 0){
                req['microDeposit']['creditAmount']['amount2'] = data.amount2;
            }
            status = await verifyMicroDeposit(req);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'External Account Validation updated Successfully!',
                });
                props?.close();
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'external-account-details-card' + externalAccountData['id'] , data_state:1 } });
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'external-account-validation-status-card', data_state:1 } });
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        /**
         * Method to to call API for verify micro deposit
         * @param paylaoddata : request payload
         */
        async function verifyMicroDeposit(paylaoddata: any) {
            try {
                const url = '/externalAccount/id/' + externalAccountData['id'] + '/verify'
                const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("VerifyMicroDeposit.tsx", "error", err);
                return false;
            }
        }

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {isEdit && <form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="validate-ea-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-ea-modal" variant="h4">
                                            Verify Micro Deposit
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="ea-create-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2}  >
                                    {/* dotted line */}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FxTextEdit register={{ ...register("amount1") }} className={errors.amount1 ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAmountValidationOptions} prefix="$" type="number" control={control} id="amount1" label={"Enter First Credit Amount Recieved on " + accountNumber} name="amount1" variant="outlined" />
                                        <div className={'error-message'}>
                                            {errors.amount1 && renderError(errors.amount1)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FxTextEdit register={{ ...register("amount2") }} className={errors.amount2 ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} prefix="$" type="number" control={control} id="amount2" label={"Enter Second Credit Amount Received on " + accountNumber} name="amount2" variant="outlined" />
                                        <div className={'error-message'}>
                                            {errors.amount2 && renderError(errors.amount2)}
                                        </div>
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="ea-modal"
                                        onClick={() => props.close()}>
                                        Cancel
                                    </FxButton>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id={submitButton + "external-account"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                                submitButton
                                            )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
