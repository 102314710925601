import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonGroupProps } from "@mui/material";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxButtonGroupMultiSelect.tsx", "Fx Button Group Multi Select initializing")

interface IOption {
    id: string;
    value: string;
    label: string;
    selected: boolean;
}

interface FxButtonGroupMultiSelectProps extends ButtonGroupProps {
    options: IOption[]
}

/**
 * fx button group
 * @param props FxButtonGroupMultiSelectProps
 */
export const FxButtonGroupMultiSelect: React.FC<FxButtonGroupMultiSelectProps> =
    (props) => {
        const [options, setOptions] = useState<any>(props.options);
        
        //to get updated options
        useEffect(()=>{setOptions(props.options)},[props.options])

        // Method handles the click event of the button
        const handleClick = (event: any, item: any) => {
            try {
                const buttonOptions: any = [...options];
                // eslint-disable-next-line array-callback-return
                buttonOptions.map((button: any) => {
                    if (button.value === item.value && (button.selected === false || button.selected === undefined )) {
                        button.selected = true;
                    }
                    else if (button.value === item.value && button.selected === true) {
                        button.selected = false;
                    }
                })
                // update the state with new item selected
                setOptions(buttonOptions);
                if (props.onClick) {
                    props.onClick(item);
                }
            }
            catch (e) { }
        }

        return (
            <ButtonGroup key={props?.id} variant="contained" aria-label="outlined primary button group" className="fx-button-group" id={props.id}>
                {options?.map((item: any) => {
                    return <Button id={props.id + '-' + item.id} onClick={(e: any) => handleClick(e, item)} className={"fx-button " + (item.selected ? "fx-button-group-sel" : "fx-button-group-def")} >{item.label}</Button>
                })}
            </ButtonGroup>
        )
    }