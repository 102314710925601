import React , { useMemo } from 'react'; // we need this to make JSX compile
import { Grid, IconButton, Modal, Typography, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useMediaQuery } from "react-responsive";
import { FxTextEdit } from '../../Input';
import { FxButton } from '../../Action/FxButton';
import { useForm } from 'react-hook-form';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { getKey } from '../../../libs/utils/storageManager';

const httpClient = HttpClient.getClient();


Logger.debug("FxInvestmentAllocationEditModal.tsx", "Investment Edit Modal initializing")

/**
 * This component handles the Edit Investment Allocation Modal
 */
export const FxInvestmentAllocationEditModal: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const isTabScreen = useMediaQuery({ query: '(max-width: 999px)' })
        /**
         * Method handles the onclick event for closing modal
         */
        const handleClose = () => {
            if (props.onClose) {
                props.onClose();
            }
        };

        const { register, formState: { errors }, handleSubmit, setValue, getValues, control, watch, setError, clearErrors } = useForm();

        /**
         * function to get total percentage of all three field
         */
        const totalAmount = useMemo(() => {
            let totalValue = Number(getValues('managedIncomeNewPercentage')) + Number(getValues('managedMoneyMarketNewPercentage')) + Number(getValues('managedTreasuresNewPercentage'));
            return (totalValue || '0');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [watch()]);


        /**
         * function on submit for edit the investment
         * @param data request data
         */
        async function onSubmit(data: any) {
            let req = {
                "investmentProvider": "TREASURE",
                "allocation": {
                    "managedTreasuries": data?.managedTreasuresNewPercentage,
                    "managedIncome": data?.managedIncomeNewPercentage,
                    "managedMoneyMarket": data?.managedMoneyMarketNewPercentage
                }
            }
            let status:any;
            status = await edit(req);
            status = processAPIResponse(status);

            if (status.status) {
                props.onClose();
                // updateComponentParams(dispatch, 'investment-summary-card', { 'investSuccessMessage': true,'amount':data?.managedMoneyMarketPercentage})
            } else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        };
        /**
         * calling api to edit the investment
         * @param req req object
         * @returns 
         */
         async function edit(req: any) {
            try {
                const data = await httpClient.post(getCustomerUrl('account/id/' + getKey('customerInitialAccount')?.id + '/portfolio/rebalance'), req).then(response => {
                    return response;
                }).catch(error => {
                    return { ...error };
                })
                return data;
            } catch (err) {
                return err;
            }
        }

        /**
         * Method to validate input value should not have more than 100
         * @param event input object
         */
        function handleMaxValue(event: any) {
            let targetElement = event.target;
            setValue(targetElement.name, (targetElement?.value));
            if(+targetElement?.value > 100 || !targetElement?.value) {
                setError(targetElement.name, {
                    type: 'required'
                })
            } else {
                clearErrors(targetElement.name)
            }
        }

        /**
         * Method to handle upto two decimal values
         * @param val value
         * @param fieldName field name
         */
        function handleUptoTwoDecimalValue(value: any, fieldName: string) {
            if(value) {
                setValue(fieldName, (+value).toFixed(2));
            }
        }

        return (
            <Grid container className={"fx-container"}>
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={props.open}
                    onClose={handleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className={isTabScreen ? "fx-modal fx-modal-medium" : 'fx-modal fx-modal-small2medium'}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-content fx-view-balance-detail" >
                        <div className="modal-body">
                            <FxCard className="fx-theme-passport">
                                <FxCardHeader>
                                    <Grid container direction="row" item xs={12} justifyContent="space-between">
                                        <Grid item container xs={11} justifyContent="flex-start">
                                            <Typography variant="h3" className='fx-header-title'>
                                                Edit Investment Allocation
                                            </Typography>
                                        </Grid>
                                        <Grid className="fx-modal-close-icon" item xs={1}>
                                            <IconButton
                                                onClick={handleClose}
                                                size="large">
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" className='fx-header-title fx-header-secondary-text'>
                                                Rebalance your portfolio assets by providing new percentage allocation.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody className="fx-info-card fx-form-edit-profile" >
                                    <Grid container columnSpacing={2} className='fx-investment-allocation-edit-header'>
                                        <Grid item xs={5}> <Typography>Investment</Typography></Grid>
                                        <Grid item xs={7} container columnSpacing={3}>
                                            <Grid item xs={6}> <Typography>Current</Typography></Grid>
                                            <Grid item xs={6}> <Typography>New</Typography></Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={2} marginBottom='1rem'>
                                            <Grid item container alignItems='center' xs={5}>
                                                <Typography>Managed Money Market</Typography>
                                            </Grid>
                                            <Grid container item xs={7} spacing={2}>
                                                <Grid item xs={6} container alignItems="center" justifyContent='space-between'>
                                                    <FxTextEdit
                                                        register={{ ...register("managedMoneyMarketCurrentPercentage") }}
                                                        className={"fx-input-edit"}
                                                        control={control}
                                                        id="managed-money-market-current-percentage-textbox"
                                                        name="managedMoneyMarketCurrentPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        isEditable={false}
                                                        showDecimal={true}
                                                        value={props.allocation?.managedMoneyMarket?.targetPercent || 0}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedMoneyMarketNewPercentage") }}
                                                        className={errors.managedMoneyMarket ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                        control={control}
                                                        valuePattern={/-|\+|e|E/}
                                                        onWheel={(e:any)=>e.target.blur()}
                                                        onChange={handleMaxValue}
                                                        onBlur={(val) => handleUptoTwoDecimalValue(val, 'managedMoneyMarketNewPercentage')}
                                                        type='number'
                                                        id="managed-money-market-new-percentage-textbox"
                                                        name="managedMoneyMarketNewPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        showDecimal={true}
                                                        defaultValue={0}
                                                        placeholder='0.00'
                                                        
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item display='flex' alignItems='center' xs={5}>
                                                <Typography>Managed Treasuries</Typography>
                                            </Grid>
                                            <Grid container item xs={7} spacing={2}>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedtreasuresCurrentPercentage") }}
                                                        className={"fx-input-edit"}
                                                        control={control}
                                                        isEditable={false}
                                                        id="managed-treasuries-current-percentage-textbox"
                                                        name="managedtreasuresCurrentPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        value={props.allocation?.managedTreasuries?.targetPercent || 0}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedTreasuresNewPercentage") }}
                                                        className={errors.managedTreasuries ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                        control={control}
                                                        valuePattern={/-|\+|e|E/}
                                                        onWheel={(e:any)=>e.target.blur()}
                                                        onChange={handleMaxValue}
                                                        onBlur={(val) => handleUptoTwoDecimalValue(val, 'managedTreasuresNewPercentage')}
                                                        type='number'
                                                        id="managed-treasuries-new-percentage-textbox"
                                                        name="managedTreasuresNewPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        defaultValue={0}
                                                        placeholder='0.00'
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item display='flex' alignItems='center' xs={5}>
                                                <Typography>Managed Income</Typography>
                                            </Grid>
                                            <Grid container item xs={7} spacing={2}>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedIncometCurrentPercentage") }}
                                                        className={"fx-input-edit"}
                                                        control={control}
                                                        isEditable={false}
                                                        id="managed-income-current-percentage-textbox"
                                                        name="managedIncometCurrentPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        showDecimal={true}
                                                        value={props.allocation?.managedIncome?.targetPercent || 0}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedIncomeNewPercentage") }}
                                                        className={errors.managedIncome ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                        control={control}
                                                        valuePattern={/-|\+|e|E/}
                                                        onWheel={(e:any)=>e.target.blur()}
                                                        onChange={handleMaxValue}
                                                        onBlur={(val) => handleUptoTwoDecimalValue(val, 'managedIncomeNewPercentage')}
                                                        type='number'
                                                        id="managed-income-new-percentage-textbox"
                                                        name="managedIncomeNewPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        showDecimal={true}
                                                        defaultValue={0}
                                                        placeholder='0.00'
                                                    />
                                                </Grid>
                                        </Grid>
                                    </Grid>
                                   
                                    <Divider />
                                    <Grid item container xs={12} spacing={2} marginBottom='1rem' marginTop='0' alignItems='center'>
                                            <Grid item xs={5}>
                                                <Typography className='fx-investment-allocation-total' variant='caption'>Total Investment</Typography>
                                                <Typography className='fx-investment-allocation-total-target' variant='caption'>Total New Investment should be 100%</Typography>
                                            </Grid>
                                            <Grid container item xs={7} spacing={2}>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedMoneyMarketPercentage") }}
                                                        className={errors.annualGrossRevenue ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                        control={control}
                                                        id="managed-money-market-percentage-textbox"
                                                        name="managedMoneyMarketPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        defaultValue={100}
                                                        isEditable={false}
                                                        showDecimal={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FxTextEdit
                                                        register={{ ...register("managedMoneyMarketFinalPercentage") }}
                                                        className={errors.annualGrossRevenue ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                                        control={control}
                                                        id="managed-money-market-percentage-textbox"
                                                        name="managedMoneyMarketFinalPercentage"
                                                        variant="outlined"
                                                        suffix='%'
                                                        value={totalAmount}
                                                        type='number'
                                                        isEditable={false}
                                                        showDecimal={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid xs={12} direction="row" display='flex' justifyContent="flex-end" className="fx-modal-footer">
                                        <Button variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="cancel-button"
                                            onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            className={totalAmount === 100 ? "fx-button fx-button-theme" : "fx-button fx-button-theme fx-button-disabled"}
                                            type="submit"
                                            disabled={totalAmount !== 100}
                                        >
                                            Submit
                                        </FxButton>
                                    </Grid>

                                </FxCardBody>
                            </FxCard>
                        </div>
                    </div>
                    </form>
                </Modal>
            </Grid>
        );
    })