/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import HttpClient from "../../../libs/utils/httpClient";
import FxSnackBar from "../../Utils/fx-snack-bar";
import {  Divider } from '@mui/material';
import { ReactComponent as CaptureIcon } from '../../../assets/svg/capturecreate.svg';
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { useDispatch } from "react-redux";
import { clean, updateComponentParams } from "../../Utils/CommonBaseClass";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
const httpClient = HttpClient.getClient();

Logger.debug("CaptureCollect.tsx", "Void Collect initializing");


interface ICaptureCollect {
  id?: string,
  open?: boolean,
  onClose: () => any,
  className?: string,
  isVoid:boolean,
  data?: any,
  metadata:any,
  capturedAmount:string,
  showVoid: boolean,
  showTip: boolean,
  isTip: boolean,
  amountSame: boolean,
}

/**
 * This component handles the Capturing of the Auth Created
 */
export const CaptureCollect: React.FC<ICaptureCollect> =  React.memo(
  (props) => {
    ({ props } = RegisterComponent(props));
      const { 
        register, 
        formState: { errors, isSubmitting }, 
        handleSubmit, 
        watch,
        setValue,
        control } = useForm();
     

        const dispatch = useDispatch();

        useEffect(() => {
          dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
          updateComponentParams(dispatch, props?.id, { data : props?.data, 'isVoid': false, 'isTip': false, 'capturedAmount':props?.metadata?.data?.pendingCaptureAmount, 'showVoid': false, 'showTip': false, 'amountSame': true })
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 
    
  /**
   * Async function handles the capture form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let status: any;

   const request:any = {
      "amount": data?.captureAmount
    }

    if(props?.showTip && props?.isTip)
      request.tipAmount = data?.tipAmount;

    if(props?.isVoid){
      request.isFinalCapture = true;
    }

    status = await captureCollect(clean(request));
    status = processAPIResponse(status)

    if(status.status){
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'Capture created Successfully',
    });
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'transaction-Cards-details-config-card1' + props?.metadata?.scheduleId } });
      dispatch({ type: "DATA_COMPONENT_RESET", payload: {id:"transaction-processing-card-" + props?.metadata?.scheduleId} });
      props.onClose();
    } else {
      //api  failed
      FxSnackBar.show({
          text: status.message,
      });
    }
  }



  /**
   * Method to call api for capture auth
   * @param payloadData : request payload
   */
  async function captureCollect(payloadData: any) {
    try {
      const url: any = 'transaction/id/' + props?.metadata?.scheduleId + '/capture';
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("CaptureCollect.tsx", "error", err);
      return err;
    }
  }

   /**
     * Method enables and disables the allow duplicate
     * @param event : allow duplicate status
     */
   const handleIsVoid = (event: any) => {
    updateComponentParams(dispatch, props?.id, { 'isVoid': event }) ; 
};

    /**
     * Method enables and disables the add Tip
     * @param event 
     */
    const handleIsTip = (event: any) => {
      const captureAmount = Number(watch('captureAmount'));
      const pendingCaptureAmount = Number(props?.metadata?.data?.pendingCaptureAmount);
      const tipAmount = isNaN(captureAmount) || isNaN(pendingCaptureAmount) ? 0 : (captureAmount - pendingCaptureAmount).toFixed(2);
      
      setValue('tipAmount', tipAmount);
      updateComponentParams(dispatch, props?.id, { 'isTip': event }) ; 
    };

  /**
   * Checking the Entered Capture Amount to render the specified section below the Amount Field
   */
  const handleAmountChange = (event:any) => {
    const amount = parseFloat(event.target.value); 
    const pendingCaptureAmount = Number(props?.metadata?.data?.pendingCaptureAmount);
    if( event.target.name === 'captureAmount'){
      setValue('captureAmount', amount); 
    }
    setValue('tipAmount', event.target.name === 'captureAmount' ? (amount - pendingCaptureAmount).toFixed(2): amount.toFixed(2));
    const updatedCaptureAmount = event.target.name === 'tipAmount' ? Number(watch('captureAmount')): amount;
    updateComponentParams(dispatch, props?.id, { 'amountSame': false }) ;
    if(updatedCaptureAmount < pendingCaptureAmount)
      updateComponentParams(dispatch, props?.id, { 'showVoid': true, 'showTip': false, 'isTip': false }) ; 
    else if(updatedCaptureAmount > pendingCaptureAmount)
      updateComponentParams(dispatch, props?.id, { 'showTip': true, 'showVoid': false, 'isVoid': false}) ; 
    else if(updatedCaptureAmount === pendingCaptureAmount || isNaN(updatedCaptureAmount))
      updateComponentParams(dispatch, props?.id, { 'showTip': false, 'showVoid': false, 'amountSame': true, 'isTip': false, 'isVoid': false }) ; 
  };


      return (
        <div className="modal-content" >
          <div>
            {<form onSubmit={handleSubmit(onSubmit)}>
              <FxCard className="fx-manage-card-usage-modal">
                <FxCardHeader id="capture-schedule-header">
                  <Grid  container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                    <Grid item container xs={12} justifyContent="flex-center" className="fx-capture-collect-header">
                      {/* image */}
                      <Grid><CaptureIcon></CaptureIcon></Grid>
                    </Grid>
                  </Grid>
                </FxCardHeader>
                <FxCardBody id="capture-schedule-modal-body" className="fx-info-card" >
                <Grid container direction="row" spacing={2} className="fx-modal-form flex column" >
                  <Grid item xs={12} className="fx-capture-collect-header">
                    <Typography id="sub-title-capture-schedule-modal" variant="h4">
                           Capture
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography id="sub-title-capture-schedule-modal" className="fx-capture-collect-subheading fx-capture-collect-header" variant="h6">
                          You cannot undo this action
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        register={{ ...register("pendingCaptureAmount") }}
                        className={errors.pendingCaptureAmount ? "border-error-input fx-input-edit" : "fx-input-edit"}
                        control={control}
                        prefix="$"
                        type="number"
                        defaultValue={props?.metadata?.data?.pendingCaptureAmount}
                        id="pendingCaptureAmount"
                        label={"Pending Capture Amount*"}
                        name="pendingCaptureAmount"
                        isEditable= {false}
                        readOnly= {true}
                        variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FxTextEdit
                        register={{ ...register("captureAmount") }}
                        className={errors.captureAmount ? "border-error-input fx-input-edit" : "fx-input-edit"}
                        defaultValue={props?.metadata?.data?.pendingCaptureAmount}
                        control={control}
                        prefix="$"
                        type="number"
                        id="captureAmount"
                        label={"Capture Amount*"}
                        name="captureAmount"
                        setValue={setValue}
                        onChange={handleAmountChange}
                        variant="outlined" />
                    </Grid>

                    { props?.showVoid && <Grid item xs={12} sm={12}>
                      <FxSwitch
                          id="create-auth-collect-void"
                          value={props?.isVoid}
                          onClick={handleIsVoid}
                      ></FxSwitch>{" "}
                      <FxLabel value={`Void remaining auth amount of $ ${!isNaN(Number(watch('captureAmount'))) ? (Number(props?.metadata?.data?.pendingCaptureAmount)-Number(watch('captureAmount'))).toFixed(2) : ''}`}></FxLabel>
                    </Grid>}

                    { props?.showTip && ((props?.metadata?.salesDetails?.length === 0) || (props?.metadata?.showVoid?.length === 0)) && <Grid item xs={12} sm={12}>
                      <FxSwitch
                          id="create-auth-collect-void"
                          value={props?.isTip}
                          onClick={handleIsTip}
                      ></FxSwitch>{" "}
                      <FxLabel value={"Add Tip (Gratitude)"}></FxLabel>
                    </Grid>}

                    { props?.isTip && props?.showTip && 
                      <Grid item xs={12} sm={12}>
                        <FxTextEdit
                          register={{ ...register("tipAmount") }}
                          className={errors.tipAmount ? "border-error-input fx-input-edit" : "fx-input-edit"}
                          defaultValue={Number(watch('captureAmount'))-Number(props?.metadata?.data?.pendingCaptureAmount)}
                          control={control}
                          prefix="$"
                          type="number"
                          id="tipAmount"
                          label={"Tip Amount (Gratitude)"}
                          name="tipAmount"
                          setValue={setValue}
                          onChange={handleAmountChange}
                          variant="outlined" />
                      </Grid>
                    }

                    {props?.amountSame && <Grid className="fx-capture-collect-description-text fx-capture-collect-subheading">
                      <Typography>This will create a new capture for the amount listed above, and will decrease the pending capture amount by the same. Completing a capture less than the pending capture amount could result in increased processing fees.</Typography>
                    </Grid>}

                    {props?.isVoid && <Grid className="fx-capture-collect-description-text fx-capture-collect-subheading">
                      <Typography>This will create a new capture for the amount listed above, and will void the remaining transaction amount.</Typography>
                    </Grid>}

                    {props?.showVoid && !props?.isVoid && <Grid className="fx-capture-collect-description-text fx-capture-collect-subheading">
                      <Typography>This will create a new capture for the amount listed above, and will decrease the pending capture amount by the same. Completing a capture less than the pending capture amount could result in increased processing fees.</Typography>
                    </Grid>}

                    {props?.showTip && <Grid className="fx-capture-collect-description-text fx-capture-collect-subheading">
                      <Typography>This will create a new capture for the amount listed above.</Typography>
                    </Grid>}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </FxCardBody>
                <FxCardFooter id="capture-schedule-modal-Footer" className="fx-footer">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    className="fx-modal-footer"
                  >
                    <Button
                      variant="contained"
                      className="fx-button fx-button-cancel"
                      id="cancel-button"
                      onClick={() => props.onClose()}
                    >
                      Cancel
                    </Button>
                    <span className="fx-padding-right-16" />
                    <FxButton
                      disableRipple={false}
                      className="fx-button fx-button-theme"
                      id={"replace-debit-card-submit-button"}
                      type="submit"
                      isSubmitting={isSubmitting}
                    >
                      CAPTURE
                    </FxButton>
                  </Grid>
                </FxCardFooter>
              </FxCard>
            </form>}
          </div>
        </div>
      );
  });
