import React from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { AUTH_STRINGS } from '../../../constants/strings';
import HttpClient from '../../../libs/utils/httpClient';
import { useHistory } from 'react-router';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { FxLink } from '../../Action/FxLink';
import { Logo } from '../../Utils/Logo';
import { useMediaQuery } from 'react-responsive'
import { FxButton } from '../../Action/FxButton';
import Copyright from './Copyright';
import FxSnackBar from '../../Utils/fx-snack-bar';

/**
 * Component: attemptForgotPassword
 * Usage: to show forgot password assistance form
 */

/**
 * Method to call forgot password API
 */
async function attemptForgotPassword(paylaoddata: any) {

    try {
        const newpayload = { ...paylaoddata };
        newpayload.userType = "CUSTOMER";
        const httpClientnoBearer = HttpClient.getClient();
        HttpClient.unsetToken();
        const data: any = await httpClientnoBearer.post('user/forgotPassword', newpayload).then(response => {
            return response.data
        }).catch(error => {
            return error.response.data;
        })

        if (data) {
            return data;
        }
        else {
            return false;
        }
    } catch (err) {
        return false;
    }
}
const ForgotPassword = (): JSX.Element => {
    const { register, formState: { errors }, handleSubmit, control } = useForm();
    const history = useHistory();

    const [isloading, setIsloading] = React.useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })

    /**
     * Method to handle form submit
     * @param data: form data
     */
    async function onSubmit(data: any) {
        setIsloading(true);

        const status = await attemptForgotPassword(data);
        setIsloading(false);

        if (status) {
            // auth success
            if (!status.errorCode) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Password Reset Instructions Successfully Sent on your Email',
                });
                history.push("/login");
            }
            else {
                FxSnackBar.show({
                    text: status?.errorMessage || 'Please try again later',
                });
            }

        }
        else {
            //auth failed
            FxSnackBar.show({
                text: 'Please try again',
            });
        }
    }


    const renderEmailError = (err: any): string => {
        if (err.type === 'required') {
            return AUTH_STRINGS.ERRORS.EMAIL_PLEASE_ENTER;
        }
        return err.message;
    };

    const setEmailValidationOptions = {
        required: true,
        maxLength: 250,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
            message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
        },
    };

    return (
        <Grid item xs={12} container direction="row">
            <Grid item xs={12} container direction="row" alignContent="flex-start" sm={isSmallScreen ? undefined : 12}  >
                <div className="login-page">
                    <Grid xs={12} sm={11} container justifyContent="center">
                        <Grid xs={12} sm={4} className="login-page-content">
                            <div className="login-logo">
                                {/* logo */}
                                <Logo showFull />
                            </div>
                            {/* title */}
                            <Grid id="email-forgot-label" container direction="row" item className="fx-login-text" justifyContent="center">{" Forgot your password? No problem."}</Grid>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="login-page-content-card">
                                    <div className="flex column login-page-content-card-content">
                                        <Typography id="email-label" className="form-label label-color">{"Enter your email below. We will send an email to you, with instructions to reset your password."}
                                        </Typography>
                                        <FxTextEdit register={{ ...register("userName") }} type="email" className={errors.userName ? "border-error-input fx-input-edit login-email forgot-password-text-input" : "fx-input-edit login-email forgot-password-text-input"} control={control} rules={setEmailValidationOptions} id="forgot-password-userName" label="Email" name="userName" />
                                        <input type="hidden" value="CUSTOMER" name="userType" />
                                        <div className={'error-message'}>
                                            {errors.userName && renderEmailError(errors.userName)}
                                        </div>

                                    </div>
                                    <div className="flex login-buttonarea">
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button login-signin"
                                            id="forgot-password-signin"
                                            type="submit"
                                        >
                                            {isloading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                'Continue'
                                            )}
                                        </FxButton>
                                    </div>
                                </div>
                            </form>
                        </Grid>
                    </Grid>

                </div>
            </Grid>
            <Grid item xs={12} className="login-page-bottomstatic" container>
                <Grid item xs={12} className="login-page-bottom-forget-password"><FxLink className="forgot-password" id="go-back-to-login-link" redirect="/login">Go Back To Login</FxLink></Grid>
                <Copyright />
            </Grid>
        </Grid>

    );
};

export default ForgotPassword;