import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxCustomComponent.tsx", "Fx Custom Component initializing")

/**
 * Component used for custom data purpose
 * @param props :FxCustomComponentProps
 * @returns 
 */
export const FxCustomComponent: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> =
  (props) => {
    return (
      <>
        <div
          id={props.id}
          className={clsx(props.className, props.className ? props.className : '')}
        >{props.children}
        </div>
      </>
    );
  }
