/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Logger } from '../../../libs/utils/logger';
import { EditMailingAddress } from './EditMailingAddress';
import { getKey } from "../../../libs/utils/storageManager";
/**
 * Component used to display edit mailing address form
 */
Logger.debug("MailingAddress.tsx", "mailing address initializing")


export const MailingAddress: React.FC<any> = React.memo(() => {
        const params = useParams<any>();
        const id = params.id;

        const info_source = {
            url: "/customer/id/" + getKey('customerId') +"/mailingAddress/id/" + id,
            method: "GET"
        }
        return (
            <Grid id="customer-profile-info-Grid" item xs={12}>
                <EditMailingAddress id="edit-mailing-address" source={info_source}></EditMailingAddress>
            </Grid >
        )
    })
