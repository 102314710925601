/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import HttpClient from '../../../../libs/utils/httpClient';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { FxVisibilityIndicator } from '../../../Utils/FxVisibilityIndicator';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const httpClient = HttpClient.externalUrl({ responseType: 'blob' });

export interface FxPdfReaderProps {
    className?: string;
    url: string;
    onFileInfoReady?(fileInfoBase64: string): void;
    onRead?(): void;
}

/**
 * Component: FxPdfReader
 * Usage: display pdf
 */
export const FxPdfReader: React.FC<FxPdfReaderProps> = React.memo(
    ({className, url, onFileInfoReady, onRead}) => {
        // Initially FxVisibilityIndicator will show wrong value because of react-pdf
        const [rendered, setRendered] = useState(false);
        const [fileBase64, setFileBase64] = useState<string | ArrayBuffer>();
        const [loading, setLoading] = useState(true);
        const [numPages, setNumPages] = useState<number>();
        const onFileInfoReadyRef = useRef(onFileInfoReady);
        onFileInfoReadyRef.current = onFileInfoReady;
        const onReadRef = useRef(onRead);
        onReadRef.current = onRead;

        /**
         * Function to convert pdf to base64
         * @param value :content
         */
        function convertPdfToBase64(pdf: Blob) {
            const fileReader = new FileReader();
            //Check File is not Empty
            if (pdf) {
                // Onload of file read the file content
                fileReader.onload = function () {
                    if(!fileReader.result) {
                        return;
                    }
                    setFileBase64(fileReader.result);
                    const fileInfo = onFileInfoReadyRef.current ? (fileReader.result as string)?.replace?.('data:application/pdf;base64,', '') : '';
                    if(fileInfo) {
                        onFileInfoReadyRef.current?.(fileInfo);
                    }
                };
                // Convert data to base64
                fileReader.readAsDataURL(pdf);
            }
        }

        useEffect(() => {
            /**
             * Function to get pdf File
             */
            const getPdfFile = async () => {
                try {
                    const data: any = await httpClient.get(url).then(response => {
                        return response;
                    })
                    .catch((error) => {
                        return { ...error };
                    });
                    convertPdfToBase64(data.data)
                    return data;
                } catch (err) {
                    return err;
                }
            }
            getPdfFile();
        }, [url]);

        const onDocumentLoadSuccess = useCallback(({ numPages }: PDFDocumentProxy) => {
            setNumPages(numPages);
            setLoading(false);
            setTimeout(() => {
                setRendered(true);
            }, 1000);
        }, []);

        const handleVisibilityChange = useCallback((visible: boolean) => {
            visible && onReadRef.current?.();
        }, []);

        const renderPages = useMemo(() => {
            if(!numPages) {
                return;
            }
            return (
                <>
                    {Array(numPages).fill(null).map((_, i) => (
                        <Page pageIndex={i}/>
                    ))}
                    {rendered && (
                        <FxVisibilityIndicator onVisibilityChange={handleVisibilityChange}/>
                    )}
                </>
            );
        }, [numPages, rendered, handleVisibilityChange]);

        return (
            <Grid container id="fx-pdf-reader-grid" item xs={12} justifyContent="center" paddingBottom={'1%'} alignItems="center" className={className || "fx-terms-conditions"}>
                {/* document viewer */}
                <Document
                    file={fileBase64}
                    loading={''}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {renderPages}
                </Document>
                <CircularProgress
                    className={loading ? "" : "fx-hidden"}
                    size={20}
                    style={{
                        color: 'orange',
                    }}
                />
            </Grid >
        )
    });