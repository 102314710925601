import React from 'react'; // we need this to make JSX compile
import { LayoutDefault } from '../Layout/Default';
import { Route, useHistory } from 'react-router-dom';
import { CustomerProfile } from './Details/CustomerProfile';
import { CompanyProfile } from './Details/CompanyProfile';

import { TransactionHistory } from './Index/Passport/TransactionHistory';
import { ExternalAccountList } from './ExternalAccounts/ExternalAccountList';
import { isUserLoggedIn, setKey, getKey } from '../../libs/utils/storageManager';
import { EditProfile } from './Profile/EditProfile';
import { MailingAddress } from './Profile/MailingAddress';
import { CreateMailingAddress } from './Profile/CreateMailingAddress';
import { CustomerPasswordChange } from './Profile/CustomerPasswordChange';
import { LayoutPassport } from '../Layout/Passport';
import { CustomerHomePassport } from './Index/Passport/Home';
import { ExternalAccountDetails } from './ExternalAccounts/ExternalAccountDetails'
import { CreateExternalAccount } from './ExternalAccounts/CreateExternalAccount';
import { CreateTransferPage } from './Schedules/CreateTransferPage';
import { ThankYouPage } from './Index/Passport/ThankYouPage';
import { CreateSendAccountACH } from './Schedules/CreateSendAccountACH';
import { CreateSendAccountWIRE } from './Schedules/CreateSendAccountWIRE';
import { CreateSendAccountCHECK } from './Schedules/CreateSendAccountCHECK';
import { CreateMobileCheckDeposit } from './Schedules/CreateMobileCheckDeposit';
import { EditExternalAccount } from './ExternalAccounts/EditExternalAccount';
import { HelpCenter } from './Index/Passport/HelpCenter';
import { CreateOnetimeCollectAch } from './Schedules/CreateOnetimeCollectAch';
import { CreateOnetimeCollectCard } from './Schedules/CreateOnetimeCollectCard';
import { CreateCollectFromSelfCard } from './Schedules/CreateCollectFromSelfCard';
import { MyMailingAddress } from './Index/Passport/MyMailingAddress';
import {CreateSendOneTimeACH} from './Schedules/CreateSendOneTimeACH';
import {CreateSendOneTimeCheck} from './Schedules/CreateSendOneTimeCheck';
import { CreateSendOneTimeWire} from './Schedules/CreateSendOneTimeWire';
// import { ContactDetails } from './Contacts/ContactDetails';
import { CreateSendContactACH } from './Schedules/CreateSendContactACH';
import { CreateSendContactWIRE } from './Schedules/CreateSendContactWIRE';
import { CreateSendContactCHECK } from './Schedules/CreateSendContactCHECK';
import { CreateSendContactBOOK } from './Schedules/CreateSendContactBOOK';
import { ManageContactList } from './Contacts/ManageContactList';
// import { CreateContact } from './Contacts/CreateContact';
import { AddMerchant } from './Merchants/AddMerchant';
// import { MerchantDetails } from './Details/MerchantDetails';
import { MerchantViewDetails } from './Merchants/MerchantViewDetails';
// import { EditMerchant } from './Merchants/EditMerchant';
import { RequestDebitCard } from './DebitCard/RequestDebitCard';
import { DebitCardDetails } from './DebitCard/DebitCardDetails';
import { EditMerchantHome } from './Merchants/EditMerchantHome';
import { CreateExternalAccountCard } from './Contacts/CreateExternalAccountCard';
// import {CreateAddressCard} from './Contacts/CreateAddress';
import {ExternalAccountDetailsCard} from './Contacts/ExternalAccountInfoCard';
import { EditContactHome} from './Contacts/EditContactHome'
import { EditEAHome } from './Contacts/EditEAHome';
import { EditAddressHome } from './Contacts/EditAddressHome';
import { RecurringDepositList } from './Recurring/Listing/RecurringDepositList';
import { RecurringDetails } from './Recurring/Details/RecurringDetails';
import { EditRecurringHome } from './Recurring/Details/EditRecurringHome';
import { AuthorisedUserList } from './AuthorisedUsers/AuthorisedUserList';
import { EditAuthorisedUser } from './AuthorisedUsers/EditAuthorisedUser';
import { CreateAuthorisedUser } from './AuthorisedUsers/CreateAuthorisedUser';
import { CreateCollectFromSelfAch } from './Schedules/CreateCollectFromSelfAch';
import { AuthorisedUser } from './AuthorisedUsers/AuthorisedUser';
import { DebitCardRequest } from './DebitCard/DebitCard';
import { ContactDetailsHome } from './Contacts/ContactDetailsHome';
import { ContactAddHome } from './Contacts/ContactAddHome';
import { CreateAddressHome } from './Contacts/CreateAddressHome';
import { TransactionDetails } from './ScheduleDetails/Transactions/TransactionDetails';
import { LedgerBatchDetails } from './LedgerBatchDetail/FxLedgerBatchDetail';

import { Roles } from './Index/Passport/Roles';
import { TransactionList } from './Schedules/TransactionList';
import { TransactionHistoryList } from './Schedules/TransactionHistory';

import { CustomerDebitCardList } from './DebitCard/CustomerDebitCardList';
import { CreateRole } from './Role/CreateRole';
import { CreateSendOneTimeBook } from './Schedules/CreateSendOneTimeBook';
// import { EditSendContactWire } from './Schedules/EditSendContactWire';
import { ManagePPI } from './PPI/ManagePPI';
import { CreateSendOneTimeVirtualCard } from './Schedules/CreateSendOneTimeVirtualCard';
import { CreateSendContactVirtualCard } from './Schedules/CreateSendContactVirtualCard';
import { CreateSendAccountInternationalWIRE } from './Schedules/CreateSendAccountInternationalWIRE';
import { CreateSendOneTimeInternationalWire } from './Schedules/CreateSendOneTimeInternationalWire';
import { CreateSendContactInternationalWIRE } from './Schedules/CreateSendContactInternationalWIRE';
import { CreateInternationalExternalAccountCard } from './Contacts/CreateInternationalExternalAccountCard';
import { InternationalExternalAccountDetailsCard } from './Contacts/InternationalExternalAccountInfoCard';
import { KeycloakPostLogin } from './Auth/KeycloakPostLogin';
import { EditInternationalEAHome } from './Contacts/EditInternationalEAHome';
import { InternationalExternalAccountList } from './InternationalExternalAccount/InternationalExternalAccountList';
import {CreateInternationalExternalAccount} from './InternationalExternalAccount/CreateInternationalExternalAccount';
import { InternationalExternalAccountDetails } from './InternationalExternalAccount/InternationalExternalAccountDetails';
import { EditInternationalExternalAccount } from './InternationalExternalAccount/EditInternationalExternalAccount';
import { FxRedirectTo } from './Index/Passport/FxRedirectTo';
import { AccountDetails } from './Accounts/AccountDetails';
import { BatchedViewList } from './Index/Passport/BatchViewList';
import { FxTreasureDashboard } from '../DrawerPages/Treasure/FxTreasureDashboard';
import { InvestmentInstructionList } from '../DrawerPages/Treasure/InvestmentInstructionList';
import { ApprovalsQueue } from './PolicyQueue/ApprovalsQueue';
import { PendingApprovalsQueue } from './PolicyQueue/PendingApprovalQueue';
import { RejectApprovalQueue } from './PolicyQueue/RejectApprovalQueue';
import { ApprovedApprovalQueue } from './PolicyQueue/ApprovedApprovalQueue';
import { FundingRuleQueue } from './FundingRules/FundingRuleQueue';
import { InactiveFundingRuleQueue } from './FundingRules/InactiveFundingRuleQueue';
import { EditApprovalPolicy } from './Approval Policy/EditApprovalPolicy';
import { ApprovalPolicyInfo } from './Approval Policy/ApprovalPolicyInfo';
import { CreateApprovalPolicy } from './Approval Policy/CreateApprovalPolicy';
import { ApprovalPolicyList } from './Approval Policy/ApprovalPolicyList';
import { PartnerRoutes } from './Partner/PartnerRoutes';
import { CustomerApiTokenList } from './CustomerApi/CustomerApiTokenList';
import { AlertList } from './Alerts/AlertList';
import { AlertPreference } from './ManageAlerts/AlertPreference';
import { CreateFundingRule } from './FundingRules/CreateFundingRule';
import { SplitFundingInfo } from './FundingRules/SplitFundingInfo';
import { CloneFundingRuleAction } from './FundingRules/CloneFundingRule/CloneFundingRuleAction';
import { AdminPreferences } from './AdminPreferences/AdminPreferences';
import { FasterFundsDetail } from './Ledgers/FasterFundsDetail';
import { CreateContactCard } from './Contacts/CreateContactCard';
import { ContactCardInfo } from './Contacts/ContactCardInfo';
import { CreateCollectContactACH } from './Schedules/CreateCollectContactACH';
import { CreateCollectContactCARD } from './Schedules/CreateCollectContactCard';
import {FundingLinkedTransactionDetail } from './FundingRules/FundingLinkedTransactionDetail';
import { EditFundingRuleAction } from './FundingRules/EditFundingRule/EditFundingRuleAction';
import { DashboardAccountHome } from './Index/Passport/DashboardAccountHome';
import { FxDepositPlusAccountDashboard } from '../DrawerPages/DepositPlus/FxDepositPlusAccountDashboard';
import { SwitchCustomerRedirectPage } from './Index/Passport/SwitchCustomerRedirectPage';
import { AddLocationDetails } from './Location/AddLocationDetails';
import { EditLocationDetails } from './Location/EditLocationDetails';
import { ViewLocation } from './Location/ViewLocation';
import { CoOwnerList } from './CoOwner/CoOwnerList';
import { AddCoOwner } from './CoOwner/AddCoOwner';
import { CoOwnerDetails } from './CoOwner/CoOwnerDetails';
import { WebhookList } from './Webhooks/WebhookList';
import { CreateWebhook } from './Webhooks/Create/CreateWebhook';
import { WebhookInfo } from './Webhooks/View/WebhookInfo';
import { WebhookEventDetailList } from './Webhooks/WebhookEventDetailList';
import { EditWebhook } from './Webhooks/Edit/EditWebhook';

export const PrivateRoute: React.FC =
  () => {
    const history = useHistory();

    if (!isUserLoggedIn()) {
      history.push("/login");
      return <></>;
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let layout = params.get('layout');


    const routes = <>
      <Route path="/profile">
        <CustomerProfile key="customerprofile" />
      </Route>
      {/* <Route path="/editprofile">
                  <EditProfile key="editprofile" />
                </Route> */}
      <Route path="/externalaccounts">
        <ExternalAccountList key="externalaccount" />
      </Route>
      <Route path="/externalaccount/view/:id">
        <ExternalAccountDetails key="externalaccountdetails" />
      </Route>
      <Route path="/accountledgers">
        <TransactionHistory key="transactionhistory" id={'account-ledger-list'}/>
      </Route>
      <Route path="/batchedviewledgers">
        <BatchedViewList key="batchedviewledgers" />
      </Route>
      <Route exact path="/profile/edit/:type">
        <EditProfile key="editprofile" />
      </Route>
      <Route exact path="/mailingaddress/view/:id">
        <MailingAddress key="mailingaddress" />
      </Route>
      <Route exact path="/mailingaddress/add">
        <CreateMailingAddress key="addmailingaddress" />
      </Route>
      <Route exact path="/changepassword">
        <CustomerPasswordChange key="changepassword" />
      </Route>
      <Route exact path="/portfolio">
        <FxTreasureDashboard id="treasure-dashboard" key="treasure-dashboard" />
      </Route>
      <Route exact path="/cashbuilder">
        <FxDepositPlusAccountDashboard id="cash-builder-dashboard" key="cash-builder-dashboard" />
      </Route>
      </>

    /*passport routes using a different design.
    We will be re-using max component excpet home pages
    and pages with larger changes */
    const routesPassport = <>
      {/*  Home    */}
      { getKey("isUserDetailsAvailable") === 'TRUE'?<Route exact path="/dashboard">
      <CustomerHomePassport id={'customer-home-passport'} key="customerhome" />
      </Route>:
      <Route exact path="/dashboard">
      <KeycloakPostLogin id={'keycloak-post-login'} key='userInfo'/>
      </Route>}
     { getKey("isUserDetailsAvailable") === 'TRUE'?<Route exact path="/">
      <CustomerHomePassport id={'customer-home-passport'} key="customerhome" />
      </Route>:
      <Route exact path="/">
      <KeycloakPostLogin id={'keycloak-post-login'} key='userInfo'/>
      </Route>}
      <Route exact path="/proxylogin/:token*">
      <KeycloakPostLogin id={'keycloak-post-login'} key='userInfo'/>
      </Route>
      <Route exact path="/sso/:token*">
      <KeycloakPostLogin id={'keycloak-post-login'} key='userInfo'/>
      </Route>
      <Route exact path="/sso">
      <KeycloakPostLogin id={'keycloak-post-login'} key='userInfo'/>
      </Route>
      <Route exact path="/postauth">
      <KeycloakPostLogin id={'keycloak-post-login'} key='postauth'/>
      </Route>
      <Route path="/account/view/:id">
        <AccountDetails key="accountdetails" />
      </Route>
      {/* Thank You */}
      <Route path="/thanks/:page/:type/:id">
        <ThankYouPage key="thankspage" />
      </Route>
      <Route exact path="/thanks/:page/:type">
        <ThankYouPage key="thankspage" />
      </Route>
      {/*  Profile    */}
      <Route exact path="/profile">
        <CustomerProfile key="customerprofile" />
      </Route>
      <Route exact path="/profile/edit/:type">
        <EditProfile key="editprofile" />
      </Route>
      <Route exact path="/changepassword">
        <CustomerPasswordChange key="changepassword" />
      </Route>
      {/*  Company    */}
      <Route path="/company">
        <CompanyProfile key="company" />
      </Route>
      <Route path="/accountledgers">
        <TransactionHistory key="transactionhistory" id={'account-ledger-list'}/>
      </Route>
      <Route path="/batchedviewledgers">
        <BatchedViewList key="batchedviewledgers" />
      </Route>
      {/* External Accounts    */}
      <Route path="/externalaccounts">
        <ExternalAccountList key="externalaccount" />
      </Route>
      <Route path="/externalaccount/view/:id">
        <ExternalAccountDetails key="externalaccountdetails" />
      </Route>
      <Route exact path="/externalaccount/add/:type">
        <CreateExternalAccount key="createexternalaccountType" />
      </Route>
      <Route exact path="/externalaccount/add">
        <CreateExternalAccount key="createexternalaccount" />
      </Route>
      <Route exact path="/externalaccount/edit/:id">
        <EditExternalAccount key="editexternalaccount" />
      </Route>
      {/* Mailing Address    */}
      <Route exact path="/mailingaddress/view/:id">
        <MailingAddress key="mailingaddress" />
      </Route>
      <Route exact path="/mailingaddresses">
        <MyMailingAddress key="mailingaddresslist" />
      </Route>
      <Route exact path="/mailingaddress/add/:type">
        <CreateMailingAddress key="addmailingaddressType" />
      </Route>
      <Route exact path="/mailingaddress/add">
        <CreateMailingAddress key="addmailingaddress" />
      </Route>
      <Route path="/internationalexternalaccounts">
        <InternationalExternalAccountList key="internationalexternalaccount" />
      </Route>
      <Route exact path="/internationalexternalaccount/add">
        <CreateInternationalExternalAccount key="createinternationalexternalaccount" />
      </Route>
      <Route path="/internationalexternalaccount/view/:id">
        <InternationalExternalAccountDetails key="internationalexternalaccountdetails" />
      </Route>
      <Route exact path="/internationalexternalaccount/edit/:id">
        <EditInternationalExternalAccount key="editinternationalexternalaccount" />
      </Route>

      {/* Schedules */}
      <Route exact path="/transaction/collect/edit/my-account-ach/:id">
        <CreateCollectFromSelfAch id={"edit-collect-from-self-ach"} key="editcollectfromselfachwithid" />
      </Route>
      <Route exact path="/transaction/collect/add/from-self-card/:cardid">
        <CreateCollectFromSelfCard key="createcollectfromselfcard" />
      </Route>
      <Route exact path="/transaction/collect/add/from-self-card">
        <CreateCollectFromSelfCard key="createcollectfromselfcard" />
      </Route>
      <Route exact path="/transaction/collect/add/mobile-check-deposit">
        <CreateMobileCheckDeposit id={"create-collect-from-onetime-check"} key="createmobilecheckdeposit" />
      </Route>
      <Route exact path="/transaction/collect/add/my-account-ach">
          <CreateCollectFromSelfAch id={"create-collect-from-self-ach"} key="createcollectmyaccountach" />
      </Route>
      <Route exact path="/transaction/collect/add/onetime-ach">
        <CreateOnetimeCollectAch id={"create-collect-from-onetime-ach"} key="createonetimecollectach" />
      </Route>

      <Route exact path="/transaction/collect/contact/add/ach">
        <CreateCollectContactACH id={"create-collect-contact-ach"} key="createcollectcontactach" />
      </Route>
      <Route exact path="/transaction/collect/contact/edit/ach/:id">
        <CreateCollectContactACH id={"edit-collect-via-contact-ach"} key="createcollectcontacteditach" />
      </Route>

      <Route exact path="/transaction/collect/contact/add/card">
        <CreateCollectContactCARD id={"create-collect-contact-card"} key="createcollectcontactcard" />
      </Route>
      <Route exact path="/transaction/collect/contact/edit/card/:id">
        <CreateCollectContactCARD id={"edit-collect-via-contact-card"} key="createcollectcontacteditcard" />
      </Route>

      <Route exact path="/contact-recurring/:type/edit/:id">
        <EditRecurringHome key="collectviacontactrecurring" />
      </Route>

      <Route exact path="/transaction/collect/edit/onetime-ach/:id">
        <CreateOnetimeCollectAch id={"edit-collect-onetime-ach"} key="editonetimecollectach" />
      </Route>
      <Route exact path="/transaction/collect/add/onetime-card">
      <CreateOnetimeCollectCard id={"create-onetime-card-collect"} key="createonetimecollectcardhome" />
      </Route>
      <Route exact path="/transaction/collect/edit/onetime-card/:id">
        <CreateOnetimeCollectCard id={"edit-collect-from-onetime-card"}  key="editonetimecollectcardhomeWithId" />
      </Route>
      <Route exact path="/transaction/send/transfer">
        <CreateTransferPage id='create-transfer' key="createtransfer" />
      </Route>
      <Route exact path="/transaction/send/edit/account/book/:id">
        <CreateTransferPage key="createtransfer" id={'edit-send-account-book'} />
      </Route>
      <Route exact path="/transaction/send/add/ach/:externalaccountid">
        <CreateSendAccountACH  id={"create-send-from-self-ach"} key="createSendAccountACHWithId" />
      </Route>
      <Route exact path="/transaction/send/add/ach">
        <CreateSendAccountACH id={"edit-send-from-self-ach"} key="createSendAccountACH" />
      </Route>
      <Route exact path="/transaction/send/add/internationalWire">
        <CreateSendAccountInternationalWIRE id='create-send-account-international-wire' key="createSendAccountInternationalWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/account/internationalWire/:id">
        <CreateSendAccountInternationalWIRE id={'edit-send-to-account-internationalWire'} key="editSendAccountInternationalWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/account/ach/:id">
        <CreateSendAccountACH id={'edit-send-to-account-ach'} key="editSendAccountACH" />
      </Route>
      <Route exact path="/transaction/send/add/wire/:externalaccountid">
        <CreateSendAccountWIRE id='create-send-account-wire' key="createSendAccountWIREWithId" />
      </Route>
      <Route exact path="/transaction/send/add/wire">
        <CreateSendAccountWIRE id='create-send-account-wire' key="createSendAccountWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/account/wire/:id">
        <CreateSendAccountWIRE id={'edit-send-to-account-wire'} key="editSendAccountWire" />
      </Route>
      <Route exact path="/transaction/send/add/check/:mailingaddressid">
        <CreateSendAccountCHECK id={"edit-collect-from-self-check"} key="CreateSendAccountCHECKWITHID" />
      </Route>
      <Route exact path="/transaction/send/add/check">
        <CreateSendAccountCHECK id={"create-collect-from-self-check"} key="CreateSendAccountCHECK" />
      </Route>
      <Route exact path="/transaction/send/edit/account/check/:id">
        <CreateSendAccountCHECK id={'edit-send-to-account-check'} key="editSendAccountCHECK" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/ach">
        <CreateSendOneTimeACH id={'create-send-to-one-time-ACH'} key="createSendOoneTimeACH" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/ach/:id">
        <CreateSendOneTimeACH id={'edit-send-to-one-time-ACH'} key="EditSendOneTimeACH" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/wire">
        <CreateSendOneTimeWire id={'create-send-to-one-time-WIRE'} key="CreateSendOneTimeWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/wire/:id">
        <CreateSendOneTimeWire id={'edit-send-to-one-time-WIRE'} key="EditSendOneTimeWIRE" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/check">
        <CreateSendOneTimeCheck id={'create-send-to-one-time-CHECK'} key="CreateSendOneTimeCheck" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/check/:id">
        <CreateSendOneTimeCheck id={'edit-send-to-one-time-CHECK'} key="EditSendOneTimeCheck" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/book">
        <CreateSendOneTimeBook id={'create-send-to-one-time-BOOK'} key="CreateSendOneTimeBook" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/book/:id">
        <CreateSendOneTimeBook id={'edit-send-to-one-time-BOOK'} key="EditSendOneTimeBook" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/internationalWire">
        <CreateSendOneTimeInternationalWire id={'create-send-to-one-time-internationalWire'} key="CreateSendOneTimeInternationalWire" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/internationalWire/:id">
        <CreateSendOneTimeInternationalWire id={'edit-send-to-one-time-internationalWire'} key="EditSendOneTimeInternationalWire" />
      </Route>
      <Route exact path="/transaction/send/onetime/add/virtualcard">
        <CreateSendOneTimeVirtualCard id={'create-send-to-one-time--virtual-card'} key="createSendOneTimeVirtualCard" />
      </Route>
      <Route exact path="/transaction/send/edit/onetime/virtualcard/:id">
        <CreateSendOneTimeVirtualCard id={'edit-send-to-one-time-virtual-card'} key="editSendOneTimeVirtualCard" />
      </Route>
      <Route exact path="/transaction/send/contact/add/ach">
        <CreateSendContactACH id={'create-send-to-contact-ach'} key="createSendContactACH" />
      </Route>
      <Route exact path="/transaction/send/contact/add/ach/:contactId">
        <CreateSendContactACH id={'create-send-to-contact-ach'} key="createSendContactACH" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/ach/:id">
        <CreateSendContactACH id={'edit-send-to-contact-ach'} key="editSendContactACH" />
      </Route>
      <Route exact path="/transaction/send/contact/add/wire">
        <CreateSendContactWIRE id={'create-send-to-contact-wire'} key="createSendContactWIRE" />
      </Route>
      <Route exact path="/transaction/send/contact/add/wire/:contactId">
        <CreateSendContactWIRE id={'create-send-to-contact-wire'} key="createSendContactWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/wire/:id">
        <CreateSendContactWIRE id={'edit-send-contact-wire'} key="editSendContactWire" />
      </Route>
      <Route exact path="/transaction/send/contact/add/check">
        <CreateSendContactCHECK id={'create-send-to-contact-check'} key="createSendContactCHECK" />
      </Route>
      <Route exact path="/transaction/send/contact/add/check/:contactId">
        <CreateSendContactCHECK id={'create-send-to-contact-check'} key="createSendContactCHECK" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/check/:id">
        <CreateSendContactCHECK id={'edit-send-contact-check'} key="editSendContactCheck" />
      </Route>
      <Route exact path="/transaction/send/contact/add/book">
        <CreateSendContactBOOK id={'create-send-to-contact-book'} key="createSendContactBook" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/book/:id">
        <CreateSendContactBOOK id={'edit-send-to-contact-book'} key="editSendContactBook" />
      </Route>
      <Route exact path="/transaction/send/contact/add/internationalWire">
        <CreateSendContactInternationalWIRE id={'create-send-to-contact-internationalWire'} key="createSendContactInternationalWIRE" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/internationalWire/:id">
        <CreateSendContactInternationalWIRE id={'edit-send-to-contact-internationalWire'} key="editSendContactInternationalWIRE" />
      </Route>
      <Route exact path="/transaction/send/contact/add/virtualcard">
        <CreateSendContactVirtualCard id={'create-send-to-contact-virtual-card'} key="createSendContactVirtualCard" />
      </Route>
      <Route exact path="/transaction/send/edit/contact/virtualcard/:id">
        <CreateSendContactVirtualCard id={'edit-send-to-contact-virtual-card'} key="editSendContactVirtualCard" />
      </Route>
      {/*    Merchant    */}
      <Route exact path="/merchant/add">
        <AddMerchant key="addmerchant" />
      </Route>
      <Route exact path="/merchant/view/:id">
        <MerchantViewDetails key="merchantviewdetails" />
      </Route>
      <Route exact path="/merchant/edit/:id">
        <EditMerchantHome key="editmerchanthome" />
      </Route>
      {/*    Debit card    */}
      <Route exact path="/debit-card">
        <RequestDebitCard key="requestDebitCard"/>
      </Route>
      <Route exact path="/debit-card/physical/:id">
        <DebitCardRequest key="debitCardIssue"/>
      </Route>
      <Route exact path="/debit-card/details/:id">
        <DebitCardDetails key="DebitCardDetails"/>
      </Route>
      <Route exact path="/debitcards">
        <CustomerDebitCardList key='DebitCardCustomerList' />
      </Route>
       <Route exact path="/payee/view/:id">
        <ContactDetailsHome key="payeeprofile" />
      </Route>

      <Route exact path="/payees">
        <ManageContactList key="managepayees" />
      </Route>
      <Route exact path="/recurring">
      <RecurringDepositList key="RecurringList"></RecurringDepositList>
      </Route>
      <Route exact path="/recurring/details/:id">
      <RecurringDetails key="RecurringDetails"></RecurringDetails>
      </Route>
      <Route exact path="/approvals">
        <ApprovalsQueue key="approvalsList" />
      </Route>
      <Route exact path="/pendingApprovals">
        <PendingApprovalsQueue key="pendingApprovalsList" />
      </Route>
      <Route exact path="/approvedApprovals">
        <ApprovedApprovalQueue key="approvedApprovalsList" />
      </Route>
      <Route exact path="/rejectApprovals">
        <RejectApprovalQueue key="rejectApprovalsList" />
      </Route>
      <Route exact path="/payee/add">
        <ContactAddHome  key="addpayee" />
      </Route>
      <Route exact path="/payee/add/:type">
        <ContactAddHome key="addpayeetype" />
      </Route>
      <Route exact path="/payee/externalaccount/add/:id">
        <CreateExternalAccountCard key="createea" />
      </Route>
      <Route exact path="/payee/internationalExternalAccount/add/:id">
        <CreateInternationalExternalAccountCard key="createeia" />
      </Route>
      <Route exact path="/payee/card/add/:id">
        <CreateContactCard key="createcard" />
      </Route>
      <Route exact path="/payee/address/add/:id">
        <CreateAddressHome key="createaddress" />
      </Route>
      <Route exact path="/payee/id/:contactId/externalaccount/view/:id">
        <ExternalAccountDetailsCard key="eadetails" />
      </Route>
      <Route exact path="/payee/id/:contactId/internationalExternalAccount/view/:id">
        <InternationalExternalAccountDetailsCard key="eiadetails" />
      </Route>
      <Route exact path="/payee/card/view/:id">
        <ContactCardInfo key="cardinfo" />
      </Route>
      <Route exact path="/payee/edit/:id">
        <EditContactHome key="editpayee" />
      </Route>
      <Route exact path="/payee/id/:contactId/externalaccount/edit/:id">
        <EditEAHome key="edit-contact-ea" />
      </Route>
      <Route exact path="/payee/id/:contactId/internationalExternalAccount/edit/:id">
        <EditInternationalEAHome key="edit-contact-eia" />
      </Route>
      <Route exact path="/payee/id/:contactId/address/edit/:id">
        <EditAddressHome key="edit-payee-address" />
      </Route>
      <Route exact path="/help-center">
        <HelpCenter key="helpcenter" />
      </Route>
      <Route exact path="/recurring/:type/edit/:id">
        <EditRecurringHome key="recurringeditsendhome" />
      </Route>
      <Route exact path="/authorizedusers">
        <AuthorisedUserList key="authorizedusers" />
      </Route>
      <Route exact path="/authorizedusers/edit/:id">
        <EditAuthorisedUser key="useredit" />
      </Route>
      <Route exact path="/authorizedusers/add">
        <CreateAuthorisedUser key="CreateUsers" />
      </Route>
      <Route exact path="/authorizedusers/view/:id">
        <AuthorisedUser key="authoriseduserdetails" />
      </Route>
      <Route exact path="/roles">
        <Roles key="transactionhistory" />
      </Route>
      <Route exact path="/transactions/:type/:id">
        <TransactionDetails key="transactiondetailsschedule" />
      </Route>
      <Route exact path="/ledgerBatchDetails/:id/:cardBatchId">
        <LedgerBatchDetails key="ledgerBatchDetails" />
      </Route>
      <Route exact path="/transactions">
        <TransactionList key="TransactionList" />
      </Route>
      <Route path="/transactionsHistory">
        <TransactionHistoryList key="transactionsHistory" />
      </Route>
      <Route path="/customerapi/list">
        <CustomerApiTokenList key="customer-api-token-list" id="customer-api-token-list" />
      </Route>
      
      <Route exact path="/role">
        <CreateRole id={'create-role'} key="addRole" />
      </Route>
      <Route exact path="/role/:id">
        <CreateRole id={'create-role'} key="roleview" />
        </Route>
      {/*    PPI   */}
      <Route exact path="/manage-ppi">
        <ManagePPI id={'manage-customer-ppi'} key="manageppi" />
      </Route>
      <Route exact path="/redirect/:redirectTo">
        <FxRedirectTo id={'redirect-To'} key="redirectTo" />
      </Route>
      <Route exact path="/portfolio">
        <FxTreasureDashboard id="treasure-dashboard" key="treasure-dashboard" />
      </Route>
      <Route exact path="/cashbuilder">
        <FxDepositPlusAccountDashboard id="cash-builder-dashboard" key="cash-builder-dashboard" />
      </Route>
      <Route exact path="/investmentInstructions">
        <InvestmentInstructionList key="InvestmentInstructionList" />
      </Route>
      <Route exact path="/approvalpolicy">
        <ApprovalPolicyList id="approval-policy" key="approvalpolicy" />
      </Route>
      <Route exact path="/approvalpolicy/add">
        <CreateApprovalPolicy id={'create-approval-policy'} key="createApprovalPolicy" />
      </Route>
      <Route exact path="/approvalpolicy/edit/:id">
        <EditApprovalPolicy key="editApprovalPolicy" />
      </Route>
      <Route exact path="/approvalpolicy/view/:id">
        <ApprovalPolicyInfo key="viewApprovalPolicy" />
      </Route>
      <Route exact path="/alerts">
        <AlertList id="alerts-list" key="alertsList" />
      </Route>
      <Route exact path="/alertpreferences">
        <AlertPreference id="alert-preference" key="alertPreference" />
      </Route>      
      {/*    Funding Rules   */}
      <Route exact path="/fundingrules/active">
        <FundingRuleQueue key="fundingrulesList" />
      </Route>
      <Route exact path="/fundingrules/inactive">
        <InactiveFundingRuleQueue key="fundingRules" />
      </Route>
      <Route exact path="/fundingrule/create">
        <CreateFundingRule key="createFundingRules" id='create-funding-rules'/>
      </Route>
      <Route exact path="/fundingrule/clone">
        <CloneFundingRuleAction key="cloneFundingRules" />
      </Route>
      <Route exact path="/fundingrule/edit/:id">
        <EditFundingRuleAction key="editFundingRules" />
      </Route>
      <Route exact path="/fundingrule/view/:id">
        <SplitFundingInfo key="viewFundingRules" />
      </Route>
      <Route exact path="/fundingrule/viewlinkedtransaction/:name/:id">
        <FundingLinkedTransactionDetail key="viewLinkedTransactiondetail" />
      </Route>
      <Route exact path="/admin-preferences">
        <AdminPreferences id='admin-preferences' key="admin-preferences" />
      </Route>
      <Route path="/fasterfunds/view">
        <FasterFundsDetail key="fasterfundsdetail" />
      </Route>
      <Route path="/home">
        <DashboardAccountHome key="account-dashboard" id="accounts-dashboard"/>
      </Route>
      <Route path="/switch-customer">
        <SwitchCustomerRedirectPage key="/switch-customer" id="/switch-customer"/>
      </Route>
      <Route path="/location/add">
        <AddLocationDetails key="add-location" id="add-location" />
      </Route>
      <Route path="/location/edit/:id">
        <EditLocationDetails key="edit-location" />
      </Route>
      <Route path="/location/view/:id">
        <ViewLocation key="view-location"/>
      </Route>

      <Route exact path="/co-owner/profile">
        <CoOwnerList id="co-owner-profile" key="co-owner-profile" anchorEl={null} />
      </Route>
      <Route exact path="/co-owner/add">
        <AddCoOwner id="add-co-owner" key="add-co-owner" />
      </Route>
      <Route exact path="/co-owner/details/:id">
        <CoOwnerDetails id="details-co-owner" key="details-co-owner" />
      </Route>     

      <PartnerRoutes/>

      {/*    Webhooks   */}
      <Route exact path='/webhooks'>
        <WebhookList id='webhook-list' key='webhook-list'/>
      </Route>
      <Route exact path="/webhook/add">
        <CreateWebhook id='create-webhook' key="create-webhook" />
      </Route>
      <Route exact path="/webhook/edit/:id">
        <EditWebhook key="edit-webhook" />
      </Route>
      <Route exact path="/webhook/view/:id">
        <WebhookInfo key="webhook-info" id="webhook-info"/>
      </Route>
      <Route exact path="/webhook/eventDetail/list">
        <WebhookEventDetailList key="webhook-event-detail-list" id="webhook-event-detail-list"/>
      </Route>

      </>
    //load theme if specified
    if (layout) {
      setKey("layout", layout)
    }
    else {
      layout = getKey("layout");
    }

    return (
      layout === "default" ? <LayoutDefault routes={routes} /> : <LayoutPassport id="layout-passport" routes={routesPassport} />

    )
  }
