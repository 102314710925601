import React, { useMemo } from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { checkPermission } from '../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import { ViewLocationDetails } from './ViewLocationDetails';
/**
 * Component: ViewLocation
 * Usage: To display location details
 */
export const ViewLocation = () => {
    
    interface RouteParams {
        id: string
    }
    
    const params = useParams<RouteParams>();
    
    //get ID from URL  
    const id = params.id;

    //Location Details Source
    const location_source = {
        url: "/location/id/" + id,
        method: "GET"
    }
    
    /**
    * to set permission
    */
    const permission = useMemo(() => {
        return checkPermission({
            entity: "Other Entities",
            name: "Location",
            operation: "VIEW"
        });
    }, []);

    return (
        <>
            {permission ? <ViewLocationDetails id={"location-details-config-card" + id} source={location_source} ></ViewLocationDetails>
                : <FxAccessDenied id={'location-details-access-denied'} title={'You are not authorized to access this page'} />
            }
        </> 
    )
}
