/**
 * Component: Alert Drawer Component
 * Usage: To display alerts list in drawer
 */

import React, { useEffect } from 'react'
import { Drawer, Grid, IconButton, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useHistory } from 'react-router-dom';
import { FxButton } from '../../Action/FxButton';
import { getAlerts, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxSwitch } from '../../Action/FxSwitch';
import { ReactComponent as AlertCloseIcon } from '../../../assets/svg/alerts-close-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/svg/right-arrow-icon.svg';
import { ReactComponent as SettingIcon } from "../../../assets/svg/settings-icon-small.svg";
import { AlertComponent } from './AlertComponent';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxNoGridDataImage from "../../Data/FxNoGridDataImage";

export const AlertDrawerComponent: React.FC<any> = React.memo((props) => {

    let context:any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const httpClient = HttpClient.getClient();
    const history = useHistory();

    const alertList = context?.data?.body?.resources || [];

    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,props.id])

  /**
   * Method to naviagte to view all alerts screen
   */
  const handleViewAllClick = () => {
    props.handleClose();
    updateComponentParams(dispatch, props?.id, { showUnread: true });
    history.push('/alerts');
  };

  /**
   * Method to navigate to manage alerts screen
   */
  const handleManageAlerts = () => {
    props.handleClose();
    updateComponentParams(dispatch, props?.id, { showUnread: true });
    history.push('/alertpreferences');
  };

  /**
 * funtion to update state for alerts count
 */
  async function getAlertsCount(){
    const data = await getAlerts();
    if(data){
        updateComponentParams(dispatch, "alerts-info", { 'count': data.data.totalCount});
    }
  }


  /**
   * Method to call api  for mark all as read 
   * */
  async function markAsRead(paylaoddata: any) {
    try {
        const data: any = await httpClient.post(getCustomerUrl(`/authorizedUser/id/${getKey('authUserId')}/alert/markAsRead`,false), paylaoddata)
        return data;
    } catch (err) {
        Logger.error("AlertDrawerComponent.tsx", "error", err);
        return err;
    }
  }

  /**
   * Method to handle on click of Mark All As Read Button
   */
  const handleMarkAllAsRead = async () => {

    let status: any;
    const req = {
      readAll: true
    }

    status = await markAsRead(req);
    status = processAPIResponse(status);
    
    if (status.status) {
        dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: props.id, source: props.showUnread ? props.source : src}}); 
        getAlertsCount();
    }
    else {
        //api  failed
        FxSnackBar.show({
            text: status.message,
        });
    }
  }

    /**
     * Method to handle onclick of Mark As Read Button
     */
    const handleMarkAsRead = async (id:any) => {

        let status: any;
        const req = {
            readAll: false,
            alert: [
                {
                    "id": id
                }
            ]
        }

        status = await markAsRead(req);
        status = processAPIResponse(status);
        
        if (status.status) {
            dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: props.id, source: props.showUnread ? props.source : src}});     
            getAlertsCount();
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

  const src = {
    url: "/authorizedUser/id/"+ getKey('authUserId') + "/alert/list",
    method: "POST",
    // expiry: 3600,
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "sortOptions": {
        "sortBy": "createdOn",
        "sortOrder": "DESC"
      },
      "criteria": {
        "filters" : [
        ] 
      }
    }
  }

  /**
   * Method to handle on change of Switch
   */
  const handleToggleSwitch = () => {
    updateComponentParams(dispatch, props?.id, { showUnread: !props.showUnread });
    dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: props.id, source: props.showUnread ? src : props.source}});
  }

  const handleClose = () =>{
    props?.handleClose();
    updateComponentParams(dispatch, props?.id, { showUnread: true });
  }

    /**
    * to get alerts count
    */
      const alertsCount = useSelector((state: any) => {
        if (state.data['alerts-info']) {
            return state.data['alerts-info']?.params?.count
        } 
    });

  return (
    <Drawer id="alert-overlay-drawer" anchor={'right'} open={props?.alertsOpen} onClose={handleClose}>
      <Grid container xs direction='column' className='fx-alerts-drawer'>
        <Grid container item xs='auto' direction='column' className='fx-alerts-drawer-header'> 
            <Grid container item direction='row' justifyContent='space-between'>
              <Grid item container xs='auto' direction='row' spacing={2} alignItems='center'>
                <Grid item xs>
                  <IconButton
                    component="a"
                    className="fx-profile-icon fx-logout"
                    id="menu-top-alerts-button"
                    onClick={handleClose}
                    title={'Alerts'}
                    aria-label={'Alerts'}
                    size="large"> <AlertCloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs className='fx-alerts-drawer-header-text1'>
                  <Typography>Alerts</Typography>
                </Grid>
              </Grid>
              <Grid item container xs='auto' direction='row' alignItems='center' spacing={1}>
                <Grid item xs className='fx-alerts-drawer-header-text2'>
                  <Typography>
                    Show unread
                  </Typography>
                </Grid>
                <Grid item xs>
                  <FxSwitch 
                    defaultChecked={true} 
                    value={props?.showUnread}
                    onClick={handleToggleSwitch}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs alignSelf='flex-end'>
              <FxButton
                id='btn-mark-all-as-read'
                className={alertsCount > 0 ? 'fx-btn-mark-read' : 'fx-btn-mark-read-disabled'}
                onClick={handleMarkAllAsRead}
                disabled={alertsCount > 0 ? false : true}
              >
                MARK ALL AS READ
              </FxButton>
            </Grid>
        </Grid>
        <Grid container item xs direction='column' className='fx-alerts-drawer-body'> 
          {alertList.length > 0 ? alertList.map((item:any,index:any)=>
            <AlertComponent data={item} id={'alert-card'+index} markAsRead={handleMarkAsRead} />
           ) : 
          <Grid container item direction='column' alignItems='center' justifyContent='center' className='fx-alerts-drawer-body-no-data'>
            <Grid item xs>  
              <FxNoGridDataImage /> 
            </Grid>
            <Grid item xs>
              <Typography>You have read all your messages received till date.</Typography> 
            </Grid>
          </Grid>}
        </Grid>
        <Grid container item xs='auto' direction='row' className='fx-alerts-drawer-footer-actions' justifyContent='space-between'>
            <Grid item xs='auto'>
              <FxButton
                id='btn-manage-all-alerts'
                className='fx-btn-alert-preferences' 
                onClick={handleManageAlerts}
              >
                <SettingIcon />&nbsp; 
                Alert Preferences
              </FxButton>
            </Grid>
            <Grid item xs='auto'>
              <FxButton 
                id='btn-view-all-alerts'
                className='fx-btn-view-all-alerts' 
                onClick={handleViewAllClick}
              >
                View All&nbsp;&nbsp;
                <RightArrowIcon />
              </FxButton>
            </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
})
