import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { ReactComponent as DownArrow } from "../../../../assets/svg/down-black-triangle-arrow.svg";
import { ReactComponent as UpArrow } from "../../../../assets/svg/up-black-triangle-arrow.svg";
import { useFieldArray } from "react-hook-form";
import { FxFundingRuleCriteriaCard } from "./FxFundingRuleCriteriaCard";
import { FxLink } from "../../../Action/FxLink";
import FxRadioEdit from "../../../Input/FxRadio/FxRadioEdit";

/**
 * Component: Funding Rules Create Screen >> Criteria Section Wrapper component
 * Usage: To render the criteria section of the create screen
 */
export const FxSplitFundingCriteria: React.FC<any> = React.memo((props) => {
  const [expanded, setExpanded] = React.useState(
    props?.data?.length > 0 ? true : false
  );
  const [accordingIcon, setAccordingIcon] = React.useState(<DownArrow />);
  const [incomingTxn, setIncomingTxn] = useState(false);

  const applicableConditions = [
    { label: "Apply to all incoming transactions", value: "toAll"},
    { label: "Custom", value: "Custom" , disabled: !props?.onlyCard}
  ];

  /**
   * create a Form Field Array
   */
  const { fields, append, remove, update } = useFieldArray({
    control: props.control,
    name: "funding-rule-criteria",
    shouldUnregister: true,
  });

  /**
   * Method to add Field
   */
  function handleAppend() {
    append({});
  }

  //By default criteria card should open
  useEffect(()=>{
    handleCriteraSectionOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


    //To handle the accordion expansion when no data is present on clone
    useEffect(() => {
      if (props?.isEdit && props?.data?.length === 0) {
        handleCriteraSectionOpen();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  

  /**
   * useEffect to load the api data into the useFieldArray Object
   */
  useEffect(() => {
    if (props?.data?.length > 0) {
      props.setValue('applicableConditions', props?.data?.length && props?.onlyCard ? 'Custom' : 'toAll');
      setIncomingTxn(props?.data?.length > 0 && props?.onlyCard ? true : false);
      fields.length = props?.data?.length;

      for (let i = 0; i < props?.data?.length; i++) {
        update(i, props?.data[i]);
      }
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);

  /**
   * On change either the custom options will be shown or hidden and delete newly added custom options
   * @param ev selected option
   */
  const handleConditionChange = (ev: any) => {
    if (ev?.target?.value === 'toAll') {
      setIncomingTxn(false);
    } else {
      setExpanded(true);
      setIncomingTxn(true);
      if(props?.isEdit){
        remove();
        removeNewCriteria();
      } else {
        remove()
      }
      !props?.data?.length && handleAppend();
    }
  }

  //To handle the radio options based on the medthod selection
  useEffect(() => {
    props.setValue('applicableConditions', 'toAll');
    setIncomingTxn(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.onlyCard])


  /**
   * Method handles the opening of the Criteria Section Accordian
   */
  const handleCriteraSectionOpen = () => {
    if (expanded === true && fields.length >= 1) {
      setExpanded(false);
      setAccordingIcon(<DownArrow />);
    } else {
      if (fields.length === 0) {
        handleAppend();
      }
      setExpanded(true);
      setAccordingIcon(<UpArrow />);
    }
  };

  /**
   * Method to handle the accordian close event when 1 item is left
   * @param index
   * @param length
   */
  const handleAccordianClose = (index: any, length: any) => {
    remove(index);
    setExpanded(false);
    setAccordingIcon(<DownArrow />);
  };

  /**
   * Method to handle deletion of the criteria accordian rows
   * @param index
   * @param length
   */
  const removingCriteria = (index: any, length: any) => {
    remove(index);
  };

//Method to remove new added rows
  const removeNewCriteria = () => {
    for (let i = 0; i < props?.data?.length; i++) {
      update(i, props?.data[i]);
    }
  }

  return (
    <Grid container paddingTop={"2rem"}>
      <Grid item xs={12} sm={12}>
        <fieldset className="fx-container-create-schdule-summery fx-splitfunding">
          <Grid container xs={12}>
            <Accordion className="fx-criteria" expanded={expanded}>
              <AccordionSummary>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    justifyContent={"flex-start"}
                    className="fx-container-funding-rules-criteria-summery"
                  >
                    <Typography className="fx-summary-title" variant="h3">
                      {fields.length > 0 && expanded === false && incomingTxn
                        ? `Rule Condition (${fields.length})`
                        : `Rule Condition`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    <FxLink onClick={handleCriteraSectionOpen}>
                      <Typography
                        className="fx-add-button-theme-text"
                        variant="h3"
                      >
                        {fields.length === 0 && expanded === false
                          ? "+ Add"
                          : accordingIcon}
                      </Typography>
                    </FxLink>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} >
                  <FxRadioEdit register={{ ...props?.register("applicableConditions") }} control={props?.control} className={'radio-edit'} data={applicableConditions} id="rule-condition-form-card-radio" name="applicableConditions"
                    defaultValue={'toAll'} setValue={props?.setValue} onChange={handleConditionChange} />
                </Grid>
                {incomingTxn ? <Grid
                  item
                  xs={12}
                  sm={12}
                  width={"100%"}
                  marginTop={'2rem'}
                  className={
                    "fx-approval-policy-condition fx-create-approval-policy"
                  }
                  id={"add-criteria-funding-rule"}
                >
                  <fieldset className="fx-container-create-schdule-summery fx-condition-summary funding-rule-criteria-section fx-splitfunding-condition">
                    {fields.map((field: any, index: any) => {
                      return (
                        <FxFundingRuleCriteriaCard
                          id={`funding-rule-row-` + index}
                          control={props.control}
                          index={index}
                          value={field}
                          data={props?.data}
                          setValue={props.setValue}
                          register={props.register}
                          errors={props.errors}
                          handleAppend={handleAppend}
                          handleRemove={() => {
                            fields.length === 1
                              ? handleAccordianClose(index, fields.length)
                              : removingCriteria(index, fields.length);
                          }}
                          isAddButtonDisabled={
                            fields.length !== index + 1
                          }
                          fieldLength={fields.length}
                          getValues={props.getValues}
                          update={update}
                          watch={props.watch}
                        />
                      );
                    })}
                  </fieldset>
                </Grid> : props?.hideTxn ? <Grid
                  item
                  xs={12}
                  sm={12} 
                  width={"100%"}
                  marginTop={'2rem'}
                  className={
                    "fx-approval-policy-condition fx-create-approval-policy"
                  }
                  id={"add-criteria-funding-rule-incoming-txn"}>
                  <Typography>If the applicable transaction method is other than CARD, the rule will be applicable to all incoming transactions.</Typography>
                </Grid> : <></>}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </fieldset>
      </Grid>
    </Grid>
  );
});
