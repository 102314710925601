import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid, Typography, CircularProgress } from '@mui/material';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { maskAccountNumber, downloadFileCommonClass, checkPermission } from '../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-icon.svg';
import { getKey } from '../../../libs/utils/storageManager';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { ReactComponent as IncomingWire } from '../../../assets/svg/Incoming-wire.svg';


Logger.debug("DepositFundsCard.tsx", "Deposit funds Account InfoCard initializing");
/**
 * Component: DepositFundsCard
 * Usage: To display account deposit funds information
 */

export const DepositFundsCard: React.FC<any> = React.memo(
    (props) => {

        const dispatch = useDispatch()
        const uiFeat = UIFeatureImpl.getInstance();
        const jointTenancy = uiFeat.getJointTenancyFeature().available;

        let achSource: any;
        let wireSource: any;
        let ppiSource: any;
        const newData: any = {};
        const userName: any = getKey('firstName');
        const fileData: any = [];
        let account_deposit_funds_data: any;
        const [isLoading, setIsLoading] = useState(false);
        const isMobileOrTabScreen = useIsMobileOrTabletScreen()

        useEffect(()=>{
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Account Details', backButton: '/dashboard' } } });
        },[dispatch, account_deposit_funds_data?.accountNumber])

        /**
         * Method to transform ACH/Wire Instructions data
         * @param data : bank data
         */
        function transformInstructionsData(data: any) {
            try {
                newData.mailingInfo = 'Priority Technology Holdings, 2975 Regent Blvd, Suite 100, Lockbox Services 200805, Irving, TX 75063';
                newData.beneficiaryName = jointTenancy ? getKey('PrimaryOwnerFullName') : (getKey('CustomerName') ? getKey('CustomerName') : getKey('CustomerIndividualFullName'));
                if (getKey('firstName') && getKey('lastName')) {
                    newData.memo1 = getKey('firstName') + ' ' + getKey('lastName') + ' ';
                }
                if (data.resources && data.resources[0]) {
                    if (props.data?.routableAccount?.routingNumber) {
                        newData.bankName = data.resources[0].name;
                        newData.bankAddress = data.resources[0].address;
                        newData.beneficiaryAccountNumber = props.data?.routableAccount?.accountNumber;
                        newData.routingNumber = props.data?.routableAccount?.routingNumber;
                        newData.memo = props.data?.routableAccount?.memo
                    }
                    if (props.data?.routableAccount?.wireRoutingNumber) {
                        newData.wireBankName = data.resources[0].name;
                        newData.wireBankAddress = data.resources[0].address;
                        newData.wireAccountNumber = props.data?.routableAccount?.wireAccountNumber;
                        newData.wireRoutingNumber = props.data?.routableAccount?.wireRoutingNumber;
                        newData.wireMemo = props.data?.routableAccount?.wireMemo
                    }
                }
                if(getKey("isPPIEnabled")?.toLowerCase() === 'true'&& data.ppi){
                    newData.ppi = data.ppi;
                }
                return newData;
            }
            catch (e) {
                Logger.error("ExternalAccountList.tsx", "error", e);
                return false;
            }
        }
        /**
         * Transform data
         */
        if (props?.data) {
            try {
                account_deposit_funds_data = { ...props.data };
                account_deposit_funds_data.mailingInfo = 'Priority Technology Holdings, 2975 Regent Blvd, Suite 100, Lockbox Services 200805, Irving, TX 75063';
                if (account_deposit_funds_data.routableAccount?.routingNumber) {
                    achSource = {
                        url: 'list/bank',
                        method: 'POST',
                        baseUrl: true,
                        data: {
                            "pageNumber": 1,
                            "pageSize": 10,
                            "criteria": {
                                "filters": [
                                    {
                                        "operator": "eq",
                                        "key": "searchPattern",
                                        "values": [
                                            props.data?.routableAccount?.routingNumber
                                        ]
                                    }
                                ]
                            }
                        }
                    }

                }
                if (account_deposit_funds_data.routableAccount?.wireRoutingNumber) {
                    wireSource = {
                        url: 'list/bank',
                        method: 'POST',
                        baseUrl: true,
                        data: {
                            "pageNumber": 1,
                            "pageSize": 10,
                            "criteria": {
                                "filters": [
                                    {
                                        "operator": "eq",
                                        "key": "searchPattern",
                                        "values": [
                                            props.data?.routableAccount?.wireRoutingNumber
                                        ]
                                    }
                                ]
                            }
                        }
                    }
                }
                if(getKey("isPPIEnabled")?.toLowerCase() === 'true' && (checkPermission({entity:"Customer",name:"PPI",operation:"VIEW"})||checkPermission({entity:"Business Customer",name:"PPI",operation:"VIEW"}))){
                    ppiSource = {
                        url: '/ppi',
                        method: "GET"
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }

        /**
         * Async function handles the api call for downloading transaction receipt
         * @param event : event parameter
         */
        async function submitData(event: any) {
            event.preventDefault();
            try {
                if (newData) {
                    fileData.length === 0 && fileData.push({
                        "": "Beneficiary Name",
                        "Wire Instructions": newData['beneficiaryName'] ? newData['beneficiaryName'] : '',
                        "ACH Instructions": newData['beneficiaryName'] ? newData['beneficiaryName'] : ''
                    }, {
                        "": "Beneficiary Bank Name",
                        "Wire Instructions": newData['wireBankName'] ? newData['wireBankName'] : '',
                        "ACH Instructions": newData['bankName'] ? newData['bankName'] : ''
                    }, {
                        "": "Beneficiary Bank Address",
                        "Wire Instructions": newData['wireBankAddress'] ? newData['wireBankAddress'] : '',
                        "ACH Instructions": newData['bankAddress'] ? newData['bankAddress'] : ''
                    }, {
                        "": "Account Number",
                        "Wire Instructions": newData['wireAccountNumber'] ? "'" + newData['wireAccountNumber'] + "'" : '',
                        "ACH Instructions": newData['beneficiaryAccountNumber'] ? "'" + newData['beneficiaryAccountNumber'] + "'" : ''
                    }, {
                        "": "Routing Number",
                        "Wire Instructions": newData['wireRoutingNumber'] ? "'" + newData['wireRoutingNumber'] + "'" : '',
                        "ACH Instructions": newData['routingNumber'] ? "'" + newData['routingNumber'] + "'" : ''
                    }, {
                        "": "Memo",
                        "Wire Instructions": newData['wireMemo'] ? newData['wireMemo'] : '',
                        "ACH Instructions": newData['memo'] ? newData['memo'] : ''
                    })
                }
                let csvContent: any = ",Wire Instructions,ACH Instructions\n"
                fileData.forEach((row: any) => {
                    const data = row[""] + ',"' + row['Wire Instructions'] + '","' + row["ACH Instructions"] + '"\n';
                    csvContent += data

                });
                csvContent+='"It is mandatory to include the memo precisely as indicated above while creating the wire transfer to facilitate the credit to your Passport account.  Failure to do so may result in delays in funds processing."'
                csvContent += '\n\n"Mailing Address for Check Deposits/correspondence: Priority Technology Holdings, 2975 Regent Blvd Suite 100, Lockbox Services 200805, Irving, TX 75063"';
                csvContent += '\n\n"Passport Payment ID (only passport customer can deposit via Passport Payment ID): "'+newData['ppi'];
                downloadFileCommonClass(csvContent, 'CSV', userName? userName + '_Deposit_Fund_Instructions': 'Deposit_Fund_Instructions');
                FxSnackBar.show({
                    autoHideDuration: 2000,
                    severity: 'success',
                    text: 'File Downloaded',
                });
                setIsLoading(false);
            }
            catch (err) {
                FxSnackBar.show({
                    autoHideDuration: 2000,
                    text: 'Instructions Download Failed',
                });
                setIsLoading(false);
            }

        }

        const account_deposit_funds_section_left_one = [
            {
                label: 'Beneficiary Name',
                value: 'beneficiaryName'
            },
            {
                label: 'Beneficiary Bank Address',
                value: 'wireBankAddress'
            },
            {
                label: 'Routing Number',
                value: 'wireRoutingNumber'
            },
        ];
        const account_deposit_funds_section_left_two = [
            {
                label: 'Beneficiary Name',
                value: 'beneficiaryName'
            },
            {
                label: 'Beneficiary Bank Address',
                value: 'bankAddress'
            },
            {
                label: 'Routing Number',
                value: 'routingNumber'
            },

        ];
        const account_deposit_funds_section_left_three = [
            {
                label: 'Mailing Address for checks/correspondence',
                value: 'mailingInfo'
            }

        ];
        const account_deposit_funds_section_left_four = [
            {
                label: 'Passport Payment ID',
                value: 'ppi'
            }

        ];
        const account_deposit_funds_section_right_one = [
            {
                label: 'Beneficiary Bank Name',
                value: 'wireBankName'
            },
            {
                label: 'Account Number',
                value: 'wireAccountNumber'
            },
            {
                label: 'Memo',
                value: 'wireMemo'
            },
        ];
        const account_deposit_funds_section_right_two = [
            {
                label: 'Beneficiary Bank Name',
                value: 'bankName'
            },
            {
                label: 'Account Number',
                value: 'beneficiaryAccountNumber'
            },
            {
                label: 'Memo',
                value: 'memo'
            },
        ];

        return (
            <Grid id="account-settings-deposit-funds-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="deposit-info-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'account-settings-deposit-funds-info-card#card-header'}>
                        <Grid id="account-configurations-Grid2" item container justifyContent="space-between" xs={12} >
                            <Grid id="account-configurations-Grid3"  >
                                <Typography className={!isMobileOrTabScreen ? 'fx-deposit-funds-title':''} id="deposit-funds-infocard-sub-title">Fund your passport account {maskAccountNumber(account_deposit_funds_data?.accountNumber, false)} using details below</Typography>
                                <div id="div-grow-title-payees-list" className="fx-flex-grow" />
                            </Grid>
                            <Grid item> <FxButton id="collect-details-card-transaction-receipt-download-button" title="Instructions" variant="contained" className="fx-button  fx-button-passport-border fx-svg-theme" onClick={submitData}  ><DownloadIcon /> &nbsp; Download Instructions {isLoading && <CircularProgress size={20} style={{ color: 'white' }} />}</FxButton></Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="deposit-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        {/* Info card section */}
                        <Grid container item xs={12} direction="row" >
                            <Grid item xs={12} direction="column">
                                <FxInfoCard id={"section-info-card-account-configurations-basic-info-one"} title="WIRE INSTRUCTION" leftcolumn={account_deposit_funds_section_left_one} rightcolumn={account_deposit_funds_section_right_one} source={wireSource} dataTransformer={transformInstructionsData} />
                            </Grid>
                            <Grid container item className='fx-container-box'  alignItems={"center"} direction="row">
                            <Grid item > <IncomingWire /> &nbsp;&nbsp;</Grid>
                            <Grid item xs> <Typography variant='h5'>It is mandatory to include the memo precisely as indicated above while creating the wire transfer to facilitate the credit to your Passport account. Failure to do so may result in delays in funds processing.</Typography></Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <FxInfoCard id={"section-info-card-account-configurations-basic-info-two"} title="ACH INSTRUCTION" leftcolumn={account_deposit_funds_section_left_two} rightcolumn={account_deposit_funds_section_right_two} source={achSource} dataTransformer={transformInstructionsData} />
                            </Grid>
                            <Grid item xs={12}>
                                <FxInfoCard id="section-info-card-account-configurations-basic-info-three" title="DEPOSIT VIA CHECK" column={account_deposit_funds_section_left_three} data={account_deposit_funds_data} />
                            </Grid>
                            {getKey("selectedAccount")?.isPrimary && <Grid item xs={12}>
                                <FxInfoCard id="section-info-card-account-configurations-basic-info-four" title={"DEPOSIT VIA PAYMENT ID (only passport customer can deposit via Passport Payment ID)"} leftcolumn={account_deposit_funds_section_left_four} source={ppiSource} dataTransformer={transformInstructionsData} />
                            </Grid>}
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
