/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Logger } from '../../../../libs/utils/logger';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as GreyDot } from '../../../../assets/svg/grey-dot.svg';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import { FxDateTimeView } from '../../../Input/FxDate/FxDateView';
import { removeUnderScoreConvertTitleCase } from '../../../Utils/CommonBaseClass';
import HttpClient from '../../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../../libs/utils/utils';

const httpClient = HttpClient.getClient();

Logger.debug("ScheduleDetailSummaryCard.tsx", "Schedule Detail Summary Card initializing");

/**
 * Component: ApprovalDetailSummaryCard
 * Usage: To show approval summary details
 */

export const ApprovalDetailSummaryCard: React.FC<any> = React.memo(
  (props) => {
    const [showUsers, setshowUsers] = useState(false);  
    const [summaryData, setSummaryData] = useState<any>();

    /**
    * On showing the all user name
   */
    const handleShowUsersOnClick =()=>{
        setshowUsers(true)
    }

   /**
    * for hiding the all user names
   */
    const handleHideUsersOnClick =()=>{
        setshowUsers(false)
    }


  useEffect(() => {
    getApprovalSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Api call for Approval Summary
   */
  async function getApprovalSummary() {
    try {
      const response = await httpClient.get(getCustomerUrl("/transaction/id/" + props.scheduleId + "/approvalSummary"));
      if(response.data) {
        setSummaryData(response.data);
      }
    } catch (err) {
      return err;
    }
  }

    return (
      <Grid id="approval-summary-card-Grid" item xs={12}>
        {(summaryData?.approvalStatus && summaryData?.approvalStatus !== 'NOT_REQUIRED' && summaryData?.approvalStatus !== 'VALIDATION_PENDING' && summaryData?.approvalStatus !== 'DELETED') && <FxCard
          id="approval-summary-card"
          className="fx-info-card fx-theme-passport"
        >
          <FxCardHeader id="approval-summary-header">
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
            >
              <Typography id="title-approval-summary-card" variant="h3">
                Approval Summary{" "}
              </Typography>
            </Grid>
          </FxCardHeader>

          <FxCardBody
            id="approval-summary-card#source-body"
            className=""
          >
            <Grid
                  item
                  container
                  sm={12}
                  xs={12}
                  className="fx-policy-detail-summary"
                  paddingBottom={"1rem"}
                >
                  <Grid
                    item
                    sm={1}
                    xs={1}
                    className={"fx-transaction-summery"}
                  >
                    <GreyDot></GreyDot>
                  </Grid>
                  <Grid item container sm={11} xs={11}>
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        sm={11}
                        xs={11}
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"0.3rem"}
                      >
                        <Grid item alignContent={"flex-start"}>
                          <FxStatus
                            id="approval-status"
                            value={summaryData?.approvalStatus}
                            fill={true}
                            className="fx-status"
                          ></FxStatus>
                        </Grid>

                        {summaryData?.approvalStatus !== 'NOT_REQUIRED' && <Grid item paddingLeft={"0.8rem"}>
                          <Typography
                            id="approval-summary-card#source-header"
                            variant="h5"
                          >
                            ON <FxDateTimeView value={summaryData?.approvalStatusDate} format={'MMM DD, yyyy HH:MM:SS'}/>
                          </Typography>
                        </Grid>}
                      </Grid>
                    </Grid>
                    {summaryData?.approvalStatus === 'PENDING' && <Grid item sm={12} xs={12}>
                      <Grid item container>
                        <Grid item container>
                          <Typography
                            id="approval-summary-card-bank-name" variant="h4" marginTop="0.5rem" >
                            By user (1 Approval Required)
                          </Typography>
                        </Grid>
                        {!showUsers ? 
                        <Grid item container className='fx-light-grey-text' onClick={handleShowUsersOnClick}>
                            Show all approver(s)
                        </Grid>
                        : 
                        <Grid>
                            <Grid item container marginTop={"1rem"} gap={1}>
                            {summaryData?.approvers?.map((item: any, i: number) => {
                                 const user_info =  removeUnderScoreConvertTitleCase(item?.firstName) + ' ' + removeUnderScoreConvertTitleCase(item?.lastName); 
                              return <Typography className='fx-user-info'>{user_info}</Typography>
                            })}
                            </Grid> 
                            <Grid item container className='fx-light-grey-text' onClick={handleHideUsersOnClick}>
                               Hide all approver(s)
                            </Grid>
                        </Grid>}
                      </Grid>
                    </Grid>
                    }
                   {summaryData?.approvalStatus === 'APPROVED' && <Grid item sm={12} xs={12}>
                      <Grid item container alignItems="center">
                        <Grid item>
                          <Typography
                            id="approval-summary-card-bank-name" variant="h4" marginTop="1rem" >
                            By user : {summaryData?.approvedBy?.username}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    }
                    {summaryData?.approvalStatus === 'REJECTED' && <Grid item sm={12} xs={12}>
                      <Grid item container alignItems="center">
                        <Grid item>
                          <Typography
                            id="approval-summary-card-bank-name" variant="h4" marginTop="1rem" >
                            By user : {summaryData?.rejectedBy?.username}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    }
                    {summaryData?.comment && <Grid item container className='fx-light-grey-text' alignItems='center'>
                       <span> Comment : {summaryData?.comment} </span>
                    </Grid>
                    }
                  </Grid>
                </Grid>
          </FxCardBody>
        </FxCard>}
      </Grid>
    );
  })
