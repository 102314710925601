/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// /*
// *@sarath
// component dictionary
// */

// /*
// Import all the required files
// */

// import { FxButton } from "../../components/Action/FxButton";
// import FxLabel from "../../components/Input/FxLabel/FxLabelView";
// import { FxTypography } from "../../components/Data/FxTypography";
// import FxToolbar from "../../components/Action/FxToolbar";
// import { FxLink } from "../../components/Action/FxLink";
// import { Grid } from "@mui/material";
// import FxCard from "../../components/Container/FxCard";
// import FxStepperCard from '../../components/Container/FxStepperCard';
// import FxCardHeader from "../../components/Container/FxCardHeader";
// import FxCardBody from "../../components/Container/FxCardBody";
// import FxCardFooter from "../../components/Container/FxCardFooter";
// import { FxIframe } from "../../components/Container/FxIframe";
// import FxMenu from "../../components/Navigation/FxMenu";
// import FxContainer from "../../components/Container/FxContainer";
// import FxTab from "../../components/Navigation/FxTab";
// import { FxInfoCard } from "../../components/Page/Cards/FxInfoCard";
// import { FxAccordionInfoCard } from "../../components/Page/Cards/FxAccordionInfoCard";
// import { FxDebtCard } from "../../components/Page/Cards/FxDebtCard";
// import { FxExtAccountCard } from "../../components/Page/Cards/FxExtAccountCard";
// import { FxListCard } from "../../components/Page/Cards/FxListCard";
// import { FxBalanceCalendarCard } from "../../components/Page/Cards/FxBalanceCalendarCard";
// import { FxContextMenu } from "../../components/Navigation/FxContextMenu";
// import { FxDataGrid } from "../../components/Data/FxDataGrid";
// //import { FxAutoComplete } from "../../components/Utils/FxAutoComplete";
// import { FxDataGridCard } from "../../components/Page/Cards/FxDataGridCard";
// import FxTextView from '../../components/Input/FxText/FxTextView';
// import { FxCurrencyEdit } from '../../components/Input/FxCurrency/FxCurrencyEdit';
// import { FxDateTimeView } from '../../components/Input/FxDate/FxDateView';
// import FxRadioEdit from '../../components/Input/FxRadio/FxRadioEdit';
// import FxArrayView from '../../components/Input/FxArray/FxArrayView';
// import { FxCurrencyView } from '../../components/Input/FxCurrency/FxCurrencyView';
// import { FxEmailView } from '../../components/Input/FxEmail/FxEmailView';
// import FxNumberView from '../../components/Input/FxNumber/FxNumberView';
// import { FxPhoneView } from '../../components/Input/FxPhone/FxPhoneView';
// import { FxSSNView } from '../../components/Input/FxSSN/FxSSNView';
// import FxDateEdit from '../../components/Input/FxDate/FxDateEdit';
// import { FxEmailEdit, FxNumberEdit } from '../../components/Input';
// import FxPhoneEdit from '../../components/Input/FxPhone/FxPhoneEdit';
// import FxSSNEdit from '../../components/Input/FxSSN/FxSSNEdit';
// import {FxTimeEdit} from '../../components/Input/FxTime/FxTimeEdit';
// import { FxTextEdit } from '../../components/Input/FxText/FxTextEdit';
// import { FxAddressView } from "../../components/Input/FxAddress/FxAddressView";
// import FxAvatar from '../../components/Input/FxAvatar/FxAvatar';
// import { FxUserInfo } from "../../components/Data/FxUserInfo";
// import { FxListTagCard } from "../../components/Page/Cards/FxListTagCard";
// import { FxStatus } from "../../components/Input/FxStatus/FxStatus";
// import FxArrayObjectView from "../../components/Input/FxArrayObject/FxArrayObject";
// import { FxAvatarCard } from "../../components/Page/Cards/FxAvatarCard";
// import { FxSort } from "../../components/Page/Cards/FxSort";
// import { FxArrayEdit } from "../../components/Input/FxArray/FxArrayEdit";
// import { FxFilterDrawer } from "../../components/Utils/Drawer/FxFilterDrawer";
// import { FxRangeEdit } from "../../components/Input/FxRange/FxRangeEdit";
// import FxSelect from "../../components/Input/FxSelect/FxSelect";
// import { FxColumnDrawer } from "../../components/Utils/Drawer/FxColumnDrawer";
// import { FxGridSearch } from '../../components/Page/Cards/FxGridSearch';
// import { FxMngPopGridSearch } from '../../components/Page/Cards/FxMngPopGridSearch';

// import { FxPreferenceListCard } from "../../components/Page/Cards/FxPreferenceListing";
// import { FxModal } from "../../components/Utils/FxModal";
// import { FxProgressbar } from "../../components/Input/FxProgress/FxProgress";
// import { FxForm } from "../../components/Container/FxForm";
// import { FxAccordion } from "../../components/Container/FxAccordion";
// import { FxFormCard } from "../../components/Page/Cards/FxFormCard";
// import { FxSnackBar } from "../../components/Utils/FxSnackBar";
// import FxCheckEdit from "../../components/Input/FxCheck/FxCheckEdit";
// import { FxHidden } from "../../components/Input/FxHiden/FxHidden";
// import { FxCommunicationListCard } from "../../components/Page/Cards/FxCommunicationListCard";
// import { FxManage } from "../../components/Action/FxManage";
// import { FxPopOver } from "../../components/Utils/FxPopOver";
// import { FxBreadCrumb } from "../../components/Data/FxBreadCrumb";
// import { FxTableCard } from "../../components/Page/Cards/FxTableCard";
// import { FxSlider } from "../../components/Utils/FxSlider";
// import { FxExport } from "../../components/Utils/FxExport";
// import { FxAutoComplete } from "../../components/Input/FxAutoComplete/FxAutoComplete";
// import { FxEditTable } from "../../components/Page/Cards/FxEditTable";
// import { FxShare } from "../../components/Utils/FxShare";
// import {FxParagraphView} from '../../components/Input/FxParagraph/FxParagraphView';
// import { FxFileUpload } from "../../components/Input/FxFile/FxFileUpload";
// import { FxAsisgneeList } from "../../components/Utils/FxAssignee";
// import FxGridIcon from "../../components/Input/FxGridIcon/FxGridIcon";
// import { FxButtonPopover } from "../../components/Utils/FxButtonPopover";
// import FxImage from "../../components/Data/FxImage";
// import { FxGridDropdownFilter } from "../../components/Page/Cards/FxGridDropdownFilter";
// import { FxRoleCard } from "../../components/Page/Cards/FxRolesCard";
// import { FxTextArea } from '../../components/Input/FxTextArea/FxTextArea';
// import FxTinyEditor from "../../components/Utils/FxTinyEditor";
// import { FxConfirmModal } from "../../components/Utils/FxConfirmModal";
// import FxLogo from "../../components/Data/FxLogo";
// import  {FxPdfViewer}  from '../../components/Data/FxPdf/FxPdfViewer';
// import FxTabContainer from "../../components/Navigation/FxTabContainer";
// import FxHtmlView from "../../components/Input/FxHtml/FxHtmlView";
// import FxStatusBar from "../../components/Container/FxStatusBar";
// import { FxExpandableContent } from "../../components/Data/FxExpandableContent";
// import { FxTableFormCard } from "../../components/Page/Cards/FxTableForm";
// import { FxToggleButton } from "../../components/Action/FxToggleButton";
// import { FxSimpleDataGrid } from "../../components/Data/FxSimpleDataGrid";
// import { FxInclusionRuleEditor } from "../../components/Data/FxInclusionRuleEditor/FxInclusionRuleEditor";
// import { FxMacroFormCard } from "../../components/Page/Cards/FxMacroCard";
// import FxGraph from "../../components/Data/FxGraph";
// import { FxInfoCardSimple } from "../../components/Page/Cards/FxInfoCardSimple";
// import { FxSimpleCard } from "../../components/Page/Cards/FxSimpleCard";
// import { FxCode } from "../../components/Input/FxCode/FxCode";
// import { FxCodeEditor } from '../../components/Input/FxCodeEditor/FxCodeEditor';
// import { FxFieldGroup } from "../../components/Page/Cards/FxFieldGroup";
// import { FxTemplateNote } from "../../components/Data/FxTemplateNote/FxTemplateNote";
// import { FxFieldReplicator } from "../../components/Data/FxFieldReplicator";
// import { FxBothSideCard } from "../../components/Page/Cards/FxBothSideCard";
// import { FxTransition } from "../../components/Utils/FxTransition";
// import FxCardHandler from "../../components/Container/FxCardHandler";
// import { FxReportCard } from "../../components/Page/Cards/FxReportCard";
// import { FxSimpleDataCard } from "../../components/Page/Cards/FxSimpleDataCard";
// import { FxDynamicListGrid } from "../../components/Data/FxDynamicListGrid";
// import { Logger } from "../utils/logger";
// import {FxCheckListCard} from "../../components/Page/Cards/FxChecklistCard";
// import { FxIconBrowser, FxIconViewer } from "../../components/Page/Cards/FxIconBrowser";
// import { FxZipEdit } from "../../components/Input/FxZip/FxZipEdit";
// import { FxBulletList } from "../../components/Data/FxBulletList";
// import { FxCommentList } from "../../components/Data/FxCommentList";
// import { FxRowDataGrid } from "../../components/Data/FxRowDataGrid";
// import { FxCopy } from "../../components/Input/FxCopy/FxCopy";
// import { FxProgressToast } from "../../components/Utils/FxProgressToast";
// import { FxCheckCard } from "../../components/Page/Cards/FxCheckCard";
// import { FxMessages } from "../../components/Data/FxMessages";
// import { FxEmailViewCard } from "../../components/Page/Cards/FxEmailViewCard";
// import { FxForecastGrid } from "../../components/Data/FxForecastGrid";

// Logger.debug("DictionayIndex","Initializing")
export interface Dictionary {
  [key: string]: any;
}

// export const compDictionary: Dictionary = {
//   "fri::uicomponent:button": FxButton,
//   "fri::uicomponent:label": FxLabel,
//   "fri::uicomponent:typography": FxTypography,
//   "fri::uicomponent:breadcrumbs": FxBreadCrumb,
//   "fri::uicomponent:userinfo": FxUserInfo,
//   "fri::uicomponent:toolbar": FxToolbar,
//   "fri::uicomponent:card": FxCard,
//   "fri::uicomponent:steppercard": FxStepperCard,
//   "fri::uicomponent:cardheader": FxCardHeader,
//   "fri::uicomponent:cardbody": FxCardBody,
//   "fri::uicomponent:cardfooter": FxCardFooter,
//   "fri::uicomponent:fxiframe": FxIframe,
//   "fri::uicomponent:inlinemenu": FxMenu,
//   "fri::uicomponent:contextmenu": FxContextMenu,
//   "fri::uicomponent:tab": FxTab,
//   "fri::uicomponent:tabContainer": FxTabContainer,
//   "fri::uicomponent:infocard": FxInfoCard,
//   "fri::uicomponent:simplecard": FxSimpleCard,
//   "fri::uicomponent:simpledatacard": FxSimpleDataCard,
//   "fri::uicomponent:bothsidecard": FxBothSideCard,
//   "fri::uicomponent:infocardsimple": FxInfoCardSimple,
//   "fri::uicomponent:accordioninfocard": FxAccordionInfoCard,
//   "fri::uicomponent:accordion": FxAccordion,
//   "fri::uicomponent:debtcard": FxDebtCard,
//   "fri::uicomponent:listcard": FxListCard,
//   "fri::uicomponent:externalcard": FxExtAccountCard,
//   "fri::uicomponent:balancecard": FxBalanceCalendarCard,
//   "fri::uicomponent:table": FxContainer,
//   "fri::uicomponent:tr": FxContainer,
//   "fri::uicomponent:td": FxContainer,
//   "fri::uicomponent:tbody": FxContainer,
//   "fri::uicomponent:div": FxContainer,
//   "fri::uicomponent:placeholder": FxContainer,
//   "fri::uicomponent:span": FxContainer,
//   "fri::uicomponent:grid": FxContainer,
//   "fri::uicomponent:datagrid": FxDataGrid,
//   "fri::uicomponent:simpledatagrid": FxSimpleDataGrid,
//   "fri::uicomponent:forecastgrid": FxForecastGrid,
//   "fri::uicomponent:rowdatagrid": FxRowDataGrid,
//   //"fri::uicomponent:autocomplete": FxAutoComplete,
//   "fri::uicomponent:gridcard": FxDataGridCard,
//   "fri::uicomponent:taglistcard": FxListTagCard,
//   "fri::uicomponent:avatarcard": FxAvatarCard,
//   "fri::uicomponent:preferencelisting": FxPreferenceListCard,
//   "fri::uicomponent:gridsearch": FxGridSearch,
//   "fri::uicomponent:mngpopgridsearch": FxMngPopGridSearch,
//   "fri::uicomponent:sort": FxSort,
//   "fri::uicomponent:gridDropdownFilter": FxGridDropdownFilter,
//   "fri::uicomponent:filterdrawer": FxFilterDrawer,
//   "fri::uicomponent:columndrawer": FxColumnDrawer,
//   "fri::uicomponent:modal": FxModal,
//   "fri::uicomponent:confirmmodal":FxConfirmModal,
//   "fri::uicomponent:form": FxForm,
//   "fri::uicomponent:formcard": FxFormCard,
//   "fri::uicomponent:select": FxSelect,
//   "fri::uicomponent:toast": FxSnackBar,
//   "fri::uicomponent:hyperlink": FxLink,
//   "fri::uicomponent:communicationlistcard": FxCommunicationListCard,
//   "fri::uicomponent:popover": FxPopOver,
//   "fri::uicomponent:share": FxShare,
//   "fri::uicomponent:tablecard": FxTableCard,
//   "fri::uicomponent:slider": FxSlider,
//   "fri::uicomponent:export": FxExport,
//   "fri::uicomponent:assigneecard":FxAsisgneeList,
//   "fri::uicomponent:groupTable": FxEditTable,
//   "fri::uicomponent:buttonpopover": FxButtonPopover,
//   "fri::uicomponent:rolecard": FxRoleCard,
//   "fri::uicomponent:checkcard": FxCheckCard,
//    "fri::uicomponent:richtexteditor": FxTinyEditor,
//   "fri::uicomponent:logo": FxLogo,
//   "fri::uicomponent:pdfviewer": FxPdfViewer,
//   "fri::uicomponent:statusbar" : FxStatusBar,
//   "fri::uicomponent:tableformcard": FxTableFormCard,
//   "fri::uicomponent:inclusionruleeditor" : FxInclusionRuleEditor,
//   "fri::uicomponent:macroformcard": FxMacroFormCard,
//   "fri::uicomponent:graph": FxGraph,
//   "fri::uicomponent:codeblock": FxCodeEditor,
//   "fri::uicomponent:fieldgroup": FxFieldGroup,
//   "fri::uicomponent:templatenote": FxTemplateNote,
//   "fri::uicomponent:fieldreplicator": FxFieldReplicator,
//   "fri::uicomponent:cardhandler": FxCardHandler,
//   "fri::uicomponent:reportcard": FxReportCard,
//   "fri::uicomponent:dynamiclistgrid": FxDynamicListGrid,
//   "fri::uicomponent:checklistcard": FxCheckListCard,
//   "fri::uicomponent:iconbrowser": FxIconBrowser,
//   "fri::uicomponent:iconviewer": FxIconViewer,
//   "fri::uicomponent:bulletlist": FxBulletList,
//   "fri::uicomponent:commentlist": FxCommentList,
//   "fri::uicomponent:progresstoast": FxProgressToast,
//   "fri::uicomponent:messages": FxMessages,
//   "fri::uicomponent:emailviewcard": FxEmailViewCard

// };

// export const compDOMDictionary: Dictionary = {
//   "fri::uicomponent:table": "table",
//   "fri::uicomponent:tr": "tr",
//   "fri::uicomponent:td": "td",
//   "fri::uicomponent:tbody": "tbody",
//   "fri::uicomponent:div": "div",
//   "fri::uicomponent:placeholder": "div",
//   "fri::uicomponent:span": "span",
//   "fri::uicomponent:grid": Grid
// };
// export const compViewDictionary: Dictionary = {
//   "fri://fieldtype/array": FxArrayView,
//   "fri://fieldtype/currency": FxCurrencyView,
//   "fri://fieldtype/date": FxDateTimeView,
//   "fri://fieldtype/email": FxEmailView,
//   "AccountNumberField": FxNumberView,
//   "fri://fieldtype/phonenumber": FxPhoneView,
//   "fri://fieldtype/ssn": FxSSNView,
//   "fri://fieldtype/default": FxTextView,
//   "fri://fieldtype/hidden": FxHidden,
//   "ExternalReferenceField": FxNumberView,
//   "IDField": FxNumberView,
//   "fri://fieldtype/number": FxNumberView,
//   "ZipField": FxNumberView,
//   "fri://fieldtype/address": FxAddressView,
//   "fri://fieldtype/avatar": FxAvatar,
//   "fri://fieldtype/status": FxStatus,
//   "fri://fieldtype/arrayObject": FxArrayObjectView,
//   "fri://fieldtype/progressBar": FxProgressbar,
//   "fri://fieldtype/hyperlink": FxLink,
//   "fri://fieldtype/manage": FxManage,
//   "fri://fieldtype/slider": FxSlider,
//   "fri://fieldtype/paragraph":FxParagraphView,
//   "fri://fieldtype/gridIcon": FxGridIcon,
//   "fri://fieldtype/image": FxImage,
//   "fri::uicomponent:button": FxButton,
//   "fri://fieldtype/html": FxHtmlView,
//   "fri://fieldtype/transition": FxTransition,
//   "fri://fieldtype/iconviewer": FxIconViewer,
//   "fri://fieldtype/typography": FxTypography,
//   "fri://fieldtype/copy": FxCopy,
//   "fri://fieldtype/messagesview":FxMessages

// }

// export const compEditDictionary: Dictionary = {
//   //"check": FxCheckboxEdit,
//   "fri://fieldtype/currency": FxCurrencyEdit,
//   //"fri://fieldtype/date": FxDateEdit,
//   "fri://fieldtype/email": FxEmailEdit,
//   "fri://fieldtype/phonenumber": FxPhoneEdit,
//   //"radio": FxRadioEdit,
//   "SSNField": FxSSNEdit,
//   "fri://fieldtype/default": FxTextEdit,
//   "fri://fieldtype/textarea": FxTextArea,
//   "fri://fieldtype/code": FxCode,
//   "fri://fieldtype/time": FxTimeEdit,
//   "fri://fieldtype/number": FxNumberEdit,
//   "fri://fieldtype/range": FxRangeEdit,
//   "fri://fieldtype/select": FxSelect,
//   "fri://fieldtype/date": FxDateEdit,
//   "fri://fieldtype/radio": FxRadioEdit,
//   "fri://fieldtype/check": FxCheckEdit,
//   "fri://fieldtype/hidden": FxHidden,
//   "fri://fieldtype/slider": FxSlider,
//   "fri://fieldtype/groupTable": FxEditTable,
//   "fri://fieldtype/upload": FxFileUpload,
//   "fri://fieldtype/typography": FxTypography,
//   "fri://fieldtype/expandablecontent": FxExpandableContent,
//   "fri://fieldtype/toggleButton": FxToggleButton,
//   "fri://fieldtype/codeblock": FxCodeEditor,
//   "fri://fieldtype/fieldgroup": FxFieldGroup,
//   "fri://fieldtype/expandable": FxExpandableContent,
//   "fri://fieldtype/status": FxStatus ,
//   "fri://fieldtype/label": FxTextView,
//   "fri://fieldtype/zip": FxZipEdit,
//   "fri://fieldtype/manage": FxManage,
//   "fri://fieldtype/messagesview":FxMessages



// }
// // enum dictionary
// export const compEnumDictionary: Dictionary = {
//   "fri://fieldtype/check": FxCheckEdit,
//   "fri://fieldtype/radio": FxRadioEdit,
//   "fri://fieldtype/select": FxSelect,
//   "fri://fieldtype/array": FxArrayEdit,
//   "fri://fieldtype/slider": FxSlider,
//   "fri://fieldtype/autocomplete": FxAutoComplete,
//   "fri://fieldtype/groupTable": FxEditTable ,
//   "fri://fieldtype/image": FxImage,
//   "fri://fieldtype/codeblock": FxCodeEditor,
//   "fri://fieldtype/fieldgroup": FxFieldGroup,
//   "fri://fieldtype/range": FxRangeEdit,

// }

//get view/edit component
export function getViewEditComponent(field: string, mode: string) {
  return field;
}

//get enum component
export function getEnumComponent(field: string) {
  return field;//compEnumDictionary[field];
}

//get the component
export function getComponent(id: string) {
  return id;
}

// get component name
export function getComponentName(id: string) {
  let orgId: string;

  if (id.indexOf("?") !== -1) {
    orgId = id.substring(0, id.indexOf("?"));
  } else if (id.indexOf("#") !== -1) {
    orgId = id.substring(0, id.indexOf("#"));
  } else {
    orgId = id;
  }
  return orgId;
}

//get the component
export function getDOMComponent(id: string) {
  return id;
}