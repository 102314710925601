/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, select, takeLeading, takeEvery } from "redux-saga/effects";
import { State } from "../reducer/rootReducer";
import { PortalActions } from "../reducer/portalReducer";
import { LayoutAction } from "../reducer/layoutReducer";
import { buildPageJSON } from "./pageSaga";
import { PageActions } from "../reducer/pageReducer";
import HttpClient from "../utils/httpClient";
import { setUserToken, setUserLoggedIn } from "../utils/storageManager";
import { Logger } from "../utils/logger";

Logger.debug("portalSaga.tsx", "Portal Saga initializing")

const httpClient = HttpClient.getClient();
const getPortal = (state: State) => state.portal;

const SINGLE_TIME_LOAD: boolean = false;
const DEVELOPMENT: boolean = true;

/**
 * 1) RoleAPI or something similar set or changes portal
 *
 */
export default function* portalSaga() {
  yield takeLeading(PortalActions.PORTAL_LOAD, loadPortal);
  yield takeLeading(PortalActions.PORTAL_LOAD_API_DONE, processAPIResponse);
  yield takeEvery("ATTEMPT_SIGN_IN", attemptSignInSaga);

}

/**
 * Get the portal JSON as
 *  1)single JSON or only portal.json to begin with.
 * @param action
 */
function* loadPortal(action: any): any {
  let portalState = yield select(getPortal);
  const url: string = getPortalJSONUrl(portalState.name, SINGLE_TIME_LOAD);

  yield put({
    type: "CALL_API",
    payload: {
      url: url,
      type: PortalActions.PORTAL_LOAD_API_DONE,
      id: portalState.name,
      // expiry:3600
    }
  });
}

/**
 * API responded and this saga is going to process it.
 * @param action
 */
function* processAPIResponse(action: any): any {
  const data: any = action.payload.data.body;

  if (SINGLE_TIME_LOAD) {
    // first get all layout keys
    // set layout keys to store. (LayoutAction.LAYOUT_LOAD_JSON_SET) {fri: key , data: value }
    // data.layout ;

    const layoutKeys = Object.keys(data.layout);
    for (let i = 0; i < layoutKeys.length; i++) {
      yield setLayout(layoutKeys[i], data.layout[layoutKeys[i]]);
    }
    Logger.info("portalSaga.tsx", "info", yield select(state => state.config));

    const pageKeys = Object.keys(data.page);
    for (let i = 0; i < pageKeys.length; i++) {
      yield setPage(pageKeys[i], data.page[pageKeys[i]]);
    }

    yield put({
      type: PortalActions.PORTAL_LOAD_PATH_SET,
      payload: {
        path: data.portal.path
      }
    });
  } else {
    yield put({
      type: PortalActions.PORTAL_LOAD_PATH_SET,
      payload: {
        path: data.path
      }
    });
  }
}

function* setLayout(fri: string, json: any) {
  yield put({
    type: LayoutAction.LAYOUT_LOAD_JSON_SET,
    payload: {
      fri: fri,
      data: json
    }
  });
}
function* setPage(page: string, newJson: any): any {
  const finalJson = yield buildPageJSON(page, newJson);
  yield put({
    type: PageActions.PAGE_LOAD_JSON_UPDATE_COMBINE_JSON,
    payload: {
      fri: page,
      data: finalJson
    }
  });
  return;
}

function getPortalJSONUrl(name: string, single: boolean): string {
  if (DEVELOPMENT) {
    const pathname = name.substr(name.lastIndexOf(":") + 1);
    return "/api/portal/" + pathname + "/portal.json";
  }
  if (single) return "portal/" + name;
  else return "portal/" + name + '?portalOnly = true';
}

function* attemptSignInSaga(data: any): any {
  const payload = data.payload;
  Logger.info("portalSaga.tsx", "info", "saga paylaod", payload);
  try {
    const { data } = yield httpClient.post('auth/signin', payload);
    Logger.info("portalSaga.tsx", "info", "data respnse", data);
    if (data?.accessToken) {
      yield setUserToken(data?.accessToken);
      yield setUserLoggedIn();

    }
    //   yield put({
    //     type: PortalActions.SET_AUTH_ACTION_STATUS,
    //     payload: {"isLoggedIn":true}
    // });
  } catch (err) {
    Logger.error("portalSaga.tsx", "error", err);
  }
}
