import React, { useCallback, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FxButton } from "../../../Action/FxButton";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add-new-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import FxMaterialSelect from "../../../Input/FxSelect/FxMaterialSelect";
import { updateComponentParams } from "../../../Utils/CommonBaseClass";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import FxMaterialMultipleSelect from "../../../Input/FxSelect/FXMaterialMultipleSelect";
import { Logger } from "../../../../libs/utils/logger";
import { getCustomerUrl } from "../../../../libs/utils/utils";
import HttpClient from "../../../../libs/utils/httpClient";
import { getKey } from "../../../../libs/utils/storageManager";
const httpClient = HttpClient.getClient();

/**
 * Component: Funding Rules association Section
 * Usage: To display association Section of Funding Rules Creation Screen
 */
export const FxFundingRuleAssociationCard: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const dispatch = useDispatch();

  const entity = [{
    key: "entityId",
    operator: "eq",
    values: [getKey('customerId')],
  }]

  /**
   * to get merchant, mcc details
   */
  const apiData = useSelector((state: any) => {
    if (state.data['faster-funding-data-list']) {
      return state.data['faster-funding-data-list']?.params
    }
  });

  useEffect(() => {
    //if there is api data that needs to be rendered in case of clone then, refactoring the data to a more component friendly format
    const merchantValue =  props?.value?.name ? props?.value?.name : props?.value?.key ? props?.value?.key?.toLowerCase() :'';
    updateComponentParams(dispatch, props?.id, { 'paramData': merchantValue })
    props.setValue(`funding-rule-association.${props.index}.name`, merchantValue);
    if (props?.data || props?.value) {
      updateComponentParams(dispatch, props?.id, { 'selectedTags': [] })
      if (props?.value?.value) {
        if ((props?.value?.name || props?.value?.key)) {
          if (props?.value?.value) {
            const tags = apiDataTransformer(props?.value?.value, merchantValue);
            updateComponentParams(dispatch, props?.id, { 'selectedTags': tags })
          } 
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, props?.value]);

  useEffect(() => {
    updateComponentParams(dispatch, props?.id, {
      'paramOptions': [
        {
          label: "Merchant Industry",
          value: 'merchant_industry',
          disabled: false,
        },
        {
          label: "Merchant",
          value: 'merchant_mid',
          disabled: false,
        }
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * This method transforms the data received from the Api to handle the clone usecase
   * @param data 
   */
  function apiDataTransformer(data: any, key: any = '') {
    const newData = data;
    const myData =
      newData &&
      newData?.map((data: any) => {
        if (data.label) {
          return data;
        } else {
          return key !== 'merchant_industry' ? { value: `${data.trim()}`, label: `${data.trim()}` } : { value: `${data.trim()}`, label: getMccSecondaryLabel(data.trim()) };
        }

      });

    let transformedData: any = [];

    if (key === 'merchant_industry' && props?.mccApiData?.mccList && myData?.length === props?.mccApiData?.mccList?.length - 1) {
      transformedData = [...[{ label: 'Select All', value: 'Select All' }, ...myData]];
    } else if (key === 'merchant_mid' && props?.mccApiData?.merchantList && myData?.length === props?.mccApiData?.merchantList?.length - 1) {
      transformedData = [...[{ label: 'Select All', value: 'Select All' }, ...myData]];
    }

    props.setValue(`funding-rule-association.${props.index}.value`, transformedData ? transformedData : myData);
    return transformedData?.length > 0 ? transformedData : myData;
  }


  //Method to transform data for mcc dropdown
  const getMccSecondaryLabel = (code: any) => {
    const data = props?.mccApiData?.mccList?.find((item: any) => item?.value === code);

    return data?.label ? data?.label : code;
  }



  /**
   * Method returns whether an item is already present in the association array
   * @param data : form data
   * @param item : form field value
   * @returns : whether the item is already there or not
   */
  const checkValueExists = (data: any, item: any) => {
    const index = data?.["funding-rule-association"]?.findIndex(
      (association: any) => association?.name === item
    )
    const exists = data?.["funding-rule-association"]?.find(
      (association: any) => association?.name === item && props?.index !== index
    )

    return exists ? true : false;
  };

  const { watch } = props;

  /**
   * Dropdown values for the field Named - Parametern
   */
  const parameterDropdownData = useCallback(() => {
    let data = [
      {
        label: "Merchant Industry",
        value: 'merchant_industry',
        disabled: checkValueExists(watch(), 'merchant_industry'),
      },
      {
        label: "Merchant MID",
        value: 'merchant_mid',
        disabled: checkValueExists(watch(), 'merchant_mid'),
      }
    ];
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  /**
   * reset the property
   * */
  useEffect(() => () => { 
    updateComponentParams(dispatch, props?.id, { 'selectedTags': [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Method to handle on change of Param field
   * @param event
   */
  const handleParamChange = (event: any) => {
    props.setValue(`funding-rule-association.${props.index}.operator`, 'in');
    updateComponentParams(dispatch, props?.id, { 'paramData': event?.target?.value })
    updateComponentParams(dispatch, props?.id, { 'selectedTags': [] })
  };

  /**
   * To transform the data
   * @param data: Data received from api
   */
  function dataTransformerForValue(data: any) {

    return data?.map((item: any) => {
      const valueKey = props?.paramData === 'merchant_mid' ? 'mid' : props?.paramData === 'merchant_industry' ? "code" : null;

      if (valueKey) {
        const subKey = 'description';

        if (valueKey) {
          return valueKey === 'code' ? { value: `${item[valueKey]}`, label: `${item[subKey]} (${item[valueKey]})` } : { value: `${item[valueKey]}`, label: `${item[valueKey]}` }
        }
      }

      // Return a default value or handle other cases as needed
      return null;
    }) || null;
  }

  /**
   * To do the api call based on the user search
   * @param searchValue: user entered value
   */
  async function onMerchantSearch(searchValue: any) {
    if (searchValue) {
      const merchantPayloadData = {
        pageSize: 100,
        pageNumber: 1,
        criteria: {
          filters: [
            {
              key: 'mid',
              operator: "like",
              values: [searchValue],
            },
          ],
        },
      };

      const mccCodePayloadData = {
        pageNumber: 1,
        pageSize: 100,
        sortOptions: {
          sortBy: "lastUpdatedOn",
          sortOrder: "desc",
        },
        criteria: {
          filters: [
            {
              operator: "like",
              key: "code",
              values: [searchValue],
            },
            ...entity
          ],
        },
      };

      return props?.paramData === 'merchant_industry'
        ? getMccCodes(mccCodePayloadData)
        : props?.paramData === 'merchant_mid'
          ? getMerchantList(merchantPayloadData)
          : [];
    }
  }

  /**
   * Method used to call the LIST Api for merchants
   * @param body :form data
   * @returns
   */
  async function getMerchantList(payloadData: any) {
    try {
      const response = await httpClient.post(
        getCustomerUrl(merchantId_src.url, false),
        payloadData
      );

      if (
        response?.data &&
        response?.data?.resources &&
        response?.data?.resources?.length > 0
      ) {
        const transformedData = dataTransformerForValue(
          response.data.resources
        );
        return transformedData;
      }
      return response.data.resources;

    } catch (err) {
      Logger.error("FxFundingRuleassociationCard.tsx", "error", err);
      return err;
    }
  }

  /**
   * Method used to call the GET MCC Codes api
   * @param body :form data
   * @returns
   */
  async function getMccCodes(payloadData: any) {
    try {
      const response = await httpClient
        .post(getCustomerUrl(mccCode_src.url, false), payloadData);

      if (
        response?.data &&
        response?.data?.settings &&
        response?.data?.settings?.length > 0
      ) {
        const transformedData = dataTransformerForValue(
          response.data.settings
        );
        return transformedData;
      }
      return response?.data?.settings;

    } catch (err) {
      Logger.error("FxFundingRuleassociationCard.tsx", "error", err);
      return err;
    }
  }

  const mccCode_src = {
    url: "merchant/mcc",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 25,
      sortOptions: {
        sortBy: "lastUpdatedOn",
        sortOrder: "desc",
      },
      association: {
        filters: [
          {
            key: "code",
            operator: "like",
            values: [""],
          },
          ...entity
        ],
      },
    },
  };

  const merchantId_src = {
    url: "merchant/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      association: {
        filters: [
          {
            key: "mid",
            operator: "like",
            values: [""],
          },
        ],
      },
    },
  };

  return (
    <Grid
      container
      item
      direction="row"
      className="fx-form-edit-profile"
      spacing={2}
      id={"add-funding-rule-association" + props.index}
      key={"association" + props.index}
    >
      <Grid
        item
        display={"inline-flex"}
        alignItems={"center"}
        maxWidth={"fit-content"}
      >
        <Typography className="fx-summary-title" variant="h3">
          {props.index + 1}.
        </Typography>
      </Grid>
      <Grid item xs={2} display={'inline-flex'} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <FxMaterialSelect
            register={{
              ...props.register(`funding-rule-association.${props.index}.name`),
            }}
            control={props.control}
            className={
              props.errors.name
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            rules={{ required: true }}
            id={props.index + "-" + props.index + "-funding-rule-association-name"}
            name={`funding-rule-association.${props.index}.name`}
            data={props?.paramOptions || []}
            label="Select Parameter"
            setValue={props.setValue}
            placeholder="Select"
            onChange={handleParamChange}
            value={props?.value?.key ? props?.value?.key?.toLowerCase() : ''}
            onFocus={() => {
              updateComponentParams(dispatch, props?.id, {
                'paramOptions': parameterDropdownData()
              })
            }}
          />
        </Grid>
      </Grid>
      {
        <Grid item xs className="fx-contact-user-card fx-authorized-user-select split-funding-association">
        <FxMaterialMultipleSelect
          register={{
            ...props.register(`funding-rule-association.${props.index}.value`),
          }}
          id={`funding-rule-association.${props.index}.value`}
          name={`funding-rule-association.${props.index}.value`}
          data={props?.paramData === 'merchant_mid' ? apiData?.merchantList|| [] : props?.paramData === 'merchant_industry' ? apiData?.mccList || [] : []}
          control={props.control}
          canSearch={true}
          rules={{ required: true }}
          dataTransformation={dataTransformerForValue}
          listBoxClass="fx-auto-complete-custom-auto-complete-dropdown-display-block"
          fixedOptions={[]}
          onChange={onMerchantSearch}
          limitTags={2}
          selectAllLabel={"Select All"}
          label="Value *"
          setValue={props.setValue}
          isEdit={false}
          value={props?.selectedTags}
          isSelectAllRequired={true}
          readOnly={watch(`funding-rule-association.${props.index}.name`) ? false : true}
        /></Grid>}
      <Grid
        item
        xs={"auto"}
        spacing={2}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-delete-condition-association-btn"}
            title="Delete"
            className={`fx-button fx-approval-button-icon fx-condition-btn`}
            variant="contained"
            onClick={props.handleRemove}
            startIcon={<DeleteIcon />}
          />
        </Grid>
        <Grid item>
          <FxButton
            id={props.index + "-" + props.index + "-add-condition-association-btn"}
            title="Add"
            className={`fx-button fx-approval-button-icon fx-condition-btn ${props.isAddButtonDisabled ? "fx-button-disabled" : ""
              }`}
            disabled={props.isAddButtonDisabled}
            variant="contained"
            onClick={props.handleAppend}
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
