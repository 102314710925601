/* eslint-disable  @typescript-eslint/no-explicit-any */
import { all } from "redux-saga/effects";
import toolbarSaga from "./toolbarSaga";
import componentSaga from "./componentSaga";
import { apiRegisterSaga } from "./apiSaga";
import portalSaga from "./portalSaga";
import pageSaga from "./pageSaga";
import entityInfoSaga from './entityInfoSaga';
import formActionSaga from "./formSaga";
import sourceProcessorSaga from './sourceProcessorSaga';
import apiDataSaga from "./dataSaga";
// Calls all the generator functions(sagas).
function* rootSaga(): any {
  yield all([
    toolbarSaga(),
    componentSaga(),
    apiRegisterSaga(),
    pageSaga(),
    portalSaga(),
    entityInfoSaga(),
    formActionSaga(),
    sourceProcessorSaga(),
    apiDataSaga()
  ]);
}

export default rootSaga;
