import React, { useMemo, useState } from 'react'
import { Grid, Modal, Typography, IconButton, CircularProgress, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { checkPermission, toTitleCase } from '../../Utils/CommonBaseClass';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/deactivate-cross.svg';
import { ReactComponent as DeactivateIcon } from '../../../assets/svg/icon-deactivate.svg';

const httpClient = HttpClient.getClient();

Logger.debug("FxDeactivateFundingRule.tsx", "fx deavtivate initializing")

interface FxDeactivateFundingRuleProps {
    permissions?: any;
    header: string;
    description?: string;
    buttonTitle?: string;
    buttonId?: string;
    entity: string;
    id: string;
    noRedirection?: boolean;
    redirectUrl?: string;
    open?: boolean;
    close?: () => void;
    onSuccess?: () => void;
    noEntity?: boolean;
    domId?: string;
    menuType?: boolean;
    successEntity?: string;
    buttonName?: String;
}
/**
 * Deavtivate modal window for split funding
 */
export const FxDeactivateFundingRule: React.FC<FxDeactivateFundingRuleProps> = React.memo(
    (props) => {
        const { handleSubmit } = useForm();

        const [isLoading, setIsLoading] = useState(false);
        const [deleteOpen, setDeleteOpen] = useState(false);
        const [propsOpen, setPropsOpen] = useState(props.open);
        const buttonId = props?.buttonId ? props?.buttonId : props.entity;
        const history = useHistory();
        const handleClose = () => {
        };

        /**
         * Function for closing the modal
         * **/
        const handleDeleteClose = () => {
            if (props.open && props.close) {
                props.close()
            }
            setDeleteOpen(false);
            setPropsOpen(false);
        };



        /**
         * Function to open the modal
         */
        const handleDeleteOpen = () => {
            setDeleteOpen(true);
        };

        /**
        * to set permission
        */
        const permission = useMemo(() => {
            return checkPermission(props?.permissions)
        }, [props?.permissions])

        /**
         * onsubmit function for deactivate form
         * @param data 
         */
        async function onSubmit(data: any) {
            const successText: any = props.successEntity ? props.successEntity : toTitleCase(props.entity);
            //if condition to check whether need a API call or not
            if (props?.noEntity === true) {
                FxSnackBar.show({
                    severity: 'success',
                    text: successText + ' Deactivated Successfully!',
                });
                setTimeout(() => {
                    props.onSuccess?.();
                    setDeleteOpen(false);
                    setPropsOpen(false);
                    FxSnackBar.closeForce();
                }, 800);
            }
            else {
                let status: any;
                status = await deleteEntity(props.id);
                status = processAPIResponse(status)
                setIsLoading(false);
                if (status.status) {
                    FxSnackBar.show({
                        severity: 'success',
                        text: 'Funding Rule Deactivated Successfully!',
                    });
                    setTimeout(() => {
                        if (props.noRedirection) {
                            setDeleteOpen(false);
                            setPropsOpen(false);
                        } else {
                            FxSnackBar.closeForce();
                            props.redirectUrl && history.push(props.redirectUrl)
                        }
                        props.onSuccess?.();
                    }, 1000);

                }
                else {
                    //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }
            }

        };

        /**
         * Api call for deactivate entity
         * @param id
         * @returns 
         */
        async function deleteEntity(id: any) {
            try {
                let url = getCustomerUrl(props.entity + '/id/' + id + '/deactivate', false);
                const data: any = await httpClient.post(url, {}).then((response: any) => {
                    return response;
                })
                    .catch((error: any) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("FxDeactivateSplitFunding.tsx", "error", err);
                return false;
            }
        }

        /**
         * Component structure
         */
        return <>
            {props.menuType && permission ? (<><MenuItem id={"deavtivate-" + props.entity + "-menu"} onClick={handleDeleteOpen}>
                <DeleteIcon />&nbsp;&nbsp;{props.buttonTitle}
            </MenuItem></>) : permission ? (<FxButton id={props?.domId ? props?.domId : "deavtivate-" + props.entity + "-button"} variant="contained" className="fx-button fx-button-action fx-svg-theme" startIcon={<DeleteIcon />} onClick={handleDeleteOpen}>{props.buttonTitle}</FxButton>) : <></>}
            <Modal
                style={{ overflow: 'scroll' }}
                open={propsOpen ? propsOpen : deleteOpen}
                onClose={handleClose}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small"
            >
                <div className="modal-content" >
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCard>
                                <FxCardHeader id="create-deavtivate-header" className="fx-card-header fx-modal-header">
                                    <Grid container className="fx-modal-close-icon" justifyContent="flex-end">
                                        <IconButton onClick={handleDeleteClose} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody id="deavtivate-create-modal-body" className="fx-info-card fx-delete-info" >
                                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                                        <Grid item container justifyContent="center" xs={12}>
                                           <DeactivateIcon />
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                                            <Typography id="title-deavtivate-modal" variant="h3" >
                                                {props.header}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="deavtivate-modal-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="center" className="fx-modal-footer">

                                        <FxButton id={"cancel-" + buttonId + "-button"}
                                            title="Cancel"
                                            variant="contained"
                                            className="fx-button fx-button-cancel"
                                            onClick={handleDeleteClose}
                                        >
                                            Cancel
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme fx-button-delete"
                                            id={"deavtivate-" + buttonId + "-button"}
                                            type="submit"
                                        >
                                            {isLoading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                props && props.buttonName ? props.buttonName : 'Yes, Deactivate It'
                                            )}
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </FxCard>
                        </form>
                    </div>
                </div>
            </Modal>
        </>;
    });
