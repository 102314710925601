/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { ReactComponent as MenuDeleteIcon } from '../../../assets/svg/menu-delete-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { toTitleCase } from '../../Utils/CommonBaseClass';
import { Modal, Grid } from '@mui/material';
import { CancelSchedule } from '../Schedules/CancelSchedule';
import { ResendEmail } from '../Schedules/ResendEmail';
import { FxDelete } from '../../Data/FxDelete';
import { ReactComponent as EditIcon } from '../../../assets/svg/transaction-edit.svg';
import { ReactComponent as RefundIcon } from '../../../assets/svg/refund-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/svg/transaction-cancel.svg';
import { ReactComponent as CaptureIcon } from '../../../assets/svg/captureicon.svg';
import { FxButton } from '../../Action/FxButton';
import { RefundCollect } from '../Schedules/RefundCollect';
import { PauseSchedule } from '../Schedules/PauseSchedule';
import { ResumeSchedule } from '../Schedules/ResumeSchedule';
import { ReactComponent as ResumeIcon } from '../../../assets/svg/resume-icon.svg';
import { ReactComponent as PauseIcon } from '../../../assets/svg/pause-icon.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/svg/refresh-icon.svg';
import { ReactComponent as AdjustIcon } from '../../../assets/svg/adjust.svg';
import { ReactComponent as VoidIcon } from '../../../assets/svg/void.svg';
import { CaptureCollect} from '../Schedules/CaptureCollect';
import { getKey } from '../../../libs/utils/storageManager';
import { AdjustCollect } from '../Schedules/AdjustCollect';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { ReactComponent as StopIcon } from '../../../assets/svg/stop.svg';
import { StopCheck } from '../Schedules/StopCheck';
import { VoidCollect } from '../Schedules/VoidCollect';




/**
 * This component handles the actions to be performed on the schedule details
 */
export const ScheduleActions: React.FC<any> = React.memo(
    (props) => {
        const { scheduleData = props?.schedule, type, salesDetails } = props;
        const [openRefund, setOpenRefund] = useState(false);
        const schedule = getResourceName(props?.schedule?.resourceName);
        const scheduleId: any = props?.schedule?.id

        let options = [];

        const [cancelScheduleOpen, setCancelScheduleOpen] = useState(false);
        const [pauseScheduleOpen, setPauseScheduleOpen] = useState(false);
        const [resumeScheduleOpen, setResumeScheduleOpen] = useState(false);
        const [deleteScheduleOpen, setDeleteScheduleOpen] = useState(false);
        const [ResendEmailOpen, setResendEmailOpen] = useState(false);
        const [StopCheckOpen, setStopCheckOpen] = useState(false);
        const [captureScheduleOpen, setCaptureScheduleOpen] = useState(false);
        const [adjustScheduleOpen,setAdjustScheduleOpen] = useState(false);
        const [voidOpen, setVoidOpen] = useState(false);

        const { method, status, transactionClass } = props?.schedule;
        const isCheckProcessing = method === 'CHECK' && ['PROCESSING', 'IN_DELIVERY', 'DELIVERED'].includes(status) && transactionClass==='SEND';

        const [showVoid, setShowVoid] = useState(props?.salesDetails);
        //To hide void based on sale data.
        useEffect(()=>{
            const salesDetails : any[] = props?.salesDetails?.length > 0 && props?.salesDetails?.filter((ele:any)=> ele?.status !== 'VOIDED');
            setShowVoid(salesDetails)
        },[props?.salesDetails])
        /**
         * This function is used to extract the permissions item for recurring transaction.
        */

        const extractPermissionItemForRecurring =(type: any)=> {
            let permission: any = null;

            // This condition is only for collect recurring transaction
            if(props?.schedule?.action[0]?.transaction?.destination?.account?.id === getKey("selectedAccount")?.id){
                if (type === 'PAUSE') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_collect_pause')
                            break
                        case 'CARD':
                            permission = checkActionPermission('recurring_card_collect_pause')
                            break
                    }
                }
                else if (type === 'RESUME') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_collect_resume')
                            break
                        case 'CARD':
                            permission = checkActionPermission('recurring_card_collect_resume')
                            break
                    }
                }
                else if (type === 'CANCEL') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_collect_cancel')
                            break
                        case 'WIRE':
                            permission = checkActionPermission('recurring_card_collect_cancel')
                            break
                    }
                }
            }else{
                if (type === 'PAUSE') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_send_pause')
                            break
                        case 'WIRE':
                            permission = checkActionPermission('recurring_wire_send_pause')
                            break
                        case 'CHECK':
                            permission = checkActionPermission('recurring_check_send_pause')
                            break
                        case 'BOOK':
                            permission = checkActionPermission('recurring_book_send_pause')
                            break
                        case 'VIRTUAL_CARD':
                            permission = checkActionPermission('recurring_virtual_card_send_pause')
                            break
                        case 'INTERNATIONAL_WIRE':
                            permission = checkActionPermission('recurring_internationalWire_send_pause')
                            break
                    }
                }

                else if (type === 'RESUME') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_send_resume')
                            break
                        case 'WIRE':
                            permission = checkActionPermission('recurring_wire_send_resume')
                            break
                        case 'CHECK':
                            permission = checkActionPermission('recurring_check_send_resume')
                            break
                        case 'BOOK':
                            permission = checkActionPermission('recurring_book_send_resume')
                            break
                        case 'VIRTUAL_CARD':
                            permission = checkActionPermission('recurring_virtual_card_send_resume')
                            break
                        case 'INTERNATIONAL_WIRE':
                            permission = checkActionPermission('recurring_internationalWire_send_resume')
                            break
                    }
                }

                else if (type === 'CANCEL') {
                    switch (props?.schedule?.method) {
                        case 'ACH':
                            permission = checkActionPermission('recurring_ach_send_cancel')
                            break
                        case 'WIRE':
                            permission = checkActionPermission('recurring_wire_send_cancel')
                            break
                        case 'CHECK':
                            permission = checkActionPermission('recurring_check_send_cancel')
                            break
                        case 'BOOK':
                            permission = checkActionPermission('recurring_book_send_cancel')
                            break
                        case 'VIRTUAL_CARD':
                            permission = checkActionPermission('recurring_virtual_card_send_cancel')
                            break
                        case 'INTERNATIONAL_WIRE':
                            permission = checkActionPermission('recurring_internationalWire_send_cancel')
                            break
                    }
                }
            }

            return permission;
        }

        /**
        * function to map permissions
        */
        function checkActionPermission(data: any) {
            const permissionObject: any = {
                recurring_wire_send_pause: {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "PAUSE"
                },
                recurring_check_send_pause: {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "PAUSE"
                },
                recurring_book_send_pause: {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "PAUSE"
                },
                recurring_ach_send_pause: {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "PAUSE"
                },
                recurring_internationalWire_send_pause: {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "PAUSE"
                },
                recurring_virtual_card_send_pause: {
                    entity: "Recurring Transaction",
                    name: "Virtual Card Send",
                    operation: "PAUSE"
                },
                recurring_wire_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "CANCEL"
                },
                recurring_check_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "CANCEL"
                },
                recurring_book_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "CANCEL"
                },
                recurring_ach_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "CANCEL"
                },
                recurring_internationalWire_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "CANCEL"
                },
                recurring_virtual_card_send_cancel: {
                    entity: "Recurring Transaction",
                    name: "Virtual Card Send",
                    operation: "CANCEL"
                },
                recurring_wire_send_resume: {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "RESUME"
                },
                recurring_check_send_resume: {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "RESUME"
                },
                recurring_book_send_resume: {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "RESUME"
                },
                recurring_ach_send_resume: {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "RESUME"
                },
                recurring_internationalWire_send_resume: {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "RESUME"
                },
                recurring_virtual_card_send_resume: {
                    entity: "Recurring Transaction",
                    name: "Virtual Card Send",
                    operation: "RESUME"
                },
                recurring_wire_send_delete: {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "DELETE"
                },
                recurring_check_send_delete: {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "DELETE"
                },
                accounts_check_send_stop: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "STOP"
                },
                recurring_book_send_delete: {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "DELETE"
                },
                recurring_ach_send_delete: {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "DELETE"
                },
                recurring_internationalWire_send_delete: {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "DELETE"
                },
                recurring_wire_send_edit: {
                    entity: "Recurring Transaction",
                    name: "Wire Send",
                    operation: "EDIT"
                },
                recurring_check_send_edit: {
                    entity: "Recurring Transaction",
                    name: "Check Send",
                    operation: "EDIT"
                },
                recurring_book_send_edit: {
                    entity: "Recurring Transaction",
                    name: "Book Send",
                    operation: "EDIT"
                },
                recurring_ach_send_edit: {
                    entity: "Recurring Transaction",
                    name: "Ach Send",
                    operation: "EDIT"
                },
                recurring_internationalWire_send_edit: {
                    entity: "Recurring Transaction",
                    name: "International Wire Send",
                    operation: "EDIT"
                },
                recurring_ach_collect_resume: {
                    entity: "Recurring Transaction",
                    name: "Ach Collect",
                    operation: "RESUME"
                },
                recurring_card_collect_resume: {
                    entity: "Recurring Transaction",
                    name: "Card Collect",
                    operation: "RESUME"
                },
                recurring_ach_collect_pause: {
                    entity: "Recurring Transaction",
                    name: "Ach Collect",
                    operation: "PAUSE"
                },
                recurring_card_collect_pause:{
                    entity: "Recurring Transaction",
                    name: "Card Collect",
                    operation: "PAUSE"
                },
                recurring_ach_collect_cancel: {
                    entity: "Recurring Transaction",
                    name: "Ach Collect",
                    operation: "CANCEL"
                },
                recurring_card_collect_cancel:{
                    entity: "Recurring Transaction",
                    name: "Card Collect",
                    operation: "CANCEL"
                },
                recurring_ach_collect_delete: {
                    entity: "Recurring Transaction",
                    name: "Ach Collect",
                    operation: "DELETE"
                },
                recurring_card_collect_delete:{
                    entity: "Recurring Transaction",
                    name: "Card Collect",
                    operation: "DELETE"
                },
                transactions_card_collect_edit: {
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "EDIT"
                },
                transactions_check_collect_edit: {
                    entity: "Accounts",
                    name: "Check Collect",
                    operation: "EDIT"
                },
                transactions_ach_collect_edit: {
                    entity: "Accounts",
                    name: "Ach Collect",
                    operation: "EDIT"
                },
                transactions_wire_send_edit: {
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "EDIT"
                },
                transactions_book_send_edit: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "EDIT"
                },
                transactions_check_send_edit: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "EDIT"
                },
                transactions_internationalWire_send_edit: {
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "EDIT"
                },
                transactions_ach_send_edit: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "EDIT"
                }, transactions_card_collect_delete: {
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "DELETE"
                },
                transactions_check_collect_delete: {
                    entity: "Accounts",
                    name: "Check Collect",
                    operation: "DELETE"
                },
                transactions_ach_collect_delete: {
                    entity: "Accounts",
                    name: "Ach Collect",
                    operation: "DELETE"
                },
                transactions_wire_send_delete: {
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "DELETE"
                },
                transactions_book_send_delete: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "DELETE"
                },
                transactions_check_send_delete: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "DELETE"
                },
                transactions_ach_send_delete: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "DELETE"
                },
                transactions_internationalWire_send_delete: {
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "DELETE"
                },
                transactions_card_collect_view: {
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "View"
                },
                transactions_check_collect_view: {
                    entity: "Accounts",
                    name: "Check Collect",
                    operation: "View"
                },
                transactions_ach_collect_view: {
                    entity: "Accounts",
                    name: "Ach Collect",
                    operation: "View"
                },
                transactions_card_collect_cancel: {
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "CANCEL"
                },
                transactions_check_collect_cancel: {
                    entity: "Accounts",
                    name: "Check Collect",
                    operation: "CANCEL"
                },
                transactions_ach_collect_cancel: {
                    entity: "Accounts",
                    name: "Ach Collect",
                    operation: "CANCEL"
                },
                transactions_wire_send_view: {
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "View"
                },
                transactions_book_send_view: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "View"
                },
                transactions_check_send_view: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "View"
                },
                transactions_ach_send_view: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "View"
                },
                transactions_internationalWire_send_view: {
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "View"
                },
                transactions_wire_send_cancel: {
                    entity: "Accounts",
                    name: "Wire Send",
                    operation: "CANCEL"
                },
                transactions_book_send_cancel: {
                    entity: "Accounts",
                    name: "Book Send",
                    operation: "CANCEL"
                },
                transactions_check_send_cancel: {
                    entity: "Accounts",
                    name: "Check Send",
                    operation: "CANCEL"
                },
                transactions_ach_send_cancel: {
                    entity: "Accounts",
                    name: "Ach Send",
                    operation: "CANCEL"
                },
                transactions_internationalWire_send_cancel: {
                    entity: "Accounts",
                    name: "International Wire Send",
                    operation: "CANCEL"
                },
                transactions_virtual_card_send_view: {
                    entity: "Accounts",
                    name: "Virtual Card Send",
                    operation: "View"
                },
                transactions_virtual_card_resend: {
                    entity: "Accounts",
                    name: "Virtual Card Send",
                    operation: "RESEND"
                },
                transactions_virtual_card_cancel: {
                    entity: "Accounts",
                    name: "Virtual Card Send",
                    operation: "CANCEL"
                }
            }
            return permissionObject[data]
        }

        //Recurring require specific menu options
        if (schedule === "recurring") {
            if (props?.schedule?.status === 'PAUSED') {
                options.push({
                    id: schedule + '-resume-option',
                    btnText: 'Resume',
                    title: 'Resume This ' + toTitleCase(schedule),
                    icon: <ResumeIcon />,
                    permission: extractPermissionItemForRecurring('RESUME')
                })
            }
            else if (props?.schedule?.status === 'ACTIVE') {
                options.push({
                    id: schedule + '-pause-option',
                    btnText: 'Pause',
                    title: 'Pause This ' + toTitleCase(schedule),
                    icon: <PauseIcon />,
                    permission: extractPermissionItemForRecurring('PAUSE')
                })
            }

            options.push({
                id: schedule + '-cancel-option',
                btnText: 'Cancel',
                title: 'Cancel This ' + toTitleCase(schedule),
                icon: <CloseIcon />,
                permission: extractPermissionItemForRecurring('CANCEL')
            })

            const startDate = (props?.schedule?.transactionDetail?.startDate) + ' 23:59:59';
            const schstartDate = new Date(startDate).getTime();

            const now = new Date().getTime();

            //delete option available only for future start date and for non virtual card transaction
            if (schstartDate >= now && props?.schedule.method !== 'VIRTUAL_CARD') {
                options.push({
                    id: schedule + '-delete-option',
                    btnText: 'Delete',
                    title: 'Delete This ' + toTitleCase(schedule),
                    icon: <MenuDeleteIcon />,
                    permission: (props?.schedule?.action[0]?.transaction?.destination?.account?.id === getKey("selectedAccount")?.id) ? (props?.schedule.method === 'ACH' ? checkActionPermission('recurring_ach_collect_delete') : props?.schedule.method === 'CARD' ? checkActionPermission('recurring_card_collect_delete') : '') : (props?.schedule.method === 'WIRE' ? checkActionPermission('recurring_wire_send_delete') : props?.schedule.method === 'CHECK' ? checkActionPermission('recurring_check_send_delete') : props?.schedule.method === 'BOOK' ? checkActionPermission('recurring_book_send_delete') : props?.schedule.method === 'ACH' ? checkActionPermission('recurring_ach_send_delete') :props?.schedule.method === 'INTERNATIONAL_WIRE' ? checkActionPermission('recurring_internationalWire_send_delete') : '')
                })
            }

        } else {
            options = [
                {
                    id: schedule + '-cancel-option',
                    btnText: 'Cancel',
                    title: 'Cancel This ' + toTitleCase(schedule),
                    icon: <CloseIcon />
                },
                {
                    id: schedule + '-delete-option',
                    btnText: 'Delete',
                    title: 'Delete This ' + toTitleCase(schedule),
                    icon: <MenuDeleteIcon />
                }
            ]
        }

        /**
         * Method handles the resourceName
         * @param data : event parameter
         */
        function getResourceName(data: any) {
            if (data === 'recurringTransaction') {
                return 'recurring'
            }

            return data;
        }

        /**
         * Method handles the onclick event of the menu items
         * @param event : event parameter
         */
        const handleChange = (event: any) => {
            const firstWord = event.split(' ')[0];
            if (firstWord === 'Cancel') {
                setCancelScheduleOpen(true);
            }
            if (firstWord === 'Delete') {
                setDeleteScheduleOpen(true);
            }
            if (firstWord === 'Pause') {
                setPauseScheduleOpen(true);
            }
            if (firstWord === 'Resume') {
                setResumeScheduleOpen(true);
            }
        }
        /**
         * Method handles the onclick event of the schedule actions items
         * @param event : event parameter
         */
        const handleCancelSchedule = () => {
            setCancelScheduleOpen(true);
        }

        
        /**
         * Method to handle capture schedule modal
         */
        const handleCaptureSchedule = () =>{
            setCaptureScheduleOpen(true);
        }

        /**
         * Method to handle adjust schedule modal
         */
        const handleAdjustSchedule = () =>{
            setAdjustScheduleOpen(true);
        }


         /**
         * Method to handle void schedule modal
         */
         const handleVoidSchedule = () =>{
            setVoidOpen(true);
        }
        

        




        /**
         * Method handles the closing of the modal
         */
        const cancelScheduleClose = () => {
            setCancelScheduleOpen(false);
        };

        /**
         * Method handles the close event of the Pause Recurring modal
         */
        const PauseScheduleClose = () => {
            setPauseScheduleOpen(false);
        };

        /**
         * Method handles the close event of the Resume Recurring modal
         */
        const ResumeScheduleClose = () => {
            setResumeScheduleOpen(false);
        };

        /**
         * Method handles the close event of the FxDelete component
         */
        const deleteScheduleClose = () => {
            setDeleteScheduleOpen(false);
        };
        /**
         * Method handles the redirection to edit page
         */
        const handleRedirect = () => {
            props.redirect();
        }

        /**
       * Method handles to opne and close resend email modal
       */
        const onResendEmailModalToggle = () => {
            setResendEmailOpen(!ResendEmailOpen);
        }

        /**
       * Method handles to open and close stop check modal
       */
        const onStopCheckModalToggle = () => {
            setStopCheckOpen(!StopCheckOpen);
        }

        /**
         * Method to open refund schedule modal
         */
        const handleOpen = () => {
            setOpenRefund(true);
        };

         /**
         * Method to close refund schedule modal
         */
        const handleRefundClose = () => {
            setOpenRefund(false);
        };

         /**
         * Method to close void schedule modal
         */
        const handleVoidClose = () => {
            setVoidOpen(false);
        };

         /**
         * Method to open capture auth schedule modal
         */
        const handleCaptureClose = () => {
            setCaptureScheduleOpen(false);
        };

         /**
         * Method to close Adjust schedule modal
         */
        const handleAdjustClose=()=>{
            setAdjustScheduleOpen(false);
        }


        let hideEdit : boolean = false;

        if(((props.type === 'collect' && (props?.schedule.method === 'CHECK' || props?.schedule?.source?.card?.cardNumberLast4))
            || ( props?.schedule.method === 'VIRTUAL_CARD' && (props.type === 'send' || props.type === "recurring")))){
            hideEdit = true;
        }
        if (props?.schedule?.method === 'BOOK' && props?.schedule?.action?.[0]?.transaction?.source?.account?.id?.toString() !== getKey('selectedAccount')?.id?.toString() && props?.schedule?.source?.account?.id?.toString() !== getKey('selectedAccount')?.id?.toString()) {
            hideEdit = true;
        }
        /**
         *
         * @returns function to check permision based on method
         */
        function checkEditPermissions(type: any) {
            let data: any;
            if (props.type === 'collect') {
                switch (props?.schedule?.method) {
                    case 'ACH':
                        data = type === 'receipt' ? checkActionPermission('transactions_ach_collect_view') : type === 'delete' ? checkActionPermission('transactions_ach_collect_delete') : type === 'cancel' ? checkActionPermission('transactions_ach_collect_cancel'):checkActionPermission('transactions_ach_collect_edit')
                        break
                    case 'CHECK':
                        data = type === 'receipt' ? checkActionPermission('transactions_check_collect_view') : type === 'delete' ? checkActionPermission('transactions_check_collect_delete') : type === 'cancel' ? checkActionPermission('transactions_check_collect_cancel'):checkActionPermission('transactions_check_collect_edit')
                        break
                    case 'CARD':
                        data = type === 'receipt' ? checkActionPermission('transactions_card_collect_view') : type === 'delete' ? checkActionPermission('transactions_card_collect_delete') : type === 'cancel' ? checkActionPermission('transactions_card_collect_cancel'):checkActionPermission('transactions_card_collect_edit')
                        break
                }
            }
            else if (props.type === 'send') {
                switch (props?.schedule?.method) {
                    case 'ACH':
                        data = type === 'receipt' ? checkActionPermission('transactions_ach_send_view') : type === 'delete' ? checkActionPermission('transactions_ach_send_delete') :type === 'cancel' ? checkActionPermission('transactions_ach_send_cancel'): checkActionPermission('transactions_ach_send_edit')
                        break
                    case 'WIRE':
                        data = type === 'receipt' ? checkActionPermission('transactions_wire_send_view') : type === 'delete' ? checkActionPermission('transactions_wire_send_delete') : type === 'cancel' ? checkActionPermission('transactions_wire_send_cancel'):checkActionPermission('transactions_wire_send_edit')
                        break
                    case 'CHECK':
                        data = type === 'receipt' ? checkActionPermission('transactions_check_send_view') : type === 'delete' ? checkActionPermission('transactions_check_send_delete') :type === 'cancel' ? checkActionPermission('transactions_check_send_cancel'): checkActionPermission('transactions_check_send_edit')
                        break
                    case 'BOOK':
                        data = type === 'receipt' ? checkActionPermission('transactions_book_send_view') : type === 'delete' ? checkActionPermission('transactions_book_send_delete') :type === 'cancel' ? checkActionPermission('transactions_book_send_cancel'): checkActionPermission('transactions_book_send_edit')
                        break
                    case 'VIRTUAL_CARD':
                        data = type === 'resend' ? checkActionPermission('transactions_virtual_card_resend') : checkActionPermission('transactions_virtual_card_cancel')
                        break
                    case 'INTERNATIONAL_WIRE':
                        data = type === 'resend' ? checkActionPermission('transactions_internationalWire_resend') : checkActionPermission('transactions_internationalWire_cancel')
                        break
                }
            }
            else if (schedule === 'recurring') {
                switch (props?.schedule?.method) {
                    case 'ACH':
                        data = checkActionPermission('recurring_ach_send_edit')
                        break
                    case 'WIRE':
                        data = checkActionPermission('recurring_wire_send_edit')
                        break
                    case 'CHECK':
                        data = checkActionPermission('recurring_check_send_edit')
                        break
                    case 'BOOK':
                        data = checkActionPermission('recurring_book_send_edit')
                        break
                    case 'INTERNATIONAL_WIRE':
                        data = checkActionPermission('recurring_internationalWire_send_edit')
                        break
                }
            }
            return data
        }

        /**
         * check the neccessary conditions for allowing the capture button
         */
        const allowCaptureButton = () => {
           return  (((scheduleData?.status === 'UNCAPTURED' || scheduleData?.status === 'PARTIALLY_CAPTURED') && scheduleData?.method === 'CARD' && type === 'collect' && scheduleData?.isCaptured === false  && scheduleData?.pendingCaptureAmount !== '0.00' &&  scheduleData?.isAutoCapture === false ))
        }

        /**
         * check the neccessary conditions for allowing the adjust button
         */
        const allowAdjustButton = () => {
            return  (((scheduleData?.status === 'UNCAPTURED' || scheduleData?.status === 'PARTIALLY_CAPTURED' || scheduleData?.status === 'CAPTURED' || scheduleData?.status === 'APPROVED') && scheduleData?.method === 'CARD' && type === 'collect' && ((scheduleData?.isAutoCapture === false && (salesDetails?.length === 0 || showVoid?.length === 0)) || (scheduleData?.isAutoCapture === true)) ))
        }

        /**
         * check the neccessary conditions for allowing the void button
         */
        const allowVoidButton = () => {
            return  (((scheduleData?.status === 'UNCAPTURED' || scheduleData?.status === 'PARTIALLY_CAPTURED' || scheduleData?.status === 'CAPTURED' || scheduleData?.status === 'APPROVED') && method === 'CARD' && type === 'collect' && ((scheduleData?.isAutoCapture === false && (salesDetails?.length === 0 || showVoid?.length === 0)) || (scheduleData?.isAutoCapture === true)) ) || (props?.schedule.type === 'REFUND' && props?.schedule?.status !== 'COMPLETED' && props?.schedule?.status !== 'VOIDED'))
        }

        return (
            <Grid item container id={props.id + '-container'} className="fx-view-action fx-schedule-actions">
                {(props?.schedule.status === 'PENDING' || props?.schedule.status === 'SCHEDULED' || props?.schedule?.resourceName === 'recurringTransaction') && !hideEdit && props.schedule?.transactionClass !== 'SYSTEM_FEE' && props.schedule?.type !== 'EXTERNAL' && <Grid item title={'Edit'}><FxButton id={schedule + "-details-card-edit-button"} permissions={checkEditPermissions('edit')} variant="contained" className="fx-button  fx-button-action " startIcon={<EditIcon />} onClick={handleRedirect}>Edit</FxButton></Grid>}
                {((props?.schedule.status === 'PENDING' || props?.schedule.status === 'SCHEDULED' || (props?.schedule.method === 'VIRTUAL_CARD' &&  props?.schedule.status === 'PROCESSING')) && (props?.type === 'send' || props?.type === 'collect' ))&& props.schedule?.transactionClass !== 'SYSTEM_FEE' && <Grid item title={'Cancel'}><FxButton id={schedule + "-details-card-cancel-button"} permissions={checkEditPermissions('cancel')} variant="contained" className="fx-button  fx-button-action " startIcon={<CancelIcon />} onClick={handleCancelSchedule}>Cancel</FxButton></Grid>}
                {((props?.schedule.status === 'PENDING' || props?.schedule.status === 'SCHEDULED') && (props?.type === 'send' || props?.type === 'collect') && props?.schedule.method !== 'VIRTUAL_CARD')&& props.schedule?.transactionClass !== 'SYSTEM_FEE' && <Grid item title={'Delete'}><FxDelete id={scheduleId} permissions={checkEditPermissions('delete')} entity={schedule} buttonTitle='Delete' header={"Are you sure you want to Delete this transaction " + scheduleId} description="You will not be able to recover it" redirectUrl={'/transactions'} menuType={false} /></Grid>}
                { allowCaptureButton() && <Grid item title={'Capture'}><FxButton id={schedule + "-details-card-capture-button"} variant="contained" className="fx-button  fx-button-action " startIcon={<CaptureIcon />} onClick={handleCaptureSchedule}>CAPTURE</FxButton></Grid>}
                { allowAdjustButton() && <Grid item title={'Adjust'}><FxButton id={schedule + "-details-card-adjust-button"} variant="contained" className="fx-button  fx-button-action " startIcon={<AdjustIcon />} onClick={handleAdjustSchedule}>ADJUST</FxButton></Grid>}
                { allowVoidButton() && <Grid item title={'Void'}><FxButton id={schedule + "-details-card-void-button"} variant="contained" className="fx-button  fx-button-action " startIcon={<VoidIcon />} onClick={handleVoidSchedule}>VOID</FxButton></Grid>}
                {(props?.schedule?.resourceName !== 'recurringTransaction' && props?.schedule.method === 'VIRTUAL_CARD') &&
                    <Grid item title={'RESEND EMAIL'}><FxButton id={schedule + "-details-card-resend-email-button"}
                        permissions={checkEditPermissions('resend')}
                        variant="contained"
                        className="fx-button  fx-button-action " startIcon={<RefreshIcon />}
                        onClick={onResendEmailModalToggle}>RESEND EMAIL</FxButton></Grid>
                }

                {isCheckProcessing && (
                    <Grid item title={'STOP'}>
                        <FxButton
                        id={schedule + "-details-card-stop-check-button"}
                        permissions={checkActionPermission('accounts_check_send_stop')}
                        variant="contained"
                        className="fx-button  fx-button-action "
                        startIcon={<StopIcon />}
                        onClick={onStopCheckModalToggle}
                        > STOP </FxButton>
                    </Grid>
                    )}

                {((schedule === 'recurring' && (props?.schedule.status === 'ACTIVE' || props?.schedule.status === 'PAUSED')) && (props?.type !== 'send' || props?.type !== 'collect' ))  &&
                  options.map((option: any) => (
                    <Grid item key={option}>
                      <FxButton title={option.btnText} id={option.id} permissions={option?.permission} variant="contained" className="fx-button fx-button-action fx-svg-theme" startIcon={option.icon} onClick={() => { handleChange(option.title) }}>
                          {option.btnText}
                      </FxButton>
                    </Grid>
                    ))
                }
                {props?.schedule?.type !== 'REFUND' && props?.schedule?.status === 'COMPLETED' && props?.schedule?.isAutoCapture && props?.schedule?.method === 'CARD' && props.schedule?.transactionClass !== 'SYSTEM_FEE' && <Grid item title={'Refund'}>
                    <FxButton id={"refund-collect-button"} permissions={checkActionPermission('transactions_card_collect_edit') || checkActionPermission('transactions_check_collect_edit') || checkActionPermission('transactions_ach_collect_edit') || checkActionPermission('transactions_wire_send_edit') || checkActionPermission('transactions_book_send_edit') || checkActionPermission('transactions_check_send_edit') || checkActionPermission('transactions_ach_send_edit')} variant="contained" className="fx-button fx-button-action " startIcon={<RefundIcon />} onClick={handleOpen}>Refund</FxButton>
                </Grid>}


                <Modal
                    style={{ overflow: 'scroll' }}
                    open={ResendEmailOpen}
                    onClose={onResendEmailModalToggle}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <ResendEmail id='resend-email-modal' schedule={schedule} scheduleId={scheduleId} close={onResendEmailModalToggle} />
                </Modal>

                <Modal
                    style={{ overflow: 'scroll' }}
                    open={StopCheckOpen}
                    onClose={onStopCheckModalToggle}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <StopCheck id='stop-check-modal' schedule={schedule} scheduleId={scheduleId} close={onStopCheckModalToggle} />
                </Modal>
                
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={cancelScheduleOpen}
                    onClose={cancelScheduleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <CancelSchedule id={'Cancel-Schedule-collect'} schedule={schedule} scheduleId={scheduleId} close={cancelScheduleClose} />
                </Modal>
                {deleteScheduleOpen && <FxDelete id={scheduleId} close={deleteScheduleClose} open={deleteScheduleOpen} entity={schedule} header={"Are you sure you want to Delete the " + toTitleCase(schedule) + " " + scheduleId} description="You cannot undo this action" redirectUrl={ '/'+schedule} />}

                {/*         Pause modal starts here          */}
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={pauseScheduleOpen}
                    onClose={PauseScheduleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <PauseSchedule id={'Pause-Schedule-collect'} schedule={schedule} scheduleId={scheduleId} close={PauseScheduleClose} />
                </Modal>

                {/*         Resume modal starts here          */}
                <Modal
                    style={{ overflow: 'scroll' }}
                    open={resumeScheduleOpen}
                    onClose={ResumeScheduleClose}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <ResumeSchedule id={'Resume-Schedule-collect'} schedule={schedule} scheduleId={scheduleId} close={ResumeScheduleClose} />
                </Modal>

                {/*         Refund modal starts here          */}
                <FxModalGeneric
                    id={"void-schedule-collect"}
                    open={openRefund}
                    onClose={handleRefundClose}
                    className={'fx-modal-small2medium'}
                    component={RefundCollect}
                    componentProps={{ scheduleId: scheduleId}}
                    title={'Are you sure you want to Refund?'}
                >
                </FxModalGeneric>
                {/*         Refund modal ends here          */}

                {/*         Void modal starts here          */}
                <FxModalGeneric
                    id={"void-schedule-collect"}
                    open={voidOpen}
                    onClose={handleVoidClose}
                    componentProps={{ schedule: schedule, scheduleId: scheduleId, data: props?.schedule, close: { handleCaptureClose } }}
                    className={'fx-modal-small2medium'}
                    component={VoidCollect}
                >
                </FxModalGeneric>


                {/*         Void modal ends here          */}

                {/*    Capture modal Start here */}
                <FxModalGeneric
                    id={"capture-schedule-collect"}
                    open={captureScheduleOpen}
                    onClose={handleCaptureClose}
                    componentProps={{ schedule: schedule, scheduleId: scheduleId, data: props?.schedule, close: { handleCaptureClose }, showVoid: showVoid, salesDetails: salesDetails }}
                    className={'fx-modal-small2medium'}
                    component={CaptureCollect}
                >
                </FxModalGeneric>
                {/* Capture modal ends here  */}


                {/*    Adjust modal Start here */}



                <FxModalGeneric
                    id={"adjust-schedule-collect"}
                    open={adjustScheduleOpen}
                    onClose={handleAdjustClose}
                    componentProps={{ schedule: schedule, scheduleId: scheduleId, data: props?.schedule, close: handleAdjustClose }}
                    className={'fx-modal-small2medium'}
                    component={AdjustCollect}
                >
                </FxModalGeneric>
                 {/*    Adjust modal ends here */}

            </Grid>
        );
    })
