import React, { PropsWithChildren } from "react";
import { InitializeComponent } from "../Utils/ComponentInitializer";
import { Logger } from "../../libs/utils/logger";
const cssFxCardBody = 'fx-card-body';

Logger.debug("FxCardBody.tsx", "Fx Card Body initializing")

/**
 * <FxCardBody> component 
 * using in body area
 * @param props 
 */
const FxCardBody: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = props => {
  ({ props } = InitializeComponent(props));

  return (
    <div id={props.id} className={`${cssFxCardBody} ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  )
};

export default FxCardBody;


