/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Logger } from "../../../libs/utils/logger";
import {
  IDataGridProps,
  IFilterColumns,
  IFilterFields,
} from "../../../types/common.interfaces";
import { truncateChars, updateComponentParams } from "../../Utils/CommonBaseClass";
import { Grid, Modal } from "@mui/material";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import {
  FxPrimaryFilter,
  IPrimaryFilterData,
} from "../../Data/FxPrimaryFilter";
import FxCardBody from "../../Container/FxCardBody";
import { DataGrid } from "../../Data/DataGrid";
import { Filter } from "../../Data/Filter";
import { PolicyQueueDetailModal } from "./PolicyQueueDetailModal";
import { ApprovalConfirmation } from "./ApprovalConfirmation/ApprovalConfirmation";
import { ReactComponent as RightUpArrow } from '../../../assets/svg/arrow-right-topbig.svg'
import { FxPaymentModeStatus } from "../../Input/FxPaymentModeStatus/FxPaymentModeStatus";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import { useDispatch } from "react-redux";

Logger.debug(
  "ApprovalsQueueListing.tsx",
  "Add Approval Policy Queue Listing initializing"
);

/**
 * Component: ApprovalsQueueListing
 * Usage: Listing of the Approval Policy Queue
 */
export const ApprovalsQueueListing: React.FC<any> = React.memo((props) => {
  const [open, setOpen] = useState(false);
  const [openDetailViewModal, setOpenDetailViewModal] = useState(false);
  const [data, setData] = useState();
  const [pendingApproval, setPendingApproval] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('');
  let approvalRowDataId: any;
  const dispatch = useDispatch();

  /**
   * Method to transform data
   * @param data : approval escalation data
   */
  function transformApprovalListData(data: any) {
    try {
      const newData: any = [];
      data?.forEach((item: any, i: number) => {
        newData[i] = { ...data[i] };
        approvalRowDataId = item?.id;
        newData[i].icon = <div className={"fx-passport-debit-credit-svg"} ><RightUpArrow /></div>;
        newData[i].transactionDate = item?.entity?.transactionDate;
        newData[i].transactionId = item?.entity?.id;
        newData[i].purpose = <><span title={item?.entity?.purpose}>{` ${item?.entity?.purpose ? truncateChars(item?.entity?.purpose, 35) : ''}`} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'approval-queue-method'} value={item?.entity?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus></>;
        newData[i].approvalStatus = item.status;
        newData[i].amount = <FxCurrencyView value={item?.entity?.amount} showMinus prefix={"$"}/>;
        newData[i].age = item?.age;
        newData[i].createdBy = <><span title={item?.entity?.createdBy?.username}>{` ${item?.entity?.createdBy?.username ? truncateChars(item?.entity?.createdBy?.username, 20) : ''}`}</span></>;
        newData[i].createdOn = <FxDateTimeView value={item?.entity?.createdOn} format={'MMM DD, YYYY'}/>;
        newData[i].lastUpdatedBy = <><span title={item?.lastUpdatedBy?.username}>{` ${item?.lastUpdatedBy?.username ? truncateChars(item?.lastUpdatedBy?.username, 20) : ''}`}</span></>;
        newData[i].lastUpdatedOn = <FxDateTimeView value={item?.lastUpdatedOn} format={'MMM DD, YYYY'}/>;
        newData[i].lastUpdatedOnValue = item?.lastUpdatedOn;
        newData[i].lastUpdatedByValue = item?.lastUpdatedBy?.username;
      });
      return newData;
    } catch (e) {
      Logger.error("Approval Queue List Data Transformer", "error", e);
      return false;
    }
  }

  /**
   * Approval Queue list columns
   */
  const ApprovalQueueColumns: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        "enable": true,
        "fieldName": "icon",
        "type": "fri://fieldtype/custom",
        "default": true,
        "style": { "width": "4.5rem", "paddingLeft": "0 !important" },
        "dataStyle": { "textAlign": "left !important" }
      },
      {
        label: "TXN DATE",
        enable: true,
        fieldName: "transactionDate",
        type: "fri://fieldtype/date",
        default: true,
        "style": { "width": "10rem" }
      },
      {
        label: "TXN ID",
        enable: true,
        fieldName: "transactionId",
        type: "fri://fieldtype/default",
        default: true,
        style: { "width": "11rem" }
      },
      {
        label: "PURPOSE",
        enable: true,
        fieldName: "purpose",
        type: "fri://fieldtype/custom",
        default: true,
      },
      {
        label: "STATUS",
        enable: true,
        fieldName: "approvalStatus",
        "type": "fri://fieldtype/status",
        default: true,
      },
      {
        label: "AMOUNT",
        enable: true,
        fieldName: "amount",
        type: "fri://fieldtype/custom",
        default: true,
      },
      {
        label: "AGE (DAYS)",
        enable: true,
        fieldName: "age",
        type: "fri://fieldtype/custom",
        default: true,
        style: { "width": "8rem" }
      },
    ],
  };

  if(props.approvalQueue === 'PENDING') {
    ApprovalQueueColumns.columns.push({
        label: "CREATED BY",
        enable: true,
        fieldName: "createdBy",
        type: "fri://fieldtype/custom",
        default: true,
      },
      {
        label: "CREATED ON",
        enable: true,
        fieldName: "createdOn",
        type: "fri://fieldtype/custom",
        default: true,
      },)
  } else {
    ApprovalQueueColumns.columns.push({
        label: "Last Updated By",
        enable: true,
        fieldName: "lastUpdatedBy",
        type: "fri://fieldtype/custom",
        default: true,
      },
      {
        label: "Last Updated On",
        enable: true,
        fieldName: "lastUpdatedOn",
        type: "fri://fieldtype/custom",
        default: true,
      })
  }

  /**
   * Method to redirect to the transaction details page or open the popup Modal based upon the approval status
   */
  function handleRowClick(event: any, data: any) {
    setData(data);
    setPendingApproval(data.approvalStatus === "PENDING");
    if (data.approvalStatus === "PENDING") {
      setOpen(true);
    } else {
      setOpenDetailViewModal(true);
    }
  }

  /**
  * useEffect to reset the filter while unmount the page
  */
  useEffect(() => () => {
    updateComponentParams(dispatch, 'approvals-queue-filter', { resetFilter: true });
  }, [dispatch]);

  // filter fields
  const filterFields: IFilterFields = {
    fields: [
      {
        label: "Created On",
        toLabel: "To",
        fieldName: "createdOn",
        type: "fri://fieldtype/daterange",
        data: [],
        defaultValue: false,
      },
      {
        label: "Last Updated On",
        toLabel: "To",
        fieldName: "lastUpdatedOn",
        type: "fri://fieldtype/daterange",
        data: [],
        defaultValue: false,
      },
    ],
  };

  //datagrid props
  const datagridProps: IDataGridProps = {
    id: "approvals-queue-list",
    column: ApprovalQueueColumns,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  //primary filters data
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: "TXN ID", value: "transaction.id", operator: "eq", },
  ];


  /**
   * to handle modal close
   */
  function handleClose() {
    setOpen(false);
  }

  /**
   * to handle modal close
   */
  function handleDetailModalClose() {
    dispatch({
      type: "DATA_API_PARAM_CHANGED",
      payload: { id: "approvals-queue-list", source: props.src },
    });
    setOpenDetailViewModal(false);
  }

  /**
   * to open detail modal after approval
   */
  function openDetailModal() {
    setOpenDetailViewModal(true);
  }

  return (
    <>
      <Grid container xs={12} className="fx-layout-list">
        <FxCard id="approvals-queue-card" className="fx-theme-passport">
          <FxCardHeader
            id="approvals-queue-card-header"
            className="fx-card-header"
          >
            <Grid
              id="approvals-queue-card-header-first-grid"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid item className="fx-approval-primary-filter">
                <FxPrimaryFilter
                  id="approvals-queue-primary-filter"
                  permissions={""}
                  name="approvals-queue-primary-filter"
                  data={primaryFilterData}
                  dataGridProps={datagridProps}
                  defaultSelection="transaction.id"
                />
              </Grid>
              <Grid
                id="approvals-queue-filter-grid"
                item
                container
                spacing={2}
                className="fx-action-block fx-inline-flex"
              >
                  <Filter
                    id="approvals-queue-filter"
                    permissions={""}
                    field={filterFields}
                    hiddenParameter={['status']}
                    dataGridProps={datagridProps}
                  />
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="approvals-queue-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="approvals-queue-card-body-grid" item xs={12}>
              <DataGrid
                id="approvals-queue-list"
                permissions={""}
                rowClick={handleRowClick}
                tableClass="fx-table-passport"
                dataTransformer={transformApprovalListData}
                source={props.src}
                column={ApprovalQueueColumns}
                pagination={true}
                pageNumber={1}
                pageSize={25}
                resultPerPage={25}
              />
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
      <Modal
        style={{ overflow: "scroll" }}
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={false}
        className="fx-modal fx-modal-small"
      >
        <ApprovalConfirmation
          escalationId={approvalRowDataId}
          data={data}
          id={"approval-confirmation-modal"}
          close={handleClose}
          openDetailModal={openDetailModal}
          setApprovalStatus={setApprovalStatus}
        />
      </Modal>

      <Modal
        style={{ overflow: "scroll" }}
        open={openDetailViewModal}
        onClose={handleDetailModalClose}
        disableEnforceFocus={true}
        disableAutoFocus={false}
        className="fx-modal fx-modal-small"
      >
        <PolicyQueueDetailModal
          escalationId={approvalRowDataId}
          data={data}
          id={"approval-detail-confirmation-modal"}
          close={handleDetailModalClose}
          isPendingApproved={pendingApproval}
          approvalStatus={approvalStatus}
        />
      </Modal>
    </>
  );
});
