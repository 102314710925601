import React from 'react'
import { Grid, Typography } from '@mui/material';
import { removeUnderScoreConvertTitleCase } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReviewAgreementAccordion } from './ReviewAgreementAccordion';
import { FxApplicationBanner } from './FxApplicationBanner';

/**
 * This component handles the list of fee details list
 */
export const ReviewAgreementList: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));    

    const {reviewAggreement} = props;

    return (
        <Grid id="review-agreement-list-Home-Grid" item xs={12} className={'fx-treasure-review-agreement-list'}>
            <Grid container id="review-agreement-list-Home-Card">
                <Grid item xs={12} marginY={'1rem'}>
                    <FxApplicationBanner text='Opt in for Treasure Investment Account!' />
                </Grid>
                <Grid item xs={12} id="fee-details-list-Home-Card-Body" className="fx-review-agreement-list ">
                    {reviewAggreement && reviewAggreement.agreement &&
                        <>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography className="fx-review-agreement-list-headerText"
                                        variant="h4"> {removeUnderScoreConvertTitleCase(reviewAggreement.groupName)}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} className='fx-review-agreement-section'>
                                {reviewAggreement.agreement?.map((obj: any, index: number) => {
                                    return (
                                        <ReviewAgreementAccordion
                                            agreementIndex={index}
                                            treasureReviewAggreement={reviewAggreement}
                                            id={'review-agreement-accordion-' + index}
                                            agreementInfo={obj}
                                            agreementDoc={props.agreementDoc}
                                        />
                                    )
                                })}
                            </Grid>
                        </>
                    }
                    
                    {reviewAggreement === undefined && <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography align='center' className='fx-no-data'> No data to show </Typography>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
})
