import React from 'react'; // we need this to make JSX compile
import { useParams } from "react-router-dom";
import { CreateDebitCardPhysicalPage } from './CreateDebitCardPhysical';
/**
 * This component handles the rendering of the ScheduleCards
 */
export const DebitCardRequest = () => {
        interface ScheduleParams {
            id: string
            type: string;
        }

        const params = useParams<ScheduleParams>();
        const id = params.id

        const user_auth_source = {
            url: "authorizedUser/id/" + id,
            method: "GET"
        }

        return <CreateDebitCardPhysicalPage id={"create-debitcard-card1" + id} source={user_auth_source}></CreateDebitCardPhysicalPage>

    }
