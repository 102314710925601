/**
 * Component: AddCoOwner
 * Usage: For add new Co-Owner
 */
import React, { useEffect } from 'react';
import { Divider, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import usa_state from '../../Utils/usa_states.json'
import { handleKeyDownChange, handleZipChange, handleAddressChange, renderError, setZipValidationOptions, setPhoneValidationOptions, convertToPhoneRequestFormat, renderPhoneError, clean, setEmailValidationOptions, setSsnValidationOptions } from '../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxTextEditSSN } from '../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { processAPIResponse, useIsMobileScreen } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { getKey } from '../../../libs/utils/storageManager';
const httpClient = HttpClient.getClient();

export const AddCoOwner: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control, clearErrors, setError } = useForm();
        const isSmallScreen = useIsMobileScreen();
        const history = useHistory();
        /**
         * useEffect to load title while loading page
         */
        useEffect(() => {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Owner', backButton: '/co-owner/profile' } } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])        

        /**
         * Method handles the submit form
         *  */
        async function onSubmit(data: any) {
            const request = setUpdateRequest(data);
            let apiStatus = await createCoOwner(clean(request));
            let status: any = processAPIResponse(apiStatus)
            let redirectPage: any;
            let successMessage = 'Co-Owner added Successfully!';
            if (status.status) {
                redirectBackToPage(JSON.parse(apiStatus?.config?.data))
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push(redirectPage)
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }

        }
        /**
  * Method to call api  for create owner
  * @param payloadData : request payload
  */
        async function createCoOwner(payloadData: any) {
            try {
                const data: any = await httpClient.post(`customer/id/${getKey('customerId')}`, payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("AddCoOwner.tsx -> createCoOwner", "error", err);
                return err;
            }
        }
        /***
         * Redirect page
         */
        const redirectBackToPage = (data: any) => {
            history.push(`/thanks/transactions/co-owner/${data?.owners[0]?.firstName} ${data?.owners[0]?.lastName}`);
        }
        /**
         * Method handles the click operation for going back to co-owner list screen
         */
        const handleGoBackClick = () => {
            history.push('/co-owner/profile')
        }

        /**
          * Method for to set request
          * @param data 
          * @returns 
          */
        function setUpdateRequest(data: any) {
            let request: any = {}
            let mailingAddress = [{
                "isPrimary":true,
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'] && data['addressLine2'] !== '' ? data['addressLine2'] : undefined,
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip']
            }];
            request['owners'] = [
                {
                    isPrimaryOwner: false,
                    firstName: data['firstName'],
                    lastName: data['lastName'],
                    middleName: data['middleName'] && data['middleName'] !== '' ? data['middleName'] : undefined,
                    dob: data['dob'] && data['dob'] !== '' ? data['dob'] : undefined,
                    ssn: data['ssn'] && data['ssn'] !== '' ? data['ssn'] : undefined,
                    email: data['email'],
                    portalAccess: {
                        grantAccess: true
                    },
                    mobilePhone: convertToPhoneRequestFormat(data['mobilePhone']),
                    mailingAddress: mailingAddress
                }
            ]
            return request
        }


        /**
         * Addressline1 validation for length
         */
        const setAddressLine1Validation = {
            required: true,
            minLength: {
                value: 4,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            },
            maxLength: {
                value: 40,
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            }
        }
        return (
            <Grid container id="add-co-owner-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="add-co-owner-first-grid" item xs={12} sm={12} spacing={2}>
                    <Grid id="add-co-owner-sub-grid" container justifyContent="center" alignItems="center" className="flex column" >
                        <Grid id="add-co-owner-second-grid" item xs={12} sm={isSmallScreen ? 8 : 6}>
                            <div className="fx-form-edit-profile flex column">
                                <Grid container id='add-co-owner' xs={12} className='fx-co-owner-add'>
                                    <FxCard id="add-co-owner-form-card" className={isSmallScreen ? "fx-theme-passport fx-co-owner-add-border" : "fx-theme-passport"}>
                                        <div className="fx-form-edit-profile">
                                            <form id="add-co-owner-form" onSubmit={handleSubmit(onSubmit)}>
                                                <FxCardBody id="add-co-owner-form-card-body" className="fx-info-card" >
                                                    <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                                        <Grid item xs={12} sm={12}>
                                                            <FxLabelView id="co-owner-details-label-basic-info" className="" >BASIC INFORMATION</FxLabelView>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="add-co-owner-form-card-first-name" label="First Name *" name="firstName" variant="outlined" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FxTextEdit register={{ ...register("middleName") }} className={errors.middleName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="add-co-owner-form-card-middle-name" label="Middle Name" name="middleName" variant="outlined" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FxTextEdit register={{ ...register("lastName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="add-co-owner-form-card-last-name" label="Last Name *" name="lastName" variant="outlined" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions(true)} id="add-co-owner-form-card-email" label="Email *" name="email" variant="outlined" />
                                                            <div className={'error-message'}>
                                                                {errors.email && renderError(errors.email)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={6} xs={12}>
                                                            <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="add-co-owner-form-card-dob" label="Date of Birth *" name="dob" variant="outlined"  setValue={setValue} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FxTextEditSSN
                                                                register={{ ...register("ssn") }}
                                                                className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                                error={errors?.ssn}
                                                                control={control}
                                                                rules={setSsnValidationOptions(true)}
                                                                id="add-co-owner-form-card-ssn"
                                                                label="SSN *"
                                                                placeholder="XXX-XX-XXXX"
                                                                name="ssn"
                                                                variant="outlined"
                                                                setValue={setValue}
                                                                setError={setError}
                                                                clearErrors={clearErrors}
                                                            />
                                                            <div className={'error-message'}>
                                                                {errors.ssn && renderError(errors.ssn)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxPhoneEdit register={register} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id="add-business-owner-form-card-mobilePhone" label="Phone Number *" name="mobilePhone" placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                            <div className={'error-message'}>
                                                                {errors.phone && renderPhoneError(errors.phone)}
                                                            </div>
                                                        </Grid>
                                                        <Grid item container xs={12} sm={12}>
                                                            <fieldset className="fx-add-user-address-container">
                                                                <legend>Address Details</legend>
                                                                <Grid item container xs={12} sm={12} spacing={2}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAddressLine1Validation} control={control} id="add-co-owner-addressLine1" label="Address Line 1 *" name="addressLine1" onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                                                        <div className={'error-message'}>
                                                                            {errors.addressLine1 && renderError(errors.addressLine1)}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="add-co-owner-addressLine2" label="Address Line 2" name="addressLine2" />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="add-co-owner-city" label="City *" name="city" />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="add-co-owner-select-state" name="state" data={usa_state} label="State *" setValue={setValue} value={''} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="add-co-owner-zip" label="ZIP *" name="zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                                                        <div className={'error-message'}>
                                                                            {errors.zip && renderError(errors.zip)}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </fieldset>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                </FxCardBody>
                                                <FxCardFooter id="add-co-owner-Card-Footer" className="fx-footer">
                                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                        <FxButton
                                                            disableRipple={false}
                                                            variant="contained"
                                                            className="fx-button fx-button-cancel"
                                                            id="cancel-add-co-owner-btn"
                                                            onClick={handleGoBackClick}
                                                        > CANCEL
                                                        </FxButton>
                                                        <Grid className='fx-padding-right-15'></Grid>
                                                        <FxButton
                                                            disableRipple={false}
                                                            className="fx-button fx-button-theme"
                                                            id="save-add-co-owner-btn"
                                                            type="submit"
                                                            isSubmitting={isSubmitting}
                                                        >
                                                            Add Owner
                                                        </FxButton>
                                                    </Grid>
                                                </FxCardFooter>
                                            </form>
                                        </div>
                                    </FxCard>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
)
