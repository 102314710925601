/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { AUTH_STRINGS } from '../../../constants/strings';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import { ageLimit, formatDate } from '../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("CustomerEditProfile.tsx", "Customer edit profile initializing")

/**
 * Component used to edit customer Authorized Signatory
 */
export const CustomerEditAuthorizedSignatory: React.FC<any> = React.memo((props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();

        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let customerInfo: any = {
            individual: {}
        };

        const setPhoneValidationOptions = {
            required: false,
            //AUTH_STRINGS.ERRORS.CUSTOMER_PHONE_PLEASE_ENTER
            maxLength: 12,
            pattern: {
                value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                message: AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP,
            },
        };
        const setEmailValidationOptions = {
            required: false,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };
        const setSsnValidationOptions = {
            required: true,
            //AUTH_STRINGS.ERRORS.CUSTOMER_SSN_PLEASE_ENTER
            maxLength: 11,
            pattern: {
                value: /^\d{3}-?\d{2}-?\d{4}$/,
                message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
            },
        };

        const setZipValidationOptions = {
            required: true,
            //AUTH_STRINGS.ERRORS.CUSTOMER_ZIP_PLEASE_ENTER
            pattern: {
                value: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
                message: AUTH_STRINGS.ERRORS.ZIP_INVALID_REGEXP,
            },
        };
        let customerInfoName: any;
        let customerInfoLastName: any;
        let addressLine: any;
        let street: any;
        let state: any = 'AL';
        let zip: any;
        let city: any;
        let email: any;
        let dob:any;
        let homePhone:any;
        let workPhone:any;
        let mobilePhone:any;
        let last4ssn:any;
        let beneficialOwnerId:any;

        /**
         * Setting values to variables to load default values in form
         */
        if (context && context.data && context.data.body) {
            customerInfo = { ...context.data.body };
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Authorized Signatory Information', backButton:'/company'} } });
            if (customerInfo?.business?.beneficialOwner) {
                customerInfo['business']['beneficialOwner'].forEach((item: any) => {
                    if (item.actAsAuthorizedSignatory === true) {
                        item?.mailingAddress?.forEach((elem: any) => {
                            if (elem.isPrimary === true) {
                                addressLine = elem.addressLine1;
                                street = elem.addressLine2 ? elem.addressLine2 : '';
                                city = elem.city;
                                state = elem.state;
                                zip = elem.zip;
                            }
                        })
                        beneficialOwnerId = item.id
                        customerInfoName = item.firstName ? item.firstName : '';
                        customerInfoLastName = item.lastName ? item.lastName : '';
                        dob = item.dob ? formatDate(item.dob) : ''
                        last4ssn = item.last4ssn ? item.last4ssn : ''
                        homePhone = item.homePhone ? item.homePhone : '';
                        workPhone = item.workPhone ? item.workPhone : '';
                        mobilePhone = item.mobilePhone ? item.mobilePhone : '';
                        email = item.email ? item.email : '';
                    }
                })
            }
        }

        const [customerSsn, setCustomerSsn] = useState(last4ssn);
        const [editSsn, setEditSsn] = useState(false);

        const [customerMobilePhone, setCustomerMobilePhone] = useState(mobilePhone);
        const [customerHomePhone, setCustomerHomePhone] = useState(homePhone);
        const [customerWorkPhone, setCustomerWorkPhone] = useState(workPhone);
        const [isLoading, setIsLoading] = useState(false);

        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const handleChange = (event: any) => {
            if (event.target.name === 'ssn') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (val.length === 11) {
                    clearErrors('ssn');
                }
                else {
                    setError("ssn", {
                        type: "manual",
                        message: "Invalid SSN",
                    });
                }
                setCustomerSsn(val);
            }

            if (event.target.name === 'mobilePhone' || event.target.name === 'homePhone' || event.target.name === 'workPhone') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{3})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (event.target.name === 'mobilePhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('mobilePhone');
                    }
                    else {
                        setError("mobilePhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerMobilePhone(val);
                }
                else if (event.target.name === 'homePhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('homePhone');
                    }
                    else {
                        setError("homePhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerHomePhone(val);
                }
                else if (event.target.name === 'workPhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('workPhone');
                    }
                    else {
                        setError("workPhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerWorkPhone(val);
                }
            }
        }
        /**
         * Method used to handle form fields
         * @param event :event data
         */
        const handleKeyDownChange = (event: any) => {
            if (event.key === 'Backspace') {
                let val = event.target.value;
                const name = event.target.name;
                if (val && val.length > 0 && val[val.length - 1] === '-') {
                    setTimeout(() => {
                        val = val.substring(0, val.length - 1);
                        if (name === 'ssn') {
                            setCustomerSsn(val);
                        }

                        else if (name === 'mobilePhone') {
                            setCustomerMobilePhone(val);
                        }
                        else if (name === 'homePhone') {
                            setCustomerHomePhone(val);
                        }
                        else if (name === 'workPhone') {
                            setCustomerWorkPhone(val);
                        }

                    }, 1);
                }
            }
        }
        /**
         * Method used to call update Customer Authorized Signatory API
         * @param payloadData :request
         * @returns
         */
        async function updateCustomerAuthorizedSignatory(payloadData: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('',false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CustomerEditProfile.tsx", "error", err);
                return false;
            }
        }
        /**
         * Method used to clear form data
         * @param obj :form data
         * @returns
         */
        function clean(obj: any) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }
        /**
       * Method used to format request and call API
       * @param data :form data
       */
        async function onSubmit(data: any) {
            if (data['ssn'] && data['ssn'].length && data['ssn'].length !== 11) {
                delete data['ssn']
            }
            if (data['dob']) {
                data['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            if (data['fullName']) {
                data['firstName'] = data['fullName'];
                // eslint-disable-next-line no-self-assign
                data['lastName'] = data['lastName'];
                delete data['fullName'];
            }
            data['mailingAddress'] = [{
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip'],
                "isPrimary":true
            }]
            data['id']=beneficialOwnerId
            data['workPhone']=customerWorkPhone;
            data['homePhone']=customerHomePhone;
            data['mobilePhone']=customerMobilePhone;
            delete data['addressLine1'];
            delete data['addressLine2'];
            delete data['city'];
            delete data['state'];
            delete data['zip'];
            delete data['ssnview'];
            data = clean(data);
            const request: any = {
                "type": "BUSINESS",
                "business": {
                    "beneficialOwner": [data]
                }
            }
            setIsLoading(true);
            let status: any = await updateCustomerAuthorizedSignatory(request);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Authorized Signatory Updated Successfully!',
                });
                history.push('/company');
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        const handleSsnEdit = () => {
            setEditSsn(true);
        }

        const handleSsnView = () => {
            setEditSsn(false);
        }
        const history = useHistory()

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="customer-profile-info-Grid" item xs={12} className='fx-form-grid'>
                <FxCard id="customer-profile-info-card" className="fx-theme-passport">
                    {dataLoadStatus ?
                        (context?.data?.body && <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCardHeader id={'customer-profile-info-card#card-header'}>

                            </FxCardHeader>
                            <FxCardBody id="customer-profile-info-card#card-body"
                                        className="fx-info-card fx-margin-top-reducer">
                                <Grid container direction="row" spacing={2} className="fx-info-card-form">
                                    <Grid item xs={12}>
                                        <Typography variant="h5" id="customer-profile-business-info" className="">GENERAL
                                            INFORMATION</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("fullName") }}
                                                    className={errors.fullName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }}
                                                    id="authorized-signatory-fullName" label="First Name" name="fullName"
                                                    defaultValue={customerInfoName} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("lastName") }}
                                                    className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: false }}
                                                    id="authorized-signatory-lastName" label="Last Name" name="lastName"
                                                    defaultValue={customerInfoLastName} />
                                    </Grid>
                                    {!editSsn && <><Grid item xs={4}>
                                        <FxTextEdit register={{ ...register("ssnview") }}
                                                    className={errors.ssnview ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} id="authorized-signatory-ssnview" label="SSN"
                                                    name="ssnview" defaultValue={'###-##-' + last4ssn} inputProps={
                                            { readOnly: true, }} />
                                    </Grid>
                                        <Grid item xs={2}>
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button"
                                                id="authorized-signatory-change-ssn-btn"
                                                onClick={handleSsnEdit}
                                            >
                                                Edit
                                            </FxButton>
                                        </Grid></>}
                                    {editSsn && <><Grid item xs={4}>
                                        <FxTextEdit register={{ ...register("ssn") }}
                                                    className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setSsnValidationOptions}
                                                    id="authorized-signatory-ssn" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} value={customerSsn} label="SSN"
                                                    name="ssn" />
                                    </Grid>
                                        <Grid item xs={2}>
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button"
                                                id="authorized-signatory-change-ssn-btn"
                                                onClick={handleSsnView}
                                            >
                                                View
                                            </FxButton>
                                        </Grid>
                                    </>}
                                    <Grid item xs={12} sm={6}>
                                        <FxDateEdit register={{ ...register("dob") }}
                                                    className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }}
                                                    id="authorized-signatory-dob" label="DOB" name="dob" defaultValue={dob}
                                                    maxDate={ageLimit(18)} setValue={setValue} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" title="PRIMARY ADDRESS"
                                                    id="customer-profile-edit-contact-info" className="">PRIMARY
                                            ADDRESS</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("addressLine1") }}
                                                    className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }}
                                                    id="authorized-signatory-addressLine1" label="Address Line 1"
                                                    name="addressLine1" defaultValue={addressLine} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("addressLine2") }}
                                                    className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: false }}
                                                    id="authorized-signatory-addressLine2" label="Address Line 2"
                                                    name="addressLine2" defaultValue={street} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("city") }}
                                                    className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }}
                                                    id="authorized-signatory-city" label="City" name="city"
                                                    defaultValue={city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} className={"fx-input-edit"}
                                                            control={control} id="edit-profile-select-state" name="state"
                                                            data={usa_state}
                                                           value={state} label="State"
                                                            setValue={setValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }}
                                                    className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setZipValidationOptions}
                                                    id="authorized-signatory-zip" label="ZIP" name="zip"
                                                    defaultValue={zip} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" title="CONTACT INFORMATION"
                                                    id="customer-profile-edit-contact-info" className="">CONTACT
                                            INFORMATION</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("mobilePhone") }}
                                                    className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setPhoneValidationOptions}
                                                    id="authorized-signatory-mobilePhone" label="Mobile Phone"
                                                    name="mobilePhone" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} value={customerMobilePhone}
                                                    defaultValue={mobilePhone} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("homePhone") }}
                                                    className={errors.homePhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setPhoneValidationOptions}
                                                    id="authorized-signatory-homePhone" label="Home Phone" name="homePhone"
                                                    onChange={handleChange} onKeyDown={handleKeyDownChange}
                                                    value={customerHomePhone} defaultValue={homePhone} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("workPhone") }}
                                                    className={errors.workPhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setPhoneValidationOptions}
                                                    id="authorized-signatory-workPhone" label="Work Phone" name="workPhone"
                                                    onChange={handleChange} onKeyDown={handleKeyDownChange}
                                                    value={customerWorkPhone} defaultValue={workPhone} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("email") }}
                                                    className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setEmailValidationOptions}
                                                    id="authorized-signatory-email" label="Email" name="email"
                                                    defaultValue={email} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="customer-edit-profile-Card-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">

                                    <FxButton
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="cancel-authorized-signatory-btn"
                                        onClick={() => history.push('/company')}
                                    > Cancel
                                    </FxButton>
                                    <Grid className='fx-padding-right-15'></Grid>
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-authorized-signatory-btn"
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>)
                    :
                        <FxSkeltonList height="35rem" />
                    }
                </FxCard>
            </Grid >
        )
    })

const usa_state: any = [
    { "value": "AL", "label": "Alabama" },
    { "value": "AK", "label": "Alaska" },
    { "value": "AS", "label": "American Samoa" },
    { "value": "AZ", "label": "Arizona" },
    { "value": "AR", "label": "Arkansas" },
    { "value": "CA", "label": "California" },
    { "value": "CO", "label": "Colorado" },
    { "value": "CT", "label": "Connecticut" },
    { "value": "DE", "label": "Delaware" },
    { "value": "DC", "label": "District Of Columbia" },
    { "value": "FM", "label": "Federated States Of Micronesia" },
    { "value": "FL", "label": "Florida" },
    { "value": "GA", "label": "Georgia" },
    { "value": "GU", "label": "Guam" },
    { "value": "HI", "label": "Hawaii" },
    { "value": "value", "label": "Idaho" },
    { "value": "IL", "label": "Illinois" },
    { "value": "IN", "label": "Indiana" },
    { "value": "IA", "label": "Iowa" },
    { "value": "KS", "label": "Kansas" },
    { "value": "KY", "label": "Kentucky" },
    { "value": "LA", "label": "Louisiana" },
    { "value": "ME", "label": "Maine" },
    { "value": "MH", "label": "Marshall Islands" },
    { "value": "MD", "label": "Maryland" },
    { "value": "MA", "label": "Massachusetts" },
    { "value": "MI", "label": "Michigan" },
    { "value": "MN", "label": "Minnesota" },
    { "value": "UM", "label": "Minor Outlying Islands" },
    { "value": "MS", "label": "Mississippi" },
    { "value": "MO", "label": "Missouri" },
    { "value": "MT", "label": "Montana" },
    { "value": "NE", "label": "Nebraska" },
    { "value": "NV", "label": "Nevada" },
    { "value": "NH", "label": "New Hampshire" },
    { "value": "NJ", "label": "New Jersey" },
    { "value": "NM", "label": "New Mexico" },
    { "value": "NY", "label": "New York" },
    { "value": "NC", "label": "North Carolina" },
    { "value": "ND", "label": "North Dakota" },
    { "value": "MP", "label": "Northern Mariana Islands" },
    { "value": "OH", "label": "Ohio" },
    { "value": "OK", "label": "Oklahoma" },
    { "value": "OR", "label": "Oregon" },
    { "value": "PW", "label": "Palau" },
    { "value": "PA", "label": "Pennsylvania" },
    { "value": "PR", "label": "Puerto Rico" },
    { "value": "RI", "label": "Rhode Island" },
    { "value": "SC", "label": "South Carolina" },
    { "value": "SD", "label": "South Dakota" },
    { "value": "TN", "label": "Tennessee" },
    { "value": "TX", "label": "Texas" },
    { "value": "UT", "label": "Utah" },
    { "value": "VT", "label": "Vermont" },
    { "value": "VA", "label": "Virginia" },
    { "value": "VI", "label": "Virgin Islands" },
    { "value": "WA", "label": "Washington" },
    { "value": "WV", "label": "West Virginia" },
    { "value": "WI", "label": "Wisconsin" },
    { "value": "WY", "label": "Wyoming" }
]
