/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Generic string functions
 */

/**
 * convert given string to title case
 * @param str 
 */
export function toTitleCase(str: any) {
    return str.toString().replace(
        /\w\S*/g,
        function (txt: any) {
            return txt.toString().charAt(0).toUpperCase() + txt.toString().substr(1).toLowerCase();
        }
    );
}
/**
 * setting a <td id>
 * @param str 
 */
export function idConvert(str: any) {
    // eslint-disable-next-line no-useless-escape
    return str.toString().replace(/[\. ,:-]+/g, "-");
}