import React from 'react'

/**
 * Component used to draw default fast funding modal content
 * @returns 
 */
export default function DefaultFastFundingDialogContent() {
    return (
        <p>I want to enable Faster Funding via Passport</p>
    )
}