/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { FxButton } from '../Action/FxButton';
import FxLabel from '../Input/FxLabel/FxLabelView';
import HttpClient from '../../libs/utils/httpClient';
import { FxFileUpload } from '../Input/FxFile/FxFileUpload';
import { Logger } from '../../libs/utils/logger';
import FxSnackBar from '../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("UploadAvatar.tsx", "UploadAvatar initializing")

async function uploadAvatar(payloadData: any) {
    try {
        const data: any = await httpClient.post('auth/signup', payloadData).then(response => {
            return response.data
        })
        if (data && data.accessToken) {
            return true;
        }
        else {
            return false;
        }
    } catch (err) {
        Logger.error("Login.tsx", "error", err);
        return false;
    }
}

const UploadAvatar = (): JSX.Element => {
    const { formState: { errors }, handleSubmit } = useForm();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    async function onSubmit(data: any) {
        setIsLoading(true);
        const status = await uploadAvatar(data);
        setIsLoading(false);
        if (status) {
            //api success
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'Avatar Uploaded Successfully!',
            });
            history.push("/auth/upload-avatar");
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: 'Please try again',
            });
        }
    }

    return (
        <div className="login-page">
            <div className="login-page-content">
                <div className="airbase-logo">
                    <a className="registration-head">
                        Upload an Avatar</a>
                </div>
                <div className="airbase-logo">
                    <a className="registration-suntext">
                        Must be a png or svg file upto 2mb in size.
                </a>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-page-content-card">
                        <div className="flex column login-page-content-card-content">
                            <FxLabel className="form-label" value="Avatar" />
                            <FxFileUpload
                                name="file"
                                className="fx-input-edit login-email"
                            />
                            <div className={'error-message'}>
                                {errors.file}
                            </div>
                        </div>
                        <div className="flex login-buttonarea">
                            <FxButton
                                id="login-signin"
                                type="submit"
                                className="login-signin"
                            >
                                {isLoading ? (
                                    <CircularProgress
                                        size={20}
                                        style={{
                                            color: 'white',
                                        }}
                                    />
                                ) : (
                                        'Save and Continue'
                                )}
                            </FxButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default UploadAvatar;
