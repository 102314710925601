import React, { useEffect, useState, useRef } from "react";
import { Button, TextField, Grid, Chip, StandardTextFieldProps, CircularProgress } from "@mui/material";
import { UseFormSetValue, UseFormRegisterReturn } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone'
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { Logger } from "../../../libs/utils/logger";
import HttpClient from '../../../libs/utils/httpClient';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { RegisterComponent } from "../../../libs/saga/dataSaga";

Logger.debug("FxFileUpload.tsx", "Fx File Upload initializing")
/**
 * Component used to upload file and get uploaded data
 */

type TFieldValues = Record<string, string>;

interface IFxFileUploadProps extends StandardTextFieldProps {
    acceptedFiles?: string[];
    showDocs?: boolean;
    passData?: (event: any)=> void;
    maxFileSize?: number;
    setValue?: UseFormSetValue<TFieldValues>;
    register?: UseFormRegisterReturn;
    disableDrop?:boolean;
    name: string;
    formData?: {
        [key:string]: string;
    };
    files?: any;
    hide?: any;
    encode?: any;
    onMaxFileSizeExceed?:()=>void;
}

const DEFAULT_FILE_TYPES = [
  '.jpeg',
  '.png',
  '.pdf'
];

export const FxFileUpload: React.FC<IFxFileUploadProps> =
  (props) => {

    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<Array<any>>([])
    const [fileFormData, setFileFormData] = useState(props.formData);
    const passDataRef = useRef(props.passData);
    passDataRef.current = props.passData;

    /**
     * to get existing uploaded data
     */
    useEffect(() => {
      if (context && props.files) {
        passDataRef.current?.(props.files);
      }
    }, [props.files, context]);

    if (fileFormData !== props.formData) {
      setFileFormData(props.formData)
    } 

    const { getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      onDrop: acceptedFiles => {
        fileUpload(acceptedFiles)
      },
      accept:props.acceptedFiles || DEFAULT_FILE_TYPES,
      multiple: false
    })

    /**
     * method to remove a file
     * @param id :file id
     */
    const removeFile = (id: any) => {
      const newVal = value.filter((item: any) => item.id !== id);
      setValue(newVal)
      if (props.showDocs === false) {
          props.passData && props.passData(newVal);
      }
    }

    return (
      <Grid item id='fileUpload-grid'>
        <div {...getRootProps()}>
          <input {...getInputProps()} id={'file-upload-input-' + props.id} />
          <Button
            variant="contained"
            className="fx-button fx-button-lite"
            onClick={open}
            id={'file-upload-button-' + props.id}
            startIcon={<AddIcon />}
          >
            {loading ? (
              <CircularProgress
                size={20}
                style={{
                  color: 'white',
                }}
              />
            ) : (
                'Choose file'
              )}
          </Button>
          <TextField
            {...props.register}
            className="fx-hidden"
            id={props.id}
            name={props.name}
            value={props.files ? props.files : ""}
            inputRef={props.inputRef ? props.inputRef : undefined}
            type={'hidden'} />
          <br />
          {props.showDocs && <Grid>
            {getFileNames()}</Grid>}
        </div>
      </Grid>
    )
    
    /**
     * method to get the names from file
     * @returns file name
     */
    function getFileNames() {
      let html: any;
      try {
        // eslint-disable-next-line array-callback-return
        html = value.map((key: any) => {
          // check for hiding the delete icon
          if(!props.hide){
          return <Chip icon={<InsertDriveFileTwoToneIcon />} label={key['name']} onDelete={() => removeFile(key['id'])} color="primary" variant="outlined" deleteIcon={<DeleteIcon />} />
          }
        })
      } catch (e) {
      }
      return <div className={'fx-filelist'}>{html}</div>
    }
    /**
     * Method to upload file
     * @param files :file
     * @returns
     */
    async function fileUpload(files: any) {
      try {
        //check the file
        if (files.length < 1) {
          return;
        }
        const file = files[0];
        if (props.maxFileSize && file.size > props.maxFileSize) {  

          // Trigger onMaxFileSizeExceed event when file size exceeds maxFileSize
         if(props.onMaxFileSizeExceed){
            props.onMaxFileSizeExceed();
         }
          return;
        }
        
        setLoading(true);
        const httpClient = HttpClient.newClientCustomHeaders({ "Content-Type": "multipart/form-data" });

        const fileData = fileFormData;

        const formData = new FormData();
        formData.append("file", file);
        //add custom form data
        if (fileData) {
          let customData = fileData
          for (const key in customData) {
            formData.append(key, customData[key]);
          }
        }
        // if encode in props is true file is converted to base 64 format else call the document api and fetch the file id
        if(props.encode){
          // convert to base64 format
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const base64Node: any = new Promise(resolve => {
              let fileInfo;
              let baseURL: any;
              // Make new FileReader
              const reader = new FileReader();
              // Convert the file to base64 text
              reader.readAsDataURL(file);
              // on reader load somthing...
              reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                fileInfo = baseURL.split('base64,')[1];
                fileDispatch('',file,fileInfo)
              };
            });
        }
        else{
          //upload file
          await httpClient.post("document", formData).then(response => {
            //find id
            const id = response?.headers?.url?.toString().replace("v1/document/id/","");
            fileDispatch(id,file)
          });

        }
      } catch (e) {

      }
      finally {
        setLoading(false);
      }

    }
    /**
     * This function updates the id,name and base64 properties of file upload component
     */
    function fileDispatch(id:any = null,file:any = null, base64: any = null){
        let newFiles: any;
        if(id){
          newFiles={id:id, name:file.name}
        }
        else{
          newFiles={base64:base64, name:file.name}
        }

        let fileData=[]
          fileData.push(newFiles);
          if (props.files !== undefined) {
            fileData = [...props.files, newFiles]
          }
            dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: {"id": props.id,'files': fileData } });
            props.passData && props.passData(fileData);
    }
  };
