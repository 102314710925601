/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Grid, Typography, Chip } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import {
  clean,
  getEAOrAddressDetailsFromContact,
  setPayeeInformation,
  fetchLabelFromId,
  convertToTitleCaseAndAppendWithS,
  convertEndData,
  convertToOnDayFormat,
  deleteUnwantedRequest,
  updateComponentParams,
  renderError,
  transformRecurringRequestGlobal,
  createCommonVerifyProps
} from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
  createSendMyAccount,
  dataSourceTransformation,
  getEAList,
  accountLabelTransformation,
  getDestinationValue,
  getLocationData
} from "./ScheduleGlobalFunctions";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

Logger.debug(
  "CreateSendContactInternationalWIRE.tsx",
  "Create Send Contact International Wire initializing"
);

const currency = [
  { label: 'USD', value: 'USD' }
];

/**
 * This component handles the send creation to linked contacts through International Wire method
 */
export const CreateSendContactInternationalWIRE: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const params = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const scheduleID = params?.id;
  const isRecurringRequired = false;
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individual = uiFeat.getIndividualCustomerFeature().available;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  let sendId: any = "";

  const acc_src = {
    url: "account/list",
    method: "POST",
    data: {
      pageSize: 25,
      pageNumber: 1,
      sortOptions: {
        sortOrder: "DESC",
        sortBy: "createdOn",
      },
      criteria: {
        filters: [
          {
            key: "status",
            operator: "nin",
            values: ["INACTIVE"],
          },
        ],
      },
    },
  };

  const destination_src = {
    url: "contact/list",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 5,
      criteria: {
        filters: [
          {
            key: "allowedMethod",
            operator: "eq",
            values: ["INTERNATIONAL_WIRE"],
          },
        ],
      },
    },
  };

  const method_Tab = useMemo(() => {
    const tabs = [{
      title: "ACH",
      link: "/transaction/send/contact/add/ach",
      disabled: !!props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Ach Send",
        operation: "CREATE"
      },
      {
        entity: "Recurring Transaction",
        name: "Ach Send",
        operation: "CREATE"
      }]
    },
    {
      title: "WIRE",
      link: "/transaction/send/contact/add/wire",
      disabled: !!props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Wire Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Wire Send",
        operation: "CREATE"
      }]
    },
    {
      className: "fx-link-sel",
      title: "INTERNATIONAL WIRE",
      link: "/transaction/send/contact/add/internationalWire",
      disabled: !!props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "International Wire Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "International Wire Send",
        operation: "CREATE"
      }]
    },
      ...(!individual ? [{
      title: "CHECK",
      link: "/transaction/send/contact/add/check",
      disabled: !!props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Check Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Check Send",
        operation: "CREATE"
      }]
    }]: []),
    {
      title: "BOOK",
      link: "/transaction/send/contact/add/book",
      disabled: !!props.isEdit,
      multiplePermission: true,
      permission: [{
        entity: "Accounts",
        name: "Book Send",
        operation: "CREATE"
      }, {
        entity: "Recurring Transaction",
        name: "Book Send",
        operation: "CREATE"
      }]
    }];
    // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
    if (getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer) {
      tabs.push({
        title: "VIRTUAL CARD",
        link: "/transaction/send/contact/add/virtualcard",
        disabled: !!props.isEdit,
        multiplePermission: true,
        permission: [{
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "CREATE"
        }, {
          entity: "Recurring Transaction",
          name: "Virtual Card Send",
          operation: "CREATE"
        }]
      });
    }
    return tabs;
  }, [props.isEdit]);

  const [isloading, setIsloading] = useState(false);
  const [destination, setDestination] = useState<any>();
  const [isDestination, setIsDestination] = useState(true);
  const [contacEA, setContacEA] = useState<any>([]);
  const [eaLoad, setEALoad] = useState(false);
  const [contactName, setContactName] = useState<any>();
  const [externalEAId, setExternalEAId] = useState();
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const [repeatStatus, setRepeatStatus] = useState(false);
  const [sendEnabled, setSendEnabled] = useState(true);
  const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];
  const destinationFilter = "name";
  const [location, setLocation] = useState<any>();

  const handleClose = () => {
    if (scheduleID) {
      history.push("/transactions/send/" + scheduleID);
    } else {
      history.push("/dashboard");
    }
  };

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-contact-internationalWire-form-card-destination" },
    });
    setValue("type", "REGULAR");
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-internationalWire-form-card-file-id-file-upload",
        files: [],
      },
    });
    setValue("type", "REGULAR");
    // checking whether edit or not.
    if (scheduleID) {
      const src = {
        url: "/transaction/id/" + scheduleID,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-to-contact-internationalWire", source: src },
      });
      updateComponentParams(dispatch, "edit-send-to-contact-internationalWire", {
        isEdit: true,
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  /**
   * Method setting the default values for the text edit component from the context data
   */
  function setFormValues(data: any) {
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue("memo", data?.processingDetail?.memo);
    getDestinationData();
    setAllowDuplicate(data?.allowDuplicate);
    if (data?.destination?.contact) {
      setInternationalExternalAccount(data?.destination?.contact?.id);
    } else {
      setValue(
        "internationalExternalAccount",
        data?.destination?.contact?.internationalExternalAccount?.id
      );
    }
  }

  /**
   * Method handles the api call for getting contact name from the contact api and set the value
   */
  async function getDestinationData() {
    const destinationValue: any = await getDestinationValue(
      context?.data?.body?.destination
    );
    setValue("destination", destinationValue);
  }

  /**
   * Function to be called when context data body changes
   */
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues(context?.data?.body);
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any = {};
    let request: any = {};
    let recurringRequest: any = {};
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    if (!data.destination) {
      setError("destination", {
        type: "manual",
        message: "Destination is required",
      });
      return;
    }
    if (repeatStatus) {
      recurringRequest = transformRecurringRequestGlobal(
        data,
        recurringFormData
      );
      req = {
        name: data?.name,
        transactionDetail: recurringRequest["transactionDetail"],
        action: [],
      };
      req["transactionDetail"]["startDate"] = data?.startDate;
      request = {
        transaction: {
          source: {
            account: {
              id: data.source,
            },
          },
          method: "INTERNATIONAL_WIRE",
          type: "REGULAR",
          amount: data.amount,
          purpose: data.purpose,
          allowDuplicate: allowDuplicate,
        },
      };
      if (data.destination) {
        request["transaction"]["destination"] = {
          contact: {
            id: data.destination,
            internationalExternalAccount: {
              id: data.internationalExternalAccount,
            },
          },
        };
      }
      if(data['location']){
        req['transaction']['processingDetail']['location'] = {
            'id' : data['location']
        }
      }

      req["action"].push(request);
      if (fileUploadData?.length) {
        req["linkedDocument"] = fileUploadData.map((item: any) => ({
          purpose: "Authorization",
          document: { id: item.id }
        }));
      }
    } else {
      req = {
        source: {
          account: {
            id: data.source,
          },
        },
        method: "INTERNATIONAL_WIRE",
        type: "REGULAR",
        amount: data.amount,
        purpose: data.purpose,
        allowDuplicate: allowDuplicate,
      };
      if (data.memo) {
        req['processingDetail'] = {};
        req['processingDetail']['memo'] = data.memo;
    }
    if(data['location']){
      req['processingDetail']['location'] = {
          'id' : data['location']
      }
    }
      if (data.destination) {
        req["destination"] = {
          contact: {
            id: data.destination,
            internationalExternalAccount: {
              id: data.internationalExternalAccount,
            },
          },
        };
      }
    }
    if (props.isEdit) {
      req["id"] = context?.data?.body?.id;
      const unwantedRequestArray = ["source", "destination", "method", "type"];
      deleteUnwantedRequest(req, unwantedRequestArray);
    }
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    if (status?.headers?.url) {
      sendId = status.headers.url.split("/").pop();
    }

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
      const successMessage = props.isEdit? 'Send Updated Successfully!': repeatStatus? 'Recurring Created Successfully!' : 'Send Created Successfully';
        const redirectUrl = props.isEdit? `/transactions/send/${context?.data?.body?.id}`: repeatStatus? '/thanks/transactions/send-contact-internationalWire-recurring/': '/thanks/transactions/send-contact-internationalWire/';
        const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
        if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:params?.id?false: true,
            postSuccessEvent:handlePostSuccessEvent
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      if (props.isEdit) {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: 'Send Updated Successfully!',
        });
        history.push("/transactions/send/" + context?.data?.body?.id);
      } else {
        FxSnackBar.show({
          autoHideDuration: 1000,
          severity: 'success',
          text: repeatStatus
            ? 'Recurring Created Successfully!'
            : 'Send Created Successfully!',
        });
        if (repeatStatus) {
          dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
              id: "create-send-contact-internationalWire-form-card-file-id-file-upload",
              files: [],
            },
          });
        }
        if (repeatStatus) {
          history.push(
            "/thanks/transactions/send-contact-internationalWire-recurring/" + sendId
          );
        } else {
          history.push("/thanks/transactions/send-contact-internationalWire/" + sendId);
        }
      }
    } 
   }
    else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  const destinationFilterOptions: any = [
    { label: "Contact Name", value: "name", operator: "like" },
  ];

  /**
   * Function handles transformation of destination data
   * @param data : destination data array
   * @returns : array
   */
  const destinationTransformer = useCallback((data: any) => data?.map((item: any) => ({
    label: item.name + " (ID: " + item.id + ")",
    value: item.id,
  })) || [], []);

  const handleChange = (event: any) => {
    if (event && event?.value) {
      setInternationalExternalAccount(event?.value);
      setIsDestination(true);
    } else {
      setContacEA([]);
      setEALoad(false);
      setIsDestination(true);
    }
    setSendEnabled(true);
    FxSnackBar.closeForce();
  };

  /**
   * // getting the destination data
   * @param contactId
   */
  function setInternationalExternalAccount(contactId: any) {
    setContacEA([]);
    setIsDestination(false);
    getEAList(contactId).then((data: any) => {
      setContactName(data?.name);
      const ea = getEAOrAddressDetailsFromContact(data, "EIA", "", false, true);
      if (ea && ea.length > 0) {
        setDestination(setPayeeInformation(data));
        setIsDestination(true);
        setExternalEAId(ea[0].value);
      }
      setContacEA(ea);
      setEALoad(true);
      setSendEnabled(true);
    });
  }

  /**
   * Method to set file upload data
   * @param event :event object
   */
  function passdata(event: any) {
    try {
      setFileUploadData(event);
      if (event.length > 0 && errors["fileError"]) {
        clearErrors("fileError");
      }
    } catch (e) { 
      Logger.error("CreateSendContactInternationalWIRE.tsx", "error", e);
    }
  }

  /**
   * Method to remove file upload data
   * @param id : file id
   */
  const removefile = (id: any) => {
    const newval = fileUploadData.filter((item: any) => item.id !== id);
    dispatch({
      type: "DATA_UPDATE_COMPONENT_PARAM",
      payload: {
        id: "create-send-contact-wire-form-card-file-id-file-upload",
        files: newval,
      },
    });
    setFileUploadData(newval);
  };

  /**
  * The main summary data
  */
  const amount = watch('amount');
  const summaryData: Record<string, TransactionSummaryFieldType | string> = {
    'Send Amount': {
      id: 'amount',
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : '',
    },
    'From': {
      id: 'from',
      value: accountLabelTransformation(getKey('selectedAccount'))
    },
    'To': {
      id: 'to',
      value: watch('destination') && contactName ? contactName : '',
    },
    ...(businessCustomer
      ? { Purpose: { id: "purpose", value: watch("purpose") } }
      : { Memo: { id: "purpose", value: watch("purpose") } }),
    'Via': {
      id: 'method',
      value: 'INTERNATIONAL WIRE',
    },
    'To International External Account': {
      id: 'internationalExternalAccount',
      value: watch('internationalExternalAccount')
        ? fetchLabelFromId(contacEA, watch('internationalExternalAccount'))
        : fetchLabelFromId(contacEA, externalEAId),
    }
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: 'memo',
      value: watch('memo'),
    }
}

  if(allowDuplicate) {
    summaryData['Allow Duplicate'] = {
      id: 'allowDuplicate',
      value: allowDuplicate ? 'Yes' : 'No',
    }
  }
  if(watch('currency')) {
    summaryData['Currency'] = {
      id: 'currency',
      value: watch("currency"),
    }
  }

  /**
  * Recurring related summary data
  */
  const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
      if(!repeatStatus || !recurringFormData) {
          return {}
      }
      const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
      const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
          ? convertToTitleCaseAndAppendWithS(
              recurringFormData?.repeatEveryFormData?.repeatEverySelect
          )
        : '';
      const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
          'Repeat every': {
              id: 'repeat-every',
              value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
          },
      }
      if(onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
          result['On'] = {
              id: 'repeat-on',
              value: convertToOnDayFormat(recurringFormData),
          };
      }
      const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
      const ends = recurringFormData?.endFormData
          ? convertEndData(recurringFormData?.endFormData)
          : '';
      if (ends || endOn) {

      }
      result['Ends'] = endOn ? {
          id: 'repeat-ends',
          value: ends,
          ValueComponent: ({ value }) => (
              <>
                  On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
              </>
          )
      } : {
          id: 'repeat-ends',
          value: ends,
      };
      return result;
  }, [recurringFormData, repeatStatus]);

  const topSectionDefaultValues = {
    amount: context?.data?.body?.amount,
    source: context?.data?.body?.source?.id,
    purpose: context?.data?.body?.purpose,
  };
  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: "send",
        type: "contact",
        formData: { amount: watch("amount"), purpose: watch("purpose") },
      };
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: { id: "schedule-top-section", data: data },
      });
    } catch (e) { 
      Logger.error("CreateSendContactInternationalWIRE.tsx", "error", e);
    }
  };

    /**
         * Function to handle the closing of the modal.
         */
	
    const handleCloseModal = () => {
      const verifyProps = {...props.verifyProps}
      verifyProps['open'] = false;
      updateComponentParams(dispatch, props.id, { verifyProps });
  };
    /** 
            *This function is called upon successful completion of a post operation.
            *It dispatches an action to update component parameters related to file uploads.
            */
            const handlePostSuccessEvent=()=>{
              dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'ccreate-send-contact-internationalWire-form-card-file-id-file-upload', 'files': [] } });
          }
  return (
    <>
    <ScheduleLayout
      id='create-send-via-contact-internationalWire'
      type='send'
      loading={isloading}
      submitTitle={repeatStatus ? 'Create' : scheduleID ? 'Save Changes' : 'Send Money'}
      summaryData={{ ...summaryData, ...summaryRepeatData }}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleClose}
      saveEnabled={sendEnabled}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleTopSection
        id="create-send-contact-internationalWire-schedule-top-section"
        schedule="send"
        type="contact"
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={topSectionDefaultValues}
        acc_src={acc_src}
        dataSourceTransformation={dataSourceTransformation}
        amountId="create-send-contact-internationalWire-form-card-amount-textbox"
        sourceId="create-send-contact-internationalWire-form-card-source"
        purposeId="create-send-contact-internationalWire-card-purpose-textbox"
      />
      {((location || !scheduleID) && businessCustomer) && <Grid item xs={12} sm={12}>
          <FxLocationSelectComponent
              label='Location'
              register={{ ...register("location") }}
              control={control}
              rules={{ required: false }}
              id="create-send-contact-internationalWire-form-location"
              name="location"
              className="fx-input-edit"
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              isEditable={!scheduleID}
              value={location ? {
                label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                value: location?.id,
              }: null}
          />
      </Grid>}
      {!props.isEdit && (
        <Grid item xs={12} sm={12}>
          {isDestination && (
            <FxAutoComplete
              register={{ ...register("destination") }}
              control={control}
              rules={{ required: true }}
              id="create-send-contact-internationalWire-form-card-destination"
              name="destination"
              source={destination_src}
              searchBy={destinationFilter}
              className={
                errors.destination
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              dataTransformer={destinationTransformer}
              setError={setError}
              clearError={clearErrors}
              setValue={setValue}
              resetField={resetField}
              value={destination}
              filterOptions={destinationFilterOptions}
              placeholder={"Search Contact..."}
              isEditable={true}
              setParam={handleChange}
            />
          )}
        </Grid>
      )}
      {props.isEdit && (
        <Grid item xs={12} sm={12}>
          <FxTextEdit
            register={{ ...register("destination") }}
            className={
              errors.destination
                ? "border-error-input fx-input-edit"
                : "fx-input-edit"
            }
            control={control}
            id="create-send-contact-internationalWire-form-card-destination-textbox"
            label="Contact"
            name="destination"
            variant="outlined"
            isEditable={false}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <ScheduleCreateMethodTab
          tabItems={method_Tab}
          id="create-send-contact-internationalWire-form-card-tab"
          tabClick={tabClickFunction}
        />
      </Grid>
      <Grid item>
        <FxMaterialSelect
          name={"currency"}
          control={control}
          rules={{ required: true }} register={{ ...register("currency") }}
          id="create-send-contact-internationalWire-form-card-currency"
          value={'USD'}
          readOnly={true}
          label='Currency*'
          data={currency}
          setValue={setValue}
        />
      </Grid>
      {businessCustomer && <Grid item>
        <FxTextEdit register={{ ...register("memo") }} className={errors.memo ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-send-contact-internationalWire-form-card-company-description-textbox" label="Memo" name="memo" variant="outlined" defaultValue={context?.data?.body?.processingDetail?.memo ? context.data.body.processingDetail.memo : ''} />
      </Grid>}
      {((eaLoad && !props.isEdit) ||
        (props.isEdit && externalEAId)) && (
          <Grid item xs={12} sm={12}>
            <FxMaterialSelect
              register={{ ...register("internationalExternalAccount") }}
              id="create-send-contact-internationalWire-form-card-ea"
              name="internationalExternalAccount"
              data={contacEA}
              control={control}
              label="Contact International External Account"
              rules={{ required: true }}
              value={externalEAId ? externalEAId : ""}
              clearError={clearErrors}
              className={
                errors.internationalExternalAccount
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              readOnly={props.isEdit ? true : false}
              setValue={setValue}
            />
          </Grid>
        )}
      <Grid item xs={12} sm={12}>
        <FxSwitch
          id="create-send-contact-internationalWire-form-card-allow-duplicate"
          value={allowDuplicate}
          onClick={setAllowDuplicate}
        ></FxSwitch>{" "}
        <FxLabel value="Allow Duplicate"></FxLabel>
      </Grid>
      {!props.isEdit && isRecurringRequired && (
        <Grid item xs={12} sm={12}>
          <FxSwitch
            id="repeat-switch"
            value={repeatStatus}
            onClick={setRepeatStatus}
          ></FxSwitch>{" "}
          <FxLabel value="Repeat"></FxLabel>
        </Grid>
      )}
      {repeatStatus && isRecurringRequired && (
        <>
          <Grid item xs={12} sm={12}>
            <FxDateEdit
              register={{ ...register("startDate") }}
              className={
                errors.startDate
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              id="create-send-contact-internationalWire-form-card-startDate"
              name="startDate"
              type="date"
              variant="outlined"
              label={"Start Date *"}
              setValue={setValue}
              disablePast={true}
              resetField={resetField}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FxTextEdit
              register={{ ...register("name") }}
              className={
                errors.name
                  ? "border-error-input fx-input-edit"
                  : "fx-input-edit"
              }
              control={control}
              rules={{ required: true }}
              id="create-send-account-internationalWire-form-card-name-textbox"
              label="Name *"
              name="name"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            {/*This section handles the recurring*/}
            <FxRecurring
              id="create-send-contact-internationalWire-form-card-fx-recurring"
              updateTrigger={setRecurringFormData}
              startDate={watch("startDate")}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
            ></FxRecurring>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Typography className="filter-popover-label">
                  LINKED DOCUMENTS
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                &nbsp;
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} sm={8}>
                  <FxMaterialSelect
                    register={{ ...register("docType") }}
                    id="create-send-contact-internationalWire-form-card-doc-type"
                    control={control}
                    rules={{ required: false }}
                    name="docType"
                    data={docTypeData}
                    value={"CONTRACT"}
                    readOnly={true}
                    label="Select a document type"
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="fx-choose-file-button">
                  <Controller
                    name={"fileId"}
                    control={control}
                    rules={{ required: false }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FxFileUpload
                          register={{ ...register("fileId") }}
                          name="fileId"
                          passData={passdata}
                          id="create-send-contact-internationalWire-form-card-file-id-file-upload"
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          value={
                            fileUploadData ? fileUploadData : value
                          }
                          acceptedFiles={[
                            ".pdf",
                            ".docx",
                            ".jpg",
                            ".txt",
                            ".png",
                            ".jpeg",
                            ".xls",
                            ".wav",
                            ".xlsx",
                            ".tiff",
                            ".doc",
                            ".rtf",
                            ".bmp",
                            ".efx",
                            ".csv",
                            ".kswps",
                            ".wps",
                          ]}
                          formData={{ type: "CONTRACT" }}
                          setValue={setValue}
                          maxFileSize={10485760}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FxLabelView
                  className="fx-label-small"
                  id="create-send-contact-internationalWire-form-card-file-types-supported"
                >
                  (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                  .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                </FxLabelView>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={"error-message"}>
                  {errors.fileError && renderError(errors.fileError)}
                </div>
              </Grid>
              {fileUploadData && (
                <Grid item container>
                  {fileUploadData.map((key: any) => {
                    return (
                      <Chip
                        icon={<InsertDriveFileTwoToneIcon />}
                        label={key["name"]}
                        onDelete={() => removefile(key["id"])}
                        color="primary"
                        variant="outlined"
                        deleteIcon={<DeleteIcon />}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </ScheduleLayout>
    </>
  );
});
