import { useEffect } from "react";
import { useHistory } from "react-router-dom";

//component used to set scroll of page at top
export default function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    // Listen for history changes (page navigation)
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten(); // Remove the history listener when the component unmounts
    };
  }, [history]);

  return null;
}