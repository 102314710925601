import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { EditContact } from './EditContact';

/**
 * This component loads the Edit Send component
 */

export const EditContactHome: React.FC =
    () => {
        const params = useParams<any>();
        const id = params.id;
        const edit_contact_source = {
            url: "/contact/id/" + id,
            method: "GET"
        }
           /**
         * Default params for edit contact
         */
         const objectParams:any={
            isloading:false,
            submitButton:'Save Changes',
            openFxSnackBar:false,
            fxSnackbarProps:{
                severity: "" as any,
                text: ""
            }
        }
         return (
            <Grid item container xs={12}>
                <Grid item xs={12} sm={12} >
                    <EditContact key="editcontact" id={"edit-payees-component-"+id} {...objectParams} source={edit_contact_source}></EditContact>
                </Grid>
            </Grid>

        )
    }
