/**
 * Component:LeadsList
 * This component handles the  Leads List component
 */
import { Divider, Grid, Typography } from '@mui/material'
import { useState } from 'react';
import React, { useEffect } from 'react'
import FxCard from '../../../Container/FxCard'
import { FxButton } from '../../../Action/FxButton'
import { DataGrid } from '../../../Data/DataGrid'
import { FxDivider } from '../../../Data/FxDivider'
import { FxGridList } from '../../../Data/FxGridList'
import { FxPrimaryFilter, IPrimaryFilterData } from '../../../Data/FxPrimaryFilter'
import { Filter } from '../../../Data/Filter';
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new-icon.svg';
import { IDataGridProps, IFilterColumns, IFilterFields } from '../../../../types/common.interfaces'
import { useHistory } from 'react-router'
import { useIsMobileOrTabletScreen } from '../../../../libs/utils/utils'
import FxCardBody from '../../../Container/FxCardBody'
import FxCardHeader from '../../../Container/FxCardHeader'
import { FxStatus } from '../../../Input/FxStatus/FxStatus'
import { useDispatch } from 'react-redux'
import { checkPermission, toTitleCase, updateComponentParams } from '../../../Utils/CommonBaseClass'
import { ReminderType } from './ReminderModal';
import { ReminderModal } from './ReminderModal';

type leadListType = {
  id: string;
 
}
export const LeadsList: React.FC<leadListType> = React.memo((props) => {

  const history = useHistory();
  const isSmallScreen = useIsMobileOrTabletScreen();
  const dispatch = useDispatch();


   /**
   * useEffect to reset page while initalising and set page title
   */
   useEffect(() =>{
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({type: "DATA_UPDATE_COMPONENT_REDUCER",payload: { id: "page-title-details", data: { title: "Leads" } }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => () => {
    updateComponentParams(dispatch, 'lead-list-card-filter', { resetFilter: true });
  }, [dispatch]);


  const src = {
    url: "/lead/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "sortOptions": {
        "sortBy": "createdOn",
        "sortOrder": "desc"
      },
      "criteria": {
        "filters": []
      }
    }
  }
  const column: IFilterColumns = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "label": "ID",
        "enable": true,
        "fieldName": "id",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "NAME",
        "enable": true,
        "fieldName": "name",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "Type",
        "enable": true,
        "fieldName": "type",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "SHARED WITH",
        "enable": true,
        "fieldName": "sharedWith",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "CIP Status",
        "enable": true,
        "fieldName": "cipStatus",
        "type": "fri://fieldtype/status",
        "default": true,
        "style": { width: "10%" },
      },
      {
        "label": "OFAC Status",
        "enable": true,
        "fieldName": "ofacStatus",
        "type": "fri://fieldtype/status",
        "default": true,
        "style": { width: "10%" },
      },
      {
        "label": "MODE",
        "enable": true,
        "fieldName": "mode",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "CREATED ON",
        "enable": true,
        "fieldName": "dateShared",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "INVITE STATUS",
        "enable": true,
        "fieldName": "invitationStatus",
        "type": "fri://fieldtype/status",
        "default": true
      },
      // {
      //   "label": "ACTIONS",
      //   "enable": true,
      //   "fieldName": "actions",
      //   "type": "fri://fieldtype/custom",
      //   "default": true,
      //   "style": { "width": "15rem" },
      // }
    ]
  };

  const filterFields: IFilterFields = {
    "fields": [
      {
        "label": "STATUS",
        "fieldName": "status",
        "type": "fri://fieldtype/checkbox",
        "data": [
          { label: "Sent", value: "sent", "checked": false },
          { label: "Accepted", value: "accepted", "checked": false },
          { label: "Draft", value: "draft", "checked": false },
          { label: "Expired", value: "expired", "checked": false }
        ],
      },
      {
        "label": "CREATED ON",
        "toLabel": "To",
        "fieldName": "createdOn",
        "type": "fri://fieldtype/daterange",
        "data": [],
        "defaultValue": false
      },

    ]
  }

  const datagridProps: IDataGridProps = {
    id: "lead-list-card-data",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  const primaryFilterData: IPrimaryFilterData[] = [
    { label: 'ID', value: 'id', operator: 'eq' },
    { label: 'NAME', value: 'name', operator: 'eq' }
  ];

  const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "enable": true,
        "fieldName": "description",
        "type": "fri://fieldtype/custom",
        "xs": "12",
        "alignItems": 'center'
      },
      {
        "enable": true,
        "fieldName": "divider",
        "type": "fri://fieldtype/custom",
        "xs": "12",
      }
    ]
  }
  /*
    This function redirects to the create page .
  */
  const redirectToCreatePage = () => {
    history.push(`/lead/invite`);
  }


  /*These are the states used for reminder modal*/
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [isReminderSentSuccess, setIsReminderSentSuccess] = useState(false);
  const [reminderType, setReminderType] = useState<ReminderType>('reminder');
  const [recipientEmail, setRecipientEmail] = useState<string>('');

  /* This function opens the reminder modal*/
  const onReminderModalOpen = (reminderType: ReminderType | undefined) => {
    if(!reminderType) return;
    setReminderType(reminderType);
    setIsReminderModalOpen(true);
  }

  /*This function closes the reminder modal.*/
  const onReminderModalClose = () => {
    setIsReminderModalOpen(false);
    setIsReminderSentSuccess(false);
  }

  /*This function handles the submit of reminder modal.*/
  const onReminderModalSubmit = () => {
    if (isReminderSentSuccess) {
      //redirect to invite list page
      setIsReminderModalOpen(false);
      setIsReminderSentSuccess(false);
    } else {
      //API call to send reminder
      setIsReminderSentSuccess(true);
    }
  }
  /*
    This function redirects to the view page .
  */
  const redirectDetailsPage = (event: any, data: any) => {
    const reminderType: ReminderType | undefined = data.status === 'SENT' ? 'reminder' : (data.status === 'ACCEPTED' ? 'activationReminder' : undefined);
    if (event.target.name === 'ACCEPTED' || event.target.name === 'SENT') {
      onReminderModalOpen(reminderType);
      setRecipientEmail(data.email);
    } else {
      dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'lead-details', data: data.invitationStatus} });
      history.push(`/lead/details/${data.id}`);
    }
  }
  /**
   * Method to set button name based on status
   */
  const getButtonName = (status: any) => {
    const mapper: any = {
      "DRAFT": 'SENT INVITE',
      "SENT": 'SEND REMINDER',
      "EXPIRED": 'RE INVITE',
      "ACCEPTED": 'ACTIVATION REMINDER'

    }
    return mapper[status]
  }
  /**
   * function to get invite list Actions
   */
  const getActions = (data: any) => {
    if (data.status !== 'PENDING' && data.status !== 'ONBOARDED') {
      return <FxButton id={'invite-action-button'} name={data.status} className={`fx-button-theme fx-button-partner-invite-actions`}>{getButtonName(data.status)}</FxButton>
    }
  }
  /*
   Function handles transformation of data to required format
 */
  function transformLeadList(data: any) {
    try {
      let newData: any = [];
      // eslint-disable-next-line array-callback-return
      data?.map((item: any, i: number) => {
        newData[i] = { ...data[i] }
        newData[i].sharedWith=data[i]?.email && data[i]?.phoneNumber? data[i]?.email+', '+data[i]?.phoneNumber:data[i]?.email? data[i]?.email: data[i]?.phoneNumber? data[i]?.phoneNumber: '';
        newData[i].mode=data[i]?.email && data[i]?.phoneNumber?'SMS, E-mail': data[i]?.email? 'E-mail': data[i]?.phoneNumber? 'SMS': '';
        newData[i].dateShared= data[i].createdOn
        newData[i].type=toTitleCase(data[i]?.customerType)
        newData[i].actions = getActions(data[i]); 
        newData[i].ofacStatus=item?.verification?.ofacStatus ? item?.verification?.ofacStatus : '';
        newData[i].cipStatus=item?.verification?.cipStatus ? item?.verification?.cipStatus : '';
    });
      return newData;
    }
    catch (e) {
      return false;
    }
  }
  /**
   * Transform data
   * @param data 
   * @returns 
   */
  function transformData(data: any) { 
    const newData: any = []

    try{      
      const transformedData = newData?.map((item: any, i: number) => {
        const row = {
          id:item.id,
          description:(
            <Grid item container xs={12} className='fx-grid-list-content'>
              <Grid item container xs={12} alignItems='center'>
                <Grid item container xs={12}>
                  <Grid item>
                    <Grid>
                      <Typography variant='h4'>ID:{item?.id} </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs>
                      <Grid item container justifyContent="flex-end">
                        <FxStatus id="lead-list-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item>
                    <Typography variant='h4'></Typography>
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs>
                      <Grid item container justifyContent="flex-end"><Typography className='fx-customer-info-label'></Typography></Grid>
                    </Grid>
                  </Grid>              
                </Grid>
              </Grid>
          </Grid>
          ),
          divider:(
            <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
              <Grid item xs>
                <Divider />
              </Grid>
          </Grid>
          )
        };       

        return row;
      });    

      return transformedData || [];
    }catch (e) {
      return false;
    }        
   }
  return (

    <Grid id="lead-list-grid" item xs={12}>
      <ReminderModal
        type={reminderType}
        onClose={onReminderModalClose}
        isOpen={isReminderModalOpen}
        onSubmit={onReminderModalSubmit}
        isSuccess={isReminderSentSuccess}
        recipientEmail={recipientEmail}
      />
      <Grid container xs={12}>
        <FxCard id="lead-list-card" className="fx-theme-passport">
          <FxCardHeader id="lead-list-card-header" className="fx-card-header">
            <Grid id="lead-list-card-header-first-grid" item container justifyContent="space-between" xs={12} >
              <Grid>
                <Grid item>
                  <FxPrimaryFilter id="lead-list-card-primary-filter" name="primary-filter" data={primaryFilterData} dataGridProps={datagridProps} />
                </Grid>
              </Grid>
              <Grid id="lead-list-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                <Grid item>
                  <Filter id="lead-list-card-filter" field={filterFields} dataGridProps={datagridProps} />
                </Grid>
                {checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "Create" }) && <>
                  <Grid item className="fx-action-block-divider">
                    <FxDivider />
                  </Grid>
                  <Grid item>
                    <FxButton id="lead-list-add-button" variant="contained" className="fx-button fx-button-theme fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} > NEW LEAD</FxButton>
                  </Grid>
                </>}
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="lead-list-card-body" className="fx-card-body-no-border-no-shadow">
            <Grid id="lead-list-card-body-grid" item xs={12}>
              {
                !isSmallScreen ? <DataGrid id="lead-list-card-data" rowClick={redirectDetailsPage} tableClass="fx-table-passport" dataTransformer={transformLeadList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} randomKey={true} /> :
                  <FxGridList id="lead-list-card-data" source={src} dataTransformer={transformData} column={gridColumn} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
              }
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </Grid>
  )


})