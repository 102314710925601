import { Grid } from '@mui/material'
import React from 'react'
import { getKey } from '../../../libs/utils/storageManager';
import { FxInvestmentInstructionList } from './FxInvestmentInstructionList';

export const FxInvestmentInstructions: React.FC<any> = React.memo((props) => {

    /**
    * to get account id
    */
    const url = {
        url: `account/id/${getKey("selectedAccount")?.id}/portfolio/investmentHistory`,
        method: "POST",
        expiry: 10,
        data: {
            "pageNumber": 1,
            "pageSize": 9,
            "sortOptions": {
                "sortBy": "lastUpdatedOn",
                "sortOrder": "desc"
            },
            "criteria": {
                "filters": [                           
                ]
            }
        }
    }

    return (
        <Grid id="home-treasure-transaction-list-card-Home-Grid" item xs={12} >
            <FxInvestmentInstructionList id="investment-instruction-list" source={url} />
        </Grid>
    )
})

