import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Controller, ControllerProps, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { FormControl, FormControlProps, Popper } from '@mui/material';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ReactComponent as SelectIcon } from '../../../assets/svg/select-arrow.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import clsx from "clsx";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Component used for Auto complete multi select search 
 */

type FxMaterialMultipleSelectType = ControllerProps & FormControlProps;

type TFieldValues = Record<string, any[]>;

interface IData {
    label: number | string | React.ReactElement;
    value: number | string;
    operator?: string
}

interface FFxMultiSelectChekEditAutoCompleteProps extends Partial<FxMaterialMultipleSelectType> {
    id: any;
    dataTransformation?: (data: any) => any;
    onChange?: (event: any) => void;
    readOnly?: boolean;
    label: string;
    name: string;
    setValue: UseFormSetValue<TFieldValues>;
    register: UseFormRegisterReturn;
    data?: Array<IData>;
    value?: Array<IData>;
    isEdit: boolean;
    source?: {
        method: string;
        url: string;
        baseUrl?: boolean;
    }
}
export const FxMultiSelectChekEditAutoComplete: React.FC<FFxMultiSelectChekEditAutoCompleteProps> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        let selectOptions: any = [];
        const defaultValues = props.value || [];
        const [selectVal, setSelectVal] = React.useState<Array<IData>>([...defaultValues] || []);

        /**
         * to set default value
         */
        useEffect(() => {
            if (props.value && props.setValue) {
                props.setValue(props.name, props.value)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        /**
        * to set default value
        */
        useEffect(() => {
            if (props.value) {
                setSelectVal([...props.value])
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.value]);


        /**
         * function to set changed data
         * @param event :event
         * @param data :data
         */
        function handleChange(event: any, value: any) {
            let selectedValue = value || [];
            let filteredOptions: Array<IData> = selectedValue;
            props.setValue(props.name, filteredOptions);
            setSelectVal(filteredOptions);
        }
        /**
         * Method used to manipulate data from api 
         * @returns :selected options
         */
        function dataTransformation() {
            let Options: any = [];
            if (props.dataTransformation && context?.data?.body?.resources) {
                Options = props.dataTransformation(context.data.body.resources);
            }
            return Options;
        }

        /**
         * function to emit the user entered text
         * @param inputValue : user entered text
         */
        const searchChange = async (inputValue: any) => {
            if (props.onChange) {
                if (inputValue.length >= 3 || inputValue.length === 0) {
                    props.onChange(inputValue);
                }
            }
        };
        selectOptions = props.source ? dataTransformation() : props.data ? props.data : [];
        return (
            <FormControl variant="outlined" className={clsx("fx-input-edit fx-multiselect-autocomplete", props.className)}>
                {<Controller
                    name={props.name}
                    control={props.control}
                    rules={props.rules}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <Autocomplete
                                multiple
                                id={props?.id}
                                options={selectOptions}
                                value={selectVal}
                                disableClearable
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.label}
                                popupIcon={<SelectIcon />}
                                disablePortal={true}
                                onChange={onChange = (event, value) => { handleChange(event.target.value, value) }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <div>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </div>
                                    </li>
                                )}
                                ChipProps={{
                                    deleteIcon: <CloseIcon />,
                                }}
                                PopperComponent={(popperProps) => (
                                    <Popper {...popperProps} className="fx-multiselect-autocomplete-dropdown-paper" style={{ zIndex: 1500, width: "100%" }} />
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" InputLabelProps={{ shrink: true }} label={props?.label} placeholder={selectVal.length > 0 ? '' : props?.placeholder} onChange={onChange = (event) => { searchChange(event.target.value) }} className={`fx-input-edit ${'fx-auto-complete-custom-MuiInputBaseroot'} ${props.readOnly ? 'fx-disabled-generic' : ''}`} />
                                )}
                            />
                        )
                    }}
                />}
            </FormControl>
        )
    })


