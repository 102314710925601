import React, { useEffect, useState } from 'react';
import { Logger } from '../../../libs/utils/logger';
import { Grid, Typography } from '@mui/material';
import { FxTextEdit } from '../../Input';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxButton } from '../../Action/FxButton';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import HttpClient from '../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { setAllowZeroAmountValidationOptions, setAmountValidationOptions } from '../Schedules/ScheduleGlobalFunctions';

Logger.debug("FxArrayObject.tsx", "Fx Array Object initializing")

/**
 * Component used to view Array data
 */

interface FxDynamicFormGroupProps {
    applicableMethod:any
    data : any,
    approverList: any
    control: any,
    index: any,
    value: any,
    // formField: any,
    setValue: any,
    register: any,
    errors: any,
    handleAppend: any,
    handleRemove: any,
    isAddButtonDisabled: boolean,
    fieldLength: number,
    getValues: any,
    update: any
}

const CriteriaForm: React.FC<FxDynamicFormGroupProps> = (props) => {
    
    /**
     * Data for Operators
     */
    const operatorData = [
        { label: "Greater Than", value: "GT" },
        { label: "Less Than", value: "LT" },
        { label: "Range", value: "IN" }
    ];

    const httpClient = HttpClient.getClient();

    const [conditionType, setConditionType] = useState('');
    const [authUserList, setAuthUserList] = useState([]);

    const methodPermissions : any = {
        "ACH" : "CUSTOMER_APPROVE_SEND_ACH_TRANSACTION",
        "CHECK" : "CUSTOMER_APPROVE_SEND_CHECK_TRANSACTION",
        "WIRE" : "CUSTOMER_APPROVE_SEND_WIRE_TRANSACTION",
        "VIRTUAL_CARD" : "CUSTOMER_APPROVE_SEND_VIRTUAL_CARD_TRANSACTION",
        "INTERNATIONAL_WIRE" : "CUSTOMER_APPROVE_SEND_INTERNATIONAL_WIRE_TRANSACTION",
        "BOOK" : "CUSTOMER_APPROVE_SEND_BOOK_TRANSACTION"
    }

    useEffect(()=>{
        if(props?.applicableMethod){
            getAuthorisedUserList(methodPermissions[props?.applicableMethod]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.value, props?.applicableMethod])

    useEffect(()=>{
        const _values = props.getValues();
        const condition = _values?.policy[props.index]?.condition;
        if(condition){
            setConditionType(condition)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.index])

    const getAuthorisedUserList = async (filter:any) => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                "sortBy": "lastUpdatedOn",
                "sortOrder": "desc"
                },
                "criteria": {
                "filters": [
                    {
                        "key": "permission",
                        "operator": "eq",
                        "values": [filter]
                    }    
                ]
                }
            }
            await httpClient.post(getCustomerUrl('/authorizedUser/list'),payloadData).then(response => {
                approverTransformer(response?.data?.resources);
            });
        } catch (e) { }
    }

    /* Method to transform approver data as dropdown options
    * @param data : approver name data
    */
   function approverTransformer(data: any) {
       try {
        setAuthUserList(data?.map((item: any) => {
           return {
           label: `${item.firstName} ${item.lastName} (ID: ${item.id})`,
           value: item.id,
           };
       }));
       } catch (e) {
       }
   }

    /**
     * Method to handle on change of Condition
     * @param event
     */
    const handleConditionChange = (event:any) => {
        setConditionType(event.target.value)        
    }
    
    /**
     * Method used to call request roles
     * @param body :form data
     * @returns
     */
    async function getAuthUserList(payloadData: any, isOnload = false) {
        try {
            await httpClient.post(getCustomerUrl('/authorizedUser/list', false), payloadData).then(response => {
                if (response.data && response.data.resources && response.data.resources.length > 0) {
                    approverTransformer(response.data.resources);
                }
                return response.data.resources;
           })
       } catch (err) {
           Logger.error("GetAuthorisedUser.tsx", "error", err);
           return err;
       }
    }

    /**
    * To do the api call based on the user search
    * @param searchValue: user entered value
    */
    async function onAuthUserSearch(searchValue: any) {
        if(searchValue){
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                "sortBy": "lastUpdatedOn",
                "sortOrder": "desc"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "permission",
                            "operator": "eq",
                            "values": [methodPermissions[props?.applicableMethod || props?.data?.applicableToMethod]] 
                        },
                        {
                            "key": "firstName",
                            "operator": "like",
                            "values": [
                                searchValue
                            ]
                        }
                    ]
                }
            }
            getAuthUserList(payloadData);
        }
    }

    return (
        <Grid item xs={12} sm={12} className={'fx-approval-policy-condition'} id={'add-criteria-conditions' + props.index} key={props.index}>
            <Grid container item direction="row" spacing={2} className="fx-form-edit-profile">
                <Grid item xs={2}>
                    <FxMaterialSelect
                        control={props.control}
                        rules={{ required: true }}
                        name={`policy.${props.index}.condition`}
                        register={{ ...props.register(`policy.${props.index}.condition`) }}
                        id={props.index + '-' + props.index + '-criteria-condition-operator'}
                        label={`Condition ${props.index + 1}*`}
                        placeholder="Select"
                        data={operatorData}
                        setValue={props.setValue}
                        value={props.value && props.value.condition ? props.value.condition :''}
                        onChange={handleConditionChange}
                    />
                </Grid>
                {conditionType === 'IN' ? 
                <Grid container item spacing={1} xs={3} justifyContent='space-between'>
                    <Grid item xs={5}>
                        <FxTextEdit
                            className='fx-input-edit clear-number-field-arrow'
                            register={{ ...props.register(`policy.${props.index}.fromAmount`) }}
                            name={`policy.${props.index}.fromAmount`}
                            control={props.control}
                            rules={setAllowZeroAmountValidationOptions}
                            prefix="$"
                            id={props.index + '-' + props.index + "-approval-policy-criteria-from-amount"}
                            label="Amount*"
                            type="number"
                            variant="outlined"
                            valuePattern={/-|\+|e|E/}
                            showDecimal={true}
                            setValue={props.setValue}
                            onWheel={(e: any) => e.target.blur()}
                            placeholder='0.00'
                            defaultValue={props?.data?.policy[props.index]?.fromAmount}
                        />
                    </Grid>
                    <Typography alignSelf='center' marginLeft='0.4rem'>to</Typography>
                    <Grid item xs={5}>
                        <FxTextEdit
                            className='fx-input-edit clear-number-field-arrow'
                            register={{ ...props.register(`policy.${props.index}.toAmount`) }}
                            name={`policy.${props.index}.toAmount`}
                            control={props.control}
                            rules={setAmountValidationOptions}
                            prefix="$"
                            id={props.index + '-' + props.index + "-approval-policy-criteria-to-amount"}
                            label="Amount*"
                            type="number"
                            variant="outlined"
                            valuePattern={/-|\+|e|E/}
                            showDecimal={true}
                            setValue={props.setValue}
                            onWheel={(e: any) => e.target.blur()}
                            placeholder='0.00'
                            defaultValue={props?.data?.policy[props.index]?.toAmount}
                        />
                    </Grid>
                </Grid> :  
                <Grid item xs={3}>
                    <FxTextEdit
                        className='fx-input-edit clear-number-field-arrow'
                        register={{ ...props.register(`policy.${props.index}.amount`) }}
                        name={`policy.${props.index}.amount`}
                        control={props.control}
                        rules={setAllowZeroAmountValidationOptions}
                        prefix="$"
                        id={props.index + '-' + props.index + "-approval-policy-criteria-amount"}
                        label="Amount*"
                        type="number"
                        variant="outlined"
                        valuePattern={/-|\+|e|E/}
                        showDecimal={true}
                        setValue={props.setValue}
                        onWheel={(e: any) => e.target.blur()}
                        placeholder='0.00'
                        defaultValue={props.value && props.value.amount ? props.value.amount :''}
                    />
                </Grid>}
                <Grid item xs={2}>
                    <FxTextEdit
                        register={{ ...props.register(`policy.${props.index}.approvedBy`) }}
                        name={`policy.${props.index}.approvedBy`}
                        control={props.control}
                        id={props.index + '-' + props.index + "-approval-policy-criteria-approved-by"}
                        disabled={true}
                        label="Approved By*"
                        variant="outlined"
                        value='User'
                        className={'fx-input-edit fx-input-disabled'}
                    />
                </Grid>
                <Grid item xs className="fx-contact-user-card fx-authorized-user-select">
                    <FxMaterialMultipleSelect
                        register={{ ...props.register(`policy.${props.index}.approver`) }}
                        name={`policy.${props.index}.approver`}
                        selectAllLabel={'Select All'}
                        control={props.control}
                        isEdit={false}
                        label="User"
                        id={props.index + '-' + props.index + "-approval-policy-criteria-approver"}
                        data={props.approverList.length > 0 ? props.approverList : authUserList}
                        rules={{ required: true }}
                        fixedOptions={[]}
                        limitTags={1}
                        listBoxClass="fx-auto-complete-custom-auto-complete-dropdown-display-block"
                        canSearch={true}
                        setValue={props.setValue}
                        value={props.value && props.value.approver ? props.value.approver :''}
                        onChange={onAuthUserSearch}
                        readOnly={(props?.applicableMethod || props?.data?.applicableToMethod) ? false : true}
                    />
                </Grid>
                <Grid item xs={'auto'} spacing={2} container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <FxButton
                            id={props.index + '-' + props.index + '-delete-condition-btn'}
                            title="Delete"
                            className={`fx-button fx-approval-button-icon fx-condition-btn ${props.fieldLength === 1 ? 'fx-button-disabled' : ''}`}
                            variant="contained"
                            disabled={props.fieldLength === 1}
                            onClick={props.handleRemove}
                            startIcon={<DeleteIcon />}
                        />
                    </Grid>
                    <Grid item>
                        <FxButton
                            id={props.index + '-' + props.index + '-add-condition-btn'}
                            title="Add"
                            className={`fx-button fx-approval-button-icon fx-condition-btn ${props.isAddButtonDisabled ? 'fx-button-disabled' : ''}`}
                            disabled={props.isAddButtonDisabled}
                            variant="contained"
                            onClick={props.handleAppend}
                            startIcon={<AddIcon />}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {conditionType === 'IN' && <Grid item xs>
                <Typography variant='h6' className='fx-small-text fx-text-disabled-gray'>Note: Range will include both Upper and Lower Limit</Typography>
            </Grid>}
        </Grid>              
    )
}
export default CriteriaForm;