import React from 'react';
import { PartnerHome } from './Dashboard/PartnerHome';
import { Route } from 'react-router';
import { LeadsList } from './Leads/LeadList';
import { CreateNewInvite } from './Leads/CreateNewInvite';
import { CustomerList } from './Customer/CustomerList';
import { LeadDetailsCard } from './Leads/LeadDetailsCard';
import { CustomerView } from './Customer/CustomerView';
import { EditLeadInviteHome } from './Leads/EditLeadInviteHome';

/**
 * Component used to handle partner routes
 */
export const PartnerRoutes: React.FC =
    () => {
        const partnerRoutes = <>
            <Route exact path="/leads-home">
                <PartnerHome id={'leads-home'} key="Leads-home" />
            </Route>
            <Route exact path="/leads">
                <LeadsList id={'leads-list'} key="Leads-list" />
            </Route>
            <Route exact path="/lead/invite">
                <CreateNewInvite id={'lead-invite'} key="lead-invite" isInviteClicked={true}  />
            </Route>
            <Route exact path="/lead/edit/:id">
                <EditLeadInviteHome id={'lead-invite-edit'} key="lead-invite-edit" />
            </Route>
            <Route exact path="/customers">
                <CustomerList id={'customers-list'} key="Customers-list" />
            </Route>
            <Route exact path="/lead/details/:id">
                <LeadDetailsCard id={'lead-details'} key="Lead-details" />
            </Route>
            <Route exact path="/customers/details/:id">
                <CustomerView id={'customer-details'} key="Customer-details" />
            </Route>
        </>
        return (partnerRoutes)
    }