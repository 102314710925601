import React, { useEffect } from 'react'
import { Grid, Modal, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { ReactComponent as PauseIcon } from '../../../assets/svg/pause-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-verified.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { removeUnderScoreConvertTitleCase } from '../../Utils/CommonBaseClass';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { ActivatePolicy } from '../PolicyQueue/ActivatePolicy';
import { DeactivatePolicy } from '../PolicyQueue/DeactivatePolicy';
import { FxSuccess } from '../../Data/FxSuccess';

/**
 * Component: Approval Policy Detail
 * Usage: To display details of Approval policy
 */

export const ApprovalPolicyDetail: React.FC<any> = React.memo((props) => {
  
  //#region Variable Declarations
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const dispatch = useDispatch()
  const history = useHistory();
  const [showActivateModal, setShowActivateModal] = React.useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = React.useState(false);
  const [showSuccessActivate, setShowSuccessActivate] = React.useState(false);
  const [showSuccessDeactivate, setShowSuccessDeactivate] = React.useState(false);
  let policyDetailData: any;

  if (context && context.data && context.data.body) {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: context.data.body.policyName , backButton: '/approvalpolicy' } } });
    policyDetailData = { ...context.data.body };
    policyDetailData.transactionType = 'Send' ;
    policyDetailData['createdInfo'] = policyDetailData['createdBy']['username'] + '\nOn ' + moment(policyDetailData['createdOn']).format('MM/DD/YYYY');
    policyDetailData['updatedInfo'] = policyDetailData['lastUpdatedBy']['username'] + '\nOn ' + moment(policyDetailData['lastUpdatedOn']).format('MM/DD/YYYY');
  }

  //rest the component on unmount.so that when redirect back page it will call the api again
  useEffect(() => () => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface RouteParams {
    id: string
  }

  //get ID from  url 
  const params = useParams<RouteParams>();
  const id = params.id

  /**
   * Method to handle Edit Button
   */
  const handleEdit= () => {
    try
    {
      history.push(`/approvalpolicy/edit/`+id);
    }
    catch(e){}
  }

  /**
   * Method to set Activate Modal
   */
  const handleActivate = () => {
    setShowActivateModal(true);
  }

  /**
   * Method to set Deactivate Modal
   */
  const handleDeactivate = () => {
    setShowDeactivateModal(true);
  }

  const policy_detail_left_section = [
    {
      label: 'Status',
      value: 'status',
      type: 'fri://fieldtype/status'
    },
    {
      label: 'Status Date',
      value: 'statusDate',
    },
  ];

  const policy_detail_right_section = [
    {
      label: 'Transaction Type',
      value: 'transactionType',
    }
  ];

  const audit_field_left_section = [
    {
      label: 'Created',
      value: 'createdInfo',
    }
  ];

  const audit_field_right_section = [
    {
      label: 'Updated',
      value: 'updatedInfo',
    }
  ];

  const editPermission = {
    entity: "Approval Policy",
    name: "Approval Policy",
    operation: 'Edit'
  }

  const deactivatePermission = {
    entity: "Approval Policy",
    name: "Approval Policy",
    operation: 'Deactivate'
  }

  const activatePermission = {
    entity: "Approval Policy",
    name: "Approval Policy",
    operation: 'Activate'
  }

  /**
  * On close activate modal handler
 */
  const onCloseActivateModalHandler = (status:any)=>{
    setShowActivateModal(false);

    if(status === true){
      setShowSuccessActivate(true)
    }
  }

  /**
  * On close deactivate modal handler
  */
  const onCloseDeactivateModalHandler = (status:any)=>{
    setShowDeactivateModal(false);

    if(status === true){
      setShowSuccessDeactivate(true)
    }
  }

  const operatorData = [
    { label: "Greater Than", value: "gt" },
    { label: "Less Than", value: "lt" },
    { label: "Range", value: "in" }
  ];

  const getOperator = (op:any) => {
    const operator:any = operatorData.filter((item)=> item.value === op)[0]
    return operator.label
  }

  const getAuthorizedUserName = (id: any) => {
    const authUser = props?.authorizedUserList.filter((item:any) => item.id === id)[0]
    return authUser?.firstName + ' ' +authUser?.lastName;
  }
  
  return (
    <>
      <Grid container className={'fx-approval-policy-detail'}>
        <Grid item xs={12} sm={9}>
          <FxCard className="fx-theme-passport">
            <FxCardHeader id="approver-policy-detail-header">
              <Grid container item xs={12} justifyContent="space-between">
                <Typography variant="h3" >Basic Info</Typography>
                <Grid>
                  {policyDetailData && 
                    <>
                      {policyDetailData?.status === 'ACTIVE' ? <FxButton id="approver-policy-detail-deactivate-button" permissions={deactivatePermission} title="deactivate" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<PauseIcon />} onClick={handleDeactivate}>
                          DEACTIVATE
                        </FxButton>
                        :<FxButton id="approver-policy-detail-deactivate-button" permissions={activatePermission} title="activate" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<CheckIcon />} onClick={handleActivate}>
                          ACTIVATE
                        </FxButton>
                        }
                    </>                  
                  }
                 
                  <span className='fx-padding-right-16'></span>
                  <FxButton id="approver-policy-detail-edit-button" permissions={editPermission} title="Edit" className="fx-button fx-button-action fx-svg-theme" variant="contained" startIcon={<EditIcon />} onClick={handleEdit}>
                    Edit
                  </FxButton>
                </Grid>
              </Grid>
            </FxCardHeader>
            <FxCardBody id="approver-policy-detail-Card-Body" className="fx-home-ledger-list">

              <Grid id={"policy-detail"} item xs={12} marginBottom='1rem'>
                <FxInfoCard id="approver-policy-detail-section-info-card" title="Policy Details" leftcolumn={policy_detail_left_section} rightcolumn={policy_detail_right_section} data={policyDetailData}></FxInfoCard>
              </Grid>

              <Grid id={"policy-detail"} item xs={12}>
                <FxInfoCard id="approver-policy-detail-section-info-card-second" title="Audit Fields" leftcolumn={audit_field_left_section} rightcolumn={audit_field_right_section} data={policyDetailData}></FxInfoCard>
              </Grid>
            </FxCardBody>
          </FxCard>
        </Grid>
        {policyDetailData?.policyDetails?.map((item: any)=>{
          let methodName= removeUnderScoreConvertTitleCase(item?.criteria?.values[0]);
          return <Grid item xs={12} sm={9}>
          <FxCard className="fx-theme-passport">
            <FxCardHeader id="approver-policy-detail-header-second">
              <Grid container item direction="row" justifyContent="space-between">
                <Typography variant="h3" >Send Money to {methodName}</Typography>
              </Grid>
            </FxCardHeader>
            <FxCardBody id="approver-policy-detail-Card-Body-second" className="fx-home-ledger-list">
            {item?.condition?.map((conditionItem: any, i: number)=>{
              let operatorName =  getOperator(conditionItem?.filter?.operator);
             return <Grid item xs={12} marginBottom='1rem' className={'fx-approval-policy-detail-condition'}>
                <Grid container xs={12}>
                  <Grid item xs className='fx-home-ledger-list-headerText fx-padding-header' display='flex' alignItems='center'>
                    <Typography variant='h6'>Condition {i+1}</Typography>
                    <Typography variant='h6' className={'fx-approval-policy-detail-condition-operator'} paddingLeft='1rem'> {operatorName} </Typography> &nbsp;:&nbsp; 
                    {operatorName === 'Range' ? 
                      <Typography variant='h6' className={'fx-approval-policy-detail-condition-amount'}>
                        <FxCurrencyView  className={'fx-approval-policy-detail-condition-currency-view'} value={conditionItem?.filter?.values[0]} prefix={"$"} />
                        &nbsp; To &nbsp; 
                        <FxCurrencyView  className={'fx-approval-policy-detail-condition-currency-view'} value={conditionItem?.filter?.values[1]} prefix={"$"} />
                        &nbsp; (Range will include both Upper and Lower Limit)
                      </Typography>:
                      <Typography variant='h6'><FxCurrencyView  className={'fx-approval-policy-detail-condition-currency-view'} value={conditionItem?.filter?.values[0]} prefix={"$"} /></Typography>}
                  </Grid>
                </Grid>
                <Grid container direction="row" className={"fx-home-ledger-list-column fx-home-ledger-list-column-pointer-event"}>
                  <Grid item container xs className="fx-home-ledger-list-column-content">
                    <Grid item container xs={12} alignItems='center'>
                      <Typography>Approver (User) </Typography>
                      <Typography paddingX='1rem'>:</Typography>
                      {conditionItem?.approver?.authorizedUser?.map((userItem: any, i: number)=>{
                        return <Typography variant='h6' className='fx-home-ledger-list-headerText fx-padding-header-width-changes fx-approval-policy-detail-chip'>{getAuthorizedUserName(userItem?.id) + ' (ID:  ' + userItem?.id + ')'}</Typography>
                      })}
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            })}
            </FxCardBody>
          </FxCard>
        </Grid>
        })}

        <Modal
          style={{ overflow: 'scroll' }}
          open={showActivateModal}
          onClose={onCloseActivateModalHandler }
          disableEnforceFocus={true}
          disableAutoFocus={false}
          className="fx-modal fx-modal-small2medium"
        >
          <ActivatePolicy id={'activate-policy'} approvalPolicyId={policyDetailData?.id} close={onCloseActivateModalHandler} />
        </Modal>

        <Modal
          style={{ overflow: 'scroll' }}
          open={showDeactivateModal}
          onClose={onCloseActivateModalHandler}
          disableEnforceFocus={true}
          disableAutoFocus={false}
          className="fx-modal fx-modal-small2medium"
        >
          <DeactivatePolicy id={'deactivate-policy'} approvalPolicyId={policyDetailData?.id} close={onCloseDeactivateModalHandler} />
        </Modal>

        {showSuccessActivate && 
          <FxSuccess id="sucess-activate" className={'fx-success-modal'} header={"Activated Successfully"} 
                  description={'We have successfully activated this policy.'} footer={"GO TO POLICY LISTING"} redirect={"/approvalpolicy"} />
        }

        {showSuccessDeactivate && 
          <FxSuccess id="sucess-deactivate" className={'fx-success-modal'} header={"Deactivated Successfully"} 
                  description={'We have successfully deactivated this policy.'} footer={"GO TO POLICY LISTING"} redirect={"/approvalpolicy"} />
        }
      </Grid>
    </>
  )
})
