import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { RegisterComponent } from '../../../libs/saga/dataSaga';

Logger.debug("ExternalAccountValidationStatus.tsx", "External Account Validation Status initializing");
/**
 * Component: ExternalAccountValidationStatus
 * Usage: To display external account validation status information
 */

export const ExternalAccountValidationStatus: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        ({ props } = RegisterComponent(props));
        
        useEffect(()=>{
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

         /**
         * Set external account validation status default value 
         */
        const external_account_data=useMemo(()=>{
            const ext_data:any={ ...props?.data }
            if (props?.data) {
                //check if account validation default value available
                if (props?.data?.validateAccount) {
                    // eslint-disable-next-line array-callback-return
                    props?.data['validateAccount'].map((item: any) => {
                        if (item.ews) {
                            ext_data.ewsStatus = item.ews.status;
                        }
                    });
                }
                if (props?.data?.prenote) {
                    ext_data.prenoteStatus = props?.data?.prenote.status;
                }
                if (props?.data?.microDeposit) {
                    ext_data.microDeposit = props?.data?.microDeposit.status;
                }
            }  
            return ext_data 
        },[props?.data])
       

        return (
            <Grid id="external-account-validation-info-status-grid" item xs={12}>
                <FxCard id="external-account-validation-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'dexternal-account-info-card#card-header'}>
                        <Grid id="external-account-validation-info-second-grid" item container justifyContent="space-between" xs={12} >
                            <Grid id="external-account-validation-info-third-grid"  >
                                <Typography id="external-account-validation-info-title-label" variant="h4" >Account Validations</Typography>
                                <div id="external-account-validation-info-div" className="fx-flex-grow" />
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="external-account-validation-info-card-body" className="fx-info-card " >
                        <Grid id={'external-account-validation-info-grid'} container justifyContent="space-between" >
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>&nbsp;</Grid>
                            {external_account_data?.microDeposit && <><Grid xs={6} sm={5} item className="fx-label fx-label-large" >Micro Deposit</Grid>
                            <Grid item xs={6} sm={7} className="fx-value">{external_account_data.microDeposit && <FxStatus id={"external-account-validation-micro-deposit-status"} tooltip={true} value={external_account_data.microDeposit} fill={true} className="fx-status" />}</Grid></>}
                            {external_account_data?.ewsStatus && <><Grid xs={6} sm={5} item className="fx-label fx-label-large" >EWS</Grid>
                            <Grid item xs={6} sm={7} className="fx-value">{external_account_data.ewsStatus && <FxStatus id={"external-account-validation-ews-status"} tooltip={true} value={external_account_data.ewsStatus} fill={true} className="fx-status" />}</Grid></>}
                            {external_account_data?.prenoteStatus && <><Grid xs={6} sm={5} item className="fx-label fx-label-large">Prenote</Grid>
                            <Grid item xs={6} sm={7} className="fx-value">{external_account_data.prenoteStatus && <FxStatus id={"external-account-validation-status-prenote-status"} tooltip={true} value={external_account_data.prenoteStatus} fill={true} className="fx-status" />}</Grid></>}
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
