import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxPdfReader } from '../../Page/SignUp/Business/FxPdfReader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowPermsDown } from '../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as ArrowPermsUp } from '../../../assets/svg/triangle-up-icon.svg';
import { Logger } from '../../../libs/utils/logger';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as SelectIcon } from '../../../assets/svg/select-icon.svg';
Logger.debug("ReviewAgreementAccordion.tsx", "Accordion initialized");
/**
 * Component: RolesAccordion
 * Accordion Configurations for Displaying Roles
 */
export const ReviewAgreementAccordion: React.FC<any> = React.memo(
    (props) => {
        const [fileInfo, setFileInfo] = useState<string>();
        
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();

        /**
         * Method called before component loading
         * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });     
            updateComponentParams(dispatch, props.id, { isApproved: false, "expand": false });       
        }, [ dispatch, props.id ]);

        /**
         * Method to handle row click
         * @param event 
         */
        const handleArrowClick = () => {
            updateComponentParams(dispatch, props?.id, { "expand": !props.expand });
        }

        /**
        * On approve button handler
        */
        const handleApproveClick = useCallback(() => {
            
            props.treasureReviewAggreement.agreement[props.agreementIndex].isApproved = !props.isApproved
            updateComponentParams(dispatch, props?.id, { isApproved: true, "expand": !props.expand });
            updateComponentParams(dispatch, 'treasure-review-agreement', { agreementDoc:[...props.agreementDoc, {
                purpose: 'AUTHORIZATION',
                document: {
                    name: props?.agreementInfo?.name,
                    type: props?.agreementInfo?.type,
                    base64encodedContent: fileInfo
                }
            }
            ] });                    
        },[props, dispatch, fileInfo])

        return (
            <Grid item container xs={12} className='review-agrement-accordion'>
                <Grid item xs={12} >
                    <FxCardBody className="fx-theme-passport">
                        <Accordion expanded={props.expand === true}>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Grid item sm={12}>
                                    <Grid direction='row' container justifyContent="space-between" onClick={handleArrowClick} >
                                        <Grid item xs={9} className="fx-permissions-accordian-title-cell">
                                            <Typography variant="h4" >
                                                {props?.agreementInfo?.title}
                                            </Typography>
                                        </Grid>
                                        {!props.isApproved && <Grid item >
                                            {!props.expand && <ArrowPermsDown />}
                                            {props.expand && <ArrowPermsUp />}

                                        </Grid>}
                                        {props.isApproved && <Grid item xs={3} >
                                            <div className={'fx-dialog-card-message fx-treasure-card-message'}>
                                                <div>
                                                    <SelectIcon />
                                                    <p>Accepted</p>
                                                </div>
                                            </div>
                                        </Grid>}                                       
                                    </Grid>
                                    <Grid>
                                            <Typography
                                                variant="h6">{props?.agreementInfo?.description}</Typography>
                                        </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>
                                        <div style={{ height: '25rem', overflow: 'auto' }}>
                                                <FxPdfReader
                                                    url={props?.agreementInfo?.url}
                                                    onFileInfoReady={setFileInfo}
                                                />
                                        </div>                                      
                                    </Grid>  
                                    {!props.isApproved && <Grid item sm={12}>
                                        <Grid container justifyContent="space-between" >
                                            <Grid item xs={9}>
                                            </Grid>
                                            <Grid item xs={3}>
                                            <div className={'fx-dialog-card-message fx-treasure-card-message'}>
                                                <div>                                                    
                                                    <p><FxButton className="fx-button fx-button-theme" onClick={handleApproveClick}>Accept</FxButton></p>
                                                </div>
                                            </div>
                                            </Grid>
                                        </Grid>   
                                    </Grid> 
                                    }   
                                </Grid>                     
                            </AccordionDetails>
                        </Accordion>
                    </FxCardBody>
                </Grid>
            </Grid>
        );
    });


