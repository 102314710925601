import React, { useEffect, useMemo, useState } from 'react'; 
import { Grid, Typography } from '@mui/material';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { LogoAccount } from '../../Utils/Logo';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { getKey } from '../../../libs/utils/storageManager';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as MoneyIcon } from '../../../assets/svg/move-money-new.svg';
import { FxInvestmentAccountTransferModal } from './FxInvestmentAccountTransferModal';
import { maskAccountNumberCircle, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch, useSelector } from 'react-redux';
import { FxSuccess } from '../../Data/FxSuccess';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-home.svg';
import { AccountStatementDownload } from '../../Page/Index/Passport/AccountStatementDownload';

const accountStatementPermission = {
    entity: "Accounts",
    name: "Statement",
    operation: "Download"
};

/**
 * This component handles the display of current balance details card for Treasury
 */
export const TreasuryAccountBalanceCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ props, context } = RegisterComponent(props));
        const dispatch = useDispatch()

        const [openTransferModal, setOpenTransferModal] = useState(false);
        const customerName = getKey("CustomerName") || getKey('CustomerIndividualFullName');
        let accountData = null;
        const isMobileScreen = useMediaQuery({ query: '(max-width: 900px)' });
        const [open, setOpen] = useState(false);

         /**
       * useEffect to set default address 
       */
         useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch,  props.id, { 'transferSuccess': false, transferSuccessMessage:null})  

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

         /**
            * checking account number in the context data
        */
        if (context && context.data) {
            accountData = context.data;          
        }     

        const onCloseTransferAmountHandler=(success:any)=>{
            if(success && props.onTransferAmountFinishedHandle){
                props.onTransferAmountFinishedHandle();

            }

            setOpenTransferModal(false)
        }
        
        /**
         * Method handles the onclick event for closing modal
        */
        const onCloseFxSuccessHandler=()=>{
            updateComponentParams(dispatch, props.id, { 'transferSuccess': false, transferSuccessMessage:null})           
        }

        const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
        const selectedAccount = accountListInfo?.params?.selectedOption;
        const btnDisabled = selectedAccount?.status === 'INACTIVE'

        /**
         * Method to get success message
        */
        const fxSuccess = useMemo(()=>{
           if(props?.transferSuccessMessage){
               const subHeader = <Grid item className='fx-digest-grid'>
               <FxCurrencyView className="balance-amount" id="fx-success-amount" value={props?.transferSuccessMessage?.amount} prefix={"$"} /></Grid>;
           return <FxSuccess id="sucess-transfer" className={'fx-success-modal'} header={"Transfer Successful"} 
                   subHeader={subHeader}
                   subTitle={'Total Amount'} description={props?.transferSuccessMessage?.description} footer={"GO TO DASHBOARD"} redirect={"/portfolio"} setClose={onCloseFxSuccessHandler}/>
           }
         // eslint-disable-next-line react-hooks/exhaustive-deps
       },[props?.transferSuccessMessage]);

        /**
        * Method handles the onclick event for opening modal
        */
        const handleOpen = () => {
            setOpen(true);
        };
        
        /**
        * Method handles the closing for account statement
        */
        const handleAccountStatementClose = () => {
            setOpen(false);
        }

        return (
              <>
                <Grid  item xs={12} container className="fx-account-insight fx-investment-account-summary-card">
                  <FxCard className="fx-theme-passport">
                        <FxCardHeader>
                            <Grid container item xs={12} spacing={1} justifyContent='space-between'>
                                <Grid container item>
                                    <Grid item className='fx-account-summary-card-center-account-logo'>
                                        <LogoAccount />
                                    </Grid>
                                    <Grid item marginLeft='0.5rem'>
                                        <Grid container direction='column' rowSpacing={0.5}>
                                            <Grid item>
                                                <Typography id="account-customer-name" className="fx-investment-account-summary-card-theme-text" >{customerName}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography id="account-customer-name" className="fx-investment-account-summary-card-account-number">
                                                    A/c &nbsp;{maskAccountNumberCircle(accountData?.accountNumber, false)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {!isMobileScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status !== 'INACTIVE' &&
                                        <FxButton
                                            permissions={accountStatementPermission}
                                            className="fx-button fx-button-theme"
                                            title='Download Statement'
                                            id="account-statement"
                                            onClick={handleOpen}
                                        > <DownloadIcon /> <span className='fx-account-insight-ac-statement'> STATEMENT</span></FxButton>}
                                    {isMobileScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status !== 'INACTIVE' &&
                                        <FxButton
                                            permissions={accountStatementPermission}
                                            variant="contained"
                                            className="fx-button fx-button-rounded fx-download-icon"
                                            title='Download Statement'
                                            id="account-statement"
                                            onClick={handleOpen}
                                        > <DownloadIcon />
                                        </FxButton>}
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                    <FxCardBody className="fx-account-summary-card-bal-card">
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <Typography id="" variant="subtitle1" className="fx-account-summary-card-header-text"> AVAILABLE CASH BALANCE </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4} alignItems='center'>
                                    <Grid item className="fx-digest-grid">
                                        <FxCurrencyView className="balance-amount" id="home-account-info-card-currencyview" value={accountData?.availableBalance?.amount} prefix={"$"} />
                                    </Grid>
                                    { <Grid item className='fx-investment-account-transfer-money'>
                                        <FxButton 
                                            className={btnDisabled ? "fx-button fx-button-theme-disabled" : "fx-button fx-button-theme"}
                                            onClick={()=>setOpenTransferModal(true)}
                                            disabled={btnDisabled}
                                        > 
                                            <MoneyIcon className="fx-svg-up" /><span className='fx-account-insight-ac-statement'> TRANSFER</span></FxButton>
                                    </Grid>}
                                </Grid>                                
                            </Grid>
                        </Grid>   
                    </FxCardBody>
                  </FxCard>
                </Grid>
                {openTransferModal &&
                    <FxInvestmentAccountTransferModal
                        id="modal-invest-now"
                        open={openTransferModal}
                        onClose={onCloseTransferAmountHandler}
                    />
                }

                {props?.transferSuccess &&
                   fxSuccess
                }
                {open && <AccountStatementDownload id="account-statement-download" open={open} onClose={handleAccountStatementClose} />}
              </>
            );
    })