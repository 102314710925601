/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@mui/material';
import { FxButton } from '../Action/FxButton';
import { ReactComponent as DefaultImage } from '../../../src/assets/svg/congratulation-image.svg';

interface IFxCongratulationsCard {
  message?: string;
  buttonText: string;
  onButtonClick?: (event: any) => void;
  Image?: React.ComponentType;
  className?: any;
}

/** 
 * Component used to display image and content in split screen with full width
 * used to display congratulations card for partner
 */

export const FxCongratulations: React.FC<IFxCongratulationsCard> = React.memo(({
                                                                                 message,
                                                                                 Image,
                                                                                 buttonText,
                                                                                 onButtonClick,
                                                                                 className
                                                                               }) => {

  return (
    <Grid xs={12} container justifyContent={'center'} className={`fx-congratulation ${className ? className : ''}`}>
      <Grid item xs={12} container justifyContent={'center'} className={'fx-congratulation-image'}>
        {Image ? <Image /> : <DefaultImage />}
      </Grid>
      <Grid item xs={12} textAlign={'center'} className={'fx-congratulation-title'}>Congratulations!</Grid>
      {message &&
          <Grid item xs={12} className={'fx-congratulation-message'}>
            {message}
          </Grid>
      }
      <FxButton id={'congratulations-button'} variant={'contained'} onClick={onButtonClick} className={`fx-button-theme`}>
        {buttonText}
      </FxButton>
    </Grid>
  )
})