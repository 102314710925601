/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import {
  handleKeyDownChange,
  handleZipChange,
  renderError,
  setZipValidationOptions,
  updateComponentParams,
  renderAddressLine1Error,
  renderAddressLine2Error,
  renderCityNameError,
  setCityNameValidation,
  setAddressLine2Validation,
  setAddressLine1Validation
} from "../../Utils/CommonBaseClass";
import usa_state from "../../Utils/usa_states.json";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { InvoiceItemTable } from "./InvoiceItemTable";
import { FxButton } from "../../Action/FxButton";
import { InvoiceAmountsTable } from "./InvoiceAmountsTable";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import countries from '../../../libs/utils/country/country.json';

Logger.debug(
  "InvoiceDetails.tsx",
  "Invoice Details initializing"
);

interface IInvoiceDetails {
  id?: string,
  open?: boolean,
  onClose: () => any,
  className?: string,
  title?: string,
  metadata?: {
    amount?: string,
    saveInvoiceFormData: (data: any) => any,
    invoiceDataAvailable?: boolean,
    data?: any,
    billingAddressData: any
  }
  taxExemptValue?: boolean,
  addressSame?: boolean,
  noBillingAddress?: boolean,
  isAmountChanged?: boolean,
}

/**
 * This component handles the invoice details for  collect creation via card 
 */
export const InvoiceDetails: React.FC<IInvoiceDetails> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    control,
    setError,
    watch,
    reset
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
    updateComponentParams(dispatch, props?.id, { taxExemptValue : props?.metadata?.data?.taxExempt ? props?.metadata?.data?.taxExempt : false, addressSame: false, noBillingAddress: (props?.metadata?.billingAddressData?.holderAddressLine1 && props?.metadata?.billingAddressData?.holderAddressLine1 !== '' ? false : true), isAmountChanged: false })    
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); 

  /**
   * Initializing the properties with default values for the address toggle Button
   */
  useEffect(() => {
    updateComponentParams(dispatch, props?.id, { addressSame :props?.metadata?.data?.addressSame, noBillingAddress: (props?.metadata?.billingAddressData?.holderAddressLine1 && props?.metadata?.billingAddressData?.holderAddressLine1 !== '' ? false : true) })    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.metadata?.data]); 


 const invoiceData = props?.metadata?.data ? props?.metadata?.data : {};


  /**
   * Async function handles the on submit of form
   * @param data : form request
   */
  async function saveInvoiceData(data: any) {
    data = { ...data, taxExempt : props?.taxExemptValue === true ? true : false, addressSame : props?.addressSame === true ? true : false }
    props?.metadata?.saveInvoiceFormData(data);
    props?.onClose();
  }

  /**
   * Handles the toggle button changes and store the values in the state
   */
  const handleTaxExemptToggle = () => {
    updateComponentParams(dispatch, props?.id, { taxExemptValue : !props?.taxExemptValue })    
  }

  /**
   * Method populates the Billing Address Details in the Shipping Address Section when the Toggle Button is turned on
   */
  const populateShippingAddressWithBillingAddressDetails = () => {
    const { holderAddressLine1, holderAddressLine2, holderCity, holderZip, holderState } = props?.metadata?.billingAddressData || {};
    setValue('addressLine1', holderAddressLine1);
    setValue('addressLine2', holderAddressLine2);
    setValue('city', holderCity);
    setValue('state', holderState);
    setValue('zip', holderZip);
  }

  /**
   * Handles the toggle button changes for address 
   */
  const hanldeSetAddressSame = (e: any) => {
    updateComponentParams(dispatch, props?.id, { addressSame : !props?.addressSame })
    //If 'addressSame' is true, it populates the shipping address with the billing address details.
    //If 'addressSame' is false, it resets the shipping address fields to empty strings.
    if(e) {
      populateShippingAddressWithBillingAddressDetails();
    } else {
      setValue('addressLine1', '');
      setValue('addressLine2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
    }
  }

  /**
   * Function to handle Close Button of Create/Edit Screen
   */
  const handleClose = () => {
    props?.onClose();
  };

  const invoiceItemsData = watch('invoice-items');

  //Method to disapatch amountchanged flag up on changing invoice items
  const handleAmountChange = () =>{
    updateComponentParams(dispatch, props?.id, { isAmountChanged : !props?.isAmountChanged })    
  }

  return (
    <>
    <Grid item xs paddingTop={'0.5rem'}>
      <Divider />
    </Grid>
      <Grid container className={"fx-container"} marginTop={'1rem'}>
        <Grid container className="fx-info-card">
          <Grid container xs={12} className="fx-top-move-money-menu fx-form-edit-profile">
            {/** Header Section */}
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={2}
              paddingBottom={"1.5rem"}
            >
              {/** Amount to be collected */}
              <Grid container item xs={12} paddingBottom={"1rem"}>
                <Typography display={'inline-flex'} variant="h3">
                  Amount to be collected : &nbsp; <FxCurrencyView className='fx-account-summary-card-balance-view fx-invoice-details-amount' value={props?.metadata?.amount} prefix={"$"}/>
                </Typography>
              </Grid>

              {/** Invoice Number + Tax Exempt Toggle Button */}
              <Grid
                container
                item
                xs={12}
                sm={12}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item xs={6}>
                  <FxTextEdit
                    register={{ ...register("invoiceNumber") }}
                    control={control}
                    rules={{ required: true }}
                    className={
                      errors.invoiceNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    id="invoice-details-invoice-number-textbox"
                    name="invoiceNumber"
                    defaultValue={ invoiceData?.invoiceNumber ?  invoiceData.invoiceNumber : "" }
                    label="Invoice Number"
                    isEditable={true}
                    setValue={setValue}
                  />
                </Grid>

                <Grid item marginRight={'6rem'}>
                  <FxSwitch
                    id="invoice-details-tax-exempt-toggle"
                    value={props?.taxExemptValue}
                    onClick={ handleTaxExemptToggle }
                    disabled={false}
                  ></FxSwitch>{" "}
                  <FxLabel value="Tax Exempt"></FxLabel>
                </Grid>
              </Grid>
            </Grid>

            <Grid className="fx-invoice-details-main-grid">
                
              {/** Invoice Items Section */}
              <Grid container item xs={12} sm={12}>
                <InvoiceItemTable
                  id="card-collect-invoice-details-invoice-item-table"
                  setValue={setValue}
                  control={control}
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  watch={watch}
                  reset={reset}
                  handleAmountChange={handleAmountChange}
                  data={invoiceData?.['invoice-items'] ? invoiceData?.['invoice-items'] : ''}
                />
              </Grid>

              <br />

              <Grid container xs={12} sm={12} display={"flex"}>

                {/** Shipping Address Section */}
                <Grid item container xs={8} sm={8}>
                  <fieldset className="fx-container-create-schdule-summery fx-invoice-details-address">
                    <legend>Shipping Address</legend>
                    <Grid item container xs={12} sm={12} spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FxSwitch
                          id="invoice-detail-shipping-address-same-billing-address-toggle"
                          value={props?.addressSame}
                          onClick={ hanldeSetAddressSame }
                          disabled={ props?.noBillingAddress }
                        ></FxSwitch>{" "}
                        <FxLabel value="Shipping address is same as the billing address"></FxLabel>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FxTextEdit
                          register={{ ...register("addressLine1") }}
                          control={control}
                          rules={setAddressLine1Validation(false)}
                          className={
                            errors.addressLine1
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="invoice-detail-shipping-address-line1-textbox"
                          name="addressLine1"
                          label="Address Line 1"
                          setValue={setValue}
                          defaultValue={ invoiceData?.addressLine1 ?  invoiceData.addressLine1 : "" }
                          isEditable={!props?.addressSame}
                        />
                        <div className={"error-message"}>
                          {errors.addressLine1 &&
                            renderAddressLine1Error(errors.addressLine1)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FxTextEdit
                          register={{ ...register("addressLine2") }}
                          control={control}
                          rules={setAddressLine2Validation(false)}
                          className={
                            errors.addressLine2
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="invoice-detail-shipping-address-line2-textbox"
                          name="addressLine2"
                          defaultValue={ invoiceData?.addressLine2 ?  invoiceData.addressLine2 : "" }
                          label="Address Line 2"
                          isEditable={!props?.addressSame}
                          setValue={setValue}
                        />
                        <div className={"error-message"}>
                          {errors.addressLine2 &&
                            renderAddressLine2Error(errors.addressLine2)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("city") }}
                          control={control}
                          rules={setCityNameValidation(false)}
                          className={
                            errors.city
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="invoice-detail-shipping-city-textbox"
                          name="city"
                          label="City"
                          isEditable={!props?.addressSame}
                          defaultValue={ invoiceData?.city ?  invoiceData.city : "" }
                          setValue={setValue}
                        />
                        <div className={"error-message"}>
                          {errors.city && renderCityNameError(errors.city)}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <FxMaterialSelect register={{ ...register("state") }} rules={{ required: false }} 
                      className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} 
                      id="invoice-details-shipping-state-textbox" name="state" data={usa_state} label="State" 
                      value={ invoiceData?.state ?  invoiceData.state : "" } setValue={setValue} readOnly={!props?.addressSame ? false : true} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <FxMaterialSelect register={{ ...register("country") }} rules={{ required: false }} 
                      className={errors.country ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} 
                      id="invoice-details-shipping-country-textbox" name="country" data={countries.slice(1)} label="Country" readOnly={true}
                      value={'US'} 
                      setValue={setValue}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FxTextEdit
                          register={{ ...register("zip") }}
                          control={control}
                          rules={setZipValidationOptions(false)}
                          className={
                            errors.zip
                              ? "border-error-input fx-input-edit"
                              : "fx-input-edit"
                          }
                          id="invoice-detail-shipping-zipcode-textbox"
                          name="zip"
                          label="ZIP"
                          defaultValue={ invoiceData?.zip ?  invoiceData.zip : "" }
                          onChange={(e: any) => {
                            handleZipChange(e, setValue, setError, clearErrors);
                          }}
                          onKeyDown={(e: any) => {
                            handleKeyDownChange(e, setValue);
                          }}
                          isEditable={!props?.addressSame}
                        />
                        <div className={"error-message"}>
                          {errors.zip && renderError(errors.zip)}
                        </div>
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>

                {/** Amount Summaries Section */}
                <Grid container xs={4} sm={4}>
                  <InvoiceAmountsTable
                    id={"invoice-amounts-table-section"}
                    control={control}
                    setValue={setValue}
                    register={register}
                    errors={errors}
                    invoiceItemsData={invoiceItemsData}
                    watch={watch}
                    taxExemptValue={props?.taxExemptValue}
                    isAmountChanged={props?.isAmountChanged}
                    data={invoiceData?.['invoice-amounts'] ? invoiceData?.['invoice-amounts'] : {}}
                  />
                </Grid>
              </Grid>

              {/** Footer Area - Cancel & Submit Buttons */}
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                className="fx-modal-footer"
                marginTop={"2rem"}
              >
                <FxButton
                  variant="contained"
                  className="fx-button fx-button-cancel"
                  id="create-invoice-form-card-cancel-button"
                  onClick={handleClose}
                >
                  Cancel
                </FxButton>
                <span className="fx-padding-right-16" />
                <FxButton
                  disableRipple={false}
                  className={"fx-button fx-button-theme"}
                  id={"create-invoice-form-card-submit-button"}
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit((data) => saveInvoiceData(data))}
                >
                  SAVE
                </FxButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
