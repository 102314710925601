import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Divider, Grid, Typography } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxGridList } from '../../Data/FxGridList';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';

/**
 * Component used to display location list linked to the customer/company
*/
export const LocationDetails: React.FC<any> = React.memo((props: any) => {
    
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const isMobile = useIsMobileOrTabletScreen();
    const history = useHistory();
    const dispatch = useDispatch();
    let location_data: any = [];
    let newData: any = [];

    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //check api call completed or not
    const contextState = context?.data_state;
    const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

    const column: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "ID",
                "enable": true,
                "fieldName": "id",
                "type": "fri://fieldtype/default",
                "default": true,
                "style": { "width": "20%" }
            },
            {
                "label": "Doing business as",
                "enable": true,
                "fieldName": "doingBusinessAs",
                "type": "fri://fieldtype/default",
                "default": true,
            },
            {
                "label": "ADDRESS",
                "enable": true,
                "fieldName": "address",
                "type": "fri://fieldtype/default",
                "default": true,
            }
        ]
    }
    
    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "header",
                "type": "fri://fieldtype/header",
                "xs": "12"
            },
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }
        ]
    }

    /**
    * mapping used to format values from API and to load data in datagrid
    */
    if (context && context.data && context.data.body) {
        location_data = { ...context.data.body };
        if (location_data.resources && location_data.resources.length > 0) {
            newData = location_data.resources.map((item: any) => {
                const addressLine2 = item.address.addressLine2 ? item.address.addressLine2 + ', ' : '';
                return {
                    id: item.id,
                    doingBusinessAs: item.doingBusinessAs,
                    address: item.address.addressLine1 + ', ' + addressLine2 + item.address.city + ', ' + item.address.state + ', ' + item.address.zip
                }
            })
        }

    }

    // Method handling the onclick event
    const handleOnClick = (event: any, item: any) => {
        history.push('/location/view/' + item?.id)
    }

    /**
    * Method to transform data
    * @param data
    * @returns transformed data object for mobile view
    */
    const locationListGridTransformerMobile =(data:any)=>{
        var length=data?.length-1;
        const transformedData = data?.map((item: any,index:any) => (
            length===index ? {
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createLocationDetails(item)}
                    </Grid>
                )
            } :{ 
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {createLocationDetails(item)}
                    </Grid>
                ),
                divider: createDivider()
            }
        ));
        return transformedData || [];
    };

    /**
    * Grid lay out for mobile
    * @param item 
    * @returns 
    */
    const createLocationDetails = (item: any) => (
        <> 
            <Grid item container xs={12} alignItems='center'>
                <Grid item container xs={12}>
                    <Typography  variant='h4'>ID:{item?.id}</Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Typography  variant='h4'>{item?.doingBusinessAs}</Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Typography variant='h4'>{item?.address}</Typography>    
                </Grid>
            </Grid>
        </>
    );
    
    /**
    * function to create divider to separate data
    */
    const createDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    );

    //Getting Customer Details from State
    const sourceInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
          return state.data['customer-basic-info']
        }
    });
    
    const accountList = sourceInfo?.params?.accountList?.resources;
    
    return (
        <Grid id="company-location-list-Grid" item xs={12}>
            <FxCard id="location-list-card" className=" fx-theme-passport">
                <FxCardHeader id={'location-lict-card#card-header'} >
                    <Grid id="location-list-Grid" item container justifyContent="space-between" xs={12} >
                        <Typography id="title-company-location-list-info" variant="h4" >Location</Typography>
                        { accountList?.length > 0 && <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-passport-border fx-svg-theme"
                            id="company-profile-add-location"
                            onClick={() => history.push('/location/add')}
                            startIcon={<PlusIcon />}
                        > {isMobile ? '':'Add'}
                        </FxButton>}
                    </Grid>
                    <div id="div-grow-title-company-location-list-info" className="fx-flex-grow" />
                </FxCardHeader>
                <FxCardBody id="company-location-list#card-body" className="fx-card-body-no-border-no-shadow">
                    {(dataLoadStatus && location_data) ? !isMobile ? 
                        <DataGrid id="company-profile-location-list-grid" tableClass="fx-table-passport" data={newData} column={column} rowClick={handleOnClick}/>
                        : <FxGridList id="company-profile-location-card-list" data={locationListGridTransformerMobile(newData)} column={gridColumn} rowClick={handleOnClick}/>
                    : <FxSkeltonList height="5rem" />}
                </FxCardBody>
            </FxCard>
        </Grid>
    )
})
