import React, { useState } from 'react'
import { Grid, Menu, MenuItem, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
import { ReactComponent as Avatar } from '../../../../assets/svg/avatar.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/svg/Profile_Icon.svg';
import { redirect } from '../../../../libs/utils/utils';


/**
 * This component handles the section of user profile and logout
 */
export const ViewProfile: React.FC<any> = React.memo(() => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event: any) => {
        console.log(event, "event");
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item >
            <IconButton onClick={handleClick} id="header-view-profile-button-user" size="large">
                <Avatar />
            </IconButton>
            <Menu className="fx-view-profile-links"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem component="a" onClick={() => redirect(history, '/profile')} id="header-view-profile-link-profile">
                    <ProfileIcon className="fx-view-profile-icons" />Profile
                </MenuItem>
                <MenuItem component="a" onClick={() => redirect(history, '/logout')} id="header-view-profile-link-logout">
                    <IoLogOutOutline className="fx-view-Logout-icons" />Logout
                </MenuItem>
            </Menu>
        </Grid>
    );
})
