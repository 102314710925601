import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import FxVerify, { FxVerifyPropsType } from "./FxVerify";
import HttpClient from "../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../libs/utils/utils";
import { Logger } from "../../libs/utils/logger";
import { AUTH_STRINGS } from "../../constants/strings";
import { ReactComponent as WarningIcon } from "../../assets/svg/danger-icon.svg";
const httpClient = HttpClient.getClient();

type FxVerifyRemainedPropsType = Omit<
  FxVerifyPropsType,
  | "inputLabel"
  | "buttonLabelNormal"
  | "buttonLabelSucceeded"
  | "buttonLabelFailed"
  | "verify"
  | "suffix"
  | "rules"
>;

export type PropsType = FxVerifyRemainedPropsType & {
  currentPPI?: string;
};

async function verifyPPIAPI(ppi: string) {
  try {
    const req = { ppi };
    const data: any = await httpClient
      .post(getCustomerUrl("/ppi/verify", true), req)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { ...error };
      });
    return data;
  } catch (err) {
    Logger.error("FxVerifyPPI.tsx", "error", err);
    return err;
  }
}

/**
 * rules for PPI input
 */
const rules = {
  required: true,
  maxLength: {
    value: 250,
    message: AUTH_STRINGS.ERRORS.PPI_INVALID_LENGTH,
  },
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()\[\]{}_+=|\\-]/g,
    message: AUTH_STRINGS.ERRORS.PPI_INVALID_REGEXP,
  },
};

/**
 * Component responsible for verifying PPI
 */
const FxVerifyPPI: React.FC<PropsType> = ({
  onVerificationFinished,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const verify = useCallback(
    async (ppi: string) => {
      if (ppi.replace("@ppi", "") === props.currentPPI) {
        return true;
      }

      const response = await verifyPPIAPI(ppi);
      const apiResponse = processAPIResponse(response);
      if (!apiResponse?.status) {
        setErrorMessage(apiResponse?.message);
        return false;
      } else {
        setErrorMessage("");
        if (response?.data?.status === "UNVERIFIED") {
          return false;
        }
        if (!processAPIResponse(response)) {
          return false;
        }
        return response?.data;
      }
    },
    [props.currentPPI]
  );

  const handleVerificationFinished = useCallback(
    (status) => {
      onVerificationFinished?.(status);
    },
    [onVerificationFinished]
  );

  return (
    <>
      <FxVerify
        inputLabel="Payment ID"
        buttonLabelNormal="Verify"
        buttonLabelSucceeded="Verified"
        buttonLabelFailed="Verification Failed"
        verify={verify}
        suffix="@ppi"
        rules={rules}
        onVerificationFinished={handleVerificationFinished}
        {...props}
      />
      {errorMessage !== "" && (
        <Grid item container xs={12} sm={12} alignItems="center">
          <WarningIcon />
          <Typography
            id="manage-ppi-check-availability-error-message"
            className="error-message"
          >
            {errorMessage}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default FxVerifyPPI;
