/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Download doc by creating a dynamic link 
 * @param data 
 * @param mime 
 * @param extension 
 */
export const getDownloadedDoc = function (data: any, mime: any, extension: any) {
  const link = document.createElement('a');
  link.download = 'response.' + extension;
  const blob = new Blob(
    [data.body], {
      type: mime
    }

  );
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}

/**
 * Download the pdf file from the base64 content 
 * @param b64Data 
 * @param sliceSize 
 * @param fileName 
 * @param fileExtension 
 */
export const b64toBlobAndDownload = (b64Data: any, sliceSize = 512, fileName: string, fileExtension: any) => {
  const link = document.createElement('a');
  link.download = `${fileName}.${fileExtension}`
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: '' });
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);

}
