/* eslint-disable eqeqeq */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { CircularProgress, Grid, Typography, IconButton, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { AUTH_STRINGS } from '../../../constants/strings';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import HttpClient from '../../../libs/utils/httpClient';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxBreadCrumbs from '../../Navigation/FxBreadCrumbs';
import { FxButton } from '../../Action/FxButton';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();

Logger.debug("CustomerEditProfile.tsx", "Customer edit profile initializing")

/**
 * Component used to edit customer profile details
 */
export const CustomerEditProfile: React.FC<any> = React.memo((props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError } = useForm();

        let context: any;
        ({ context, props } = RegisterComponent(props));
        let customerInfo: any = {
            individual: {}
        };
        const dispatch = useDispatch()
        const [customerSsn, setCustomerSsn] = useState();

        const [customerEin, setCustomerEin] = useState();
        const [customerMobilePhone, setCustomerMobilePhone] = useState();
        const [customerHomePhone, setCustomerHomePhone] = useState();
        const [customerWorkPhone, setCustomerWorkPhone] = useState();
        const [customerPhone, setCustomerPhone] = useState();
        const paperlessData: any = [
            { label: 'Enabled', value: true },
            { label: 'Disabled', value: false }
        ]

        const [editSsn, setEditSsn] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const page_menu = [
            {
                title: 'Dashboard',
                link: '/'
            },
            {
                title: 'Profile',
                link: '/profile',
            },
            {
                title: 'Edit Profile',
                link: '/profile/edit/general',
            }
        ]

        const setPhoneValidationOptions = {
            required: false,
            //AUTH_STRINGS.ERRORS.CUSTOMER_PHONE_PLEASE_ENTER
            maxLength: 12,
            pattern: {
                value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                message: AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP,
            },
        };
        const setEmailValidationOptions = {
            required: false,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };
        const setSsnValidationOptions = {
            required: true,
            //AUTH_STRINGS.ERRORS.CUSTOMER_SSN_PLEASE_ENTER
            maxLength: 11,
            pattern: {
                value: /^\d{3}-?\d{2}-?\d{4}$/,
                message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
            },
        };
        const setEinValidationOptions = {
            required: true,
            //AUTH_STRINGS.ERRORS.CUSTOMER_SSN_PLEASE_ENTER
            maxLength: 11,
            pattern: {
                value: /^\d{2}-?\d{7}$/,
                message: AUTH_STRINGS.ERRORS.EIN_INVALID_REGEXP,
            },
        };
        const setZipValidationOptions = {
            required: true,
            //AUTH_STRINGS.ERRORS.CUSTOMER_ZIP_PLEASE_ENTER
            maxLength: 9,
            pattern: {
                value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                message: AUTH_STRINGS.ERRORS.ZIP_INVALID_REGEXP,
            },
        };
        let customerInfoName: any;
        let customerInfoLastName: any;
        let customerInfoMiddleName: any;
        let addressLine: any;
        let street: any;
        let city: any;
        let state: any = 'AL';
        let zip: any;

        let email: any;
        let isPaperless: any;
        /**
         * Setting values to variables to load default values in form
         */
        if (context && context.data && context.data.body) {
            customerInfo = { ...context.data.body };
            customerInfoName = customerInfo.individual ? customerInfo.individual.firstName : customerInfo.business.name;
            customerInfoLastName = customerInfo.individual ? customerInfo.individual.lastName : '';
            customerInfoMiddleName = customerInfo.individual ? customerInfo.individual.middleName : '';
            addressLine = customerInfo.individual ? customerInfo.individual.primaryAddress?.addressLine1 : customerInfo.business.primaryAddress?.addressLine1;
            street = customerInfo.individual ? customerInfo.individual.primaryAddress?.addressLine2 : customerInfo.business.primaryAddress?.addressLine2;
            city = customerInfo.individual ? customerInfo.individual.primaryAddress?.city : customerInfo.business.primaryAddress?.city;
            state = customerInfo.individual ? customerInfo.individual.primaryAddress?.state : customerInfo.business.primaryAddress?.state;
            zip = customerInfo.individual ? customerInfo.individual.primaryAddress?.zip : customerInfo.business.primaryAddress?.zip;
            email = customerInfo.individual ? customerInfo.individual.email : customerInfo.business.email;
            isPaperless = customerInfo.isPaperless;

        }

        /**
         * Method used to handle changes in form fields and to set values
         * @param event :event
         */
        const handleChange = (event: any) => {
            if (event.target.name == 'ssn') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (val.length === 11) {
                    clearErrors('ssn');
                }
                else {
                    setError("ssn", {
                        type: "manual",
                        message: "Invalid SSN",
                    });
                }
                setCustomerSsn(val);
            }
            if (event.target.name == 'ein') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{2})/, '$1-');
                val = val.replace(/(\d)-(\d{7}).*/, '$1-$2');
                if (val.length === 10) {
                    clearErrors('ein');
                }
                else {
                    setError("ein", {
                        type: "manual",
                        message: "Invalid EIN",
                    });
                }
                setCustomerEin(val);
            }
            if (event.target.name == 'mobilePhone' || event.target.name == 'homePhone' || event.target.name == 'workPhone' || event.target.name == 'phone') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{3})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (event.target.name == 'mobilePhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('mobilePhone');
                    }
                    else {
                        setError("mobilePhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerMobilePhone(val);
                }
                else if (event.target.name == 'homePhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('homePhone');
                    }
                    else {
                        setError("homePhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerHomePhone(val);
                }
                else if (event.target.name == 'workPhone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('workPhone');
                    }
                    else {
                        setError("workPhone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerWorkPhone(val);
                }
                else if (event.target.name == 'phone') {
                    if (val.length === 12 || val.length === 0) {
                        clearErrors('phone');
                    }
                    else {
                        setError("phone", {
                            type: "manual",
                            message: "Invalid phone number",
                        });
                    }
                    setCustomerPhone(val);
                }
            }
        }
        /**
        * Method used to handle form fields
        * @param event :event data
        */
        const handleKeyDownChange = (event: any) => {
            if (event.key == 'Backspace') {
                let val = event.target.value;
                const name = event.target.name;
                if (val && val.length > 0 && val[val.length - 1] == '-') {
                    setTimeout(() => {
                        val = val.substring(0, val.length - 1);
                        if (name == 'ssn') {
                            setCustomerSsn(val);
                        }
                        else if (name == 'ein') {
                            setCustomerEin(val);
                        }
                        else if (name == 'mobilePhone') {
                            setCustomerMobilePhone(val);
                        }
                        else if (name == 'homePhone') {
                            setCustomerHomePhone(val);
                        }
                        else if (name == 'workPhone') {
                            setCustomerWorkPhone(val);
                        }
                        else if (name == 'phone') {
                            setCustomerPhone(val);
                        }
                    }, 1);
                }
            }
        }
        /**
         * Method used to call update Customer API
         * @param payloadData :request
         * @returns
         */
        async function updateCustomerProfile(payloadData: any) {
            try {
                const data: any = await httpClient.put('customer', payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CustomerEditProfile.tsx", "error", err);
                return err;
            }
        }
        /**
         * Method used to clear form data
         * @param obj :form data
         * @returns
         */
        function clean(obj: any) {
            for (const propName in obj) {
                if (obj[propName] == null || obj[propName] == undefined || obj[propName] == "") {
                    delete obj[propName];
                }
            }
            return obj
        }

        async function onSubmit(data: any) {
            if (data['ssn'] && data['ssn'].length && data['ssn'].length !== 11) {
                delete data['ssn']
            }
            if (data['dob']) {
                data['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            data['primaryAddress'] = {
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip']
            }
            delete data['addressLine1'];
            delete data['addressLine2'];
            delete data['city'];
            delete data['state'];
            delete data['zip'];
            delete data['ssnview'];
            if (customerInfo?.business) {
                data['name'] = data['fullName'];
            }
            else if (customerInfo?.individual) {
                data['firstName'] = data['fullName'];
            }
            delete data['fullName'];
            const paperless: any = data['isPaperless'];
            delete data['isPaperless'];
            data = clean(data);
            let request: any = {};
            if (customerInfo?.individual) {
                request = {
                    "type": "INDIVIDUAL",
                    "individual": data,
                    "isPaperless": paperless
                };
            }
            else {
                request = {
                    "type": "BUSINESS",
                    "business": data,
                    "isPaperless": paperless
                }
            }

            setIsLoading(true);
            let status: any = await updateCustomerProfile(request);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Profile Updated Successfully!',
                });
                history.push('/profile')
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        const handleSsnEdit = () => {
            setEditSsn(true);
        }

        const handleSsnView = () => {
            setEditSsn(false);
        }

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="customer-profile-info-Grid" item xs={12}>
                <FxBreadCrumbs menuItems={page_menu} id="customer-profile-edit-breadcrumbs" />
                <FxCard id="customer-profile-info-card" className="fx-theme-passport">
                    {dataLoadStatus ?
                        (context?.data?.body && <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCardHeader id={'customer-profile-info-card#card-header'}>
                                <Grid id="customer-profile-info-Grid2" item container justifyContent="space-between"
                                      xs={12}>
                                    <Typography id="title-customer-profile-edit-info" variant="h3">Edit User</Typography>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="customer-profile-info-card#card-body"
                                        className="fx-info-card fx-margin-top-reducer">
                                <Grid container direction="row" spacing={2} className="fx-info-card-form">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" id="customer-profile-business-info" className=""
                                                    title="GENERAL INFORMATION">GENERAL INFORMATION</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("fullName") }}
                                                    className={errors.fullName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }} id="edit-profile-fullName"
                                                    label={customerInfo?.individual ? "First Name" : "Name"} name="fullName"
                                                    defaultValue={customerInfoName} />
                                    </Grid>
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("middleName") }}
                                                    className={errors.middleName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: false }}
                                                    id="edit-profile-middleName" label="Middle Name" name="middleName"
                                                    defaultValue={customerInfoMiddleName} />
                                    </Grid>}
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("lastName") }}
                                                    className={errors.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }} id="edit-profile-lastName"
                                                    label="Last Name" name="lastName" defaultValue={customerInfoLastName} />
                                    </Grid>}
                                    {customerInfo?.business && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("ein") }}
                                                    className={errors.ein ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setEinValidationOptions}
                                                    onChange={handleChange} onKeyDown={handleKeyDownChange}
                                                    id="edit-profile-ein" label="EIN" name="ein" value={customerEin}
                                                    defaultValue={customerInfo.business.ein} />
                                    </Grid>}
                                    {customerInfo?.individual && !editSsn && <><Grid item xs={4}>
                                        <FxTextEdit register={{ ...register("ssnview") }}
                                                    className={errors.ssnview ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} id="edit-profile-ssnview" label="SSN" name="ssnview"
                                                    defaultValue={'###-##-' + customerInfo?.individual?.last4ssn}
                                                    inputProps={
                                                        { readOnly: true, }
                                                    } />
                                    </Grid>
                                        <Grid item xs={2}>
                                            <IconButton aria-label="Edit SSN" onClick={handleSsnEdit}
                                                        id="edit-profile-ssn-edit" size="large"><EditIcon /></IconButton>
                                        </Grid></>}
                                    {customerInfo?.individual && editSsn && <><Grid item xs={4}>
                                        <FxTextEdit register={{ ...register("ssn") }}
                                                    className={errors.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setSsnValidationOptions}
                                                    onChange={handleChange} onKeyDown={handleKeyDownChange}
                                                    id="edit-profile-ssn" label="SSN" name="ssn" value={customerSsn} />
                                    </Grid>
                                        <Grid item xs={2}>
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button"
                                                id="change-ssn-btn"
                                                onClick={handleSsnView}
                                            >
                                                View
                                            </FxButton>
                                        </Grid>
                                    </>}
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("dob") }}
                                                    className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} id="edit-profile-dob" label="DOB" name="dob"
                                                    defaultValue={customerInfo?.individual?.dob ? formatDate(customerInfo?.individual?.dob) : ''} />
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Typography variant="h5" title="PRIMARY ADDRESS"
                                                    id="customer-profile-edit-contact-info" className="">PRIMARY
                                            ADDRESS</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("addressLine1") }}
                                                    className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }}
                                                    id="edit-profile-addressLine1" label="Address Line 1"
                                                    name="addressLine1" defaultValue={addressLine} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("addressLine2") }}
                                                    className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: false }}
                                                    id="edit-profile-addressLine2" label="Address Line 2"
                                                    name="addressLine2" defaultValue={street} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("city") }}
                                                    className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={{ required: true }} id="edit-profile-city"
                                                    label="City" name="city" defaultValue={city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} className={"fx-input-edit"}
                                                          control={control} id="edit-profile-select-state" name="state"
                                                          data={usa_state}
                                                          value={state} label="State"
                                                          setValue={setValue} />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }}
                                                    className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    control={control} rules={setZipValidationOptions} id="edit-profile-zip"
                                                    label="ZIP" name="zip" defaultValue={zip} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" title="CONTACT INFORMATION"
                                                    id="customer-profile-edit-contact-info" className="">CONTACT
                                            INFORMATION</Typography>
                                    </Grid>
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("mobilePhone") }} control={control}
                                                    rules={setPhoneValidationOptions}
                                                    className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    id="edit-profile-mobilePhone" name="mobilePhone" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} label="Mobile Phone"
                                                    value={customerMobilePhone}
                                                    defaultValue={customerInfo.individual.mobilePhone} />
                                    </Grid>}
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("homePhone") }} control={control}
                                                    rules={setPhoneValidationOptions}
                                                    className={errors.homePhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    id="edit-profile-homePhone" name="homePhone" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} label="Home Phone"
                                                    value={customerHomePhone}
                                                    defaultValue={customerInfo.individual.homePhone} />
                                    </Grid>}
                                    {customerInfo?.individual && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("workPhone") }} control={control}
                                                    rules={setPhoneValidationOptions}
                                                    className={errors.workPhone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    id="edit-profile-workPhone" name="workPhone" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} label="Work Phone"
                                                    value={customerWorkPhone}
                                                    defaultValue={customerInfo.individual.workPhone} />
                                    </Grid>}
                                    {customerInfo?.business && <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("phone") }} control={control}
                                                    rules={setPhoneValidationOptions}
                                                    className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    id="phone" name="edit-profile-phone" onChange={handleChange}
                                                    onKeyDown={handleKeyDownChange} label="Phone" value={customerPhone}
                                                    defaultValue={customerInfo.business.phone} />
                                    </Grid>}
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("email") }} control={control}
                                                    rules={setEmailValidationOptions} type="email"
                                                    className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                    id="edit-profile-email" name="email" label="Email"
                                                    defaultValue={email} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <>
                                            <FxMaterialSelect register={{ ...register("isPaperless") }}
                                                              id="edit-profile-isPaperless" control={control}
                                                              name="isPaperless" data={paperlessData}
                                                              onChange={handleChange} label="Paperless" value={isPaperless}
                                                              setValue={setValue} />
                                        </>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="customer-edit-profile-Card-Footer" className="fx-footer">
                                <Grid container xs={12} justifyContent="flex-end">
                                    <Grid className='fx-padding-right-15'>
                                        <FxButton
                                            variant="contained"
                                            className="fx-button"
                                            id="cancel-edit-profile"
                                            onClick={() => history.push('/profile')}
                                        > Cancel
                                        </FxButton>
                                    </Grid>

                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-edit-profile-changes-btn"
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>)
                        :
                        <FxSkeltonList height="35rem" />
                    }
                </FxCard>
            </Grid >
        );
    })


function formatDate(date: any) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const usa_state: any = [
    { "value": "AL", "label": "Alabama" },
    { "value": "AK", "label": "Alaska" },
    { "value": "AS", "label": "American Samoa" },
    { "value": "AZ", "label": "Arizona" },
    { "value": "AR", "label": "Arkansas" },
    { "value": "CA", "label": "California" },
    { "value": "CO", "label": "Colorado" },
    { "value": "CT", "label": "Connecticut" },
    { "value": "DE", "label": "Delaware" },
    { "value": "DC", "label": "District Of Columbia" },
    { "value": "FM", "label": "Federated States Of Micronesia" },
    { "value": "FL", "label": "Florida" },
    { "value": "GA", "label": "Georgia" },
    { "value": "GU", "label": "Guam" },
    { "value": "HI", "label": "Hawaii" },
    { "value": "value", "label": "Idaho" },
    { "value": "IL", "label": "Illinois" },
    { "value": "IN", "label": "Indiana" },
    { "value": "IA", "label": "Iowa" },
    { "value": "KS", "label": "Kansas" },
    { "value": "KY", "label": "Kentucky" },
    { "value": "LA", "label": "Louisiana" },
    { "value": "ME", "label": "Maine" },
    { "value": "MH", "label": "Marshall Islands" },
    { "value": "MD", "label": "Maryland" },
    { "value": "MA", "label": "Massachusetts" },
    { "value": "MI", "label": "Michigan" },
    { "value": "MN", "label": "Minnesota" },
    { "value": "UM", "label": "Minor Outlying Islands" },
    { "value": "MS", "label": "Mississippi" },
    { "value": "MO", "label": "Missouri" },
    { "value": "MT", "label": "Montana" },
    { "value": "NE", "label": "Nebraska" },
    { "value": "NV", "label": "Nevada" },
    { "value": "NH", "label": "New Hampshire" },
    { "value": "NJ", "label": "New Jersey" },
    { "value": "NM", "label": "New Mexico" },
    { "value": "NY", "label": "New York" },
    { "value": "NC", "label": "North Carolina" },
    { "value": "ND", "label": "North Dakota" },
    { "value": "MP", "label": "Northern Mariana Islands" },
    { "value": "OH", "label": "Ohio" },
    { "value": "OK", "label": "Oklahoma" },
    { "value": "OR", "label": "Oregon" },
    { "value": "PW", "label": "Palau" },
    { "value": "PA", "label": "Pennsylvania" },
    { "value": "PR", "label": "Puerto Rico" },
    { "value": "RI", "label": "Rhode Island" },
    { "value": "SC", "label": "South Carolina" },
    { "value": "SD", "label": "South Dakota" },
    { "value": "TN", "label": "Tennessee" },
    { "value": "TX", "label": "Texas" },
    { "value": "UT", "label": "Utah" },
    { "value": "VT", "label": "Vermont" },
    { "value": "VA", "label": "Virginia" },
    { "value": "VI", "label": "Virgin Islands" },
    { "value": "WA", "label": "Washington" },
    { "value": "WV", "label": "West Virginia" },
    { "value": "WI", "label": "Wisconsin" },
    { "value": "WY", "label": "Wyoming" }
]
