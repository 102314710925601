import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import { MerchantInfoCard } from './MerchantInfoCard';
import { MerchantFasterFunding } from './MerchantFasterFunding';
Logger.debug("MerchantViewDetails.tsx", "Merchant Details initializing");

/**
 * Component: MerchantViewDetails
 * Usage: To display merchant details
 */
export const MerchantViewDetails: React.FC<any> = React.memo((props) => {
        interface RouteParams {
            id: string
        }
        //get ID from page url
        const params = useParams<RouteParams>();
        const id = params.id
        const merchant_source = {
            url: "/merchant/id/" + id,
            method: "GET"
        }

        const dispatch = useDispatch()

        /**
         * Method to rest the component on unmount.so that when redirect back page it will call the api again
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Merchant Information', backButton:'/company'} } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <MerchantInfoCard id="merchant-details-info-card" source={merchant_source} />
                </Grid>
                <Grid container xs={12} sm={4} className="fx-container-right">
                <MerchantFasterFunding id="merchant-details-faster-funding" source={merchant_source} />
                </Grid>
            </Grid>
        );
    })
