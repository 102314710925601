import * as React from 'react';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as VerifiedIcon } from '../../../assets/svg/verified-new-icon.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FxInfoCard } from '../../Page/Cards/FxInfoCard';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { FxButton } from '../../Action/FxButton';
import moment from 'moment';
import { FxApplicationBanner } from './FxApplicationBanner';
import { FxInfoTooltip } from '../../Utils/FxInfoTooltip';
import { ReactComponent as DarkInfoIcon } from '../../../assets/svg/darkgrey-info-icon.svg';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

type FormValues = {
    entityOfficer: 'TRUE' | 'FALSE';
    leaderOfPublicCompany: 'TRUE' | 'FALSE';
    brokerDealer: 'TRUE' | 'FALSE';
    largeTrade: 'TRUE' | 'FALSE';
    politicallyExposed: 'TRUE' | 'FALSE';
    stockExchangeFinraMemberAssociated: 'TRUE' | 'FALSE';
}

export const FxAuthSignatoryDetail: React.FC<any> = (props) => {
    const beneficialOwnerDetail = props.ownerDetails?.beneficialOwner;
    const { register, setValue, control, handleSubmit } = useForm();
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available
    const basic_info_section_left = [
        {
            label: 'Date of Birth',
            value: 'dob',
        },
        {
            label: 'Social Security Number',
            value: 'last4ssn',
        },
        {
            label: 'Phone Number',
            value: 'authmobilePhone',
        },
        {
            label: 'Address',
            value: 'address',
        }
    ];

    /**
     * method to show tool tip amount
     * @param label :label input for header
     * @param description description of header element
     * @returns 
     */
    const ShowToolTip = (label: string, description: string) => {
        return <Grid item container alignItems='center'>
            <p>{label}</p>
        <FxInfoTooltip arrow placement={'right-start'} className={'fx-amount-info-tooltip'} title={description}>
            <DarkInfoIcon className="fx-permissions-tooltip fx-dark-grey-icon"></DarkInfoIcon>
        </FxInfoTooltip>
        </Grid>
    }

    const additional_info = [
        {
            label: ShowToolTip('Is Leader of Public Company? ', 'You are a director, officer or hold more than 10% of shares of another company.'),
            value: 'isLeaderOfPublicCompany',
            checked: beneficialOwnerDetail?.isLeaderOfPublicCompany || false,
        },
        {
            label: ShowToolTip('Is Broker Dealer?', 'You are a financial entity who is a buyer and seller of cash and securities for another broker-dealer.'),
            value: 'isBrokerDealer',
            checked: beneficialOwnerDetail?.isBrokerDealer || false,
        },
        {
            label: ShowToolTip('Is large trader?', 'You have traded more than 2 million shares/$20 million in a single day, or 20 million shares/$200 million of exchange listed securities in a month.'),
            value: 'isLargeTrader',
            checked: beneficialOwnerDetail?.isLargeTrader || false,
        },
        {
            label: ShowToolTip('Is Politically Exposed?', 'You are, or are related to a current or former senior political figure.'),
            value: 'isPoliticallyExposed',
            checked: beneficialOwnerDetail?.isPoliticallyExposed || false,
        },
        {
            label: ShowToolTip('Is Stock Exchange FINRA member ?', 'You work for, with, or are affiliated with a FINRA registered firm.'),
            value: 'hasStockExchangeFinraMemberAssociation',
            checked: beneficialOwnerDetail?.hasStockExchangeFinraMemberAssociation || false,
        },
    ]

    let customerInfo: any;

    if (props.data) {
        customerInfo = { ...props.data };
        if (businessCustomer && customerInfo?.beneficialOwner) {
            let isActAsAuthorizedSignatory = customerInfo['beneficialOwner'].find((item: any) => item.actAsAuthorizedSignatory === true);
            if (isActAsAuthorizedSignatory) {
                let isPrimaryAddress = isActAsAuthorizedSignatory.mailingAddress.find((elem: any) => elem.isPrimary === true);
                if (isPrimaryAddress) {
                    customerInfo.address = isPrimaryAddress.addressLine1 + ', ' + (isPrimaryAddress.addressLine2 ? isPrimaryAddress.addressLine2 + ', ' : '') + isPrimaryAddress.city + ', ' + isPrimaryAddress.state + ', ' + isPrimaryAddress.zip;
                }
                customerInfo.signatoryName = isActAsAuthorizedSignatory.fullName || '';
                customerInfo.dob = isActAsAuthorizedSignatory.dob ? moment(isActAsAuthorizedSignatory.dob).format('MM/DD/YYYY') : '';
                customerInfo.last4ssn = isActAsAuthorizedSignatory.last4ssn ? 'XXX-XX-' + isActAsAuthorizedSignatory.last4ssn : '';
                customerInfo.authmobilePhone = isActAsAuthorizedSignatory.mobilePhone || '';
                customerInfo.isUSCitizen = (isActAsAuthorizedSignatory.isUSCitizen === true) ? 'Yes' : 'No';
                customerInfo.email = isActAsAuthorizedSignatory.email || '';
                customerInfo.ownership = isActAsAuthorizedSignatory.businessDetails.ownershipPercentage || '';
                customerInfo.title = isActAsAuthorizedSignatory.businessDetails.title || '';
            }
        }
    }

    function handleAuthSignatoryDetailsChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        if (event.target?.type === 'checkbox') {
            props.setOwnerDetails({ 
                ...props.ownerDetails, 
                beneficialOwner: { 
                    ...props.ownerDetails?.beneficialOwner, 
                    [event.target.value]: !props.ownerDetails.beneficialOwner[event.target.value]
                }
            });
        }
    }

    /**
     * Method used to request to apply debit card
     */
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        let req: any = { ...data }
        const additionalValues: any = {
            'isLeaderOfPublicCompany': false,
            'isBrokerDealer': false,
            'isLargeTrader': false,
            'isPoliticallyExposed': false,
            'hasStockExchangeFinraMemberAssociation': false
        }

        if (req.authSignatoryDetails?.length) {
            Object.keys(additionalValues).forEach(additionaVal => {
                if (req.authSignatoryDetails.includes(additionaVal)) {
                    additionalValues[additionaVal] = true;
                } else {
                    additionalValues[additionaVal] = false;
                }
            })
        } else {
            Object.keys(additionalValues).forEach(additionaVal => {
                additionalValues[additionaVal] = props.ownerDetails?.beneficialOwner[additionaVal] || false;
            })
        }
        req = additionalValues;
        // req = clean(req);
        props.setOwnerDetails({...props.ownerDetails, beneficialOwner: {...props.ownerDetails.beneficialOwner, ...req}});
        // onSubmit function define in parent component from there we will fire API
        if (props.onSubmit) {
            props.onSubmit(req)
        }
    }

    return (
        <Grid container item xs={12} direction="row" className='fx-review-auth-signatory-details'>
            <Grid item xs={12} marginY='1rem'>
                <FxApplicationBanner text='Opt in for Treasure Investment Account!'/>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} paddingLeft='1rem'>
                    <Typography className='fx-owner-name'>{customerInfo.signatoryName} (Ownership {customerInfo.ownership ? customerInfo.ownership + '%' : '    '})</Typography>
                    <Typography className='fx-owner-title'>{customerInfo.title}</Typography>
                    <Typography className='fx-owner-email'>{customerInfo.email}</Typography>
                    <Grid display='inline-flex' alignItems='center' className='fx-owner-signatory-card'>
                        <VerifiedIcon className={'permission-svg'} />
                        <Typography>AUTHORIZED SIGNATORY</Typography>
                    </Grid>
                    <Typography className='fx-owner-signatory-note'>This authorised signatory will get access to manage Deposit accounts.</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">BASIC INFORMATION</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FxInfoCard title="BASIC INFORMATION" className={'fx-treasure-details-info-card'} notiltle={true} column={basic_info_section_left} data={customerInfo} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">ADDITIONAL INFORMATION *</Typography>
                    </Grid>

                    <Grid item xs={12} paddingLeft='2rem'>
                        <FxCheckEdit
                            register={{ ...register("authSignatoryDetails") }}
                            className='fx-privacy-text' control={control}
                            id="authSignatoryDetails"
                            name="authSignatoryDetails"
                            data={additional_info}
                            row='vertical'
                            setValue={setValue}
                            onChange={handleAuthSignatoryDetailsChange}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} textAlign={'right'} paddingTop={'1.3rem'} paddingBottom='4rem'>
                    <FxButton
                        variant='contained'
                        className='fx-button fx-button-cancel'
                        id="business-info-btn-back"
                        onClick={() => props.onChange(2)}>BACK</FxButton>
                    <span className="fx-padding-right-16" />
                    <FxButton
                        disableRipple={false}
                        className='fx-button fx-button-theme'
                        id="business-info-btn-continue"
                        type='submit'>
                        {props.loading ? <CircularProgress size={20} /> : 'SAVE AND CONTINUE'}  
                    </FxButton>
                </Grid>
            </form>
        </Grid>
    )
}