/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';

export enum DataSourceEnum {
    INLINE,
    API
}
export interface IDataSource {
    type?: DataSourceEnum;
    api?: string;
    data?: any;
}


export interface IConfig {
    id: string;
    params?: any;
    components?: string | IConfig[];
    events?: any;
    source?: IDataSource;
    toolbar?: any
}

//filter
interface IFieldData {
    label: string;
    value: string;
    checked?: boolean;
}

interface IFilterField {
    label: string;
    fieldName: string;
    toLabel?: string;
    type: string;
    className?: string;
    operator?: string;
    data?: IFieldData[];
    notAllowFutureDate?: boolean;
    defaultValue?: unknown;
}

export interface IFilterFields {
    fields: IFilterField[]
}

interface IFilterColumn {
    label?: string;
    enable: boolean;
    fieldName: string;
    type: string;
    default: boolean;
    style?: object;
    dataStyle?: unknown;
    format?: string;
    icon?: React.ReactElement;
    className?: string;
    truncateChars?: number;
    valueTransformer?:(value:any)=>any;
}

export interface IFilterColumns {
    title: string;
    columns: IFilterColumn[]
}

export interface IDataGridProps  {
    id: string;
    column: IFilterColumns;
    pagination: boolean;
    pageNumber: number;
    pageSize: number;
    resultPerPage?: number;
}

//source
export interface ICriteriaFilter {
    key: string;
    operator: string;
    values: string[];
}

interface ISourceData {
    pageSize?: number;
    pageNumber?: number;
    sortOptions?: {
        sortOrder: string;
        sortBy: string;
    },
    criteria?: {
        filters?: ICriteriaFilter[]
    }
}

export interface ISource {
    url: string;
    method: string;
    baseUrl?: boolean;
    expiry?: number;
    data?: ISourceData;
}

export type ScheduleMenuItemNameType = "contact" | 'one_time' | 'my_account';

export interface IScheduleMenuItem {
    title: string;
    selected?: boolean;
    icon?: React.ReactElement;
    link: string;
    name: ScheduleMenuItemNameType;
    narration?:string;
}

export interface IScheduleLinkItem {
    link: string;
    name: ScheduleMenuItemNameType;
}

export enum CustomerType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    JOINT_TENANCY = "JOINT_TENANCY"
}


export interface IAccountOptions {
  value: string;
  label: string;
  type: string;
  subType: string;
  prefix?: React.ReactElement;
}
export enum LoggedInUserTypeEnum {
    PARTNER = 'PARTNER',
    CUSTOMER = 'CUSTOMER',
    PM = 'PORTAL_USER',
    MXM = 'MXM',
    SMB = 'SMB'
}

export enum AgreementsEnum {
  CASH_BUILDER_RATE_CARD = 'PASSPORT_CASH_BUILDER_ACCOUNT_RATE_CARD',
  CASH_BUILDER_PLUS_RATE_CARD = 'PASSPORT_CASH_BUILDER_PLUS_ACCOUNT_RATE_CARD',
  COMMERCIAL_RATE_CARD = 'PASSPORT_COMMERCIAL_DEPOSIT_ACCOUNT_RATE_CARD',
  CONSUMER_RATE_CARD = 'PASSPORT_CONSUMER_DEPOSIT_ACCOUNT_RATE_CARD',
  COMMERCIAL_DEBIT_CARD = 'COMMERCIAL_DEBIT_CARD_CARDHOLDER_AGREEMENT',
  CONSUMER_DEBIT_CARD = 'CONSUMER_DEBIT_CARD_CARDHOLDER_AGREEMENT',
  ACCOUNT_AGREEMENT = 'PASSPORT_ACCOUNT_AGREEMENTS',
  MERCHANT_AGREEMENT = 'PASSPORT_MERCHANT_SERVICES_AGREEMENT',
  MERCHANT_RATE_CARD = 'PASSPORT_MERCHANT_SERVICES_RATE_CARD'
}

export enum AgreementTypeEnum {
  PASSPORT_AGREEMENT = 'PASSPORT_AGREEMENT',
  CASH_BUILDER_PLUS_ACCOUNT_AGREEMENT = 'PASSPORT_CASH_BUILDER_PLUS_ACCOUNT_AGREEMENT',
  CASH_BUILDER_ACCOUNT_AGREEMENT = 'PASSPORT_CASH_BUILDER_ACCOUNT_AGREEMENT',
  RATE_CARD_AGREEMENT = 'RATE_CARD',
  DEBIT_CARD_AGREEMENT = 'DEBIT_CARD_AGREEMENT'
}


export enum ThemeEnum {
    ORANGE_THEME = 'Passport Orange Theme',
    GREEN_THEME = 'Passport Green Theme',
};
