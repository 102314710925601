import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { ReactComponent as DownArrow } from "../../../../assets/svg/down-black-triangle-arrow.svg";
import { ReactComponent as UpArrow } from "../../../../assets/svg/up-black-triangle-arrow.svg";
import { useFieldArray } from "react-hook-form";
import { FxLink } from "../../../Action/FxLink";
import { useSelector } from "react-redux";
import { FxFundingRuleAssociationCard } from "./FxFundingRuleAssociationCard";
import FxRadioEdit from "../../../Input/FxRadio/FxRadioEdit";

/**
 * Component: Funding Rules Create Screen >> Association Section Wrapper component
 * Usage: To render the Association section of the create screen
 */
export const FxSplitFundingAssociation: React.FC<any> = React.memo((props) => {
  const [expandedAssociation, setExpandedAssociation] = React.useState(
    props?.data?.length > 0 ? true : false
  );
  const [accordingIcon, setAccordingIcon] = React.useState(<DownArrow />);
  const [allMerchant, setAllMerchant] = React.useState("toAllMerchants");

  const merchantConditions = [
    { label: "Apply to all Merchants", value: "toAllMerchants" },
    { label: "Custom", value: "CustomAssociation", disabled: props?.ownership === 'INHERITED' }
  ];

  /**
   * create a Form Field Array
   */
  const { fields, append, remove, update } = useFieldArray({
    control: props.control,
    name: "funding-rule-association",
    shouldUnregister: true,
  });

  /**
   * Method to add Field
   */
  function handleAppend() {
    append({});
  }

  //By default association card should open
  useEffect(()=>{
    handleAssociationSectionOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  //On load while clone card should open by defualt if no data
  useEffect(() => {
    if (props?.isEdit && props?.data?.length === 0) {
      handleAssociationSectionOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Method handles the opening of the association Section Accordian
   */
  const handleAssociationSectionOpen = () => {
    if (expandedAssociation === true && fields.length >= 1) {
      setExpandedAssociation(false);
      setAccordingIcon(<DownArrow />);
    } else {
      if (fields.length === 0) {
        handleAppend();
      }
      setExpandedAssociation(true);
      setAccordingIcon(<UpArrow />);
    }
  };

  /**
   * Method to handle the accordian close event when 1 item is left
   * @param index
   * @param length
   */
  const handleAccordianClose = (index: any, length: any) => {
    remove(index);
    setExpandedAssociation(false);
    setAccordingIcon(<DownArrow />);
  };

  /**
   * Method to handle deletion of the association accordian rows
   * @param index
   * @param length
   */
  const removingAssociation = (index: any, length: any) => {
    remove(index);
  };

  /**
   * To get mcc details
   */
  const mccApiData = useSelector((state: any) => {
    if (state.data['faster-funding-data-list']) {
      return state.data['faster-funding-data-list']?.params
    }
  });

    /**
   * useEffect to load the api data into the useFieldArray Object
   */
    useEffect(() => {
      if (props?.data?.length > 0) {
        setAllMerchant('CustomAssociation');
        fields.length = props?.data?.length;
  
        for (let i = 0; i < props?.data?.length; i++) {
          update(i, props?.data[i]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data,mccApiData]);

  
  /**
   * On change either the custom options will be shown or hidden and delete newly added custom options
   * @param ev selected option
   */
  const handleAssociationChange = (ev: any) => {
    if (ev?.target?.value === 'toAllMerchants') {
      setAllMerchant('toAllMerchants');
      remove();
    } else {
      setExpandedAssociation(true);
      if(props?.isEdit){
        remove();
        removeNewAssociation();
      } else {
        remove();
      }
      !props?.data?.length && handleAppend();
      setAllMerchant('CustomAssociation')
    }
  }

  /**
   * Method to remove new added associations
   */
  const removeNewAssociation = () => {
    for (let i = 0; i < props?.data?.length; i++) {
      update(i, props?.data[i]);
    }
  }

  return (
    <Grid container paddingTop={"2rem"}>
      <Grid item xs={12} sm={12}>
        <fieldset className="fx-container-create-schdule-summery fx-splitfunding">
          <Grid container xs={12}>
            <Accordion className="fx-criteria" expanded={expandedAssociation}>
              <AccordionSummary>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    justifyContent={"flex-start"}
                    className="fx-container-funding-rules-criteria-summery"
                  >
                    <Typography className="fx-summary-title" variant="h3">
                      {fields.length > 0 && expandedAssociation === false && allMerchant !=='toAllMerchants'
                        ? `Association (${fields.length})`
                        : `Association`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    textAlign={"right"}
                  >
                    <FxLink onClick={handleAssociationSectionOpen}>
                      <Typography
                        className="fx-add-button-theme-text"
                        variant="h3"
                      >
                        {fields.length === 0 && expandedAssociation === false
                          ? "+ Add"
                          : accordingIcon}
                      </Typography>
                    </FxLink>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12}>
                  <FxRadioEdit register={{ ...props?.register("applicableMerchants") }} control={props?.control} className={'radio-edit'} data={merchantConditions} id="rule-merchat-form-card-radio" name="applicableMerchants"
                    defaultValue={allMerchant} setValue={props?.setValue} onChange={handleAssociationChange} />
                </Grid>
                {allMerchant === 'CustomAssociation' && props?.ownership !== 'INHERITED' && <Grid
                  item
                  xs={12}
                  sm={12}
                  width={"100%"}
                  marginTop={'2rem'}
                  className={
                    "fx-approval-policy-condition fx-create-approval-policy"
                  }
                  id={"add-association-funding-rule"}
                >
                  <fieldset className="fx-container-create-schdule-summery fx-condition-summary funding-rule-criteria-section fx-splitfunding-condition">
                    {fields.map((field: any, index: any) => {
                      return (
                        <FxFundingRuleAssociationCard
                          id={`funding-rule-association-row-` + index}
                          control={props.control}
                          index={index}
                          value={field}
                          data={props?.data}
                          setValue={props.setValue}
                          register={props.register}
                          errors={props.errors}
                          handleAppend={handleAppend}
                          handleRemove={() => {
                            fields.length === 1
                              ? handleAccordianClose(index, fields.length)
                              : removingAssociation(index, fields.length);
                          }}
                          isAddButtonDisabled={
                            fields.length !== index + 1 || fields.length === 2
                          }
                          fieldLength={fields.length}
                          getValues={props.getValues}
                          update={update}
                          watch={props.watch}
                          mccApiData={mccApiData}
                        />
                      );
                    })}
                  </fieldset>
                </Grid>}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </fieldset>
      </Grid>
    </Grid>
  );
});
