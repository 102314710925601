import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as PassportLogoMXM } from '../../../assets/svg/logo/passportNameLogoGreenBlue.svg';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getKey } from '../../../libs/utils/storageManager';
import { processAPIResponse, useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import { FxCongratulationCard } from '../../Data/FxCongratulationCard';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { ISelectData } from '../../Input/FxSelect/FxMaterialSelect';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { addressFormatter } from '../../Utils/CommonBaseClass';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxInfoLayout } from '../DrawerLayaouts/FxInfoLayout';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const httpClient = HttpClient.getClient();

Logger.debug("FxApplyDebitCard.tsx", "apply debit card initializing")

/**
 * Component used inside the right drawer to apply for a debit card
 */
export interface IApplyDebitCardPayload {
    isDebitCardApplied: boolean;
}

interface IFxApplyDebitCard {
    onDrawerClose: (event: any, payload?: IApplyDebitCardPayload) => void;
}

interface IMailingAddress {
    addressLine1: string;
    city: string;
    id: number;
    isPrimary: boolean;
    state: string;
    zip: string;
}

interface ICustomer {
    id: number;
    fullName: string;
    mailingAddress: IMailingAddress[];
}

interface IUserData {
    business: {
        beneficialOwner: ICustomer[]
    }
}

type FormValues = {
    authorisedUser: number;
    shippingAddress: number;
    cardType: string;
    expressDelivery: 'YES' | 'NO';
}

export const FxApplyDebitCard: React.FC<IFxApplyDebitCard> = ({ onDrawerClose }) => {

    const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm();
    const [isCardLinked, setIsCardLinked] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [agreementInfo, setAgreementInfo] = useState<IFileInfo[]>();
    const [addresses, setAddresses] = useState<ISelectData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState<number | string>('');
    const isSmallScreen = useIsMobileOrTabletScreen()
    const history = useHistory();
    const [isExpressDelivery, setExpressDelivery] = useState<boolean>(false);

    const express_delivery = [{
        label: 'Express Delivery',
        value: 'expressDelivery',
        checked: isExpressDelivery || false,
    }];

    /**
    * to get customer details
    */
    const customerDetails = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']?.params?.customerDetails
        }
    });

    /**
     * useEffect to set default address 
     */
    useEffect(() => {        
        const addressArray = customerDetails?.business?.beneficialOwner?.[0]?.mailingAddress
        const addressOptions = addressArray.map((address: any) => {
            return {
                value: address.id,
                label: addressFormatter("", address)
            }
        })
        const primaryAddress = addressArray.find((address: any) => address.isPrimary);
        primaryAddress?.id && setDefaultAddress(primaryAddress.id);
        setAddresses(addressOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDetails?.business?.beneficialOwner])

    // debit terms & condition options
    const debitCardTerms = [
        {
            label: 'By submitting this form, you agree to the Commercial CardHolder Agreement terms and conditions that governs your use of their commercial B2B Debit Card provided by Priority Technology Holdings, Inc. You also agree to receiving electronic communications regarding your account, and you certify that the information you provided is complete and accurate.',
            value: 'yes'
        }
    ];

    const applyCardLayoutHeader = <>
        <h2>Get Corporate Debit card&nbsp;</h2>
        <div>
            <h2>with &nbsp;</h2>
            <PassportLogoMXM />
        </div>
    </>

    const goToDashboard = (event: any) => {
        onDrawerClose(event, { isDebitCardApplied: true });
    }

    /**
     * Method to get onchange value of authorised user select
     */
    const handleSelectUserChange = (event: any) => {
        const selectedUser = customerDetails?.business?.beneficialOwner?.find(((user:any) => user.id === event.target.value));
        if (selectedUser) {
            const addressOptions = selectedUser.mailingAddress.map((address:any)=>{
                return {
                    value: address.id,
                    label: addressFormatter("", address)
                }
            })
            const primaryAddress = selectedUser.mailingAddress?.find((address:any) => address.isPrimary);
            primaryAddress?.id && setDefaultAddress(primaryAddress.id);
            setAddresses(addressOptions);
        }
    }

    /**
     * useEffect to set new default shipping address for select
     */
    useEffect(() => {
        setValue('shippingAddress', defaultAddress);
    },[defaultAddress, setValue]);

    /**
     * Function to transform data for authorised user select
     */
    function userDataTransformation(data: IUserData) {
        try {
            return data?.business?.beneficialOwner?.map((option) => {
                return {
                    value: option.id,
                    label: `${option.fullName} (${option.id})`
                }
            })
        }
        catch (e) { }
    }


    /**
     * Method to handle form change event of agreement checkbox
     */
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        setBtnDisabled(!event.target.checked)
    }

    /**
     * Method used to call request debit card API
     * @param body :form data
     * @returns
     */
    async function requestDebitCard (body: any) {
        try {
            return  await httpClient.post(getCustomerUrl('account/id/' + getKey("selectedAccount")?.id + '/debitCard',false), body);
        } catch (err) {
            Logger.error("RequestDebitCard.tsx", "error", err);
            return false;
        }
    }

    /**
     * Method used to request to apply debit card
     */
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
      const linkedDocuments = agreementInfo?.map((file: IFileInfo) => {
        return {
          purpose: 'DEBIT_CARD_AGREEMENT',
          document: {
            type: file?.fileType,
            name: 'Terms&Condition.pdf',
            base64encodedContent: file?.base64
          }
        }
      })
        let requestBody = {
            type: 'DEBIT',
            shippingDetail: {
                "address": {
                    "id": data.shippingAddress
                },
                "expressDelivery": data.expressDelivery === 'YES'
            },
            linkedDocument: linkedDocuments,
            cardholder:{
                "type":  "BENEFICIAL_OWNER",
                "id": data.authorisedUser
            },
            cardProgram: {
                id: data.cardType
            }
        }
        setIsLoading(true);
        let status: any = await requestDebitCard(requestBody);
        status = processAPIResponse(status)
        setIsLoading(false);
        if (status.status) {
            //api success
            history.push('/redirect/dashboard')
            setIsCardLinked(true);
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    return (
        <Grid container id="apply-debit-card" className="fx-apply-debit-card">
            <Grid item className={'fx-drawer-left-content'}>
                <FxInfoLayout layoutHeader={applyCardLayoutHeader} />
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                {!isCardLinked ?
                <Grid container className={ isSmallScreen ?  'fx-apply-debit-card-main-content-mobile' : 'fx-apply-debit-card-main-content'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">CARDHOLDER INFO</Typography>
                            </Grid>
                          <Grid item container xs spacing={2} className="fx-apply-debit-card-main-content-individual-shipping-info">
                            <Grid item xs={12}>
                              <FxMaterialSelect register={{ ...register('authorisedUser') }} control={control}
                                                rules={{ required: true }}
                                                className={errors.authorisedUser ? 'border-error-input' : 'fx-input-edit fx-input-edit-text-layout'}
                                                id="apply-debitCard-authorisedUser"
                                                data={userDataTransformation(customerDetails)}
                                                name="authorisedUser" label={'Authorised Signatory'}
                                                value={customerDetails?.business?.beneficialOwner?.[0]?.id || ''}
                                                setValue={setValue}
                                                onChange={handleSelectUserChange} />
                            </Grid>
                            <Grid item xs={12}>
                              <FxMaterialSelect register={{ ...register('shippingAddress') }} control={control}
                                                rules={{ required: true }}
                                                className={errors.shippingAddress ? 'border-error-input fx-input-edit' : 'fx-input-edit fx-input-edit-text-layout'}
                                                id="apply-debitCard-shippingAddress"
                                                name="shippingAddress" label={'Shipped to'}
                                                setValue={setValue}
                                                data={addresses}
                                                readOnly={!watch('authorisedUser')}
                                                value={defaultAddress}/>
                            </Grid>
                            <Grid item xs={12}>
                              <FxCheckEdit
                                register={{ ...register("expressDelivery") }}
                                className='fx-privacy-text' control={control}
                                id="expressDelivery"
                                name="expressDelivery"
                                data={express_delivery}
                                row='vertical'
                                setValue={setValue}
                                onChange={()=>setExpressDelivery(!isExpressDelivery)}
                              />
                              <Grid item xs={12} className='fx-apply-debit-card-main-content-individual-shipping-info-text'>
                                <Typography>With this you incur an additional charge as per your fee schedule</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                              <FxAgreementLinkCard
                                agreementTypes={['DebitCard']}
                                onFileInfoReady={setAgreementInfo}
                              />
                            </Grid>
                            {!!agreementInfo?.length && <Grid item xs={12} className="fx-submit-agree">
                                <FxCheckEdit register={{ ...register('debitCardTerms') }}
                                             control={control}
                                             id="debit-card-terms-condition-agree" name="debitCardTerms"
                                             className="fx-privacy-text"
                                             data={debitCardTerms}
                                             row="vertical"
                                             onChange={(e: any) => {
                                               handleCheckboxChange(e);
                                             }}
                                             setValue={setValue} />
                            </Grid>}
                          </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <FxButton
                                    disableRipple={false}
                                    className={`fx-accept-button ${btnDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`}
                                    id="request-debit-card-accept"
                                    type="submit"
                                    disabled={btnDisabled}
                                    isSubmitting={isLoading}>Accept</FxButton>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
                    :
                    <FxCongratulationCard message={'Debit card successfully applied for your Passport Account.'} onButtonClick={goToDashboard} iconName="debitCard"/>
                }
            </Grid>
        </Grid>
    )
}