import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FxCard from '../../Container/FxCard';
import { Grid, Typography } from '@mui/material';
import FxRightDrawer from '../../Utils/fx-right-drawer';
import { FxTreasureDrawer } from './FxTreasureDrawer';
import { FxInvestmentAllocationCard } from './FxInvestmentAllocation';
import { FxInvestmentSummary } from './FxInvestmentSummary';
import { HomeTreasureListCard } from './HomeTreasureListCard';
import { TreasuryAccountBalanceCard } from './FxTreasureAccountBalanceCard';
import { useDispatch, useSelector } from 'react-redux';
import FxCardHeader from '../../Container/FxCardHeader';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { FxInvestmentInstructions } from './FxInvestmentInstructions';
import moment from 'moment';
const httpClient = HttpClient.getClient()

export const FxTreasureDashboard: React.FC<any> = (props) => {
    
    ({ props } = RegisterComponent(props)); 

    const dispatch = useDispatch()
    const [customerDetails, setCustomerDetails] = useState(null);
    const [portfolioData, setPortfolioData] = useState();
    const [portfolioCreated, setPortfolioCreated] = useState(0)
     /** 
     * to get accountList details
    */
     const accountListInfo = useSelector((state: any) => state?.data['account-list-sub-menu'] || null);
     const selectedAccount = accountListInfo?.params?.selectedOption;

     /**
     * useEffect to set default address 
     */
     useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        updateComponentParams(dispatch, props?.id, { selectedAccount:null});       
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Portfolio' } } });                
        getCustomerDetails(); 
       
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    /**
     * function to get Customer details
     */
    async function getCustomerDetails(){
        const customerInfo = await httpClient.get('customer/id/' + getKey('customerId'))
        setCustomerDetails(customerInfo.data)
    }

     /**
     * useEffect to set default address 
    */
     useEffect(() => {
          if(selectedAccount){
            getAccountDetails(selectedAccount.id);
            getLedgerList(selectedAccount.id);
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'home-ledger-list', 'account': selectedAccount.id } });
        }

        if(selectedAccount?.status === 'ACTIVE'){
            getPortfolioStatus();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, portfolioCreated]) 

    const ledger_request = {
        pageNumber: 1,
        pageSize: 25,
        sortOptions: {
          "sortOrder": "desc",
          "sortBy": "lastUpdatedOn"
        },
        criteria: {}
      }

    //  this need to update, we need to call after account fetched and investment account selected
    /**
     * to get Portfolio details 
    */
     async function getPortfolio() {
        await httpClient.get('/customer/id/' + getKey('customerId') + '/account/id/' + getKey("selectedAccount")?.id + '/portfolio').then(response => {
            setPortfolioData(response.data)
        }).catch(error => {
            return { ...error };
        })
     }

     /**
        * function to get account data and storage quick settle configuration
        * @param id :account id
    */
    async function getAccountDetails(id: number) {
        try {
            const res = await httpClient.get(getCustomerUrl("account/id/" + id));                  
            dispatch({ type: "DATA_UPDATE_COMPONENT", payload: { id: "investment-account-balance-card", data: res.data } });
        } catch (e) { }
    }  
    
      /**
        * function to get account data and storage quick settle configuration
        * @param id :account id
    */
      async function getLedgerList(id: number) {
        try {
            const filters: any = [];
            filters.push(
                {
                  "operator": "gte",
                  "key": "lastUpdatedOn",
                  "values": [
                    moment().subtract(1, 'months').format('MM/DD/YYYY')
                  ]
                },
                {
                  "operator": "lte",
                  "key": "lastUpdatedOn",
                  "values": [
                    moment().format('MM/DD/YYYY')
                  ]
                },
                {
                  "operator": "eq",
                  "key": "account.id",
                  "values": [
                    getKey("selectedAccount")?.id
                  ]
                },
                {
                    "operator": "eq",
                    "key": "isRealTime",
                    "values": [
                        "true"
                    ]
                })
              ledger_request.criteria = { filters }

            const ledger_list = await httpClient.post(getCustomerUrl("account/id/" + id + '/ledger/list', false), ledger_request);
            updateComponentParams(dispatch, "customer-basic-info", { 'ledgerList': ledger_list?.data })           
        } catch (e) { }
    }  

    async function getPortfolioStatus() {   
        const response = await httpClient.get('/customer/id/' + getKey('customerId') + '/account/id/' + getKey("selectedAccount")?.id + '/portfolio/investmentProvider').then(response => {
            return response;
        }).catch(error => {
            return { ...error };
        })
        if(response.data) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'investment-account-status', data: { portfolioStatusData: response.data?.portfolio[0] } } });
            if(response.data?.portfolio[0]?.status === 'ACTIVE') {
                getPortfolio();
            }
        }
    }

    /**
     * Function to Open Treasure Onboarding Drawer
     */
    const openPassportDrawer = (async () => {
        await FxRightDrawer.show(({ onClose }) => {
            return <FxTreasureDrawer onDrawerClose={onClose} customerInfo={customerDetails} setPortfolioCreated={setPortfolioCreated}/>
        })
      })
    
      const onTransferAmountFinishedHandle = useCallback(
        () => {
            if(selectedAccount){
                getAccountDetails(selectedAccount.id)
            }
            
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedAccount]
      );    
    
    const accountActivationInProgress = useMemo(() => {
        if (selectedAccount && selectedAccount.status === 'INACTIVE') {
            return <Grid item xs={12}>
            <FxCard className='fx-theme-passport'>
                <FxCardHeader>
                    <Grid container direction="row" xs={12}   >
                        <Grid item xs={12}>
                            <Grid item container >                                   
                                <Grid item xs container justifyContent="flex-start">
                                    <Grid item xs ={12} >
                                        <Typography variant='h3'>Account Activation in Progress!</Typography>
                                    </Grid>
                                    <Grid item xs={12} > 
                                        <Typography variant='h6'>It may take few minutes to complete the activation. You will receive an email once activation is successful.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>                           
                </FxCardHeader>
            </FxCard>
        </Grid>
        }

        return null;

    }, [selectedAccount])  

    return (
        <>
            <Grid container xs={12} className='fx-treasure-dashbaord'>
                {accountActivationInProgress}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <TreasuryAccountBalanceCard id='investment-account-balance-card' onTransferAmountFinishedHandle={onTransferAmountFinishedHandle} />
                            </Grid>
                            <Grid item xs={12}>
                                <HomeTreasureListCard id='investment-leLedger-transaction' />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <FxInvestmentSummary id='investment-summary-card' openPassportDrawer={openPassportDrawer} selectedAccount={selectedAccount} portfolioData={portfolioData} customerDetails={customerDetails}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FxInvestmentAllocationCard portfolioData={portfolioData}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FxInvestmentInstructions id='investment-instructions' />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
