/* eslint-disable  @typescript-eslint/no-explicit-any */
type ValueType = {
    value: any;
    expires?: number;
}

/**
 * Simple key - value storage which lives during app lifetime
 */
class AppStorage {
    constructor() {
        this._storage = {}
    }

    public get = <T = any>(key: string): T | undefined => {
        if(!this._storage[key]) {
            return;
        }
        if(this._storage[key].expires && Date.now() - this._storage[key].expires! < 0) {
            this.removeKey(key);
            return
        }
        return this._storage[key].value;
    }

    public set = (key: string, value: any, expires?: number) => {
        this._storage[key] = {
            value,
            expires,
        }
    }

    public removeKey = (key: string) => {
        delete this._storage[key];
    }

    public clear = () => {
        this._storage = {}
    }

    private _storage: Record<string, ValueType>;
}

export const appStorage = new AppStorage();
