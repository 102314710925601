import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { IPrimaryFilterData } from '../../Data/FxPrimaryFilter';
import { ViewProfile } from '../Index/Passport/ViewProfile';
import { checkPermission,getPaymentMethodsByCustomerType,updateComponentParams } from '../../Utils/CommonBaseClass';
import { IFilterColumns, IDataGridProps, IFilterFields } from '../../../types/common.interfaces';
import { Filter } from '../../Data/Filter';
import { Logger } from '../../../libs/utils/logger';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { FxPrimaryFilter } from '../../Data/FxPrimaryFilter';
import { FxDivider } from '../../Data/FxDivider';
import { FxButton } from '../../Action/FxButton';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { getKey } from '../../../libs/utils/storageManager';
import { FxGridList } from '../../Data/FxGridList';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component: ManageContactList
 * Usage: For contact listing
 */

export const ManageContactList: React.FC<any> = React.memo(() => {

  const isEnableBillPaymentVisible = getKey('isEnableBillPaymentVisible') === 'true';
  const dispatch = useDispatch();
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;

  const addPermission = {
    entity:"Other Entities",
    name:"Contact",
    operation:"CREATE"
  }

  const viewPermission = {
    entity:"Other Entities",
    name:"Contact",
    operation:"VIEW"
  }

  //API for contact list
  const src = {
    url:  "/contact/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "criteria": {
        "filters": [

        ]
      }
    }
  }

  // Contact list columns
  const column: IFilterColumns = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "label": "ID",
        "enable": true,
        "fieldName": "id",
        "type": "fri://fieldtype/default",
        "default": true,
        "style": { "width": "9rem"}
      },
      {
        "label": "DISPLAY LABEL",
        "enable": true,
        "fieldName": "name",
        "type": "fri://fieldtype/default",
        "default": true
      },
      {
        "label": "Created On",
        "enable": true,
        "fieldName": "createdOn",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "Last Updated On",
        "enable": true,
        "fieldName": "lastUpdatedOn",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "Last Updated By",
        "enable": true,
        "fieldName": "lastUpdated",
        "type": "fri://fieldtype/default",
        "default": true
      }
    ]
  };

  const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "enable": true,
        "fieldName": "description",
        "type": "fri://fieldtype/custom",
        "xs": "12",
        "alignItems": 'center'
      },
      {
        "enable": true,
        "fieldName": "divider",
        "type": "fri://fieldtype/custom",
        "xs": "12",
      }
    ]
  }


  const history = useHistory()
  function redirectToCreatePage() {
    history.push('/payee/add');
  }

  /**
  * useEffect to load title while loading page
  */
  useEffect(() => {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Contacts' } } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => () => {
    updateComponentParams(dispatch, 'manage-payees-filter', { resetFilter: true });
  }, [dispatch]);

  /**
   * Method to redirect to the external account details page
   * @param event :event object
   * @param data : external account data
   */
  function redirectDetailsPage(event: any, data: any) {
    try
    {
      if(checkPermission(viewPermission)){
        const url = data.url;
        const parts = url.split('/');
        const idValue = parts[parts.length-1];
        history.push(`/payee/view/${idValue}`);
      }
      
    }
    catch(e){}
  }     

  //dataGrid props
  const dataGridProps: IDataGridProps = {
    id: "manage-payees-card-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };
  // filter fields
  const filterFields: IFilterFields = {
    "fields": [
      {
        "label": "Payment Mode",
        "fieldName": "allowedMethod",
        "type": "fri://fieldtype/checkbox",
        "data": [
          { label: "ACH", value: "ACH", "checked": false },
          { label: "WIRE", value: "WIRE", "checked": false },
          { label: "INTERNATIONAL WIRE", value: "INTERNATIONAL_WIRE", "checked": false },
          { label: "CHECK", value: "CHECK", "checked": false },
          { label: "CARD", value: "CARD", "checked": false }
        ],
      },
    ]
  }
  const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
  /**
   * Method to transform data
   * @param data : contact data
   */
  function transformContactList(data: any) {
    try {
      const newData: any = [];
      // eslint-disable-next-line array-callback-return
      data?.map((item: any, i: number) => {
        newData[i] = { ...data[i] }
        if (item) {
          newData[i].lastUpdated = item?.lastUpdatedBy?.username
        }
      });
      return newData;
    }
    catch (e) {
      Logger.error("ManageContactList.tsx", "error", e);
      return false;
    }
  } 

  /**
  * @param data : create contact grid mobile
  */
  const createContactGridItem = (item: any, modes:any) => (
    <Grid item container xs={12} className='fx-grid-list-content'>
      <Grid item container xs={12} alignItems='center'>
        <Grid item container xs={12}>
          <Grid item xs={7} sm={6}>
            <Grid className='fx-info-label-value'>
              <Typography> {item?.name}</Typography> 
            </Grid>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Grid>              
                 <FxDateTimeView tooltip={true} id={`contact-created-on-${item.id}`} value={item?.lastUpdatedOn} format={"MMM DD, yyyy"}></FxDateTimeView>             
            </Grid>
          </Grid>
        </Grid>        
        <Grid item container xs={12}>
          <Grid item className='fx-grid-list-method fx-info-label-value'>
            <FxPaymentModeStatus tooltip={true} id={'recurring-method'} noColor={true} value={isEnableBillPaymentVisible && businessCustomer ? (modes + ', CASH') : modes} fill={false} className="fx-status"></FxPaymentModeStatus>
          </Grid>
        </Grid>        
      </Grid>
    </Grid>
  );
  
  /**
  * Divider
  */
  const createContactDivider = () => (
      <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
  );

  /**
   * Method to transform data for mobile screen
  * @param data: Contact list
  * @returns :Grid Transformed data
  */
  const transactionHistoryGridTransformer = (data: any) => {
    const transformedData = data?.map((item: any) => ({
      description: createContactGridItem(item, getPaymentMethodsByCustomerType(item, businessCustomer)),
      divider: createContactDivider(),
      url: item?.url 
    }));

    return transformedData || [];   
  };

  //primary filters data
  const primaryFilterData: IPrimaryFilterData[] = [
    { label: 'Display Label', value: 'name', operator: 'like' }

  ];

  return (

    <Grid id="manage-payees-grid" item xs={12}>
      <Grid container className="fx-top-header" id="manage-payees-top-grid" item justifyContent="space-between" alignItems="center" xs={12} >
        <Grid item><h3 >Contacts</h3></Grid>
        <ViewProfile />
      </Grid>
      <Grid container xs={12}>
        <FxCard id="manage-payees-card" className="fx-theme-passport">
          <FxCardHeader id="manage-payees-card-header" className="fx-card-header">
            <Grid id="manage-payees-card-header-first-grid" item container justifyContent="space-between" xs={12} >
              <Grid id="manage-payees-card-header-second-grid">
                <Grid item>
                  <FxPrimaryFilter id="manage-payees-primary-filter" permissions={viewPermission} name="externals-primary-filter" data={primaryFilterData} dataGridProps={dataGridProps} defaultSelection="name"/>
                </Grid>
              </Grid>
              <Grid id="manage-payees-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                <Grid item>
                  <Filter id="manage-payees-filter" permissions={viewPermission} field={filterFields} dataGridProps={dataGridProps} ></Filter>
                </Grid>
                <Grid item className="fx-action-block-divider">
                  <FxDivider />
                </Grid>
                <Grid item>
                  <FxButton id="manage-payees-add-button" permissions={addPermission} variant="contained" className="fx-button fx-button-theme fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} >Add</FxButton>
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="manage-payees-card-body" className="fx-card-body-no-border-no-shadow">
            <Grid id="manage-payees-card-body-grid" item xs={12}>
              {
                !isSmallScreen ?  <DataGrid id="manage-payees-card-list" permissions={viewPermission} rowClick={redirectDetailsPage} tableClass="fx-table-passport" dataTransformer={transformContactList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />:
                <FxGridList id="manage-payees-card-list" source={src} permissions={viewPermission} dataTransformer={transactionHistoryGridTransformer} column={gridColumn} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
              }
             
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </Grid>
  )
})
