/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Chip, Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { Controller, useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import { CardCollectAdvancedOptions } from './CardCollectAdvancedOptions';


import {
    checkPermission,
    clean,
    convertEndData,
    convertToOnDayFormat,
    convertToTitleCaseAndAppendWithS,
    fetchLabelFromId,
    renderError,
    setPayeeInformation,
    transformRecurringRequestGlobal,
    isMethodSupported,
    truncateChars,
    updateComponentParams,
    customErrorMessage,
    maskAccountNumber
} from "../../Utils/CommonBaseClass";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import {
    accountLabelTransformation,
    createTransactionUrl,
    getEAList,
    getLocation,
    getMerchantData,
} from "./ScheduleGlobalFunctions";
import { FxSuccess } from "../../Data/FxSuccess";
import { FxRecurring } from "./Recurring/FxRecurring";
import { FxDateEdit } from "../../Input/FxDate/FxDateEdit";
import { FxSwitch } from "../../Action/FxSwitch";
import FxLabel from "../../Input/FxLabel/FxLabelView";
import FxLabelView from "../../Input/FxLabel/FxLabelView";
import { FxFileUpload } from "../../Input/FxFile/FxFileUpload";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { ReactComponent as DeleteIcon } from "../../../assets/svg/delete-icon.svg";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";
import FxModalGeneric from "../../Utils/FxModalGeneric";
import { AuthenticationVerificationModal } from "../AuthenticationModal/AuthenticationVerificationModal";
import HttpClient from "../../../libs/utils/httpClient";
import { isAchControlAllowed } from "../../Utils/ThemeChecker";
import { FxFasterFundingSwitch } from "../../Utils/FxFasterFundingSwitch";
import UIFeatureImpl from "../../../libs/services/uiFeatures";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";

/**
 * This component handles the collect via contact thorugh card method and edit 
 */

Logger.debug(
    "CreateCollectContactCard.tsx",
    "create collect to Contact Card initializing"
);

// src to fetch account
const acc_src = {
    url: "account/list",
    method: "POST",
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};

// src for contact list
const contact_list_src = {
    url: "contact/list",
    method: "POST",
    data: {
        pageNumber: 1,
        pageSize: 5,
        criteria: {
            filters: [
                {
                    key: "allowedMethod",
                    operator: "eq",
                    values: ["CARD"],
                },
            ],
        },
    },
};

export const CreateCollectContactCARD: React.FC<any> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const [contactCardLoad, setContactCardLoad] = useState(false);
    const [contactCards, setContactCards] = useState<any>([]); // external account array
    const [contactCardId, setContactCardId] = useState<any>();
    const [selCardBillingAddress, setSelCardBillingAddress] = useState<any>();
    const [source, setSource] = useState<any>(); // to set value in search contact field
    const [isSource, setIsSource] = useState(true);

    const [isPpiContact, setIsPpiContact] = useState(false);
    const [selectedPPI, setSelectedPPI] = useState("");

    const [allowDuplicate, setAllowDuplicate] = useState(false);

    const [repeatStatus, setRepeatStatus] = useState(false);
    const [recurringFormData, setRecurringFormData] = useState<any>();
    const [fileUploadData, setFileUploadData] = useState<any>();

    const [sendEnabled, setSendEnabled] = useState(true);
    const [captureFundsLater, setCaptureFundsLater] = useState(false);
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null); 
    const [invoiceDataAvailable, setInvoiceDataAvailable] = useState(false);

    
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available

    const pmLevelQuickSettle = getKey('isCardQuickSettleEnabled');
    const showInstantFunding = pmLevelQuickSettle || context?.data?.body?.processingDetail?.quickSettle;
    const accountLevelQuickSettle = getKey('accountLevelQuickSettle')?.card;
    const [instantFunding, setInstantFunding] = useState(false);
    const [merchantList, setMerchantList] = useState<any>([]);
    const [location, setLocation] = useState<any>();
    const customerName = getKey("CustomerName") || getKey('CustomerIndividualFullName');

    const dispatch = useDispatch();
    const history = useHistory();

    const docTypeData: any = [{ label: "Contract", value: "CONTRACT" }];

    //Merchant list API source
    const merchant_src = {
        url: "merchant/list",
        method: "POST",
        data: {
            "criteria": {
                "filters": [
                    {
                        "operator": "eq",
                        "key": "account.id",
                        "values": [getKey("selectedAccount")?.id]
                    }
                ]
            }
        }
    }

    // Params For edit option
    const params = useParams<any>();
    const urlContactId = params?.contactId;
    const scheduleId = params.id;

    const httpClient = HttpClient.getClient();

    const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, setError, watch, getValues } = useForm();

    /**
     * This method checks for the value from the component and if it is equal to '' then changes it to null for supported fields
     * @param value 
     * @returns 
     */
    const populateValue = (value: any) => (value === '' ? null : value);

    /**
       * This method draws the Order node containing the Invoice details in the processing Detail Node in request creation
       * @param data 
       * @returns 
    */
      const drawInvoiceNodes = (data: any) => {
        const {
            invoiceNumber,
            'invoice-amounts': { discountAmount, taxAmount, freightAmount, dutyAmount },
            taxExempt,
            addressLine1,
            addressLine2,
            city,
            state,
            zip,
            country,
            'invoice-items': invoiceItems
        } = data;
    
        let address = {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zip: zip,
            country: country,
        };

        address = clean(address);

        const shipmentDetail: any = {
          freightAmount: populateValue(freightAmount),
          dutyAmount: populateValue(dutyAmount),
          ...(Object.values(address).some((value) => value) && Object.values(address).length >1 && { address }),
        };

        const returnData: any = {
            invoice : 
                {
                    number: invoiceNumber,
                    discountAmount : populateValue(discountAmount),
                    taxAmount : populateValue(taxAmount),
                    taxExempt,
                    shipmentDetail: shipmentDetail
                }
        };

        if(formatInvoiceItemsData(invoiceItems).length > 0){
            returnData.invoice.lineItem = formatInvoiceItemsData(invoiceItems);
        }

        return returnData;
     };

      /**
       * THis method formats the invoice item data in to api friendly data,
       */
      const formatInvoiceItemsData = (data: any) => {
          //check for an empty array
          const hasNonEmptyObject = data.some((obj:any) => Object.keys(obj).length > 0);
          if (!hasNonEmptyObject) {
            return [];
          } else {
            return data.map((obj: any) => {
              obj = clean(obj);
              delete obj.totalAmount;
              return obj;
            });
          }
      };

    /**
       * Method saves the data of the invoice detail form in a state
       * @param data 
    */
    const saveInvoiceFormData = (data:any) => {
        setInvoiceData(data);
        setInvoiceDataAvailable(true);
    }

      
    // Methods Tab Data 
    const method_Tab = useMemo(() => {
        const tabs = [
            {
                className: "fx-link-sel",
                title: "CARD",
                disabled: !!scheduleId,
                link: "/transaction/collect/contact/add/card",
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "CREATE"
                  },
                  {
                    entity: "Recurring Transaction",
                    name: "CARD Collect",
                    operation: "CREATE"
                  },
                  {
                    entity: "Accounts",
                    name: "Card Collect",
                    operation: "EDIT"
                  }
                ]
            },
        ];

        if(isAchControlAllowed(getKey('collectAchControl'))){
            tabs.unshift({
                className: "",
                title: "ACH",
                disabled: !!scheduleId,
                link: "/transaction/collect/contact/add/ach",
                multiplePermission: true,
                permission: [{
                    entity: "Accounts",
                    name: "ACH Collect",
                    operation: "CREATE"
                  },
                  {
                    entity: "Recurring Transaction",
                    name: "ACH Collect",
                    operation: "CREATE"
                  },
                  {
                    entity: "Accounts",
                    name: "ACH Collect",
                    operation: "EDIT"
                  }
                ]
            })
        }

        return tabs;
    }, [scheduleId]);

    const [isloading, setIsloading] = React.useState(false);

    /**
     * Function to be called before loading the component
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });

        dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
                id: "create-collect-ach-from-contact-file-id-file-upload",
                files: [],
            },
        });

        // need to check
        if (urlContactId) {
            setContactExternalCard(urlContactId);
        }

        if (scheduleId) { // need to update the code for edit
            const src = {
                url: "/transaction/id/" + scheduleId,
                method: "GET",
            };
            dispatch({
                type: "DATA_API_PARAM_CHANGED",
                payload: { id: "edit-collect-via-contact-card", source: src },
            });
        } else {
            dispatch({
                type: "DATA_UPDATE_COMPONENT_REDUCER",
                payload: {
                    id: "page-title-details",
                    data: { title: "Collect Money", backButton: "/dashboard" },
                },
            });
        }

        const verifyProps = {
            open: false
        }
        updateComponentParams(dispatch, props.id, { verifyProps });
        handleMerchantListData();
    }, []);

    // useEffect to set edit data details
    useEffect(() => {
        if (context?.data?.body) {
            setFormValues(context?.data?.body);
        }
    }, [context?.data?.body]);

    /**
     * method to get the source data
     * @param contactId
     */
    function setContactExternalCard(contactId: any) {
        setContactCards([]);
        setIsSource(false);

        getEAList(contactId).then((data: any) => {
          const cards = data?.card;
            if (cards && cards.length > 0) {
                setSource(setPayeeInformation(data)); //to set search contact data
                setIsSource(true);
                if (scheduleId && context?.data?.body) {
                    const selectedCardId = context?.data?.body?.source?.contact?.card.id;

                    setValue("source", contactId);
                    setContactCardId(cards.find((cardDetail: any) => cardDetail.id === selectedCardId)?.id);
                    setSelCardBillingAddress(convertBillingAddress(cards.find((card: any) => card.id === selectedCardId)?.billingAddress));
                } else {
                    setContactCardId(cards[0]?.id);
                    setSelCardBillingAddress(convertBillingAddress(cards[0]?.billingAddress));
                }
            }
            if (data && data.ppi) {
                setContactCardLoad(false);
                setIsPpiContact(true);
                setSelectedPPI(data.ppi);

                // need to check for contact having PPI
                if (isMethodSupported("card")) {
                    setSendEnabled(true);
                } else {
                    setSendEnabled(false);
                    FxSnackBar.show({
                        text: 'Selected payment method is not supported for collecting money from contact.',
                    });
                }
            } else {
                setContactCards(cards);
                setIsPpiContact(false);
                setContactCardLoad(true);
                setSelectedPPI("");
                setSendEnabled(true);
                FxSnackBar.closeForce();
            }
        });
    }

    /**
    * UseMemo hook is used to create an array of options for the contact cards.
    */
    const contactCardOptions = useMemo(() => {
        return contactCards?.map((item: any) => ({
            label: `${item?.holderName ? item.holderName + ' ' : ''}${maskAccountNumber(item?.cardNumberLast4, false)}`,
            value: item.id,
        })) || [];
    }, [contactCards]);

  /**
   * Transforms the given address into a billing address format.
   * @param address - The input address object.
   * @returns A new object with properties for holder's address line 1, line 2, city, country, state, and zip.
   */
    const convertBillingAddress = (address: any) => {
      return {
        holderAddressLine1: address?.addressLine1 || '',
        holderAddressLine2: address?.addressLine2 || '',
        holderCity: address?.city || '',
        holderCountry: address?.country || '',
        holderState: address?.state || '',
        holderZip: address?.zip || '',
      }
    }

    /**
     * Method setting the default values for the text edit component from the context data
     * @param data 
     */
    function setFormValues(data: any) {
        setValue("amount", data?.amount);
        setValue("purpose", data?.purpose);
        setValue("achCompanyDescription", data?.processingDetail?.companyDescription);
        setInstantFunding(data?.processingDetail?.quickSettle);
        setValue("authType", 'ONLINE');
        setAllowDuplicate(data?.allowDuplicate);

        if (data?.source?.contact) {
            setContactExternalCard(data?.source?.contact?.id);
        }
    }

    /**
     * Method to get the merchant List
     * @returns data - Merchant List Data
     */
    async function getMerchantList() {
        const data = await httpClient.post(getCustomerUrl(`/${merchant_src.url}`, false), merchant_src.data);
        return data;
    }

    /**
     * Method to transform Merchant List into label and value object
     * Setting the transformed merchant list into merchantList state object
     */
    async function handleMerchantListData() {
        const data = await getMerchantList();
        if (data?.data?.resources) {
            const transformedMerchant = data?.data?.resources?.map((mList: any) => {
                if(scheduleId){
                    return ({ label: mList.categoryType, value: mList.id })
                } else {
                    return ({ label: `${'ID: ' + mList.id || ''}${customerName ? ', (' + customerName + ')' : ''}`, value: mList.id })
                }
            })
            setMerchantList(transformedMerchant);
            !context?.data?.body && setValue('merchantList', transformedMerchant?.[0]?.['value']);
        }
    }

    /**
     * Function handles transformation of source data
     * @param data : source data array
     * @returns : array
     */
    function contactNameTransformer(data: any) {
        return data?.map((item: any) => ({
            label: item.name + " (ID: " + item.id + ")",
            value: item.id,
        })) || [];
    }

    const sourceFilterOptions: any = [
        { label: "Contact Name", value: "name", operator: "like" },
    ];

    /**
     * Method to set file upload data
     * @param event :event object
     */
    function passFileUploaddata(event: any) {
        try {
            setFileUploadData(event);
            if (event.length > 0 && errors["fileError"]) {
                clearErrors("fileError");
            }
        } catch (e) { 
            Logger.error("CreateCollectContactCard.tsx", "error", e);
        }
    }
    /**
     * Method to remove file upload data
     * @param id : file id
     */
    const removeFile = (id: any) => {
        const newval = fileUploadData.filter((item: any) => item.id !== id);
        dispatch({
            type: "DATA_UPDATE_COMPONENT_PARAM",
            payload: {
                id: "create-collect-ach-from-contact-file-id-file-upload",
                files: newval,
            },
        });
        setFileUploadData(newval);
    };

    const handleClose = () => {
        if (scheduleId) {
            history.push("/transactions/send/" + scheduleId);
        } else {
            history.push("/dashboard");
        }
    };

    /**
     * function to call on change of contact from dropdown
     * @param event 
     */
    const handleChange = (event: any) => {
        if (event && event?.value) {
            setContactExternalCard(event?.value);
            setIsSource(true);
        } else {
            setContactCards([]);
            setContactCardLoad(false);
            setIsSource(true);
            setIsPpiContact(false);
        }
        setSendEnabled(true);
        FxSnackBar.closeForce();
    };

    /**
     * Method enables and disables the allow duplicate
     * @param event : allow duplicate status
     */
    const handleAllowDuplicate = (event: any) => {
        setAllowDuplicate(event);
    };
    /**
     * Method enables and disables the repeat functionality
     * @param event : repeat status
     */
    const handleRepeat = (event: any) => {
        setRepeatStatus(event);
    };
    /**
     * Method triggers if the recurring updates
     */
    const handleUpdateRecurring = (data: any) => {
        setRecurringFormData(data);
    };

    // summary data for source either for PPI or extrnal card
    const externalCard = watch("card");
    const viaToLable = useMemo(() => {
        const ppi = {
            'To PPI': {
                id: 'ppi',
                value: selectedPPI,
            }
        }

        const address = {
            'From': {
                id: 'from',
                value: externalCard
                    ? fetchLabelFromId(contactCards, externalCard)
                    : fetchLabelFromId(contactCards, contactCardId),
            }
        }

        if (isPpiContact) {
            return ppi;
        } else {
            return address
        }
    }, [isPpiContact, selectedPPI, externalCard, contactCards, contactCardId]);

    /**
    * The main summary data
    */
    const amount = watch('amount');
    const summaryData: Record<string, TransactionSummaryFieldType | string> = {
        'Collect Amount': {
            id: 'amount',
            ValueComponent: FxSummaryCurrency,
            value: amount && !isNaN(amount) ? amount : '',
        },
        ...viaToLable,
        'To': {
            id: 'to',
            value: 'Passport ' + accountLabelTransformation(getKey("selectedAccount")),
        },

        ...(businessCustomer
            ? { Purpose: { id: "purpose", value: watch("purpose") } }
            : { Memo: { id: "purpose", value: watch("purpose") } }),

        'Via': {
            id: 'method',
            value: 'CARD',
        },
    };
    if (uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) {
        summaryData['Faster Funding'] = {
            id: 'quickSettle',
            value: instantFunding ? 'Yes' : 'No',
        }
    }

    if (businessCustomer) {
        summaryData['Statement Descriptor'] = {
            id: 'statementDescriptor',
            value: watch("achCompanyDescription"),
        }
    }
    

    /**
    * Recurring related summary data
    */
    const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
        if (!repeatStatus || !recurringFormData) {
            return {}
        }
        const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
        const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
            ? convertToTitleCaseAndAppendWithS(
                recurringFormData?.repeatEveryFormData?.repeatEverySelect
            )
            : '';
        const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
            'Repeat every': {
                id: 'repeat-every',
                value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
            },
        }
        if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
            result['On'] = {
                id: 'repeat-on',
                value: convertToOnDayFormat(recurringFormData),
            };
        }
        const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
        const ends = recurringFormData?.endFormData
            ? convertEndData(recurringFormData?.endFormData)
            : '';
        if (ends || endOn) {

        }
        result['Ends'] = endOn ? {
            id: 'repeat-ends',
            value: ends,
            ValueComponent: ({ value }) => (
                <>
                    On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                </>
            )
        } : {
            id: 'repeat-ends',
            value: ends,
        };
        return result;
    }, [recurringFormData, repeatStatus]);

    const topSectionDefaultValues = {
        amount: context?.data?.body?.amount,
        source: context?.data?.body?.source?.id,
        purpose: context?.data?.body?.purpose
    }

    /**
    * Function handles transformation of Destination account (Passport Account)
    * @param data : Destination Account data array
    * @returns : array
    */
    const destinationAccountTransformation = useCallback((data: any) => data?.map((item: any) => ({
        value: item.id,
        label: accountLabelTransformation(item),
    })) || [], []);

    /**
    * Function to handle the closing of the modal.
    */
    const handleCloseModal = () => {
        const verifyProps = { ...props.verifyProps }
        verifyProps['open'] = false;
        updateComponentParams(dispatch, props.id, { verifyProps });
    };

    /** 
    *This function is called upon successful completion of a post operation.
    *It dispatches an action to update component parameters related to file uploads.
    */
    const handlePostSuccessEvent = () => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'create-collect-ach-from-contact-file-id-file-upload', 'files': [] } });
    }

    /**
     * Async function handles the create send form submission
     * @param data : form request
     */
    async function onSubmit(data: any) {
        let req: any = {};
        let request: any = {};
        let recurringRequest: any = {};
        if (urlContactId) {
            data.source = urlContactId;
        }
        if (!data.source) {
            setError("source", {
                type: "manual",
                message: "Source is required",
            });
            return;
        }
        if (repeatStatus) {
            recurringRequest = transformRecurringRequestGlobal(
                data,
                recurringFormData
            );
            req = {
                name: data?.name,
                transactionDetail: (recurringRequest["transactionDetail"]),
                action: [],
            };
            req["transactionDetail"]["startDate"] = data?.startDate;
            request = {
                type: 'TRANSACTION',
                transaction: {
                    destination: {
                        account: {
                            id: data.depositAccount,
                        },
                    },
                    method: "CARD",
                    type: "REGULAR",
                    amount: data.amount,
                    purpose: data.purpose,
                    allowDuplicate: allowDuplicate,
                    processingDetail: {
                        merchant: {
                            id: data.merchantList
                        },
                        statementDescriptor: data.achCompanyDescription || undefined
                    }
                },
            };
            if(uiFeat.getTransactionFeatures().fastFunding) {
                request["transaction"]["processingDetail"] = {
                    ...request["transaction"]["processingDetail"],
                    quickSettle: instantFunding
                }
            }
            if(data['location']){
                request['transaction']['processingDetail']['location'] = {
                    'id': data['location']
                }
            }
            if (data.source) {
                request["transaction"]["source"] = {
                    contact: {
                        id: data.source,
                        card: {
                            id: data.card,
                        },
                    },
                };
            }

            req["action"].push(request);

            if (fileUploadData?.length) {
                req["linkedDocument"] = fileUploadData.map((item: any) => ({
                    purpose: "Authorization",
                    document: { id: item.id }
                }));
            }
        } else {
            req = {
                id: params?.id,
                destination: {
                    account: {
                        id: data.depositAccount,
                    },
                },
                method: "CARD",
                type: "REGULAR",
                amount: data.amount,
                purpose: data.purpose,
                allowDuplicate: allowDuplicate,
            };

            if (data.source) {
                req["source"] = {
                    contact: {
                        id: data.source,
                        card: data.cvv ? {
                                id: data.card,
                                cvv:data.cvv
                            }:{
                                id: data.card
                            },
                    },
                };
            }            

            req['processingDetail'] = {
                'merchant': {
                    'id': scheduleId ? context?.data?.body?.processingDetail?.merchant?.id : data.merchantList
                }
            };
            if(uiFeat.getTransactionFeatures().fastFunding) {
                req['processingDetail']['quickSettle'] = instantFunding
            }
            if (data.achCompanyDescription) {
                req['processingDetail']['statementDescriptor'] = data.achCompanyDescription;
            }
            if(data['location']){
                req['processingDetail']['location'] = { 
                    'id' : data['location']
                }
            }
        }

        /**
         * function used to check if user selected fundingRule option or not
         */
        if(!repeatStatus){
            req.isAutoCapture = !captureFundsLater;  
        }
    
        /**
         * function used to check if user selected Invoice option or not
         */
        if(advancedOptions && data?.addInvoice?.[0] === 'yes' && invoiceData && Object.keys(invoiceData).length > 0){
            req.processingDetail.order = drawInvoiceNodes(invoiceData);
        } 
    
        /**
         * function used to check if user selected Advanced option or not
         */
        if(advancedOptions){
            req.amountDetails = {};
            if(data?.addSurcharge?.[0] === 'yes' && data?.surchargeAmount){
                req.amountDetails.surchargeAmount = data?.surchargeAmount;
            }
            if(data?.addTip?.[0] === 'yes' && data?.tipAmount){
                req.amountDetails.tipAmount = data?.tipAmount;
            }
            if(data?.addTag?.[0] === 'yes' && data?.tagValue && !captureFundsLater){
                req.funding ={
                    tags:  
                        {
                            "type": data?.tagValue
                        }
                };
            }
        } 
    
        if (!contactCardLoad && isPpiContact) {
            delete req?.processingDetail //in case of ppi we are deleting ea node
            if (req?.source?.contact?.card) {
                delete req["source"]['contact']['card'];
            }
            else if (req?.action && req['action'][0] && req['action'][0] && req['action'][0]['transaction']?.source?.contact?.card) {
                delete req['action'][0]['transaction'].source.contact.card;
            }

        }
        req = clean(req);

        setIsloading(true);
        let status: any;
        status = await createCollectViaCARD(req);
        const sendId = status?.headers?.url?.split("/").pop() || '';

        status = processAPIResponse(status);
        setIsloading(false);


        if (status.status) {
            const successMessage = scheduleId ? 'Collect Updated Successfully!' : repeatStatus ? 'Recurring Created Successfully!' : 'Collect Created Successfully';
            const redirectUrl = scheduleId ? `/transactions/send/${scheduleId['id']}` : repeatStatus ? '/thanks/transactions/collect-contact-card-recurring/' : '/thanks/transactions/collect-contact-card/';
            const verifyMessage = status.responseData?.email ? `email ${status.responseData?.email}` : status.responseData?.phone ? `phone ${status.responseData?.phone}` : '';
            if (status.responseData?.sessionId) {
                const verifyProps = {
                    open: true,
                    channelTitle: `To verify ${repeatStatus ? 'recurring send' : 'send'} for $${req.amount}, Passport will send you a one-time code. Choose how to receive your code.`,
                    subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to complete verification.`,
                    url: createTransactionUrl(req), 
                    req: req,
                    initiationDetails: status.responseData,
                    resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
                    successMessage: successMessage,
                    redirectUrl: redirectUrl,
                    redirectUrlFromHeader: params?.id ? false : true,
                    postSuccessEvent: handlePostSuccessEvent
                }
                updateComponentParams(dispatch, props.id, { verifyProps })
            }

            else {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: repeatStatus
                        ? 'Recurring Created Successfully!'
                        : scheduleId
                            ? 'Collect Updated Successfully'
                            : 'Collect Created Successfully!',
                });
                if (repeatStatus) {
                    dispatch({
                        type: "DATA_UPDATE_COMPONENT_PARAM",
                        payload: {
                            id: "create-collect-ach-from-contact-file-id-file-upload",
                            files: [],
                        },
                    });
                }
                if (repeatStatus) {
                    history.push(
                        "/thanks/transactions/collect-contact-card-recurring/" + sendId
                    );
                } else if (scheduleId) {
                    history.push("/transactions/collect/" + scheduleId);
                } else {
                    history.push("/thanks/transactions/collect-contact-ach/" + sendId);
                }
            }
        } else {
            //api  failed(
            FxSnackBar.show({
                text: customErrorMessage(status)
            });

        }
    }

    /**
    * Async Function handles api call for create and update send
    * @param paylaoddata : form request
    * @returns : response object
    */
    async function createCollectViaCARD(paylaoddata: any) {
        try {
            let url = '/transaction';
            if (paylaoddata.action !== undefined) {
                url += '/recurring';
            }
            if (paylaoddata.id && paylaoddata.id !== undefined) {
                url += '/id/' + paylaoddata.id;
            }

            const data: any = await httpClient.post(getCustomerUrl(url, false), paylaoddata);
            return data;
        } catch (err) {
            Logger.error("CreateCollectContactCARD.tsx", "error", err);
            return err;
        }

    }


    /**
     * Method to handle Tab Click
     */
    const tabClickFunction = () => {
        try {
            const data: any = {
                schedule: 'collect',
                type: 'contact',
                formData: { amount: watch('amount'), purpose: watch('purpose') }
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
        }
        catch (e) {
            Logger.error("CreateCollectContactCard.tsx", "error", e);
         }
    }

    const isInstantFundingDisabled = !accountLevelQuickSettle || (accountLevelQuickSettle && !pmLevelQuickSettle);
    /**
    * Method enables and disables the instant fund functionality
    * @param event : instant fund status
    */
    const handleInstantFund = (event: any) => {
        setInstantFunding(event);
    };

    /**
    * Method handles the onchange event
    * @param event : onchange event parameter
    */
    const handleMerchantChange = async (event:any) => {
        try{
            const merchantData = await getMerchantData(event.target.value).then(res => res?.data);
            const merchantLocationUrl = merchantData?.location?.url?.split('/v1/')[1];
            if(merchantLocationUrl){
                const locationData = await getLocation(merchantLocationUrl).then(res => res.data);
                if(locationData){
                    setLocation(locationData[0])
                    setValue('location',locationData[0]?.id);
                }
            }
        }catch(e){
            Logger.error('CreateCollectContactCard.tsx','habdle merchant change', e)
        }
    }
  /**
   * This useEffect hook is triggered when the 'card' value or 'contactCards' array changes.
   * It finds the selected card from the 'contactCards' array using the 'card' value.
   * Then, it transforms the billing address of the selected card into a specific format and sets it to 'selCardBillingAddress' state.
   */
    useEffect(()=> {
      const selectedCardId = watch('card');
      if(!selectedCardId) return;
      const selectedCard = contactCards?.find((card: any) => card?.id === selectedCardId);
      setSelCardBillingAddress(convertBillingAddress(selectedCard?.billingAddress));
    },[watch('card'), contactCards])

    return (
        <>
            <ScheduleLayout
                id="create-collect-contact-ach-"
                type='collect'
                loading={isloading}
                summaryData={{ ...summaryData, ...summaryRepeatData }}
                submitTitle={repeatStatus ? 'Create' : scheduleId ? 'Save Changes' : 'Collect Money'}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={handleClose}
                saveEnabled={sendEnabled}
            >
                {urlContactId && (<FxSuccess id="sucess-external-account" header={"Contact added successfully"} description={"CONTACT ID - " + urlContactId} />)}
                <ScheduleTopSection
                    id="create-collect-contact-ach-schedule-top-section"
                    schedule="collect"
                    type="contact"
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={topSectionDefaultValues}
                    acc_src={acc_src}
                    dataTransformation={destinationAccountTransformation}
                    amountId="create-collect-contact-amount-textbox"
                    destinationId="create-collect-contact-ach-destination-account"
                />
                {!scheduleId && <Grid item xs={12} sm={12}>
                    <FxMaterialSelect
                        register={{ ...register("merchantList") }}
                        id="create-send-contact-from-card-merchant-list"
                        name="merchantList"
                        setValue={setValue}
                        data={merchantList}
                        value={''}
                        control={control}
                        label="Merchant*"
                        rules={{ required: true }}
                        clearError={clearErrors}
                        className={
                            errors.merchantList
                                ? "border-error-input fx-input-edit"
                                : "fx-input-edit"
                        }
                        readOnly={scheduleId ? true : false}
                        onChange={handleMerchantChange}
                    />
                </Grid>}
                {getValues('merchantList') && location && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location *'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="add-contact-collect-card-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={false}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                {scheduleId && (
                    <Grid item xs={12} sm={12}>
                        <FxTextEdit
                            register={{ ...register("merchantList") }}
                            value={merchantList?.find((mList: any) => mList.value === context?.data?.body?.processingDetail?.merchant?.id)?.label}
                            type="text"
                            className={
                                errors.source
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                            }
                            control={control}
                            id="edit-send-contact-ach-form-card-merchantlist-textbox"
                            label="Merchant"
                            name="merchantList"
                            isEditable={false}
                        />
                    </Grid>
                )}
                {!scheduleId && (
                    <Grid item xs={12} sm={12}>
                        {isSource && (
                            <>
                                <FxLabelView className="fx-label-top">Contact Name*</FxLabelView>
                                <FxAutoComplete
                                    register={{ ...register("source") }}
                                    control={control}
                                    rules={{ required: !urlContactId ? true : false }}
                                    id="create-collect-contact-ach-form-card-source"
                                    name="source"
                                    source={contact_list_src}
                                    searchBy={'name'}
                                    className={
                                        errors.source
                                            ? "border-error-input fx-input-edit"
                                            : "fx-input-edit"
                                    }
                                    dataTransformer={contactNameTransformer}
                                    setError={setError}
                                    clearError={clearErrors}
                                    setValue={setValue}
                                    resetField={resetField}
                                    value={source}
                                    filterOptions={sourceFilterOptions}
                                    placeholder="Search Contact..."
                                    isEditable={true}
                                    setParam={handleChange}
                                />
                            </>
                        )}
                    </Grid>
                )}
                {scheduleId && (
                    <Grid item xs={12} sm={12}>
                        <FxTextEdit
                            register={{ ...register("source") }}
                            value={source && source[0]?.label}
                            type="text"
                            className={
                                errors.source
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                            }
                            control={control}
                            id="edit-send-contact-ach-form-card-source-textbox"
                            label="Contact"
                            name="source"
                            isEditable={false}
                        />
                    </Grid>
                )}
                <Grid item>
                    <ScheduleCreateMethodTab tabItems={method_Tab} id="create-send-contact-ach-tab" tabClick={tabClickFunction} />
                </Grid>
                {isPpiContact && (
                    <Grid item xs={12} sm={12}>
                        <FxTextEdit
                            control={control}
                            rules={{ required: false }}
                            id="create-send-contact-ach-form-card-ppi-textbox"
                            label="Payment ID"
                            name="passportPaymentId"
                            variant="outlined"
                            value={truncateChars(selectedPPI, 60)}
                            isEditable={false}
                            className={"fx-input-edit fx-input-edit-disabled"}
                        />
                    </Grid>
                )}

                {((contactCardLoad && !params?.id) ||
                    (contactCardId && scheduleId)) && (
                        <>
                        <Grid item xs={12} sm={12}>
                            <FxMaterialSelect
                                register={{ ...register("card") }}
                                id="create-send-contact-ach-form-card"
                                name="card"
                                data={contactCardOptions}
                                control={control}
                                label="Contact Card"
                                rules={{ required: true }}
                                value={contactCardId || ""}
                                clearError={clearErrors}
                                className={
                                    errors.card
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                }
                                readOnly={!!scheduleId}
                                setValue={setValue}
                            />
                        </Grid>
                        {!scheduleId && !repeatStatus &&
                            <Grid item xs={12} sm={12}>
                                <FxTextEdit 
                                register={{ ...register("cvv") }} 
                                className={errors.cvv ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                type="password"
                                control={control} 
                                rules={{required:false,
                                    minLength: 3,
                                    maxLength: 4
                                    }
                                } 
                                id="card-cvv" 
                                label={"CVV"}
                                name="cvv"
                                variant="outlined"
                                maxLength={4} />
                            </Grid>
                        }
                        
                        </>
                    )}

                <Grid item sm={12} xs={12}>
                    <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-onetime-card-form-card-statement-descriptor-textbox" label="Statement Descriptor" name="achCompanyDescription" variant="outlined" setValue={setValue} />
                </Grid>

                      {/** Advanced Options Toggle Button */}
            <Grid item xs={12}>
                   <FxSwitch
                        id="advanced-options"
                        value={advancedOptions}
                        onClick={setAdvancedOptions} 
                        disabled={repeatStatus}
                    ></FxSwitch>{" "}
                    <FxLabel value="Advanced Options"></FxLabel>
            </Grid>

            {/** Rendering the Advanced Options Form */}
              { advancedOptions && <CardCollectAdvancedOptions id={'card-collect-advanced-options-form'} amount={amount} saveInvoiceFormData={saveInvoiceFormData} invoiceDataAvailable={invoiceDataAvailable}
                control={control}
                setValue={setValue}
                register={register}
                errors={errors}
                watch={watch}
                getValues={getValues}
                setInvoiceDataAvailable={setInvoiceDataAvailable}
                invoiceData={invoiceData}
                billingAddressData={selCardBillingAddress || {}}
                isAddTagDisabled={captureFundsLater}
              />}

              {/** Capture Funds Later Toggle Button */}
              <Grid item xs={12}>
                  <FxSwitch
                      id="capture-funds-later"
                      value={captureFundsLater}
                      onClick={ setCaptureFundsLater }
                      disabled={repeatStatus}
                  ></FxSwitch>{" "}
                  <FxLabel value="Capture Funds Later"></FxLabel>
              </Grid>



                <Grid item xs={12} sm={12}>
                    <FxSwitch
                        id="create-send-contact-ach-form-card-allow-duplicate"
                        value={allowDuplicate}
                        onClick={handleAllowDuplicate}
                    ></FxSwitch>{" "}
                    <FxLabel value="Allow Duplicate"></FxLabel>
                </Grid>
                {(!scheduleId && checkPermission({ entity: "Recurring Transaction", name: "CARD Collect", operation: "CREATE" })) && (
                    <Grid item xs={12} sm={12}>
                        <FxSwitch
                            id="repeat-switch"
                            value={repeatStatus}
                            onClick={handleRepeat}
                            disabled={advancedOptions || captureFundsLater}
                        ></FxSwitch>{" "}
                        <FxLabel value="Repeat"></FxLabel>
                    </Grid>
                )}
                {repeatStatus && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <FxDateEdit
                                register={{ ...register("startDate") }}
                                className={
                                    errors.startDate
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                }
                                control={control}
                                id="create-send-contact-ach-form-card-startDate"
                                name="startDate"
                                type="date"
                                variant="outlined"
                                label={"Start Date *"}
                                setValue={setValue}
                                disablePast={true}
                                resetField={resetField}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxTextEdit
                                register={{ ...register("name") }}
                                className={
                                    errors.name
                                        ? "border-error-input fx-input-edit"
                                        : "fx-input-edit"
                                }
                                control={control}
                                rules={{ required: true }}
                                id="create-send-account-ach-form-card-name-textbox"
                                label="Name *"
                                name="name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FxRecurring
                                id="create-send-contact-ach-form-card-fx-recurring"
                                updateTrigger={handleUpdateRecurring}
                                startDate={watch("startDate")}
                                register={register}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            ></FxRecurring>
                            <Grid item container xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <Typography className="filter-popover-label">
                                        LINKED DOCUMENTS
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    &nbsp;
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={8}>
                                        <FxMaterialSelect
                                            register={{ ...register("docType") }}
                                            id="create-send-contact-ach-form-card-doc-type"
                                            control={control}
                                            rules={{ required: false }}
                                            name="docType"
                                            data={docTypeData}
                                            value={"CONTRACT"}
                                            readOnly={true}
                                            label="Select a document type"
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="fx-choose-file-button">
                                        <Controller
                                            name={"fileId"}
                                            control={control}
                                            rules={{ required: false }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <>
                                                    <FxFileUpload
                                                        register={{ ...register("fileId") }}
                                                        name="fileId"
                                                        passData={passFileUploaddata}
                                                        id="create-collect-ach-from-contact-file-id-file-upload"
                                                        onChange={(e: any) => {
                                                            onChange(e);
                                                        }}
                                                        value={
                                                            fileUploadData ? fileUploadData : value
                                                        }
                                                        acceptedFiles={[
                                                            ".pdf",
                                                            ".docx",
                                                            ".jpg",
                                                            ".txt",
                                                            ".png",
                                                            ".jpeg",
                                                            ".xls",
                                                            ".wav",
                                                            ".xlsx",
                                                            ".tiff",
                                                            ".doc",
                                                            ".rtf",
                                                            ".bmp",
                                                            ".efx",
                                                            ".csv",
                                                            ".kswps",
                                                            ".wps",
                                                        ]}
                                                        formData={{ type: "CONTRACT" }}
                                                        setValue={setValue}
                                                        maxFileSize={10485760}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <FxLabelView
                                        className="fx-label-small"
                                        id="create-send-contact-ach-form-card-file-types-supported"
                                    >
                                        (.jpeg, .png, .pdf, .xls, .wav, .xlsx, .tiff, .doc,
                                        .docx, .txt, .rtf, .bmp, .jpg, .efx, .csv, .wps)
                                    </FxLabelView>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className={"error-message"}>
                                        {errors.fileError && renderError(errors.fileError)}
                                    </div>
                                </Grid>
                                {fileUploadData && (
                                    <Grid item container>
                                        {fileUploadData.map((key: any) => {
                                            return (
                                                <Chip
                                                    icon={<InsertDriveFileTwoToneIcon />}
                                                    label={key["name"]}
                                                    onDelete={() => removeFile(key["id"])}
                                                    color="primary"
                                                    variant="outlined"
                                                    deleteIcon={<DeleteIcon />}
                                                />
                                            );
                                        })}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
                {(uiFeat.getTransactionFeatures().fastFunding && showInstantFunding) &&
                    <FxFasterFundingSwitch instantFunding={instantFunding} handleInstantFund={handleInstantFund} disabled={isInstantFundingDisabled}/>
                }
            </ScheduleLayout>
            <FxModalGeneric
                id="creat-send-contact-ach-otp"
                open={props.verifyProps?.open}
                onClose={handleCloseModal}
                componentProps={{ ...props.verifyProps }}
                className={'fx-modal-small2medium'}
                component={AuthenticationVerificationModal}
            >
            </FxModalGeneric>
        </>
    )

});