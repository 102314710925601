import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import { FxButtonGroup } from '../../Action/FxButtonGroup';
import { HomeTransactionActivity } from '../../Page/Index/Passport/HomeTransactionActivity';
import { HomeLedgerList } from '../../Page/Index/Passport/HomeLedgerList';


export const HomeTreasureListCard: React.FC<any> = React.memo((props) => {

    const [type, setType] = useState<any>('LEDGER');

    /**
    * to get account id
    */
    const componentState = useSelector((state: any) => {
        if (state.data['account-list-sub-menu']) {
            return state.data['account-list-sub-menu']
        } else {
            return { config: { params: {} } };
        }
    });

    const selectedAccount = componentState?.params?.selectedOption;

    const recent_transaction__source = selectedAccount?.id ? {
        url: "transaction/list",
        method: "POST",
        expiry: 10,
        data: {
            "pageNumber": 1,
            "pageSize": 9,
            "sortOptions": {
                "sortBy": "lastUpdatedOn",
                "sortOrder": "desc"
            },
            "criteria": {
                "filters": [
                    {
                        "operator": "eq",
                        "key": "accountId",
                        "values": [selectedAccount?.id]
                    },
                    {
                        "operator": "ne",
                        "key": "status",
                        "values": ['COMPLETED']
                    }
                ]
            }
        }
    } : null

    /**
    * to get ledger list
    */
    const ledgerList = useSelector((state: any) => state.data['customer-basic-info'] || null);

    /**
     * method to load list based on type
     * @returns :list
     */
    const listdata = () => {
        switch (type) {
            case 'TRANSACTION':
                return <HomeTransactionActivity id={`${props?.id}-home-transaction-activity-list`} source={recent_transaction__source} />
            case 'LEDGER':
                return <HomeLedgerList id="home-ledger-list" data={ledgerList?.params?.ledgerList} />
            default:
                return null
        }
    }
    /**
     * useMemo to get type
     */
    const typeData = useMemo(() => {
        let options = [{ id: 'ledger', value: 'LEDGER', label: 'RECENT ACCOUNT ACTIVITY', selected: type === 'LEDGER' },
        { id: 'transaction', value: 'TRANSACTION', label: 'TRANSACTION ACTIVITY', selected: type === 'TRANSACTION' }]
        return options
    }, [type])
    /**
     * Method handles the onchange
     * @param item :value
     */
    const handleTypeChange = (item: any) => {
        setType(item?.value)
    }

    return (
        <Grid id="home-treasure-transaction-list-card-Home-Grid" item xs={12} >
            <FxCard id="home-treasure-transaction-list-card-Home-Card" className="fx-card-general fx-theme-passport">
                <FxCardHeader id="home-treasure-transaction-list-card-Home-Card-Header" className="fx-card-header">
                    <Grid item container xs={12} spacing={1} alignItems='center'>
                        <Grid item className="fx-flex-grow">
                        { 
                            selectedAccount?.type === 'CASH_BUILDER' ? 
                            <Grid className='fx-tab-border'>
                                <FxButtonGroup id="home-treasure-transaction-list-card-button-group" options={typeData} onClick={handleTypeChange} />
                            </Grid> : 
                            <Grid id="account-ledger-header-title" item xs justifyContent="flex-start">
                                <Typography variant="h3" >Recent Account Activity</Typography>
                            </Grid>
                        }
                        </Grid>
                    </Grid>
                </FxCardHeader>
                {listdata()}
            </FxCard>
        </Grid>
    )
})

