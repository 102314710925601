/**
 * Component handles the display of the communication preference card in the user profile
 */
import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxLink } from '../../Action/FxLink';
import { Method, callAPI, clean, truncateChars, updateComponentParams } from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { FxSkeltonList } from '../Cards/FxSkelton';


export const CommunicationPreference: React.FC<any> = React.memo((props: any) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const { primaryMethod, email, phone } = props;
    const dispatch = useDispatch()
    /**
     * Useeffect hook resets the data
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    },[dispatch,props.id])
    /**
     * The useMemo hook is used to memoize the context data
     */
    const editData = useMemo(() => {
        if(context?.data?.body){
            updateComponentParams(dispatch, props.id, { primaryMethod: context?.data?.body?.preference?.mfaPreference?.primaryMethod, email: context?.data?.body?.email, phone: context?.data?.body?.phone } )
            return context?.data?.body
        }
    }, [context?.data?.body,dispatch,props.id]);


    /**
     * Method handles the set as primary functionality
     * @param primaryMethod : SMS or EMAIL
     */
    const handleSetAsPrimary = async (primaryMethod: any) => {
        const url = '/user/preference';
        const req = {
            mfaPreference:{
                primaryMethod
            }
        }
        const response = await callAPI(url, Method.POST, clean(req))
        const status = processAPIResponse(response)
        if (status.status) {
            updateComponentParams(dispatch, props.id, { primaryMethod });
        }
        else {
            //otp send failed
            FxSnackBar.show({
            text: status.message,
            });
        }
    }


    return (
        <Grid id="communication-preference-grid" item xs={12} className='fx-communication-preference'>
            <FxCard id="communication-preference-card" className="fx-theme-passport">
                {editData ?<><FxCardHeader id={'communication-preference-card-header'} >
                    <Grid item container justifyContent="space-between" xs={12} alignItems="center">
                        <Typography id="communication-preference-card-title" className='fx-communication-preference-title'>Communication Preference</Typography>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="communication-preference-card-body" className="fx-info-card fx-margin-top-reducer">
                    <Grid container direction="row" key="password-updated" >
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} className="fx-communication-preference-sub-title-grid">
                            <Typography className='fx-communication-preference-sub-title'>MOBILE</Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item>
                                <Typography className='fx-communication-preference-label'>{phone}</Typography>
                            </Grid>
                            { primaryMethod === 'SMS' && <Grid item>
                                <Typography className='fx-communication-preference-primary-label'>PRIMARY</Typography>
                            </Grid>}
                            {primaryMethod === 'EMAIL' && <Grid item>
                                <FxLink className='fx-communication-preference-edit-link' onClick={(e) => handleSetAsPrimary('SMS')}>SET AS PRIMARY</FxLink>
                            </Grid>}
                        </Grid>
                        <Grid item xs={12} className="fx-communication-preference-sub-title-grid">
                            <Typography className='fx-communication-preference-sub-title'>EMAIL</Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item>
                                <Typography className='fx-communication-preference-label' title={email}>{truncateChars(email, 35)}</Typography>
                            </Grid>
                            { primaryMethod === 'EMAIL' && <Grid item>
                                <Typography className='fx-communication-preference-primary-label'>PRIMARY</Typography>
                            </Grid>}
                            { primaryMethod === 'SMS' && <Grid item>
                                <FxLink className='fx-communication-preference-edit-link' onClick={(e) => handleSetAsPrimary('EMAIL')}>SET AS PRIMARY</FxLink>
                            </Grid>}
                        </Grid>
                    </Grid>
                </FxCardBody></>: <FxSkeltonList height="25rem" />}
            </FxCard>
        </Grid>
    )
})