/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { ISource } from '../../../types/common.interfaces';
import { Controller, useForm } from 'react-hook-form';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { convertEndData, maskAccountNumber, removeUnderScoreConvertTitleCase, customErrorMessage, clean, transformRecurringRequestGlobal, checkPermission, convertToTitleCaseAndAppendWithS, convertToOnDayFormat, updateComponentParams, createCommonVerifyProps, getScheduleType } from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { accountLabelTransformation, createUpdateCollect, getDestinationValue, getLocationData } from './ScheduleGlobalFunctions';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import { ScheduleTopSection } from './ScheduleTopSection';
import { FxSwitch } from '../../Action/FxSwitch';
import FxLabel from '../../Input/FxLabel/FxLabelView';
import FxRepeat from './Recurring/FxRepeat';
import FxSnackBar from '../../Utils/fx-snack-bar';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

Logger.debug("CreateCollectFromSelfAch.tsx", "create collect from account Ach initializing");

/**
 * This component handles the collect creation from user account through ach method
 */
export const CreateCollectFromSelfAch: React.FC<any> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const [contacEA, setContacEA] = useState<any>([]);
        const [repeatStatus, setRepeatStatus] = useState(false);
        const [recurringFormData, setRecurringFormData] = useState<any>();
        const [instantFunding, setInstantFunding] = useState(false);
        const pmLevelQuickSettle = getKey('isAchQuickSettleEnabled');
        const accountLevelQuickSettle = getKey('accountLevelQuickSettle')?.ach;
        const showInstantFunding = pmLevelQuickSettle || context?.data?.body?.processingDetail?.quickSettle;
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, resetField, control, clearErrors, watch, setError } = useForm();
        const params = useParams<any>();
        let scheduleInfo: any = {};
        let collectId: any = '';
        const scheduleId = params.id;
        const [externalEAId, setExternalEAId] = useState();
        const [location, setLocation] = useState<any>();
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available

        /**
         * Function to handle the closing of the modal.
         */
        const handleCloseModal = () => {
            const verifyProps = {...props.verifyProps}
            verifyProps['open'] = false;
            updateComponentParams(dispatch, props.id, { verifyProps });
        };

        const acc_src = {
            url: "account/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "status",
                            "operator": "nin",
                            "values": [
                                "INACTIVE"
                            ]
                        }
                    ]
                }
            }
        };

        const ea_src: ISource = {
            url: "externalAccount/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                    ]
                }
            }
        };

        const processingModeData: any = [
            { label: 'Same Day', value: 'SAME_DAY' },
            { label: 'Next Day', value: 'FORWARD' }
        ];

        const method_Tab = [
            {
                className: 'fx-link-sel',
                title: 'ACH',
                multiplePermission: true,
                disbaled: true,
                permission: [{
                    entity: "Accounts",
                    name: "ACH Collect",
                    operation: "CREATE"
                }, {
                    entity: "Recurring Transaction",
                    name: "Ach Collect",
                    operation: "CREATE"
                }]
            }
        ];

        /**
         * Declaring Constants
         */
        const [isLoading, setIsLoading] = useState(false);
        // const [methodSelected, setMethod] = React.useState(context?.data?.body?.method ? context.data.body.method : 'ACH');
        const isEdit = !!props.source;
        const sourceSelected = 'EXISTING';
        const history = useHistory()

        /**
        * Method enables and disables the instant fund functionality
        * @param event : instant fund status
        */
        const handleInstantFund = (event: any) => {
            setInstantFunding(event);
        };


        useEffect(() => {

            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-collect-from-my-account-form-card-source-account' } });
            setValue('method', 'ACH')
            setValue('sourceType', 'EXISTING')
            setValue('allowDuplicate', 'false')
            if (scheduleId) {
                const src = {
                    url: "/transaction/id/" + scheduleId,
                    method: "GET"
                }
                dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id: 'edit-collect-from-self-ach', source: src } });
            }
            else {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: getScheduleType(businessCustomer,'title') + ' Money', backButton: '/dashboard' } } });
            }

            const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props.id, { verifyProps })
        }, []);

        scheduleInfo = context?.data?.body;

        /**
         * Handle closeing of the model
         */

        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/transactions/collect/' + context.data.body.id)
            } else {
                history.push('/dashboard')
            }
        };

        /**
        * Async function handles the create collect form submission
        * @param data : form request
        */
        async function onSubmit(data: any) {
            

            let req: any;
            req = {
                source: {
                    externalAccount: {
                        id: data['externalAccount']
                    }
                },
                allowDuplicate: data.allowDuplicate,
                method: 'ACH',
                amount: data.amount,
                type: 'REGULAR',
                purpose: data.purpose,
                destination: {
                    account: {
                        id: data['depositAccount']
                    }
                },
                processingDetail: {
                    processingMode: data.processingMode,
                    quickSettle: instantFunding
                }
            }
            if (data.achCompanyDescription) {
                req['processingDetail']['companyDescription'] = data.achCompanyDescription
            }
            if (data.location) {
                req['processingDetail']['location'] = { 
                    'id' : data.location
                }
            }
            if (repeatStatus) {
                req = {
                    action: [{
                        type: 'transaction',
                        transaction: req,
                    }],
                    name: data.name,
                    linkedDocument: data.files?.map((file: any) => ({
                        purpose: "Authorization",
                        document: {
                            id: file.id,
                        }
                    })),
                    ...transformRecurringRequestGlobal(data, recurringFormData),
                }
            }
            if (params?.id) {
                req.id = params?.id;
                delete req.source;
                delete req.destination;
                delete req.method;
            }
            req = clean(req);
            
            

            setIsLoading(true);
            let status = await createUpdateCollect(req);
            if (status?.headers?.url) {
                collectId = (status.headers.url).split('/').pop();
            }
            setIsLoading(false);
            status = processAPIResponse(status);
            let redirectUrl=`/thanks/transactions/collect-self-ach/`;
            let backurl = `/thanks/transactions/collect-self-ach/${collectId}`;
            let successMessage = 'Created Successfully';
            if (params?.id) {
                 redirectUrl='/transactions/collect/' + params?.id;
                backurl = '/transactions/collect/' + params?.id;
                successMessage = 'Updated Successfully';
            }
            if (repeatStatus) {
                redirectUrl=`/thanks/transactions/collect-self-ach-recurring/`;
                backurl = `/thanks/transactions/collect-self-ach-recurring/${collectId}`;
                successMessage = 'Recurring Created Successfully!';
            }
            if (status.status) {
                if(status.responseData?.sessionId){
                    const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                    const scheduleType:any = getScheduleType(businessCustomer,'lowercase'); 
                    const verifyProps = {
                        ...createCommonVerifyProps(req, status, repeatStatus, data?.amount, verifyMessage, scheduleType),
                        successMessage : successMessage,
                        redirectUrl: redirectUrl,
                        redirectUrlFromHeader:params?.id?false: true
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                    //api success
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: successMessage,
                    });
                    history.push(backurl);
                }
            }
            else {
                    FxSnackBar.show({
                        text: customErrorMessage(status),
                    });
            }
        };

        /**
        * Function handles transformation of destination data
        * @param data : destination data array
        * @returns : array
        */
        const dataTransformation = useCallback((data: any) => data?.map((item: any) => ({
            value: item.id,
            label: accountLabelTransformation(item),
        })) || [], []);
        /**
         * Method fetches Account using id from an array
         * @param data : array
         * @param id: selected account id
         */
        function fetchAccountFromId(data: any, id: any) {
            const foundItem = data?.find((item: any) => id === item.id);
            if (!foundItem) {
                return;
            }
            return foundItem.bankInfo
                ? foundItem.bankInfo?.name + " (***" + foundItem.accountNumberLast4 + ")"
                : "";
        }

        /**
         * Function handles transformation of source data
         * @param data : source data array
         * @returns : array
         */
        function sourcedataTransformation(data: any) {
            try {
                setContacEA(data);
                const optionData = data?.map(function (option: any) {
                    return { value: option.id, label: option.bankInfo.name + ' (' + maskAccountNumber(option.accountNumberLast4, false) + ')' };
                })
                return clean(optionData)
            }
            catch (e) { 
                Logger.error("CreateCollectFromSelfAch.tsx", "error", e);   
            }
        }

        const handleChange = (event: any) => {
            if (event && event?.value) {
                // setExternalAccount(event?.value)
            }
            else {
                setContacEA([])
            }

        };


        /**
         * Method setting the default values for the text edit component from the context data
         */
        function setFormValues(data: any) {
            setValue("amount", data?.amount)
            setValue("purpose", data?.purpose)
            setValue("achCompanyName", data?.processingDetail?.companyName)
            setInstantFunding(data?.processingDetail?.quickSettle);
            setValue("achCompanyDescription", data?.processingDetail?.companyDescription)
            setValue("authType", data?.processingDetail?.authType)
            setValue("addenda", data?.processingDetail?.addenda && data?.processingDetail?.addenda?.[0])
            setValue("externalAccount", data?.source?.externalAccount?.id)
            setExternalEAId(data?.source?.externalAccount?.id)
            setValue("allowDuplicate", data?.allowDuplicate);
            // if(data?.destination?.contact){
            //     setExternalAccount(data?.destination?.contact?.id)
            // }
        }

        /**
         * useEffect to set edit data details
         */
        useEffect(() => {
            setFormValues(context?.data?.body);
            getDestinationData(context?.data?.body)
            if(context?.data?.body?.processingDetail?.location){
                getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
            }
        }, [context?.data?.body]);

        /**
        * function to get destination details
        * @param collectInfo :data
        */
        async function getDestinationData(collectInfo: any) {
            if (collectInfo) {
                const destinationValue = await getDestinationValue(collectInfo?.destination);
                setValue('destination', destinationValue)
            }
        }

        /**
         * The main summary data
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            [getScheduleType(businessCustomer,'title') + ' Amount']: {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'From': {
                id: 'from',
                value: watch('externalAccount') ? fetchAccountFromId(contacEA, watch('externalAccount')) : '',
            },
            'To': {
                id: 'to',
                value: accountLabelTransformation(getKey('selectedAccount')),
            },
            'Purpose': {
                id: 'purpose',
                value: watch('purpose'),
            },
            'Via': {
                id: 'method',
                value: 'ACH',
            },
            'Processing Mode': {
                id: 'processingMode',
                value: watch("processingMode")
                    ? watch("processingMode") === 'FORWARD' ? removeUnderScoreConvertTitleCase('NEXT_DAY') : removeUnderScoreConvertTitleCase(watch("processingMode"))
                    : ""
            },
            'Memo': {
                id: 'achCompanyDescription',
                value: watch('achCompanyDescription'),
            }
        };
        if (showInstantFunding) {
            summaryData['Faster Funding'] = {
                id: 'quickSettle',
                value: instantFunding ? 'Yes' : 'No',
            }
        }

        /**
         * Recurring related summary data
         */
        const summaryRepeatData: Record<string, TransactionSummaryFieldType | string | undefined> = useMemo(() => {
            if (!repeatStatus || !recurringFormData) {
                return {}
            }
            const { repeatEveryFormData, onDayStatus, onDateStatus } = recurringFormData;
            const repeatEverySelect = recurringFormData?.repeatEveryFormData?.repeatEverySelect
                ? convertToTitleCaseAndAppendWithS(
                    recurringFormData?.repeatEveryFormData?.repeatEverySelect
                )
                : '';
            const result: Record<string, TransactionSummaryFieldType | string | undefined> = {
                'Repeat every': {
                    id: 'repeat-every',
                    value: `${repeatEveryFormData?.repeatEvery || ''} ${repeatEverySelect}`
                },
            }
            if (onDayStatus || onDateStatus || repeatEverySelect === 'Weeks') {
                result['On'] = {
                    id: 'repeat-on',
                    value: convertToOnDayFormat(recurringFormData),
                };
            }
            const endOn = !!recurringFormData?.endFormData?.endRecurring && recurringFormData.endFormData.endRecurring === 'AFTER';
            const ends = recurringFormData?.endFormData
                ? convertEndData(recurringFormData?.endFormData)
                : '';
            if (ends || endOn) {

            }
            result['Ends'] = endOn ? {
                id: 'repeat-ends',
                value: ends,
                ValueComponent: ({ value }) => (
                    <>
                        On <FxDateTimeView value={value} format=" MMM DD, yyyy" />
                    </>
                )
            } : {
                id: 'repeat-ends',
                value: ends,
            };
            return result;
        }, [recurringFormData, repeatStatus]);

        const topSectionDefaultValues = {
            amount: context?.data?.body?.amount,
            depositAccount: context?.data?.body?.destination?.url ? context.data.body.destination.url.split("/").pop() : '',
            purpose: context?.data?.body?.purpose
        }
        const tabClickFunction = () => {
            try {
                const data: any = {
                    schedule: 'collect',
                    type: 'my-account',
                    formData: { amount: watch('amount'), purpose: watch('purpose') }
                }
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch (e) {
                Logger.error("CreateCollectFromSelfAch.tsx", "error", e);  
             }
        }

        const isInstantFundingDisabled = !accountLevelQuickSettle || (accountLevelQuickSettle && !pmLevelQuickSettle);
        return (
          <>
            <ScheduleLayout
                id='create-collect-from-my-account-ach'
                type='collect'
                loading={isLoading}
                submitTitle={repeatStatus ? 'Create' : scheduleId ? 'Save Changes' : getScheduleType(businessCustomer,'title') + ' Money'}
                summaryData={{ ...summaryData, ...summaryRepeatData }}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={handleClose}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                <ScheduleTopSection id="create-collect-from-my-account-ach-schedule-top-section" schedule="collect" type="my-account" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataTransformation={dataTransformation} amountId="create-collect-from-my-account-ach-form-card-amount-textbox" destinationId="create-collect-from-my-account-form-card-destination-account" purposeId="create-collect-from-my-account-ach-form-card-purpose-textbox" />
                {/* <Grid item xs={12} sm={12} >
                    <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} prefix="$" id="create-collect-from-my-account-ach-form-card-amount-textbox" label="Amount*" name="amount" type="number" variant="outlined" defaultValue={context?.data?.body?.amount ? context.data.body['amount'] : ''} showDecimal={true} setValue={setValue} />
                </Grid>
                <Grid item xs={12} sm={12} >
                    <FxMaterialSelect register={{ ...register("depositAccount") }} control={control} rules={{ required: true }} className={errors.depositAccount ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-collect-from-my-account-form-card-destination-account" name="depositAccount" dataTransformation={dataTransformation} readOnly={true} value={scheduleInfo?.destination?.url ? scheduleInfo.destination.url.split("/").pop() : getKey("selectedAccount")?.id} source={acc_src} label="Destination Account*" setValue={setValue} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true, maxLength: 128 }} id="create-collect-from-my-account-ach-form-card-purpose-textbox" label="Purpose*" name="purpose" variant="outlined" defaultValue={context?.data?.body?.purpose ? context.data.body['purpose'] : ''} />
                </Grid> */}
                {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="create-collect-from-my-account-ach-form-card-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={!scheduleId}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                {businessCustomer && <Grid item xs={12} sm={12}>
                    <ScheduleCreateMethodTab tabItems={method_Tab} id="create-collect-from-my-account-ach-form-card-tab" tabClick={tabClickFunction} />
                </Grid>}
                {(!params?.id || (externalEAId && scheduleId)) && <Grid item xs={12} sm={12}>
                    <FxMaterialSelect register={{ ...register("externalAccount") }} control={control} rules={{ required: (!isEdit && sourceSelected === 'EXISTING') ? true : false }} className={errors.externalAccount ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-collect-from-my-account-form-card-source-account" readOnly={scheduleId ? true : false} dataTransformation={sourcedataTransformation} source={ea_src} name="externalAccount" label={"Source Account *"} setValue={setValue} setParam={handleChange} value={externalEAId ? externalEAId : ''} />
                </Grid>}
                {((scheduleId && context?.data?.body?.processingDetail?.processingMode) || (!scheduleId)) && <Grid item xs={12} sm={12}>
                    <FxMaterialSelect register={{ ...register("processingMode") }} control={control} rules={{ required: true }} id="create-collect-from-my-account-ach-form-card-processing-mode" name="processingMode" data={processingModeData} value={context?.data?.body && context?.data?.body['processingDetail'] && context?.data?.body?.processingDetail?.processingMode ? context.data.body['processingDetail']['processingMode'] : 'SAME_DAY'} label="Processing Mode" setValue={setValue} />
                </Grid>}
                <Grid item sm={12} xs={12}>
                    <FxTextEdit register={{ ...register("achCompanyDescription") }} className={errors.achCompanyDescription ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-collect-from-my-account-form-card-achCompanyDescription-textbox" label="Memo" name="achCompanyDescription" variant="outlined" defaultValue={scheduleInfo?.companyDescription ? scheduleInfo['companyDescription'] : ''} />
                </Grid>
                <Controller
                    name='allowDuplicate'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Grid item>
                            <FxSwitch
                                id='create-collect-from-my-account-ach-form-card-allow-duplicate'
                                value={value}
                                onClick={onChange}
                            >
                            </FxSwitch>
                            <FxLabel value="Allow Duplicate"></FxLabel>
                        </Grid>
                    )}
                />
                {(!scheduleId && checkPermission({ entity: "Recurring Transaction", name: "Ach Collect", operation: "Create" })) && (
                    <Grid item xs={12} sm={12}>
                        <FxSwitch
                            id="repeat-switch"
                            value={repeatStatus}
                            onClick={setRepeatStatus}
                        ></FxSwitch>{" "}
                        <FxLabel value="Repeat"></FxLabel>
                    </Grid>
                )}
                {repeatStatus && (
                    <FxRepeat
                        id='create-collect-from-my-account-ach-form'
                        control={control}
                        errors={errors}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        resetField={resetField}
                        clearErrors={clearErrors}
                        onRecurringFormChange={setRecurringFormData}
                    />
                )}
                {showInstantFunding &&
                    <Grid item xs={12} sm={12}>
                        <FxSwitch id="create-collect-from-my-account-form-card-instant-funding" value={instantFunding} onClick={handleInstantFund} disabled={isInstantFundingDisabled}></FxSwitch>
                        <FxLabel value="Faster Funding"></FxLabel>
                    </Grid>
                }

            </ScheduleLayout>
          </>
        )
    })
