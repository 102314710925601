import React from 'react';
import { Logger } from '../../../libs/utils/logger';
import { ContactCardDetails } from './ContactCardDetails';
import { useLocation } from 'react-router';
import { Grid } from '@mui/material';


Logger.debug("ContactCardInfo.tsx", "Contact Card Info initializing");
/**
 * Component: ContactCardInfo
 * Usage: To display the card detail
 */

export const ContactCardInfo: React.FC<any> = React.memo((props) => {
    const location: any = useLocation();
    const contactId = location.state?.contact?.id;
    const contact_source = {
        url:  "/contact/id/" + contactId,
        method: "GET"
    }

    return (
        <Grid id="contact-card-info-grid" className="fx-margin-bottom-16" item xs={12}>
            <ContactCardDetails source={contact_source} id="contact-card-info"/>
        </Grid>
        
    )
})
