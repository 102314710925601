import React, { useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import { ApprovalPolicyDetail } from './ApprovalPolicyDetail';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import { getCustomerUrl } from '../../../libs/utils/utils';
import HttpClient from "../../../libs/utils/httpClient";

Logger.debug("ApprovalPolicyDetails.tsx", "approval policy Details initializing");

/**
 * Component: ApprovalPolicyDetails
 * Usage: To display approval policy profile 
 */
export const ApprovalPolicyInfo: React.FC<any> = React.memo(() => {
    interface RouteParams {
        id: string
    }
    const params = useParams<RouteParams>();
    /**
     * get ID from  url
     */
    const id = params.id
      /**
     * policy details API
     */
    const policy_source = {
        url: "approvalPolicy/id/" + id,
        method: "GET"
    }

    const [ authorizedUserList, setAuthorizedUserList ] = useState([])
    const httpClient = HttpClient.getClient();

    useEffect(()=>{
        getAuthorisedUserList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    /**
     * Method to get Authorized Users List
     */
    const getAuthorisedUserList = async () => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                  "sortBy": "lastUpdatedOn",
                  "sortOrder": "desc"
                },
                "criteria": {
                  "filters": []
                }
            }
            await httpClient.post(getCustomerUrl('/authorizedUser/list'),payloadData).then(response => {
                setAuthorizedUserList(response?.data?.resources)
            });
        } catch (e) { }
    }

    /**
     * to set permission
     */
    const permission = useMemo(() => {
        return checkPermission({
            entity: "Approval Policy",
            name: "Approval Policy",
            operation: 'View'
        });
    }, []);

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                {permission ? <ApprovalPolicyDetail id="approval-poilicy-info-card" source={policy_source} authorizedUserList={authorizedUserList}/>
                    : <FxAccessDenied id={'approval-poilicy-info-denied'} title={'You are not authorized to access this page'} />
                }
                    
            </Grid>
        )
    })