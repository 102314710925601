import React  from 'react';
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/svg/question-mark-icon.svg';
import { ReactComponent as DigitalDebitInfoCardIcon } from '../../../assets/svg/digital-debit-card-info-icon.svg';
import { ReactComponent as QNAIcon } from '../../../assets/svg/qna-icon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/svg/settings-icon.svg';

Logger.debug("DebitCardTextInfo.tsx", "Debit Card TextInfo initializing");

/**
 * Component: Debit Card Text Info Card
 * Usage: To display Debit Card Text information
 */

export const DebitCardTextInfo: React.FC<any> = React.memo((props) => {
        return (
            <Grid id="debit-card-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                <FxCard id="debit-card-info-card" className="fx-theme-passport-no-before-reduced-margin">
                    <FxCardBody id="debit-card-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                        {props?.data?.properties?.isDigitalFirst ? 
                        <>
                       {props?.data?.status === 'ACTIVE' && !props?.data?.properties?.cardPresentTransactionsEnabled && <Grid container alignItems='center' direction="row" xs={12} marginBottom='2rem'>
                            <Grid item xs={1}>
                                <DigitalDebitInfoCardIcon />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography id="title-debit-card-info" className='fx-small-text' variant="subtitle1" >
                                    The digital debit card can be used for transactions while we ship the physical card. Use the 'Enable Physical card' option to activate the physical card once it is received.
                                </Typography>
                            </Grid>
                        </Grid>}
                        <Grid container alignItems='center' direction="row" xs={12} marginBottom='2rem'>
                            <Grid item xs={1}>
                                <QNAIcon />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography id="title-debit-card-info" className='fx-small-text' variant="subtitle1" >
                                    To activate card, temporarily freeze card, report a lost/stolen card, or for any other reason please call customer service at <b>1-833-316-6047</b> 
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' direction="row" xs={12} >
                            <Grid item xs={1}>
                                <SettingIcon /> &nbsp; &nbsp;
                            </Grid>
                            <Grid item xs={11}>
                                <Typography id="title-debit-card-info" className='fx-small-text' variant="subtitle1" >
                                   This Card is issued by Sutton Bank, pursuant to a license from Visa U.S.A., Inc. Visa is a registered trademark of Visa, U.S.A. Inc. All other trademarks and service marks belong to their respective owners.
                                </Typography>
                            </Grid>
                        </Grid> 
                        </>: 
                        <Grid id="contact-Grid2" container alignItems='center' direction="row" xs={12} >
                            <Grid item id="contact-Grid3"  >
                                <QuestionMarkIcon /> &nbsp; &nbsp;
                            </Grid>
                            <Grid item>
                                <Typography id="title-debit-card-info" className='fx-small-text' variant="subtitle1" > To activate card, temporarily freeze card, report a lost/stolen card, or for any other reason please call customer service at <b>1-833-316-6047</b> </Typography>
                            </Grid>
                        </Grid>}  
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })
