/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { FxTextEdit } from "../../../Input/FxText/FxTextEdit";
import { useForm } from "react-hook-form";
import FxRadioEdit from "../../../Input/FxRadio/FxRadioEdit";
import { FxDateEdit } from "../../../Input/FxDate/FxDateEdit";
import FxLabel from "../../../Input/FxLabel/FxLabelView";


Logger.debug("FxEndRecurring.tsx", "Fx End Recurring initializing")
/**
 *  This component handles the fx end recurring
 */

export const FxEndRecurring: React.FC<any> = React.memo(
    (props: any) => {
        const { setValue, resetField } = useForm();
        const endRecurringTypes: any = [
            { label: 'Never', value: 'NEVER' },
            { label: 'After', value: 'THE' },
            { label: 'On', value: 'AFTER' }
        ]
        const [endType, setEndType] = useState<any>('NEVER');
        // Method handles the event for end type change
        const handleEndRecurringChange = (event: any) => {
            try {
                if (props.updateFormData) {
                    props.updateFormData({name: 'endRecurring', value: event.target.value})
                }
                setEndType(event.target.value);
                if (event.target.value === 'NEVER') {
                    resetField('transactions');
                    setValue('afterDate', '')
                }
                else if (event.target.value === 'AFTER') {
                    resetField('transactions');
                }
                else if (event.target.value === 'THE') {
                    setValue('afterDate', '')
                }
            }
            catch (e) { 
                Logger.error("FxEndRecurring.tsx", "error", e);
            }

        }
        /**
         * to get edit data
         */
        useEffect(() => {
            setValue('transactions',props?.data?.occurrence )
            setEndType(props?.data?.endDate ? 'AFTER' : (props?.data?.occurrence ? 'THE' : 'NEVER'))
        }, [props?.data])

         useEffect(() => {
            if (props.updateFormData && !props?.data) {
                props.updateFormData({name: 'endRecurring', value: 'NEVER'})
            }

        },[])

        // Method handles the on blur event for the transactions field
        const handleOnBlur = (value: any) => {
            if (props.updateFormData) {
                props.updateFormData({name: 'transactions', value: value})
            }
        }
        // Method handles the on change event for date field
        const handleOnChange = (value: any) => {
            if (props.updateFormData) {
                props.updateFormData({name: 'afterDate', value: value})
            }
        }

        return (
            <Grid item container xs={12} className="fx-repeat-payment-end">
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={12}>
                    <FxRadioEdit register={{ ...props?.register("endRecurring") }} control={props?.control} className={props?.errors.endRecurring ? "border-error-input fx-input-edit" : "fx-input-edit"} data={endRecurringTypes} id={props.id + '-radio'} name="endRecurring" label="End" onChange={handleEndRecurringChange} defaultValue={props?.data?.endDate ? 'AFTER' : (props?.data?.occurrence ? 'THE' : 'NEVER')} row="vertical" setValue={props?.setValue}  />
                </Grid>
                <Grid item xs={12}> &nbsp; </Grid>
                {endType === 'AFTER' && <><Grid item container xs={12} sm={6}>
                    <Grid item xs={12}>
                        <FxDateEdit register={{ ...props?.register("afterDate") }} className={props?.errors?.afterDate?  "border-error-input fx-input-edit" : "fx-input-edit"} control={props?.control} defaultValue={props?.data ? props?.data?.endDate:''} id={props.id + '-date'} label="" name="afterDate" variant="outlined" setValue={props?.setValue} onChange={handleOnChange} resetField={props?.resetField} disablePast={true} minDate={props.startDate?props.startDate:new Date()} rules={{ required: true }} />
                    </Grid>
                </Grid>
                    <Grid item xs={12}> &nbsp; </Grid></>}
                {endType === 'THE' && <><Grid item container xs={12} alignItems='center'>
                    <Grid item xs>
                        <FxTextEdit register={{ ...props?.register("transactions") }} className={props?.errors?.transactions ? "border-error-input fx-input-edit" : "fx-input-edit"} control={props?.control} id={props.id + '-textbox'} label="" name="transactions" type="number" variant="outlined" defaultValue={props?.data?.occurrence} setValue={props?.setValue} onBlur={handleOnBlur} rules={{ required: true }} min={1} /> </Grid>
                    <Grid item xs> &nbsp;&nbsp;<FxLabel className="form-label" value="occurrence(s)" />
                    </Grid>
                </Grid>
                    <Grid item xs={12}> &nbsp; </Grid></>}
            </Grid>
        )
    })
