/**
 * Component used to display co owner agreement
 */
import { Divider } from '@mui/material';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FxButton } from '../../../Action/FxButton';
import { FxAgreementLinkCard } from '../../../Data/FxAgreementLinkCard';
import FxCheckEdit from '../../../Input/FxCheck/FxCheckEdit';
import { FxCongratulations } from '../../../Utils/FxCongratulations';
import { CoOwnerOnboardingLayout } from '../Layout/CoOwnerOnboardingLayout'
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getKey, setKey, setUserLoggedIn } from '../../../../libs/utils/storageManager';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import HttpClient from '../../../../libs/utils/httpClient';


export const CoOwnerAgreement: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const history = useHistory();

    const { register, setValue, control } = useForm();
    const { isSubmitDisabled, isAccepted, fileInfo, ownerPayload, isLoading } = props;

    useEffect(() => {
        updateComponentParams(dispatch, props?.id, { isSubmitDisabled: true, isLoading: false, isRead: false})
    }, [dispatch, props?.id])

    const agreementCheckboxData = [
        {
            label: (
                <span>By submitting this form, you agree to the Passport Account Agreement, to receiving
                    <br />
                    electronic communications from Passport, and certify that the information provided is
                    <br />
                    complete and correct.
                </span>),
            value: 'yes',
            checked: false
        }
    ];  
    /**
     * handle check box change
     * @param event 
     */

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        updateComponentParams(dispatch, props?.id, { isSubmitDisabled: !isSubmitDisabled })
    }
    /**
     * submiting the data
     */
    const onAcceptClick = async () => {
        let req: any = { ...ownerPayload }
        let newRequest={...req.newPayload}
        if (fileInfo?.length && getKey("showTerms")==='YES') {
            newRequest.linkedDocument = newRequest?.linkedDocument ? newRequest?.linkedDocument : [];
        }

        let newOwnerReq: any = {};
        newOwnerReq['owners'] = [{
            id: getKey('authUserId')//same as owner
        }]

        const httpClient = HttpClient.getClient();
        updateComponentParams(dispatch, props?.id, { isLoading: true })
        if(getKey("showTerms")==='YES'){
            const status = await httpClient.post('user/acceptPrivacyPolicy', newRequest).then(response => {
                setUserLoggedIn();
                setKey("showTerms", "FALSE")
                return response
            }).catch(errorss => {
                setKey("showTerms", "FALSE")
                return errorss.response
            })
            updateComponentParams(dispatch, props?.id, { isLoading: false })
            if (status) {
                updateComponentParams(dispatch, 'layout-passport', { 'showTerms': 'NO' })
                setKey('accountAgreementAccepted',true) 
               updateComponentParams(dispatch, props?.id, { 'isAccepted': true })
            }
            else {
                //auth failed
                FxSnackBar.show({
                    text: 'Please try again',
                });
            }
        }

        const accountAgreementStatus = await httpClient.post(`customer/id/${getKey('customerId')}/account/id/${req?.account?.id}/acceptAgreement`, newOwnerReq).then(response => {
            return response
        }).catch(errorss => {
            return errorss.response
        })
        if (accountAgreementStatus) {
            updateComponentParams(dispatch, props?.id, { 'isAccepted': true })
        }
        else {
            FxSnackBar.show({
                text: 'Please try again',
            });
        }
       
}
    /**
     * Go to dashboard
     */
    const onContinueButtonClick = () => {
        history.push('/dashboard')
    }
    /**
     * Setting the file information
     * @param data 
     */
    const setFileInfo = (data: any) => {
        updateComponentParams(dispatch, props?.id, { 'fileInfo': data })
    }

    return (
        <CoOwnerOnboardingLayout title={'Passport Account Agreement'}>
            {!isAccepted ? <Grid item xs={9}>
                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="h3" className={'fx-partner-agreement-header'}>Passport Account Agreement</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} marginTop={'2.33rem'}>
                    <FxAgreementLinkCard
                      agreementTypes={['PassportAccount','RateCard']}
                      onFileInfoReady={setFileInfo}
                    />
                  </Grid>
                  {!!fileInfo?.length && <Grid className={'fx-submit-agree'} item xs={12} marginTop={'2.33rem'}>
                      <FxCheckEdit register={{ ...register('agreementCheckbox') }}
                                   rules={{ required: 'Please click Agree' }}
                                   control={control}
                                   id="co-owner-agreement-chackbox"
                                   name="agreementCheckbox"
                                   className="fx-privacy-text" data={agreementCheckboxData}
                                   row="vertical"
                                   onChange={(e: any) => {
                                     handleCheckboxChange(e);
                                   }}
                                   setValue={setValue} />

                    </Grid>}
                  <Grid item xs={12} marginTop={'2.33rem'} className="fx-agreement-divider">
                    <Divider />
                  </Grid>
                    <Grid item xs={12} textAlign={'right'} className={'fx-submit-btn'} marginTop={'2rem'}>
                      <FxButton id={'accept-co-owner-aggrement-button'} className={`${isSubmitDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`} disabled={isSubmitDisabled} onClick={onAcceptClick} isSubmitting={isLoading}>Accept</FxButton>
                    </Grid>
                </Grid>
            </Grid>
                :
                <Grid item xs={6} className={'fx-partner-congratulations-card'}>
                    <FxCongratulations
                        buttonText={'Continue to Dashboard'}
                        message={'Your Passport account application has been successfully submitted and is currently under review.'}
                        onButtonClick={onContinueButtonClick}
                    />
                </Grid>}
        </CoOwnerOnboardingLayout>
    )
})