import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Tooltip, Typography } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch, useSelector } from 'react-redux';
import FxCardFooter from '../../Container/FxCardFooter';
import { useHistory } from 'react-router-dom';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import moment from 'moment';
import { truncateChars } from '../../Utils/CommonBaseClass';
import { FxButton } from '../../Action/FxButton';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import FxRightDrawer from '../../Utils/fx-right-drawer';
import { FxMerchantDrawer } from '../Merchants/FxMerchantDrawer';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { getKey } from '../../../libs/utils/storageManager';

/**
 * Component used to display merchant list linked to the customer/company
*/
const column: any = {
    "title": "Select Columns to Show",
    "columns": [
        {
            "label": "ID",
            "enable": true,
            "fieldName": "id",
            "type": "fri://fieldtype/default",
            "default": true
        },
        {
            "label": "Status",
            "enable": true,
            "fieldName": "status",
            "type": "fri://fieldtype/status",
            "default": true,
            "style": { "width": "10%" }
        },
        {
            "label": "Merchant Category",
            "enable": true,
            "fieldName": "merchantCategory",
            "type": "fri://fieldtype/default",
            "default": true,
            "style": { "width": "20%" }
        },
        {
            "label": "Funding Via Passport",
            "enable": true,
            "fieldName": "fundingViaPassport",
            "type": "fri://fieldtype/default",
            "default": true,
            "style": { "width": "20%" }
        },
        {
            "label": "Last Updated On",
            "enable": true,
            "fieldName": "lastUpdatedOn",
            "type": "fri://fieldtype/date",
            "default": true,
            "format": "MM/DD/YYYY",
            "style": { "width": "20%" }
        },
        {
            "label": "Last Updated By",
            "enable": true,
            "fieldName": "lastUpdatedByUser",
            "type": "fri://fieldtype/custom",
            "default": true,
            "style": { "width": "20%" }
        }
    ]
}

const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
        {
            "enable": true,
            "fieldName": "header",
            "type": "fri://fieldtype/header",
            "xs": "12"
        },
        {
            "enable": true,
            "fieldName": "description",
            "type": "fri://fieldtype/custom",
            "xs": "12",
            "alignItems": 'center'
        },
        {
            "enable": true,
            "fieldName": "divider",
            "type": "fri://fieldtype/custom",
            "xs": "12",
        }
    ]
}

/**
* Method to transform data
* @param data
* @returns transformed data object for mobile view
*/
const docListGridTransformerMobile =(data:any)=>{
    const newData: any = [];
    let cur_date: any = '';
    data?.forEach((item: any, i: number) => {
        newData[i] = { ...data[i] }
        let title: any
        if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
            cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
        }
        if (!title) {
            newData[i].divider = true
        }
        if (title) {
            newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
        }
        // eslint-disable-next-line eqeqeq
        newData[i].description = <Grid item container xs={12}  className='fx-grid-list-content'>
            
    <Grid item container xs={10} alignItems='center' alignContent={'center'}>
    <Grid item container xs={8}>
            <Typography  variant='h4'>ID:{item?.id}</Typography>
    </Grid>

    <Grid item container xs={4}>
        <FxStatus id="beneficial-owner-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
    </Grid>

    <Grid item container xs={12}>
                <Typography  variant='h4'>{item?.merchantCategory}</Typography>
    </Grid>

    <Grid item container xs={12}>
    <Typography  variant='h4'>{item?.lastUpdatedByUser}</Typography> 
    </Grid>
            </Grid>
        </Grid>
    });
    return newData;
}

/**
* function to create divider to separate data
*/
export const MerchantDetails: React.FC<any> = React.memo((props: any) => {

    const uiFeat = UIFeatureImpl.getInstance();
    const isMerchantOnboardingEnabled = uiFeat.getMerchantOnboardingFeature().available;
    const selectedAccount = getKey("selectedAccount");

    const isMobile = useIsMobileOrTabletScreen();
    let context: any;
    ({ context, props } = RegisterComponent(props));
    let merchant_data: any = [];
    let newData: any = [];
    
    // Method handling the onclick event
    const handleOnClick = (event: any, item: any) => {
        history.push('/merchant/view/'+item?.id)
    }

    /**
    * mapping used to format values from API and to load data in datagrid
    */
    if (context && context.data && context.data.body) {
        merchant_data = { ...context.data.body };
        if (merchant_data.resources && merchant_data.resources.length > 0) {
            newData = merchant_data.resources.map((item: any) => {
                const fundingViaPassport = item?.configuration?.passportFunding?.enable === true ||
                                            (item?.type === 'PAYFAC' && !item?.configuration?.passportFunding?.enable)
                                                ? 'YES' : 'NO';
                return {
                    id: item.id,
                    status: item.status,
                    categoryCode: item.categoryCode,
                    accountNumber: item.merchantAccount.accountNumber,
                    merchantCategory: item.categoryType + ' (' + item.categoryCode + ')',
                    fundingViaPassport:fundingViaPassport,
                    lastUpdatedOn: item.lastUpdatedOn,
                    lastUpdatedByUser:<Tooltip title={item.lastUpdatedBy.username}><Typography>{truncateChars( item.lastUpdatedBy.username,15)}</Typography></Tooltip> ,
                }
            })
        }

    }
    const history = useHistory()
    const dispatch = useDispatch()
    
    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //check api call completed or not
    const contextState = context?.data_state;
    const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

    //Getting Customer Details from State
    const sourceInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
          return state.data['customer-basic-info']
        }
    });
    
    const accountList = sourceInfo?.params?.accountList?.resources;

    /**
     * This method handles the opening of the Direct Funded Merchant Creation Right Drawer Form
     */
    const openDFMerchantCreationForm = (async () => {
        const data = await FxRightDrawer.show<any>(({ onClose }) => {
             return <FxMerchantDrawer onDrawerClose={onClose} />
        })
        if(data){
            dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: props.id, source: props?.source}}); 
        }
    })

    return (
        <Grid id="company-merchant-list-Grid" item xs={12}>
            <FxCard id="merchant-list-card" className=" fx-theme-passport">
                <FxCardHeader id={'merchant-list-card#card-header'} >
                    <Grid id="merchant-list-Grid" item container justifyContent="space-between" xs={12} >
                        <Typography id="title-company-merchant-list-info" variant="h4" >Merchant Details</Typography>
                        { (!selectedAccount || (selectedAccount?.type !== 'CASH_BUILDER_PLUS' && selectedAccount?.type !== 'CASH_BUILDER')) && accountList?.length > 0 && isMerchantOnboardingEnabled && <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-passport-border fx-svg-theme"
                            id="company-profile-add-merchant"
                            onClick={openDFMerchantCreationForm}
                            startIcon={<PlusIcon />}
                        > {isMobile ? '':'Add'}
                        </FxButton>}
                    </Grid>
                    <div id="div-grow-title-company-merchant-list-info" className="fx-flex-grow" />
                </FxCardHeader>
                <FxCardBody id="company-merchant-list#card-body" className="fx-card-body-no-border-no-shadow fx-company-merchant-list-card-body">
                {dataLoadStatus ? (
                        merchant_data ? (
                        !isMobile ? (<DataGrid id="company-profile-merchant-list-grid" tableClass="fx-table-passport" data={newData} column={column} rowClick={handleOnClick}/>) : 
                        (<FxGridList id="company-profile-beneficial-owners-card-list" data={docListGridTransformerMobile(newData)} column={gridColumn} rowClick={handleOnClick}/>)
                        ) : null
                    ) : <FxSkeltonList height="5rem" />
                }
                </FxCardBody>
                <FxCardFooter id="company-profile-merchant-list-Footer" className="fx-footer">
                    <Grid></Grid>
                </FxCardFooter>
            </FxCard>
        </Grid>
    )
})
