import React, { useEffect } from "react";
import { FormControl, FormControlProps, TextField, Autocomplete, Checkbox, Box, Chip, Popper } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Controller, UseFormRegisterReturn, UseFormSetValue, ControllerProps } from "react-hook-form";
import clsx from "clsx";
import { ReactComponent as SelectIcon } from '../../../assets/svg/select-arrow.svg';


/**
 * Component used for Auto complete multi select search 
 */

type FxMaterialMultipleSelectType = ControllerProps & FormControlProps;

type TFieldValues = Record<string, any[]>;

export interface IData {
    label: number | string | React.ReactElement;
    value: number | string;
    operator?: string
}

interface FxMaterialMultipleSelectProps extends Partial<FxMaterialMultipleSelectType> {
    dataTransformation?: (data: any) => any;
    onChange?: (event: any) => void;
    onSelect?: (event: any) => void;
    readOnly?: boolean;
    label: string;
    name: string;
    setValue: UseFormSetValue<TFieldValues>;
    register: UseFormRegisterReturn;
    data?: Array<IData>;
    value?: Array<IData>;
    canSearch?: boolean;
    limitTags?: number;
    selectAllLabel?: any;
    isEdit: boolean;
    fixedOptions?: Array<IData>;
    source?: {
        method: string;
        url: string;
        baseUrl?: boolean;
    },
    listBoxClass?:string
    isSelectAllRequired?: boolean;
}

export const FxMaterialMultipleSelect: React.FC<FxMaterialMultipleSelectProps> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        let selectOptions: any = [];
        const fixedOptions = props.fixedOptions || [];
        const defaultValues = props.value || [];
        const [selectVal, setSelectVal] = React.useState<Array<IData>>([...defaultValues, ...fixedOptions] || []);
        const allFixedOptionLables = fixedOptions?.map(ele=>ele?.label) || [];

        /**
         * to set default value
         */
        useEffect(() => {
            if (props.value && props.setValue) {
              props.setValue(props.name, props.value)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.value, props.setValue]);

         /**
         * to set default value
         */
         useEffect(() => {
            if (props.value) {
                setSelectVal([...props.value, ...fixedOptions])
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.value]);


        /**
         * function to set changed data
         * @param event :event
         * @param data :data
         */
        function handleChange(event: any, value: any, reason: any, details: any) {
            let selectedValue = [];
            let filteredOptins: Array<IData> = [];

            if (details?.option?.label?.toLowerCase().includes(props?.selectAllLabel?.toLowerCase())) {
                selectedValue = reason === "selectOption" ? selectOptions : [];
            } else if (props?.isSelectAllRequired && reason === 'selectOption' && props?.selectAllLabel && props?.data?.length && (props?.data?.length -1 === value?.length)){
                selectedValue = reason === "selectOption" ? [...[{label:props?.selectAllLabel, value: props?.selectAllLabel}],...selectOptions] : [];
            }
            else {
                selectedValue = reason === "selectOption" ? value : value?.filter((ele: any) => { return ele && ele?.label?.toLowerCase() !== props?.selectAllLabel?.toLowerCase() });
            }

            if (props.fixedOptions) {
                filteredOptins = [...props.fixedOptions, ...selectedValue].reduce((unique, o) => {
                    if (!unique.some((obj: IData) => obj.label === o.label && obj.value === o.value)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
                props.setValue(props.name, filteredOptins);
                setSelectVal(filteredOptins);
            } else {
                props.setValue(props.name, selectedValue);
                setSelectVal(selectedValue);
            }

            if (props.onSelect) {
                props.onSelect(value);
            }
        }
        /**
         * Method used to manipulate data from api 
         * @returns :selected options
         */
        function dataTransformation() {
            let Options: any = [];
            if (props.dataTransformation && context?.data?.body?.resources) {
                Options = props.dataTransformation(context.data.body.resources);
            }

            return Options;
        }

        /**
         * function to emit the user entered text
         * @param inputValue : user entered text
         */
        const searchChange = async (inputValue: any) => {
            if (props.onChange) {
                if (inputValue.length >= 3 || inputValue.length === 0) {
                    props.onChange(inputValue);
                }
            }
        };

        /**
         * Method for customer popper
         * @param props : popperprops
         * @returns popper: component
         */
        const customPopper = React.useCallback((props) => {
            return <Popper {...props} />
        }, []);

        selectOptions = props.source ? dataTransformation() : props.data ? props.data : [];

        return (
            <FormControl variant="outlined" className={clsx("fx-input-edit fx-multiple-select-dropdown", props.className)}>
                {<Controller
                    name={props.name}
                    control={props.control}
                    rules={props.rules}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <div className="fx-auto-complete-custom">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id="multiple-select-component"
                                    value={selectVal}
                                    PopperComponent={customPopper}
                                    onChange={onChange = (event, value, reason, details) => { handleChange(event.target.value, value, reason, details) }}
                                    disableClearable={true}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    readOnly={props.readOnly ? props.readOnly : false}
                                    options={selectOptions}
                                    getOptionLabel={(option: any) => option.label}
                                    renderOption={(props, option, { selected }) => {
                                        return (
                                            <Box>
                                                <ul className="fx-auto-complete-custom-removeCheckColor">
                                                    <li {...props} className={'fx-auto-complete-custom-roleList'} id={`${props.id}-input}`}>
                                                        <Checkbox checked={selected} />
                                                        {option.label}
                                                    </li>
                                                </ul>
                                            </Box>
                                        )
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        const numTags = value.length;
                                        let limitTags = props.limitTags ? props.limitTags : value.length;
                                        const values = value && value?.filter((ele: any) => { return ele && ele.value === "Select All"; })
                                        limitTags = values?.length > 0 ? limitTags + 1: limitTags;
                                        return (
                                            <>
                                                {value.slice(0, limitTags).map((option, index) => (
                                                    option.label !== "Select All" && <Chip label={option.label} {...getTagProps({ index })} disabled={allFixedOptionLables.indexOf(option?.label) !== -1} />
                                                ))}
                                                {numTags > limitTags && <span className="fx-limit-tag-text">{` +${numTags - limitTags}`}</span>}
                                            </>
                                        );
                                    }}
                                    ListboxProps={{ className: `fx-auto-complete-custom-auto-complete-dropdown ${props?.listBoxClass}` }}
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField  {...params} label={props.label} InputLabelProps={{ shrink: true }} variant="outlined"
                                        className={`fx-input-edit ${!props.limitTags ? 'fx-auto-complete-custom-MuiInputBaseroot' : ''} ${props.readOnly ? 'fx-disabled-generic' : ''}`}                                        onKeyPress={(e) => { !props.canSearch && e.preventDefault() }}
                                            onChange={onChange = (event) => { searchChange(event.target.value) }}
                                            error={!!error}
                                        />
                                    )}
                                    popupIcon={<SelectIcon />}
                                />
                            </div>
                        )
                    }}
                />}
            </FormControl>
        )
    })

export default FxMaterialMultipleSelect;