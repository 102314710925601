import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";

import { ReactComponent as DownArrow } from "../../../../assets/svg/down-black-triangle-arrow.svg";
import { ReactComponent as UpArrow } from "../../../../assets/svg/up-black-triangle-arrow.svg";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { useFieldArray } from "react-hook-form";
import { FxFundingRuleCard } from "./FxFundingRuleCard";
import { ShowInfoToolTip } from "../../../Utils/CommonBaseClass";
import { FxLink } from "../../../Action/FxLink";

/**
 * Component: Funding Rules >> Split Rule Section Wrapper Class
 * Usage: To add the Split Rule Fields in the form
 */
export const FxSplitFundingSplitRuleSection: React.FC<any> = React.memo(
  (props) => {
    ({ props } = RegisterComponent(props));
    const [splitExpanded, setSplitExpanded] = useState(
      props?.data?.length > 0 ? true : false
    );
    const [splitAccordingIcon, setSplitAccordingIcon] = useState(
      <DownArrow />
    );

    /**
     * create a Form Field Array
     */
    const { fields, append, remove, update } = useFieldArray({
      control: props.control,
      name: "funding-rule-split-rule",
      shouldUnregister: true,
    });

    //By default rule card should open
    useEffect(()=>{
      handleRuleSplitSection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    useEffect(() => {
      if (props?.data) {
        fields.length = props?.data?.length;
        //Updating the fields array with the API data
        for (let i = 0; i < props?.data?.length; i++) {
          update(i, (
            {
             "category": props?.data[i]?.transferTo ? props?.data[i]?.transferTo === 'PROGRAM_AFFILIATE' ? 'partner' : props?.data[i]?.transferTo === 'PICK_FROM_TAG' ? 'dynamic' : props?.data[i]?.transferTo?.toLowerCase() : props?.data[i]?.account ? 'my_account' : props?.data[i]?.contact ? 'my_contact' : 'dynamic', 
             "contact": props?.data[i]?.contact ? { label: props?.data[i]?.contact?.name + " (" + props?.data[i]?.contact?.ppi + ")", value: props?.data[i]?.contact?.id } : '', 
             "account":props?.data[i]?.account ? props?.data[i]?.account?.id :'',
             "variableName": props?.data[i]?.dynamic ? props?.data[i].dynamic?.tagName : '',
             "variableToMap": props?.data[i]?.dynamic ? props?.data[i].dynamic?.tagMapsTo : '',
             "transactionComponent": props?.data[i]?.transactionComponent,
             "amount": props?.data[i]?.amount,
             "percentage": props?.data[i]?.percentage,
             "tag": props?.data[i]?.account?.tag
          }));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.data]);


    useEffect(() => {
      if (props?.value && props.setValue) {
        props.setValue(props.name, props.value)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.value]);

    /**
     * Method to add Field
     */
    function handleAppend() {
      append({});
    }

    /**
     * Method to handle the opening and closing of the split rule accordian
     */
    const handleRuleSplitSection = () => {
      props.setCanSubmit(true);
      if (splitExpanded === true && fields.length >= 1) {
        setSplitExpanded(false);
        setSplitAccordingIcon(<DownArrow />);
      } else { 
        if (fields.length === 0) {
          handleAppend();
        }
        setSplitExpanded(true);
        setSplitAccordingIcon(<UpArrow />);
      }
    };

    /**
     * Method to handle the accordian when only 1 item is there
     * @param index
     * @param length
     */
    const handleSplitAccClose = (index: any, length: any) => {
      remove(index);
      setSplitExpanded(false);
      setSplitAccordingIcon(<DownArrow />);
    };

    /**
     * Method to handle the removal of items(rows) in the split rule section
     * @param index
     * @param length
     */
    const removingSplitRules = (index: any, length: any) => {
      remove(index);
    };

    return (
      <Grid container paddingTop={"2rem"}>
        <Grid item xs={12} sm={12}>
          <fieldset className="fx-container-create-schdule-summery fx-splitfunding">
            <Grid container xs={12} className='funding-splitrule-criteria-section-dropdowncontact'>
              <Accordion className="fx-criteria" expanded={splitExpanded}>
                <AccordionSummary>
                  <Grid container className="split-rule-acc-header">
                    <Grid
                      item
                      xs={9}
                      sm={9}
                      justifyContent={"flex-start"}
                      className="fx-container-funding-rules-criteria-summery"
                    >
                      <Typography className="fx-summary-title" variant="h3">
                        {fields.length > 0 && splitExpanded === false
                          ? `Rule Action* (${fields.length})`
                          :  <>Rule Action* {ShowInfoToolTip('funding-rule-info-icon fx-splitfunding-tooltip','Action will be applied as per the below defined order')}</> }
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs
                      justifyContent={"flex-end"}
                      textAlign={"right"}
                    >
                      <FxLink href="#" onClick={handleRuleSplitSection}>
                        <Typography
                          className="fx-add-button-theme-text"
                          variant="h3"
                        >
                           {fields.length === 0 && splitExpanded === false
                          ? "+ Add"
                          : splitAccordingIcon}
                        </Typography>
                      </FxLink>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    width={"100%"}
                    className={
                      "fx-approval-policy-condition fx-create-approval-policy"
                    }
                    id={"add-criteria-conditions" + props.index}
                  >
                  <fieldset className="fx-container-create-schdule-summery fx-condition-summary fx-splitfunding-condition">
                      {fields.map((field, index) => {
                        return (
                          <FxFundingRuleCard
                            control={props?.control}
                            id={"funding-rule-card" + index}
                            index={index}
                            value={field}
                            setValue={props.setValue}
                            register={props.register}
                            errors={props.errors}
                            handleAppend={handleAppend}
                            resetField={props.resetField}
                            ruleType={'PERCENTAGE'}
                            handleRemove={() => {
                              fields.length === 1
                                ? handleSplitAccClose(index, fields.length)
                                : removingSplitRules(index, fields.length);
                            }}
                            isAddButtonDisabled={fields.length !== index + 1 || fields.length===10}
                            fieldLength={fields.length}
                            getValues={props.getValues}
                            update={update}
                            data={props?.data}
                            setError={props.setError}
                            clearErrors={props.clearErrors}
                            watch={props.watch}
                            onlyCard={props?.onlyCard}
                          />
                        );
                      })}
                      </fieldset>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={12} className="fx-dark-grey-info-card" marginTop={'2rem'}>
                      <span>
                        {`Note: All debit transactions (including refunds, chargebacks, fees, reserves, returns etc) will be debited from the configured account. Recoup of funds in case of “funds reversal” is possible only if the transaction amount was initially distributed across the accounts of the customer subject to the rule.`}
                        &nbsp;
                      </span>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    );
  }
);
