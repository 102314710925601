import React from 'react';
import { useParams } from "react-router-dom";
import { AddLocationDetails } from './AddLocationDetails';
/**
 * Component: EditLocationDetails
 * Usage: To edit location details
 */
export const EditLocationDetails = () => {
    
    interface RouteParams {
        id: string
    }
    
    const params = useParams<RouteParams>();
    
    //get ID from URL
    const id = params.id

    //Location Details Source
    const user_source = {
        url: "/location/id/" + id,
        method: "GET"
    }

    const rand = Math.floor(Math.random() * 100) + 1;
    
    return (
        <>
            <AddLocationDetails id={`${rand}edit-location${id}`} source={user_source} isEdit={true}/>
        </>
    )
}
