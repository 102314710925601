import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material';
import moment from 'moment';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { FxChart } from '../../Input/FxChart/FxChart';
import { FxLink } from '../../Action/FxLink';
import { getKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
const httpClient = HttpClient.getClient()


/**
 * This component handles the display of investment report via chart
 */
export const InvestmentReportChartCard: React.FC<any> = React.memo(({account,portfolioStatus }) => {

  const [performanceHistoryData, setPerformanceHistoryData] = useState<any>({});
  const [chartVariation, setChartVariation] = useState('1M');

  /**
   * chart data
   */
  const chartData = useMemo(() => {
  return performanceHistoryData?.resources?.map((item:any)=>{
    return {
      amount : item.currentInvestmentValue,
      date : item.date
    }
  }) || [];
  }, [performanceHistoryData]);


  /**
   * useEffect to get performance history
   */
  useEffect(() => {
    if(account?.status === 'ACTIVE' || portfolioStatus === 'ACTIVE'){
      getPerformanceHistory(dates['1M']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, portfolioStatus])


  /**
   * buttons for chart
   */
  const buttons = ['1M', '3M', '6M', '1Y'];

  /**
  * function to get buttons
  * @returns :button
  */
  function getButtons() {
    return buttons.map((item: any, index: any) => {
      return <FxLink id={'date-' + index} className={chartVariation === item ? 'fx-link fx-link-sel' : 'fx-link'} onClick={() => {onButtonClick(item)}} >{item}</FxLink>
    })
  }


  /**
   * dates for chart
   */
  const dates = {
    '1M': moment().subtract(1, 'months').format('MM/DD/YYYY'),
    '3M': moment().subtract(3, 'months').format('MM/DD/YYYY'),
    '6M': moment().subtract(6, 'months').format('MM/DD/YYYY'),
    '1Y': moment(new Date().setDate(new Date().getDate() - 364)).format('MM/DD/YYYY')
  }

  /**
   * function to handle button click
   */
  function onButtonClick(data: any) {
    const date = dates[data as keyof typeof dates];
    setChartVariation(data);
    getPerformanceHistory(date);
  }

  /**
   * function to get performance history
   */
   async function getPerformanceHistory(date: string) {
     try {
       const reqBody =  {
         pageNumber: 1,
         pageSize: 25,
         sortOptions: {
           "sortOrder": "ASC",
           "sortBy": "date"
         },
         criteria: {
           filters: [
             {
               "operator": "gte",
               "key": "date",
               "values": [
                date
               ]
             },
             {
               "operator": "lte",
               "key": "date",
               "values": [
                 moment().format('MM/DD/YYYY')
               ]
             }
           ]
         }
       }
       const res = await httpClient.post(getCustomerUrl(`account/id/${account?.id || getKey("selectedAccount")?.id}/portfolio/performanceHistory`, false), reqBody);
       setPerformanceHistoryData(res?.data );
     }
      catch (e) {
        Logger.error("InvestmentReportChartCard.tsx", "error", e);
        return e;
      }
}


  return (
        <Grid container>
        <Grid item container justifyContent='space-between' alignItems='center' className='fx-investment-account-summary-card-accountbalance'>          
          <Grid className='fx-account-insight-graph-link' item>
            {getButtons()}
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
            <FxChart id="investment-report-chart" className={'fx-investment-report-chart'} data={chartData} chartVariation={chartVariation}/>
        </Grid>
      </Grid>
      )
})

