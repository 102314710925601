import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { getCustomerUrl, processAPIResponse, useIsMobileScreen } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { FxButton } from '../../Action/FxButton';
import { clean, convertToPhoneDisplayFormat, setEmailValidationOptions, setPhoneValidationOptions, setZipValidationOptions, updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { AUTH_STRINGS } from '../../../constants/strings';
import usa_state from '../../Utils/usa_states.json';
import { getKey } from '../../../libs/utils/storageManager';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { ReactComponent as DividerIcon} from '../../../assets/svg/divider-icon-two.svg';
/**
 * Component: AddLocationDetails
 * Usage: to create and edit a location
 */
Logger.debug("AddLocationDetails.tsx", "Add Location Details initializing");
interface InputErrType {
    type: string;
    message: string;
}
export const AddLocationDetails: React.FC<any> = React.memo((props) => {
        
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const isMobileScreen = useIsMobileScreen();
        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, setError, control, clearErrors } = useForm();
        const history = useHistory();
        const dispatch = useDispatch();
        const httpClient = HttpClient.getClient();

        /**
        * To check if data available in context for edit
        **/
        if (context?.data?.body && !props?.isDataLoaded) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit Location', backButton: '/location/view/'+context?.data?.body?.id } } });
            updateComponentParams(dispatch, props?.id, {'submitButton':'Save Changes'});
            updateComponentParams(dispatch, props?.id, {'isDataLoaded': true}); // set as true if edit data available
            updateComponentParams(dispatch, props?.id, {'isEdit': true});
            updateComponentParams(dispatch, props?.id, {'address': context.data.body.address});
        }

        /**
        * called before component loading
        **/
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if(!props?.source){
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Location', backButton: '/company' } } });
                updateComponentParams(dispatch, props?.id, {'submitButton':'Add Location'});
                updateComponentParams(dispatch, props?.id, {'isDataLoaded': false}); // set as true if edit data available
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        /**
        * Method to handle on submit request
        **/
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            const formattedPhoneNumber = data.phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

            let address = {
                "addressLine1": data.addressLine1,
                "addressLine2": data.addressLine2,
                "city": data.city,
                "state": data.state,
                "zip": data.zip,
            }

            address = clean(address);

            req = {
                "doingBusinessAs": data.doingBusinessAs,
                "establishmentDate": data.establishmentDate,
                "website": data.website,
                "email": data.email,
                "phone": formattedPhoneNumber,
                "address": Object.keys(address).length > 0 ? address : undefined
            };

            req = clean(req);

            if (context?.data?.body) {
                status = await updateLocation(req);
            } else {
                status = await addLocation(req);
            }

            status = processAPIResponse(status)
            if (status.status) {
                // api success
                if (context?.data?.body) {
                    history.push('/location/view/' + context.data.body.id)
                } else {
                    FxSnackBar.show({
                        autoHideDuration: 1000,
                        severity: 'success',
                        text: 'Location Added Successfully!',
                    });
                    history.push('/company');
                }
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        /**
        * method to handle cancel
        */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/location/view/' + context.data.body.id)
            }
            else {
                history.push('/company')
            }
        };

        /**
        * Method to call api  for add location
        **/
        async function addLocation(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('location',false), paylaoddata)
                return data;
            } catch (err) {
                Logger.error("AddLocationDetails.tsx", "error addLocation ", err);
                return err;
            }
        }

        /**
         * Method to call api  for update location
         * */
        async function updateLocation(paylaoddata: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('location/id/' + context?.data?.body?.id,false), paylaoddata)
                return data;
            } catch (err) {
                Logger.error("AddLocationDetails.tsx", "error updateLocation ", err);
                return false;
            }
        }

        /**
         * Method to render error
         * @param err
         * @returns
         */
        const renderError = (err: InputErrType): string => {
            return err.message;
        };

        /**
         * city name validation constant
         */
        const setCityNameValidation = {
            required: true,
            minLength: 2
        }

        /***
        * Method to handle city error
        */
        const renderCityNameError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.CITY_NAME_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to handle Zip code error
         * @param err
         * @returns
         */
        const renderZipError = (err: InputErrType): string => {
            return err.message;
        };

        return (
            <Grid item container xs={12} sm={12} justifyContent={isMobileScreen?'flex-start':'center'} className='fx-form-grid' >
                <Grid item sm={6} xs={12}>
                    <Grid className="fx-card-general fx-theme-passport fx-no-box-shadow">
                        {!props?.isDataLoaded && props?.isEdit && <FxSkeltonList height="40rem" />}
                        {(props?.isDataLoaded || !props?.isEdit) && <form id="create-user-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCard id="create-user-form-card">
                                <FxCardHeader id="create-user-form-card-header" >
                                    {/* title */}
                                </FxCardHeader>
                                <FxCardBody id="create-user-form-card-body" className="fx-info-card" >
                                    <Grid container direction="row" spacing={2} className="fx-form-edit-profile" >
                                        <Grid item xs={12} sm={12}>
                                            <Typography>LOCATION DETAILS</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit 
                                                register={{ ...register("doingBusinessAs") }} 
                                                className={errors.doingBusinessAs ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                control={control} 
                                                rules={{ required: true }} 
                                                id="add-location-details-doing-business-as-textbox" 
                                                label="Doing Business As *" 
                                                name="doingBusinessAs" 
                                                variant="outlined" 
                                                defaultValue={context?.data?.body?.doingBusinessAs ? context?.data?.body?.doingBusinessAs : ''} 
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} >
                                            <FxDateEdit 
                                                register={{ ...register("establishmentDate") }} 
                                                className={errors.establishmentDate ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                control={control} 
                                                rules={{ required: false }} 
                                                id="establishmentDate" 
                                                label="Established On" 
                                                name="establishmentDate" 
                                                setValue={setValue} 
                                                variant="outlined" 
                                                defaultValue={context?.data?.body?.establishmentDate ? context.data.body.establishmentDate : ''} 
                                                disableFuture={true} 
                                            />
                                        </Grid>
                                        <Grid item container xs={12} sm={12}>
                                            <fieldset className="fx-add-user-address-container">
                                                <legend>Address Details</legend>
                                                <Grid item container xs={12} sm={12} spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true,maxLength:30 }} id={'add-location-details-addressline1-textbox'} label="Address Line 1 *" name={'addressLine1'} inputProps={{maxLength:30}} defaultValue={props?.address?.addressLine1 ? props?.address?.addressLine1 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false ,maxLength:30 }} id={'add-location-details-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} inputProps={{maxLength:30}} defaultValue={props?.address?.addressLine2 ? props?.address?.addressLine2 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="add-location-details-city-textbox" name="city" label="City *" defaultValue={props?.address?.city} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="add-location-details-state-textbox" name="state" data={usa_state} label="State *" value={props?.address?.state}
                                                            setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(true)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="add-location-details-zip-textbox" name="zip" label="Zip *" defaultValue={props?.address?.zip} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderZipError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography>CONTACT INFO</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <FxTextEdit 
                                                register={{ ...register("email") }} 
                                                className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                control={control} 
                                                rules={setEmailValidationOptions(false)} 
                                                id="add-location-details-email-textbox" 
                                                label="Email" 
                                                name="email" 
                                                variant="outlined" 
                                                defaultValue={context?.data?.body?.email ? context.data.body.email : ''}
                                            />
                                            <div className={'error-message'}>
                                                {errors.email && renderError(errors.email)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <FxPhoneEdit 
                                                register={register} 
                                                control={control} 
                                                rules={setPhoneValidationOptions(false)} 
                                                className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                id="phone" 
                                                name="phone" 
                                                label="Phone Number" 
                                                defaultCountryCode={context?.data?.body?.countryCode || getKey('countryCode')}
                                                defaultValue={convertToPhoneDisplayFormat(context?.data?.body?.phone)} 
                                                isEditable={true} 
                                                setValue={setValue} 
                                                setError={setError} 
                                                clearErrors={clearErrors} 
                                            />
                                            <div className={'error-message'}>
                                                {errors.phone && renderError(errors.phone)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FxTextEdit 
                                                register={{ ...register("website") }} 
                                                className={errors.website ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                control={control} 
                                                rules={{ required: false }} 
                                                id="add-location-details-website-textbox" 
                                                label="Website" 
                                                name="website" 
                                                variant="outlined" 
                                                defaultValue={context?.data?.body?.website ? context.data.body.website : ''} 
                                            />
                                        </Grid>
                                        <Grid item xs marginTop='0.66rem' marginBottom='2rem'>
                                            <DividerIcon />
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="create-user-form-card-footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                        <FxButton variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="add-location-details-cancel-button"
                                            onClick={handleClose}>
                                            Cancel
                                        </FxButton>
                                        <span className="fx-padding-right-16" />
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button fx-button-theme"
                                            id={"add-location-details" + props?.submitButton + "-button"}
                                            type="submit"
                                            isSubmitting={isSubmitting}
                                        >
                                            {props?.submitButton}
                                        </FxButton>
                                    </Grid>
                                </FxCardFooter>
                            </FxCard>
                        </form>}
                    </Grid>
                </Grid>
            </Grid>
        )
    });

