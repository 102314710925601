/* eslint-disable  @typescript-eslint/no-explicit-any */
import produce from "immer"

const initialState: any = {};
export enum DATA_STATE {
    STATUS_INITIAL = 1,
    STATUS_LOADING = 2,
    STATUS_DONE = 3
}

/**
 **Data reducer for the components
 * Storing data for a component. It may be
 * data from API or a static pre defined data
 */

export const dataReducer = produce((draft = initialState, action) => {

    switch (action.type) {
        case 'DATA_COMPONENT_INIT':
            draft[action.payload.id] = { ...draft.payload, data: {}, data_state: DATA_STATE.STATUS_INITIAL, params: {} };
            return draft;
        case 'DATA_API_FETCHING':
            draft[action.payload.id].data_state = DATA_STATE.STATUS_LOADING;
            return draft;

        case 'DATA_API_FETCHED':
            draft[action.payload.id].data_state = DATA_STATE.STATUS_DONE;
            draft[action.payload.id].data = action.payload.data;
            draft[action.payload.id].source = action.payload.source;
            return draft;
        case 'DATA_UPDATE_COMPONENT_REDUCER': //update data for another component
            if (!draft[action.payload.id]) {
                draft[action.payload.id] = { ...draft.payload, data: {} };
            }
            draft[action.payload.id].data = action.payload.data;
            return draft;

        case 'DATA_COMPONENT_RESET': //Reset the component
            draft[action.payload.id] = { ...draft[action.payload.id], data: {}, data_state: DATA_STATE.STATUS_INITIAL };
            return draft;
        case 'DATA_UPDATE_COMPONENT_PARAM': //update the component props
            if (draft[action.payload.id] && draft[action.payload.id].params) {
                draft[action.payload.id].params = { ...draft[action.payload.id].params, ...action.payload };
            }
            return draft;

        default:
            return draft;
    }

});
