import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { IFilterColumns, IFilterFields, IDataGridProps } from '../../../types/common.interfaces';
import { Filter } from '../../Data/Filter';
import { getKey } from '../../../libs/utils/storageManager';
import { FxDivider } from '../../Data/FxDivider';
import { getAlerts, removeUnderScoreConvertTitleCase, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ReactComponent as ReadAlertIcon } from '../../../assets/svg/read.svg';
import { FxButton } from '../../Action/FxButton';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { FxInfoTooltip } from '../../Utils/FxInfoTooltip';

/**
 * Component: Alert List
 * Usage: To display list of alerts
 */

export const AlertList: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    const httpClient = HttpClient.getClient();

    const src = {
        url: "/authorizedUser/id/"+ getKey('authUserId') + "/alert/list",
        method: "POST",
        data: {
            "pageNumber": 1,
            "pageSize": 25,
            "sortOptions": {
              "sortBy": "createdOn",
              "sortOrder": "DESC"
            },
            "criteria": {
                "filters": [
                ]
            }
        }
    }

     /**
     * function to get value of selected dashboard 
     */
     const selectedPage= useSelector((state: any) => {
        if (state.data['layout-passport']) {
            return state.data['layout-passport']?.params?.selectedPage
        }
    });
    /**
     * Method to check wheather account is selected or not
     */
    const selectedAccount = useSelector((state: any) =>
        state.data['account-list-sub-menu']?.params?.selectedOption
    );

    /**
    * useEffect to load title while loading page
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Alerts', backButton: selectedPage === 'LEADS' ? 'leads-home' : selectedAccount?.id ? '/dashboard': '/home' } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * useEffect to reset the filter while unmount the page
    */
      useEffect(() => () => {
        updateComponentParams(dispatch, 'all-alerts-card-filter', { resetFilter: true });
    }, [dispatch]);

    const column: IFilterColumns = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "ALERTS",
                "enable": true,
                "fieldName": "alert",
                "type": "fri://fieldtype/custom",
                "default": true,
            },
            {
                "label": "DATE",
                "enable": true,
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/date",
                "default": true,
                "style": { "width": "16rem" }
            },
            {
                "label": "",
                "enable": true,
                "fieldName": "quickAction",
                "type": "fri://fieldtype/custom",
                "default": true,
                "style": { "width": "16rem", "text-align": "center" }
            },
            {
                "enable": true,
                "fieldName": "spacer",
                "type": "fri://fieldtype/custom",
                "default": true,
                "style": { "width": "2rem"}
            },
        ]
    }

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/divider",
                "xs": "12"
            },
            {
                "enable": true,
                "fieldName": "header",
                "type": "fri://fieldtype/header",
                "xs": "12"
            },
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            }
        ]
    }

    /**
    * funtion to update state for alerts count
    */
    async function getAlertsCount(){
        const data = await getAlerts();
        if(data){
            updateComponentParams(dispatch, "alerts-info", { 'count': data.data.totalCount});
        }
    }

    /**
     * Method to hanldle on click of Mark as read button
     */ 
    const handleMarkAsRead = async (id: any) => {

        let status: any;
        const req = {
          readAll: false,
          alert: [
            {
                "id": id
            }
          ]
        }
    
        status = await markAsRead(req);
        status = processAPIResponse(status);
        
        if (status.status) {
          dispatch({
            type: "DATA_API_PARAM_CHANGED",
            payload: { id: "all-alerts-data-grid-list", source: src },
          });   
          getAlertsCount();
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    /**
     * Method to call api for Mark as read
     * @param paylaoddata 
     * @returns 
     */
    async function markAsRead(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl(`/authorizedUser/id/${getKey('authUserId')}/alert/markAsRead`,false), paylaoddata)
            return data;
        } catch (err) {
            Logger.error("UnreadAlertList.tsx", "error", err);
            return err;
        }
      }
    
    /**
     * Method to hanldle on click of Mark all as read button
     */  
    const handleMarkAllAsRead = async () => {
    
        let status: any;
        const req = {
          readAll: true
        }
    
        status = await markAsRead(req);
        status = processAPIResponse(status);
        
        if (status.status) {
            dispatch({
                type: "DATA_API_PARAM_CHANGED",
                payload: { id: "all-alerts-data-grid-list", source: src },
            });
            getAlertsCount();
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
      }

    const categoryData: any = {
        "ACCOUNT": "ACCOUNT",
        "DEBIT_CARD": "DEBIT_CARD",
        "EXTERNAL_ACCOUNT": "EXTERNAL_ACCOUNT",
        "SECURITY": "SECURITY",
        "TRANSACTION": "TRANSACTION",
        "TRANSACTION_APPROVALS": "APPROVALS",
        "INVESTMENT_ACCOUNT": "ACCOUNT"
    }

    /**
     * Method handles the transformation of alert data
     * @param data
     * @returns : transformed data
     */
    function alertsList(data: any) {
        const newData: any = [];
        data?.forEach((item: any, i: number) => {
            newData[i] = { ...data[i] }
            newData[i].quickAction = item.status === 'READ' ? '' : <Grid item className='fx-alert-component-action-button' onClick={() => handleMarkAsRead(item.id)}>
                <FxInfoTooltip title='Mark as read' arrow placement={'left-start'} className={'fx-mark-as-read-tooltip'}>
                    <ReadAlertIcon />
                </FxInfoTooltip>
            </Grid>;
            newData[i].alert = <Grid container xs direction='column' className='fx-alert-grid'>
                <Grid container item xs direction='row' className='fx-alert-grid-title' spacing={2}>
                    <Grid item xs>
                        <Typography>{removeUnderScoreConvertTitleCase(item?.name)}</Typography>
                    </Grid>
                    <Grid item xs>
                        <FxStatus id="approval-status" value={categoryData[item?.category]} fill={true} className="fx-status" />
                    </Grid>
                </Grid>
                <Grid item xs className='fx-alert-grid-description'>
                    <Typography>
                        {item?.messageBody}
                    </Typography>
                </Grid>
            </Grid>
        });
        return newData;
    }

    /**
    * @param data : Alert List
    * @returns : transformed data
    */
    const alertsListGridTransformer =(data:any)=>{
        const newData: any = [];
        let cur_date: any = '';
        data?.forEach((item: any, i: number) => {
            newData[i] = { ...data[i] }
            let title: any
            if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            }
            if (!title) {
                newData[i].divider = true
            }
            if (title) {
                newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
            }
            // eslint-disable-next-line eqeqeq
            newData[i].description = 
            <Grid item container xs={12} className='fx-grid-list-content'>
                <Grid item container xs={10} direction='column' className='fx-alert-grid-title' spacing={2}>
                    <Grid item xs>
                        <Typography>
                            {removeUnderScoreConvertTitleCase(item?.name)} &nbsp;
                            <FxStatus id="approval-status" value={categoryData[item?.category]} fill={true} className="fx-status" />
                        </Typography>
                    </Grid>
                    <Grid item xs className='fx-alert-grid-description'>
                        <Typography>
                            {item?.messageBody}
                        </Typography>
                    </Grid>
                </Grid>
                
                {item.status === 'READ' ? '' : <Grid item className='fx-alert-component-action-button' onClick={() => handleMarkAsRead(item.id)}>
                    <FxInfoTooltip title='Mark as read' arrow placement={'left-start'} className={'fx-mark-as-read-tooltip'}>
                        <ReadAlertIcon />
                    </FxInfoTooltip>
                </Grid>}
            </Grid>
        });
        return newData;
    }

    const datagridProps: IDataGridProps = {
        id: "all-alerts-data-grid-list",
        column: column,
        pagination: true,
        pageNumber: 1,
        pageSize: 25,
    };

    const filterFields: IFilterFields = {
        "fields": [
            {
                "label": "Status",
                "fieldName": "status",
                "type": "fri://fieldtype/radio",
                "className": "filter-divider filter-tri-row-checkbox-divider",
                "data": [
                    { label: 'Read', value: 'READ', "checked": false },
                    { label: 'Unread', value: 'UNREAD', "checked": false },
                ]
            },
            {
                "label": "Type",
                "fieldName": "category",
                "type": "fri://fieldtype/checkbox",
                "className": "filter-divider filter-tri-row-checkbox-divider",
                "data": [
                    { label: 'Security', value: 'SECURITY', "checked": false },
                    { label: 'Transaction', value: 'TRANSACTION', "checked": false },
                    { label: 'Approvals', value: 'TRANSACTION_APPROVALS', "checked": false },
                    { label: 'Account', value: 'ACCOUNT', "checked": false },
                ]
            },
            {
                "label": "Date",
                "toLabel": "To",
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/daterange",
                "data": [],
                "notAllowFutureDate": true,
            },
        ]
    }

    /**
    * to get alerts count
    */
    const alertsCount = useSelector((state: any) => {
        if (state.data['alerts-info']) {
            return state.data['alerts-info']?.params?.count
        } 
    });

    return (   
        <Grid id="all-alerts-first-grid" item xs={12}>
            <Grid container xs={12} >
                <FxCard id="all-alerts-card" className="fx-theme-passport">
                    <FxCardHeader>
                        <Grid id="all-alerts-header-first-grid" item container justifyContent="flex-end" xs={12}>
                            <Grid id="all-alerts-card-header-filter-grid" item container className="fx-action-block fx-inline-flex" spacing={2}>
                              <Grid item>
                                <Filter 
                                    id="all-alerts-card-filter" 
                                    field={filterFields} 
                                    // permissions={permission} 
                                    dataGridProps={datagridProps} 
                                    // hiddenParameter={['account.id']}
                                />
                              </Grid>
                              {alertsCount > 0 && <>
                                <Grid item className="fx-action-block-divider">
                                      <FxDivider />
                                </Grid>
                                <Grid item>
                                <FxButton
                                  id="mark-all-read-button"
                                  variant="contained"
                                  className="fx-button fx-button-theme fx-svg-theme"
                                  // permissions={{ entity: "Role", name: "Role", operation: "Create" }}
                                  onClick={handleMarkAllAsRead}
                                >
                                  MARK ALL AS READ
                                </FxButton>
                              </Grid></>}
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="all-alerts-card-body" className="fx-card-body-no-border-no-shadow">
                        <Grid id="all-alerts-card-body-grid" item xs={12}>
                            {!isSmallScreen ? 
                                <DataGrid 
                                    id="all-alerts-data-grid-list" 
                                    // permissions={permission} 
                                    tableClass="fx-table-passport" 
                                    dataTransformer={alertsList} 
                                    source={src} 
                                    column={column} 
                                    pagination={true} 
                                    pageNumber={1} 
                                    pageSize={25} 
                                    resultPerPage={25} 
                                /> : 
                                <FxGridList 
                                    id="all-alerts-data-grid-list" 
                                    source={src} 
                                    // permissions={permission} 
                                    dataTransformer={alertsListGridTransformer} 
                                    column={gridColumn} 
                                    pagination={true} 
                                    pageNumber={1} 
                                    pageSize={25} 
                                    resultPerPage={25} 
                                />}
                        </Grid>
                    </FxCardBody>
                    <FxCardFooter id="account-ledger-card-footer">
                    </FxCardFooter>
                </FxCard>
            </Grid>
        </Grid>
    )
})
