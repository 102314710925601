import { Grid, MenuProps } from '@mui/material';
import React, { useEffect } from "react";
import { UseFormRegisterReturn, UseFormSetValue, ControllerProps, UseFormClearErrors } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { ReactComponent as Selecticon } from '../../../assets/svg/select-arrow.svg';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg'
/**
 * Component used for material selectio
 */

type FxMaterialCreatableSelectType = ControllerProps & SelectProps;

type TFieldValues = Record<string, string | number>;

interface IData {
    label: number | string | React.ReactElement;
    value: string;
    operator?: string
}

interface IFilter {
    key: string;
    operator: string;
    values: string[];
}

export interface ISource {
    url: string;
    method: string;
    data?: {
        pageSize?: number;
        pageNumber?: number;
        sortOptions?: {
            sortOrder: string;
            sortBy: string;
        },
        criteria?: {
            filters?: IFilter[]
        }
    }
}

interface FxMaterialCreatableSelectProps extends Partial<FxMaterialCreatableSelectType> {
    name: string;
    helperText?: string;
    value?: string | number;
    setValue: UseFormSetValue<TFieldValues>;
    clearError?:UseFormClearErrors<TFieldValues>;
    onChange?: (event: any) => void;
    setParam?: (event: any) => void;
    register: UseFormRegisterReturn;
    data?: IData[];
    dataTransformation?: (data: any) => any;
    noResources?: any;
    source?: ISource;
    creatable?:any;
}

const FxMaterialCreatableSelect: React.FC<FxMaterialCreatableSelectProps> = React.memo(
    (props) => {

        let context: any;
        ({ context, props } = RegisterComponent(props));
        const [selectVal, setSelectVal] = React.useState(props.value);

        useEffect(() => {
            if (props.value && props.setValue) {
                props.setValue(props.name, props.value)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const FxMenuProps: Partial<MenuProps> = {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    // width: 250,
                    overflow: "Auto",
                    overflowX: 'hidden'
                },
            },
        };

        /**
         * On change handler
        * @param event :event info
         */
        function handleChange(event: any) {
            if (props.setValue) {
                props.setValue(props.name, event.target.value)
            }
            if (props.onChange) {
                props.onChange(event);
            }

            setSelectVal(event.target.value)
        }

        /**
         * Method used to maipulate data from api
         * @returns :selected options
         */
        function dataTransformation() {
            if (props.dataTransformation && context?.data?.body?.resources) {
                return props.dataTransformation(context.data.body.resources);
            }
            else if (props.dataTransformation && context?.data?.body && props.noResources) {
                return props.dataTransformation(context.data.body);
            }
            else if (props.dataTransformation && context?.data?.body?.settings) {
                return props.dataTransformation(context.data.body.settings);
            }
        }

        const selectOptions = props.source ? dataTransformation() : props.data ? props.data : [];

        const creatableOption = props?.creatable ? props?.creatable : {value:'New', label:'Add New Item'};

         /**
         * Method used to render label
         * @returns :selected item label
         */
        const renderValue=(selected:any)=>{
            if(selected === creatableOption?.value){
                return creatableOption?.label;
            }

            return selectOptions.find((item:any) => item.value === selected)?.label;
        }

        return (<>
            <FormControl variant="outlined" className={clsx("fx-input-edit", props.className)}>
                <InputLabel
                    ref={ref => { }}
                    htmlFor={props.id}
                    id={'label-material-' + props.id}
                    shrink
                >
                    {props.label}
                </InputLabel>
                <Controller
                    name={props.name}
                    control={props.control}
                    rules={props.rules}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <Select
                                {...props.register}
                                id={props.id + '-select'}
                                labelId={props.id + '-select'}
                                readOnly={props.readOnly ? props.readOnly : false}
                                value={selectVal}
                                onChange={onChange = (e: any) => {
                                    handleChange(e);
                                }}
                                renderValue={renderValue}
                                MenuProps={FxMenuProps}
                                label={props.label}
                                IconComponent={Selecticon}
                                className={props.readOnly ? 'fx-disabled' : 'fx-select-box-icon'}
                                error={error ? error as unknown as boolean : false}
                                inputProps={{
                                    name: props.name,
                                    id: props.id + '-input',
                                    readOnly : props.readOnly
                                }}

                            >
                                {selectOptions?.map((option: any, i: number) => {
                                    return <MenuItem className="fx-select-box" value={option.value} id={props.id + '-' + option.value}>{option.label}</MenuItem>
                                })}

                                {creatableOption &&
                                    <MenuItem className="fx-select-box" value={creatableOption?.value} id={props.id + '-' + creatableOption.value}>
                                        <Grid container className="fx-inline-flex" alignItems='center'>
                                            <Grid item ><AddIcon /> &nbsp; &nbsp; {creatableOption.label}</Grid>
                                         </Grid>
                                        {/* <AddIcon /> &nbsp; &nbsp; <ListItemText primary={creatableOption.label} />                                         */}
                                    </MenuItem>
                                }

                            </Select>)
                    }}
                />
            </FormControl>
        </>
        )
    })

export default FxMaterialCreatableSelect;
