import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { Logger } from "../../../../libs/utils/logger";
import FxCard from "../../../Container/FxCard";
import FxCardHeader from "../../../Container/FxCardHeader";
import { DataGrid } from "../../../Data/DataGrid";
import FxCardBody from "../../../Container/FxCardBody";
import { Filter } from '../../../Data/Filter';
import { IDataGridProps, IFilterColumns, IFilterFields } from "../../../../types/common.interfaces";
import { truncateChars, updateComponentParams } from "../../../Utils/CommonBaseClass";
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { ReactComponent as LeftDownArrow } from "../../../../assets/svg/arrow-left-downbig.svg";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";

Logger.debug(
    "FxOtherTransactionList.tsx",
    "Funding Rule Transaction Listing initializing"
);

/**
 * Component: FxOtherTransactionList
 * Usage: Listing of the Funding Rule Transaction
 */
export const FxOtherTransactionList: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();

    /**
     * Method contains the API source related info for the Transaction/list API
     */
    const sourceInfo = () => {
        var filters: any = [];
            const src = {
                url: "linked/transaction/list",
                method: "POST",
                data: {
                    pageNumber: 1,
                    pageSize: 25,
                    sortOptions: {
                        sortBy: "lastUpdatedOn",
                        sortOrder: "desc",
                    },
                    criteria: {},
                },
            };
            try {
                filters.push(
                    {
                        "operator": "in",
                        "key": "method",
                        "values": [props?.method]
                    },
                    {
                        "operator": "in",
                        "key": "fundingRuleId",
                  // parentRuleId is the parent id of funding rule that is inherited and ruleId is the funding rule id created after the deactation of inherited fundingRule
                        "values": props?.parentRuleId ? [props?.parentRuleId, props?.ruleId] : [props?.ruleId]
                    })

                src.data.criteria = { filters };
            } catch (e) { }
        return src;
    }

    /**
     * useEffect to reset the filter while unmount the page
     */
    useEffect(
        () => () => {
            updateComponentParams(dispatch, `funding-linked-transactions-list-card-other-methodfilter`, { resetFilter: true, });
        },
        [dispatch]
    );

    const filterFields: IFilterFields = {
        "fields": [
            {
                "label": "Amount",
                "fieldName": "amount",
                "type": "fri://fieldtype/amountrange",
                "data": [],
            },
            {
                "label": "Date",
                "toLabel": "To",
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/daterange",
                "data": [],
                "notAllowFutureDate": true
            }
        ]
    }

    /**
     * Filter Columns
     */
    const column: IFilterColumns = {
        title: "Select Columns to Show",
        columns: [
            {
                enable: true,
                fieldName: "icon",
                type: "fri://fieldtype/custom",
                default: true,
                style: { "width": "4.5rem", "padding-left": "0 !important" },
                dataStyle: { "text-align": "left !important" }
            },
            {
                label: "DATE",
                enable: true,
                fieldName: "transactionDate",
                type: "fri://fieldtype/date",
                default: true,
                style: { width: "11rem" },
            },
            {
                label: "Txn ID",
                enable: true,
                fieldName: "transactionId",
                type: "fri://fieldtype/default",
                default: true,
                style: { width: "9rem" },
            },
            {
                label: "STATUS",
                enable: true,
                fieldName: "status",
                type: "fri://fieldtype/status",
                default: true,
                style: { width: "11rem" },
            },
            {
                label: "STATUS REASON",
                enable: true,
                fieldName: "statusReason",
                type: "fri://fieldtype/custom",
                default: true,
                style: { width: "10rem" },
            },
            {
                label: "AMOUNT",
                enable: true,
                fieldName: "amount",
                type: "fri://fieldtype/custom",
                default: true,
                style: { width: "10rem", "text-align": "end" },
            },
            {
                enable: true,
                fieldName: "spacer",
                type: "fri://fieldtype/custom",
                default: true,
                style: { "width": "5.5rem" }
            },
            {
                label: "LAST UPDATED ON",
                enable: true,
                fieldName: "lastUpdatedOn",
                type: "fri://fieldtype/date",
                default: true,
                style: { "width": "8rem" }
            },
        ],
    };

    const datagridProps: IDataGridProps = {
        id: `funding-linked-other-transactions-data-grid-list-${props?.method}`,
        column: column,
        pagination: true,
        pageNumber: 1,
        pageSize: 25,
    };

    /**
     * Method handles the transformation of faster funded data
     * @param data
     * @returns : transformed data
     */
    function LinkedtransactionHistoryList(data: any) {
        const newData: any = [];
        data?.forEach((item: any, i: number) => {
            newData[i] = { ...data[i] };
            newData[i].status = item?.status;
            newData[i].statusReason = <><span title={item?.statusReason}>{` ${item.statusReason ? truncateChars(item?.statusReason, 35) : ''}`} &nbsp;</span></>
            newData[i].amount = (
                <FxCurrencyView
                    className={"balance-amount-green"}
                    justifyContent="flex-end"
                    value={item.amount}
                    prefix={"$"}
                    showPlus
                />
            );
            newData[i].icon = (
                <div className={"fx-passport-debit-credit-svg"}>
                    <LeftDownArrow />
                </div>
            );
        });
        return newData;
    }

    return (
        <Grid container xs={12} className="fx-layout-list">
            {props?.method && <FxCard id="funding-linked-other-transactions-card" className="fx-theme-passport">
                <FxCardHeader id="split-funding-split-header-second-other">
                    <Grid container item justifyContent="flex-start" xs>
                        <Typography variant="h3" >Linked Transactions</Typography>
                    </Grid>
                    <Grid id="funding-linked-other-transactions-list-card-header-filter-grid" xs item display="flex" justifyContent={"flex-end"}>
                        <Filter id={`funding-linked-transactions-list-card-other-methodfilter`} resetFilter={props?.method} field={filterFields} dataGridProps={datagridProps} hiddenParameter={['fundingRuleId','method']}></Filter>
                    </Grid>
                </FxCardHeader>
                <FxCardBody
                    id="funding-linked-other-transactions-card-body"
                    className="fx-card-body-no-border-no-shadow"
                >
                    <Grid id="funding-linked-other-transactions-card-body-grid" item xs={12} className="fx-faster-fund-card" >
                        <DataGrid
                            id={`funding-linked-other-transactions-data-grid-list-${props?.method}`}
                            tableClass="fx-table-passport"
                            dataTransformer={LinkedtransactionHistoryList}
                            source={sourceInfo()}
                            column={column}
                            pagination={true}
                            pageNumber={1}
                            pageSize={25}
                            resultPerPage={25}
                        />
                    </Grid>
                </FxCardBody>
            </FxCard>}
        </Grid>
    )
});
