import React from 'react';
import { Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxLabelView.tsx", "Fx Label View initializing")
/**
 * Component used to view text as label/title
 */
interface ILabelView {
    id?: string;
    value?: any;
    className?: any;
    title?: any;
}
const FxLabel: React.FC<ILabelView> = (props) => {
    return (
        <Typography title={props.title ? props.title : ''} id={props.id} className={props.className ? props.className : ''}>{props.value}
            {props.children}
        </Typography>
    )
}
export default FxLabel;