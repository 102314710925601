import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import { useDispatch, useSelector } from "react-redux";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input";
import { useForm } from "react-hook-form";
import { FxButton } from "../../Action/FxButton";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { useHistory } from "react-router";
import FxSnackBar from "../../Utils/fx-snack-bar";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { Logger } from "../../../libs/utils/logger";
import { FxSplitFundingCriteria } from "./CriteriaSection/FxSplitFundingCriteria";
import { FxSplitFundingSplitRuleSection } from "./SplitRuleSection/FxFundingRuleSplitRuleSection";
import { FxSkeltonList } from "../Cards/FxSkelton";
import FxMaterialMultipleSelect from "../../Input/FxSelect/FXMaterialMultipleSelect";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { FxSplitFundingAssociation } from "./AssociationSection/FxSplitFundingAssociation";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { FxPartnerAssociationSection } from "./PartnerAssociationSection/FxPartnerAssociationSection";
import FxCheckEdit from "../../Input/FxCheck/FxCheckEdit";
import { ReactComponent as InfoIcon } from '../../../assets/svg/darkgrey-info-icon.svg';
import { FxInfoTooltip } from "../../Utils/FxInfoTooltip";
import { getKey } from "../../../libs/utils/storageManager";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

/**
 * Component: Funding Rules Create Screen
 * Usage: To Create a new Funding Rule
 */
export const CreateFundingRule: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const dispatch = useDispatch();
  const history = useHistory();
  const [isloading, setIsloading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(props.source ? true : false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [onlyCard, setOnlyCard] = useState(true);
  const [hideTxn, setHideTxn] = useState(false);
  const httpClient = HttpClient.getClient();
  let ruleId: any;
  const enableCard = useSelector((state: any) => state.data['Funding-rule-enable-card']?.params?.enableCard || false);
  const uiFeat = UIFeatureImpl.getInstance();
  const checkIsPartner=uiFeat.getPartnerFeature().available;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch,
    resetField,
    getValues,
    clearErrors,
    setError
  } = useForm();


  const [transactionComponentData, setTransactionComponentData] = useState<any>(enableCard || checkIsPartner? [
    { label: "Total Amount", value: "TOTAL_AMOUNT", disabled: false },
    { label: "Sub Total", value: "SUBTOTAL", disabled: false },
    { label: "Surcharge", value: "SURCHARGE", disabled: false },
    { label: "Tip", value: "TIP", disabled: false }
  ] : [{ label: "Total Amount", value: "TOTAL_AMOUNT", disabled: false }])
  const [selectedMethods, setSelectedMethods] = useState<any>([]);

  //Method to switch methods and disable component options
  const switchTransactionOptions = (methodSelected: any) => {
    const selectedOptions = methodSelected?.map((ele: any) => {
      return ele?.value;
    })

    if (selectedOptions.length === 1 && selectedOptions?.includes('CARD')) {
      setHideTxn(false);
    } else {
      setHideTxn(true);
    }

    const optionsDisable = (selectedOptions.includes('CARD') && selectedOptions.length === 1) || methodSelected.length === 0 ? false : true
    setTransactionComponentData(enableCard || checkIsPartner? [{ label: "Total Amount", value: "TOTAL_AMOUNT", disabled: false },
    { label: "Sub Total", value: "SUBTOTAL", disabled: optionsDisable },
    { label: "Surcharge", value: "SURCHARGE", disabled: optionsDisable },
    { label: "Tip", value: "TIP", disabled: optionsDisable }] : [{ label: "Total Amount", value: "TOTAL_AMOUNT", disabled: false }]);
    setOnlyCard(methodSelected.length !== 0 ? !optionsDisable : true);
    optionsDisable && setValue('transactionComponent', 'TOTAL_AMOUNT');
  }

  if (context?.data?.body && !isDataLoaded) {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Funding Rules', backButton: '/fundingrule/view/' + context?.data?.body?.id } } });
    setIsDataLoaded(true); // set as true if edit data available
    setIsEdit(true);
    if (context?.data?.body?.action?.length > 0) {
      setCanSubmit(true);
    }

    if (context?.data?.body?.method) {
      const methods = context?.data?.body?.method?.map((ele: any) => {
        return { label: ele, value: ele }
      });
      setSelectedMethods(methods)
      switchTransactionOptions(methods);
    }

    updateComponentParams(dispatch, props?.id, { 'isOveridable': context?.data?.body?.isOverridable || false, 'isRecoup':context?.data?.body?.isRecoupable || false  });
  }

  /**
   * useEffect to load title while loading page
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props?.id } });
    if (!checkIsPartner) {
      getData(merchantId_src.url, merchantId_src.data, 'merchant_mid', 'mid')
      getData(mccCode_src.url, mccCode_src.data, 'merchant_industry');
    }

    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'faster-funding-data-list' } });

    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: {
        id: "page-title-details",
        data: {
          title: "Funding Rules",
          backButton: "/fundingrules/active",
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to handle Close Button of Create/Edit Screen
   */
  const handleClose = () => {
    if (context?.data?.body) {
      history.push('/fundingrule/view/' + context.data.body.id)
    } else {
      history.push("/fundingrules/active");
    }
  };

  /**
   * Async function handles the on submit of form
   * @param data : form request
   */
  async function handleSaveRule(data: any) {
    let createReq: any = {};
    let status: any;

    setIsloading(true);

    createReq = {
      name: data.name,
      method: data?.method?.map((ele: any) => ele.value),
      transactionComponent: data.transactionComponent,
      isRecoupable: props?.isRecoup || false
    };
    if (data?.priority) {
      createReq.rank = Number(data.priority);
    }

    if (checkIsPartner) {
    createReq.isOverridable = props?.isOveridable || false;
    }

    if (data?.["funding-rule-criteria"] && data?.["funding-rule-criteria"]?.length !== 0 && data?.applicableConditions !== 'toAll') {
      let criteria = [];
      criteria = data["funding-rule-criteria"].map((item: any) => ({
        key: `${item?.parameter ? item.parameter : ''}.${item?.tagInput ? item.tagInput : ''}`,
        operator: item?.operator,
        value: Array.isArray(item?.value) ? item.value: [item?.value]
      }));

      createReq.condition = criteria;
    }
    if (data?.["funding-rule-association"] && data?.["funding-rule-association"]?.length !== 0 && data?.applicableMerchants !== 'toAllMerchants' && !checkIsPartner) {
      let association = [];
      association = data["funding-rule-association"].map((item: any) => ({
        key: `${item?.name}`,
        operator:'in',
        value: item?.value?.map((ele: any) => ele?.value).filter((ele: any)=> ele !=='Select All')
      }));

      createReq.association = association;
    }
    if (data?.["funding-rule-partner-association"] && data?.["funding-rule-partner-association"]?.length !== 0 && data?.applicableCustomers !== 'toAllCustomers' && checkIsPartner) {
      let association = [];
      association = data["funding-rule-partner-association"].map((item: any) => ({
        key: `${item?.name}`,
        operator:'in',
        value: item?.name === "customer_type" ? [item?.value]: item?.value?.map((ele: any) => ele?.value).filter((ele: any)=> ele !=='Select All')
      }));

      createReq.association = association;
    }

    let splitRule = [];

    splitRule = data["funding-rule-split-rule"].map((item: any, index: any) => {
      //for category 'account'
      if (item?.category === 'my_account') {
        return ({
          account: {id: item?.account},
          amount: item?.amount ? Number(item.amount) : null,
          percentage: item?.percentage ? Number(item.percentage) : null,
          order: index + 1,
          transferTo: 'my_account'
        });
      }
      if (item?.category === 'my_contact') {
        return ({
          contact: {id: item?.contact?.value ? item?.contact?.value : item?.contact},
          amount: item?.amount ? Number(item.amount) : null,
          percentage: item?.percentage ? Number(item.percentage) : null,
          order: index + 1,
          transferTo: 'my_contact'
        });
      }
      //for category 'dyanmic'
      if (item?.category === 'dynamic' || item?.category === 'pick_from_tag') {
        return ({
          tagName: item?.variableName,
          tagMapsTo: item?.variableToMap,
          amount: item?.amount ? Number(item.amount) : null,
          percentage: item?.percentage ? Number(item.percentage) : null,
          order: index + 1,
          transferTo: 'PICK_FROM_TAG'
        });
      }
      if (item?.category === 'partner' || item?.category === 'customer') {
        return ({
          account: {tag: item?.tag},
          amount: item?.amount ? Number(item.amount) : null,
          percentage: item?.percentage ? Number(item.percentage) : null,
          order: index + 1,
          transferTo: item?.category === 'partner'? 'PROGRAM_AFFILIATE':item?.category
        });
      }
      return item;
    });

    createReq.action = splitRule;
    const url = props?.isFundingRuleEdit ? getCustomerUrl("fundingRule/id/"+props?.ruleId, false): getCustomerUrl("fundingRule", false)

    status = await createFundingRule(createReq, url);

    if (status?.headers?.url) {
      ruleId = status.headers.url.split("/").pop();
    }
    setIsloading(false);
    status = processAPIResponse(status);

    if (status.status) {
      // api success
      setTimeout(() => {
        if(props?.isFundingRuleEdit){
          const successMessage = 'Funding Rule Updated Successfully!';
          FxSnackBar.show({
            autoHideDuration: 1000,
            severity: 'success',
            text: successMessage,
           });
          history.push("/fundingrule/view/" + props?.ruleId);
        }else{
          history.push(`/thanks/manage/fundingrule/${props?.isFundingRuleEdit ? props?.ruleId: ruleId}`);
        }
      }, 400);
    } else {
      //api  failed
      updateComponentParams(dispatch, props?.id, { 'isOveridable': props?.isOveridable , 'isRecoup': props?.isRecoup});
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
   * Method to call api for creating funding Rule
   * */
  async function createFundingRule(paylaoddata: any, url:any) {
    try {
      const data: any = await httpClient.post(
        url,
        paylaoddata
      );
      return data;
    } catch (err) {
      Logger.error("CreateFundingRule.tsx", "error", err);
      return err;
    }
  }

  const applicableMethods = enableCard || checkIsPartner? [
    { value: 'CARD', label: 'Card' },
    { value: 'ACH', label: 'ACH' },
    { value: 'WIRE', label: 'Wire' },
    { value: 'MONEYGRAM', label: 'MONEYGRAM' },
    { value: 'CHECK', label: 'Check' }
  ]: [
    { value: 'ACH', label: 'ACH' },
    { value: 'WIRE', label: 'Wire' },
    { value: 'MONEYGRAM', label: 'MONEYGRAM' },
    { value: 'CHECK', label: 'Check' }
  ]

  const mccCode_src = {
    url: "merchant/mcc",
    method: "POST",
    data: {
      pageNumber: 1,
      pageSize: 100,
      sortOptions: {
        sortBy: "lastUpdatedOn",
        sortOrder: "desc",
      },
      criteria: {
        filters: [
          {
            key: "code",
            operator: "like",
            values: [""],
          },
          {
            key: "entityId",
            operator: "eq",
            values: [getKey('customerId')],
          }
        ],
      },
    },
  };

  const merchantId_src = {
    url: "merchant/list",
    method: "POST",
    data: {
      pageSize: 100,
      pageNumber: 1,
      criteria: {
        filters: [
          {
            key: "mid",
            operator: "like",
            values: [""],
          },
        ],
      },
    },
  };

  /**
   * To transform the data
   * @param data: Data received from api
   */
  function dataTransformerForValue(data: any, param: any = '', searchParam: any = '') {

    const transformedData =  data?.map((item: any) => {
      const valueKey = param === 'merchant_mid' ? searchParam : param === 'merchant_industry' ? "code" : null;
      const subKey = 'description';

      if (valueKey) {
        return valueKey === 'code' ? { value: `${item[valueKey]}`, label: `${item[subKey]} (${item[valueKey]})` } : { value: `${item[valueKey]}`, label: `${item[valueKey]}` }
      }
      // Return a default value or handle other cases as needed
      return null;
    });
    return transformedData.length > 0 ? [...[{value:'Select All', label: 'Select All'}, ...transformedData]]:[];
  }

  /**
   * Method used to call request roles
   * @param body :form data
   * @returns
   */
  async function getData(url: any, payloadData: any, param: any = '', searchParam: any = '') {
    try {
      await httpClient.post(getCustomerUrl(url, false), payloadData).then(response => {
        if (param === 'merchant_mid' && response.data && response.data.resources && response.data.resources.length > 0) {
          if (searchParam === 'mid') {
            updateComponentParams(dispatch, 'faster-funding-data-list', { 'merchantList': dataTransformerForValue(response.data.resources, 'merchant_mid', searchParam) })
          }
        } else if (param === 'merchant_industry' && response?.data && response?.data?.settings && response?.data?.settings?.length > 0) {
          updateComponentParams(dispatch, 'faster-funding-data-list', { 'mccList': dataTransformerForValue(response.data.settings, 'merchant_industry') })

        }
      })
    } catch (err) {
      Logger.error("FxSplitFundingCriteria.tsx", "error", err);
      return err;
    }
  }

  const overriddendata = [
    { label: 'Allow rule to be overridden at lower level', value: 'override', checked: props?.isOveridable || false}
  ];

  const recoupEnabledData = [
    { label: 'Enable recoup of funds for reversals', value: 'enableRecoup', checked: props?.isRecoup || false }
  ];

  /**
   * reset the property
   * */
     useEffect(() => () => { 
      updateComponentParams(dispatch,  props?.id, { 'isOveridable': false, 'isRecoup': false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <Grid id="funding-rules-list-grid" item xs={12}>
      <FxCard className="fx-schedule-card-general fx-theme-passport fx-no-box-shadow">
        <div className="fx-form-edit-profile flex column">
          {!isDataLoaded && isEdit && <FxSkeltonList height="40rem" />}
          {(isDataLoaded || !isEdit) && <form id="create-funding-rule">
            <FxCardBody className="fx-info-card">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className="flex column fx-container-create-schdule"
              >
                <Grid
                  item
                  xs
                  className="fx-container-create-schdule-middle-scroll fx-container-create-schdule-left-layout fx-splitfunding-split"
                >
                  <FxCard id="create-funding-rule-form-card">
                    <FxCardBody
                      id="create-funding-rule-form-card-body"
                      className="fx-info-card"
                    >
                      {/* General Info */}
                      <Grid container item xs={12} columnSpacing={2}>
                        <Grid item xs={12} sm={12}>
                          <fieldset className="fx-container-create-schdule-summery">
                            <Grid container item direction="row" spacing={2}>
                              <Grid item xs={4}>
                                <FxTextEdit
                                  register={{ ...register("name") }}
                                  className={
                                    errors.name
                                      ? "border-error-input fx-input-edit"
                                      : "fx-input-edit"
                                  }
                                  control={control}
                                  inputProps={{ maxLength: 256 }}
                                  rules={{ required: true }}
                                  id="create-funding-rule-form-card-rule-name-textbox"
                                  label="Name*"
                                  name="name" 
                                  variant="outlined"
                                  defaultValue={context?.data?.body?.name ? `${context?.data?.body?.name} ${!props?.isFundingRuleEdit ? '(Copy)':''}`: ''}
                                />
                              </Grid>
                              <Grid item xs={4} className="fx-contact-user-card">
                                <FxMaterialMultipleSelect name='method'
                                  selectAllLabel={'Select All'}
                                  control={control}
                                  isEdit={false}
                                  id="applicable-funding-rules"
                                  listBoxClass="fx-auto-complete-custom-auto-complete-dropdown-display-block"
                                  data={applicableMethods}
                                  rules={{ required: true }}
                                  limitTags={3}
                                  label='Applicable Transaction Method*'
                                  canSearch={false}
                                  value={selectedMethods}
                                  register={{ ...register("method") }}
                                  onSelect={switchTransactionOptions}
                                  setValue={setValue} />
                              </Grid>
                              <Grid item xs>
                                <FxMaterialSelect
                                  register={{ ...register("transactionComponent") }}
                                  control={control}
                                  rules={{ required: true }}
                                  id={"funding-rule-transactionComponent"}
                                  name={`transactionComponent`}
                                  data={transactionComponentData}
                                  label="Select Transaction Component*"
                                  setValue={setValue}
                                  value={context?.data?.body?.transactionComponent || 'TOTAL_AMOUNT'}
                                />
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                            {checkIsPartner && <Grid item xs={12} sm={12} marginTop={'1rem'} display={'inline-flex'}> 
                              <FxCheckEdit className="fx-partner-split-funding-label-checkbox" register={{ ...register("setOverridden") }} control={control} data={overriddendata} id="set-overridden-funding-rule" name="setOverridden"  row="vertical" setValue={setValue} 
                               onChange={()=>updateComponentParams(dispatch, props?.id, { 'isOveridable': !props?.isOveridable })}/>             
                              <FxInfoTooltip  arrow placement={'right-end'} title={'The rule can be overridden by the applicable customers'}>
                                <InfoIcon className="fx-icon-button fx-partner-split-funding-tooltip"></InfoIcon>
                              </FxInfoTooltip>
                            </Grid>}
                            <Grid item xs={12} sm={12} display={'inline-flex'}> 
                              <FxCheckEdit className="fx-partner-split-funding-label-checkbox" register={{ ...register("setRecoupEnable") }} control={control} data={recoupEnabledData} id="set-recoup-funding-rule" name="setRecoupEnable"  row="vertical" setValue={setValue}
                                onChange={()=>updateComponentParams(dispatch,  props?.id, { 'isRecoup': !props?.isRecoup })}
                            />
                              <FxInfoTooltip  arrow placement={'right-end'} title={'In case of chargebacks, refunds, or returns, recover the funds from accounts where funds were initially split'}>
                                <InfoIcon className="fx-icon-button fx-partner-split-funding-tooltip"></InfoIcon>
                              </FxInfoTooltip>
                            </Grid>
                            </Grid>
                          </fieldset>
                        </Grid>
                      </Grid>

                      {/* Association */}
                      {onlyCard && !checkIsPartner && <FxSplitFundingAssociation
                        id={'funding-rule-assocation-main-component'}
                        control={control}
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        resetField={resetField}
                        watch={watch}
                        data={context?.data?.body?.association}
                        isEdit={isEdit}
                        ownership={context?.data?.body?.ownership}
                      />}

                      {checkIsPartner && <FxPartnerAssociationSection
                        id={'funding-rule-partnerassocation-main-component'}
                        control={control}
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        resetField={resetField}
                        watch={watch}
                        data={context?.data?.body?.association}
                        isEdit={isEdit}
                      />}

                      {/* Criteria */}
                      {<FxSplitFundingCriteria
                        id={'funding-rule-criteria-main-component'}
                        control={control}
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        resetField={resetField}
                        watch={watch}
                        onlyCard={onlyCard}
                        data={context?.data?.body?.condition}
                        hideTxn={hideTxn}
                        isEdit={isEdit}
                      />}

                      {/* Rules */}
                      <FxSplitFundingSplitRuleSection
                        control={control}
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        resetField={resetField}
                        watch={watch}
                        getValues={getValues}
                        data={context?.data?.body?.action}
                        setCanSubmit={setCanSubmit}
                        setError={setError}
                        clearErrors={clearErrors}
                        onlyCard={onlyCard}
                      />
                    </FxCardBody>
                    <FxCardFooter
                      id="create-funding-rule-form-card-footer"
                      className="fx-footer"
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        className="fx-modal-footer"
                        marginTop={"2rem"}
                      >
                        <FxButton
                          variant="contained"
                          className="fx-button fx-button-cancel"
                          id="create-funding-rule-form-card-cancel-button"
                          onClick={handleClose}
                        >
                          Cancel
                        </FxButton>
                        <span className="fx-padding-right-16" />
                        <FxButton
                          disableRipple={false}
                          className={canSubmit === false ? "fx-button fx-button-disabled" : "fx-button fx-button-theme"}
                          id={"create-funding-rule-btn"}
                          onClick={handleSubmit((data) => handleSaveRule(data))}
                          disabled={canSubmit === false ? true : false}
                        >
                          {isloading ? (
                            <CircularProgress
                              size={20}
                              style={{
                                color: "white",
                              }}
                            />
                          ) : (
                            "PUBLISH RULE"
                          )}
                        </FxButton>
                      </Grid>
                    </FxCardFooter>
                  </FxCard>
                </Grid>
              </Grid>
            </FxCardBody>

            <Grid
              container
              xs={12}
              id={`form-card-footer`}
              className="fx-card-footer-mobile"
              justifyContent="space-around"
            ></Grid>
          </form>}
        </div>
      </FxCard>
    </Grid>
  );
});
