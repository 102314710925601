import React from 'react';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { Badge, Box, Grid, Typography } from '@mui/material';
import { ReactComponent as Verified } from '../../../assets/svg/verify-filled-icon.svg';

interface ITreasureLeftMenu {
    step: number;
}

export const FxTreasureLeftMenu: React.FC<ITreasureLeftMenu> = ({step}) => {
    return (
        <FxCard className='fx-treasure-onboard-signup-left-menu'>
            <FxCardBody>
                <Grid item container xs={12} className={step === 2 ? 'fx-investment-account-selected' : ''}>
                    <Grid item xs={12} className={step >= 2 ? 'fx-signup-stage fx-signup-stage-selected' : 'fx-signup-stage'}>
                        <ul>
                            <li>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Badge badgeContent={1}  >
                                            { step >= 3 && <Box className='fx-signup-stage-icon' >
                                                <Verified />
                                            </Box>}
                                        </Badge>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography className='fx-signup-stage-title'>Review Business Info</Typography>
                                        <Typography className='fx-signup-stage-description'>Review & Update your business information for the Treasure Investment account.</Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={step === 3 ? 'fx-investment-account-selected' : ''}>
                    <Grid item xs={12} className={step >= 3 ? 'fx-signup-stage fx-signup-stage-selected' : 'fx-signup-stage'}>
                        <ul>
                            <li>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Badge badgeContent={2}  >
                                            { step >= 4 && <Box className='fx-signup-stage-icon' >
                                                <Verified />
                                            </Box>}
                                        </Badge>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography className='fx-signup-stage-title'>Review Auth Signatory Details</Typography>
                                        <Typography className='fx-signup-stage-description'>Review auth signatory information for the Treasure Investment account.</Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={step === 4 ? 'fx-investment-account-selected' : ''}>
                    <Grid item xs={12} className={step >= 4 ? 'fx-signup-stage fx-signup-stage-selected' : 'fx-signup-stage'}>
                        <ul>
                            <li>
                                <Grid item container xs={12}>
                                    <Grid item>
                                        <Badge badgeContent={3}  >
                                            { step >= 5 && <Box className='fx-signup-stage-icon' >
                                                <Verified />
                                            </Box>}
                                        </Badge>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography className='fx-signup-stage-title'>Review Agreement</Typography>
                                        <Typography className='fx-signup-stage-description'>Accept the Investment advisor agreements.</Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </FxCardBody>
        </FxCard>
    )
}
