import React, { useMemo, useState } from 'react';
import { Logger } from '../../../libs/utils/logger';
import { Grid, Modal, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as VisaIcon } from '../../../assets/svg/debit-card-visa-icon.svg';
import { ReactComponent as Globe } from '../../../assets/svg/debit-card-globe-icon.svg';
import { ConfirmPassword } from './ConfirmPassword';
import { FxSuccess } from '../../Data/FxSuccess';
import { DebitCardActions } from './DebitCardActions';
import { LogoMenu } from '../../Utils/Logo';
import { useSelector } from 'react-redux';
import FxImage from '../../Data/FxImage';
import { FxSkeltonRightInfoCard } from '../Cards/FxSkelton';

Logger.debug("DebitCardView.tsx", "Debit Card view initializing");

/**
 * Component: DebitCardView
 * Usage: To display Debit Card View
 */

export const DebitCardView: React.FC<any> = React.memo(
    (props) => {
        const [revealCvv, setRevealCvv] = useState(false);
        const [showInfo, setShowInfo] = useState(true);
        const [openModal, setOpenModal] = useState(false);
        const [success, setSuccess] = useState(false);
        const [description, setDescription] = useState('');

        const debit_card_data = useMemo(() => {
            const debit_data = { ...props.data }

            if (showInfo) {
                debit_data.accountNumber = props?.data?.cardNumber? ('**** **** **** ' +  (props?.data?.cardNumber?.length > 4 ? props?.data?.cardNumber?.slice(-4) : props?.data?.cardNumber )): props?.data?.cardLast4 ? '**** **** **** ' + props?.data?.cardLast4 :"**** **** **** ****" 
                debit_data.validFrom = '02/2022'
                debit_data.validTo = '04/2024'
                debit_data.HolderName = props?.data['cardHolder'] && props?.data['cardHolder']["type"] === "BENEFICIAL_OWNER" ? props?.data?.cardHolder?.name : props?.data?.cardHolder?.name
            }
            else {
                debit_data.accountNumber = '**** **** **** ****'
                debit_data.validFrom = '**/****'
                debit_data.validTo = '**/****'
                debit_data.HolderName = '*******'
            }
            debit_data.cvv = '234'
            return debit_data
        }, [props.data, showInfo])

        /**
        * to get debit card image details
        */
        const cardImageData = useSelector((state: any) => {
            if (state.data['digital-card-image']) {
            return state.data['digital-card-image']
            }
        });

        return (
            <>
        
            {success && <FxSuccess id="sucess-change-password" header={(description && description.includes('Transaction limits'))? "Transaction limits Updated":"Request Sent"} description={description} footer={"Go to debit card listing"} redirect={"/debitcards"} setClose={props.close}/>}

                <Grid id="debit-card-view-info-Grid " className="fx-margin-bottom-16" item xs={12}>
                    <FxCard id="debit-card-view-info-card" className="fx-card-debit">
                        <FxCardBody id="debit-card-info-card#card-body" >
                            {props?.dataLoadStatus ? <>
                                { props?.data && props?.data?.properties && !props?.data?.properties?.isDigitalFirst && <Grid container item xs={12} alignItems="flex-start" direction="row" className='fx-card-debit-view' >
                                    {!revealCvv && <Grid item container>
                                        <Grid item container alignItems='left' paddingTop={'1rem'}>
                                            <Grid item xs={9} sm={8}>
                                                <Grid className="fx-logo-passport-menu-icon"><LogoMenu /></Grid>
                                            </Grid>
                                            <Grid item xs={3} sm={4}>
                                                <Typography className='fx-card-debit-view-virtual'></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} > &nbsp;</Grid>
                                        <Grid item xs={12} > &nbsp;</Grid>
                                        <Grid item container className='fx-card-debit-view-name'>
                                            <Grid item xs={12} >
                                                <Typography> {debit_card_data.HolderName} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container  className='fx-card-debit-view-account'>
                                            <Grid item xs={9} >
                                                <Typography> {debit_card_data.accountNumber} </Typography>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <VisaIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                    {revealCvv && <>
                                        <Grid item xs={12}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Globe />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography> CVV</Typography>
                                            <Typography> {debit_card_data.cvv} </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={8}>
                                                Please do not disclose your CVV code to third parties.
                                            </Grid>
                                        </Grid></>}
                                </Grid>}

                                { props?.data && props?.data?.properties && props?.data?.properties?.isDigitalFirst && !cardImageData?.params?.showImage &&
                                <Grid item xs={12} className='fx-digital-debit-card-view'>
                                    <Grid item xs={12} className='fx-digital-debit-card-view-name'>
                                        <Typography className='fx-digital-debit-card-view-label'>CARD HOLDER NAME</Typography>
                                        <Typography className='fx-digital-debit-card-view-holder-name'>{debit_card_data.HolderName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className='fx-digital-debit-card-view-number'>
                                        <Typography className='fx-digital-debit-card-view-label'>ACCOUNT NUMBER</Typography>
                                        <Typography className='fx-digital-debit-card-view-hidden-number'>**** **** **** ****</Typography>
                                    </Grid>
                                    <Grid container direction='row' xs='auto' alignItems='end'>
                                        <Grid item xs={5}>
                                            <Typography className='fx-digital-debit-card-view-label'>CVV</Typography>
                                            <Typography className='fx-digital-debit-card-view-hidden-cvv'>***</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography></Typography>
                                            <Typography className='fx-digital-debit-card-view-hidden-expiry'>***</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                
                                {props?.data && props?.data?.properties?.isDigitalFirst && cardImageData?.params?.showImage  &&
                                <Grid className='fx-show-digital-debit-card' item xs={12}>
                                    <FxImage id="digital-card-image" src={cardImageData?.params?.image ? 'data:image/png;base64,'+cardImageData?.params?.image :''} />
                                </Grid>}

                                <Grid>
                                    <DebitCardActions data={props.data} setSuccess={setSuccess} setDescription={setDescription}/>
                                </Grid>
                            </>
                            : <FxSkeltonRightInfoCard height="30rem" />}
                        </FxCardBody>
                    </FxCard>
                </Grid >

                <Modal
                    style={{ overflow: 'scroll' }}
                    open={openModal}
                    onClose={() => { setOpenModal(false) }}
                    disableEnforceFocus={true}
                    disableAutoFocus={false}
                    className="fx-modal fx-modal-small2medium"
                >
                    <ConfirmPassword openInfo={''} revealCVV={() => { setRevealCvv(!revealCvv) }} showInfo={() => { setShowInfo(!showInfo) }} id={'confirm-password'} close={() => { setOpenModal(false) }} />
                </Modal>

            </>

        )
    })