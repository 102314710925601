import React from 'react';
import { useParams } from "react-router-dom";
import { CreateApprovalPolicy } from './CreateApprovalPolicy';
/**
 * Component: EditApprovalPolicy
 * Usage: To edit ApprovalPolicy details
 */
export const EditApprovalPolicy= () => {
    interface RouteParams {
        id: string
    }
    const params = useParams<RouteParams>();
    /**
     * get ID from  url
     */
    const id = params.id
    /**
     * policy details API
     */
    const policy_source = {
        url: "approvalPolicy/id/" + id,
        method: "GET"
    }
    const rand = Math.floor(Math.random() * 100) + 1;
    return (
        <>
            <CreateApprovalPolicy id={rand + 'edit-user' + id} source={policy_source} />
        </>
    )
}
