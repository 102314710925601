/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { getKey } from '../../../../libs/utils/storageManager';
import { FxLink } from '../../../Action/FxLink';
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";


export const ShowTxnId: React.FC<any> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));

    const history = useHistory()
    const dispatch = useDispatch()


    let typeOfMethod: string = ''


    if (context?.data?.body?.source?.account?.id && context?.data?.body?.source.account?.id === getKey("selectedAccount")?.id) {
        typeOfMethod = 'send'
    }
    else if (context?.data?.body?.destination?.account?.id && context?.data?.body?.destination?.account?.id === getKey("selectedAccount")?.id) {
        typeOfMethod = 'collect'
    }
/**
 *  Function handles redirection 
 */
    function handleRedirect() {

        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'schedule-processing-data-source' } });
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-processing-data-destination' } });
        history.push('/transactions/' + typeOfMethod + '/' + props?.txnId)
    }

    return (
        <>
            (Txn Id:
            <FxLink id={props?.data?.feeAgainstTransaction?.id} onClick={handleRedirect}
                className={"fx-submit-agree-color"} > {props?.txnId}
            </FxLink>)
        </>
    )

})