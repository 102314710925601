import React from 'react'
import { Grid, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as DividerIcon } from '../../../../assets/svg/divider-icon.svg';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import HttpClient from '../../../../libs/utils/httpClient';
import { clean, maskAccountNumber } from '../../../Utils/CommonBaseClass';
import { Logger } from '../../../../libs/utils/logger';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import { FxButton } from '../../../Action/FxButton';
import { useDispatch } from 'react-redux';
const httpClient = HttpClient.getClient();

Logger.debug("SetAsPrimaryAccountModal.tsx", "Set As Primary Account Modal initializing")

/**
 * This component handles the Set As Primary Account
 */
export const SetAsPrimaryAccountModal: React.FC<any> = React.memo((props) => {
    
    const { handleSubmit, formState: { isSubmitting } } = useForm();

    const { accountId, nickname, accountNumber } = props.metadata;

    const dispatch = useDispatch();

    const account_source = {
        url: "account/list",
        method: "POST",   
        data: {
    
            "pageSize": 5,
            "pageNumber": 1,
            "sortOptions": {
                "sortOrder": "ASC",
                "sortBy": "id"
            },
            "criteria": {
                "filters": [
                    {
                        "key": "insightPeriod",
                        "operator": "eq",
                        "values": [
                            "MONTHLY"
                        ]
                    }
                ]
            }
        }
    };
        
    /**
     * Async function handles the form submission
     */
    async function onSubmit(data: any) {
        let status: any;
        let request: any;

        request = {
            "isPrimary": true,
        };
        
        status = await setAsPrimaryAccount(clean(request));
        status = processAPIResponse(status)
        
        if(status.status){
            dispatch({type: "DATA_API_PARAM_CHANGED", payload: { id: 'account-list-sub-menu', source: account_source}});     
        } else {
            //api  failed
            FxSnackBar.show({
            text: status.message,
            });
        }
        props.onClose();
    }

    /**
     * Method to call api for update Account
     * @param payloadData : request payload
     */
    async function setAsPrimaryAccount(payloadData: any) {
        try {
            const url =  '/account/id/' + accountId;
            const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData)
            return data;
        } catch (err) {
            Logger.error("SetAsPrimaryAccountModal.tsx", "error", err);
            return err;
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs direction='column'>
                    <span>
                        <DividerIcon />
                    </span>
                    <Typography id="set-primary-account-subtext" variant="h5"  className='fx-set-primary-account-subtext'>
                        Are you sure you want to make "{nickname}{nickname && ' '}Passport Account {maskAccountNumber(accountNumber,false)}" your Primary passport account?
                    </Typography>
                    <span>
                        <DividerIcon />
                    </span>
                    <Typography id="set-primary-account-note" variant="h5" className='fx-set-primary-account-note'>
                        NOTE*- Primary Account is linked to your PPI, if any passport customer sends you money using your PPI, then it will be credited to Primary account.
                    </Typography>
                    <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                        <Button
                            variant="contained"
                            className="fx-button fx-button-cancel"
                            id="set-primary-cancel-button"
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>
                        <span className="fx-padding-right-16" />
                        <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-theme"
                            id={"set-primary-account-button"}
                            type="submit"
                            isSubmitting={isSubmitting}
                        >
                            SUBMIT
                        </FxButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
});
