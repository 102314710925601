import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Logger } from "../../../libs/utils/logger";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { FxButton } from "../../Action/FxButton";
import { FxInvestNowModal } from "./FxInvestnowModal";
import { FxRedeemAmountModal } from "./FxRedeemAmountModal";
import { FxStepper } from "../../Input/FxStepper/FxStepper";
import { ReactComponent as Success } from '../../../assets/svg/stepper-success.svg';
import { ReactComponent as Warning } from '../../../assets/svg/stepper-warning.svg';
import { ReactComponent as Failed } from '../../../assets/svg/stepper-failed.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/stepper-customer-arrow-icon.svg'
import { ReactComponent as InvestNowIcon } from '../../../assets/svg/Invest-now-logo.svg'
import { ReactComponent as RedeemIcon } from '../../../assets/svg/Redeem-logo.svg'
import { useDispatch, useSelector } from "react-redux";
import { FxLink } from "../../Action/FxLink";
import { InvestmentReportChartCard } from "./InvestmentReportChartCard";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import moment from 'moment';
import { FxSuccess } from '../../Data/FxSuccess';
Logger.debug("LedgerBatchSummary.tsx", "Ledger Batch Summary initializing")

/**
 * Component used to show Ledger Batch Summary
 */
 
export const FxInvestmentSummary: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const portfolioStatusData = useSelector((state: any) => state.data['investment-account-status']?.data?.portfolioStatusData || null);
    const [openInvestNow, setOpenInvestNow] = useState(false);
    const [openRedeemAmount, setOpenRedeemAmount] = useState(false);  

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: props.id } });
        updateComponentParams(dispatch, props?.id, { showGraph:false})
        updateComponentParams(dispatch,  props.id, { redeemSuccess: false, redeemSuccessMessage:null})    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const handleInvestNowOpen = () => {
        setOpenInvestNow(true);
    };
    const handleInvestNowClose = () => {
        setOpenInvestNow(false);
    }

    const handleRedeemAmountOpen = () => {
        setOpenRedeemAmount(true);
    };
    const handleRedeemAmountClose = () => {
        setOpenRedeemAmount(false);
    }
    const modalClose=()=>{
        updateComponentParams(dispatch, 'investment-summary-card', { 'investSuccessMessage': false})
    }

    /**
     * On close redeem fx success handler
     */
    const closeRedeemFxSuccess =()=>{
        updateComponentParams(dispatch, props.id, { redeemSuccess: false, redeemSuccessMessage:null})    
    }

    /**
    * On investment graph show hide toggle handler.
   */
    const onToggleGraphHandler =()=>{
        updateComponentParams(dispatch, props?.id, { showGraph:!props.showGraph})   
    }

     /**
     * to get accountList details
    */


    const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
    const selectedAccount = accountListInfo?.params?.selectedOption;

    const accountActivationInProgress = useMemo(() => {
        return [{
            'icon': Success,
            'text': 'Submitted'
        },
        {
            'icon': ArrowIcon
        },
        {
            'icon':  portfolioStatusData?.status === 'AWAITING_VERIFICATION' ? Success : Warning,
            'text': 'Under Review',
        },
        {
            'icon': ArrowIcon
        },
        {
            'icon': portfolioStatusData?.status === 'REJECTED' ? Failed : Warning,
            'text': portfolioStatusData?.status === 'REJECTED' ? 'Rejected' : 'Activated'
        }]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, portfolioStatusData]);

    const customerDetails = {...props.customerDetails}
    const showSignupButton = (selectedAccount?.status === 'ACTIVE' && !portfolioStatusData?.status && (customerDetails?.business?.businessCategory === 'LLC'||customerDetails?.business?.businessCategory === 'SINGLE_MEMBER_LLC'||customerDetails?.business?.businessCategory === 'PARTNERSHIP'||customerDetails?.business?.businessCategory=== 'S_CORP'||customerDetails?.business?.businessCategory === 'C_CORP'||customerDetails?.business?.businessCategory === 'NON_PROFIT'))

    return (
        <>
        <Grid container xs={12}>
            <FxCard id="investment-summary-card" className="fx-investment-summary-card">
                <FxCardHeader className="fx-investment-summary-card-header">
                    <Grid container columnSpacing={2} alignItems="center" justifyContent='space-between' marginBottom='1rem'>     
                        <Grid item>
                            <Grid container columnSpacing={2} direction='column'>
                                <Grid item>
                                    {/* <Typography id="title-payees-info" variant="h3">Investment Summary</Typography>     */}
                                    <Typography variant='h5' className='fx-title'>Investment Summary</Typography>                                    
                                </Grid>
                                {portfolioStatusData?.lastUpdatedOn &&
                                    <Grid item>                                       
                                        <Typography className='fx-description'>Last Updated On {moment(new Date(portfolioStatusData?.lastUpdatedOn)).format('MMM DD, YYYY')}</Typography>
                                    </Grid>
                                }
                            </Grid> 
                            
                        </Grid>
                        <Grid item display='flex' justifyContent='end'>
                            <Grid container columnSpacing={2}>
                                    { (selectedAccount?.status === 'ACTIVE' && portfolioStatusData?.status === 'ACTIVE') && 
                                         <>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-theme"
                                                    title='Invest Now'
                                                    id="invest-now-button"
                                                    onClick={handleInvestNowOpen}
                                                >
                                                    <InvestNowIcon className="fx-invest-btn-svg"/><span className="fx-invest-btn-text">INVEST NOW</span>
                                                </FxButton>
                                            </Grid>
                                            <Grid item>
                                                <FxButton
                                                    className="fx-button fx-button-theme"
                                                    title='Redeem'
                                                    id="redeem-button"
                                                    onClick={handleRedeemAmountOpen}
                                                >
                                                    <RedeemIcon className="fx-invest-btn-svg"/><span className="fx-invest-btn-text">REDEEM</span>
                                                </FxButton>

                                            </Grid>
                                        </>
                                    }
                                
                                { showSignupButton && <Grid item container className="fx-treasure-card-manage-signup ">
                                    <FxButton className={"fx-button fx-button-signup-saturated-orange"} id={"treasure-signup"} onClick={props.openPassportDrawer}>SIGN UP FOR INVESTMENT</FxButton>
                                </Grid>}
                            </Grid> 
                        </Grid>  
                        { (selectedAccount?.status === 'ACTIVE' && (portfolioStatusData?.status === 'AWAITING_VERIFICATION' || portfolioStatusData?.status === 'REJECTED' || portfolioStatusData?.status === 'NEW')) && 
                        <Grid item xs={12} className='fx-stepper'>
                            <FxStepper steps={accountActivationInProgress} alternativeLabel={false} />
                            <Grid container justifyContent='center'> 
                                <Grid item><Typography variant='h6'>Your Treasure account activation is in progress and will take 3-5 days.</Typography></Grid>
                            </Grid>
                         </Grid>}                                      
                    </Grid>    
                </FxCardHeader>
                <FxCardBody className="fx-info-card fx-margin-top-reducer">                   
                    <Grid container item alignItems="center">
                        <Grid item container className="fx-investment-summary-card-amount-section"  >                                   
                            <Grid item container xs direction={"column"} className="fx-investment-summary-card-amount-section-one">
                                <Grid item>
                                    <Typography id="current-investment-value" className="fx-investment-summary-card-amount-section-one-text">CURRENT INVESTMENT VALUE</Typography>
                                    <span className="fx-padding-right-16"/>
                                    {(selectedAccount?.status === 'ACTIVE' && portfolioStatusData?.status === 'ACTIVE') &&  
                                        <Typography id="graph" variant="body2"><FxLink className={'fx-investment-link'} id='account-details' onClick={onToggleGraphHandler} > {props?.showGraph ? 'HIDE GRAPH' : 'VIEW GRAPH'}</FxLink></Typography>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography id="" className="fx-digest-grid">
                                        <FxCurrencyView id="current-investment-value" value={props?.portfolioData?.investmentProvider[0]?.currentInvestmentValue ? props?.portfolioData?.investmentProvider[0]?.currentInvestmentValue : 0} prefix={"$"} />
                                    </Typography>
                                </Grid>
                                {/* <Grid item>
                                    <Typography className="fx-investment-summary-card-percentage">
                                        0.00%
                                    </Typography>   
                                </Grid>  */}
                            </Grid> 
                            <Divider orientation="vertical" />
                            <Grid item container xs direction={"column"} className="fx-investment-summary-card-amount-section-two">
                                <Grid item>
                                    <Typography id="return-on-investment" className="fx-investment-summary-card-amount-section-two-text">RETURN ON INVESTMENT*</Typography>                                          
                                </Grid>
                                <Grid item>
                                    <Typography id="" className="fx-digest-grid">
                                        <FxCurrencyView id="return-on-investment-amount" value={props?.portfolioData?.investmentProvider[0]?.returnValue ? props?.portfolioData?.investmentProvider[0]?.returnValue : 0} prefix={"$"} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography id="return-on-investment-note" className="fx-investment-summary-card-amount-section-two-text" >* Actual return amount may slightly vary.</Typography>                                          
                                </Grid>
                            </Grid>  
                        </Grid> 
                    </Grid>
                    {props?.showGraph && 
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} className="fx-account-insight-graph">
                                    <InvestmentReportChartCard id="investment-report-chart-card" account={selectedAccount} portfolioStatus={portfolioStatusData?.status}></InvestmentReportChartCard>
                                </Grid>
                            </Grid>}
                </FxCardBody>
            </FxCard>
        </Grid>
        { openInvestNow &&       
            <FxInvestNowModal 
                id="modal-invest-now" 
                open={openInvestNow} 
                onClose={handleInvestNowClose}
            />
        }

          {props?.investSuccessMessage &&
            <FxSuccess id="sucess-investment" className={'fx-success-modal'} header={"Invest request created Successfully"} description={'Your investment is in queue and will be processed within 3-5 business days. You can track the progress under Investment Instructions.'} subHeader={<FxCurrencyView className="balance-amount" value={props?.amount || 0} prefix={"$"} />} subTitle={'Total Amount'} footer={"GO TO DASHBOARD"} redirect={"/portfolio"} setClose={modalClose}/>}
           {props?.investEditSuccessMessage &&
            <FxSuccess id="sucess-investment" className={'fx-success-modal'}  header={"Invest edit Successfully"} subHeader={<FxCurrencyView className="balance-amount" value={props?.amount || 0} prefix={"$"} />} subTitle={'Total Amount'} description={""} footer={"GO TO DASHBOARD"} redirect={"/portfolio"} setClose={modalClose}/>}
        
        { openRedeemAmount &&       
            <FxRedeemAmountModal 
                id="modal-redeem-amount" 
                open={openRedeemAmount} 
                onClose={handleRedeemAmountClose}
                portfolioData={props?.portfolioData}
            />
        }
        {props?.redeemSuccess &&
            <FxSuccess id="sucess-redeem" className={'fx-success-modal'}  header={"Redeem request created Successfully"} 
            subHeader={<Grid item className='fx-digest-grid'><FxCurrencyView className="balance-amount" value={props?.redeemSuccessMessage?.amount} prefix={"$"} /></Grid>} subTitle={'Total Amount'} description={"Your redeem is in queue and will be processed within 3-5 business days. You can track the progress under Investment Instructions."} footer={"GO TO DASHBOARD"} redirect={"/portfolio"} setClose={closeRedeemFxSuccess}/>}
        </>
    );
});
