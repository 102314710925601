
/**
 * Component:LeadMailingAddressListView
 * This component handles the  LeadMailingAddressListView card compoent
 */
import React from "react"; // we need this to make JSX compile
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { Divider, Grid, Typography } from "@mui/material";
import FxCard from "../../../Container/FxCard";
import FxCardBody from "../../../Container/FxCardBody";
import FxCardHeader from "../../../Container/FxCardHeader";
import { useIsMobileOrTabletScreen } from "../../../../libs/utils/utils";
import { DataGrid } from "../../../Data/DataGrid";
import { FxGridList } from "../../../Data/FxGridList";

type leadMailingAddressListType = {
  id: string;
  data?:any
}
export const LeadMailingAddressListView: React.FC<leadMailingAddressListType> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const isSmallScreen = useIsMobileOrTabletScreen();


  let column: any = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "ID",
        enable: true,
        fieldName: "id",
        type: "fri://fieldtype/default",
        default: true,
      },
      {
        label: "Address",
        enable: true,
        fieldName: "address",
        type: "fri://fieldtype/default",
        default: true,
      }
    ],
  };



  /**
   * Function handles transformation of data for mobile
   */
  function leadMailingListGridTransformerMobile(data: any) { 
    const newData: any = [];

    try{      
      const transformedData = newData?.map((item: any, i: number) => {
        const row = {
          id:item.id,
          description:(
            <Grid item container xs={12} className='fx-grid-list-content'>
              <Grid item container xs={12} alignItems='center'>
                <Grid item container xs={12}>
                  <Grid item>
                    <Grid>
                      <Typography variant='h4'>ID:{item?.id} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item>
                    <Typography variant='h4'>Address:{item?.address}</Typography>
                  </Grid>             
                </Grid>
              </Grid>
          </Grid>
          ),
          divider:(
            <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
              <Grid item xs>
                <Divider />
              </Grid>
          </Grid>
          )
        };       

        return row;
      });    

      return transformedData || [];
    }catch (e) {
      return false;
    }        
   }


  const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "enable": true,
        "fieldName": "description",
        "type": "fri://fieldtype/custom",
        "xs": "12",
        "alignItems": 'center'
      },
      {
        "enable": true,
        "fieldName": "divider",
        "type": "fri://fieldtype/custom",
        "xs": "12",
      }

    ]
  }

  return (
    <>
      <Grid id="lead-mailing-address-info-grid " item xs={12}>
        <FxCard id="lead-mailing-address-info-card" className="fx-theme-passport">
          <FxCardHeader id={"lead-mailing-address-info-card#card-header"}>
            <Grid
              id="lead-mailing-address-Grid2"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid id="lead-mailing-address-Grid3">
                <Typography id="title-lead-mailing-address-info" variant="h3">
                  Mailing Address
                </Typography>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="lead-mailing-address-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="lead-mailing-address-card-list-DataGrids" item xs={12}>
              {!isSmallScreen ? (
                <DataGrid
                  id="lead-mailing-address-card-list"
                  data={props?.data}
                  tableClass="fx-table-passport"
                  column={column}
                />
              ) : (
                <FxGridList
                  id="lead-mailing-address-card-list"
                  dataTransformer={leadMailingListGridTransformerMobile}
                  column={gridColumn}
                />
              )}
            </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>
    </>
  );
});
