import React, { useEffect, useMemo } from 'react'
import { useState } from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material'
import { useCallback } from 'react';
import { FxButton } from '../../../Action/FxButton'
import { useHistory } from 'react-router';
import { getKey } from '../../../../libs/utils/storageManager';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { IAccountActivationPayload } from '../../../DrawerPages/FxAccountActivation/FxAccountActivation';
import { FxAccountActivation } from '../../../DrawerPages/FxAccountActivation/FxAccountActivation';
import { FxStepper, IStep } from '../../../Input/FxStepper/FxStepper';
import { ReactComponent as Success } from '../../../../assets/svg/success.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/pending.svg';
import { ReactComponent as Failed } from '../../../../assets/svg/failed.svg';
import { ReactComponent as Arrow } from '../../../../assets/svg/continue-arrow.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/svg/stepper-arrow.svg'
import { ReactComponent as Connector } from '../../../../assets/svg/dotted-line.svg';
import FxRightDrawer from '../../../Utils/fx-right-drawer';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useDispatch, useSelector } from 'react-redux';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';


export const HomeOverlay: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const history = useHistory();
    const dispatch = useDispatch();
    const uiFeat = UIFeatureImpl.getInstance();
    const customerType: any = getKey('userType');
    const jointTenancyCustomer = uiFeat.getJointTenancyFeature().available

    const SuccessIconWithClass: React.FC = () => {
      return <Success className="fx-stepper-success-icon" />;
    }


    const activationSteps: IStep[] = [
        {
            'icon': SuccessIconWithClass,
            'text': 'KYC Details Submitted'
        },
        {
            'icon': ArrowIcon
        },
        {
            'icon': getKey("verificationStatus") === 'false' ? Warning : getKey("verificationStatus") === 'true' ? SuccessIconWithClass : Failed,
            'text': getKey("verificationStatus") === 'false' ? 'Verification Pending' : getKey("verificationStatus") === 'true' ? 'Verification Completed' : 'Verification Failed',
        },
        {
            'icon': ArrowIcon
        },
        {
            'icon': getKey("accountStatus") === 'false' ? Warning : SuccessIconWithClass,
            'text': getKey("accountStatus") === 'false' ? 'Account Activation Pending' : 'Account Activated'
        }
    ]

  const progress = (getKey("verificationStatus") === 'true' && getKey("accountStatus") === 'true') ? 100 : (getKey("verificationStatus") === 'true' && getKey("accountStatus") === 'false') ? 56 : (getKey("verificationStatus") === 'false' && getKey("accountStatus") === 'false') ? 33 : 0;

    /**
  * to get source details
  */
    const accountList = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']?.params?.accountList
        }
    });
    const rateCardAssociation = {
        url: "rateCardAssociation",
        method: "GET"
    }

        const activateAccountFeatureAvailable = uiFeat.getActivateAccount().available;

        const [notActivated, setNotActivated] = useState<boolean>((activateAccountFeatureAvailable && getKey('accountStatus')!=='true')? true : getKey("kycPending") !== 'true' && getKey("customerKYC") === 'continue' );

        const openAccountActivationDrawer = (async () => {
           await FxRightDrawer.show<IAccountActivationPayload>(({ onClose }) => {
                 return <FxAccountActivation FeeData={rateCardAssociation} onDrawerClose={onClose} />
            })
            setNotActivated(!props?.accountStatus);
            if(!notActivated && activateAccountFeatureAvailable && getKey('goPriorityCapitalUrl') && getKey('mxmFastFunding') && getKey('fundingViaPassport')){
                updateComponentParams(dispatch, 'customer-home-passport', { 'openPipeRedirectionModal': true })
            }
        })

        const onButtonClick = useCallback(() => {
            accountList?.totalCount === 0 ? history.push(customerType.toLowerCase() + '/kyc') : openAccountActivationDrawer();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activateAccountFeatureAvailable, accountList]);

        /**
         * Account verification status 
         */
        const accounyVerificationStatus = useMemo(()=>{
            return <>
                <Typography variant={"h1"}>Account Verification in progress</Typography>
                { customerType === 'JOINT_TENANCY' ? 
                    <Typography variant={"h3"}>Co-Owners will receive an email to sign up and agree to terms & conditions. Please note, the account will be activate once all co-owners accept the terms & KYC is successful.</Typography>
                    :
                    <Typography variant={"h3"}>We will send you an email once account is active</Typography>
                }
            </>
        },[customerType])


              /**
   * This block of code handles the scenario when passport account is already created
   * for merchant but faster funding not enabled, so open the Faster Funding Drawer Automatically, when the merchant
   * logs into the passport portal
   */
  useEffect(() => {
    if(getKey('goPriorityCapitalUrl') && getKey("accountStatus") !== 'true' && accountList?.totalCount > 0 ){
        openAccountActivationDrawer();
    }
  },[accountList])

  

  
    return (
        <>
            {notActivated ?
                <Grid container justifyContent="space-between" xs={12} className="fx-overlay-home fx-overlay-home-continue">
                <Grid item xs={12}>
                    <FxCard >
                        <FxCardBody>
                            <Grid container xs={12} spacing={2} alignItems="center">
                                {accountList?.totalCount === 0 ?
                                    <Grid item container>
                                        {jointTenancyCustomer ?
                                            <Grid item container>
                                                <Grid item xs={12} className={'fx-overlay-home-info'}>
                                                    <Typography variant={"h1"}>Activate your Joint Account</Typography>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Typography variant={"h3"}>Complete Owners’ KYC and provide Owners’ information to collect, store and send money on your Joint Account.</Typography>
                                                </Grid>
                                            </Grid>:
                                            <Grid item container>
                                                <Grid item xs={12} className={'fx-overlay-home-info'}>
                                                    <Typography variant={"h1"}>Activate your Passport Account</Typography>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Typography variant={"h3"}>Complete your KYC and fill your information to collect, store and send money on your account.</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>:
                                activateAccountFeatureAvailable ?<Grid item container>
                                <Grid item xs={12} >
                                    <Typography variant={"h1"}>Yes, I want to activate my Passport Account!</Typography>
                                </Grid>
                                </Grid>:<></>
                                }
                                <Grid item >
                                    <FxButton
                                        variant="contained"
                                        className={`fx-button ${!activateAccountFeatureAvailable ? 'fx-button-passport fx-button-large' : 'fx-button-saturated-orange'}`}
                                        title={!activateAccountFeatureAvailable ? 'Continue':'Activate now'}
                                        id="continue-btn"
                                        onClick={onButtonClick}
                                    >
                                        {accountList?.totalCount === 0 ? <>CONTINUE &nbsp;<Arrow /></> : 'Activate now!'}
                                    </FxButton>
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </FxCard>
                </Grid>
                </Grid> : (activateAccountFeatureAvailable && !notActivated ) ? <></> :
                <Grid container justifyContent="space-between" xs={12} className="fx-overlay-home fx-overlay-home-stepper">
                    <Grid item xs={12}>
                        <FxCard >
                            <FxCardBody>
                                <Grid container xs={12} spacing={2} alignItems="center">
                                    {getKey("kycPending") !== 'true' && getKey("customerKYC") === 'stepper' && <Grid item container>
                                        <Grid item xs={12} className={'fx-overlay-home-info'}>
                                            <Grid container alignItems="flex-start" >
                                                <Grid>
                                                    {(getKey("verificationStatus") === 'true' || getKey("verificationStatus") === 'false') ?
                                                        accounyVerificationStatus : <>
                                                            <Typography variant={"h1"}>We need more details to verify you</Typography>
                                                            <Typography variant={"h3"}>Our representative will reach out to you</Typography>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className='fx-stepper fx-kyc-stepper'>
                                            <LinearProgress variant="determinate" value={progress} />
                                            <FxStepper steps={activationSteps} alternativeLabel={false} connector={<Connector/>} />
                                        </Grid>
                                    </Grid>}
                                    {(getKey("kycPending") === 'true' && !activateAccountFeatureAvailable) && <Grid item container>
                                        <Grid item xs={12} className={'fx-overlay-home-info'}>
                                            <Grid container alignItems="flex-start" >
                                                <Grid item xs={12}>
                                                    {accounyVerificationStatus}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className='fx-stepper fx-kyc-stepper'>
                                            <LinearProgress variant="determinate" value={progress} />
                                            <FxStepper steps={activationSteps} alternativeLabel={false} connector={<Connector/>} />
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </FxCardBody>
                        </FxCard>
                    </Grid>
                </Grid>}
        </>

    )
}
)

