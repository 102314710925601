/* eslint-disable  @typescript-eslint/no-explicit-any */  
/**
 * Mapper returns the method and type of the transaction from a string
 */
export const TransactionMethodAndTypeMapper: any =  {
    "send-ach": {
        method: "Ach",
        type: "My Account"
    },
    "send-check":{
        method: "Check",
        type: "My Account"
    },
    "send-internationalWire":{
        method: "International Wire",
        type: "My Account"
    },
    "send-wire":{
        method: "Wire",
        type: "My Account"
    },
    "send-transfer":{
        method: "Book",
        type: "My Account"
    },
    "send-contact-ach":{
        method: "Ach",
        type: "Contact"
    },
    "send-contact-ach-recurring":{
        method: "Ach",
        type: "Contact"
    },
    "send-contact-book":{
        method: "Book",
        type: "Contact"
    },
    "send-contact-check":{
        method: "Check",
        type: "Contact"
    },
    "send-contact-check-recurring":{
        method: "Check",
        type: "Contact"
    },
    "send-contact-internationalWire":{
        method: "International Wire",
        type: "Contact"
    },
    "send-contact-virtual-card":{
        method: "Virtual Card",
        type: "Contact"
    },
    "send-contact-wire":{
        method: "Wire",
        type: "Contact"
    },
    "send-onetime-ach":{
        method: "Ach",
        type: "One-time"
    },
    "send-onetime-wire":{
        method: "Wire",
        type: "One-time"
    },
    "send-book":{
        method: "Book",
        type: "One-time"
    },
    "send-onetime-internationalWire":{
        method: "International Wire",
        type: "One-time"
    },
    "send-onetime-virtual-card":{
        method: "Virtual Card",
        type: "One-time"
    },
    "collect-self-ach":{
        method: "Ach",
        type: "My Account"
    },
    "from-self-card":{
        method: "Card",
        type: "My Account"
    },
    "mobile-check-deposit":{
        method: "Check",
        type: "One-time"
    },
    "onetime-ach":{
        method: "Ach",
        type: "One-time"
    },
    "onetime-card":{
        method: "Card",
        type: "One-time"
    },
    "collect-self-ach-recurring":{
        method: "Ach",
        type: "My Account"
    },
    "send-ach-recurring":{
        method: "Ach",
        type: "My Account"
    },
    "send-wire-recurring":{
        method: "Wire",
        type: "My Account"
    },
    "send-check-recurring":{
        method: "Check",
        type: "My Account"
    },
    "send-transfer-recurring":{
        method: "Book",
        type: "My Account"
    },
    "send-contact-wire-recurring":{
        method: "Wire",
        type: "Contact"
    },
    "send-contact-book-recurring":{
        method: "Book",
        type: "Contact"
    },
    "send-contact-virtual-card-recurring":{
        method: "Virtual Card",
        type: "Contact"
    }
}
