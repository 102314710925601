import * as React from "react";
import { TextField } from "@mui/material";
import { FormFieldProps } from "../Props";
import { InitializeComponent } from "../../Utils/ComponentInitializer";
import { Logger } from "../../../libs/utils/logger";
Logger.debug("FxHidden.tsx", "Fx Hidden initializing")

export const FxHidden: React.FC<FormFieldProps> =
    (props: any) => {
        ({ props } = InitializeComponent(props));
        let value: any;
        if (props.value) {
            value = prepareAPIEndpoint(props.value);
            value = typeof value == "function" ? value(props) : props.value;
        }
        return (
            <div className={'fx-hidden'}>
                <TextField
                    id={props.id}
                    name={props.name}
                    value={value ? value : ""}
                    onChange={props.onChange}
                    inputRef={props.inputRef}
                    type={'hidden'}
                />
            </div>
        )
    }

function prepareAPIEndpoint(apiData: string): any {
    try {
        if (apiData.trim().match(/^function\(/)) {
            const functionName = "value";
            let value = function (data: any) { };
            // eslint-disable-next-line no-eval
            eval(functionName + " = " + apiData);
            return value;
        }
    } catch (e) {
    }

    return apiData;
}