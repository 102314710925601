import React from 'react';
import { Avatar } from "@mui/material";
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxAvatar.tsx", "Fx Avatar initializing")
/**
 * Component used to view Avatar of users
 */
interface FxAvatarProps {
    id: string;
    className?: string;
    value?: string;
}

const FxAvatar: React.FC<FxAvatarProps> = ({ id, value, className }) => {
    const getInitials = function (name?: string) {
        let parts: any = []
        try {
            parts = name ? name.split(' ') : []
        }
        catch (e) {
        }
        let initials = ''
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== '' && parts[i] !== '-') {
                initials += parts[i][0]
            }
        }
        try {
            initials = initials.substring(0, 2);
        } catch (e) { }
        return initials
    }

    return (
        <Avatar id={id ? id : 'avatar-' + value} className={className ? className : ''}>{getInitials(value)}</Avatar>
    )
}
export default FxAvatar;