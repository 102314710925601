import { PropsWithChildren } from 'react';
import React from "react";
import { InitializeComponent } from "../Utils/ComponentInitializer";
import { Logger } from "../../libs/utils/logger";
const cssFxCardFooter = 'fx-card-footer';

Logger.debug("FxCardFooter.tsx", "Fx Card Footer initializing")

/**
 * <FxCardFooter> component 
 * using in footer area
 * @param props 
 */

const FxCardFooter: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = props => {
  ({ props } = InitializeComponent(props));

  return (
    <div id={props.id} className={`${cssFxCardFooter} ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  )
};

export default FxCardFooter;

