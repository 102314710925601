import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducer/rootReducer";
import rootSaga from "../saga/rootSaga";
import { loadStateFromSessionStorage, updateSessionStorageMiddleware } from "../../components/Utils/CommonBaseClass";

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadStateFromSessionStorage();

/**
 * Create the store
 */
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(sagaMiddleware,updateSessionStorageMiddleware)
);
sagaMiddleware.run(rootSaga);
export default store;

