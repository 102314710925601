/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from "react";
import { Logger } from "../../../../libs/utils/logger";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FxMultiSelectWeek } from "../../../Input/FxMultiSelectWeek/FxMultiSelectWeek";


Logger.debug("FxWeekRecurring.tsx", "Fx Week Recurring initializing")
/**
 *  This component handles the fx week recurring
 */

export const FxWeekRecurring: React.FC<any> = React.memo(
    (props: any) => {
        const { register, control } = useForm();
        // Method passes the form data for week component
        const handleUpdateFormData = (value: any) => {
            if(props.updateFormData){
                props.updateFormData(value)
            }
        }

        return (
            <Grid item container xs={12}  className="fx-repeat-payment-week">
                <Grid xs={12}>&nbsp;</Grid>
                <FxMultiSelectWeek register={{ ...register("weekRange") }} control={control}  id="week-range" name="weekRange" label="" week={true} data={props?.data} updateFormData={handleUpdateFormData}/>
            </Grid>
        )
    })
