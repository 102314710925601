import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid } from "../../Data/DataGrid";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import {
  IFilterColumns,
  IFilterFields,
  IDataGridProps,
} from "../../../types/common.interfaces";
import { toTitleCase, truncateChars, updateComponentParams } from "../../Utils/CommonBaseClass";
import { Filter } from "../../Data/Filter";
import { Logger } from "../../../libs/utils/logger";
import { useHistory } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../assets/svg/add-new-icon.svg";
import { FxButton } from "../../Action/FxButton";
import { useDispatch, useSelector } from "react-redux";
import FxCardFooter from "../../Container/FxCardFooter";
import { ReactComponent as ThreeDotMenu } from '../../../assets/svg/threedot-menu-icon.svg';
import { FxMenu } from '../../Data/FxMenu';
import { FxPaymentModeStatus } from "../../Input/FxPaymentModeStatus/FxPaymentModeStatus";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

/**
 * Component: Funding Rules List
 * Usage: To display list of Funding Rules
 */
export const FundingRulesList: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menuData, setMenuData] = useState<any>();
  const [menuOptions, setMenuOptions] = useState<any>();
  const uiFeat = UIFeatureImpl.getInstance();
  const checkIsPartner=uiFeat.getPartnerFeature().available;
  //To enable card
  const enableCard = useSelector((state: any) => state.data['Funding-rule-enable-card']?.params?.enableCard || false);

  //Options for Action Menu
  const options: any = checkIsPartner ? [
    {id: 'view-details', title: 'View Detail'}
   ]: [
    {id: 'view-details', title: 'View Detail'},
    {id: 'linked-transaction', title: 'Linked Transactions'}
   ];

  // const addPermission = {
  //   entity:"Funding Rule",
  //   name:"Funding Rule",
  //   operation:"CREATE"
  // }

  // const viewPermission = {
  //   entity:"Funding Rule",
  //   name:"Funding Rule",
  //   operation:"VIEW"
  // }

  /**
   * useeffect to load title while loading page
   */
  useEffect(() => {
    dispatch({
      type: "DATA_UPDATE_COMPONENT_REDUCER",
      payload: { id: "page-title-details", data: { title: "Funding Rules" } },
    });
  }, [dispatch]);

  /**
   * useEffect to reset the filter while unmount the page
   */
  useEffect(
    () => () => {
      updateComponentParams(dispatch, "funding-rules-list-filter", {
        resetFilter: true,
      });
    },
    [dispatch]
  );


  /**
   *  Funding Rules list columns
   * */
  const column: IFilterColumns = {
    title: "Select Columns to Show",
    columns: [
      {
        label: "ID",
        enable: true,
        fieldName: "fundingRuleId",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "7rem" },
      },
      {
        label: "Name",
        enable: true,
        fieldName: "name",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "OWNERSHIP",
        enable: true,
        fieldName: "ownership",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "METHOD",
        enable: true,
        fieldName: "method",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "COMPONENT",
        enable: true,
        fieldName: "transactionComponent",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "OVERRIDABLE",
        enable: true,
        fieldName: "overridable",
        type: "fri://fieldtype/default",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "Created On",
        enable: true,
        fieldName: "createdOn",
        type: "fri://fieldtype/date",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "Created By",
        enable: true,
        fieldName: "createdBy",
        type: "fri://fieldtype/custom",
        default: true,
        style: { width: "10rem" },
      },
      {
        label: "",
        enable: true,
        fieldName: "actions",
        type: "fri://fieldtype/custom",
        style: { "width": "5rem", "text-align": "center" },
        default: true
      }
    ],
  };

  /**
   * Redirection to the Funding Rules Creation Screen
   */
  function redirectToCreatePage() {
    history.push("/fundingrule/create");
  }

   /**
     * Method handles the closing of the menus
     */
    const handleClose = () => {
      setAnchorEl(null);
  };

    /**
    * Method handles the onclick event in the 3-dot icon
    * @param event : event parameter
    */
      const handleMenuButtonClick = (event: any, data: any) => {
       setAnchorEl(event.currentTarget);
       setMenuData(data)
       setMenuOptions(options)
   };

  /**
   * Method to redirect to the funding rules details page
   * @param event :event object
   * @param data : funding rule data
   */
   const handleOptionClick = async (event: any) => {
    handleClose();
    // if(checkPermission(viewPermission)){
      if(event === 'View Detail'){
      history.push(`/fundingrule/view/${menuData.id}`, {backUrl : (history?.location?.pathname) });
      }else if(event === 'Linked Transactions'){
        history.push(`/fundingrule/viewlinkedtransaction/${menuData.name}/${menuData.id}` , {backUrl : (history?.location?.pathname), methods:menuData?.method , parentRuleId: menuData?.parentRuleId ? (menuData?.parentRuleId).toString() : undefined});
      }
    // }
  }

  /**
   * DataGrid Props
   */
  const datagridProps: IDataGridProps = {
    id: "funding-rules-list-card",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  /**
   * Filter Fields
   */
  const filterFields: IFilterFields = {
    fields: [
      {
        label: "Method",
        fieldName: "method",
        type: "fri://fieldtype/checkbox",
        data: checkIsPartner || enableCard ? [
          { label: "Card", value: "CARD", checked: false },
          { label: "ACH", value: "ACH", checked: false },
          { label: "MONEYGRAM", value: "MONEYGRAM", checked: false },
          { label: "Wire", value: "WIRE", checked: false },
          { label: "Check", value: "CHECK", checked: false }
       ]:[
          { label: "ACH", value: "ACH", checked: false },
          { label: "MONEYGRAM", value: "MONEYGRAM", checked: false },
          { label: "Wire", value: "WIRE", checked: false },
          { label: "Check", value: "CHECK", checked: false }
         ],
      },
      {
        label: "Component",
        fieldName: "transactionComponent",
        type: "fri://fieldtype/checkbox",
        data: checkIsPartner || enableCard? [
          { label: "Total Amount", value: "TOTAL_AMOUNT", checked: false },
          { label: "Sub Total", value: "SUBTOTAL", checked: false },
          { label: "Tip", value: "TIP", checked: false },
          { label: "Surcharge", value: "SURCHARGE", checked: false }
        ]:[
          { label: "Total Amount", value: "TOTAL_AMOUNT", checked: false }
        ]
      },
      {
        label: "Created On",
        toLabel: "To",
        fieldName: "createdOn",
        type: "fri://fieldtype/daterange",
        data: [],
        defaultValue: false,
      }
    ],
  };


  /**
   * Method to transform data
   * @param data : Funding Rule List data
   */
  function transormFundingRuleList(data: any) {
    try {
      let newData: any = [];
        // eslint-disable-next-line array-callback-return
      data?.map((item: any, i: number) => {
          newData[i] = { ...data[i] }
          newData[i].fundingRuleId = item?.parentRuleId ? item?.parentRuleId : item?.id
          newData[i].method = <><FxPaymentModeStatus tooltip={true} id={'funding-rule-method'} value={(newData[i]?.method).toString()} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus></>
          newData[i].createdBy = <><span title={item?.createdBy?.username}>{` ${item?.createdBy?.username ? truncateChars(item?.createdBy?.username, 20) : ''}`}</span></>
          newData[i].transactionComponent = item?.transactionComponent?.toLowerCase() === 'total_amount' ? 'Total Amount': item?.transactionComponent?.toLowerCase() === 'subtotal' ? 'Sub Total' : toTitleCase(item?.transactionComponent)
          newData[i].ownership = item?.ownership ? toTitleCase(item?.ownership): ''
          newData[i].overridable = item?.isOverridable===true?'Yes':'No'
          newData[i].actions = <Grid item container className="fx-funding-rule-listing-actions fx-view-action"><Grid item><Button id={item['id']+"-list-actions"} title="" className="fx-button fx-button-dotted fx-button-dotted-icons fx-funding-button-dotted" aria-label="more" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={(e: any) => handleMenuButtonClick(e, item)}><ThreeDotMenu /></Button></Grid><Grid item><FxMenu id={item['id']+"-list-actions-menu"} menuShadow={true} 
          className="fx-funding-rule-listing-actions funding-splitrule-criteria-section" open={open} anchorEl={anchorEl} options={menuOptions} data={newData[i]} onClose={handleClose} onMenuClick={handleOptionClick}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}></FxMenu></Grid></Grid>
      });
      return newData;
  } catch (e) {
      Logger.error("FundingRulesList.tsx", "error", e);
      return false;
    }
  }

  
  return (
    <Grid id="funding-rules-list-grid" item xs={12}>
      <Grid
        item
        xs={12}
        className="fx-flex-grow fx-ea-type-button-mobile"
      ></Grid>
      <Grid container xs={12} className="fx-layout-list">
        <FxCard id="funding-rules-list-card" className="fx-theme-passport">
          <FxCardHeader
            id="funding-rules-list-card-header"
            className="fx-card-header"
          >
            <Grid
              id="funding-rules-list-card-header-first-grid"
              item
              container
              justifyContent="space-between"
              xs={12}
            >
              <Grid>
              
                <Grid item>
                  <Filter
                    id="funding-rules-list-filter"
                    field={filterFields}
                    dataGridProps={datagridProps}
                    hiddenParameter={['status']}
                  ></Filter>
                </Grid>
                <div id="users-card-header-div" className="fx-flex-grow" />
              </Grid>
              <Grid
                id="funding-rules-list-filter-grid"
                item
                container
                spacing={2}
                className="fx-action-block fx-inline-flex"
              >
                <Grid item>
                 {props?.listingType === 'active' && <FxButton
                    id="funding-rules-list-add-button"
                    variant="contained"
                    className="fx-button fx-button-passport-border fx-svg-theme"
                    onClick={redirectToCreatePage}
                    permissions={{
                      entity: checkIsPartner? "Partner Portal" : "Funding Rule",
                      name:"Funding Rule",
                      operation:"CREATE"
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add New
                  </FxButton>}
                </Grid>
              </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody
            id="funding-rules-list-card-body"
            className="fx-card-body-no-border-no-shadow"
          >
            <Grid id="funding-rules-list-card-body-grid" item xs={12}>
              <DataGrid
                id="funding-rules-list-card"
                tableClass="fx-table-passport"
                dataTransformer={transormFundingRuleList}
                source={props.src}
                column={column}
                pagination={true}
                pageNumber={1}
                pageSize={25}
                resultPerPage={25}
              />
            </Grid>
          </FxCardBody>
          <FxCardFooter id="funding-rules-list-list-card-footer"></FxCardFooter>
        </FxCard>
      </Grid>
    </Grid>
  );
});
