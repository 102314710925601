/* eslint-disable  @typescript-eslint/no-explicit-any */
const initialState: any = {};
/**
 **Entity info reducer from dynamic page creation
 * Not required for static defined projects
 */

export const entityInfoReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case 'SET_ENTITY_INFO':
      state[action.entity] = action.value;
      return state;
  }
  return state;
};
