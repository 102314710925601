import { Divider, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getKey } from '../../../libs/utils/storageManager';
import { processAPIResponse, useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { ISelectData } from '../../Input/FxSelect/FxMaterialSelect';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { addressFormatter, calculateAge } from '../../Utils/CommonBaseClass';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FxDebitCardCongratulationsCard } from '../../Data/FxDebitCardCongratulationsCard';
import { FxCongratulationCard } from '../../Data/FxCongratulationCard';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';

const httpClient = HttpClient.getClient();

Logger.debug("FxConsumerDebitCardApply.tsx", "apply debit card initializing")

/**
 * Component used inside the right drawer to apply for a debit card
 */
export interface IApplyDebitCardPayload {
    isDebitCardApplied: boolean;
}

interface IFxApplyDebitCard {
    onDrawerClose: (event: any, payload?: IApplyDebitCardPayload) => void;
}

type FormValues = {
    authorisedUser: number;
    shippingAddress: number;
    cardHolderName?:number;
    expressDelivery: 'YES' | 'NO';
}

export const FxConsumerDebitCardApply: React.FC<IFxApplyDebitCard> = ({ onDrawerClose }) => {

    const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
    const [isCardLinked, setIsCardLinked] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [agreementInfo, setAgreementInfo] = useState<IFileInfo[]>();
    const [addresses, setAddresses] = useState<ISelectData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState<number | string>('');
    const [defaultHolder, setDefaultHolder] = useState<any>();
    const [cardHolderOptions, setCardHolderOptions] = useState<any[]>();
    const [cardHolders, setCardHolders] = useState<any[]>([]);
    const [cardProgram, setCardProgram] = useState<any>();
    const [isExpressDelivery, setExpressDelivery] = useState<boolean>(false);
    const isSmallScreen = useIsMobileOrTabletScreen()
    const history = useHistory();
    const [authorisedSignatory, setAuthorisedsignatory] = useState<any>()
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available
    const individualCustomer = uiFeat.getIndividualCustomerFeature().available
    const jointTenancy = uiFeat.getJointTenancyFeature().available
    const [cardId, setCardId] = useState<any>();

    const express_delivery = [{
            label: 'Express Delivery',
            value: 'expressDelivery',
            checked: isExpressDelivery || false,
    }];

    /**
    * to get customer details
    */
    const customerDetails = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']?.params?.customerDetails
        }
    });

  /**
   * function to get card holders options and default holder
   */
  const getCardHolders = async () => {
      const holders = customerDetails?.owners;

    // Filter holders to exclude those under 18 years old
    const filteredHolders = holders?.filter((holder:any) => {
        return calculateAge(holder.dob) >= 18;
    });

    //map the holder options
    const holdersOptions = filteredHolders?.map((option:any) => {
      return {
        value: option?.id,
        label: `${option?.firstName} ${option?.lastName}`
      }
    })

    //Find the default holder, which is either the holder with the id of the logged in owner or the first holder in the list
    const defHolder = filteredHolders?.find((holder:any) => holder.id === getKey('LoggedInOwnerId')) || holders?.[0];

    //Set the default holder and update the state with the holders and holder options
    setCardHolders(holders);
    setDefaultHolder(defHolder);
    setCardHolderOptions(holdersOptions || []);
  };

  /**
     * useEffect to set default address 
     */
    useEffect(() => {
        getCardProgramType();
        const authSignatory = customerDetails?.business?.beneficialOwner.find((beneficialOwner:any)=> beneficialOwner.actAsAuthorizedSignatory)
        setAuthorisedsignatory(authSignatory);
        let addressArray;
        if(businessCustomer){
            addressArray = authSignatory?.mailingAddress;
        }
      
        else if(jointTenancy){
            getCardHolders();
            addressArray = defaultHolder?.mailingAddress || []
        }
        else if(individualCustomer){
            addressArray = customerDetails?.individual?.mailingAddress
        }

        const addressOptions = addressArray?.map((address: any) => {
            return {
                value: address.id,
                label: addressFormatter("", address)
            }
        })
        const primaryAddress = addressArray?.find((address: any) => address.isPrimary);
        primaryAddress?.id && setDefaultAddress(primaryAddress.id);
        setAddresses(addressOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDetails?.business?.beneficialOwner,customerDetails?.individual, customerDetails?.owners, defaultHolder])

    const card_type_request:any={
        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [ ]
        }
    }

    /**
    * async function to get debit card program list
    */
    async function getCardProgramType(){
        const card_type= await httpClient.post(getCustomerUrl("account/id/" + getKey("selectedAccount")?.id +"/debitCard/program/list", false), card_type_request);
        cardTypeTransformation(card_type?.data)
    }

    /**
    * Function to transform data for card type select
    */
    function cardTypeTransformation(data: any) {
        try {
            const cardPrograms = data?.resources?.filter((option: any) => {
                return option.isDefault
            })
            setCardProgram(cardPrograms)
        }
        catch (e) { }
    }

    // debit terms & condition options
    const individualDebitCardTerms = [
        {
            label: (<span>By submitting this form, you agree to the Consumer Card Holder Agreement terms and conditions that governs your use of the Consumer Debit Card provided by Priority Technology Holdings, Inc. You also agree to receiving electronic communications regarding your account, and you certify that the information you provided is complete and accurate.</span>),
            value: 'yes'
        }
    ];

    // debit terms & condition options
    const businessDebitCardTerms = [
        {
            label: (<span>By submitting this form, you agree to the Commercial Card Holder Agreement terms and conditions that governs your use of the commercial B2B Debit Card provided by Priority Technology Holdings, Inc. You also agree to receiving electronic communications regarding your account, and you certify that the information you provided is complete and accurate.</span>),
            value: 'yes'
        }
    ];

    const primaryMessage = individualCustomer ? 'You have Successfully Applied for Passport a/c Debit card .' : businessCustomer ? 'You have Successfully Applied for Passport a/c Debit card for authorised user '+ authorisedSignatory?.fullName + '('+ authorisedSignatory?.id +').' : '';
    const secondaryMessage = isExpressDelivery ? 'It may take 2-3 days to ship your debit card with express delivery' : 'It may take 4-6 days to ship your debit card with standard delivery.';

    const goToDashboard = (event: any) => {
        onDrawerClose(event, { isDebitCardApplied: true });
        history.push('/debit-card/details/'+ cardId);
    }

    /**
     * useEffect to set new default shipping address for select
     */
    useEffect(() => {
        setValue('shippingAddress', defaultAddress);
    },[defaultAddress, setValue]);

    /**
     * Method to handle form change event of agreement checkbox
     */
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        setBtnDisabled(!event.target.checked)
    }

    /**
     * Method used to call request debit card API
     * @param body :form data
     * @returns
     */
    async function requestDebitCard (body: any) {
        try {
            return  await httpClient.post(getCustomerUrl('account/id/' + getKey("selectedAccount")?.id + '/debitCard',false), body);
        } catch (err) {
            Logger.error("RequestDebitCard.tsx", "error", err);
            return err;
        }
    }

     /**
     * Method to get onchange value of holder select
     */
     const handleSelectHolderChange = (event: any) => {
        const selectedUser = cardHolders?.find(((user:any) => user.id === event.target.value));
        if (selectedUser) {
            const addressOptions = selectedUser.mailingAddress.map((address:any)=>{
                return {
                    value: address.id,
                    label: addressFormatter("", address)
                }
            })
            const primaryAddress = selectedUser?.mailingAddress?.find((address:any) => address?.isPrimary);
            primaryAddress?.id && setDefaultAddress(primaryAddress.id);
            setAddresses(addressOptions);
        }
    }

    /**
     * Method used to request to apply debit card
     */
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        let requestBody;

      const linkedDocuments = agreementInfo?.map((file: IFileInfo) => {
        return {
          purpose: 'DEBIT_CARD_AGREEMENT',
          document: {
            type: file?.fileType,
            name: 'Terms&Condition.pdf',
            base64encodedContent: file?.base64
          }
        }
      })

        if(jointTenancy) {
            requestBody = {
                type: 'DEBIT',
                shippingDetail: {
                    "address": {
                        "id": data.shippingAddress
                    },
                    "expressDelivery": isExpressDelivery
                },
                linkedDocument: linkedDocuments,
                cardholder: {                    
                    "id": data.cardHolderName
                },
                cardProgram: {
                    id: cardProgram[0]?.id
                }
            }
        }
        else if (individualCustomer) {
            requestBody = {
                type: 'DEBIT',
                shippingDetail: {
                    "address": {
                        "id": data.shippingAddress
                    },
                    "expressDelivery": isExpressDelivery
                },
                entity:{
                    "name": "CUSTOMER",
                    "id" : customerDetails?.id
                },
                linkedDocument: linkedDocuments,
                cardProgram: {
                    id: cardProgram[0]?.id
                }
            }
        }
        else if (businessCustomer) {
            requestBody = {
                type: 'DEBIT',
                shippingDetail: {
                    "address": {
                        "id": data.shippingAddress
                    },
                    "expressDelivery": isExpressDelivery
                },
                linkedDocument: linkedDocuments,
                cardholder: {
                    "type": "BENEFICIAL_OWNER",
                    "id": authorisedSignatory?.id
                },
                cardProgram: {
                    id: cardProgram[0]?.id
                }
            }
        }
        
        setIsLoading(true);
        let status: any = await requestDebitCard(requestBody);
        if (status?.headers?.url) {
            setCardId((status.headers.url).split('/').pop())
        }
        status = processAPIResponse(status)
        setIsLoading(false);
        if (status.status) {
            //api success
            if(history.location.pathname === '/debitcards'){
                history.push('/redirect/debitcards')
            }else{
                history.push('/redirect/dashboard')
            }
            setIsCardLinked(true);
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    return (
        <Grid container id="apply-debit-card" className="fx-apply-debit-card">
           <Grid item className={'fx-drawer-left-content fx-drawer-left-content-grey-background '}>
                { uiFeat.getDebitCardContent().info}
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                {!isCardLinked ?
                <Grid container className={ isSmallScreen ?  'fx-apply-debit-card-main-content-mobile' : 'fx-apply-debit-card-main-content-individual'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">SHIPMENT INFO</Typography>
                        </Grid>
                        <Grid item container xs spacing={2} className="fx-apply-debit-card-main-content-individual-shipping-info">
                          {businessCustomer && <Grid item xs={12}>
                              <FxTextEdit
                                register={{ ...register('authorisedUser') }} 
                                control={control}
                                className={errors.authorisedUser ? 'border-error-input' : 'fx-input-edit fx-input-edit-text-layout'}
                                id="apply-debitCard-authorisedUser"
                                name="authorisedUser" 
                                label={'Authorised Signatory'}
                                value={authorisedSignatory?.fullName || ''}
                              />
                          </Grid>}
                          {jointTenancy && cardHolderOptions && <Grid item xs={12}>
                              <FxMaterialSelect register={{ ...register('cardHolderName') }} control={control}
                                                rules={{ required: true }}
                                                className={errors.authorisedUser ? 'border-error-input' : 'fx-input-edit fx-input-edit-text-layout'}
                                                id="apply-debitCard-cardHolderName"
                                                data={cardHolderOptions}
                                                name="cardHolderName" label={'Card Holder Name'}
                                                value={defaultHolder?.id || ''}
                                                setValue={setValue}
                                                onChange={handleSelectHolderChange} />
                          </Grid>}
                          <Grid item xs={12}>
                            <FxMaterialSelect
                              register={{ ...register('shippingAddress') }} control={control}
                              rules={{ required: true }}
                              className={errors.shippingAddress ? 'border-error-input fx-input-edit' : 'fx-input-edit fx-input-edit-text-layout'}
                              id="apply-debitCard-shippingAddress"
                              name="shippingAddress" label={'Shipped to '}
                              setValue={setValue}
                              data={addresses || []}
                              value={defaultAddress}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FxCheckEdit
                              register={{ ...register('expressDelivery') }}
                              className="fx-privacy-text" control={control}
                              id="expressDelivery"
                              name="expressDelivery"
                              data={express_delivery}
                              row="vertical"
                              setValue={setValue}
                              onChange={() => setExpressDelivery(!isExpressDelivery)}
                            />
                            <Grid item xs={12}
                                  className="fx-apply-debit-card-main-content-individual-shipping-info-text">
                              <Typography>With this you incur an additional charge as per your fee schedule</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={12}>
                            <FxAgreementLinkCard
                              agreementTypes={['DebitCard']}
                              onFileInfoReady={setAgreementInfo}
                            />
                          </Grid>
                          {!!agreementInfo?.length && <Grid item xs={12} className="fx-submit-agree">
                              <FxCheckEdit register={{ ...register('debitCardTerms') }}
                                           control={control}
                                           id="debit-card-terms-condition-agree" name="debitCardTerms"
                                           className="fx-privacy-text"
                                           data={businessCustomer ? businessDebitCardTerms : individualCustomer ? individualDebitCardTerms : ''}
                                           row="vertical"
                                           onChange={(e: any) => {
                                             handleCheckboxChange(e);
                                           }}
                                           setValue={setValue}
                              />
                          </Grid>}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} textAlign={'right'} paddingBottom="4rem">
                          <FxButton
                            disableRipple={false}
                            className={`${btnDisabled ? 'fx-button-theme-disabled' : 'fx-button-theme'}`}
                            id="request-debit-card-accept"
                            type="submit"
                            disabled={btnDisabled}
                            isSubmitting={isLoading}>Apply For Debit Card</FxButton>
                        </Grid>
                      </Grid>
                    </form>
                </Grid>
                    : uiFeat.getDebitCardContent().congratulation ?
                    <FxCongratulationCard
                        message={'Debit card successfully applied for your Passport Account.'} 
                        onButtonClick={goToDashboard} 
                        iconName="debitCard"/>
                     :
                    <FxDebitCardCongratulationsCard 
                        onButtonClick={goToDashboard} 
                        primaryMessage={primaryMessage} 
                        secondaryMessage={secondaryMessage}
                    />
                }
            </Grid>
        </Grid>
    )
}