import React from 'react';
import { useParams } from 'react-router';
import { getKey } from '../../../libs/utils/storageManager';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';
import { DebitCardDetailsCard } from './DebitCardDetailsCard';
/**
 * Component: DebitCardDetails
 * Usage: To display get debit card details
 */
export const DebitCardDetails =
    () => {
        const params = useParams<any>();
        const id = params.id
        //API request for get debit card details
        const debit_card_source = {
            url: "/account/id/" + getKey("selectedAccount")?.id + "/debitCard/id/"+id,
            method: "GET"
        }

        return (
            <> {checkPermission({ entity: "Accounts", name: "Debit Card", operation: "View" }) ?
                <DebitCardDetailsCard id={"debit-card-details-card"} source={debit_card_source}></DebitCardDetailsCard> :
                <FxAccessDenied id={'debit-card-details-access-denied'} title={'You are not authorized to access this page'} />}
            </>
        )
    }
