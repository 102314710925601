import React, { useEffect } from 'react'
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { clean, convertToPhoneRequestFormat, renderNameError, renderPhoneError, setPhoneValidationOptions, setZipValidationOptions, renderCityNameError, setCityNameValidation } from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import usa_state from '../../Utils/usa_states.json';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();
Logger.debug("CreateAddressCard.tsx", "create address new initializing");

// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: Create Address Card
 * Usage: Create multiple Address
 */
export const CreateAddressCard: React.FC<any> = React.memo((props) => {

        //#region Variable Declaration
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control } = useForm();
        const [isLoading, setIsLoading] = React.useState(false);
        const submitButton = 'Add Address';
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        const history = useHistory();
        const params = useParams<any>();
        const location:any=useLocation()
        const shippedTo=location.state?.contact?.shippedTo;


        //#region Assigning values to variable
        const contactId = params.id
        useEffect(() => {
            if(!shippedTo){
                history.push('/payee/view/'+ contactId)
            }
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add Shipping Address', backButton: '/payee/view/'+ contactId} } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //#region Validations

        /**
         * Name validation
         */
        const nameValidation = {
            required: true,
            maxLength: 45
        }


        //#region Handle Functions

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            history.push('/payee/view/' + contactId)
        };

        /**
         * Method to handle error
         * @param err
         * @returns
         */
        const renderZipError = (err: InputErrType): string => {
            return err.message;
        };

        /**
           * Method to handle on submit request
           * @param data : form data
           */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            req = createAddressRequest(data)
            const successMessage = 'Address Created Successfully!';
            status = await createAddress(clean(req));
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push('/payee/view/' + contactId);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsLoading(false);

        }

        /**
    * Method to create address request
    * @param data
    * @returns
    */
        function createAddressRequest(data: any) {
            const object: any = {};
            const address: any = {
                addressLine1: data.addressLine1,
                city: data.city,
                state: data.state,
                zip: data.zip,
                phone: data.recipientPhone ? convertToPhoneRequestFormat(data.recipientPhone) : '',
                countryCode: data['recipientPhone-select'],
                name: data.recipientName,
            }
            if (data.addressLine2 && data.addressLine2 !== '') {
                address.addressLine2 = data.addressLine2;
            }
            object.mailingAddress = [address];
            return object
        }
        /**
         * Method to call api  for create address
         * @param paylaoddata : request payload
         *
         */
        async function createAddress(paylaoddata: any) {
            try {
                const url =  '/contact/id/' + contactId
                const data: any = await httpClient.post(getCustomerUrl(url,false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateAddressCard.tsx", "error", err);
                return err;
            }
        }

        return (
            <Grid container id="create-address-payees-main-grid" xs={12} className='fx-form-grid' >
                <Grid id="create-address-first-grid" item xs={12} sm={8}>
                    <Grid id="create-address-sub-grid" container spacing={1} >
                        <Grid id="create-address-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {<form id="create-address-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-address-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="create-addrsss-form-card-header">

                                        </FxCardHeader>
                                        <FxCardBody id="create-address-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {<>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxLabelView id="customer-signup-individual-info-label-primary-address" className="" >SHIPPING ADDRESS DETAILS</FxLabelView>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("recipientName") }} className={errors.recipientName ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={nameValidation} control={control} id="payee-address-add-recipientname-textbox" label="Shipped To *" placeholder="Shipped To" name="recipientName" variant="outlined" defaultValue={shippedTo }/>
                                                        <div className={'error-message'}>
                                                                {errors.recipientName && renderNameError(errors.recipientName)}
                                                            </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)}
                                                        setValue={setValue} setError={setError} clearErrors={clearErrors}
                                                        className={errors.recipientPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-recipientphone-textbox" name="recipientPhone" label="Phone *" />
                                                        <div className={'error-message'}>
                                                            {errors.recipientPhone && renderPhoneError(errors.recipientPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'payee-address-add-addressline1-textbox'} label="Address Line 1*" name={'addressLine1'} variant="outlined" defaultValue={props?.data?.addressLine1 ? props?.data?.addressLine1 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'payee-address-add-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} variant="outlined" defaultValue={props?.data?.addressLine2 ? props?.data?.addressLine2 : ''} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(true)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-city-textbox" name="city" label="City *" defaultValue={context?.data?.body?.card?.billingAddress?.city} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-address-add-state-textbox" name="state" data={usa_state} label="State *" setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(true)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-add-zip-textbox" name="zip" label="Zip *" defaultValue={context?.data?.body?.card?.billingAddress?.zip} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderZipError(errors.zip)}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={!isSmallScreen ? 6 : 12}></Grid>
                                                </>
                                                }
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="create-address-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payee-address-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"payee-add-address-submit-button"}
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>

                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });