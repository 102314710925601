/**
 * Component handles the beneficial owner part in the lead invite form
 */
import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxButton } from '../../../Action/FxButton';
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new-icon.svg';
import { updateComponentParams } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import { AddBeneficialOwner } from './AddBeneficialOwner';
import { BeneficialOwnerDetailsCard } from './BeneficialOwnerDetailsCard';
import { useFieldArray, useFormContext } from 'react-hook-form';



interface IViewBusinessOwner {
    id: string;
    showAdd?: boolean;
    register?: any;
    control?: any;
    errors?: any;
    setValue?: any;
    setError?: any;
    clearErrors?: any;
    handleSubmit?: any;
    trigger?: any;
    businessCategory?: any;
    data?: any;
    watch?: any;
    fields?: any;
    append?: any;
    remove?: any;
    reset?: any;
    defaultValue?:any;
}

export const ViewBeneficialOwner: React.FC<IViewBusinessOwner> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const { register, control, formState: { errors }, watch, setValue, setError, clearErrors, handleSubmit, reset, trigger, unregister } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "beneficialOwner",
    });

   
    /**
     * Append a new object with default values to the props using append and Update component parameters
     */
    const addBeneficialOwner = () => {
        append({ firstName: '', lastName: '', email: '', jobtitle: '', ownerShipPercentage: '', dob: '', ssn: '', usCitizen: '', mobilePhone: '', homePhone: '', workPhone: '', secondaryIdentificationType: '', secondaryIdentificationId: '', secondaryIdentificationIssuanceState: '', secondaryIdentificationIssuance: '', secondaryIdentificationIssuanceAlien: '', addressLine1: '', addressLine2: '', city: '', state: '', zip: '', authSignatory: '' })
        updateComponentParams(dispatch, props.id, { showAdd: true })
    }
    /**
     * Update component parameters during submission of add beneficial owner
     */
    const handleOnSubmit = () => {
        updateComponentParams(dispatch, props.id, { showAdd: false })
    }
    /**
     * Initialize the component parameters
     */
    useEffect(() => {
       append([]);
       updateComponentParams(dispatch, props.id, { showAdd: false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    /**
     * Update component parameters during cancellation of add beneficial owner form
     * @param index : index of the item to cancel
     */
    const handleOnCancel = (index: any) => {
        updateComponentParams(dispatch, props.id, { showAdd: false })
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'partner-beneficial-owner', data: { collapseEdit: false } } });
        remove(index)
    }
    /**
     * Method hanldes the removal of the beneficial owner
     * @param index : index of the item to remove
     */
    const handleRemove = (index: number) => {
        remove(index)
        const dobValue =  watch(`beneficialOwner.${index}.dob`)
        unregister(`beneficialOwner.${index}.dob`)
        dobValue && register(`beneficialOwner.${index}.dob`)
        dobValue && setValue(`beneficialOwner.${index}.dob`, dobValue)
        const ssnValue =  watch(`beneficialOwner.${index}.ssn`)
        unregister(`beneficialOwner.${index}.ssn`)
        ssnValue && register(`beneficialOwner.${index}.ssn`)
        ssnValue && setValue(`beneficialOwner.${index}.ssn`, ssnValue)
        const stateValue =  watch(`beneficialOwner.${index}.state`)
        unregister(`beneficialOwner.${index}.state`)
        stateValue && register(`beneficialOwner.${index}.state`)
        stateValue && setValue(`beneficialOwner.${index}.state`, stateValue)
    }
    return (
        <Grid container id='view-business-owner-grid' xs={12} >
            {fields?.length > 0 && fields?.map((item: any, index: any) => {
                return <>{((index === fields?.length - 1 && !props.showAdd) || index !== fields?.length - 1) && <BeneficialOwnerDetailsCard
                    key={item.id}
                    id={`beneficial-owner-details-card-#${index}`}
                    item={watch('beneficialOwner')?.[index]}
                    index={index}
                    data={fields}
                    register={register} control={control} errors={errors} setValue={setValue} setError={setError} clearErrors={clearErrors} watch={watch} handleSubmit={handleSubmit} remove={handleRemove} reset={reset} fields={fields} trigger={trigger}
                />}</>
            })}
            {fields?.length < 1 && <>
                <Grid item xs={12} sm={12}>
                    <Typography className='fx-new-invite-sub-label'>BENEFICIAL OWNER</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>&nbsp;</Grid>
            </>}
            {!props.showAdd && <Grid item xs={12} sm={12}>
                <FxButton
                    disableRipple={false}
                    className={"fx-button fx-svg-theme"}
                    id="add-business-owner"
                    startIcon={<AddIcon />}
                    onClick={addBeneficialOwner}
                > {fields?.length > 0 ? 'Add Another Beneficial Owner' : 'Add'}
                </FxButton>
            </Grid>}
            {props.showAdd && <AddBeneficialOwner id='view-beneficial-owner-add' onSubmit={handleOnSubmit} onCancel={handleOnCancel} register={register} control={control} errors={errors} setValue={setValue} setError={setError} clearErrors={clearErrors} handleSubmit={props.handleSubmit} businessCategory={props.businessCategory} data={fields} watch={watch} reset={reset} fields={fields} index={fields?.length - 1} trigger={trigger} />}
        </Grid>
    );
}
)
