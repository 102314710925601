import React  from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { EditInternationalExternalaccountCard } from './EditInternationalEAContact';
/**
 * This component loads the Edit EIA component
 */

export const EditInternationalEAHome: React.FC<any> = React.memo((props) => {
        //#region Variable Declaration
        const params = useParams<any>();
        const location: any = useLocation();

        //#region Assigning Values to Variable
        const id = params.id;
        const contactId = params.contactId;
        const internationalExternalAcc = location.state?.contact?.eaDetails?.data;

        return (
            <Grid id="payee-eia-account-info-grid" container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container">
                <Grid item xs={12} lg={12} >
                    <EditInternationalExternalaccountCard eiadetails={{ eiaId: id,contactId:contactId, data: internationalExternalAcc }} key="editeiacontact" id={"-edit-eia-payees-component-" + id} ></EditInternationalExternalaccountCard>
                </Grid>
            </Grid>
        )
    })
