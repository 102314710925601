import { Divider, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input';
import { FxInfoCard } from '../../Page/Cards/FxInfoCard';
import { FxApplicationBanner } from './FxApplicationBanner';


Logger.debug("FxApplyDebitCard.tsx", "apply debit card initializing")

/**
 * Component used inside the right drawer to apply for a debit card
 */
export interface IApplyTreasuryAccountPayload {
    isTreasuryAccountApplied: boolean;
}

type FormValues = {
    authorisedUser: number;
    shippingAddress: number;
    cardType: string;
    expressDelivery: 'YES' | 'NO';
}

export const FxBusinessInfo: React.FC<any> = React.memo(
    
    (props) => {

        const { register, formState: { errors, isSubmitting }, handleSubmit, setValue, control } = useForm();
    
    // debit terms & condition options
    const isForeignFinInstitution = [
        {
            label: 'Is a Foreign financial institution?',
            value: 'yes',
            checked : props.ownerDetails?.foreignFinancialInstituion || false
        }
    ];

    const business_information_section = [
        {
            label: 'Legal Name',
            value: 'legalName',
        },
        {
            label: 'Doing Business As',
            value: 'doingBusinessAs',
        },
        {
            label: 'Business Category',
            value: 'businessCategory',
        },
        {
            label: 'Tax ID (EIN)',
            value: 'ein',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'Phone',
            value: 'phone',
        },
        {
            label: 'Address',
            value: 'address',
            type: 'fri://fieldtype/address'
        }
    ];

    const taxClassificationOptions = [
        {
            label: 'C Corporation',
            value: 'C_CORPORATION'
        },
        {
            label: 'S Corporation',
            value: 'S_CORPORATION'
        },
        {
            label: 'Partnership',
            value: 'PARTNERSHIP'
        },
    ]

    const industryTypeOptions = [
        {
            label: 'Agriculture, Forestry, Fishing',
            value: 'AGRICULTURE_FORESTRY_AND_FISHING'
        },
        {
            label: 'Mining',
            value: 'MINING'
        },
        {
            label: 'Construction',
            value: 'CONSTRUCTION'
        },
        {
            label: 'Manufacturing',
            value: 'MANUFACTURING'
        },
        {
            label: 'Transportation & Public Utilities',
            value: 'TRANSPORTATION_AND_PUBLIC_UTILITIES'
        },
        {
            label: 'Wholesale Trade',
            value: 'WHOLESALE_TRADE'
        },
        {
            label: 'Retail Trade',
            value: 'RETAIL_TRADE'
        },
        {
            label: 'Finance, Insurance, Real Estate',
            value: 'FINANCE_INSURANCE_AND_REAL_ESTATE'
        },
        {
            label: 'Services',
            value: 'SERVICES'
        },
        {
            label: 'Public Administration',
            value: 'PUBLIC_ADMINISTRATION'
        },
    ];

    const investmentObjectiveOptions = [
        {
            label: 'Capital Preservation',
            value: 'CAPITAL_PRESERVATION'
        },
        {
            label: 'Income',
            value: 'INCOME'
        },
        {
            label: 'Growth Income',
            value: 'GROWTH_INCOME'
        },
        {
            label: 'Growth',
            value: 'GROWTH'
        },
        {
            label: 'Speculation',
            value: 'SPECULATION'
        },
    ]

    /**
     * Method used to request to apply debit card
     */
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        let req: any = { ...data }
        const businessInfo :any = {
            "taxDetail" : {
                "taxClassification" : req['taxClassification']
            },
            "financialDetail" : {
                "annualGrossRevenue" : req['annualGrossRevenue'],
                "totalLiquidAsset" : req['totalLiquidAssets'],
            },
            "investmentObjective" : req['investmentObjective']
        }
        if(req.foreignFinancialInstituion?.length) {
            businessInfo['foreignFinancialInstituion'] = true;
        } else {
            businessInfo['foreignFinancialInstituion'] = props.ownerDetails?.foreignFinancialInstituion || false;
        }
        businessInfo['industryType'] = req.industryType;

        req = businessInfo;
        // to manage form state across drawer
        props.setOwnerDetails({ 
            ...props.ownerDetails, 
            financialDetail: req.financialDetail, 
            taxDetail: req.taxDetail,
            foreignFinancialInstituion: businessInfo['foreignFinancialInstituion'],
            industryType: businessInfo['industryType'],
        });
        
        // req = clean(req);
        if (props.onSubmit) {
            props.onSubmit(data, req)
        }
    }

    let businessInfoSource  = { ...props.data };

    // Validation rules for Tax Classification
    const taxClassificationRules = {
        required: businessInfoSource?.businessCategory === 'LLC' ? true : false,
    };

    /**
     * tranform and set user details API
     */
    if (props?.data) {
        businessInfoSource['address'] =  businessInfoSource.mailingAddress ? businessInfoSource.mailingAddress.find((address: {isPrimary: boolean}) => address.isPrimary): "";
    }

    /**
     * function to handle On Change Event of CheckBox
     * @param event 
     */
    const handleForeignFinInstitutionChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        if (event.target?.type === 'checkbox') {
            props.setOwnerDetails({...props.ownerDetails, foreignFinancialInstituion: !props?.ownerDetails?.foreignFinancialInstituion});
        }
    }

    /**
    * Method to handle upto two decimal values
    * @param value field value
    * @param fieldName field name
    */
    function handleUptoTwoDecimalValue(value: any, fieldName: string) {
        if(value) {
            setValue(fieldName, (+value).toFixed(2));
        }
    }

    return (
        <Grid container className='fx-business-info-card-main-content'>
                <Grid item xs={12} marginY={'1rem'}>
                    <FxApplicationBanner text='Opt In for Treasure Investment Account!'/>
                </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">BUSINESS INFORMATION</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FxInfoCard id={"section-add-business-info-card-business-information"}  className={'fx-treasure-details-info-card'} notiltle={true} column={business_information_section} data={businessInfoSource} />
                  </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">ADDITIONAL INFO</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container xs={12} spacing={2} className='fx-business-info-card-main-content-additional-info fx-form-edit-profile'>
                            <Grid item xs={6}>
                                <FxMaterialSelect
                                    register={{ ...register('industryType') }} control={control}
                                    className={errors.industryType ? 'border-error-input' : 'fx-input-edit fx-input-edit-text-layout'}
                                    id="industry-type"
                                    data={industryTypeOptions}
                                    name="industryType" 
                                    label={'Industry Type*'}
                                    setValue={setValue}
                                    value={props.ownerDetails?.industryType || ''}
                                    rules={{required: true}}
                                />
                            </Grid>
                            { (businessInfoSource?.businessCategory === 'LLC' || businessInfoSource?.businessCategory === 'SINGLE_MEMBER_LLC') &&  <Grid item xs={6}>
                                <FxMaterialSelect
                                    register={{ ...register('taxClassification') }} control={control}
                                    className={errors.taxClassification ? 'border-error-input' : 'fx-input-edit fx-input-edit-text-layout'}
                                    id="business-info-taxClassification"
                                    data={taxClassificationOptions}
                                    name="taxClassification" label={'Tax Classification*'}
                                    setValue={setValue}
                                    value={props.ownerDetails?.taxDetail?.taxClassification || ''}
                                    rules={taxClassificationRules}
                                />
                            </Grid>}
                            <Grid item xs={6}>
                                <FxMaterialSelect
                                    register={{ ...register('investmentObjective') }} control={control}
                                    rules={{ required: true }}
                                    className={errors.investmentObjective ? 'border-error-input fx-input-edit' : 'fx-input-edit fx-input-edit-text-layout'}
                                    id="business-info-investmentObjective"
                                    name="investmentObjective" label={'Investment Objective*'}
                                    setValue={setValue}
                                    data={investmentObjectiveOptions}
                                    value={props.businessDetail?.investmentObjective || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FxTextEdit
                                    type='number'
                                    register={{ ...register("annualGrossRevenue") }}
                                    className={errors.annualGrossRevenue ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                    rules={{required: true, min: 0.1}} 
                                    control={control}
                                    id="business-info-card-gross-revenue-textbox"
                                    label="Annual Gross Revenue*"
                                    name="annualGrossRevenue"
                                    variant="outlined"
                                    defaultValue={props.ownerDetails?.financialDetail?.annualGrossRevenue || ''}
                                    prefix='$'
                                    placeholder='0.00'
                                    valuePattern={/-|\+|e|E/}
                                    onWheel={(e:any)=>e.target.blur()}
                                    onBlur={(val) => handleUptoTwoDecimalValue(val, 'annualGrossRevenue')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FxTextEdit
                                    type='number'
                                    register={{ ...register("totalLiquidAssets") }}
                                    className={errors.firstName ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                    rules={{required: true, min: 0.1}} 
                                    control={control}
                                    id="business-info-card-liquid-assets-textbox"
                                    label="Total Liquid Assets*"
                                    name="totalLiquidAssets"
                                    variant="outlined"
                                    defaultValue={props.ownerDetails?.financialDetail?.totalLiquidAsset || ''}
                                    prefix='$'
                                    placeholder='0.00'
                                    valuePattern={/-|\+|e|E/}
                                    onWheel={(e:any)=>e.target.blur()}
                                    onBlur={(val) => handleUptoTwoDecimalValue(val, 'totalLiquidAssets')}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FxCheckEdit
                                    register={{ ...register('foreignFinancialInstituion') }}
                                    control={control}
                                    id="foreign-finanical-institution-check" 
                                    name="foreignFinancialInstituion"
                                    className="fx-privacy-text"
                                    data={isForeignFinInstitution}
                                    row="vertical"
                                    setValue={setValue} 
                                    onChange={handleForeignFinInstitutionChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'} paddingBottom='4rem'>
                                <FxButton
                                    variant='contained'
                                    className='fx-button fx-button-cancel'
                                    id="business-info-btn-back"
                                    isSubmitting={isSubmitting}
                                    onClick={()=> props.onChange(1)}>Back</FxButton>
                                <span className="fx-padding-right-16" />
                                <FxButton
                                    disableRipple={false}
                                    className='fx-button fx-button-theme'
                                    id="business-info-btn-continue"
                                    isSubmitting={isSubmitting} 
                                    type='submit'
                                    >Continue</FxButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
})  