import React, { useState } from 'react';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { FxButton } from '../Action/FxButton';
import FxCard from '../Container/FxCard';
import FxCardBody from '../Container/FxCardBody';
import FxCardFooter from '../Container/FxCardFooter';
import { ScheduleCollectMenu } from '../Page/Schedules/ScheduleCollectMenu';
import { ScheduleMenuCard } from '../Page/Schedules/ScheduleMenu';
import { TransactionSummary } from '../Page/Schedules/TransactionSummaryTemplate';
import { getKey } from '../../libs/utils/storageManager';
import { useIsMobileScreen } from '../../libs/utils/utils';
import { ReactComponent as BackIcon } from '../../assets/svg/back-button-icon.svg';
import FxModalGeneric from '../Utils/FxModalGeneric';
import { AuthenticationVerificationModal } from '../Page/AuthenticationModal/AuthenticationVerificationModal';
import UIFeatureImpl from '../../libs/services/uiFeatures';
import { getScheduleType } from '../Utils/CommonBaseClass';

type PropsType = {
    id?: string;
    type?: 'send' | 'collect';
    children: React.ReactNode;
    loading?: boolean;
    submitTitle: string;
    summaryData: Record<string, any>;
    onCancel?(): void;
    onSubmit?(): void;
    saveEnabled?: boolean | undefined;
    verifyProps?:any;
    handleCloseModal?:any;
}

/**
 * This Component handles assembling schedule screens
 * It has left menu, summary and also footer
 */
const ScheduleLayout: React.FC<PropsType> = ({ id, type, children, submitTitle, summaryData, loading, onCancel, onSubmit, saveEnabled, verifyProps, handleCloseModal }) => {
    const params = useParams<any>();
    const hasSelectedAccount = !!getKey('selectedAccount');
    const isSmallScreen = useIsMobileScreen();
    const [reviewMode, setReviewMode] = useState(false);

    const transactionType = type || params?.type;
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available

    return (<>
        <Grid item xs={12}>
            <FxCard className="fx-schedule-card-general fx-theme-passport fx-no-box-shadow">
                <div className="fx-form-edit-profile flex column">
                    <form id={`${id}-form`} onSubmit={onSubmit}>
                        <FxCardBody className="fx-info-card">
                            <Grid container direction="row" justifyContent="space-between" className="flex column fx-container-create-schdule" >
                                {(!reviewMode || !isSmallScreen) && (
                                    <>
                                        <Grid item xs={12} sm={7} md={8} className='fx-container-create-schdule-middle-scroll fx-container-create-schdule-left-layout'>
                                            <Grid item xs={12} className="fx-container-create-schdule-header">
                                                <Typography variant="h2">
                                                    {transactionType === 'collect' ? getScheduleType(businessCustomer,'title') + ' Money From' : 'Send Money To'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={'fx-schedule-menu'}>
                                                {transactionType === 'collect' ? (
                                                    <ScheduleCollectMenu id={`${id}-schedule-menu`} edit={!!params?.id} />
                                                ) : (
                                                    <ScheduleMenuCard id={`${id}-schedule-menu`} edit={!!params?.id} />
                                                )}
                                            </Grid>
                                            <Grid className={'fx-container-create-schdule-left-layout-content'}>
                                                {children}
                                                {!isSmallScreen && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                        <FxCardFooter id={`${id}-form-card-footer`}>
                                                            <Grid container xs={12} justifyContent="flex-end">
                                                                <FxButton type="submit" onClick={onCancel} variant="contained" id={`${id}-cancel-button`} className="fx-button fx-button-cancel">
                                                                    Cancel
                                                                </FxButton>
                                                                <span className="fx-padding-right-16" />
                                                                <FxButton
                                                                    disableRipple={false}
                                                                    forbiddenMessage={!hasSelectedAccount ? "Passport Account Activation is pending. Please Activate to proceed." : ''}
                                                                    className={saveEnabled ? "fx-button fx-button-theme" : "fx-button fx-button-disabled"}
                                                                    id={`${id}-submit-button`}
                                                                    disabled={loading || !saveEnabled}
                                                                    type="submit">
                                                                    {loading ? (
                                                                        <CircularProgress
                                                                            size={20}
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        />
                                                                    ) : submitTitle}
                                                                </FxButton>
                                                            </Grid>
                                                        </FxCardFooter>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} sm={5} md={4} paddingLeft={'2rem'} className='fx-container-create-schdule-right fx-container-create-schdule-right-layout'>
                                    {(!isSmallScreen || reviewMode) && (
                                        <TransactionSummary id={id} {...summaryData} />
                                    )}
                                </Grid>
                            </Grid>
                        </FxCardBody>
                        {isSmallScreen && (
                            <Grid
                                container
                                xs={12}
                                id={`${id}-form-card-footer`}
                                className='fx-card-footer-mobile'
                                justifyContent='space-around'
                            >
                                {reviewMode ? (
                                    <FxButton startIcon={<BackIcon className='fx-themed-icon' />} onClick={() => setReviewMode(false)} variant="contained" id={`${id}-back-button`} className="fx-button fx-button-cancel">
                                        Back
                                    </FxButton>
                                ) : (
                                    <>
                                        <FxButton onClick={onCancel} variant="contained" id={`${id}-cancel-button`} className="fx-button fx-button-cancel">
                                            Cancel
                                        </FxButton>
                                        <FxButton
                                            disableRipple={false}
                                            forbiddenMessage={!hasSelectedAccount ? "Passport Account Activation is pending. Please Activate to proceed." : ''}
                                            className={saveEnabled ? "fx-button fx-button-theme" : "fx-button fx-button-disabled"}
                                            id={`${id}-submit-button`}
                                            type='submit'
                                            disabled={loading || !saveEnabled}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : submitTitle}
                                        </FxButton>
                                    </>
                                )}
                            </Grid>
                        )}
                    </form>
                </div>
            </FxCard>
        </Grid>
        <FxModalGeneric
            id={`${id}-otp`}
            open={verifyProps?.open}
            onClose={handleCloseModal}
            componentProps={{ ...verifyProps }}
            className={'fx-modal-small2medium'}
            component={AuthenticationVerificationModal}
        >
        </FxModalGeneric>
    </>

    );
}

export default ScheduleLayout;
