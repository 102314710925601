import React from 'react'
import { Logo } from '../../../components//Utils/Logo';

/**
 * Component used to draw smb banner content for faster funding
 * @returns 
 */
export default function SmbBannerContent() {
    return (
        <p>Opt in for faster funding on your &nbsp;&nbsp;<Logo />&nbsp;&nbsp;Account and get access to your funds faster.</p>
    )
}
