/**
 * Component: CustomerView
 * Usage: For Customer View
 */
import React, { useEffect } from "react";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { CustomerViewDetails } from "./CustomerViewDetails";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

export const CustomerView: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const params = useParams<any>();
  const dispatch = useDispatch();

  /**
   * used to reset the component while unmount
   */
  useEffect(() => () => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'partner-customer-view-card' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'transaction-volume' } });
    
  }, [dispatch]);
/**
 * Source for customer details
 */
  const src = {
    url: `customer/id/${params?.id}`,
    method: "GET",
    baseUrl: true
  }
  return (
    <CustomerViewDetails id="partner-customer-view-card" source={src}></CustomerViewDetails>
  );
});