import React, { useEffect, useMemo } from 'react'
import { Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { checkPermission, getScheduleType, maskAccountNumber, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxButton } from '../../../Action/FxButton';
import { isAnyAchControl } from '../../../Utils/ThemeChecker';
import { useDispatch, useSelector } from 'react-redux';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { ReactComponent as ThreeDotMenu } from '../../../../assets/svg/threedot-menu-icon.svg';
import { AccountStatementDownload } from './AccountStatementDownload';
import { FxLink } from '../../../Action/FxLink';
import { ReactComponent as ForwardArrowIcon } from '../../../../assets/svg/arrow-right.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/svg/copy-to-clipboard.svg';
import FxModalGeneric from '../../../Utils/FxModalGeneric';
import { SetAsPrimaryAccountModal } from './SetAsPrimaryAccountModal';
import { EditNicknameModal } from './EditNicknameModal';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import moment from 'moment';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';

/**
 * The is a Customer Account Info Component and define actions for accounts
 */
export const AccountInfoCard: React.FC<any> = React.memo((props) => {

    const history = useHistory();
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available
    /**
    * to get ppi details
    */
    const customerBasicInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
        return state.data['customer-basic-info']
        }
    });

    const accountStatementPermission = {
        entity: "Accounts",
        name: "Statement",
        operation: "Download"
    }
    const editAccountPermission = {
        entity: "Accounts",
        name: "Account",
        operation: "Edit"
    }

    /**
     * create collect button  permission
     */
    const collectPermission = useMemo(() => {
        const permissionData: any = [ ]
          permissionData.push({
            entity: "Accounts",
            name: "Card Collect",
            operation: "CREATE"
          },
          {
            entity: "Accounts",
            name: "Check Collect",
            operation: "CREATE"
          },
          {
            entity: "Recurring Transaction",
            name: "CARD Collect",
            operation: "CREATE"
          })
  
          if(businessCustomer && (isAnyAchControl(getKey('collectAchControl')))){
            permissionData.push({
              entity: "Accounts",
              name: "ACH Collect",
              operation: "CREATE"
            },
              {
                entity: "Recurring Transaction",
                name: "ACH Collect",
                operation: "CREATE"
              }
            )
          }
        
        if ((checkPermission({ entity: "Accounts", name: "Ach Collect", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Ach Collect", operation: "Create" })) && isAnyAchControl(getKey('collectAchControl'))) {
            updateComponentParams(dispatch,props.id,{'collectUrl':'/transaction/collect/add/my-account-ach'});
        } else if (checkPermission({ entity: "Accounts", name: "Check Collect", operation: "Create" })) {
            updateComponentParams(dispatch,props.id,{'collectUrl':'/transaction/collect/add/mobile-check-deposit'});
        } else if (checkPermission({ entity: "Accounts", name: "Card Collect", operation: "Create" })) {
            updateComponentParams(dispatch,props.id,{'collectUrl':'/transaction/collect/add/onetime-card'});
        }
  
        const hasPermission = permissionData?.some((item:any) => checkPermission(item));
        return hasPermission;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [getKey("isUserDetailsAvailable")])

    
    /**
     * create send button  permission
     */
    const sendPermission = useMemo(() => {
        let hasPermission = false;
        const permissionData: any = [
          {
            entity: "Accounts",
            name: "Virtual Card Send",
            operation: "Create"
          },
          {
            entity: "Recurring Transaction",
            name: "Virtual Card Send",
            operation: "Create"
          },
          {
            entity: "Accounts",
            name: "Book Send",
            operation: "Create"
          },
          {
            entity: "Recurring Transaction",
            name: "Book Send",
            operation: "Create"
          }, {
            entity: "Accounts",
            name: "Check Send",
            operation: "Create"
          },
          {
            entity: "Recurring Transaction",
            name: "Check Send",
            operation: "Create"
          }, {
            entity: "Accounts",
            name: "Wire Send",
            operation: "Create"
          }, {
            entity: "Recurring Transaction",
            name: "Wire Send",
            operation: "Create"
          }, {
            entity: "Accounts",
            name: "Ach Send",
            operation: "Create"
          },
          {
            entity: "Recurring Transaction",
            name: "Ach Send",
            operation: "Create"
          }, {
            entity: "Accounts",
            name: "International Wire Send",
            operation: "Create"
          },
          {
            entity: "Recurring Transaction",
            name: "International Wire Send",
            operation: "Create"
          }
        ];
  
        const contactPermission= {
          entity: "Other Entities",
          name: "Contact",
          operation: "VIEW"
        };
  
        if (checkPermission({ entity: "Accounts", name: "Ach Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Ach Send", operation: "Create" })) {
          updateComponentParams(dispatch,props.id,{'sendUrl':'/transaction/send/add/ach'})
        } else if (checkPermission({ entity: "Accounts", name: "Wire Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Wire Send", operation: "Create" })) {
          updateComponentParams(dispatch,props.id,{'sendUrl':'/transaction/send/add/wire'})
        } else if (checkPermission({ entity: "Accounts", name: "International Wire Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "International Wire Send", operation: "Create" })) {
          updateComponentParams(dispatch,props.id,{'sendUrl':'/transaction/send/add/internationalWire'})
        } else if (checkPermission({ entity: "Accounts", name: "Check Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Check Send", operation: "Create" })) {
          updateComponentParams(dispatch,props.id,{'sendUrl':'/transaction/send/add/check'})
        } else if (checkPermission({ entity: "Accounts", name: "Book Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Book Send", operation: "Create" })) {
          updateComponentParams(dispatch,props.id,{'sendUrl':'/transaction/send/transfer'})
        } else if (checkPermission({ entity: "Accounts", name: "Virtual Card Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Virtual Card Send", operation: "Create" })) {
          if (businessCustomer && getKey("sendViaVirtualMethod")?.toLowerCase() === 'true') {
            updateComponentParams(dispatch,props.id,{'sendUrl':checkPermission(contactPermission) ? '/transaction/send/contact/add/virtualcard' : '/transaction/send/onetime/add/virtualcard'})
          }
        }
  
        hasPermission = permissionData?.some((item:any) => checkPermission(item));
        return hasPermission;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [getKey("isUserDetailsAvailable")])  

    /**
     * useEffect to set default address 
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });      
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    /**
     * Handling the click event
     * @param event 
     * @param id 
     */
    const handleClick = (event: any, id: any) => {
        updateComponentParams(dispatch, props?.id, { 'addressId': id, 'anchorEl': event.currentTarget })
    };

    /*
    * close the delete popup
    */
    const handleClose = () => {
        updateComponentParams(dispatch,props?.id,{'anchorEl':null})
    };

    /**
     * Method handles the change event of the menu click
     * @param event
     */
    const handleChange = (event: any) => {
        try{
            const mapper: any = {
                "Collect Money": props?.collectUrl,
                "Send Money": props?.sendUrl,
                "Deposit Money": props?.collectUrl
            }
            if(mapper[event]){
                handleAccountChange(props?.data?.id,'Actions')
                history.push(mapper[event]);
            }else if(event === 'Download Statement'){
                handleAccountChange(props?.data?.id,'Download')
                updateComponentParams(dispatch,props?.id,{'downloadStatementModalOpen':true})
            }else if(event === 'Set as Primary Account'){
                updateComponentParams(dispatch,props?.id, {'setAsPrimaryAccountModalOpen':true})
            }else if(event === 'Edit Nickname'){
                updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':true})
            }
            updateComponentParams(dispatch,props?.id,{'anchorEl':null})
        }
        catch(e){ }
    }

    const open = Boolean(props?.anchorEl);

    // declare active account menu options
    const active_account_options = [
        {
            id: 'account-collect-money-option',
            title: getScheduleType(businessCustomer,'title') + ' Money',               
            permission: collectPermission
        },
        {
            id: 'account-send-money-option',
            title: 'Send Money',                
            permission: sendPermission
        },
        {
            id: 'account-download-statement-option',
            title: 'Download Statement',                
            permission:checkPermission(accountStatementPermission)
        },
        {
            id: 'account-edit-nickname-option',
            title: 'Edit Nickname',                
            permission:checkPermission(editAccountPermission)
        }
    ];

    // declare investment account menu options
    const investment_account_options = [
        {
            id: 'account-edit-nickname-option',
            title: 'Edit Nickname',                
            permission:checkPermission(editAccountPermission)
        },
    ];

    // declare investment account menu options
    const inactive_account_options = [
        {
            id: 'account-edit-nickname-option',
            title: 'Edit Nickname',                
            permission:checkPermission(editAccountPermission)
        }
    ];

    if(props?.data?.status !== 'INACTIVE' && (props?.data?.type === 'CASH_BUILDER_PLUS' || props?.data?.type === 'CASH_BUILDER')){
        investment_account_options.push({
            id: 'account-download-statement-option',
            title: 'Download Statement',                
            permission:checkPermission(accountStatementPermission)
        });
    }

    // declare blocked account menu options
    const blocked_account_options = [
        {
            id: 'account-edit-nickname-option',
            title: 'Edit Nickname',                
            permission:checkPermission(editAccountPermission)
        },
        {
            id: 'account-download-statement-option',
            title: 'Download Statement',                
            permission:checkPermission(accountStatementPermission)
        },
    ];

    if(props?.data?.status === 'ACTIVE' && !props?.data?.isPrimary){
        active_account_options.push({
            id: 'account-set-primary-option',
            title: 'Set as Primary Account',                
            permission:checkPermission(editAccountPermission)
        });
    }
    /**
     * method used to handle debit blocked account status options
     */
    const debit_blocked_account_options = useMemo(() => {
        const [firstOption, , ...remainingOptions] = active_account_options;
        return [
            firstOption,
            ...remainingOptions,
            {
                id: 'account-set-primary-option',
                title: 'Set as Primary Account',
                permission: checkPermission(editAccountPermission),
            },
        ];
    }, [active_account_options, editAccountPermission]);

    /**
     * Method to get account actions.
     * @param item
     */
    const getAccountAction=(item:any)=>{
        const options_mapper : any = {
            'ACTIVE' : active_account_options,
            'INACTIVE' : inactive_account_options,
            'BLOCKED' : blocked_account_options,
            'CLOSED' : blocked_account_options,
            'CLOSURE_INITIATED' : blocked_account_options,
            'DORMANT' : active_account_options,
            'DELETED' : [],
            'DEBIT_BLOCKED' : debit_blocked_account_options
        }
        const menu_options = props?.data?.type === 'DEPOSIT' ? investment_account_options : options_mapper[props?.data?.status];
        const options = menu_options?.length > 0 && menu_options.filter((option:any) => option?.permission)
        return (options?.length > 0 && <Grid id="filter-Grid" className="fx-view-action" >
                <>
                    <FxButton
                        aria-label="more"
                        id={props.id + '_' + item?.id + '-button'}
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        className="fx-button fx-button-dotted fx-button-dotted-icons"
                        onClick={(event: any) => { handleClick(event, item.id) }}
                    >
                        <ThreeDotMenu />
                    </FxButton>
                    {<Menu
                        id="external-account-info-menu"
                        MenuListProps={{
                            'aria-labelledby': 'account-list-menu',
                        }}
                        anchorEl={props.anchorEl}
                        open={Boolean(props.anchorEl)}
                        onClose={handleClose}
                        className="fx-menu-dotted"
                    >
                        {(options.length > 0) && options.map((option: any) => (
                            <>
                                {option.permission &&
                                    <MenuItem id={option.id} key={option} onClick={() => { handleChange(option.title) }}>
                                        <div className={option.className}>{option.title}</div>
                                    </MenuItem>
                                }
                            </>
                        ))
                        }
                    </Menu>}
                </>
        </Grid>)
    }
  
    /**
    * Method handles the closing for account statement
    */
    const handleAccountStatementClose = () => {
        updateComponentParams(dispatch,props?.id, {'downloadStatementModalOpen':false})
    }
    
    /**
   * Method handles the selection of Account
   */
    const handleAccountChange = (id: any, type: any) => {
        if(props.handleAccountChange){
            props.handleAccountChange(id, type);
        }
    }

    /**
    * Method handles the closing of set as primary account modal
    */
    const handleSetAsPrimaryAccountModalClose = () => {
        updateComponentParams(dispatch,props?.id, {'setAsPrimaryAccountModalOpen':false})
    }
    
    /**
    * Method handles the closing of edit nickname modal
    */
    const handleEditNicknameModalClose = () => {
        updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':false})
    }

    /**
     * Method handles the onclick event of copy ppi
     * */
    const copyPPI = (e:any) => {
        try{
            navigator.clipboard.writeText(customerBasicInfo?.params?.ppiDetails?.ppi)
            FxSnackBar.show({
                severity: 'success',
                text: 'PPI ID Copied Successfully',
            });
        }
        catch(e){}
    }

    return (
        <>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid container xs direction='column' className='fx-account-info-card' spacing={1}>
            <Grid container item xs direction='row' justifyContent='space-between'>
                <Grid item>
                    <FxLink id='account-dashboard-link' className='fx-account-info-card-heading' onClick={() => handleAccountChange(props?.data?.id, 'Account')}>
                        {props?.data?.nickName ? props?.data?.nickName : props?.data?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : props?.data?.type === 'CASH_BUILDER' ? 'Cash Builder Account' : 'Passport Account'} &nbsp;<ForwardArrowIcon className='fx-thanks-arrow-right' /> &nbsp;&nbsp;&nbsp;&nbsp;
                    </FxLink>
                    {props?.data?.isPrimary && <FxPaymentModeStatus tooltip={true} id={'primary-account'} noColor={true} value='primary' fill={false} className="fx-status" />}
                </Grid>
                <Grid item>
                    {getAccountAction(props?.data)}
                </Grid>
            </Grid>
            <Grid container item xs direction='row'> 
                <Grid item className='fx-account-info-card-subtext'>
                    <Typography variant='h3'>
                        {props?.data?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : props?.data?.type === 'CASH_BUILDER' ? 'Cash Builder Account' : 'Passport Account'} {maskAccountNumber(props?.data?.accountNumber,false)}&nbsp;&nbsp;
                    </Typography>
                </Grid>
                {props?.data?.isPrimary && customerBasicInfo?.params?.ppiDetails?.ppi && <Grid item className='fx-account-info-card-ppi'>
                    <FxLink id='ppi-id-link' onClick={copyPPI}>
                        <Typography variant='h4'>
                            <CopyIcon className='fx-thanks-arrow-right' />&nbsp;{customerBasicInfo?.params?.ppiDetails?.ppi}
                        </Typography>
                    </FxLink>
                </Grid>} 
                {(props?.data?.status === 'INACTIVE' || props?.data?.status === 'BLOCKED' || props?.data?.status === 'DORMANT' || props?.data?.status === 'CLOSURE_INITIATED' || props?.data?.status === 'CLOSED' || props?.data?.status === 'DEBIT_BLOCKED') && 
                <Grid container item xs>
                    &nbsp;&nbsp;<FxStatus id="account-status" value={props?.data?.status === 'INACTIVE' ? 'PENDING ACTIVATION' : props?.data?.status} fill={true} className="fx-status" />
                </Grid>}  
            </Grid>
            <Grid container item xs direction='row' justifyContent='space-between'>
                <Grid container item xs direction='column' spacing={1}>
                    <Grid item xs className='fx-account-info-card-balance-amount'>
                        <FxCurrencyView value={props?.data?.availableBalance ? props?.data?.availableBalance?.amount : props?.data?.balance ? props?.data?.balance?.amount : 0} prefix={"$"} />
                    </Grid>
                    <Grid item xs className='fx-account-info-card-balance-text'>
                        AVAILABLE BALANCE
                    </Grid> 
                </Grid>
                <Grid container item xs direction='row' alignItems='flex-end' spacing={5}>
                    <Grid container item xs direction='column' spacing={1}>
                        <Grid item xs className='fx-account-info-card-credit-debit-amount'>
                            <FxCurrencyView value={props?.data?.totalCredit?.amount} prefix={"$"} showPlus/>
                        </Grid>
                        <Grid item xs className='fx-account-info-card-credit-debit-text'>
                            Total credit ({moment(new Date()).format('MMM YYYY')})
                        </Grid> 
                    </Grid>
                    <Grid container item xs direction='column' spacing={1}>
                        <Grid item xs className='fx-account-info-card-credit-debit-amount'>
                            <FxCurrencyView value={props?.data?.totalDebit?.amount} prefix={"$"} showMinus/>
                        </Grid>
                        <Grid item xs className='fx-account-info-card-credit-debit-text'>
                            Total Debit ({moment(new Date()).format('MMM YYYY')})
                        </Grid> 
                    </Grid>
                </Grid>    
            </Grid>
        </Grid>
        {props?.downloadStatementModalOpen && 
            <AccountStatementDownload 
                id="account-statement-download" 
                open={props?.downloadStatementModalOpen} 
                onClose={handleAccountStatementClose} 
            />
        }
        {props?.setAsPrimaryAccountModalOpen && 
            <FxModalGeneric
                id={"set-as-primary-account"}
                open={props?.setAsPrimaryAccountModalOpen}
                onClose={handleSetAsPrimaryAccountModalClose}
                componentProps={{ accountId: props.data.id, nickname: props?.data?.nickName, accountNumber: props?.data?.accountNumber}}
                className={'fx-modal-small2medium'}
                component={SetAsPrimaryAccountModal}
                title='Set as Primary Account'
            >
            </FxModalGeneric>
        }
        {props?.editNicknameModalOpen && 
            <FxModalGeneric
                id={"edit-account-nickname"}
                open={props?.editNicknameModalOpen}
                onClose={handleEditNicknameModalClose}
                componentProps={{ accountId: props.data.id, nickname: props?.data?.nickName}}
                className={'fx-modal-small2medium'}
                component={EditNicknameModal}
                title='Edit Nickname'
            >
            </FxModalGeneric>
        }
        </>
    )
})