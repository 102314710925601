/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { FxRightDrawerController, IFxRightDrawer } from './FxRightDrawerController';
import { FxRightDrawerContentType } from './FxRightDrawerView';

/**
 * Component for showing right drawer
 * Usage
 * Render only once in the root component
 * Call show() or closeForce() static methods from everywhere
 */
export default class FxRightDrawer extends React.Component {
    private static _fxRightDrawerRef: IFxRightDrawer<any> | null = null;

    public static show = <ReturnType,>(content: FxRightDrawerContentType<ReturnType>) => {
       return (this._fxRightDrawerRef as IFxRightDrawer<ReturnType>)?.show(content);
    }

    public static closeForce = () => {
        this._fxRightDrawerRef?.closeForce();
    }

    render = () => {
        return (
            <FxRightDrawerController
                ref={(ref) => FxRightDrawer._fxRightDrawerRef = ref}
            />
        );
    }
}
