import React, { useEffect } from 'react'
import { Grid, Typography, IconButton, CircularProgress, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();


/**
 * Component: ValidateExternalAccount
 * Usage: For external account validation
 */

Logger.debug("ValidateEA.tsx", "validate external account initializing");

export const ValidateEA: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        const submitButton: any = 'Validate';
        const { register, handleSubmit, setValue, control } = useForm();
        const [EAValidation, setEAValidation] = React.useState<any>([]);
        const [isLoading, setIsLoading] = React.useState(false);
        const [defaultP, setDefault] = React.useState(false);
        const external_account_data = props?.data;
        const params = useParams<any>();
        const id = params.id;
        if (external_account_data ) {
            const array: any = []
            array.push({ label: 'Instant Verification', value: "EWS", checked: false });
            array.push({ label: 'Prenote', value: "PRENOTE", checked: false });
            if(external_account_data && external_account_data.validateAccount)
            {
            external_account_data?.validateAccount.forEach((element: any) => {
                if (element.ews && element.ews['status'] !== "NOT_REQUIRED") {
                 array.find((element:any)=>element.value === 'EWS').checked = true;
                 }
            })
            }
            if (external_account_data && external_account_data.prenote) {
                if(external_account_data?.prenote?.prenoteValidation === 'ALWAYS')
                array.find((element:any) => element.value === 'PRENOTE').checked=true;
            }

            if (!defaultP) {
                setDefault(true)
                setEAValidation(array)
                const defArray:any = [];
                array?.forEach((item: any)=>{
                    if(item.value === 'EWS'&&item.checked){
                        defArray.push('EWS')
                    }
                    else if(item.value === 'PRENOTE'&&item.checked){
                        defArray.push('PRENOTE')
                    }
                })
                if(defArray.length>0){
                    setValue('eaValidation',defArray)
                }
            }
        }

        /**
         * Method to handle change event
         * @param event :event
         */
        const handleChange = (event: any) => {

            if (event.target.value === 'EWS') {
                eaObj.ews = event.target.checked
            } if (event.target.value === 'PRENOTE') {
                eaObj.prenote = event.target.checked
            }

        }

        const eaObj: any = {}

        /***
         * Submitting the results
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            const validate:any={}
            const validateAccount:any=[];
            let preNote:any;
            if(data?.eaValidation.length>0)
              {
                data.eaValidation.forEach((element:any)=>{
                    if(element === "EWS") {
                        validate.ews = true;
                        validateAccount.push(validate)
                    }
                    if(element === "PRENOTE") {
                        preNote = "ALWAYS";
                    }
                })
            }
            if(!preNote)
            {
                preNote = "NEVER"
            }
            if(validateAccount.length === 0)
            {
                validate.ews=false;
                validateAccount.push(validate)
            }
            req={
                externalAccount:[
                    {
                    id:id,
                    validateAccount:validateAccount,
                    prenote : preNote
                }]
            }
            status = await validateExternalAccount(req);
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                //api success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'External Account Validation updated Successfully!',
                });
                props?.close()
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'ea-details-info-card', data_state:1 } });
                dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'payees-ea-external-account-validation-info-card', data_state:1 } });
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }
        /**
         * Method to call external account API to validate EA
         * @param payloadData : request payload
         */
        async function validateExternalAccount(payloadData: any) {
            try {
                const url = '/contact/id/' + props?.contactSource
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("ValidateExternalAccount.tsx", "error", err);
                return false;
            }
        }
        /**
         * rest the component on unmount, so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <div className="modal-content" >
                <div className="modal-body">
                    {<form onSubmit={handleSubmit(onSubmit)}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="validate-ea-header">
                                <Grid container direction="row" item xs={12} justifyContent="space-between">
                                    <Grid item container xs={11} justifyContent="flex-start">
                                        <Typography id="title-ea-modal" variant="h4">
                                            Validate Account
                                        </Typography>
                                    </Grid>
                                    <Grid className="fx-modal-close-icon" item xs={1}>
                                        <IconButton onClick={props.close} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="ea-create-modal-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2}  >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {EAValidation && <FxCheckEdit register={{ ...register("eaValidation") }} rules={{ required: true }} name="eaValidation" control={control} id='payee-ea-validate-check' data={EAValidation} onChange={handleChange} setValue={setValue}
                                        />}
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="payee-validate-ea-cancel-button"
                                        onClick={() => props.close()}>
                                        Cancel
                                    </FxButton>
                                    <span className="fx-padding-right-16" />
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id={"payee-validate-ea-submit-button"}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: 'white',
                                                }}
                                            />
                                        ) : (
                                            submitButton
                                        )}
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </FxCard>
                    </form>}
                </div>
            </div>
        );
    });
