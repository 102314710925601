import {  convertToPhoneRequestFormat} from "../../Utils/CommonBaseClass";

 /** Function handle the request for avsDetails for card
* @param cardDetails : cardDetails
* @returns : transformed data
*/
export function generateAVSDetails(cardDetails: any) {
    const requestAVS= {
        zip: cardDetails.addressSame? cardDetails.cardBillingZip : cardDetails.avsZip,
        phone: convertToPhoneRequestFormat(cardDetails.addressSame? cardDetails.cardBillingPhone : cardDetails.avsPhone || ''),
        email: cardDetails.addressSame? cardDetails.cardBillingEmail : cardDetails.avsEmail,
        firstName: cardDetails.addressSame? cardDetails.cardFirstName : cardDetails.avsFirstName,
        lastName: cardDetails.addressSame? cardDetails.cardLastName : cardDetails.avsLastName,
        addressLine1: cardDetails.addressSame? cardDetails.cardBillingAddressLine1 : cardDetails.avsAddressLine1
    }
    return requestAVS
 }
