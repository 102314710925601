import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Logger } from "../../libs/utils/logger";
const cssFxCard = 'fx-card';

Logger.debug("FxCard.tsx", "Fx Card initializing")

/**
 * <FxCard> component 
 * sub nodes expected are
 * <FxCardHeader> 
 * <FxCardBody> 
 * <FxCardFooter> 
 * @param props 
 */
const FxCard: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = props => {
  // wire the state to component if any
  const state = useSelector((state: any) => {
    if (props.id && state.context[props.id]) {
      return state.context[props.id];
    } else {
      return { config: { params: {} } };
    }
  });
  //merge props
  if (state.config.components) {
    props = { ...props, ...state.config.params }
  }

  return (
    <div id={props.id ? props.id : ""} className={`${cssFxCard} ${props.className ? props.className : ""}`}>
      {props.children}
    </div>
  )
};
export default FxCard;



