/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { FxSnackBarView, FxSnackBarViewProps } from './FxSnackBarView';
import { SnackbarCloseReason } from '@mui/material';

type StateType = Omit<FxSnackBarViewProps, 'onClose'>;
export type FxSnackBarOptionsType = Partial<Omit<StateType, 'open' | 'text'>> & {
    text: string | string[];
}

const defaultOptions: Partial<FxSnackBarOptionsType> = {
    autoHideDuration: 6000,
    severity: 'error',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    }
}

export interface IFxSnackBar {
    show(options: FxSnackBarOptionsType): void;
    closeForce(): void;
}

export class FxSnackBarController extends React.Component<{}, StateType> implements IFxSnackBar {

    public show = (options: FxSnackBarOptionsType) => {
        this.setState({
            ...defaultOptions,
            ...options,
            open: true,
        } as StateType);
    }

    public closeForce = () => {
        this.setState({
            open: false,
        });
    }

    _handleClose = (_: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            open: false,
        });
    }

    render = () => {
        return (
            <FxSnackBarView
                {...this.state}
                onClose={this._handleClose}
            />
        );
    }
}
