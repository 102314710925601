import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { Logo } from '../../Utils/Logo';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardHeader from '../../Container/FxCardHeader';
import { LogoAccount } from '../../Utils/Logo';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as ScalableIcon } from '../../../assets/svg/scalable.svg';
import { ReactComponent as ContactlessIcon } from '../../../assets/svg/contactless-payment.svg';
import { ReactComponent as DiversifiedIcon } from '../../../assets/svg/diversified-payment.svg';
import { getKey } from '../../../libs/utils/storageManager';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import DEFAULT_CONFIG from '../../../configs/default.json'

Logger.debug("FxInvestmentAccountGetStarted .tsx", "sign up info")

interface ITreasureDrawer {
    type?:string;
    onChange: Function
}

export const FxInvestmentAccountGetStarted : React.FC<ITreasureDrawer> = (props) => {  
    const uiFeat = UIFeatureImpl.getInstance();
   


   

    const offerItems = [
        {
            icon:<ContactlessIcon />,
            title:'Accelerate cash deployment',
            subtitle:`Remove lag from your long bank account opening cycles and Open a ${props?.type === 'CASH_BUILDER_PLUS' ? 'Passport Cash Builder+ Account' : 'Passport Cash Builder Account'} in minutes.`
        },
        {
            icon:<DiversifiedIcon />,
            title:'Maximize Liquidity',
            subtitle:`Benefit from high liquidity with our ${props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Accounts' : 'Money Market investments'}, ensuring your funds are easily accessible when required.`
        },
        {
            icon:<ScalableIcon />,
            title:'Diverse Opportunities',
            subtitle:`Unlock a world of short-term investment opportunities with a ${props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : 'Cash Builder Account'}, providing the flexibility to capitalize on diverse income avenues and market opportunities.`
        }
    ];

    const renderOfferItem =(item:any) => <FxCard className="fx-theme-passport-no-before fx-no-shadow fx-investment-account-offer-card">
    <FxCardHeader id="business-details-card-header">
        <Grid container direction="row" xs={12}   >
            <Grid item xs={12}>
                <Grid item container >
                    <Grid item className='icon'> {item.icon}</Grid>
                    <Grid item xs container justifyContent="flex-start">
                        <Grid item xs ={12} ><Typography id="business-details-card-title" variant="h4" >
                            {item.title}
                        </Typography>
                        </Grid>
                        <Grid item xs={12} > <Typography id="business-details-card-title" variant={"h6"}>
                            {item.subtitle}
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </FxCardHeader>
</FxCard>;

    return (
        <Grid container id="fx-investment-account-get-started" className='fx-drawer-right-content'>
            <Grid container spacing={2} className='fx-investment-account'>
                <Grid item xs={12}>
                    <FxCard className="fx-theme-passport fx-no-box-shadow fx-investment-account-header-card">
                        <FxCardHeader id="business-details-card-header">
                            <Grid container direction="row" xs={12}   >
                                <Grid item xs={12}>
                                    <Grid item container >
                                        <Grid item xs container justifyContent="flex-start">
                                            <Grid item xs={12}>
                                                <Typography id="business-details-card-title" variant="h4" >
                                                    Dear {uiFeat.getBusinessCustomerFeature().available ? getKey('CustomerName') : getKey('CustomerIndividualFullName')},
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography id="business-details-card-title" variant="h6">
                                                    As a valued Priority customer, we are pleased to offer you our new Passport {props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : 'Cash Builder Account'} !
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody className="fx-info-card ">   
                            <Grid container>
                                <Grid item xs={10}>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item marginY={'1rem'}>
                                            <Typography variant="h2" >
                                                Turn your business’s idle cash into revenue with                                                                               
                                            </Typography>  
                                            <Grid container spacing={1} className='fx-investment-drawer-logo'>
                                                <Grid item>
                                                    <Logo /> 
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h2">                                                                                                
                                                        {props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account.' : 'Cash Builder Account.'}                                 
                                                    </Typography> 
                                                </Grid>
                                            </Grid> 
                                                  
                                            <Typography variant="h2" className='fx-investment-account-earn'>
                                                Earn up to {props?.type === 'CASH_BUILDER_PLUS' ? DEFAULT_CONFIG['CASH_BUILDER_PLUS_INTEREST_YIELD_PERCENTAGE'] : DEFAULT_CONFIG['CASH_BUILDER_INTEREST_YIELD_PERCENTAGE']} yield*                               
                                            </Typography>        
                                        </Grid>
                                        <Grid item>
                                            <Typography  variant="h6" className={'fx-no-wrap'}>
                                                Activating your Passport {props?.type === 'CASH_BUILDER_PLUS' ? 'Cash Builder+ Account' : 'Cash Builder Account'} should be quick. We hope you enjoy the experience of our powerful unified commerce platform!
                                            </Typography>     
                                        </Grid>
                                        <Grid item>
                                            <FxButton className="fx-button fx-button-theme" id='investment-account-get-start-btn' type='submit' onClick={()=>props.onChange(2)}>Let’s Get Started</FxButton>
                                        </Grid>  
                                    </Grid>                                               
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid item className='fx-investment-account-right-logo'>
                                        <LogoAccount /> 
                                    </Grid>                                
                                </Grid>
                            </Grid>  
                        </FxCardBody>
                    </FxCard>
                </Grid>     
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Get everything that your bank offers and more. Experience the banking with {props?.type === 'CASH_BUILDER_PLUS' ? 'Passport Cash Builder+ Account' : 'Cash Builder Account'} to help your money work smarter.
                    </Typography>    
                </Grid>            
                <Grid item container xs={12} >
                    <FxCard className="fx-theme-passport-no-before fx-no-shadow fx-investment-account-button-card">
                        <FxCardBody className="fx-info-card">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    {offerItems.map(x=> renderOfferItem(x))}                                
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container >
                                        <Paper elevation={3} className='fx-investment-account-video'>
                                            <video controls width="100%" height="auto" autoPlay muted loop>
                                            <source src="https://prioritycommerce.com/wp-content/uploads/2023/04/Priority-Homepage-Video-30-web-1.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </Paper>
                                    </Grid>                               
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </FxCard>
                </Grid>   
            </Grid>                    
        </Grid>
    )
}