/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, IconButton, Modal } from '@mui/material';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';

export type FxModalBaseViewProps = {
    closeDisabled: boolean;
    className?: string;
    open: boolean;
    onClose(): void;
}

/**
 * Component for rendering generic modal.
 * For internal purposes only!
 */
export const FxModalBaseView: React.FC<FxModalBaseViewProps> = ({open, closeDisabled, className, children, onClose}) => {

    const handleModalClose = useCallback((_, reason: 'backdropClick' | 'escapeKeyDown') => {
        // handle reason if needed
        onClose();
    }, [onClose]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Modal
            id={'modal'}
            open={open}
            onClose={handleModalClose}
            disableEnforceFocus={true}
            disableAutoFocus={false}
            className={clsx('fx-modal', className)}
        >
            <div className='modal-content'>
                <div className='modal-body'>
                <Grid className='fx-padding-16-24'>
                    <Grid className="fx-modal-close-icon">
                        <IconButton
                            id='modal-close-button'
                            size='large'
                            disabled={closeDisabled}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid className='fx-margin-top-10'>
                        {children}
                    </Grid>
                </Grid>
                </div>
            </div>
        </Modal>
    );
}
