import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { CreateAddressCard } from './CreateAddress';
/**
 * This component loads the contact address create page
 */

export const CreateAddressHome: React.FC =
    () => {
     /**
         * Default params for create address
         */
            const objectParams:any={
                openFxSnackBar: false,
                isloading: false,
                submitButton: 'Add Address',
                fxSnackbarProps: {
                    severity: "" as any,
                    text: ""
                }
            }
        return (
            <Grid item container xs={12}>
                <Grid item xs={12} sm={12} >
                    <CreateAddressCard id={"create-contact-address-component"} {...objectParams} ></CreateAddressCard>
                </Grid>
            </Grid>

        )
    }
