import React, { useState } from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { AUTH_STRINGS } from '../../../constants/strings';
import { useHistory, useParams } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { Logo } from '../../Utils/Logo';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { processAPIResponse } from '../../../libs/utils/utils';
import { useMediaQuery } from 'react-responsive'
import { FxButton } from '../../Action/FxButton';
import Copyright from './Copyright';
import { useDispatch } from 'react-redux';
import FxSnackBar from '../../Utils/fx-snack-bar';

Logger.debug("SetPassword.tsx", "Set Password initializing");

interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: Set Password
 * Usage: set user password on first login
 */
const SetPassword = (props: any): JSX.Element => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const params = useParams<any>();
    const token = params.token;
    const { register, getValues, trigger, formState: { errors }, handleSubmit, control } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
    const [isloading, setIsloading] = useState(false);
    const [isTokenVerified, setIsTokenVerified] = useState(false);

    // redirected to login page if the token is not verified
    if (context?.data_state === 3 && !isTokenVerified) {
        setIsTokenVerified(true);
    }
    /**
     * Method to handle form submit event
     * @param data :form data
     */
    async function onSubmit(data: any) {
        setIsloading(true);
        let status: any = await setPassword(data, token);
        status = processAPIResponse(status)
        setIsloading(false);
        if (status.status) {
            // password set success
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'You have successfully reset your password. Please continue to login using the updated login credentials.',
            });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { "id": 'logincomponent', data: true } });
            history.push("/login");
        }
        else {
            //password set failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    };
    /**
     * Method to call set password API
     * @param paylaoddata : request payload
     * @param token:user token
     */
    async function setPassword(paylaoddata: any, token: any) {
        try {
            const httpClientnoBearer = HttpClient.getClient();
            HttpClient.unsetToken();
            const setPasswordReq: any = {};
            setPasswordReq.token = token;
            setPasswordReq.password = paylaoddata.newPassword;
            const data: any = await httpClientnoBearer.post('user/setPassword', setPasswordReq).then(response => {
                return response;
            }).catch((error) => {
                return { ...error };
            })
            return data;
        } catch (err) {
            Logger.error("SetPassword.tsx", "error", err);
            return false;
        }
    }

    // new password validation constant
    const setNewPasswordValidation = {
        required: true,
        validate: () => {
            trigger('confirmPassword');
            return true;
        },
        minLength: 8
    }
    const renderNewPasswordError = (err: InputErrType): string => {
        if (err.type === 'minLength') {
            return AUTH_STRINGS.ERRORS.SET_PASS_INVALID_LENGTH;
        }
        return err.message;
    };
    // confirm password validation constant
    const setConfirmPasswordValidation = {
        required: true,
        validate: (value: any) => {
            if (value === getValues()["newPassword"]) {
                return true;
            } else {
                return "The passwords do not match";
            }
        },
        minLength: 8
    }
    const renderConfirmPasswordError = (err: InputErrType): string => {
        if (err.type === 'minLength') {
            return AUTH_STRINGS.ERRORS.SET_PASS_INVALID_LENGTH;
        }
        return err.message;
    };


    return (
        <Grid item xs={12} container direction="row">
            <Grid item xs={12} container direction="row" alignContent="flex-start" sm={isSmallScreen ? undefined : 12}  >
                <div className="login-page">
                    <Grid xs={12} sm={11} container justifyContent="center">
                        <Grid xs={12} sm={4} className="login-page-content">
                            <div className="login-logo">
                                {/* logo */}
                                <Logo showFull />
                            </div>

                            <Grid id="email-forgot-label" container direction="row" item className="fx-login-text" justifyContent="center">Create your password</Grid>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="login-page-content-card">
                                    <div className="flex column login-page-content-card-content">
                                        <Typography id="set-password-sub-label" className="form-label label-color">Please choose a password for your account and confirm.
                                        </Typography>
                                        <br />
                                    </div>
                                    <div className="flex column login-page-content-card-content">
                                        <FxTextEdit register={{ ...register("newPassword") }} control={control} rules={setNewPasswordValidation} className={errors.newPassword ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" id="password-change-newPassword" name="newPassword" label="New Password" passwordIcon={true} />
                                        <div className={'error-message'}>
                                            {errors.newPassword && renderNewPasswordError(errors.newPassword)}
                                        </div>
                                    </div>
                                    <Grid container > <Grid item>&nbsp;</Grid></Grid>
                                    <div className="flex column login-page-content-card-content">
                                        <FxTextEdit register={{ ...register("confirmPassword") }} control={control} rules={setConfirmPasswordValidation} className={errors.confirmPassword ? "border-error-input fx-input-edit" : "fx-input-edit"} type="password" id="password-change-confirmPassword" name="confirmPassword" label="Confirm Password" passwordIcon={true} />
                                        <div className={'error-message'}>
                                            {errors.confirmPassword && renderConfirmPasswordError(errors.confirmPassword)}
                                        </div>
                                    </div>
                                    <Grid container > <Grid item>&nbsp;</Grid></Grid>

                                    <div className="flex login-buttonarea">
                                        <FxButton
                                            disableRipple={false}
                                            className="fx-button login-signin"
                                            id="login-signin"
                                            type="submit"
                                        >
                                            {isloading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                'Continue'
                                            )}

                                        </FxButton>
                                    </div>
                                </div>
                            </form>
                            {/* footer */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="login-page-bottomstatic" container>  <Copyright /> </Grid>

                </div>
            </Grid>
        </Grid>
    );
};

export default SetPassword;
