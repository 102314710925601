import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import UIFeatureImpl from '../../libs/services/uiFeatures';
import { Logger } from '../../libs/utils/logger';
import { getKey } from '../../libs/utils/storageManager';
import { AgreementsEnum } from '../../types/common.interfaces';
import { AgreementTypeEnum } from '../../types/common.interfaces';
import { FxLink } from '../Action/FxLink';
import HttpClient from '../../libs/utils/httpClient';

const httpClient = HttpClient.externalUrl({ responseType: 'blob' });

interface IConfigurationData  {
  name: string;
  value: string;
}

type AgreementType  = 'DebitCard' | 'RateCard' | 'DepositAccountRateCard' | 'PassportAccount' | 'CashBuilderPlusAccountAgreement' | 'CashBuilderAccountAgreement' | 'CashBuilderRateCard' | 'CashBuilderPlusRateCard';

type ReqAgreementType = AgreementTypeEnum.CASH_BUILDER_ACCOUNT_AGREEMENT | AgreementTypeEnum.CASH_BUILDER_PLUS_ACCOUNT_AGREEMENT | AgreementTypeEnum.PASSPORT_AGREEMENT | AgreementTypeEnum.RATE_CARD_AGREEMENT | AgreementTypeEnum.DEBIT_CARD_AGREEMENT;

interface IAgreement {
  name: string;
  title: string;
  type: ReqAgreementType;
  key: AgreementType;
}

interface IFinalAgreementDetails {
  url: string;
  title: string;
  type: ReqAgreementType | '';
}

export interface IFileInfo {
  base64: string;
  fileType: ReqAgreementType;
}

interface IFxAgreementLinkCard {
  onFileInfoReady?(fileInfoArray:IFileInfo[]): void;
  onRead?(): void;
  agreementTypes: AgreementType[];
}


/**
 * Component: FxAgreementLinkCard fetches agreement data,
 converts it to base64, and displays it as links. It uses the `agreementTypes`
 prop to determine which agreements to fetch. When the base64 data is ready,
 it calls the `onFileInfoReady` function with the data as an argument.
 */


export const FxAgreementLinkCard: React.FC<IFxAgreementLinkCard> = (props) => {
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const [agreementData, setAgreementData] = useState<IFinalAgreementDetails[]>([]);
  const [fileInfos, setFileInfos] = useState<IFileInfo[]>([]);
  const [readAgreements, setReadAgreements] = useState<Record<string, boolean>>({});

  /**
   * Agreement types
   */
  const agreements: IAgreement[] = [
     {
      'key': 'PassportAccount',
      'name': AgreementsEnum.ACCOUNT_AGREEMENT,
      'title': 'Passport Account Agreement',
      'type': AgreementTypeEnum.PASSPORT_AGREEMENT
     },
    {
      'key': 'RateCard',
      'name': businessCustomer ? AgreementsEnum.COMMERCIAL_RATE_CARD :  AgreementsEnum.CONSUMER_RATE_CARD,
      'title': `Rate Card`,
      'type': AgreementTypeEnum.RATE_CARD_AGREEMENT
    },
    {
      'key': 'DebitCard',
      'name': businessCustomer ? AgreementsEnum.COMMERCIAL_DEBIT_CARD : AgreementsEnum.CONSUMER_DEBIT_CARD,
      'title': `Passport ${businessCustomer ? 'Commercial' : 'Consumer'} Debit Card Cardholder Agreement`,
      'type': AgreementTypeEnum.DEBIT_CARD_AGREEMENT
    },
    {
      'key': 'DepositAccountRateCard',
      'name': businessCustomer ? AgreementsEnum.COMMERCIAL_RATE_CARD : AgreementsEnum.CONSUMER_RATE_CARD,
      'title': `Passport ${businessCustomer ? 'Commercial' : 'Consumer'} Deposit Account Rate Card`,
      'type': AgreementTypeEnum.RATE_CARD_AGREEMENT
    },
    {
      'key': 'CashBuilderPlusAccountAgreement',
      'name': AgreementsEnum.ACCOUNT_AGREEMENT,
      'title': 'Passport Account Agreement',
      'type': AgreementTypeEnum.CASH_BUILDER_PLUS_ACCOUNT_AGREEMENT
    },
    {
      'key': 'CashBuilderAccountAgreement',
      'name': AgreementsEnum.ACCOUNT_AGREEMENT,
      'title': 'Passport Account Agreement',
      'type': AgreementTypeEnum.CASH_BUILDER_ACCOUNT_AGREEMENT
    },
    {
      'key': 'CashBuilderRateCard',
      'name': AgreementsEnum.CASH_BUILDER_RATE_CARD,
      'title': `Rate Card`,
      'type': AgreementTypeEnum.RATE_CARD_AGREEMENT
    },
     {
      'key': 'CashBuilderPlusRateCard',
      'name': AgreementsEnum.CASH_BUILDER_PLUS_RATE_CARD,
      'title': `Rate Card`,
      'type': AgreementTypeEnum.RATE_CARD_AGREEMENT
    },
  ]

  /**
   * useEffect to call onFileInfoReady when fileInfos is ready
   */
  useEffect(() => {
    if (fileInfos.length > 0) {
      props.onFileInfoReady && props.onFileInfoReady(fileInfos);
    }
  }, [fileInfos]);

  /**
   * Function to handle agreement click
   * @param agreementType
   */
  const handleAgreementClick = (agreementType: string) => {
    // Create a new object that represents the updated state
    const updatedReadAgreements = { ...readAgreements, [agreementType]: true };

    // Update read status of the read agreement
    setReadAgreements(updatedReadAgreements);

    // Check if all agreements have been read using the updated state
    const allAgreementsRead = agreementData.every(agreement => updatedReadAgreements[agreement.type]);

    // Call props.onRead if all agreements have been read
    if (allAgreementsRead) {
      props.onRead && props.onRead();
    }
  }
  /**
   * function to get customer configuration
   * @returns data
   */
  async function getCustomerConfiguration() {
    try {
      const accountAgreementConfigurations = getKey('accountAgreementConfigurations') || [];
      const debitCardAgreementConfigurations = getKey('debitCardAgreementConfigurations') || [];
      const agreementConfigurations: IConfigurationData[] = [...accountAgreementConfigurations, ...debitCardAgreementConfigurations];
      const finalAgreements: IFinalAgreementDetails[] = props.agreementTypes.map((type) => {
        const agreement = agreements.find(agreement => agreement.key === type);
        const agreementData = agreementConfigurations?.find(item => item.name === agreement?.name);
        return {
          url: agreementData?.value || '',
          title: agreement?.title || '',
          type: agreement?.type || ''
        }
      });
      setAgreementData(finalAgreements);
    } catch (err) {
      Logger.error("FxAgreementLinkCard.tsx", "getCustomerConfiguration", "error", err);
    }
  }


  /**
   * getCustomerConfiguration on mount
   */
  useEffect(()=>{
    getCustomerConfiguration();
  },[]);


  /**
   * Function to convert pdf to base64
   * @param pdf :content
   * @param type :ReqAgreementType
   */
  function convertPdfToBase64(pdf: Blob, type: ReqAgreementType) {
    const fileReader = new FileReader();
    //Check File is not Empty
    if (pdf) {
      // Onload of file read the file content
      fileReader.onload = function () {
        if(!fileReader.result) {
          return;
        }
        const base64Content = (fileReader.result as string)?.replace?.('data:application/pdf;base64,', '');
        if(base64Content) {
          setFileInfos(prevFileInfos => [...prevFileInfos,
            {
              base64: base64Content,
              fileType: type
            }
          ]);
        }
      };
      // Convert data to base64
      fileReader.readAsDataURL(pdf);
    }
  }

  useEffect(() => {
    /**
     * Function to get pdf File
     */
    const getPdfFile = async (agreementUrl:string, type: ReqAgreementType) => {
      try {
        const response = await httpClient.get(agreementUrl);
        convertPdfToBase64(response?.data, type);
        return response;
      } catch (err) {
        Logger.error('FxAgreementLinkCard.tsx', 'getPdfFile', 'error', err);
      }
    };
    // Call getPdfFile for each agreement
    if (agreementData?.length) {
      agreementData.forEach((agreement) => {
          const { url, type} = agreement || {};
          url && type && getPdfFile(url, type);
        }
      );
    }
  }, [agreementData]);

  if(!agreementData?.length) {
    return null;
  }

  return (
    <Grid className={'fx-agreement-link-card'}>
      <Typography>Click here to view the&nbsp;
        {agreementData.map((agreement, index) => {
          return (
            <React.Fragment key={index}>
              <FxLink target={'_blank'} href={agreement.url} onClick={()=>handleAgreementClick(agreement.type)}>
                {agreement.title}
              </FxLink>
              {index !== agreementData.length - 1 && <>&nbsp;&&nbsp;</>}
            </React.Fragment>
          );
        })}
        .
      </Typography>
    </Grid>
  )
}