import React, { useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxAutoComplete } from '../../Input/FxAutoComplete/FxAutoComplete';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import { FxExternalAccountNumberEdit } from '../../Input/FxExternalAccountNumber/FxExternalAccountNumberEdit';
import { FxSkeltonList } from '../Cards/FxSkelton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useMediaQuery } from 'react-responsive';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { clean, cleanWithEmptyValue, convertToPhoneRequestFormat, findAddressValidations, handleKeyDownChange, handleZipChange, renderCityNameError, renderEAAddressLine1Error, renderEAAddressLine2Error, setCityNameValidation, setPhoneValidationOptions, setZipValidationOptions } from '../../Utils/CommonBaseClass';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import usa_state from '../../Utils/usa_states.json';
import { find } from 'lodash';

const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("CreateExternalAccount.tsx", "create external account new initializing");
/**
 * Component: CreateExternalAccount
 * Usage: create external account
 */
// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}
export const CreateExternalAccount: React.FC<any> = React.memo( 
    (props) => {
        let context: any;
        let externalAccountId: any = '';
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, resetField, watch } = useForm();
        const params = useParams<any>();
        let ewsDefault: any = false;
        let preNoteDefault: any = false;
        let microDefault: any = false;
        const accountValidationDefault: any = [];
        const createType = params.type;
        if (context?.data?.body?.id) {
            externalAccountId = context.data.body.id;
        }
        const [typeSelected, setMethod] = useState(context?.data?.body?.card ? 'CARD' : 'BANK_ACCOUNT');
        const accountNumber = context?.data?.body?.accountNumber ? '**' + context.data.body.accountNumber : '';
        const [isLoading, setIsLoading] = useState(false);
        const [isDataLoaded, setIsDataLoaded] = useState(false);
        const [submitButton, setSubmitButton] = useState(isSmallScreen ? 'Submit' : 'Add External Account');
        const [isEdit, setIsEdit] = useState(!!props?.source);
        const [isActive, setIsActive] = useState(false);
        const [processApi, setProcessApi] = useState(false);
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        const individualCustomer = uiFeat.getIndividualCustomerFeature().available

        /**
         * Method useEffect functions calling before component loading
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            if (!isEdit) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Add External Account' + accountNumber,backButton:'/externalaccounts' } }});
                setValue('type', 'BANK_ACCOUNT');
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const history = useHistory();

        const accountTypeData = [
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' }
        ];
        const accountPurposeData = [
            { label: 'Consumer', value: 'CONSUMER' },
            { label: 'Corporate', value: 'CORPORATE' }
        ];

        // API request for bank list
        const bank_src = {
            url: "list/bank",
            method: "POST",
            expiry: 300,
            baseUrl: true,
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters": [
                    ]
                }
            }
        };


        if (!isEdit && !processApi) {
            setProcessApi(true)
        }
        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_NOT_VALID_LENGTH;
            }
            return err.message;
        };

        const renderNameError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
            }
            return err.message;
        };
        // routing number validation constant
        const setRoutingNumberValidation = {
            required: (typeSelected === 'BANK_ACCOUNT' || !isActive),
            minLength: 9,
            maxLength: 9,
            pattern: {
                value: /^[0-9-_&,.]*$/,
                message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
            },
        }
        // routing number validation error messages
        const renderRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };
        // wire routing number validation constant
        const setWireRoutingNumberValidation = {
            required: false,
            minLength: 9,
            maxLength: 9,
            pattern: {
                value: /^[0-9-_&,.]*$/,
                message: AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_REGEXP,
            },
        }
        //wire routing  number validation error messages
        const renderWireRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };
        // account number validation constant
        const setAccountNumberValidation = {
            required: (typeSelected === 'BANK_ACCOUNT' && !isActive),
            maxLength: 17
        }

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/externalaccount/view/' + context.data.body.id)
            } else if (createType === 'my-account-ach') {
                history.push('/transaction/collect/add/my-account-ach')
            } else if (createType === 'send-ach') {
                history.push('/transaction/send/add/ach')
            } else if (createType === 'send-wire') {
                history.push('/transaction/send/add/wire')
            } else {
                history.push('/externalaccounts')
            }
        };
        /**
         * Method to handle changes on event
         * @param event : event type
         */
        const handleChange = (event: any) => {
            if (event.target.name === 'type') {
                setMethod(event.target.value);
                clearErrors();
            }
        }

        /**
         * Method to check if account validation default value available
         */
        if (context?.data?.body?.accountValidation) {
            try {
                context.data.body['accountValidation'].forEach((item: any) => {
                    if (item.prenote) {
                        preNoteDefault = true;
                        accountValidationDefault.push('prenote')
                    }
                    else if (item.ews) {
                        ewsDefault = true;
                        accountValidationDefault.push('ews')
                    }
                });

            }
            catch (e) { }
        }
        /**
         * Method to check if data available in context for edit and set default values
         *  */
        if (context?.data?.body && !isDataLoaded) {
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Edit External Account' + accountNumber,backButton:'/externalaccount/view/' + context?.data?.body?.id } }});
            setValue('type', context.data.body ? 'BANK_ACCOUNT' : 'CARD')
            setValue('routingNumber', context.data.body?.routingNumber)
            setValue('wireRoutingNumber', context.data.body?.wireRoutingNumber)
            setMethod(context.data.body ? 'BANK_ACCOUNT' : 'CARD')
            setSubmitButton('Save');
            setValue('accountValidation', accountValidationDefault);
            setIsDataLoaded(true); // set as true if edit data available
            setIsEdit(true);
            if (context.data.body.status === 'ACTIVE') {
                setIsActive(true);
            }
        }

        /**
         * Method to transform routing number data as dropdown options
         * @param data
         */
        function routingNumberTransformer(data: any) {
                return data?.map((item: any) => {
                    return  { label: item.routingNumber + ' (' + item.name + ')', value: item.routingNumber };
                }) || [];
        }
        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            setIsLoading(true)
            let req: any = {};
            let status: any;
            let ewsVal: boolean = false;
            const accountValidationArray: any = [];
            if (typeSelected === 'BANK_ACCOUNT') {
                let holderAddress = {
                    "addressLine1": data.addressLine1,
                    "addressLine2": data.addressLine2,
                    "city": data.city,
                    "state": data.state,
                    "zip": data.zip
                }
                cleanWithEmptyValue(holderAddress);
                if (!isEdit) {
                    data['accountValidation']?.forEach((item: any) => {
                        if (item === 'ews') {
                            ewsVal = true;
                        }
                    });
                    req = {
                        "type": data.accountType,
                        "holderName": data.holderName,
                        "accountNumber": data.accountNumber,
                        "routingNumber": data.routingNumber,
                        "wireRoutingNumber": data.wireRoutingNumber,
                        "holderType": data.holderType,
                        "validateAccount": accountValidationArray,
                        "microDeposit": 'ALWAYS',
                         "holderPhone": convertToPhoneRequestFormat(data.holderPhone),
                    }
                    if (holderAddress && Object.keys(holderAddress).length > 0 && Object.values(holderAddress).some(value => value !== null)) {
                        req['holderAddress'] = holderAddress;
                    }
                    clean(req);
                } else {
                    accountValidationArray[0] = {}
                    data['accountValidation']?.forEach((item: any) => {
                        if (item === 'ews') {
                            ewsVal = true;
                        }
                    });
                    if (!microDefault) {
                        req['microDeposit'] = "ALWAYS"
                    }
                    if (!preNoteDefault) {
                        req['prenote'] = "ALWAYS"
                    }
                    if (!ewsDefault) {
                        accountValidationArray[0]['ews'] = {}
                        accountValidationArray[0]['ews'] = ewsVal
                    }
                    req = {
                        "type": data.accountType,
                        "holderName": data.holderName,
                        "accountNumber": data.accountNumber,
                        "routingNumber": data.routingNumber,
                        "wireRoutingNumber": data.wireRoutingNumber,
                        "holderType": data.holderType,
                        "validateAccount": accountValidationArray,
                        "holderPhone":convertToPhoneRequestFormat(data.holderPhone),
                    }
                    if (holderAddress && Object.keys(holderAddress).length > 0 && Object.values(holderAddress).some(value => value !== null)) {
                        req['holderAddress'] = holderAddress;
                    }
                    cleanWithEmptyValue(req);
                    if (ewsDefault) {
                        delete req['validateAccount'][0]['ews'];
                    }
                    if (preNoteDefault) {
                        delete req['prenote'];
                    }
                    if (microDefault) {
                        delete req['microDeposit'];
                    }
                }
                if (data.routingNumber && data.routingNumber.value) {
                    req.routingNumber = data.routingNumber.value;
                }
                if (data.wireRoutingNumber && data.wireRoutingNumber.value) {
                    req.wireRoutingNumber = data.wireRoutingNumber.value;
                }
                if (isActive) {
                    delete req.accountNumber;
                    delete req.purpose;
                    delete req.type;
                    delete req.routingNumber;
                    delete req.accountValidation;
                    if (!context?.data?.body?.wireRoutingNumber) {
                        req.wireRoutingNumber = data.wireRoutingNumber;
                    } else {
                        delete req.wireRoutingNumber;
                    }
                }
            }
        

            if (data.externalId !== '') {
                req['externalId'] = data.externalId
            }

            let successMessage = 'External Account Created Successfully!';
            if (context?.data?.body?.id) {
                status = await updateExternalAccount(req);
                successMessage = 'External Account Updated Successfully!';
            } else {
                status = await createExternalAccount(req);
                if (status?.headers?.url) {
                    externalAccountId = (status.headers.url).split('/').pop();
                }
            }
            status = processAPIResponse(status)
            setIsLoading(false);
            if (status.status) {
                dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'ea-upload', 'files': [] } });
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push((isEdit ? '/externalaccount/view/' : '/thanks/manage/externalaccount/') + externalAccountId);
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }


        /**
         * Method to call api  for create externalAccount
         * @param payloadData : request payload
         */
        async function createExternalAccount(payloadData: any) {
            try {

                const url =  '/externalAccount'

                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateExternalAccount.tsx", "error", err);
                return err;
            }
        }
        /**
         * Method to call api  for update externalAccount
         * @param payloadData : request payload
         */
        async function updateExternalAccount(payloadData: any) {
            try {
                const url = '/externalAccount/id/'

                const data: any = await httpClient.post(getCustomerUrl(url + context?.data?.body?.id,false), payloadData).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateExternalAccount.tsx", "error", err);
                return false;
            }
        }
        /**
         * Method to handle error
         * @param err
         * @returns
         */
        const renderZipError = (err: InputErrType): string => {
            return err.message;
        };

        /**
         * Method handles reset the value of the wire routing number
         * @param event : autocomplete selection/unselection event parameter
         */
        const handleWireRoutingNumberChange = (event: any) => {
            event === null && setValue('wireRoutingNumber','');
        }
        
        

        return (
            <Grid container id="create-external-account-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="create-external-account-first-grid" item xs={12} container justifyContent="center">
                    <Grid id="create-external-account-sub-grid" item xs={12} sm={isSmallScreen ? 8 : 6} >
                            <div className="fx-form-edit-profile flex column">
                                {!isDataLoaded && isEdit && <FxSkeltonList height="15rem" />}
                                {(isDataLoaded || !isEdit) && <form id="create-external-account-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-external-account-form-card" className="fx-theme-passport fx-create-external-account">
                                        <FxCardBody id="create-external-account-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                <Grid xs={12} sm={12}>
                                                <Typography className='fx-create-external-account-sub-title'>BASIC ACCOUNT INFORMATION</Typography>
                                                </Grid>
                                               
                                                {typeSelected === 'BANK_ACCOUNT' && <>
                                                <Grid item xs={12} sm={6}>
                                                    <FxMaterialSelect register={{ ...register("holderType") }} className={errors.accountPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) }} value={context?.data?.body?.holderType ? context.data.body.holderType : 'CORPORATE'} control={control} onChange={handleChange} id="create-external-account-form-card-account-purpose" name="holderType" data={accountPurposeData} label="Holder Type*" readOnly={isActive} setValue={setValue} />
                                                </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="create-external-account-form-card-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined" defaultValue={context?.data?.body?.holderName ? context.data.body.holderName : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderName && renderNameError(errors.holderName)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxPhoneEdit register={register } className={errors.holderPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-external-account-form-card-holderphone-textbox" label="Holder Phone" name="holderPhone" variant="outlined" 
                                                        rules={setPhoneValidationOptions(false)} disableFlag={true}
                                                        setValue={setValue} defaultValue={context?.data?.body?.holderPhone ? context.data.body.holderPhone : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderPhone && renderNameError(errors.holderPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item  xs={12} sm={12} spacing={2}>
                                                        <fieldset className='fx-create-external-account-address-container'>
                                                        <legend>Holder Address</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: findAddressValidations(watch),maxLength:35 }} id="create-external-account-form-card-addressline1-textbox" label="Address Line 1" name="addressLine1" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine1 ? context.data.body.holderAddress.addressLine1 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine1 && renderEAAddressLine1Error(errors.addressLine1)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false,maxLength:35 }} id="create-external-account-form-card-addressline2-textbox" label="Address Line 2" name="addressLine2" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine2 ? context.data.body.holderAddress.addressLine2 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine2 && renderEAAddressLine2Error(errors.addressLine2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setCityNameValidation(findAddressValidations(watch))} id="create-external-account-form-card-city-textbox" label="City" name="city" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.city ? context.data.body.holderAddress.city : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: findAddressValidations(watch) }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-external-account-form-card-state-textbox" name="state" data={usa_state} label="State" value={context?.data?.body?.holderAddress?.state ? context.data.body.holderAddress.state : ''}
                                                            setValue={setValue} readOnly={false} placeholder="Select" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(findAddressValidations(watch))} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-external-account-form-card-state-textbox" name="zip" label="Zip" defaultValue={context?.data?.body?.holderAddress?.zip ? context.data.body.holderAddress.zip : ''} isEditable={true} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors); }} onKeyDown={(e: any) => {  handleKeyDownChange(e, setValue);}}/>
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderZipError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                        </fieldset>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant='h5'>Holder’s address and phone number are mandatory to send a wire payment to this account.</Typography>
                                                   </Grid>
                                                   <Grid xs={12} sm={12}>
                                                <Typography className='fx-create-external-account-sub-title'>BANK ACCOUNT INFORMATION</Typography>
                                                </Grid>
                                                    {individualCustomer && <> 
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountType") }} className={errors.accountType ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) }} control={control} onChange={handleChange} id="create-external-account-form-card-account-type" name="accountType" data={accountTypeData} value={context?.data?.body?.type ? context.data.body.type : 'SAVINGS'} label="Account Type*" readOnly={isActive} setValue={setValue} />
                                                    </Grid>
                                                        </>}
                                                    {businessCustomer && <> <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountType") }} className={errors.accountType ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: (typeSelected === 'BANK_ACCOUNT' || !isActive) }} control={control} onChange={handleChange} id="create-external-account-form-card-account-type" name="accountType" data={accountTypeData} value={context?.data?.body?.type ? context.data.body.type : 'CHECKING'} label="Account Type*" readOnly={isActive} setValue={setValue} />
                                                    </Grid>
                                                    </>}
                                                    <Grid item xs={12} sm={6}>
                                                        <FxExternalAccountNumberEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} error={errors?.accountNumber} control={control} rules={setAccountNumberValidation} id="create-external-account-form-card-account-number-textbox" label="Account Number *" name="accountNumber" variant="outlined" defaultValue={context?.data?.body?.accountNumberLast4 ? '*****' + context.data.body.accountNumberLast4 : ''} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                                        <div className={'error-message'}>
                                                            {errors.accountNumber && renderAccountNumberError(errors.accountNumber)}
                                                        </div>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={6}>
                                                        <FxLabelView className="fx-label-top" id="create-external-account-form-card-routing-number-label">Routing Number*</FxLabelView>
                                                        < FxAutoComplete register={{ ...register("routingNumber") }} control={control} rules={setRoutingNumberValidation} id="create-external-account-form-card-routing-number" name="routingNumber" source={bank_src} searchBy={'searchPattern'} className={errors.routingNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} onChange={handleChange} value={context?.data?.body?.routingNumber ? { label: context.data.body.routingNumber, value: context.data.body.routingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} isEditable={!(isActive && context?.data?.body?.routingNumber)} />
                                                        <div className={'error-message'}>
                                                            {errors.routingNumber && renderRoutingNumberError(errors.routingNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography className="fx-label-top" id="create-external-account-form-card-wire-routing-number-label">Wire Routing Number</Typography>
                                                        < FxAutoComplete register={{ ...register("wireRoutingNumber") }} control={control} rules={setWireRoutingNumberValidation} id="create-external-account-form-card-wire-routing-number" name="wireRoutingNumber" source={bank_src} searchBy={'searchPattern'} onChange={handleChange} value={context?.data?.body?.wireRoutingNumber ? { label: context.data.body.wireRoutingNumber, value: context.data.body.wireRoutingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} isEditable={!(isActive && context?.data?.body?.wireRoutingNumber)} setParam={handleWireRoutingNumberChange}/>
                                                        <div className={'error-message'}>
                                                            {errors.wireRoutingNumber && renderWireRoutingNumberError(errors.wireRoutingNumber)}
                                                        </div>
                                                    </Grid>
                                                    {!isEdit && <Grid item xs={12} sm={12}>
                                                        <Typography variant='h5'>You will receive penny credits into your external account, which will be used to validate that account belongs to you.</Typography>
                                                   </Grid>}
                                                </>}
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </FxCardBody>
                                    <FxCardFooter id="create-external-account-form-card-footer" className="fx-footer">
                                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                            <FxButton variant="contained"
                                                className="fx-button fx-button-cancel"
                                                id="ea-modal"
                                                onClick={handleClose}>
                                                Cancel
                                                </FxButton>
                                            <span className="fx-padding-right-16" />
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button fx-button-theme"
                                                id={"create-external-account-form-card-" + submitButton + "-button"}
                                                type="submit"
                                            >
                                                {isLoading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                        submitButton
                                                    )}
                                            </FxButton>
                                        </Grid>
                                    </FxCardFooter>
                                </FxCard>
                            </form>}
                            </div>
                    </Grid>
                </Grid>

            </Grid>
        )
    });