import React from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as CardPaymentsIllus } from '../../../assets/svg/card-payments-illustration.svg';
import { ReactComponent as CardPaymentsIllusGreen } from '../../../assets/svg/card-payments-illustration-green.svg';
import { getKey } from '../../../libs/utils/storageManager';
import { LogoAccount } from '../../Utils/Logo';
import { Logo } from '../../Utils/Logo';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * Component used inside the right drawer as a left layout for creating Direct Funded Merchants
 */

interface IFxBaseLayout {
    id?: any;
    type?: 'primary' | 'secondary';
    layoutHeader: React.ReactElement;
}

export interface IInfoItem {
    class?:any;
    header?: string;
    text: string;
    Icon: any;
}

export const FxMerchantCreationDrawerLayout: React.FC<IFxBaseLayout> = React.memo(({ layoutHeader, type = 'primary' }) => {
    const uiFeat = UIFeatureImpl.getInstance();
    const fastFundAvailable = uiFeat.getFasterFunding().available;

    return (
        <Grid container className={'fx-drawer-left-layout fx-merchant-creation-drawer-layout'}>
            <Grid container item className={'fx-merchant-creation-drawer-layout-grid'}>
            <Grid item xs={12} className={'fx-left-layout-header'}>
                <Grid item xs={2} className={'fx-logo'} marginRight={'1.25rem'}><LogoAccount /></Grid>
                <Grid item xs={10}>  
                    <h2>Welcome!</h2>
                    <h3>{getKey('CustomerName')}</h3>
                </Grid>
            </Grid>

            <Grid item className={'fx-left-layout-passport'}>
                <h2>Accept Card&nbsp;</h2>
                <h2>Payments on your</h2>
                <div>
                    <Logo />
                </div>
                <h2>Account</h2>
            </Grid>

            <Grid item className={'fx-left-layout-image'} >
                <CardPaymentsIllus />
            </Grid>
            
            <Grid item className={'fx-left-layout-footer'}>
                <p>Accept all Credit and Debit Card payments from your account in a few easy steps.</p>
                <hr/>
            </Grid>
            </Grid>
        </Grid>
    )
})