import React, { useCallback } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as VerificationIcon } from '../../../assets/svg/otp-verification-icon.svg';
import { ReactComponent as DividerIcon } from "../../../assets/svg/divider-icon.svg";
import { FxDateEdit } from "../../Input";
import { clean } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { getKey } from "../../../libs/utils/storageManager";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import FxSnackBar from "../../Utils/fx-snack-bar";
const httpClient = HttpClient.getClient();

Logger.debug("FreezeDebitCard.tsx", "Freeze Debit Card initializing");
/**
 * This component handles the Freeze action of the Debit Card
 */
export const FreezeDebitCard: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, control, setValue, formState: { isSubmitting, errors } } = useForm();
  const [commentRequired, setCommentRecuired] = React.useState(false);

  const { debitCardId, setSuccess, setTitle, setDescription, setBtnText } = props.metadata

  /**
   * Async function handles the form submission
   */
  async function onSubmit(data: any) {
    let status: any;
    let request: any;

    request = {
      "reason": data.freezeReason ,
      "comment": data.comment,
      "freezeTill": data.freezeTill
    };

    status = await freezeDebitCard(clean(request));
    status = processAPIResponse(status)

    if(status.status){
      setTitle('Request Sent'); 
      setDescription('Your Request to freeze card is placed successfully.');
      setSuccess(true);
      setBtnText('Close');
      props.onClose();
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
  * Method to handle changes on event
  * @param event : event type
  */
  const handleChange = useCallback((event: any) => setCommentRecuired(event?.target.value === 'OTHERS'), []);

  /**
   * Method to call api for Freezing Debit Card
   * @param payloadData : request payload
   */
  async function freezeDebitCard(payloadData: any) {
    try {
      const url =
        "/account/id/" +
        getKey("selectedAccount")?.id +
        "/debitCard/id/" +
        debitCardId +
        "/freeze";
      const data: any = await httpClient
        .post(getCustomerUrl(url, false), payloadData);
      return data;
    } catch (err) {
      Logger.error("FreezeDebitCard.tsx", "error", err);
      return err;
    }
  }

  /**
   * Validations for Freeze Till Date field
   */
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const scheduleDateValidation = {
    required: true,
    minDate: tomorrow,
  };

  // Freeze Card options data
  const freezeOptions = [
    { label: "Not Able To Locate", value: "NOT_ABLE_TO_LOCATE" },
    {
      label: "Suspected Fraudulent Activity",
      value: "SUSPECTED_FRAUDULENT_ACTIVITY",
    },
    { label: "Temporarily Suspend", value: "TEMPORARILY_SUSPEND" },
    { label: "Others", value: "OTHERS" },
  ];

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FxCard className="fx-freeze-debit-card-modal">
          <FxCardHeader id="replace-debit-card-header" className="fx-card-header">
            <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  <VerificationIcon />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="fx-replace-debit-card-modal-title" align="center" className="fx-replace-debit-card-modal-title" >
                    Freeze Card
                  </Typography>
                </Grid>
            </Grid>
          </FxCardHeader>
          <FxCardBody id="freeze-debit-card-modal-body" className="fx-info-card">
            <Grid item xs={12} className="fx-modal-form flex column">
              <Typography id="title-delete-modal" variant="h5" className="fx-freeze-debit-card-modal-subtext">
                If you suspect your card is misplaced, freezing it will temporarily block all transactions on your card. Once your card is found, it can be unfreezed again. If your card is not found and has fraudulent activity on it, you can block & request replacement of card anytime.
              </Typography>
            </Grid>
            <Grid container className="fx-modal-form flex column">
              <Grid container xs={12} sm={12}>
                <FxMaterialSelect
                  register={{ ...register("freezeReason") }}
                  className={errors.freezeReason ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  rules={{ required: true }}
                  control={control}
                  id="freeze-debit-card-reason"
                  name="freezeReason"
                  data={freezeOptions}
                  label="Reason*"
                  setValue={setValue}
                  onChange={handleChange}
                  value={''}
                />
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxDateEdit
                  register={{ ...register("freezeTill") }}
                  className={errors.freezeTill ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  control={control}
                  rules={scheduleDateValidation}
                  id="freeze-till-date-textbox"
                  label="Freeze Till*"
                  name="freezeTill"
                  type="date"
                  variant="outlined"
                  defaultValue={""}
                  setValue={setValue}
                  disablePast={true}
                  minDate={tomorrow}
                />
              </Grid>
              <Grid container xs={12} sm={12}>
                <FxTextEdit
                  register={{ ...register("comment") }}
                  type="text"
                  passwordIcon={true}
                  className={errors.comment ? "border-error-input fx-input-edit" : "fx-input-edit"}
                  control={control}
                  rules={{ required: commentRequired }}
                  id="comment"
                  label={commentRequired ? "Comment *" :"Comment"}
                  name="comment"
                  variant="outlined"
                />
              </Grid>

              <span className="fx-replace-debit-card-modal-divider">
                <DividerIcon />
              </span>
            </Grid>
          </FxCardBody>
          <FxCardFooter
            id="freeze-debit-card-modal-Footer"
            className="fx-footer"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              className="fx-modal-footer"
            >
              <Button
                variant="contained"
                className="fx-button fx-button-cancel"
                id="cancel-button"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"freeze-debit-card-submit-button"}
                type="submit"
                isSubmitting={isSubmitting}
              >
                SUBMIT
              </FxButton>
            </Grid>
          </FxCardFooter>
        </FxCard>
      </form>
    </Grid>
  );
});
