import React, { useMemo } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Grid } from '@mui/material';
import moment from 'moment';
import { Logger } from '../../../libs/utils/logger';
import { CustomTooltip } from './CustomTooltip'
import clsx from "clsx";


Logger.debug("FxChart.tsx", "FxChart initializing")
export interface IChartColor {
    key: string;
    color: string;
    name: string;
}
interface IData {
  date: string;
  amount: number;
}
interface FxChartProps {
    id: string;
    chartColors?: IChartColor[];
    data: IData[];
    showXAxisTicks?: boolean;
    className?: string;
    chartVariation: string;
    isMonthly?: boolean;
}

export const FxChart: React.FC<FxChartProps> = (props) => {
  const {id,chartColors, chartVariation, data, showXAxisTicks, className, isMonthly} = props;

    /**
     * Method used to process the input data array by creating an object where each key is a unique date (formatted as 'MM/DD/YYYY')
     * and the value is the corresponding data object. If there are multiple data objects with the same date, only the first one is kept.
     * @param data - An array of data objects, each containing a date and an amount.
     * @returns An array of data objects with unique dates.
     */
      function processData(data: IData[]) {
        let acc: any = {};
        for (let curr of data) {
          const date = moment(curr.date).format('MM/DD/YYYY');
          if (!acc[date] && curr.amount) {
            acc[date] = curr;
          }
        }
        return Object.values(acc);
      }


    /**
     * Method used to transform the input data array into a new format that can be used for charting.
     * @returns An array of data objects with unique dates and corresponding pv values.
     */
      function dataTransformation() {
        try {
          const newData: any = [];
          let result: any;
          let obj: any;

          if (data.length) {
            if (chartVariation === '1D') {
              result = data;
            } else {
              result = processData(data);
            }

            // Convert data for chart
            result?.forEach((item: any) => {
              // Check if an object with the same date already exists in the newData array
              if (!newData.some((obj: any) => moment(obj.name).isSame(new Date(item.date), 'day'))) {
                // Format the date based on the chartVariation
                if (chartVariation === '1D') {
                  obj = {
                    name: moment(new Date(item.date)).format("MMM DD, yyyy HH:MM:SS"),
                    pv: item.amount
                  };
                } else {
                  obj = {
                    name: moment(new Date(item.date)).format("MMM DD, yyyy"),
                    pv: item.amount
                  };
                }

                // Push the new object to the newData array
                newData.push(obj);
              }
            });
          } else {
            // If the data array is empty, create a new object with the current date and null pv
            obj = {
              name: moment(new Date()).format("MMM DD, yyyy"),
              pv: null
            };
            newData.push(obj);
          }
          return newData;
        } catch (e) {
          Logger.error('FxChart.tsx', 'error', e);
        }
      }

    const colors = chartColors ||  [
        {
          key: 'pv',
          color: '#F48220',
          name: 'Balance'
        }
      ]

        /**
         * function to set graph color
         */
        function getLinearGradient() {
           return colors.map((color: any) => {
                return <linearGradient id={"color" + color.key} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color.color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color.color} stopOpacity={0} />
                </linearGradient>
            })
        }

        /**
         * function to draw area
         */
        function getArea() {
           return colors.map((color: any) => {
                const colorUrl = 'url(#color' + color.key + ')';
                return <Area type="monotone" name={color.name} dataKey={color.key} stroke={color.color} fillOpacity={1} fill={colorUrl} />
            })
        }


      /**
       * Extract x-axis label from start date and end date.
       */
      const extractXAxisLabels = (startDate: any, endDate: any) => {
        const start = moment(startDate);
        const end = moment(endDate);

        let totalDuration: number;

        if (isMonthly) {
          totalDuration = end.diff(start, 'months') + 1;
        } else {
          totalDuration = end.diff(start, 'days');
        }

        const partDuration = Math.floor(totalDuration / 3); // Calculate the duration of each part

        const parts = Array.from({ length: 2 }, (_, index) => {
          const partEnd = moment(start).add(partDuration * (index + 1), isMonthly ? 'months' : 'days');
          return partEnd.format('MMM DD, yyyy');
        });

        parts.unshift(start.format('MMM DD, yyyy'));
        parts.push(end.format('MMM DD, yyyy'));

        // Using filter to ensure unique values
        return parts.filter((value, index, arr) => arr.indexOf(value) === index);
      };

      /**
       * Get x-axis label from data start date and end date.
       */
      const xAxisTickets = useMemo(()=>{
        let returnValue:any = [];
        if(data.length){
          const startDate = data[0]?.date;
          const endDate = data[data.length - 1]?.date;
          const dates = extractXAxisLabels(startDate, endDate);
          returnValue = dates.map((x:any)=> moment(new Date(x)).format("MMM DD, yyyy"));
        }
        return returnValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data]);

        /**
         * function to draw graph
         * @returns :chart
         */
        function chart() {
            let objChart: any = {};
            let result:any=[];
            try {
                if(data){
                  result = processData(data);
                }
                const updatedNumbers = result.length > 0 ? result.map((num: any) => Number(num.amount)) : 0;
                const avg = updatedNumbers === 0 ? 0 : Math.max(...updatedNumbers);
                const yAxisTickFormatter=(value:any) => `$${abbreviateNumber(value)}`;
                objChart = <div className={clsx('fx-account-insight-graph-container', className ? className : '')}>
                  <ResponsiveContainer>
                    <AreaChart data={dataTransformation()}>
                    <defs>
                        {getLinearGradient()}
                    </defs>
                    {showXAxisTicks ?
                        <XAxis xAxisId="0" dataKey="name" tickFormatter={(tickItem:any)=> moment(tickItem).format("MMM DD")?.toUpperCase() } ticks={xAxisTickets}  />
                      :
                        <XAxis xAxisId="0" dataKey="name" tickFormatter={formatXAxis} interval={(chartVariation === "1M" || chartVariation === "1D") ? 1 : 5} />
                    }
                    <YAxis domain={[0, Math.floor(avg) + 100]} axisLine={false} tickFormatter={yAxisTickFormatter } tick={{ dx: -7 }}/>
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <Tooltip content={<CustomTooltip chartVariation={chartVariation} />} />
                    {updatedNumbers === 0 && <ReferenceLine y={0} />}
                    {getArea()}
                </AreaChart>
                </ResponsiveContainer></div>
                return objChart;
            } catch (e) {
              Logger.error('FxChart.tsx', 'error', e);
            }
        }
        /**
         * method to handle axis data
         * @param tickItem :data
         * @returns 
         */
        function formatXAxis(tickItem: any) {
            let val: any
            if (chartVariation === "1M") {
                val = moment(tickItem).format("MMM DD")
            }
            else if (chartVariation === "1D") {
                val = moment(tickItem).format("HH:MM:SS")
            }
            else{
                val = moment(tickItem).format("MMM")
            }
            return val?.toUpperCase()
        }

        /**
         * function to abbreviate number
         * @param num
         * @returns 
         */
        function abbreviateNumber(num: any) {
            const suffixes = ["", "k", "M", "B"];
            let magnitude = 0;

            while (num >= 1000 && magnitude < suffixes.length - 1) {
                num /= 1000;
                magnitude++;
            }

            const roundedNum = Math.round(num * 10) / 10;

            return roundedNum.toString() + suffixes[magnitude];
        }    

        return (
            <Grid item id={id}>
                <Grid item container justifyContent="center" className='fx-account-insight-graph-data'>
                    <Grid item container>
                        {chart()}
                    </Grid>                    
                </Grid>
            </Grid>
        );
    }