import { Card, CardContent, CardHeader, Skeleton } from '@mui/material'
import React from 'react'
/**
 * Component used to show dashboard skeleton
 */
export const DashboardSkeleton: React.FC<any> = React.memo(() => {
    return (
        <Card className='fx-skelton-fullpage'>
            <CardHeader
                avatar={
                    <Skeleton animation="wave" variant="circular" />
                }

                title={
                    <Skeleton
                        animation="wave"
                    />

                }
                subheader={
                    <Skeleton animation="wave" />

                }
            />
            <Skeleton animation="wave" variant="rectangular" />

            <CardContent>
                <React.Fragment>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </React.Fragment>

            </CardContent>
        </Card>
    )
})
