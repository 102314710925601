/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxAccountNumberView } from '../../Input/FxAccountNumber/FxAccountNumberView';
import FxTextView from '../../Input/FxText/FxTextView';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { FxAddressView } from '../../Input/FxAddress/FxAddressView';

Logger.debug("CustomerInfoCard.tsx", "Customer profile initializing")
/**
 * This component handles the display of the details in a section of the card
 */
export const FxSectionInfoCard: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        /**
         * rest the component on unmount, so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        let apiInfo: any = {};
        if (context && context.data && context.data.body) {
            apiInfo = { ...context.data.body };
        }
        if (props.data !== undefined) {
            apiInfo = props.data;
        }
        /**
         * Checking the UI is mobile or other devices
         */
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        /**
         * Method splits the path if it is not an array, compares the value with api value and set the data 
         *
         */
        function resolve(path: any, obj: any, separator = '.') {
            const properties = Array.isArray(path) ? path : path.split(separator)
            return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj)
        }
        return (
            <Grid id={props.id} container className={"fx-info-label-value-container"}>
                {!props.notiltle && (!isSmallScreen || props.title) && <Grid item xs={12}>
                    <Typography variant="h5" id={props.id + '_' + props.title} className="">{props.title} &nbsp;</Typography>
                </Grid>}
                {
                    props?.column?.map((item: any, index: any) => {
                        return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                            className={"fx-info-label-value"}>
                            <Grid xs={12} sm={5} item>{item.label}</Grid>
                            <Grid item xs={12} sm={7}>
                                {item.type === 'fri://fieldtype/default' || !item.type && <FxTextView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''}></FxTextView>}
                                {item.type === 'fri://fieldtype/accountnumber' && <FxAccountNumberView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} unMask={item.unMask ? item.unMask : false}></FxAccountNumberView>}
                                {item.type === 'fri://fieldtype/status' && <FxStatus id={"status-info-" + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} fill={true} className="fx-status"></FxStatus>}
                                {item.type === 'fri://fieldtype/date' && <FxDateTimeView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} format={item.format ? item.format : "MM/DD/YYYY"}></FxDateTimeView>}
                                {item.type === 'fri://fieldtype/currency' && <FxCurrencyView id={item.label + '-' + index} tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} prefix={item.prefix ? item.prefix : "$"}></FxCurrencyView>}
                                {item.type === 'fri://fieldtype/address' && <FxAddressView tooltip={true} value={resolve(item.value, apiInfo) ? resolve(item.value, apiInfo) : ''} className={item.className ? item.className : ''}></FxAddressView>}
                            </Grid>
                        </Grid>
                    })}


            </Grid>
        )
    })
