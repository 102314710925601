import React from 'react'
import { Logo } from '../../../components//Utils/Logo';

/**
 * Component used to draw mxm banner content for faster funding
 * @returns 
 */
export default function MxmBannerContent() {
    return (
        <p>Thanks for processing through&nbsp;<span className={'fx-bold'}>Priority MX™ Merchant!</span>&nbsp;Link your&nbsp;&nbsp;<Logo />&nbsp;&nbsp;account to MX™ Merchant now & Opt in for Funding via Passport.</p>
    )
}
