import React, { useEffect } from 'react'; // we need this to make JSX compile
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { RegisterComponent } from '../../../libs/saga/dataSaga';


Logger.debug("MerchantInfoCard.tsx", "Merchant InfoCard initializing");
/**
 * Component: Merchant Account Card
 * Usage: To display merchant account information
 */

export const MerchantAccountCard: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const merchant_account_information_section_left = [
            {
                label: 'Account Number',
                value: 'accountNumber',
                type: 'fri://fieldtype/accountnumber'
            },
            {
                label: 'Routing number',
                value: 'routableAccount.routingNumber'
            }
        ];
        // declare merchant information right section columns
        const merchant_account__information_section_right = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
        ];
       
        
        return (
            <>
                    {context?.data?.body && <FxInfoCard id={"info-card-"+props.id} title="PASSPORT ACCOUNT INFORMATION" leftcolumn={merchant_account_information_section_left} rightcolumn={merchant_account__information_section_right} data={context.data.body} />}
            </>
        )
    })
