import React, { useEffect } from "react";
import { TextField, Autocomplete, Chip } from "@mui/material";
import { Controller } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import CloseIcon from '@mui/icons-material/Close';
import { makeArrayUnique } from "../../Utils/CommonBaseClass";

/**
 * Component :FxTextEditWithChips
 * Usage     : used for Text editor with chips.
 */
Logger.debug("FxTextEditWithChips.tsx", "Fx Text Edit With Chips initializing");

export const FxTextEditWithChips: React.FC<any> = (props) => {
  const { name, defaultValue, control, rules, label, className, setValue } = props;

  /**
   * Method to set defaultValue
   */
  useEffect(() => {
    setValue(name, defaultValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, defaultValue])
  
  return (
    <Controller
      name={name}
      control={control ? control : undefined}
      rules={rules ? rules : { required: false }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          clearIcon={true}
          options={[]}
          freeSolo
          multiple
          className={className}
          defaultValue={defaultValue}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          renderTags={(value, props) =>
            value.map((option, index) => (
              <Chip deleteIcon={<CloseIcon />} label={option} {...props({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onBlur={(event: any) => {
                const data: any[] = [
                  ...(value ?? []),
                  ...(event?.target?.value ? [event.target.value] : [])
                ];
                const updatedValue = makeArrayUnique(data);
                onChange(updatedValue);
              }}

            />
          )}
        />
      )}
    />
  );
};