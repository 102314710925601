import React from 'react'
import { Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as ThanksIcon } from '../../../assets/svg/successful-icon.svg';  

Logger.debug("FxSuccessCard.tsx", "FxSuccess Card initializing")

/**
 * This component handles the Enabling of Physical Card
 */
export const FxSuccessCard: React.FC<any> = (props) => {
        
    const { title, description, handleClick, buttonText } = props.metadata;

    return (
        <Grid>
            <FxCard className='fx-digital-first-card-modal'>
                <FxCardHeader id="fx-success-card-modal-header" className="fx-card-header fx-modal-header">
                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                        <Grid item container xs={12} justifyContent="center">
                            <ThanksIcon />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography id="fx-success-card-modal-title" align="center" className="fx-enable-physical-card-modal-title" >
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="fx-success-card-modal-body" className="fx-info-card">
                    <Grid container item xs={12} className="fx-modal-form flex column" justifyContent={"center"}>
                        <Typography id="fx-success-card-modal-subtext" variant="h5" paddingBottom='0.75rem'>
                            {description}
                        </Typography>
                    </Grid>
                </FxCardBody>
                <FxCardFooter id="fx-success-card-modal-footer" className="fx-footer">
                    <Grid container direction="row" justifyContent="center" className="fx-modal-footer">
                        <FxButton
                            disableRipple={false}
                            className="fx-button fx-button-theme"
                            id={"fx-success-card-button"}
                            onClick={handleClick}
                        >
                            {buttonText}
                        </FxButton>
                    </Grid>
                </FxCardFooter>
            </FxCard>
        </Grid>
    );
};
