/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Grid, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Logger } from "../../../libs/utils/logger"
import FxCheckEdit from "../../Input/FxCheck/FxCheckEdit";
import { ReactComponent as UnselectIcon } from '../../../assets/svg/unselect-icon.svg';
import { ReactComponent as SelectIcon } from '../../../assets/svg/select-icon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svg/info-icon.svg';
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { checkViewShouldBeDisabled, isDisableView, parseJsonData, updateComponentParams } from "../../Utils/CommonBaseClass";

Logger.debug("FxMultiSelectDataTable.tsx", "Fx Multiple Data Selection initializing")

interface FxMultiSelectDataTableProps {
    valueChanged: any;
    readOnly?: boolean;
    tableData: any;
    columns: any;
    id: string;
    isHeaderHide?:boolean;
}

/**
 * fx multiple data selection
 * @param props FxMultiSelectDataTableProps
 */
export const FxMultiSelectDataTable: React.FC<FxMultiSelectDataTableProps> =
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const { register, setValue, control } = useForm();
        const dispatch = useDispatch()

        let dataTable: any;
        let tempDataTable: any;
        if (props?.tableData) {
            tempDataTable = parseJsonData([...props?.tableData])
        }
        dataTable = [...tempDataTable].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
        useEffect(() => {
            if (context) {
                dataTable = parseJsonData([...props?.tableData])
            }
        }, [props?.valueChanged]);

        /**
         * Method calculate the total permission count
         * @param data : tableData from props
         * @returns : count of the total permission
         */
        
        const getTotalPermissionCount = (data: any) => {
            try{
                let count = 0
                data?.forEach((item: any)=>{
                    count = count + item.permissions?.length;
                })
                return count;
            }
            catch(e){}
        }

         /**
         * Method calculate the permission count based on type
         * @param data : tableData from props
         * @param type : 'VIEW' or 'EDIT'
         * @returns : count of the permission based on type
         */

        const getTotalPermissionTypeCount = (data: any, type: any) => {
            try{
                let count = 0
                data?.forEach((item: any)=>{
                    item.permissions?.forEach((permissionItem: any)=>{
                        if(permissionItem.operation === type){
                            count = count+1;
                        }
                    })
                })
                return count;
            }
            catch(e){}
        }

        /**
         * Method calculate the total permission count of the item which is checked
         * @param data : tableData from props
         * @returns : count of the total permission of the item which is checked
         */

        const getActiveTotalPermissionCount = (data: any) => {
            try{
                let count = 0
                data?.forEach((item: any)=>{
                    item.permissions?.forEach((permissionItem: any)=>{
                        if(permissionItem.value === 'true'){
                            count = count+1;
                        }
                    })
                })
                return count;
            }
            catch(e){}
        }

          /**
         * Method calculate the permission count of the item which is checked based on type
         * @param data : tableData from props
         * @param type : 'VIEW' or 'EDIT'
         * @returns : count of the permission of the item which is checked based on type
         */

        const getActiveTotalPermissionTypeCount = (data: any, type: any) => {
            try{
                let count = 0
                data?.forEach((item: any)=>{
                    item.permissions?.forEach((permissionItem: any)=>{
                        if(permissionItem.operation === type && permissionItem.value === 'true'){
                            count = count+1;
                        }
                    })
                })
                return count;
            }
            catch(e){}
        }
        /**
         * Method gets called when props.tableData changes
         */

        useEffect(() => {
            if (props.tableData) {
                handleAccessLabel(props.tableData);
            }
        }, [props.tableData]);

        
        /**
         * Method sets the custom message based on the permission selected
         * @param data : tabData from props
         */
        const handleAccessLabel = (data: any) => {
            try{
                const totalPermissionCount = getTotalPermissionCount(data);
                const viewPermissionCount = getTotalPermissionTypeCount(data,'VIEW');
                const editPermissionCount = getTotalPermissionTypeCount(data,'EDIT');
                const activeTotalPermissionCount:any = getActiveTotalPermissionCount(data);
                const activeViewPermissionCount:any = getActiveTotalPermissionTypeCount(data,'VIEW');
                const activeEditPermissionCount:any = getActiveTotalPermissionTypeCount(data,'EDIT');
                const noAccessStatus: boolean = activeTotalPermissionCount > 0? false: true;
                let customMessage:any;
                if(activeTotalPermissionCount === totalPermissionCount && activeTotalPermissionCount>0){
                    customMessage = 'FULL ACCESS';
                }
                else if(activeEditPermissionCount === editPermissionCount && activeEditPermissionCount>0 && (activeTotalPermissionCount === (activeEditPermissionCount+activeViewPermissionCount))){
                    customMessage = 'EDIT ACCESS';
                }
                else if(activeViewPermissionCount === viewPermissionCount && activeViewPermissionCount>0 && (activeTotalPermissionCount === activeViewPermissionCount)){
                    customMessage = 'VIEW ACCESS';
                }
                else if(noAccessStatus){
                    customMessage = 'NO ACCESS';
                }
                else{
                    customMessage = 'CUSTOM ACCESS';
                }
                updateComponentParams(dispatch, props.id, { customMessage: customMessage })
            }
            catch(e){}
        }
        
        /**
         * Event for handling the checkbox changes
         * @param event 
         * @param row 
         * @param id 
         */
        const handleChange = (event: any, row: any, id: any) => {
            row.value = event.target.checked.toString()
            row.checked = event.target.checked
            isAllColumnsChecked(id, row)
        }
        /**
         * Method calculated the length of the item which is checked
         * @param data : permission array of items
         * @returns : length of the item which is checked
         */

        const findAllCheckedLength = (data: any) => {
            try{
                let length = 0;
                data?.forEach((item: any)=>{
                    if(item.value === 'true'){
                        length = length + 1;
                    }
                })
                return length;
            }
            catch(e){}
        }

     
        /**
         * Checking all columns selected 
         * @param id 
         */
        function isAllColumnsChecked(id: any, row: any) {
            try {
                const selectedData: any = parseJsonData(dataTable)
                let isAllChecked = true;
                const selectedRow = [selectedData.find((item: any) => item.name === id)];
                for (const field of selectedRow[0].permissions) {

                    if (field.value === undefined || field.value === false || field.value === 'false') {
                        isAllChecked = false;
                        makeViewButtonChecked(selectedRow, row)
                        break;
                    }
                    else if (field.value === 'true') {
                        makeViewButtonChecked(selectedRow, row)
                    }
                }
                const allCheckedLength:any = findAllCheckedLength(selectedRow[0].permissions)
                if(selectedRow[0].permissions?.length === allCheckedLength){
                    isAllChecked = true;
                }
                else{
                    isAllChecked = false;
                }

                if (isAllChecked) {
                    selectedRow[0].checked = true;
                }
                else {
                    selectedRow[0].checked = false;
                }
                updateComponentParams(dispatch, props?.id, { 'tableData': selectedData })

            } catch (e) { }

        }
      
        /***
         * Method for marked view button as checked when any of the particular row checked
         */
        function makeViewButtonChecked(selectedRow: any, col: any) {
            try {
                selectedRow[0].permissions.forEach((item: any) => {
                    if (col.operation.toLowerCase() !== 'view' && col.checked === true && item.operation.toLowerCase() === 'view') {
                        item.value = 'true';
                        item.checked = true;
                    }
                    if (isDisableView(selectedRow, col) && item.operation.toLowerCase() === 'view') {
                        item.disabled = true;
                    }
                    else if(col.operation.toLowerCase() !== 'view' && checkViewShouldBeDisabled(selectedRow[0].permissions) && item.operation.toLowerCase() === 'view'){
                        item.disabled = true;
                    }
                    else {
                        item.disabled = false;
                    }
                }
                )
            } catch (e) { }
        }

        /**
         * Event for selecting all columns
         * @param event 
         * @param row 
         * @param isSelected 
         */
        const handleSelectAll = (event: any, row: any, isSelected: boolean) => {
 
            selectAll(isSelected, row.name)
            updateComponentParams(dispatch, props?.id, { 'customMessage': 'CUSTOM ACCESS' })
        }
        
        /***
         * Function for selecting all columns
         */
        function selectAll(isSelect: Boolean, id: any) {
            const selectedData: any = parseJsonData(dataTable)
            const selectedRow = [selectedData.find((item: any) => item.name === id)];
            selectedRow.forEach((element: any) => {
                element.checked = isSelect
                element.permissions.forEach((row: any) => {
                    row.checked = isSelect
                    row.value = isSelect.toString()
                    if (isSelect === true && row.operation.toLowerCase() === 'view') {
                        row.disabled = true;
                    }
                    else {
                        row.disabled = false;
                    }
                })
            })
            updateComponentParams(dispatch, props?.id, { 'tableData': selectedData })
        }

        /**
         * This function handles the displaying of the Permissions properly without any underscores
         * @param item 
         */
        function transformItem(item: any) {
            try {
                if (item.includes('_')) {
                    const words = item.split("_");

                    return (words.map((word: any, index: any) => (
                        <React.Fragment key={index}>
                            {word}<br />
                        </React.Fragment>
                    )));

                } else {
                    return item;
                }
            }
            catch (e) {
                console.log(e);
            }

        }

        return (
            <>
                <Grid xs={12} container sm={12} className={!props?.readOnly?"fx-permissions-list-table":"fx-permissions-list-table fx-disabled"}>
                    <Grid item container xs={12} direction="row" className="fx-permissions-head-row" wrap="nowrap"  >
                        <Grid className="fx-permissions-grid-item-50 long header-cell" xs={2}>Permission</Grid>
                        {props?.columns?.map((item: any, index: number) => {
                            const cls = 'fx-permissions-grid-item-50 header-cell';
                            return <Grid className={cls} alignItems='center'>{transformItem(item)}</Grid>
                        })}
                    </Grid>
                    {dataTable && dataTable?.map((item: any) => {
                        return <Grid item container xs={12} direction="row" className="fx-permissions-row" wrap="nowrap">
                                    <Grid className="fx-permissions-grid-item-50 long list-cell" xs={2}> {<Tooltip title="Select All"><UnselectIcon className={item?.checked === undefined || item?.checked === false ? "" : "fx-hidden"} onClick={(event: any) => handleSelectAll(event, item, true)} id={item.name} >{item.name}</UnselectIcon></Tooltip>}
                                        {<SelectIcon className={item?.checked === true ? '' : "fx-hidden"} onClick={(event: any) => handleSelectAll(event, item, false)} id={'unselect-' + item.name}></SelectIcon>}
                                        <Typography className={'permission-name'}>{item.name}</Typography>
                                        <Tooltip title={item.name}><InfoIcon className="fx-permissions-tooltip"></InfoIcon></Tooltip>
                                    </Grid>
                                    {props?.columns?.map((rowvalue: any, index: number) => {
                                        let row = item?.permissions.find((column: any) => column.operation === rowvalue);
                                        let cls: any = 'fx-permissions-grid-item-50';
                                        if (row) {
                                            return <Grid className={cls}>
                                                        <FxCheckEdit className={`fx-permissions-checkbox ${row.value && row.checked ? 'green' : ''}`} disabled={row.disabled} updateOptions={true} onChange={(event: any) => handleChange(event, row, item?.name)} register={{ ...register(row.operation + "-" + row.id) }} control={control} data={[{ label: '', value: row?.value?.toString(), checked: row.value === 'true' ? true : false }]} id={row.operation + "-" + row.id} name={row.operation + "-" + row.id} setValue={setValue} />
                                                    </Grid>
                                        }
                                        else {
                                            return <Grid className={cls}>
                                                        <div className="diabled-check-box">
                                                            <FxCheckEdit className='fx-permissions-checkbox' updateOptions={true} disabled={true} onChange={(event: any) => handleChange(event, row, item?.name)} register={{ ...register(rowvalue) }} control={control} data={[{ label: '', value: 'false', checked: false }]} id={rowvalue} name={rowvalue} setValue={setValue} />
                                                        </div>
                                                    </Grid>
                                        }

                                    })}
                                </Grid>
                        })}
                </Grid>
            </>
           
        )

    }