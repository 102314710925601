import React from 'react'

/**
 * Component used to draw mxm fast funding modal content
 * @returns 
 */
export default function MxmFastFundingDialogContent() {
    return (
        <p>I want to link my Passport account to MX™ Merchant & enable Funding via Passport.</p>
    )
}
